import { takeEvery, put, select, all } from 'redux-saga/effects'
import { ADD_GROUP_TYPE, AddGroupTypeAction, AddGroupTypeCustomFieldAction, ADD_GROUP_TYPE_CUSTOM_FIELD, UPDATE_GROUP_TYPE_CUSTOM_FIELD } from './types/types';
import { addGroupTypeCustomField, updateGroupTypeCustomFieldStartPiece, registerGroupTypeCustomFieldVariable, removeGroupFromGroupType, addGroupToGroupType } from './types/actions';
import { FieldType, IUpdateableCustomFieldData } from '../custom-fields';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { addPiece } from '../flowchart/pieces/actions';
import uuid from 'uuid';
import { PieceType } from '../flowchart/pieces/types';
import { ApplicationState } from '../main';
import { addGroupToLocation, removeGroupFromLocation } from '../structure/location/actions';
import { AddGroupAction, DeleteGroupAction, UpdateGroupRequestAction, ADD_GROUP, DELETE_GROUP, UPDATE_GROUP_REQUEST, IGroup, SET_MEMBERS_FOR_GROUP_REQUEST, SetMembersForGroupRequestAction } from './types';
import { updateGroup, setMembersForGroup } from './actions';
import { addGroupToMember, removeGroupFromMember } from '../members/actions';

function* createDefaultCustomFields(action: AddGroupTypeAction) {
    const nameCustomField: IUpdateableCustomFieldData = {
        id: action.payload.nameFieldId,
        name: 'Name',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        isUnique: false,
        seedEntityVariable: uuid.v4(),
    };
    
    const subTitleCustomField: IUpdateableCustomFieldData = {
        id: action.payload.subTitleFieldId,
        name: 'Sub Title',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        isUnique: false,
        seedEntityVariable: uuid.v4(),
    };

    yield all([
        put(addGroupTypeCustomField(nameCustomField, action.payload.id)),
        put(addGroupTypeCustomField(subTitleCustomField, action.payload.id)),
    ]);
}

export function* watchGroupTypeCreationRequest() {
    yield takeEvery(ADD_GROUP_TYPE, createDefaultCustomFields);
}

function* createSeedFlowchartForGroupTypeCustomField(action: AddGroupTypeCustomFieldAction) {

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        const state: ApplicationState = yield select();

        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {
        
            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'Group',
                    type: VariableType.GROUP,
                })),

                put(registerGroupTypeCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateGroupTypeCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id))
            ]);

        }

    }
}

export function* watchGroupTypeCustomFieldChanges() {
    yield takeEvery([ADD_GROUP_TYPE_CUSTOM_FIELD, UPDATE_GROUP_TYPE_CUSTOM_FIELD], createSeedFlowchartForGroupTypeCustomField);
}

function* provideReverseLinksForNewGroup(action: AddGroupAction) {
    let allPutActions = [];
    
    allPutActions.push(put(addGroupToLocation(action.payload.id, action.payload.location)));
    allPutActions.push(put(addGroupToGroupType(action.payload.id, action.payload.type)));

    const newMemberIds = new Set(action.payload.members);

    for (const newMemberId of newMemberIds) {
        allPutActions.push(put(addGroupToMember(action.payload.id, action.payload.type, newMemberId)));
    }

    yield all(allPutActions);
}

function* removeReverseLocationLinksForGroup(action: DeleteGroupAction) {
    const currentGroup: IGroup = yield select(state => state.groups.byId[action.id]);

    let allPutActions = [];

    allPutActions.push(put(removeGroupFromLocation(action.id, currentGroup.location)));
    allPutActions.push(put(removeGroupFromGroupType(action.id, currentGroup.type)));

    const oldMemberIds = new Set(currentGroup.members);

    for (const oldMemberId of oldMemberIds) {
        allPutActions.push(put(removeGroupFromMember(action.id, currentGroup.type, oldMemberId)));
    }

    yield all(allPutActions);
}

function* updateLocationsAndMembers(action: UpdateGroupRequestAction) {

    const currentGroup: IGroup = yield select(state => state.groups.byId[action.payload.id]);

    let allPutActions = [];

    if (action.payload.location !== currentGroup.location) {
        allPutActions.push(put(removeGroupFromLocation(action.payload.id, currentGroup.location)));
        allPutActions.push(put(addGroupToLocation(action.payload.id, action.payload.location)));
    }

    if (action.payload.type !== currentGroup.type) {
        allPutActions.push(put(removeGroupFromGroupType(action.payload.id, currentGroup.type)));
        allPutActions.push(put(addGroupToGroupType(action.payload.id, action.payload.type)));
    }

    const oldMemberIds = new Set(currentGroup.members);
    const newMemberIds = new Set(action.payload.members);

    for (const oldMemberId of oldMemberIds) {
        if (!newMemberIds.has(oldMemberId)) {
            allPutActions.push(put(removeGroupFromMember(action.payload.id, action.payload.type, oldMemberId)));
        }
    }

    for (const newMemberId of newMemberIds) {
        if (!oldMemberIds.has(newMemberId)) {
            allPutActions.push(put(addGroupToMember(action.payload.id, action.payload.type, newMemberId)));
        }
    }

    allPutActions.push(put(updateGroup(action.payload)));

    yield all(allPutActions);
}

function* updateMemberReverseLinks(action: SetMembersForGroupRequestAction) {
    const currentGroup: IGroup = yield select(state => state.groups.byId[action.groupId]);
    const currentMemberIds = currentGroup.members;
    const newMemberIds = action.memberTypes === 'all_members' ? action.memberIds : Array.from(new Set(currentGroup.members.concat(action.memberIds)));

    const memberIdsToAdd = newMemberIds.filter(newMemberId => !currentMemberIds.includes(newMemberId));
    const memberIdsToRemove = currentMemberIds.filter(currentMemberId => !newMemberIds.includes(currentMemberId));

    const allPutActions = [];

    for (const memberId of memberIdsToAdd) {
        allPutActions.push(put(addGroupToMember(currentGroup.id, currentGroup.type, memberId)));
    }

    for (const memberId of memberIdsToRemove) {
        allPutActions.push(put(removeGroupFromMember(currentGroup.id, currentGroup.type, memberId)));
    }

    allPutActions.push(put(setMembersForGroup(action.groupId, action.memberTypes, action.memberIds)));

    yield all(allPutActions);
}

export function* watchGroupCreationChanges() {
    yield takeEvery(ADD_GROUP, provideReverseLinksForNewGroup);
}

export function* watchGroupDeletionChanges() {
    yield takeEvery(DELETE_GROUP, removeReverseLocationLinksForGroup);
}

export function* watchGroupUpdateChanges() {
    yield takeEvery(UPDATE_GROUP_REQUEST, updateLocationsAndMembers);
}

export function* watchGroupMemberUpdateChanges() {
    yield takeEvery(SET_MEMBERS_FOR_GROUP_REQUEST, updateMemberReverseLinks);
}