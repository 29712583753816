import React, { Component } from 'react';
import styles from './TooltipList.module.scss';
import onClickOutside from 'react-onclickoutside';


type OwnProps = {
    isShowingSearch: boolean,
    listElements: Array<JSX.Element>,
};

class TooltipList extends Component<OwnProps>  {
    static defaultProps = {
        isShowingSearch: false,
        listElements: [],
    }

    render() {
        return (
            <div className={styles.tooltipHolder}>
                {this.props.isShowingSearch && <section className={styles.search}>
                    <input type="text" className={styles.searchBox} placeholder="Search the list" />
                </section>}
                <section className={styles.listItems}>
                    {this.props.listElements.map(child => {
                        return <section className={styles.listItem}>{child}</section>
                    })}
                </section>
            </div>
        );
    }
}

const EnhancedTooltip = onClickOutside(TooltipList, {
    excludeScrollbar: true
});

export default EnhancedTooltip;