import React, { Component } from 'react';
import styles from './QuestionPiece.module.scss';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setCustomField } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { booleanPieceSlotTarget, valuePieceSlotTarget } from './utilities';
import Input from '../Input';


type QuestionPieceEssentialsProps = {
    isRequiredPiece?: JSX.Element,
    isDisabledPiece?: JSX.Element,
    defaultPiece?: JSX.Element,
    defaultText?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setRequiredPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionRequiredPiece(targetPieceId, draggedPieceId)),
        setDisabledPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionDisabledPiece(targetPieceId, draggedPieceId)),
        setDefaultPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionDefaultPiece(targetPieceId, draggedPieceId)),
        setCustomField: (pieceId: string, value: string) => dispatch(setCustomField(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = QuestionPieceEssentialsProps & StateProps & DispatchProps & FlowchartPieceProps;

type QuestionPieceEssentialsState = {
    isHoveringOverRequiredPiece: boolean,
    isHoveringOverDisabledPiece: boolean,
    isHoveringOverDefaultPiece: boolean,
}

class ConnectedQuestionPieceEssentials extends Component<Props, QuestionPieceEssentialsState>  {

    state = {
        isHoveringOverRequiredPiece: false,
        isHoveringOverDisabledPiece: false,
        isHoveringOverDefaultPiece: false,
    };

    handleHoverOverDisabledPiece = () => {
        this.setState({
            isHoveringOverDisabledPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDisabledPiece = () => {
        this.setState({
            isHoveringOverDisabledPiece: false,
        });
    };

    handleHoverOverDefaultPiece = () => {
        this.setState({
            isHoveringOverDefaultPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDefaultPiece = () => {
        this.setState({
            isHoveringOverDefaultPiece: false,
        });
    };

    handleHoverOverRequiredPiece = () => {
        this.setState({
            isHoveringOverRequiredPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfRequiredPiece = () => {
        this.setState({
            isHoveringOverRequiredPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverRequiredPiece || this.state.isHoveringOverDisabledPiece || this.state.isHoveringOverDefaultPiece)) {
            if (this.state.isHoveringOverRequiredPiece) {
                this.props.setRequiredPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverDisabledPiece) {
                this.props.setDisabledPiece(this.props.pieceId, this.props.lastDraggedPiece.id)
            } else if (this.state.isHoveringOverDefaultPiece) {
                this.props.setDefaultPiece(this.props.pieceId, this.props.lastDraggedPiece.id)
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverRequiredPiece: false,
                isHoveringOverDisabledPiece: false,
                isHoveringOverDefaultPiece: false,
            });
        }
    }

    handleDefaultValueUpdate = (value: string) => {
        this.props.setDefaultPiece(this.props.pieceId, value);
    }

    render() {

        return (
            <div className={styles.visibleItems}>
                <div className={styles.text}>Is Required?</div>
                {this.props.isRequiredPiece ? this.props.isRequiredPiece : <div className={(this.state.isHoveringOverRequiredPiece && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverRequiredPiece} onMouseOut={this.handleHoverOutOfRequiredPiece}></div>}

                <div className={styles.text}>Is Disabled?</div>
                {this.props.isDisabledPiece ? this.props.isDisabledPiece : <div className={(this.state.isHoveringOverDisabledPiece && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverDisabledPiece} onMouseOut={this.handleHoverOutOfDisabledPiece}></div>}

                <div className={styles.text}>Default</div>
                {this.props.defaultPiece ? this.props.defaultPiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverDefaultPiece && !!this.props.targetPiece} placeholderText="Default" minSize={9} defaultText={this.props.defaultText} onMouseOver={this.handleHoverOverDefaultPiece} onMouseOut={this.handleHoverOutOfDefaultPiece} />}
            </div>
        )

    }
}

const QuestionPieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionPieceEssentials);

export default QuestionPieceEssentials;