import React, { Component } from 'react';
import styles from './CountDown.module.scss';

type OwnProps = { remaining: number, color?: string };
type OwnState = { remainingSeconds: number, interval: number|undefined };

class CountDown extends Component<OwnProps, OwnState> {

    constructor(props: Readonly<OwnProps>) {
        super(props);
        this.decrementSeconds = this.decrementSeconds.bind(this);
        const interval = window.setInterval(this.decrementSeconds, 1000);
        
        this.state = {
            remainingSeconds: props.remaining,
            interval: interval
        };
    }
    
    decrementSeconds() {

        // If the remaining time is 0, stop counting down
        if (this.state.remainingSeconds === 0) {
            window.clearInterval(this.state.interval);
            return;
        }

        this.setState((prevState, props) => {
            return {remainingSeconds: prevState.remainingSeconds - 1};
        });
    }
    
    render() {
        return <span className={styles.counter} style={{color: this.props.color}}>00:{this.state.remainingSeconds < 10 ? '0' + this.state.remainingSeconds : this.state.remainingSeconds}</span>;
    }
}

export default CountDown;