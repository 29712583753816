import { Permissions, UPDATE_GENERAL_PERMISSION, UPDATE_MEMBER_PERMISSION, UPDATE_GROUP_PERMISSION, UPDATE_WORKFLOW_PERMISSION, UPDATE_REPORT_PERMISSION, UpdateGeneralPermission, UpdateMemberPermission, UpdateGroupPermission, UpdateWorkflowPermission, UpdateReportPermission } from './types';

export function updateGeneralPermission(id: string, role: string, permission: Permissions): UpdateGeneralPermission {
    return {
        type: UPDATE_GENERAL_PERMISSION,
        id,
        role,
        permission,
    }
}

export function updateMemberPermission(memberTypeId: string, role: string, permission: Permissions): UpdateMemberPermission {
    return {
        type: UPDATE_MEMBER_PERMISSION,
        memberTypeId,
        role,
        permission,
    }
}

export function updateGroupPermission(groupTypeId: string, role: string, permission: Permissions): UpdateGroupPermission {
    return {
        type: UPDATE_GROUP_PERMISSION,
        groupTypeId,
        role,
        permission,
    }
}

export function updateWorkflowPermission(workflowTypeId: string, role: string, permission: Permissions): UpdateWorkflowPermission {
    return {
        type: UPDATE_WORKFLOW_PERMISSION,
        workflowTypeId,
        role,
        permission,
    }
}

export function updateReportPermission(reportTypeId: string, role: string, permission: Permissions): UpdateReportPermission {
    return {
        type: UPDATE_REPORT_PERMISSION,
        reportTypeId,
        role,
        permission,
    }
}