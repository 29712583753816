import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type OrOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const OrOperator: FC<OrOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator operatorSymbol="or" isBoolean={true} areOperatorsBoolean={true} {...props} />
    )
}

export default OrOperator;