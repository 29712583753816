import { takeEvery, put, all } from 'redux-saga/effects'
import { registerWidgetCreation, unregisterWidgetCreation } from '../my-data/widgets/actions';
import { ADD_WIDGET, DELETE_WIDGET, AddWidgetAction, DeleteWidgetAction } from './types';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import uuid from 'uuid';
import { addPiece } from '../flowchart/pieces/actions';
import { PieceType } from '../flowchart/pieces/types';
import { updateWidgetStartPiece } from './actions';

function* createSeedFlowchartAndRegisterWidget(action: AddWidgetAction) {
    let variableName: string, variableType: VariableType;

    const startPieceId = uuid.v4();

    switch (action.payload.type) {
        case 'User':
            variableName = 'User';
            variableType = VariableType.USER;
            break;
        case 'Member':
            variableName = 'Member';
            variableType = VariableType.MEMBER;
            break;
        case 'Group':
            variableName = 'Group';
            variableType = VariableType.GROUP;
            break;
        case 'Workflow':
            variableName = 'Workflow';
            variableType = VariableType.WORKFLOW;
            break;
        default:
            throw new Error('Unknown entity');
    }
    
    yield all([
        put(registerWidgetCreation(action.payload.id)),
        put(addVariable({
            id: action.payload.seedEntityVariable,
            name: variableName,
            type: variableType,
        })),
        put(addPiece(startPieceId, PieceType.START)),
        put(updateWidgetStartPiece({
            piece: startPieceId,
            position: {
                x: 0,
                y: 0,
            }
        }, action.payload.id)),
    ]);
}

function* unregisterWidgetCreationForUser(action: DeleteWidgetAction) {
    yield put(unregisterWidgetCreation(action.id));
}

export function* watchWidgetCreationRequest() {
    yield takeEvery(ADD_WIDGET, createSeedFlowchartAndRegisterWidget);
}

export function* watchWidgetDeletionRequest() {
    yield takeEvery(DELETE_WIDGET, unregisterWidgetCreationForUser);
}