import React, { Component, ChangeEvent } from 'react';
import styles from './List.module.scss';

import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import MembersTable from './MembersTable';

import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import uuid from 'uuid';
import { ReactComponent as TableIcon } from '../../../assets/table-grid.svg';
import { ReactComponent as ExportIcon } from '../../../assets/export.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';

import TooltipList from '../../../widgets/tooltip-list/TooltipList';

import { ApplicationState } from '../../../shared/store/main';
import { Permissions } from '../../../shared/store/permissions/types';
import { ILocation, LocationState } from '../../../shared/store/structure/location/types';
import store from '../../../shared/store/main';
import { getCustomFieldValueForInput } from '../../../shared/store/custom-fields';
import { IUpdateableMemberData } from '../../../shared/store/members/types';
import { Dispatch } from 'redux';
import { addMember } from '../../../shared/store/members/actions';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditMembers = state.permissions.myPermissions.general.Members === Permissions.WRITE;
    const canViewMembers = canEditMembers || state.permissions.myPermissions.general.Members === Permissions.READ;
    
    return {
        filtersExpanded: false,
        read: canViewMembers,
        write: canEditMembers,
        structureData: state.structure,
        memberTypesData: state.members.types,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addMember: (payload: IUpdateableMemberData) => dispatch(addMember(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & OwnProps;

type OwnState = {
    isShowingMemberTypesForImportTemplate: boolean,
    erroneousData: Array<Array<string>>,
}

class ConnectedMembers extends Component<Props, OwnState> {
    state = {
        isShowingMemberTypesForImportTemplate: false,
        erroneousData: [],
    }

    showMemberTypesForImportTemplate = () => {
        this.setState({
            isShowingMemberTypesForImportTemplate: true,
        });
    }

    hideMemberTypesForImportTemplate = () => {
        this.setState({
            isShowingMemberTypesForImportTemplate: false,
        });
    }

    importRow = (memberData: Array<string>) => {
        let memberTypeId: string|undefined;

        if (this.props.memberTypesData.allEntries.length === 1) {
            memberTypeId = this.props.memberTypesData.allEntries[0];
        } else {
            const memberTypeName = memberData[0];
            memberTypeId = this.props.memberTypesData.allEntries.find(memberTypeId => {
                const memberType = this.props.memberTypesData.byId[memberTypeId];
                return memberTypeName === memberType.name;
            });
        }

        if (typeof memberTypeId === 'undefined') {
            throw new Error('Unknown member type');
        }

        const memberType = this.props.memberTypesData.byId[memberTypeId];
        const selectedProject = this.props.structureData.projects.byId[memberType.project];

        const firstLocationColumnIndex = this.props.memberTypesData.allEntries.length === 1 ? 0 : 1;
        let columnIndex = firstLocationColumnIndex;  // The first column is reserved for the member type only if there is more than one member type
        let rawImportInput: string;
        let parentLocation: ILocation|undefined;
        let locationStateData = store.getState().structure.locations;

        // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
        function getLocationFromName (siblingLocationIds: Array<string>, locationName: string, locationStateData: LocationState) {
            const currentLocationId = siblingLocationIds.find(locationId =>  locationStateData.byId[locationId].name === locationName);

            return typeof currentLocationId !== 'undefined' ? locationStateData.byId[currentLocationId] : undefined;
        }

        let currentLocation: ILocation|undefined;
        
        // Loop through each level
        for (const levelId of selectedProject.children) {
            const level = this.props.structureData.levels.byId[levelId];

            if (memberData.length <= columnIndex) {
                throw new Error(`This row does not have data for the level ${level.name}`);
            }

            rawImportInput = memberData[columnIndex];

            // Get current location data
            if (columnIndex === firstLocationColumnIndex) {
                currentLocation = getLocationFromName(locationStateData.byProject[selectedProject.id], rawImportInput, locationStateData);
            } else {
                if (typeof parentLocation === 'undefined') {
                    throw new Error('The parent location must be defined for lower level locations');
                }

                currentLocation = getLocationFromName(parentLocation.children, rawImportInput, locationStateData);
            }

            // If the current location does not exist, prepare the new location data.
            if (typeof currentLocation === 'undefined') {
                throw new Error('This location does not exist');
            }

            columnIndex += 1;
            parentLocation = currentLocation;
        }

        if (typeof currentLocation === 'undefined') {
            throw new Error('This member does not have a valid location');
        }

        const newMemberId = uuid.v4();
        const newMemberData: IUpdateableMemberData = {
            id: newMemberId,
            type: memberTypeId,
            location: currentLocation.id,
            groups: {},
            customFields: {},
        }

        // Loop through the custom fields, and store the value in the new Location Data
        for (const customFieldId of memberType.customFields) {
            const customField = this.props.memberTypesData.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                
                if (memberData.length <= columnIndex) {
                    throw new Error(`This row does not have data for the custom field ${customField.name}`);
                }
                
                rawImportInput = memberData[columnIndex];
                
                // Custom field values of the new location is stored
                const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, this.props.memberTypesData.customFieldOptions);
                newMemberData.customFields[customField.id] = customFieldValue;

                columnIndex += 1;
            }

        }

        this.props.addMember(newMemberData);

    }

    importMembers = (importData: Array<Array<string>>) => {

        const erroneousData: Array<Array<string>> = [];

        if (importData.length < 2) {
            return;
        }

        let memberTypeId: string|undefined;

        if (this.props.memberTypesData.allEntries.length === 1) {
            memberTypeId = this.props.memberTypesData.allEntries[0];
        } else {
            const memberTypeName = importData[1][0];
            memberTypeId = this.props.memberTypesData.allEntries.find(memberTypeId => {
                const memberType = this.props.memberTypesData.byId[memberTypeId];
                return memberTypeName === memberType.name;
            });
        }

        if (typeof memberTypeId === 'undefined') {
            throw new Error('Unknown member type');
        }

        const errorFileHeading = this.getImportFileHeader(memberTypeId).concat(['Errors']);
        const noOfColumns = errorFileHeading.length - 1;
        erroneousData.push(errorFileHeading);

        const dataWithoutHeader = importData.slice(1);

        for (const rowData of dataWithoutHeader) {
            if (rowData.length > 1 || (rowData.length === 1 && rowData[0].trim() !== '')) {
                try {
                    this.importRow(rowData);
                } catch (e) {
                    const erroneousRow = Array(noOfColumns).fill('').map((entry, index) => {
                        if (index < rowData.length) {
                            return rowData[index];
                        } else {
                            return '';
                        }
                    });
                    erroneousRow.push(e.message);
                    erroneousData.push(erroneousRow);
                }
            }
        }

        this.setState({
            erroneousData,
        });

    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;
                    this.importMembers(csvData);
                }
            });
        }
    }

    getImportFileHeader = (memberTypeId: string) => {
        let templateMarkup: Array<string> = [];
        const memberType = this.props.memberTypesData.byId[memberTypeId];
        const project = this.props.structureData.projects.byId[memberType.project];

        if (this.props.memberTypesData.allEntries.length !== 1) {
            templateMarkup.push('Member Type');
        }

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(level.name);
        }

        for (const customFieldId of memberType.customFields) {
            const customField = this.props.memberTypesData.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                templateMarkup.push(customField.name);
            }

        }

        return templateMarkup;

    }

    dismissErrorMessage = () => {
        this.setState({
            erroneousData: [],
        });
    }

    render() {
        if (!this.props.read) {
            return <Redirect to="/dashboard" />;
        }

        const memberTypesImportTemplates = this.props.memberTypesData.allEntries.map(memberTypeId => {
            const memberType = this.props.memberTypesData.byId[memberTypeId];
            const templateMarkup = this.getImportFileHeader(memberTypeId);

            return <CSVLink data={[templateMarkup]} filename={`${memberType.name} template.csv`}><div className={styles.listItem}>{memberType.name}</div></CSVLink>;

        });

        let templateDownloadButton: JSX.Element;

        if (this.props.memberTypesData.allEntries.length === 1) {
            const memberTypeId = this.props.memberTypesData.allEntries[0];
            const memberType = this.props.memberTypesData.byId[memberTypeId];
            const templateMarkup = this.getImportFileHeader(memberTypeId);
            templateDownloadButton = <CSVLink data={[templateMarkup]} filename={`${memberType.name} template.csv`}><button className={styles.importTemplate + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'} > <TableIcon /> Download template</button></CSVLink>;
        } else {
            templateDownloadButton = <button className={styles.importTemplate + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'} onClick={this.props.memberTypesData.allEntries.length !== 1 ? this.showMemberTypesForImportTemplate : () => {}} > <TableIcon /> Download template</button>;
        }
        
        return (
            <section className={styles.membersSection}>
                {templateDownloadButton}
                {this.props.memberTypesData.allEntries.length > 1 && this.state.isShowingMemberTypesForImportTemplate && <div className={styles.memberTypesHolder}>
                    <TooltipList handleClickOutside={this.hideMemberTypesForImportTemplate} listElements={memberTypesImportTemplates} />
                </div>}

                <input type="file" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />
                <label htmlFor="import-file-input" className={styles.importButton + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}> <ExportIcon /> Import</label>

                <div className={this.props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                    <MembersTable isReadOnly={!this.props.write} />
                </div>

                {this.state.erroneousData.length > 1 && <div className={styles.errorMessageHolder + ' ignore-react-onclickoutside'}>
                Errors during import. Please download <CSVLink data={this.state.erroneousData} filename={`Import errors.csv`}><span className={styles.highlight}>this file</span></CSVLink> to fix errors and re upload.

                <div className={styles.dismissal} onClick={this.dismissErrorMessage} >
                    <CancelIcon/>
                </div>
            </div>}
            </section>
        );
    }
};

const Members = connect(mapStateToProps, mapDispatchToProps)(ConnectedMembers);

export default Members;