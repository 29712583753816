import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { INewRoleData, IUpdateableRoleData } from '../../../shared/store/structure/role/types';
import { reOrderRoles, addRole, updateRole, deleteRole } from '../../../shared/store/structure/role/actions';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState 
} from '../CardTreeVertical';
import { translatePhrase } from '../../../shared/helpers/translation';

interface OwnProps extends CardTreeOwnProps {
    levelId?: string,
    isShowingProjectName: boolean,
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewRoleData) => void,
    updateCard: (payload: IUpdateableRoleData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    let allRoleIds: Array<string> = [];

    if (ownProps.levelId) {
        allRoleIds = state.structure.levels.byId[ownProps.levelId].children;
    } else {
        allRoleIds = state.structure.roles.allEntries.filter(roleId => {
            const role = state.structure.roles.byId[roleId];
            const level = state.structure.levels.byId[role.level];
            const project = state.structure.projects.byId[level.project];

            return !role.archived && !level.archived && !project.archived;
        });
    }

    let allRoles: Array<IUpdateableRoleData> = allRoleIds.map(roleId => {
        const role = state.structure.roles.byId[roleId];
        let suffix = '';

        if (ownProps.isShowingProjectName) {
            suffix = ' (' + translatePhrase(state.structure.projects.byId[state.structure.levels.byId[role.level].project].name) + ')';
        }

        return {
            ...role,
            name: translatePhrase(role.name) + suffix,
        };
    });

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        cardsList: allRoles,
        selectedCard: ownProps.selectedId ? allRoles.find(role => role.id === ownProps.selectedId) : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderRoles(sourceIndex, destinationIndex, ownProps.levelId || '')),
        addCard: (payload: INewRoleData) => dispatch(addRole(payload, ownProps.levelId || '')),
        deleteCard: (id: string) => dispatch(deleteRole(id, ownProps.levelId || '')),
        updateCard: (payload: IUpdateableRoleData) => dispatch(updateRole(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedRolesList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
    }

    static defaultProps = {
        isReadOnly: false,
        isShowingProjectName: false,
    }

}

const RolesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedRolesList);

export default RolesList;