import { takeEvery, put, select, all } from 'redux-saga/effects'
import { AddLevelCustomFieldAction, ADD_LEVEL_CUSTOM_FIELD, UPDATE_LEVEL_CUSTOM_FIELD, AddLevelAction, DeleteLevelAction, ADD_LEVEL, DELETE_LEVEL } from './types';
import { updateLevelCustomFieldStartPiece, registerLevelCustomFieldVariable } from './actions';
import { addVariable } from '../../flowchart/variables/actions';
import { VariableType } from '../../flowchart/variables/types';
import { addPiece } from '../../flowchart/pieces/actions';
import uuid from 'uuid';
import { PieceType } from '../../flowchart/pieces/types';
import { ApplicationState } from '../../main';
import { addLevelToProject, removeLevelFromProject } from '../project/actions';

function* createSeedFlowchartForLevelCustomField(action: AddLevelCustomFieldAction) {

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        const state: ApplicationState = yield select();

        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {
        
            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'Group',
                    type: VariableType.GROUP,
                })),

                put(registerLevelCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateLevelCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id))
            ]);

        }

    }
}

export function* watchLevelCustomFieldChanges() {
    yield takeEvery([ADD_LEVEL_CUSTOM_FIELD, UPDATE_LEVEL_CUSTOM_FIELD], createSeedFlowchartForLevelCustomField);
}

function* addReverseLinkForParentProject(action: AddLevelAction) {
    yield put(addLevelToProject(action.payload.id, action.parentId));
}

function* removeReverseLinkForParentProject(action: DeleteLevelAction) {
    yield put(removeLevelFromProject(action.id, action.parentId));
}

export function* watchLevelCreationChanges() {
    yield takeEvery(ADD_LEVEL, addReverseLinkForParentProject);
}

export function* watchLevelDeletionChanges() {
    yield takeEvery(DELETE_LEVEL, removeReverseLinkForParentProject);
}