import { Position } from '../../../helpers/common-types';
import { NormalizedModel } from '../../normalized-model';
import { VariableType } from '../variables/types';

export const ADD_PIECE = 'ADD_PIECE';
export const ADD_FULL_PIECE = 'ADD_FULL_PIECE';

export const SET_NEXT_PIECE = 'SET_NEXT_PIECE';  // Used in all pieces except End and Return
export const SET_CONDITION_NEXT_PIECE = 'SET_CONDITION_NEXT_PIECE';  // Used in split piece for the if conditions
export const SET_INNER_PIECE = 'SET_INNER_PIECE';  // Used in for and question pieces
export const SET_CONDITION_PIECES = 'SET_CONDITION_PIECES';  // Used in split piece
export const SET_CONDITION_PIECE = 'SET_CONDITION_PIECE';  // Used in split piece
export const SET_CONDITION_PIECE_POSITION = 'SET_CONDITION_PIECE_POSITION';  // Used in split piece
export const SET_LOOP_VARIABLE = 'SET_LOOP_VARIABLE';  // Used in for loop
export const SET_ITERABLE_VARIABLE = 'SET_ITERABLE_VARIABLE';  // Used in for loop
export const SET_UPDATE_STATUS_VALUE = 'SET_UPDATE_STATUS_VALUE';  // Used in update status piece
export const SET_UPDATE_DUE_DATE_VALUE = 'SET_UPDATE_DUE_DATE_VALUE';  // Used in update due date piece

export const SET_OPERAND = 'SET_OPERAND';  // Used in unary operators
export const SET_LEFT_OPERAND = 'SET_LEFT_OPERAND';  // Used in binary operators
export const SET_RIGHT_OPERAND = 'SET_RIGHT_OPERAND';  // Used in binary operators
export const SET_QUESTION_DATA = 'SET_QUESTION_DATA';  // Used in question and choose pieces
export const SET_QUESTION_CHOICE_LIST = 'SET_QUESTION_CHOICE_LIST';  // Used in choose piece
export const SET_QUESTION_CHOICE_VARIABLE = 'SET_QUESTION_CHOICE_VARIABLE';  // Used in choose piece
export const SET_QUESTION_REQUIRED_PIECE = 'SET_QUESTION_REQUIRED_PIECE';  // Used in question piece
export const SET_QUESTION_DISABLED_PIECE = 'SET_QUESTION_DISABLED_PIECE';  // Used in question piece
export const SET_QUESTION_DEFAULT_PIECE = 'SET_QUESTION_DEFAULT_PIECE';  // Used in question piece
export const SET_MEMBER_VARIABLE = 'SET_MEMBER_VARIABLE';  // Used in get and store for member pieces

export const SET_DATA_STORE_VALUE = 'SET_DATA_STORE_VALUE';  // Used in store and store for member pieces
export const SET_DATA_SET_VALUE = 'SET_DATA_SET_VALUE';  // Used in set variable piece
export const SET_VARIABLE_FOR_SET = 'SET_VARIABLE_FOR_SET';  // Used in set variable piece
export const SET_VARIABLE_FOR_CUSTOM_FIELD = 'SET_VARIABLE_FOR_CUSTOM_FIELD';  // Used in get and store variable piece
export const SET_DATA_COPY_VARIABLE = 'SET_DATA_COPY_VARIABLE';  // Used in get and get for member pieces
export const SET_RETURN_VALUE = 'SET_RETURN_VALUE';  // Used in return piece

export const SET_VARIABLE_FOR_SHOW = 'SET_VARIABLE_FOR_SHOW';  // Used in show piece
export const SET_ENTITY_TYPE = 'SET_ENTITY_TYPE';  // Used in show piece

export const SET_SELECTED_OPTIONS = 'SET_SEQUENCE_OPTIONS';  // Used in the sequence piece
export const SET_CUSTOM_FIELDS_FOR_SHOW = 'SET_CUSTOM_FIELDS_FOR_SHOW';  // Used in show piece

export const SET_CUSTOM_FIELD = 'SET_CUSTOM_FIELD';  // Used in get and set pieces

export const SET_TRANSFER_ROLES = 'SET_TRANSFER_ROLES';  // Used in the transfer piece

export const START_PIECE_DRAG = 'START_PIECE_DRAG';
export const STOP_PIECE_DRAG = 'STOP_PIECE_DRAG';
export const SET_TARGET_PIECE = 'SET_TARGET_PIECE';
export const DELETE_PIECE = 'DELETE_PIECE';

export const UPDATE_VARIABLE_PIECE = 'UPDATE_VARIABLE_PIECE';
export const SET_WORKFLOW_TYPE = 'SET_WORKFLOW_TYPE';
export const SET_WORKFLOW_STATUS = 'SET_WORKFLOW_STATUS';
export const SET_WORKFLOW_DUE_DATE = 'SET_WORKFLOW_DUE_DATE';
export const SET_WORKFLOW_AFFILIATION_VARIABLE = 'SET_WORKFLOW_AFFILIATION_VARIABLE';
export const SET_WORKFLOW_IS_ASYNC = 'SET_WORKFLOW_IS_ASYNC';

export const SET_REPORT_NAME = 'SET_REPORT_NAME';
export const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
export const SET_REPORT_USER = 'SET_REPORT_USER';
export const SET_REPORT_START_DATE = 'SET_REPORT_START_DATE';
export const SET_REPORT_END_DATE = 'SET_REPORT_END_DATE';

export const SET_STRUCTURE_PROJECT = 'SET_STRUCTURE_PROJECT';
export const SET_STRUCTURE_LEVEL = 'SET_STRUCTURE_LEVEL';
export const SET_STRUCTURE_ROLE = 'SET_STRUCTURE_ROLE';

export const UPDATE_CUSTOM_FIELD_PIECE = 'UPDATE_CUSTOM_FIELD_PIECE';
export const UPDATE_ANSWER_PIECE = 'UPDATE_ANSWER_PIECE';
export const UPDATE_STATUS_PIECE = 'UPDATE_STATUS_PIECE';

export const SET_ERROR = 'SET_ERROR';
export const SET_MESSAGE = 'SET_MESSAGE';

export const SET_VARIABLE_FOR_ADD_MEMBER = 'SET_VARIABLE_FOR_ADD_MEMBER';
export const SET_VARIABLE_FOR_ADD_GROUP = 'SET_VARIABLE_FOR_ADD_GROUP';
export const SET_VARIABLE_FOR_ADD_WORKFLOW = 'SET_VARIABLE_FOR_ADD_WORKFLOW';
export const SET_VARIABLE_FOR_ADD_REPORT = 'SET_VARIABLE_FOR_ADD_REPORT';
export const SET_VARIABLE_FOR_LIST = 'SET_VARIABLE_FOR_LIST';
export const SET_VARIABLE_FOR_NEW_DATE = 'SET_VARIABLE_FOR_NEW_DATE';
export const SET_VARIABLE_PIECE = 'SET_VARIABLE_PIECE';
export const SET_PIECE_FOR_LIST = 'SET_PIECE_FOR_LIST';
export const SET_LOCATION_PIECE = 'SET_LOCATION_PIECE';
export const SET_MEMBERS_TYPE_IN_GROUP = 'SET_MEMBERS_TYPE_IN_GROUP';
export const SET_HEADING = 'SET_HEADING';
export const SET_COLUMNS = 'SET_COLUMNS';

export const SET_YEAR = 'SET_YEAR';
export const SET_MONTH = 'SET_MONTH';
export const SET_DATE = 'SET_DATE';

export enum PieceType {
    START = 'START',
    FOR = 'FOR',
    QUESTION = 'QUESTION',
    CHOOSE = 'CHOOSE',
    GROUPED_CHOOSE = 'GROUPED_CHOOSE',
    ANSWER = 'ANSWER',
    GROUPED_QUESTION = 'GROUPED_QUESTION',
    GROUPED_ANSWER = 'GROUPED_ANSWER',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    CHOICE = 'CHOICE',
    VARIABLE = 'VARIABLE',
    CUSTOM_FIELD = 'CUSTOM_FIELD',
    STORE = 'STORE',
    GET = 'GET',
    GET_VALUE = 'GET_VALUE',
    SET_VARIABLE = 'SET_VARIABLE',
    END = 'END',
    UPDATE_STATUS = 'UPDATE_STATUS',
    STATUS = 'STATUS',
    UPDATE_DUE_DATE = 'UPDATE_DUE_DATE',
    RETURN = 'RETURN',
    SPLIT = 'SPLIT',
    SHOW = 'SHOW',
    GROUPED_SHOW = 'GROUPED_SHOW',
    ADD = 'ADD',
    SUBTRACT = 'SUBTRACT',
    MULTIPLY = 'MULTIPLY',
    DIVIDE = 'DIVIDE',
    EXPONENT = 'EXPONENT',
    SEQUENCE = 'SEQUENCE',
    LESSER_THAN = 'LESSER_THAN',
    GREATER_THAN = 'GREATER_THAN',
    EQUAL_TO = 'EQUAL_TO',
    NOT_EQUAL_TO = 'NOT_EQUAL_TO',
    IS_DEFINED = 'IS_DEFINED',
    IN = 'IN',
    GROUP = 'GROUP',
    GROUP_FOR_LIST = 'GROUP_FOR_LIST',
    SECTION = 'SECTION',
    BREAK = 'BREAK',
    REPEAT = 'REPEAT',
    AND = 'AND',
    OR = 'OR',
    NOT = 'NOT',
    VARIABLE_TO_BOOLEAN = 'VARIABLE_TO_BOOLEAN',
    BOOLEAN_TO_VARIABLE = 'BOOLEAN_TO_VARIABLE',
    START_WORKFLOW = 'START_WORKFLOW',
    SWITCH_WORKFLOW = 'SWITCH_WORKFLOW',
    TRANSFER_WORKFLOW = 'TRANSFER_WORKFLOW',
    ADD_MONTHS = 'ADD_MONTHS',
    ADD_YEARS = 'ADD_YEARS',
    SUBTRACT_MONTHS = 'SUBTRACT_MONTHS',
    SUBTRACT_YEARS = 'SUBTRACT_YEARS',
    PICK_FIRST_ELEMENT = 'PICK_FIRST_ELEMENT',
    PICK_FIRST_N_ELEMENTS = 'PICK_FIRST_N_ELEMENTS',
    PICK_LAST_ELEMENT = 'PICK_LAST_ELEMENT',
    PICK_LAST_N_ELEMENTS = 'PICK_LAST_N_ELEMENTS',
    PICK_NTH_ELEMENT = 'PICK_NTH_ELEMENT',
    ADD_TO_LIST = 'ADD_TO_LIST',
    REMOVE_FROM_LIST = 'REMOVE_FROM_LIST',
    ADD_TO_TABLE = 'ADD_TO_TABLE',
    LENGTH = 'LENGTH',
    SET_MEMBERS_IN_GROUP = 'SET_MEMBERS_IN_GROUP',

    STRUCTURE = 'STRUCTURE',
    RESTRICT_NAVIGATION = 'RESTRICT_NAVIGATION',
    ARCHIVE = 'ARCHIVE',

    GET_DATE = 'GET_DATE',
    GET_DAY = 'GET_DAY',
    GET_MONTH = 'GET_MONTH',
    GET_READABLE_MONTH = 'GET_READABLE_MONTH',
    GET_YEAR = 'GET_YEAR',

    // Constants
    TODAY = 'TODAY',
    TRUE = 'TRUE',
    FALSE = 'FALSE',
    MY_GROUPS = 'MY_GROUPS',
    MY_MEMBERS = 'MY_MEMBERS',

    FINANCIAL_YEAR_MONTHS = 'FINANCIAL_YEAR_MONTHS',

    ADD_MEMBER = 'ADD_MEMBER',
    ADD_GROUP = 'ADD_GROUP',
    ADD_WORKFLOW = 'ADD_WORKFLOW',
    ADD_REPORT = 'ADD_REPORT',

    NEW_DATE = 'NEW_DATE',
};

export interface PiecePositionState {
    piece: string,
    position: Position,
}

export type NestingData = {
    value: string,
    type?: string,
}

export type IfData = {
    position?: Position,
    conditionPiece?: string, // ID for condition piece
    nextPiece?: string, // ID for next piece
}

export interface IPiece {
    id: string,
    type: PieceType,
    createdTime: string,
    lastUpdatedTime: string,
    lastSyncedTime?: string,
}

export interface IStartPiece extends IPiece {
    type: typeof PieceType.START,
    nextPiece?: string,  // ID of the next piece
}

export interface IForPiece extends IPiece {
    type: typeof PieceType.FOR,
    innerPiece?: string,  // ID of the inner piece
    nextPiece?: string,   // ID of the next piece
    loopVariable?: string,  // ID of the variable piece which is assigned the value each loop
    iterableVariable?: string,  // ID of the variable piece which is assigned to the iterable
}

export interface IGroupForMembersListPiece extends IPiece {
    type: typeof PieceType.GROUP_FOR_LIST,
    innerPiece?: string,  // ID of the inner piece
    nextPiece?: string,   // ID of the next piece
    loopVariable?: string,  // ID of the variable piece which is assigned the value each loop
    iterableVariable?: string,  // ID of the variable piece which is assigned to the iterable
}

export interface IGroupPiece extends IPiece {
    type: typeof PieceType.GROUP,
    innerPiece?: string,  // ID of the inner piece
    nextPiece?: string,   // ID of the next piece
}

export interface ISectionPiece extends IPiece {
    type: typeof PieceType.SECTION,
    innerPiece?: string,  // ID of the inner piece
    nextPiece?: string,   // ID of the next piece
    heading?: string,
    columns?: string,
}

export interface IQuestionPiece extends IPiece {
    type: typeof PieceType.QUESTION,
    nextPiece?: string,   // ID of the next piece
    innerPiece?: string,   // ID of the inner piece
    customFieldId?: string,  // ID of the custom field that will hold the answer
    memberVariablePiece?: string,  // ID of the member variable piece. The above value is stored for this member
    isRequiredPiece?: string,  // ID of the required variable piece.
    isDisabledPiece?: string,  // ID of the disabled variable piece.

    question?: string,  // ID of a piece which gives a string value, or a string itself

    placeholder?: string,
    default?: string,
}

export interface IGroupedQuestionPiece extends IPiece {
    type: typeof PieceType.GROUPED_QUESTION,
    nextPiece?: string,   // ID of the next piece
    innerPiece?: string,   // ID of the inner piece
    customFieldId?: string,  // ID of the custom field that will hold the answer
    memberVariablePiece?: string,  // ID of the member variable piece. The above value is stored for this member
    isRequiredPiece?: string,  // ID of the required variable piece.
    isDisabledPiece?: string,  // ID of the disabled variable piece.

    question?: string,  // ID of a piece which gives a string value, or a string itself

    placeholder?: string,
    default?: string,
}

export interface IChoosePiece extends IPiece {
    type: typeof PieceType.CHOOSE,
    nextPiece?: string,   // ID of the next piece
    innerPiece?: string,   // ID of the inner piece

    variablePiece?: string,
    choiceVariable?: string,

    isRequiredPiece?: string,  // ID of the required variable piece.
    isDisabledPiece?: string,  // ID of the disabled variable piece.

    question?: string,  // ID of a piece which gives a string value, or a string itself

    placeholder?: string,
    default?: string,
}

export interface IGroupedChoosePiece extends IPiece {
    type: typeof PieceType.GROUPED_CHOOSE,
    nextPiece?: string,   // ID of the next piece
    innerPiece?: string,   // ID of the inner piece

    variablePiece?: string,
    choiceVariable?: string,

    isRequiredPiece?: string,  // ID of the required variable piece.
    isDisabledPiece?: string,  // ID of the disabled variable piece.

    question?: string,  // ID of a piece which gives a string value, or a string itself

    placeholder?: string,
    default?: string,
}

export interface IChoicePiece extends IPiece {
    type: typeof PieceType.CHOICE,
    nextPiece?: string,  // ID of the next piece

    choice: string,
}

export interface ISplitPiece extends IPiece {
    type: typeof PieceType.SPLIT,
    nextPiece?: string,  // ID of the next piece
    ifPieceData?: Array<IfData>,
}

export interface IVariablePiece extends IPiece {
    type: typeof PieceType.VARIABLE,

    variable?: string,  // ID of the variable
    nesting?: Array<NestingData>,  // The nested list of properties in a variable. E.g. ["name"] or ["location", "name"] for a member variable
}

export interface ICustomFieldPiece extends IPiece {
    type: typeof PieceType.CUSTOM_FIELD,

    customField?: string,
    customFieldOption?: string,
    memberVariablePiece?: string,  // ID of the member variable piece. The above value is stored for this member
}

export interface IGroupedAnswerPiece extends IPiece {
    type: typeof PieceType.GROUPED_ANSWER,

    customField?: string,
    memberVariablePiece?: string,  // ID of the member variable piece. The above value is stored for this member
}

export interface IStatusPiece extends IPiece {
    type: typeof PieceType.STATUS,
    statusId?: string,
}

export interface IAddMemberPiece extends IPiece {
    type: typeof PieceType.ADD_MEMBER,
    nextPiece?: string,  // ID of the next piece

    variable?: string,
    locationPiece?: string,
    entityType?: string,
}

export interface IAddGroupPiece extends IPiece {
    type: typeof PieceType.ADD_GROUP,
    nextPiece?: string,  // ID of the next piece

    variable?: string,
    locationPiece?: string,
    entityType?: string,
}

export interface IAddWorkflowPiece extends IPiece {
    type: typeof PieceType.ADD_WORKFLOW,
    nextPiece?: string,  // ID of the next piece

    variable?: string,
    workflowType?: string,
    affiliationVariable?: string,
}

export interface IAddReportPiece extends IPiece {
    type: typeof PieceType.ADD_REPORT,
    nextPiece?: string,  // ID of the next piece

    variable?: string,
    name?: string,
    reportType?: string,
    user?: string,
    startDate?: string,
    endDate?: string,
}

export interface INewDatePiece extends IPiece {
    type: typeof PieceType.NEW_DATE,
    nextPiece?: string,  // ID of the next piece

    variablePiece?: string,
    yearVariablePiece?: string,
    monthVariablePiece?: string,
    dateVariablePiece?: string,
}

export interface IStorePiece extends IPiece {
    type: typeof PieceType.STORE,
    nextPiece?: string,   // ID of the next piece

    variablePiece?: string,  // ID of the variable piece
    entityType?: string,
    dataToStore?: string,  // ID of the piece the value of which is being stored, or the data itself
    memberVariablePiece?: string,  // ID of the member variable piece. The above value is stored for this member
    customFieldId?: string,  // This can only be the ID of a custom field. The variable's type and the custom field's type must match
}

export interface IGetPiece extends IPiece {
    type: typeof PieceType.GET,
    nextPiece?: string,   // ID of the next piece
    
    variablePiece?: string,  // ID of the variable piece
    entityType?: string,
    variableToCopy?: string,  // ID of the variable piece to which the data is copied
    memberVariablePiece?: string,  // ID of the member variable piece. The value is copied to the above variable for this member
    customFieldId?: string,  // This can only be the ID of a custom field. The variable's type and the custom field's type must match
}

export interface IGetValuePiece extends IPiece {
    type: typeof PieceType.GET_VALUE,
    nextPiece?: string,   // ID of the next piece
    
    variablePiece?: string,  // ID of the variable piece
    entityType?: string,
    memberVariablePiece?: string,  // ID of the member variable piece. The value is copied to the above variable for this member
    customFieldId?: string,  // This can only be the ID of a custom field. The variable's type and the custom field's type must match
}

export interface IShowPiece extends IPiece {
    type: typeof PieceType.SHOW,
    nextPiece?: string,  // ID of the next piece

    entityType?: string,
    variableToShow?: string,  // ID of the variable piece
    customFieldIds?: Array<string>,  // Array of IDs of associated custom fields
}

export interface IGroupedShowPiece extends IPiece {
    type: typeof PieceType.GROUPED_SHOW,
    nextPiece?: string,  // ID of the next piece

    entityType?: string,
    variableToShow?: string,  // ID of the variable piece
    customFieldIds?: Array<string>,  // Array of IDs of associated custom fields
}

export interface ISetVariablePiece extends IPiece {
    type: typeof PieceType.SET_VARIABLE,
    nextPiece?: string,  // ID of the next piece

    variableToSet?: string,  // ID of the variable to set
    dataToSet?: string,  // ID of either the variable piece or the data itself
}

export interface IEndPiece extends IPiece {
    type: typeof PieceType.END,
    status?: string,  // ID of the status that the workflow should have after the end piece is computed
    message?: string,  // ID of either the variable piece or the message itself
}

export interface IUpdateStatusPiece extends IPiece {
    type: typeof PieceType.UPDATE_STATUS,
    nextPiece?: string,  // ID of the next piece
    
    status?: string,  // ID of the status that the workflow should have after the end piece is computed
}

export interface IUpdateDueDatePiece extends IPiece {
    type: typeof PieceType.UPDATE_DUE_DATE,
    nextPiece?: string,  // ID of the next piece
    
    dueDate?: string,  // ID of the status that the workflow should have after the end piece is computed
}

export interface ITransferPiece extends IPiece {
    type: typeof PieceType.TRANSFER_WORKFLOW,
    nextPiece?: string,  // ID of the next piece
    
    roles?: Array<string>,  // ID of the status that the workflow should have after the end piece is computed
}

export interface IReturnPiece extends IPiece {
    type: typeof PieceType.RETURN,
    returnValue?: string,  // ID of the variable piece
}

export interface IErrorPiece extends IPiece {
    type: typeof PieceType.ERROR,
    error?: string,
}

export interface ISuccessPiece extends IPiece {
    type: typeof PieceType.SUCCESS,
    error?: string,
}

export interface IRestrictNavigationPiece extends IPiece {
    type: typeof PieceType.RESTRICT_NAVIGATION,
    nextPiece?: string,  // ID of the next piece
}

export interface IArchivePiece extends IPiece {
    type: typeof PieceType.ARCHIVE,
    nextPiece?: string,  // ID of the next piece
    variablePiece: string|undefined,
}

export interface IBreakPiece extends IPiece {
    type: typeof PieceType.BREAK,
}

export interface IRepeatPiece extends IPiece {
    type: typeof PieceType.REPEAT,
    nextPiece?: string,  // ID of the next piece
}



export interface IAddPiece extends IPiece {
    type: typeof PieceType.ADD,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
    variableType?: VariableType,
}

export interface ISubtractPiece extends IPiece {
    type: typeof PieceType.SUBTRACT,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
    variableType?: VariableType,
}

export interface IMultiplyPiece extends IPiece {
    type: typeof PieceType.MULTIPLY,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
    variableType?: VariableType,
}

export interface IDividePiece extends IPiece {
    type: typeof PieceType.DIVIDE,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
    variableType?: VariableType,
}

export interface IExponentPiece extends IPiece {
    type: typeof PieceType.EXPONENT,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
    variableType?: VariableType,
}

export interface ISequencePiece extends IPiece {
    type: typeof PieceType.SEQUENCE,
    selectedOptions?: Array<string>,
}

export interface ILesserThanPiece extends IPiece {
    type: typeof PieceType.LESSER_THAN,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IGreaterThanPiece extends IPiece {
    type: typeof PieceType.GREATER_THAN,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IEqualToPiece extends IPiece {
    type: typeof PieceType.EQUAL_TO,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface INotEqualToPiece extends IPiece {
    type: typeof PieceType.NOT_EQUAL_TO,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IIsDefinedPiece extends IPiece {
    type: typeof PieceType.IS_DEFINED,
    operand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IInPiece extends IPiece {
    type: typeof PieceType.IN,
    leftOperand?: string,
    rightOperand?: string,
}

export interface IAndPiece extends IPiece {
    type: typeof PieceType.AND,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IOrPiece extends IPiece {
    type: typeof PieceType.OR,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface INotPiece extends IPiece {
    type: typeof PieceType.NOT,
    operand?: string,  // ID of the variable piece
}

export interface IAddMonthsPiece extends IPiece {
    type: typeof PieceType.ADD_MONTHS,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IAddYearsPiece extends IPiece {
    type: typeof PieceType.ADD_YEARS,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface ISubtractMonthsPiece extends IPiece {
    type: typeof PieceType.SUBTRACT_MONTHS,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface ISubtractYearsPiece extends IPiece {
    type: typeof PieceType.SUBTRACT_YEARS,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IGetDatePiece extends IPiece {
    type: typeof PieceType.GET_DATE,
    operand?: string,
}

export interface IGetDayPiece extends IPiece {
    type: typeof PieceType.GET_DAY,
    operand?: string,
}

export interface IGetMonthPiece extends IPiece {
    type: typeof PieceType.GET_MONTH,
    operand?: string,
}

export interface IGetReadableMonthPiece extends IPiece {
    type: typeof PieceType.GET_READABLE_MONTH,
    operand?: string,
}

export interface IGetYearPiece extends IPiece {
    type: typeof PieceType.GET_YEAR,
    operand?: string,
}

export interface IPickFirstElementPiece extends IPiece {
    type: typeof PieceType.PICK_FIRST_ELEMENT,
    operand?: string,
}

export interface IPickFirstNElementsPiece extends IPiece {
    type: typeof PieceType.PICK_FIRST_N_ELEMENTS,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IPickLastElementPiece extends IPiece {
    type: typeof PieceType.PICK_LAST_ELEMENT,
    operand?: string,
}

export interface IPickLastNElementsPiece extends IPiece {
    type: typeof PieceType.PICK_LAST_N_ELEMENTS,
    leftOperand?: string,  // Either a piece ID, or a string value to put in the input field
    rightOperand?: string,  // Either a piece ID, or a string value to put in the input field
}

export interface IPickNthElementPiece extends IPiece {
    type: typeof PieceType.PICK_NTH_ELEMENT,
    leftOperand?: string,
    rightOperand?: string,
}

export interface ILengthPiece extends IPiece {
    type: typeof PieceType.LENGTH,
    operand?: string,
}

export interface IAddToTablePiece extends IPiece {
    type: typeof PieceType.ADD_TO_TABLE,
    dataToSet?: string,  // Piece ID of the piece in the input field
    listVariable?: string,  // ID of the list variable to which the value will be added
    nextPiece?: string,
}

export interface IAddToListPiece extends IPiece {
    type: typeof PieceType.ADD_TO_LIST,
    dataToSet?: string,  // Either a piece ID, or a string value to put in the input field
    listVariable?: string,  // ID of the list variable to which the value will be added
    nextPiece?: string,
}

export interface IRemoveFromListPiece extends IPiece {
    type: typeof PieceType.REMOVE_FROM_LIST,
    dataToSet?: string,  // Either a piece ID, or a string value to put in the input field
    listVariable?: string,  // ID of the list variable to which the value will be added
    nextPiece?: string,
}

export interface IVariableToBooleanPiece extends IPiece {
    type: typeof PieceType.VARIABLE_TO_BOOLEAN,
    operand?: string,  // ID of the variable piece
}

export interface IBooleanToVariablePiece extends IPiece {
    type: typeof PieceType.BOOLEAN_TO_VARIABLE,
    operand?: string,  // ID of the variable piece
}


export interface IStartWorkflowPiece extends IPiece {
    type: typeof PieceType.START_WORKFLOW,
    workflowType?: string,
    workflowStatus?: string,
    workflowDueDateVariable?: string,
    affiliationVariable?: string,
    isAsync?: boolean,
    nextPiece?: string,
}

export interface ISwitchWorkflowPiece extends IPiece {
    type: typeof PieceType.SWITCH_WORKFLOW,
    variablePiece?: string,
    nextPiece?: string,
}

export interface ISetMembersToGroupPiece extends IPiece {
    type: typeof PieceType.SET_MEMBERS_IN_GROUP,
    variablePiece?: string,
    iterableVariable?: string,
    memberType?: 'representatives'|'all_members',
    nextPiece?: string,
}


// Constants
export interface ITodayPiece extends IPiece {
    type: PieceType.TODAY,
}
export interface ITruePiece extends IPiece {
    type: PieceType.TRUE,
}

export interface IFalsePiece extends IPiece {
    type: PieceType.FALSE,
}

export interface IMyGroupsPiece extends IPiece {
    type: PieceType.MY_GROUPS,
}

export interface IMyMembersPiece extends IPiece {
    type: PieceType.MY_MEMBERS,
}

export interface IFinancialYearMonthsPiece extends IPiece {
    type: PieceType.FINANCIAL_YEAR_MONTHS,
}

export interface IAnswerPiece extends IPiece {
    type: PieceType.ANSWER,
}

export interface IStructurePiece extends IPiece {
    type: PieceType.STRUCTURE,
    project: string|undefined,
    level: string|undefined,
    role: string|undefined,
}


interface UpdatePieceAction {
    updateTime: string,
}


export interface AddPieceAction {
    type: typeof ADD_PIECE,
    pieceId: string,
    pieceType: PieceType,
    createdTime: string,
}

export interface AddFullPieceAction {
    type: typeof ADD_FULL_PIECE,
    pieceData: AllPieceTypes,
}

export interface SetNextPieceAction extends UpdatePieceAction {
    type: typeof SET_NEXT_PIECE,
    pieceId: string,
    value: string|undefined,
}

export interface SetInnerPieceAction extends UpdatePieceAction {
    type: typeof SET_INNER_PIECE,
    pieceId: string,
    value: string|undefined,
}

export interface SetConditionPiecesAction extends UpdatePieceAction {
    type: typeof SET_CONDITION_PIECES,
    pieceId: string,
    value: Array<IfData>,
}

export interface SetConditionPieceAction extends UpdatePieceAction {
    type: typeof SET_CONDITION_PIECE,
    pieceId: string,
    ifIndex: number,
    value: string|undefined,
}

export interface SetConditionPiecePositionAction extends UpdatePieceAction {
    type: typeof SET_CONDITION_PIECE_POSITION,
    pieceId: string,
    ifIndex: number,
    x: number,
    y: number,
}

export interface SetConditionNextPieceAction extends UpdatePieceAction {
    type: typeof SET_CONDITION_NEXT_PIECE,
    pieceId: string,
    ifIndex: number,
    value: string|undefined,
}

export interface SetLoopVariable extends UpdatePieceAction {
    type: typeof SET_LOOP_VARIABLE,
    pieceId: string,
    value: string|undefined,
}

export interface SetIterableVariable extends UpdatePieceAction {
    type: typeof SET_ITERABLE_VARIABLE,
    pieceId: string,
    value: string|undefined,
}

export interface SetUpdateStatusValue extends UpdatePieceAction {
    type: typeof SET_UPDATE_STATUS_VALUE,
    pieceId: string,
    value: string|undefined,
}

export interface SetUpdateDueDateValue extends UpdatePieceAction {
    type: typeof SET_UPDATE_DUE_DATE_VALUE,
    pieceId: string,
    value: string|undefined,
}

export interface SetOperand extends UpdatePieceAction {
    type: typeof SET_OPERAND,
    pieceId: string,
    value: string|undefined,
}

export interface SetSelectedOptions extends UpdatePieceAction {
    type: typeof SET_SELECTED_OPTIONS,
    pieceId: string,
    value: Array<string>,
}

export interface SetLeftOperand extends UpdatePieceAction {
    type: typeof SET_LEFT_OPERAND,
    pieceId: string,
    value: string|undefined,
}

export interface SetRightOperand extends UpdatePieceAction {
    type: typeof SET_RIGHT_OPERAND,
    pieceId: string,
    value: string|undefined,
}

export interface SetQuestionData extends UpdatePieceAction {
    type: typeof SET_QUESTION_DATA,
    pieceId: string,
    value: string|undefined,
}

export interface SetQuestionChoiceList extends UpdatePieceAction {
    type: typeof SET_QUESTION_CHOICE_LIST,
    pieceId: string,
    value: string|undefined,
}

export interface SetQuestionChoiceVariable extends UpdatePieceAction {
    type: typeof SET_QUESTION_CHOICE_VARIABLE,
    pieceId: string,
    value: string|undefined,
}

export interface SetQuestionRequiredPiece extends UpdatePieceAction {
    type: typeof SET_QUESTION_REQUIRED_PIECE,
    pieceId: string,
    value: string|undefined,
}

export interface SetQuestionDisabledPiece extends UpdatePieceAction {
    type: typeof SET_QUESTION_DISABLED_PIECE,
    pieceId: string,
    value: string|undefined,
}

export interface SetQuestionDefaultPiece extends UpdatePieceAction {
    type: typeof SET_QUESTION_DEFAULT_PIECE,
    pieceId: string,
    value: string|undefined,
}

export interface SetMemberVariable extends UpdatePieceAction {
    type: typeof SET_MEMBER_VARIABLE,
    pieceId: string,
    value: string|undefined,
}

export interface SetDataStoreValue extends UpdatePieceAction {
    type: typeof SET_DATA_STORE_VALUE,
    pieceId: string,
    value: string|undefined,
}

export interface SetDataSetValue extends UpdatePieceAction {
    type: typeof SET_DATA_SET_VALUE,
    pieceId: string,
    value: string|undefined,
}

export interface SetVariableForSet extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_SET,
    pieceId: string,
    value: string|undefined,
}

export interface SetVariableForCustomField extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_CUSTOM_FIELD,
    pieceId: string,
    value: string|undefined,
}

export interface SetDataCopyVariable extends UpdatePieceAction {
    type: typeof SET_DATA_COPY_VARIABLE,
    pieceId: string,
    value: string|undefined,
}

export interface SetReturnVariable extends UpdatePieceAction {
    type: typeof SET_RETURN_VALUE,
    pieceId: string,
    value: string|undefined,
}

export interface SetError extends UpdatePieceAction {
    type: typeof SET_ERROR,
    pieceId: string,
    error: string|undefined,
}

export interface SetVariableForAddMember extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_ADD_MEMBER,
    pieceId: string,
    variable: string|undefined,
}

export interface SetVariableForAddGroup extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_ADD_GROUP,
    pieceId: string,
    variable: string|undefined,
}

export interface SetVariableForAddWorkflow extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_ADD_WORKFLOW,
    pieceId: string,
    variable: string|undefined,
}

export interface SetVariableForAddReport extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_ADD_REPORT,
    pieceId: string,
    variable: string|undefined,
}

export interface SetPieceForList extends UpdatePieceAction {
    type: typeof SET_PIECE_FOR_LIST,
    pieceId: string,
    dataToSet: string|undefined,
}

export interface SetVariableForList extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_LIST,
    pieceId: string,
    variable: string|undefined,
}

export interface SetVariableForDate extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_NEW_DATE,
    pieceId: string,
    value: string|undefined,
}

export interface SetVariablePieceAction extends UpdatePieceAction {
    type: typeof SET_VARIABLE_PIECE,
    pieceId: string,
    variablePiece: string|undefined,
}

export interface SetLocationPiece extends UpdatePieceAction {
    type: typeof SET_LOCATION_PIECE,
    pieceId: string,
    location: string|undefined,
}

export interface SetCustomField extends UpdatePieceAction {
    type: typeof SET_CUSTOM_FIELD,
    pieceId: string,
    value: string|undefined,
}

export interface SetVariableForShow extends UpdatePieceAction {
    type: typeof SET_VARIABLE_FOR_SHOW,
    pieceId: string,
    value: string|undefined,
}

export interface SetEntityType extends UpdatePieceAction {
    type: typeof SET_ENTITY_TYPE,
    pieceId: string,
    value: string|undefined,
}

export interface SetCustomFieldsForShow extends UpdatePieceAction {
    type: typeof SET_CUSTOM_FIELDS_FOR_SHOW,
    pieceId: string,
    value: Array<string>|undefined,
}

export interface SetMessageAction extends UpdatePieceAction {
    type: typeof SET_MESSAGE,
    pieceId: string,
    message: string|undefined,
}

export interface IStartDragAction {
    type: typeof START_PIECE_DRAG,
    pieceId: string,
}

export interface IStopDragAction {
    type: typeof STOP_PIECE_DRAG,
}

export interface ISetTargetPieceAction {
    type: typeof SET_TARGET_PIECE,
    pieceId: string|undefined,
}

export interface IDeletePieceAction extends UpdatePieceAction {
    type: typeof DELETE_PIECE,
    pieceId: string,
}

export interface IUpdateVariablePieceAction extends UpdatePieceAction {
    type: typeof UPDATE_VARIABLE_PIECE,
    pieceId: string,
    variable: string|undefined,
    nesting: Array<NestingData>|undefined,
}

export interface IUpdateCustomFieldPieceAction extends UpdatePieceAction {
    type: typeof UPDATE_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customField: string|undefined,
    customFieldOption: string|undefined,
}

export interface IUpdateAnswerPieceAction extends UpdatePieceAction {
    type: typeof UPDATE_ANSWER_PIECE,
    pieceId: string,
    customField: string|undefined,
}

export interface IUpdateStatusPieceAction extends UpdatePieceAction {
    type: typeof UPDATE_STATUS_PIECE,
    pieceId: string,
    statusId: string|undefined,
}

export interface ISetStartWorkflowTypeAction extends UpdatePieceAction {
    type: typeof SET_WORKFLOW_TYPE,
    pieceId: string,
    workflowType: string,
}

export interface ISetStartWorkflowStatusAction extends UpdatePieceAction {
    type: typeof SET_WORKFLOW_STATUS,
    pieceId: string,
    status: string,
}

export interface ISetStartWorkflowDueDateAction extends UpdatePieceAction {
    type: typeof SET_WORKFLOW_DUE_DATE,
    pieceId: string,
    dueDate: string,
}

export interface ISetStartWorkflowAffiliationVariableAction extends UpdatePieceAction {
    type: typeof SET_WORKFLOW_AFFILIATION_VARIABLE,
    pieceId: string,
    affiliationVariable: string|undefined,
}

export interface ISetStartWorkflowIsAsyncAction extends UpdatePieceAction {
    type: typeof SET_WORKFLOW_IS_ASYNC,
    pieceId: string,
    isAsync: boolean,
}

export interface ISetReportNameAction extends UpdatePieceAction {
    type: typeof SET_REPORT_NAME,
    pieceId: string,
    name: string|undefined,
}

export interface ISetReportTypeAction extends UpdatePieceAction {
    type: typeof SET_REPORT_TYPE,
    pieceId: string,
    reportType: string|undefined,
}

export interface ISetReportUserAction extends UpdatePieceAction {
    type: typeof SET_REPORT_USER,
    pieceId: string,
    user: string|undefined,
}

export interface ISetReportStartDateAction extends UpdatePieceAction {
    type: typeof SET_REPORT_START_DATE,
    pieceId: string,
    startDate: string|undefined,
}

export interface ISetReportEndDateAction extends UpdatePieceAction {
    type: typeof SET_REPORT_END_DATE,
    pieceId: string,
    endDate: string|undefined,
}

export interface ISetStructureProjectAction extends UpdatePieceAction {
    type: typeof SET_STRUCTURE_PROJECT,
    pieceId: string,
    project: string|undefined,
}

export interface ISetStructureLevelAction extends UpdatePieceAction {
    type: typeof SET_STRUCTURE_LEVEL,
    pieceId: string,
    level: string|undefined,
}

export interface ISetStructureRoleAction extends UpdatePieceAction {
    type: typeof SET_STRUCTURE_ROLE,
    pieceId: string,
    role: string|undefined,
}

export interface ISetTransferRolesAction extends UpdatePieceAction {
    type: typeof SET_TRANSFER_ROLES,
    pieceId: string,
    roles: Array<string>,
}

export interface ISetMemberTypeToGroupAction extends UpdatePieceAction {
    type: typeof SET_MEMBERS_TYPE_IN_GROUP,
    pieceId: string,
    value: 'representatives'|'all_members',
}

export interface ISetHeadingAction extends UpdatePieceAction {
    type: typeof SET_HEADING,
    pieceId: string,
    value: string|undefined,
}

export interface ISetColumnsAction extends UpdatePieceAction {
    type: typeof SET_COLUMNS,
    pieceId: string,
    value: string|undefined,
}

export interface ISetYearAction extends UpdatePieceAction {
    type: typeof SET_YEAR,
    pieceId: string,
    value: string|undefined,
}

export interface ISetMonthAction extends UpdatePieceAction {
    type: typeof SET_MONTH,
    pieceId: string,
    value: string|undefined,
}

export interface ISetDateAction extends UpdatePieceAction {
    type: typeof SET_DATE,
    pieceId: string,
    value: string|undefined,
}

export type FlowchartPieceActions = {
    setNextPiece: (pieceId: string, value: string|undefined) => void,
    setInnerPiece: (pieceId: string, value: string|undefined) => void,
    setConditionPiece: (pieceId: string, ifIndex: number, value: string|undefined) => void,
    setConditionNextPiece: (pieceId: string, ifIndex: number, value: string|undefined) => void,
    setLoopVariable: (pieceId: string, value: string|undefined) => void,
    setIterableVariable: (pieceId: string, value: string|undefined) => void,
    setOperand: (pieceId: string, value: string|undefined) => void,
    setLeftOperand: (pieceId: string, value: string|undefined) => void,
    setRightOperand: (pieceId: string, value: string|undefined) => void,
    setQuestionData: (pieceId: string, value: string|undefined) => void,
    setMemberVariable: (pieceId: string, value: string|undefined) => void,
    setRequiredPiece: (pieceId: string, value: string|undefined) => void,
    setDisabledPiece: (pieceId: string, value: string|undefined) => void,
    setDefaultPiece: (pieceId: string, value: string|undefined) => void,
    setDataStoreValue: (pieceId: string, value: string|undefined) => void,
    setDataSetValue: (pieceId: string, value: string|undefined) => void,
    setDataForList: (pieceId: string, value: string|undefined) => void,
    setDataCopyVariable: (pieceId: string, value: string|undefined) => void,
    setReturnVariable: (pieceId: string, value: string|undefined) => void,
    setVariableForShow: (pieceId: string, value: string|undefined) => void,
    setLocationPiece: (pieceId: string, value: string|undefined) => void,
    setVariableForCustomField: (pieceId: string, value: string|undefined) => void,
    setVariablePiece: (pieceId: string, value: string|undefined) => void,
    setYearPiece: (pieceId: string, value: string|undefined) => void,
    setMonthPiece: (pieceId: string, value: string|undefined) => void,
    setDatePiece: (pieceId: string, value: string|undefined) => void,
    setAffiliationVariablePiece: (pieceId: string, value: string|undefined) => void,
    setHeadingPiece: (pieceId: string, value: string|undefined) => void,
    setMessage: (pieceId: string, value: string|undefined) => void,

    setStartPieceData: (affiliatedId: string, payload: PiecePositionState) => void,
};

export type BinaryOperatorPieces = IAddPiece | ISubtractPiece | IMultiplyPiece | IDividePiece | IExponentPiece | ILesserThanPiece | IGreaterThanPiece | IEqualToPiece | INotEqualToPiece | IIsDefinedPiece | IPickFirstNElementsPiece | IPickLastNElementsPiece | IPickNthElementPiece | IInPiece | IAndPiece | IOrPiece | IAddMonthsPiece | IAddYearsPiece | ISubtractMonthsPiece | ISubtractYearsPiece;
export type UnaryOperatorPieces = IVariableToBooleanPiece | IBooleanToVariablePiece | INotPiece | IPickFirstElementPiece | IPickLastElementPiece | ILengthPiece | IGetDatePiece | IGetDayPiece | IGetMonthPiece | IGetReadableMonthPiece | IGetYearPiece;
export type ConstantPieceTypes = ITodayPiece | ITruePiece | IFalsePiece | IMyGroupsPiece | IMyMembersPiece | IFinancialYearMonthsPiece | IAnswerPiece;

export type AllPieceTypes = IStartPiece | IForPiece | IGroupPiece | IGroupForMembersListPiece | IQuestionPiece | IGroupedQuestionPiece | IChoosePiece | IGroupedChoosePiece | IChoicePiece | IVariablePiece | ICustomFieldPiece | IGroupedAnswerPiece | IStatusPiece | ISetVariablePiece | IGetPiece | IGetValuePiece | IStorePiece | IShowPiece | IGroupedShowPiece | ISplitPiece | IEndPiece | IUpdateStatusPiece | IUpdateDueDatePiece | IReturnPiece | IErrorPiece | ISuccessPiece | IBreakPiece | IRepeatPiece | BinaryOperatorPieces | UnaryOperatorPieces | ISequencePiece | IStartWorkflowPiece | ISwitchWorkflowPiece | ITransferPiece | ConstantPieceTypes | IAddMemberPiece | IAddGroupPiece | IAddWorkflowPiece | IAddReportPiece | IAddToListPiece | IRemoveFromListPiece | IAddToTablePiece | ISetMembersToGroupPiece | ISectionPiece | IStructurePiece | INewDatePiece | IRestrictNavigationPiece | IArchivePiece;

export type PieceActionTypes = AddPieceAction | AddFullPieceAction | SetNextPieceAction | SetInnerPieceAction | SetConditionPiecesAction | SetConditionPieceAction | SetConditionPiecePositionAction | SetConditionNextPieceAction | SetLoopVariable | SetIterableVariable | SetUpdateStatusValue | SetUpdateDueDateValue | SetSelectedOptions | SetOperand | SetLeftOperand | SetRightOperand | SetQuestionData | SetQuestionChoiceList | SetQuestionChoiceVariable | SetQuestionRequiredPiece | SetQuestionDisabledPiece | SetQuestionDefaultPiece | SetMemberVariable | SetDataStoreValue | SetDataSetValue | SetVariableForSet | SetVariableForCustomField | SetDataCopyVariable | SetReturnVariable | SetError | SetVariableForAddMember | SetVariableForAddGroup | SetVariableForAddWorkflow | SetVariableForAddReport | SetLocationPiece | SetCustomField | SetVariableForShow | SetEntityType | SetCustomFieldsForShow | IStartDragAction | IStopDragAction | ISetTargetPieceAction | IDeletePieceAction | IUpdateVariablePieceAction | IUpdateCustomFieldPieceAction | IUpdateAnswerPieceAction | IUpdateStatusPieceAction | ISetStartWorkflowTypeAction | ISetStartWorkflowStatusAction | ISetStartWorkflowDueDateAction | ISetStartWorkflowAffiliationVariableAction | ISetStartWorkflowIsAsyncAction | ISetReportNameAction | ISetReportTypeAction | ISetReportUserAction | ISetReportStartDateAction | ISetReportEndDateAction | ISetStructureProjectAction | ISetStructureLevelAction | ISetStructureRoleAction | ISetTransferRolesAction | SetPieceForList | SetVariableForList | SetVariableForDate | ISetMemberTypeToGroupAction | SetVariablePieceAction | ISetHeadingAction | ISetColumnsAction | ISetYearAction | ISetMonthAction | ISetDateAction | SetMessageAction;

export interface PieceState extends NormalizedModel<AllPieceTypes> {
    isDragging: boolean,
    lastDraggedPiece: string|undefined,
    targetPiece: string|undefined,
}