import { put, takeEvery, select, all } from 'redux-saga/effects'
import { AddWorkflowAction, ADD_WORKFLOW, UPDATE_WORKFLOW } from './types';
import { AddWorkflowTypeAction, AddWorkflowTypeCustomFieldAction, ADD_WORKFLOW_TYPE_CUSTOM_FIELD } from './types/types';
import { addPiece } from '../flowchart/pieces/actions';
import { PieceType } from '../flowchart/pieces/types';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { registerWorkflowTypeVariable, registerWorkflowTypeCustomFieldVariable, updateWorkflowTypeCustomFieldStartPiece } from './types/actions';
import { ApplicationState } from '../main';

import { updateMemberWorkflows } from '../members/actions';
import { updateGroupWorkflows } from '../groups/actions';
import { ADD_WORKFLOW_TYPE } from './types/types';
import uuid from 'uuid';
import { updateUserWorkflows } from '../users/actions';

function* createSeedFlowchartForWorkflowType(action: AddWorkflowTypeAction) {

    let allPutActions = [];

    allPutActions.push(put(addVariable({
        id: action.payload.seedEntityVariable,
        name: 'Workflow',
        type: VariableType.WORKFLOW,
    })));

    yield put(registerWorkflowTypeVariable(action.payload.seedEntityVariable, action.payload.id));

    if (action.payload.affiliation === 'member') {

        allPutActions.push(put(addVariable({
            id: action.payload.seedAffiliationVariable,
            name: 'Member',
            type: VariableType.MEMBER,
        })));

    } else if (action.payload.affiliation === 'group') {

        allPutActions.push(put(addVariable({
            id: action.payload.seedAffiliationVariable,
            name: 'Group',
            type: VariableType.GROUP,
        })));

    } else if (action.payload.affiliation === 'none') {

        allPutActions.push(put(addVariable({
            id: action.payload.seedAffiliationVariable,
            name: 'User',
            type: VariableType.USER,
        })));

    }

    allPutActions.push(put(addPiece(action.payload.startPiece.piece, PieceType.START)));
    
    yield all(allPutActions);
    yield put(registerWorkflowTypeVariable(action.payload.seedAffiliationVariable, action.payload.id));
}

function* createSeedFlowchartForWorkflowTypeCustomField(action: AddWorkflowTypeCustomFieldAction) {

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        if (!action.payload.seedAffiliationVariable) {
            throw new Error('Computed fields need to have the seed affiliation variable ID defined');
        }

        yield put(addVariable({
            id: action.payload.seedEntityVariable,
            name: 'Workflow',
            type: VariableType.WORKFLOW,
        }));
    
        yield put(registerWorkflowTypeCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id));

        if (action.payload.affiliation === 'member') {
    
            yield put(addVariable({
                id: action.payload.seedAffiliationVariable,
                name: 'Member',
                type: VariableType.MEMBER,
            }));
    
        } else if (action.payload.affiliation === 'group') {
    
            yield put(addVariable({
                id: action.payload.seedAffiliationVariable,
                name: 'Group',
                type: VariableType.GROUP,
            }));
    
        }
    
        yield put(registerWorkflowTypeCustomFieldVariable(action.payload.seedAffiliationVariable, action.payload.id));
    
        const startPieceId = uuid.v4();
    
        yield put(addPiece(startPieceId, PieceType.START));
    
        yield put(updateWorkflowTypeCustomFieldStartPiece({
            piece: startPieceId,
            position: {
                x: 0,
                y: 0,
            }
        }, action.payload.id));

    }
}

function* provideReverseLinkForAffiliation(action: AddWorkflowAction) {
    const state: ApplicationState = yield select();
    const workflowType = state.workflows.types.byId[action.payload.type];

    yield(put(updateUserWorkflows(action.payload.user, action.payload.type, action.payload.id)));

    if (workflowType.affiliation === 'member') {
        const member = state.members.byId[action.payload.affiliatedEntity];
        yield(put(updateMemberWorkflows(member.id, workflowType.id, action.payload.id)));
    } else if (workflowType.affiliation === 'group') {
        const group = state.groups.byId[action.payload.affiliatedEntity];
        yield(put(updateGroupWorkflows(group.id, workflowType.id, action.payload.id)));
    }
}

export function* watchWorkflowChanges() {
    yield takeEvery(ADD_WORKFLOW, provideReverseLinkForAffiliation);
    yield takeEvery(UPDATE_WORKFLOW, provideReverseLinkForAffiliation);
}

export function* watchWorkflowTypeCreation() {
    yield takeEvery(ADD_WORKFLOW_TYPE, createSeedFlowchartForWorkflowType);
}

export function* watchWorkflowTypeCustomFieldCreation() {
    yield takeEvery(ADD_WORKFLOW_TYPE_CUSTOM_FIELD, createSeedFlowchartForWorkflowTypeCustomField);
}