import { put, call, takeLatest, select, delay } from 'redux-saga/effects'
import { FETCH_DATA_REQUEST } from './types';
import axios from 'axios';
import { ApplicationState } from './main';

import { UPDATE_INTERNATIONALIZATION_DATA } from './internationalization/types';
import { UPDATE_STRUCTURE_DATA } from './structure/types';
import { UPDATE_FLOWCHART_DATA } from './flowchart/types';
import { UPDATE_USER_DATA } from './users/types';
import { UPDATE_MY_DATA } from './my-data/types';
import { UPDATE_MEMBER_DATA } from './members/types';
import { UPDATE_WORKFLOW_DATA } from './workflows/types';
import { UPDATE_WIDGET_DATA } from './widgets/types';
import { UPDATE_PERMISSION_DATA } from './permissions/types';
import { AnyAction } from 'redux';
import { completeDataPush } from './my-data/actions';
import { START_DATA_PUSH } from './my-data/types';
import { UPDATE_REPORT_DATA } from './reports/types';
import { collectDataToPush } from '../helpers/synchronize';
import { UPDATE_GROUP_DATA } from './groups/types';


function fetchAllData() {

    const serverUrl = window.location.hostname === 'localhost' ? 'http://localhost:8000/org-data/' : 'https://backend.alpha.oaasapp.org/org-data/';

    return axios.get(serverUrl, {
        headers: {
            Authorization: 'Token ' + localStorage.getItem('token')
        }
    });
}

function pushAllData(applicationState: ApplicationState) {

    if (applicationState.myData.isLoaded) {

        const serverUrl = window.location.hostname === 'localhost' ? 'http://localhost:8000/org-data/' : 'https://backend.alpha.oaasapp.org/org-data/';
    
        collectDataToPush(applicationState);
    
        return axios.post(serverUrl, applicationState, {
            headers: {
                Authorization: 'Token ' + localStorage.getItem('token')
            }
        });

    }

}

function isObjectEmpty(object: any) {
    if (object === null || typeof object === 'undefined') {
        return true;
    }

    return Object.entries(object).length === 0 && object.constructor === Object
}

function* fetchDataFromServer() {
    try {
        const responseData = yield call(fetchAllData);
        const appData: ApplicationState = responseData.data as ApplicationState;  // Coerce the data to be in the expected format

        if (!isObjectEmpty(appData.internationalization)) {
            yield put({
                type: UPDATE_INTERNATIONALIZATION_DATA,
                data: appData.internationalization,
            });
        }

        if (!isObjectEmpty(appData.structure)) {
            yield put({
                type: UPDATE_STRUCTURE_DATA,
                data: appData.structure,
            });
        }

        if (!isObjectEmpty(appData.flowchart)) {
            yield put({
                type: UPDATE_FLOWCHART_DATA,
                data: appData.flowchart,
            });
        }

        if (!isObjectEmpty(appData.users)) {
            yield put({
                type: UPDATE_USER_DATA,
                data: appData.users,
            });
        }

        if (!isObjectEmpty(appData.members)) {
            yield put({
                type: UPDATE_MEMBER_DATA,
                data: appData.members,
            });
        }

        if (!isObjectEmpty(appData.groups)) {
            yield put({
                type: UPDATE_GROUP_DATA,
                data: appData.groups,
            });
        }

        if (!isObjectEmpty(appData.workflows)) {
            yield put({
                type: UPDATE_WORKFLOW_DATA,
                data: appData.workflows,
            });
        }

        if (!isObjectEmpty(appData.widgets)) {
            yield put({
                type: UPDATE_WIDGET_DATA,
                data: appData.widgets,
            });
        }

        if (!isObjectEmpty(appData.reports)) {
            yield put({
                type: UPDATE_REPORT_DATA,
                data: appData.reports,
            });
        }

        if (!isObjectEmpty(appData.permissions)) {
            yield put({
                type: UPDATE_PERMISSION_DATA,
                data: appData.permissions,
            });
        }

        if (!isObjectEmpty(appData.myData)) {
            yield put({
                type: UPDATE_MY_DATA,
                data: appData.myData,
            });
        }

    } catch (e) {
        console.error('Data fetch failed');
        console.error(e);
    }
}

function* pushDataToServer(action: AnyAction) {
    yield delay(15000);
    const state: ApplicationState = yield select();
    if (!state.myData.isPushingData) {
        try {
            yield call(pushAllData, state);
        } catch (e) {
            console.error('Data push failed');
            console.error(e);
        }
    }
}

function* saveDataToServer(action: AnyAction) {
    const state: ApplicationState = yield select();
    try {
        yield call(pushAllData, state);
        yield put(completeDataPush());
    } catch (e) {
        console.error('Data push failed');
        console.error(e);
    }
}

export function* watchDataFetchRequest() {
    yield takeLatest(FETCH_DATA_REQUEST, fetchDataFromServer);
}

export function* watchDataPushRequest() {
    yield takeLatest('*', pushDataToServer);
}

export function* watchSaveDataRequest() {
    yield takeLatest(START_DATA_PUSH, saveDataToServer);
}
