import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariableForAddMember, setLocationPiece, setEntityType } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import StepPiece from './step-piece/StepPiece';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';


type AddMemberPieceProps = {
    selectedEntityType?: string,
    nextPiece?: JSX.Element,
    locationPiece?: JSX.Element,
    variables: Array<Option>,
    addingVariableName?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        memberTypes: state.members.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setVariableForAddMember: (pieceId: string, value: string) => dispatch(setVariableForAddMember(pieceId, value)),
        setLocation: (pieceId: string, location: string) => dispatch(setLocationPiece(pieceId, location)),
        setEntityType: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = AddMemberPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type OwnState = {
    isHoveringOverLocation: boolean,
}

class ConnectedAddMemberPiece extends Component<Props, OwnState> {

    state = {
        isHoveringOverLocation: false,
    }

    handleHoverOverLocation = () => {
        this.setState({
            isHoveringOverLocation: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfLocationPiece = () => {
        this.setState({
            isHoveringOverLocation: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverLocation) {

            this.props.setLocation(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverLocation: false,
            });
        }
    }

    render() {
        const entityTypeSelectCallback = this.props.setEntityType.bind(this, this.props.pieceId);

        let entityTypeSelectBox: JSX.Element|undefined;

        const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.addingVariableName}>
            <DropDownList theme="indigo">
                {this.props.variables.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setVariableForAddMember.bind(this, this.props.pieceId)} />)}
            </DropDownList>
        </SelectBox>;


        entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType ? this.props.memberTypes.byId[this.props.selectedEntityType].name : undefined}>
            <DropDownList theme="aqua">
                {this.props.memberTypes.allEntries.map(memberTypeId => <ListItem theme="aqua" name={this.props.memberTypes.byId[memberTypeId].name} key={memberTypeId} value={memberTypeId} onClick={entityTypeSelectCallback} />)}
            </DropDownList>
        </SelectBox>;

        
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.visibleItems}>
                    <div className={styles.text}>add member of type</div>
                    {entityTypeSelectBox}
                    <div className={styles.text}>in the location</div>
                    {this.props.locationPiece ? this.props.locationPiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverLocation && !!this.props.targetPiece} onMouseOver={this.handleHoverOverLocation} onMouseOut={this.handleHoverOutOfLocationPiece} />}
                    <div className={styles.text}>and store in</div>
                    {variableSelectBox}
                </div>
            </StepPiece>
        )

    }
}

const AddMemberPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddMemberPiece);

export default AddMemberPiece;