import React, { Component } from 'react';

import { CardType } from '../widgets/card/Card';
import CardsList from '../widgets/card/CardsList';
import ModifyForm from '../widgets/card/ModifyForm';
import InputText from '../widgets/form/InputText';
import { IUpdateableCustomFieldData, FieldType, INewCustomFieldData } from '../shared/store/custom-fields';
import FlowchartCard from '../widgets/card/FlowchartCard';
import { translatePhrase } from '../shared/helpers/translation';

export interface OwnProps {
    customFieldId: string,

    selectedId?: string,
    isReadOnly: boolean,
    
}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    customFieldData: INewCustomFieldData,
    cardsList: Array<CardType>,
    selectedCard: CardType|undefined,
}

export interface DispatchProps {
    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: {name: string}) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: {id: string, name: string}) => void,

    deleteVertical: (id: string) => void,
    updateVertical: (payload: IUpdateableCustomFieldData) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,

    isShowingModifyVerticalForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalType: string,
    modifyingVerticalIsComputed: string,
    modifyingVerticalIsInTable: string,
    modifyingVerticalIsUnique: string,

    isUpdating: boolean,
};

class CustomFieldVertical<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (state.isUpdating && props.customFieldData) {
            return {
                modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
                modifyingVerticalName: props.customFieldData ? props.customFieldData.name : '',
                modifyingVerticalType: props.customFieldData.type ? props.customFieldData.type : '',modifyingVerticalIsComputed: props.customFieldData ? props.customFieldData.isComputed ? 'Yes' : 'No' : 'No',
                modifyingVerticalIsInTable: props.customFieldData ? props.customFieldData.isInTable ? 'Yes' : 'No' : 'No',
                modifyingVerticalIsUnique: props.customFieldData ? props.customFieldData.isUnique ? 'Yes' : 'No' : 'No',
            };
        }

        return null;
    }

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleVerticalEditForm = () => {
        if (this.state.isShowingModifyVerticalForm) {
            this.setState({
                isShowingModifyVerticalForm: false,
                modifyingVerticalName: '',
                modifyingVerticalType: '',
                modifyingVerticalIsInTable: '',
                modifyingVerticalIsUnique: '',
            });
        } else {
            this.setState({
                isShowingModifyVerticalForm: true,
                modifyingVerticalName: this.props.customFieldData.name,
                modifyingVerticalType: this.props.customFieldData.type,
                modifyingVerticalIsInTable: this.props.customFieldData.isInTable ? 'Yes' : 'No',
                modifyingVerticalIsUnique: this.props.customFieldData.isUnique ? 'Yes' : 'No',
            });
        }
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    updateFieldIsUnique = (value: string) => {
        this.setState({
            modifyingVerticalIsUnique: value,
        });
    }

    deleteCustomField = () => {
        this.props.deleteVertical(this.props.customFieldId);
    }

    updateCustomField = () => {
        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        this.props.updateVertical({
            id: this.props.customFieldId,
            name: this.state.modifyingVerticalName,
            type: FieldType[fieldType],
            isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
            isEditable: this.props.customFieldData.isEditable,
            isDeletable: this.props.customFieldData.isDeletable,
            isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
            isUnique: this.state.modifyingVerticalIsUnique === 'Yes',
            seedEntityVariable: this.props.customFieldData.seedEntityVariable,
        });

        this.setState({
            isShowingModifyVerticalForm: false,
            modifyingVerticalName: '',
            modifyingVerticalType: '',
            modifyingVerticalIsComputed: '',
            modifyingVerticalIsInTable: '',
            modifyingVerticalIsUnique: '',
            isUpdating: true,
        });
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsComputed)) {
            return 'Enter a valid computed field'
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        if (this.state.modifyingVerticalIsComputed !== translatePhrase('Yes')) {
    
            if (!binaryTypes.includes(this.state.modifyingVerticalIsUnique)) {
                return 'Enter a valid unique field'
            }

        }
        
        return true;
    }



    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;
        
        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }
    
    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name
        });
    }
    
    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }
    
    addCard = () => {
        
        this.props.addCard({
            name: this.state.modifyingCardName
        });
        
        this.setState({
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }
    
    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName
        });
        
        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }
    
    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }
        
        return true;
    }

    render() {
        const binaryOptions = [{
            name: translatePhrase('Yes'),
            value: 'Yes'
        }, {
            name: translatePhrase('No'),
            value: 'No'
        }];

        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: translatePhrase(name.split('_').join(' ')),
                value: name,
            }
        });

        const editVerticalForm = <ModifyForm isNew={false} submitForm={this.updateCustomField} cancelForm={this.toggleVerticalEditForm} validateForm={this.validateVerticalForm}>
            <InputText placeholder="Name" onEnterPress={this.updateCustomField} onChange={this.updateFieldName} default={translatePhrase(this.props.customFieldData.name)} />
            <InputText placeholder="Type" onEnterPress={this.updateCustomField} onChange={this.updateFieldType} default={translatePhrase(this.props.customFieldData.type.split('_').join(' '))} options={typeOptions} />
            <InputText placeholder="Is Computed" onEnterPress={this.updateCustomField} onChange={this.updateFieldIsComputed} default={this.props.customFieldData.isComputed ? translatePhrase('Yes') : translatePhrase('No')} options={binaryOptions} />
            <InputText placeholder="Is In Table" onEnterPress={this.updateCustomField} onChange={this.updateFieldIsInTable} default={this.props.customFieldData.isInTable ? translatePhrase('Yes') : translatePhrase('No')} options={binaryOptions} />
            {this.state.modifyingVerticalIsComputed !== translatePhrase('Yes') && <InputText placeholder="Is Unique" onEnterPress={this.updateCustomField} onChange={this.updateFieldIsUnique} default={this.props.customFieldData.isUnique ? translatePhrase('Yes') : translatePhrase('No')} options={binaryOptions} />}
        </ModifyForm>;

        const modifyForm = <ModifyForm isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <InputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
        </ModifyForm>;

        const choicesVisible = this.props.customFieldData.type === FieldType.SINGLE_SELECT || this.props.customFieldData.type === FieldType.MULTI_SELECT;
        let cards: Array<CardType> | undefined;
        let flowchartCard = undefined;

        if (choicesVisible) {
            cards = this.props.cardsList;
        } else {
            cards = [{
                id: this.props.customFieldData.type.split('_').join(' '),
                name: translatePhrase(this.props.customFieldData.type.split('_').join(' ')),
            }];
        }

        if (this.props.customFieldData.isComputed) {
            const cardData: CardType = {
                id: this.props.customFieldData.type.split('_').join(' '),
                name: translatePhrase(this.props.customFieldData.type.split('_').join(' ')),
            }
            flowchartCard = <FlowchartCard card={cardData} link={`/flowchart/field/${this.props.customFieldId}`} />
            cards = undefined;
        }

        return <CardsList 
            heading={this.props.customFieldData.name}
            cards={cards}
            selectedCard={this.props.selectedCard} 
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard} 
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges || !this.props.customFieldData.isDeletable}
            isReadOnly={this.props.isReadOnly || !this.props.write || !this.props.customFieldData.isEditable}

            isAddRestricted={!choicesVisible}
            isShowingIndex={choicesVisible}
            isShowingEditVerticalForm={this.state.isShowingModifyVerticalForm}
            modifyVerticalForm={editVerticalForm}
            onDeleteVertical={this.deleteCustomField}
            onEditVertical={this.toggleVerticalEditForm}
        >
            {flowchartCard}
        </CardsList>
    }
}

export default CustomFieldVertical;