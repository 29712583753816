import React, { Component } from 'react';
import styles from './WorkflowModify.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import InputText from '../../../widgets/form/InputText';
import Button from '../../../widgets/form/Button';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/main';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';

import { connect } from 'react-redux';
import uuid from 'uuid';
import moment from 'moment';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';

type OwnProps = {
    workflowId?: string,
    isReadOnly?: boolean,

    submit: (workflowData: IUpdateableWorkflowData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const workflowTypes = state.workflows.types.allEntries.map(workflowTypeId => state.workflows.types.byId[workflowTypeId]);

    return {
        workflow: ownProps.workflowId ? state.workflows.byId[ownProps.workflowId] : undefined,
        workflowTypes: workflowTypes,
        
        usersData: state.users,
        myId: state.myData.id,
        
        memberData: state.members,
        groupsData: state.groups,
        groupTypesData: state.groups.types,

        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,
        workflowStatusesData: state.workflows.types.statuses,
        customFieldsData: state.workflows.types.customFields,
        customFieldOptionsData: state.workflows.types.customFieldOptions,

        applicationState: state,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;


type OwnState = {
    workflowData: IUpdateableWorkflowData,
    submitTimer: number|undefined,
    locationKey: number,
    errorMessage: string,
};

class ConnectedWorkflowModify extends Component<Props, OwnState> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        let workflowData: IUpdateableWorkflowData;
        if (!props.workflow) {
            // This is a new workflow
            workflowData = {
                id: uuid.v4(),
                type: '',
                status: '',
                dueDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                user: this.props.myId,
                affiliatedEntity: '',
            };
        } else {
            workflowData = {
                id: props.workflow.id,
                type: props.workflow.type,
                status: props.workflow.status,
                dueDate: props.workflow.dueDate,
                user: props.workflow.user,
                affiliatedEntity: props.workflow.affiliatedEntity,
            }
        }
        
        this.state = {
            workflowData: workflowData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: ''
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }
    
    changeType = (type: string) => {

        const statusesForTypes = this.props.workflowTypesData.byId[type].statuses;
        const nonTerminalStatuses = this.props.workflowTypesData.byId[type].statuses.filter(statusId => !this.props.workflowStatusesData.byId[statusId].isTerminal);

        let defaultStatusId = '';
        let defaultDueDate = moment().add(7, 'days').format('YYYY-MM-DD');

        if (nonTerminalStatuses.length > 0) {
            defaultStatusId = nonTerminalStatuses[0];
            const defaultStatus = this.props.workflowStatusesData.byId[defaultStatusId];
            defaultDueDate = moment().add(defaultStatus.dueInDays ? defaultStatus.dueInDays : 7, 'days').format('YYYY-MM-DD');
        } else if (statusesForTypes.length > 0) {
            defaultStatusId = statusesForTypes[0];
        }

        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            type: type,
            user: '',
            status: defaultStatusId,
            dueDate: defaultDueDate,
        };
        
        this.setState({
            workflowData: updatedIUpdateableWorkflowData
        });
    }
    
    changeDueDate = (dueDate: string|undefined) => {
        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            dueDate: dueDate
        };
        
        this.setState({
            workflowData: updatedIUpdateableWorkflowData
        });
    }
    
    changeStatus = (status: string) => {
        const statusData = this.props.workflowStatusesData.byId[status];
        const defaultDueDate = moment().add(statusData.dueInDays ? statusData.dueInDays : 7, 'days').format('YYYY-MM-DD');
        
        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            status: status,
            dueDate: defaultDueDate,
        };

        this.setState({
            workflowData: updatedIUpdateableWorkflowData,
        });
    }
    
    changeUser = (user: string) => {
        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            user: user
        };
        
        this.setState({
            workflowData: updatedIUpdateableWorkflowData
        });
    }
    
    changeAffiliatedEntity = (entity: string) => {
        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            affiliatedEntity: entity,
        };
        
        this.setState({
            workflowData: updatedIUpdateableWorkflowData
        });
    }
    
    showErrorMessage = (message: string) => {
        let that = this
        
        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 5000);
        
    }
    
    validateIUpdateableWorkflowData = () => {
        
        if (!this.state.workflowData.type) {
            this.showErrorMessage('Select a valid type');
            return;
        }
        
        if (!this.state.workflowData.user) {
            this.showErrorMessage('Select a valid user');
            return;
        }
        
        if (!this.state.workflowData.status) {
            this.showErrorMessage('Select a valid status');
            return;
        }
        
        if (this.props.workflowTypesData.byId[this.state.workflowData.type].affiliation !== 'none') {
            if (!this.state.workflowData.affiliatedEntity) {
                this.showErrorMessage('Select a valid affiliated entity');
                return;
            } else {

                if (!this.props.workflow) {
                    const workflowOwner = this.props.usersData.byId[this.state.workflowData.user];
                    const allWorkflowsOfType = workflowOwner.workflows[this.state.workflowData.type];
        
                    if (Array.isArray(allWorkflowsOfType)) {
                        for (let i = 0; i < allWorkflowsOfType.length; i += 1) {
                            const workflowOfType = this.props.workflowsData.byId[allWorkflowsOfType[i]];

                            if (!workflowOfType.archived && workflowOfType.affiliatedEntity === this.state.workflowData.affiliatedEntity && !this.props.workflowStatusesData.byId[workflowOfType.status].isTerminal) {
                                this.showErrorMessage('This user already has a workflow of this type open for this member/group');
                                return;
                            }
                        }
                    }
                }
            }
        }
        
        return true
    }
    
    submitWorkflowForm = () => {
        if (this.validateIUpdateableWorkflowData()) {
            this.markForSubmit();
            
        }
    }
    
    markForSubmit = () => {
        let that = this;
        
        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.workflowData);
        }, 1000);
        
        this.setState({
            submitTimer: timeout
        });
    }
    
    render() {

        const typesList = this.props.workflowTypesData.allEntries.map(typeId => {
            return {
                name: this.props.workflowTypesData.byId[typeId].name,
                value: typeId,
            };
        });

        let usersList: Array<{name: string, value: string}> = [];

        if (this.state.workflowData.type) {
            const workflowType = this.props.workflowTypesData.byId[this.state.workflowData.type];

            usersList = this.props.usersData.allEntries
            .filter(userId => {
                const userData = this.props.usersData.byId[userId];

                return userData.project === workflowType.project;
            })
            .map(userId => {
                const user = this.props.usersData.byId[userId];
                let userName = user.customFields[this.props.usersData.nameFieldId];

                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, nameField, userId, 'user');

                return {
                    name: userName,
                    value: userId,
                };
            });
        }

        const currentUser = this.state.workflowData.user ? usersList.find(userChoice => userChoice.value === this.state.workflowData.user) : undefined;
        const currentUserName = typeof currentUser !== 'undefined' ? currentUser.name : undefined;

        let statusesInput: JSX.Element|undefined = undefined;
        let affiliationsInput: JSX.Element|undefined = undefined;

        if (this.state.workflowData.type) {
            const workflowType = this.props.workflowTypesData.byId[this.state.workflowData.type];

            const statusesForType = workflowType.statuses.map(statusId => {
                return {
                    name: this.props.workflowStatusesData.byId[statusId].name,
                    value: statusId,
                }
            });

            statusesInput = <div className={styles.inputSegment} key={this.state.workflowData.type + 's'}>
                <InputText placeholder="Status" icon={chevronIcon} default={this.props.workflow ? this.props.workflowStatusesData.byId[this.props.workflow.status].name : this.state.workflowData.status ? this.props.workflowStatusesData.byId[this.state.workflowData.status].name : ''} options={statusesForType} onChange={this.changeStatus} />
            </div>;

            let affiliatedIds: Array<string>;
            let affiliatedEntityOptions: Array<{name: string, value: string}> = [];
            let defaultAffiliation = '';

            if (workflowType.affiliation === 'member') {
                if (isUUID(workflowType.affiliatedEntity)) {
                    affiliatedIds = this.props.memberData.types.byId[workflowType.affiliatedEntity].members;
                } else {
                    affiliatedIds = this.props.memberData.allEntries;
                }

                affiliatedEntityOptions = affiliatedIds.map(memberId => {
                    const member = this.props.memberData.byId[memberId];
                    const memberType = this.props.memberData.types.byId[member.type];
                    let memberName = member.customFields[memberType.nameFieldId];

                    const nameField = this.props.memberData.types.customFields.byId[memberType.nameFieldId];

                    memberName = getReadableDataForCustomField(memberName, nameField, memberId, 'member');

                    if (this.props.workflow && this.props.workflow.affiliatedEntity === memberId) {
                        defaultAffiliation = memberName;
                    }

                    return {
                        name: memberName,
                        value: memberId,
                    };
                });
            } else if (workflowType.affiliation === 'group') {
                if (isUUID(workflowType.affiliatedEntity)) {
                    affiliatedIds = this.props.groupsData.types.byId[workflowType.affiliatedEntity].groups;
                } else {
                    affiliatedIds = this.props.groupsData.allEntries;
                }

                affiliatedEntityOptions = affiliatedIds.map(groupId => {
                    const group = this.props.groupsData.byId[groupId];
                    const groupType = this.props.groupTypesData.byId[group.type];
                    let groupName = group.customFields[groupType.nameFieldId];

                    const nameField = this.props.groupTypesData.customFields.byId[groupType.nameFieldId];

                    groupName = getReadableDataForCustomField(groupName, nameField, groupId, 'group');

                    if (this.props.workflow && this.props.workflow.affiliatedEntity === groupId) {
                        defaultAffiliation = groupName;
                    }

                    return {
                        name: groupName,
                        value: groupId,
                    };
                });
            }

            if (workflowType.affiliation !== 'none') {
                affiliationsInput = <div className={styles.inputSegment}>
                    <InputText placeholder="Affiliation" icon={chevronIcon} default={defaultAffiliation} options={affiliatedEntityOptions} onChange={this.changeAffiliatedEntity} />
                </div>;
            }

        }
        
        return (
            <section className={this.props.isReadOnly ? styles.viewOnlyWorkflow : styles.modifyWorkflow}>
                <header>
                    <h2 className={styles.formHeading}>{this.props.isReadOnly ? 'View Workflow data' : 'Add Workflow'}</h2>
                    <button className={styles.cancelButton} onClick={this.props.cancel}><CancelIcon /> {this.props.isReadOnly ? 'Close' : 'Cancel'}</button>
                </header>
                <section className={styles.errorMessage}>{this.state.errorMessage}</section>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Workflow Type" icon={chevronIcon} default={this.props.workflow ? this.props.workflowTypesData.byId[this.props.workflow.type].name : ''} options={typesList} onChange={this.changeType} />
                    </div>
                    {this.state.workflowData.type && <div className={styles.inputSegment} key={this.state.workflowData.type}>
                        <InputText placeholder="User" icon={chevronIcon} default={currentUserName || ''} options={usersList} onChange={this.changeUser} />
                    </div>}
                    {statusesInput}
                    <div className={styles.inputSegment} key={this.state.workflowData.status}>
                        <div className={styles.label}>Due Date</div>
                        <DatePicker selected={this.state.workflowData.dueDate ? new Date(this.state.workflowData.dueDate) : this.props.workflow && this.props.workflow.dueDate ? new Date(this.props.workflow.dueDate) : undefined} dateFormat="dd-MMM-yyyy" onChange={value => this.changeDueDate(value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} /> 
                    </div>
                    {affiliationsInput}
                    
                </div>
                    
                {!this.props.isReadOnly && <div className={styles.buttonHolder}>
                    {this.state.submitTimer ? <button className={styles.confirmFormButton}>{this.props.workflow ? translatePhrase('Updated Workflow') : translatePhrase('Added Workflow')}</button> : <Button text={this.props.workflow ? translatePhrase('Update Workflow') : translatePhrase('Add Workflow')} onClick={this.submitWorkflowForm} />}
                </div>}
                
            </section>
        );
    }
}

const WorkflowModify = connect(mapStateToProps)(ConnectedWorkflowModify);

export default WorkflowModify;