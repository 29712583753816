import React from 'react';
import { VariableState } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions, PiecePositionState } from '../../../shared/store/flowchart/pieces/types';
import ReturnPiece from '../../../components/flowchart/pieces/ReturnPiece';
import StartPiece from '../../../components/flowchart/pieces/StartPiece';
import { getComponent } from './index';
import { Position } from '../../../shared/helpers/common-types';
import { isUUID } from '../../../shared/helpers/utilities';
import { ApplicationState } from '../../../shared/store/main';
import { IReportType } from '../../../shared/store/reports/types/types';

export function getComponentForReportFields(reportType: IReportType, applicationState: ApplicationState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position) : JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getInnerComponentTemp = getComponentForReportFields.bind({}, reportType, applicationState, piecesState, variablesState);
    const getInnerComponentTemp2 = getInnerComponentTemp.bind({}, variableIds, isEditable, flowchartPieceActions, isolatePiece);
    const getInnerComponent = getInnerComponentTemp2.bind({}, removeIsolatedPiece, registerVariable);

    switch(piece.type) {

        case PieceType.START:
            const startNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const startInitialPosition = reportType.startPiece ? reportType.startPiece.position : undefined;
            return <StartPiece pieceId={pieceId} nextPiece={startNextPiece} isDragDisabled={!isEditable} initialPosition={startInitialPosition} removeIsolatedPiece={removeIsolatedPiece} />

        case PieceType.RETURN:
            const returnVariablePiece = piece.returnValue && isUUID(piece.returnValue) ? getInnerComponent(piece.returnValue, flowchartPieceActions.setReturnVariable.bind({}, pieceId, undefined)) : undefined;
            const returnVariableText = piece.returnValue;

            return <ReturnPiece
                pieceId={pieceId}
                returnVariablePiece={returnVariablePiece}
                returnVariableText={returnVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />
        
        default:
            return getComponent(pieceId, piecesState, variablesState, variableIds, getInnerComponent, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, detachPiece, initialPosition);
    }
}

export const piecesByCategory = {
    'Control': {
        color: '#14b1ab',
        pieces: [{
            name: 'For',
            type: PieceType.FOR,
        }, {
            name: 'Return',
            type: PieceType.RETURN,
        }, {
            name: 'Split',
            type: PieceType.SPLIT,
        }, {
            name: 'Structure',
            type: PieceType.STRUCTURE,
        }],
    },
    'Constants': {
        color: '#efaa4b',
        pieces: [{
            name: 'Today',
            type: PieceType.TODAY,
        }, {
            name: 'True',
            type: PieceType.TRUE,
        }, {
            name: 'False',
            type: PieceType.FALSE,
        }, {
            name: 'My Groups',
            type: PieceType.MY_GROUPS,
        }, {
            name: 'My Members',
            type: PieceType.MY_MEMBERS,
        }, {
            name: 'Financial Year Months',
            type: PieceType.FINANCIAL_YEAR_MONTHS,
        }],
    },
    'Arithmetic Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Add',
            type: PieceType.ADD,
        }, {
            name: 'Subtract',
            type: PieceType.SUBTRACT,
        }, {
            name: 'Multiply',
            type: PieceType.MULTIPLY,
        }, {
            name: 'Divide',
            type: PieceType.DIVIDE,
        }, {
            name: 'Exponent',
            type: PieceType.EXPONENT
        }, {
            name: 'Sequence',
            type: PieceType.SEQUENCE,
        }],
    },
    'Boolean Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Lesser Than',
            type: PieceType.LESSER_THAN,
        }, {
            name: 'Greater Than',
            type: PieceType.GREATER_THAN,
        }, {
            name: 'Equal To',
            type: PieceType.EQUAL_TO,
        }, {
            name: 'Not Equal To',
            type: PieceType.NOT_EQUAL_TO,
        }, {
            name: 'In',
            type: PieceType.IN,
        }, {
            name: 'And',
            type: PieceType.AND,
        }, {
            name: 'Or',
            type: PieceType.OR,
        }, {
            name: 'Not',
            type: PieceType.NOT,
        }, {
            name: 'Variable to Boolean',
            type: PieceType.VARIABLE_TO_BOOLEAN,
        }, {
            name: 'Boolean to Variable',
            type: PieceType.BOOLEAN_TO_VARIABLE,
        }, {
            name: 'Is Defined',
            type: PieceType.IS_DEFINED,
        }],
    },
    'List Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Pick first element',
            type: PieceType.PICK_FIRST_ELEMENT,
        }, {
            name: 'Pick first N elements',
            type: PieceType.PICK_FIRST_N_ELEMENTS,
        }, {
            name: 'Pick last element',
            type: PieceType.PICK_LAST_ELEMENT,
        }, {
            name: 'Pick last N elements',
            type: PieceType.PICK_LAST_N_ELEMENTS,
        }, {
            name: 'Pick Nth element',
            type: PieceType.PICK_NTH_ELEMENT,
        }, {
            name: 'Add to list',
            type: PieceType.ADD_TO_LIST,
        }, {
            name: 'Remove from list',
            type: PieceType.REMOVE_FROM_LIST,
        }, {
            name: 'Length',
            type: PieceType.LENGTH,
        }, {
            name: 'Add to table',
            type: PieceType.ADD_TO_TABLE,
        }],
    },
    'Date Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Add Months',
            type: PieceType.ADD_MONTHS,
        }, {
            name: 'Add Years',
            type: PieceType.ADD_YEARS,
        }, {
            name: 'Subtract Months',
            type: PieceType.SUBTRACT_MONTHS,
        }, {
            name: 'Subtract Years',
            type: PieceType.SUBTRACT_YEARS,
        }, {
            name: 'Get Date',
            type: PieceType.GET_DATE,
        }, {
            name: 'Get Day',
            type: PieceType.GET_DAY,
        }, {
            name: 'Get Month',
            type: PieceType.GET_MONTH,
        }, {
            name: 'Get Readable Month',
            type: PieceType.GET_READABLE_MONTH,
        }, {
            name: 'Get Year',
            type: PieceType.GET_YEAR,
        }],
    },
    'Variables': {
        color: '#8891c8',
        pieces: [{
            name: 'Variable',
            type: PieceType.VARIABLE,
        }, {
            name: 'Set Variable',
            type: PieceType.SET_VARIABLE,
        }],
    },
    'Custom Fields': {
        color: '#d289c0',
        pieces: [{
            name: 'Get',
            type: PieceType.GET_VALUE,
        }],
    },
}