import React, { Component } from 'react';
import styles from './StepPiece.module.scss';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setNextPiece } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/main';


type StepPieceEssentialsProps = {
    hasUpperLock?: boolean,
    hasLowerLock?: boolean,
    nextPiece?: JSX.Element,
    theme?: 'indigo'|'pink'|'aqua'|'camo',

    handleHoverOverNextPiece: () => void,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StepPieceEssentialsProps & StateProps & DispatchProps & FlowchartPieceProps;

type StepPieceEssentialsState = {
    isHoveringOverNextPiece: boolean,
}

class ConnectedStepPieceEssentials extends Component<Props, StepPieceEssentialsState> {

    static defaultProps = {
        hasUpperLock: true,
        hasLowerLock: true,
    };

    state = {
        isHoveringOverNextPiece: false,
    };

    handleHoverOverNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: true,
        });

        this.props.handleHoverOverNextPiece();
    };

    handleHoverOutOfNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverNextPiece) {
            this.props.setNextPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverNextPiece: false,
            });
        }
    }

    render() {
        let themeClass;
    
        switch(this.props.theme) {
            case 'indigo':
                themeClass = styles.indigoStepPiece;
                break;
            case 'pink':
                themeClass = styles.pinkStepPiece;
                break;
            case 'aqua':
                themeClass = styles.aquaStepPiece;
                break;
            case 'camo':
                themeClass = styles.camoStepPiece;
                break;
            default:
                themeClass = styles.indigoStepPiece;
                break;
        }

        return (
            <FlowchartPiece {...this.props}>
                <div>
                    <section className={themeClass}>
                        <div className={this.props.hasUpperLock ? styles.upperPieceLock : styles.filledUpperPieceLock}></div>
                        <div className={styles.spaceBetweenLocks}></div>
                        <div className={this.props.hasLowerLock ? styles.lowerPieceLock : styles.filledLowerPieceLock}></div>

                        <div className={styles.extendableUpperArm}></div>

                        <div className={styles.visibleItems}>
                            {this.props.children}
                        </div>
                        {this.props.hasLowerLock && !this.props.nextPiece && <div className={this.state.isHoveringOverNextPiece && this.props.isDragging && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable} onMouseOver={this.handleHoverOverNextPiece} onMouseOut={this.handleHoverOutOfNextPiece}></div>}
                    </section>
                    {this.props.nextPiece}
                </div>
            </FlowchartPiece>
        )
    }
}

const StepPieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedStepPieceEssentials);

export default StepPieceEssentials;