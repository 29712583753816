import { isUUID } from './utilities';
import store from '../store/main';
import { ILocation } from '../store/structure/location/types';


export function getAllLocationsOfUser(userId: string) {
    const applicationState = store.getState();
    const user = applicationState.users.byId[userId];
    const locationState = applicationState.structure.locations;
    const allLocations: Array<string> = [...user.locations];

    let locationsInThisLevel: Array<string> = [...allLocations];

    while (locationsInThisLevel.length !== 0) {
        const nextLevelLocations = locationsInThisLevel.map(locationId => locationState.byId[locationId].children).flat();
        allLocations.push.apply(allLocations, nextLevelLocations);
        locationsInThisLevel = nextLevelLocations;
    }

    return allLocations;
}

export function getAllLocationsVisibleToUser(userId: string) {
    const applicationState = store.getState();
    const user = applicationState.users.byId[userId];
    const locationState = applicationState.structure.locations;

    if (user.locations.length === 0) {
        return [];
    }

    const ancestorLocations: Array<string> = [];
    let upperLocation: ILocation = locationState.byId[user.locations[0]];
    let parentId = upperLocation.parent || '';

    while (parentId in locationState.byId) {
        ancestorLocations.push(parentId);
        upperLocation = locationState.byId[parentId];
        parentId = upperLocation.parent || '';
    }

    const childLocations = getAllLocationsOfUser(userId);

    return ancestorLocations.concat(childLocations);
}


export function getAllLeafLocationsOfUser(userId: string) {
    const applicationState = store.getState();
    const projectIds = isUUID(userId) ? [applicationState.users.byId[userId].project] : applicationState.structure.projects.allEntries;

    function getLeafLocationsForProject(projectId: string) {
        const projectData = applicationState.structure.projects.byId[projectId];
        const leafLevelIndex = projectData.children.map(levelId => applicationState.structure.levels.byId[levelId]).filter(level => !level.archived).length - 1;
    
        let locations = applicationState.structure.locations.byProject[projectId].map(locationId => applicationState.structure.locations.byId[locationId]);
        
        for (let i = 0; i < leafLevelIndex; i += 1) {
            let newLocations: Array<string> = [];
            
            locations.forEach(location => {
                if (location.children && location.children.length > 0) {
                    newLocations = newLocations.concat(location.children);
                }
            });
            
            locations = newLocations.map(locationId => applicationState.structure.locations.byId[locationId]);
        }
        return locations.map(location => location.id);
    }

    return projectIds.map(projectId => getLeafLocationsForProject(projectId)).flat();
}


export function getAllLocationsInTree(locationId: string) {
    const applicationState = store.getState();
    const locationState = applicationState.structure.locations;
    const locationIdsInTree = [locationId];
    
    let locationsInThisLevel: Array<string> = [locationId];

    while (locationsInThisLevel.length !== 0) {
        const nextLevelLocations = locationsInThisLevel.map(locationId => locationState.byId[locationId].children).flat();
        locationIdsInTree.push.apply(locationIdsInTree, nextLevelLocations);
        locationsInThisLevel = nextLevelLocations;
    }

    return locationIdsInTree;
}