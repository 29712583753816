export interface Identifiable {
    id: string,
};

export interface Synchronizable extends Identifiable {
    createdTime: string,
    lastUpdatedTime: string,
}

export interface NormalizedModel<T extends Synchronizable> {
    byId: {
        [key: string]: T
    },
    allEntries: Array<string>,
    
    createdIds: Set<string>,
    updatedIds: Set<string>,
    deletedIds: Set<string>,
}

export function addEntity<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, payload: U): T {
    return {
        ...state,
        byId: {
            ...state.byId,
            [payload.id]: {
                ...payload,
            },
        },
        allEntries: state.allEntries.concat([payload.id]),
        createdIds: new Set([...state.createdIds, payload.id]),
    }
}

export function updateEntity<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, payload: Partial<U> & Identifiable): T {

    return {
        ...state,
        byId: {
            ...state.byId,
            [payload.id]: {
                ...state.byId[payload.id],
                ...payload,
            },
        },
        updatedIds: new Set([...state.updatedIds, payload.id]),
    }
}

export function deleteEntity<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, id: string): T {

    return {
        ...state,
        byId: {
            ...state.byId,
            [id]: {
                ...state.byId[id],
                archived: true,
            },
        },
        allEntries: state.allEntries.filter(entityId => entityId !== id),
        deletedIds: new Set([...state.deletedIds, id]),
    }
}