import React from 'react';
import styles from './OptionsList.module.scss';
import Option from './Option';
import { Option as OptionType } from './InputText';

type OwnProps = {
    options: Array<OptionType>,
    search: string,
    isShowing: boolean,
    selectedIndex: number|undefined,

    onSelect: (selectedName: string, selectedValue: string) => void
};

const OptionsList: React.FC<OwnProps> = (props) => {
    
    // These are options that contain the search term
    const availableOptions = props.options.filter(option => option.name.toLocaleLowerCase().indexOf(props.search.toLocaleLowerCase().trim()) !== -1);

    // These are options that do not contain the search term
    const deadOptions = props.options.filter(option => option.name.toLocaleLowerCase().indexOf(props.search.toLocaleLowerCase().trim()) === -1);
    
    const optionsMarkup = availableOptions.map((option, index) => {
        return <Option key={option.id} name={option.name} isSelected={index === props.selectedIndex} value={option.value} search={props.search} onClick={(name: string, value: string) => props.onSelect(name, value)} />;
    });
    const deadOptionsMarkup = deadOptions.map((option, index) => {
        return <Option key={option.id} name={option.name} isSelected={index === props.selectedIndex} value={option.value} search={''} onClick={(name: string, value: string) => props.onSelect(name, value)} />;
    });
    
    return <section className={props.isShowing ? styles.optionsList : styles.hiddenOptionsList}>
        {optionsMarkup.concat(deadOptionsMarkup)}
    </section>;
}

export default OptionsList;