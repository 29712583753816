import { ADD_REPORT_REQUEST, ADD_REPORT, DELETE_REPORT, UPDATE_REPORT, SEARCH_REPORT_TABLE, GO_TO_PAGE_REPORT_TABLE, SET_PAGE_SIZE_REPORT_TABLE, SORT_REPORT_TABLE, AddReportRequestAction, AddReportAction, UpdateReportAction, DeleteReportAction, StoreDataForReportAction, STORE_DATA_FOR_REPORT, FilterReportTableAction, FILTER_REPORT_TABLE } from './types';
import { INewReportData, IUpdateableReportData } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function addReportRequest(payload: INewReportData) : AddReportRequestAction {
    return {
        type: ADD_REPORT_REQUEST,
        payload,
    }
}

export function addReport(payload: IUpdateableReportData) : AddReportAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_REPORT,
        payload: {
            id: uuidv4(),
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
        },
        currentTime: now,
    }
}

export function updateReport(payload: IUpdateableReportData) : UpdateReportAction {
    return {
        type: UPDATE_REPORT,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function storeDataForReport(reportId: string, data: Array<Array<string>>) : StoreDataForReportAction {
    return {
        type: STORE_DATA_FOR_REPORT,
        reportId,
        data,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function deleteReport(id: string): DeleteReportAction {
    return {
        type: DELETE_REPORT,
        id,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}


export function searchReportTable(searchTerm: string) {
    return {
        type: SEARCH_REPORT_TABLE,
        searchTerm,
    }
}

export function filterReportTable(types: Array<string>, users: Array<string>): FilterReportTableAction {
    return {
        type: FILTER_REPORT_TABLE,
        types,
        users,
    }
}

export function goToPageReportTable(pageNumber: number) {
    return {
        type: GO_TO_PAGE_REPORT_TABLE,
        pageNumber,
    }
}

export function setPageSizeReportTable(pageSize: number) {
    return {
        type: SET_PAGE_SIZE_REPORT_TABLE,
        pageSize,
    }
}

export function sortReportTable(column: string, order: 'ASC'|'DESC') {
    return {
        type: SORT_REPORT_TABLE,
        column,
        order,
    }
}