import moment from 'moment';

export function reOrderList<T>(list: Iterable<T>, startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export function getReadableDate(dateString: string) {
    return moment(dateString).format('DD MMM YYYY');
}

export function isUUID(string: string) {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(string);
}

export function flattenArray<T>(twoDimensionalArray: Array<Array<T>>) {
    const flattenedArray: Array<T> = [];
    for (const internalArray of twoDimensionalArray) {
        flattenedArray.push.apply(flattenedArray, internalArray);
    }

    return flattenedArray;
}

export function copyStringToClipboard (value: string) {
    var el = document.createElement('textarea');
    el.value = value;

    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}