import { ApplicationState } from '../../main';
import { FlowchartProcessState, DefaultFlowchartProcessState, VariableValueType } from '../types';

import { getNextPieceId, executePiece, getPieceValue } from './index';
import { PieceType } from '../pieces/types';

export function getReportPieceValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, reportId: string, pieceId: string) : VariableValueType  {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const functionShortHand = getReportPieceValue.bind({}, applicationState, processState, reportId);

    switch(piece.type) {
        default:
            return getPieceValue(applicationState, processState, pieceId, functionShortHand);
    }
}


export function getNextPieceIdForReport(applicationState: ApplicationState, processState: FlowchartProcessState, startPiece: string|undefined, getPieceValueFromAbove?: (pieceId: string) => VariableValueType): string|undefined {
    
    const getNextPieceIdShortHand = getNextPieceIdForReport.bind({}, applicationState, processState, startPiece, getPieceValueFromAbove);

    return getNextPieceId(applicationState, processState, startPiece, getNextPieceIdShortHand, getPieceValueFromAbove);
}

export type ExecutePieceReturnType = {
    canContinueExecution: boolean,
    returnValue: VariableValueType,
};

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForReport(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, reportId: string, pieceId: string): ExecutePieceReturnType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const pieceValueShorthand = getReportPieceValue.bind({}, applicationState, processState, reportId);

    switch(piece.type) {

        case PieceType.RETURN:

            if (typeof piece.returnValue === 'undefined') {
                throw new Error('The return piece must have a variable')
            }
    
            const returnVariablePiece = piecesData.byId[piece.returnValue];
            const returnVariableValue = pieceValueShorthand(returnVariablePiece.id);

            return {
                canContinueExecution: false,
                returnValue: returnVariableValue,
            };

        default:
            const canContinueExecution = executePiece(applicationState, processState, pieceId, pieceValueShorthand);

            return {
                canContinueExecution,
                returnValue: undefined,
            };
    }
}

export function getReportValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, reportId: string, startPiece: string|undefined) {
    let executionResult: ExecutePieceReturnType;
    const pieceValueShorthand = getReportPieceValue.bind({}, applicationState, processState, reportId);

    do {
        const nextPieceId = getNextPieceIdForReport(applicationState, processState, startPiece, pieceValueShorthand);

        if (typeof nextPieceId === 'undefined') {
            return true;
        }

        executionResult = executePieceForReport(applicationState, processState, reportId, nextPieceId);
    } while (executionResult.canContinueExecution);

    return executionResult.returnValue;
}

