import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { INewStatusData, IUpdateableStatusData } from '../../../shared/store/workflows/types/statuses/types';
import { reOrderStatuses, addStatus, updateStatus, deleteStatus } from '../../../shared/store/workflows/types/statuses/actions';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState 
} from './WorkflowTypeStatusesVertical';

type StatusCardType = {
    id: string,
    name: string,
    details: string,
    isTerminal: boolean,
    dueInDays?: number,
}

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewStatusData) => void,
    updateCard: (payload: IUpdateableStatusData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const allStatusesForWorkflow: Array<StatusCardType> = state.workflows.types.byId[ownProps.parentId].statuses.map(statusId => {
        const status = state.workflows.types.statuses.byId[statusId];

        return {
            id: statusId,
            name: status.name,
            details: status.isTerminal ? 'Closed' : 'Open',
            isTerminal: status.isTerminal,
            dueInDays: status.dueInDays,
        }
    });

    const allGroupTypes = state.groups.types.allEntries.map(groupTypeId => {
        const groupType = state.groups.types.byId[groupTypeId];

        return {
            id: groupTypeId,
            name: groupType.name,
        };
    })

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        groupTypesList: allGroupTypes,
        cardsList: allStatusesForWorkflow,
        selectedCard: ownProps.selectedId ? allStatusesForWorkflow.find(status => status.id === ownProps.selectedId) : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderStatuses(sourceIndex, destinationIndex, ownProps.parentId)),
        addCard: (payload: INewStatusData) => dispatch(addStatus(payload, ownProps.parentId)),
        deleteCard: (id: string) => dispatch(deleteStatus(id, ownProps.parentId)),
        updateCard: (payload: IUpdateableStatusData) => dispatch(updateStatus(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedStatusesList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
        modifyingCardIsTerminal: 'No',
        modifyingCardDueInDays: 7,
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const StatusesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedStatusesList);

export default StatusesList;