import React, { Component } from 'react';
import StepPiece from './step-piece/StepPiece'
import styles from './step-piece/StepPiece.module.scss';

import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import Input from '../Input';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setUpdateDueDateValue, setTargetPiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { valuePieceSlotTarget } from './utilities';


type UpdateDueDatePieceProps = {
    nextPiece?: JSX.Element,
    dueDatePiece?: JSX.Element,
    dueDateText?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setUpdateDueDateValue: (pieceId: string, value: string) => dispatch(setUpdateDueDateValue(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = UpdateDueDatePieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type UpdateDueDateState = {
    isHoveringOverVariable: boolean,
}

class ConnectedUpdateDueDatePiece extends Component<Props, UpdateDueDateState> {
    state = {
        isHoveringOverVariable: false,
    };

    handleHoverOverVariable = () => {
        this.setState({
            isHoveringOverVariable: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }
        
        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariable: false,
        });
    };

    handleLeftVariableValueUpdate = (value: string) => {
        this.props.setUpdateDueDateValue(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariable) {

            this.props.setUpdateDueDateValue(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariable: false,
            });
        }
    }

    render() {
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.text}>Update due date</div>
                {this.props.dueDatePiece ? this.props.dueDatePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariable && !!this.props.targetPiece} defaultText={this.props.dueDateText} onMouseOver={this.handleHoverOverVariable} onMouseOut={this.handleHoverOutOfVariablePiece} onChange={this.handleLeftVariableValueUpdate} />}
            </StepPiece>
        )
    }
}

const UpdateDueDatePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdateDueDatePiece);

export default UpdateDueDatePiece;