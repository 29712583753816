import { Phone } from '../../helpers/common-types';
import { NormalizedModel, Synchronizable } from '../normalized-model';
import { CustomFieldState, CustomFieldValueType, IUpdateableCustomFieldData, IUpdateableFieldChoiceData, CustomFieldDataHolder } from '../custom-fields';
import { PiecePositionState } from '../flowchart/pieces/types';

export const ADD_USER = 'ADD_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const UPDATE_USER_WORKFLOWS = 'UPDATE_USER_WORKFLOWS';
export const UPDATE_USER_CUSTOM_FIELD_DATA = 'UPDATE_USER_CUSTOM_FIELD_DATA';

export const SEARCH_USER_TABLE = 'SEARCH_USER_TABLE';
export const FILTER_USER_TABLE = 'FILTER_USER_TABLE';
export const GO_TO_PAGE_USER_TABLE = 'GO_TO_PAGE_USER_TABLE';
export const SET_PAGE_SIZE_USER_TABLE = 'SET_PAGE_SIZE_USER_TABLE';
export const SORT_USER_TABLE = 'SORT_USER_TABLE';

export const SELECT_USER_CUSTOM_FIELD = 'SELECT_USER_CUSTOM_FIELD';
export const UN_SELECT_USER_CUSTOM_FIELD = 'UN_SELECT_USER_CUSTOM_FIELD';
export const ADD_USER_CUSTOM_FIELD = 'ADD_USER_CUSTOM_FIELD';
export const DELETE_USER_CUSTOM_FIELD = 'DELETE_USER_CUSTOM_FIELD';
export const UPDATE_USER_CUSTOM_FIELD = 'UPDATE_USER_CUSTOM_FIELD';

export const UPDATE_USER_CUSTOM_FIELD_START_PIECE = 'UPDATE_USER_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_USER_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_USER_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE';
export const REGISTER_USER_CUSTOM_FIELD_VARIABLE = 'REGISTER_USER_CUSTOM_FIELD_VARIABLE';

export const SELECT_USER_CUSTOM_FIELD_OPTION = 'SELECT_USER_CUSTOM_FIELD_OPTION';
export const UN_SELECT_USER_CUSTOM_FIELD_OPTION = 'UN_SELECT_USER_CUSTOM_FIELD_OPTION';
export const RE_ORDER_USER_CUSTOM_FIELD_OPTION = 'RE_ORDER_USER_CUSTOM_FIELD_OPTION';
export const ADD_USER_CUSTOM_FIELD_OPTION = 'ADD_USER_CUSTOM_FIELD_OPTION';
export const DELETE_USER_CUSTOM_FIELD_OPTION = 'DELETE_USER_CUSTOM_FIELD_OPTION';
export const UPDATE_USER_CUSTOM_FIELD_OPTION = 'UPDATE_USER_CUSTOM_FIELD_OPTION';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export interface INewUserData {

    project: string,
    level: string,
    role: string,
    locations: Array<string>,

    isAppUser: boolean,

    phone: Phone,
    language: string,

    password: string,

    customFields: {
        [customFieldId: string]: CustomFieldValueType,
    }
}

export interface IUpdateableUserData extends INewUserData {
    id: string,
}

export interface IUser extends IUpdateableUserData, Synchronizable {
    archived?: boolean,
    createdTime: string,
    
    workflows: {
        [workflowTypeId: string]: Array<string>,
    },
}

export interface UserState extends NormalizedModel<IUser>, CustomFieldState {

    pageSize: number,
    currentPageNumber: number,
    filters: {
        projects: Array<string>,
        levels: Array<string>,
        roles: Array<string>,
        locations: Array<string>,
        customFields: {
            [fieldId: string]: Array<string>,
        }
    },
    sort: {
        column: string|undefined,
        order: 'ASC'|'DESC',
    },
    searchTerm: string|undefined,

    nameFieldId: string;
    subTitleFieldId: string;
    locationFieldId: string;
}

export interface AddUserAction {
    type: typeof ADD_USER,
    payload: IUser,
    currentTime: string,
}

export interface UpdateUserRequestAction {
    type: typeof UPDATE_USER_REQUEST,
    payload: IUpdateableUserData,
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: IUpdateableUserData,
    currentTime: string,
}

export interface DeleteUserAction {
    type: typeof DELETE_USER,
    id: string,
    currentTime: string,
}

export interface UpdateUserWorkflowAction {
    type: typeof UPDATE_USER_WORKFLOWS,
    userId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface UpdateUserCustomFieldDataAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD_DATA,
    userId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}


export interface SearchUserTableAction {
    type: typeof SEARCH_USER_TABLE,
    searchTerm: string,
}

export interface FilterUserTableAction {
    type: typeof FILTER_USER_TABLE,
    projects: Array<string>,
    levels: Array<string>,
    roles: Array<string>,
    locations: Array<string>,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
}

export interface GoToPageUserTableAction {
    type: typeof GO_TO_PAGE_USER_TABLE,
    pageNumber: number,
}

export interface SetPageSizeUserTableAction {
    type: typeof SET_PAGE_SIZE_USER_TABLE,
    pageSize: number,
}

export interface SortUserTableAction {
    type: typeof SORT_USER_TABLE,
    column: string,
    order: 'ASC'|'DESC'
}


export interface SelectUserCustomFieldAction {
    type: typeof SELECT_USER_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectUserCustomFieldAction {
    type: typeof UN_SELECT_USER_CUSTOM_FIELD,
}

export interface AddUserCustomFieldAction {
    type: typeof ADD_USER_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    currentTime: string,
}

export interface DeleteUserCustomFieldAction {
    type: typeof DELETE_USER_CUSTOM_FIELD,
    id: string,
    currentTime: string,
}

export interface UpdateUserCustomFieldAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    currentTime: string,
}


export interface UpdateUserCustomFieldStartPieceAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface SetIsolatedUserCustomFieldPieceAction {
    type: typeof SET_ISOLATED_USER_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface RemoveIsolatedUserCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
}

export interface RegisterUserCustomFieldVariableAction {
    type: typeof REGISTER_USER_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
}


export interface SelectUserCustomFieldOptionAction {
    type: typeof SELECT_USER_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectUserCustomFieldOptionAction {
    type: typeof UN_SELECT_USER_CUSTOM_FIELD_OPTION,
}

export interface ReOrderUserCustomFieldOptionAction {
    type: typeof RE_ORDER_USER_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddUserCustomFieldOptionAction {
    type: typeof ADD_USER_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
    currentTime: string,
}

export interface DeleteUserCustomFieldOptionAction {
    type: typeof DELETE_USER_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateUserCustomFieldOptionAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    currentTime: string,
}

export interface UpdateUserData {
    type: typeof UPDATE_USER_DATA,
    data: UserState,
}

type UserActions = AddUserAction | UpdateUserRequestAction |UpdateUserAction | DeleteUserAction | UpdateUserWorkflowAction | UpdateUserCustomFieldDataAction;

type UserTableActions = SearchUserTableAction | FilterUserTableAction | GoToPageUserTableAction | SetPageSizeUserTableAction | SortUserTableAction;

type CustomFieldActions = SelectUserCustomFieldAction | UnSelectUserCustomFieldAction | AddUserCustomFieldAction | DeleteUserCustomFieldAction | UpdateUserCustomFieldAction;

type CustomFieldChoiceActions = SelectUserCustomFieldOptionAction | UnSelectUserCustomFieldOptionAction | ReOrderUserCustomFieldOptionAction | AddUserCustomFieldOptionAction | DeleteUserCustomFieldOptionAction | UpdateUserCustomFieldOptionAction;

export type UserActionTypes = UserActions | UserTableActions | UpdateUserCustomFieldStartPieceAction | SetIsolatedUserCustomFieldPieceAction | RemoveIsolatedUserCustomFieldPieceAction | RegisterUserCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions | UpdateUserData;