import { SELECT_LOCATION, UN_SELECT_LOCATION, RE_ORDER_LOCATIONS, ADD_LOCATION_REQUEST, ADD_LOCATION, DELETE_LOCATION, UPDATE_LOCATION, ADD_USER_TO_LOCATION, REMOVE_USER_FROM_LOCATION, UPDATE_LOCATION_CUSTOM_FIELD_DATA, ADD_MEMBER_TO_LOCATION, REMOVE_MEMBER_FROM_LOCATION, ADD_GROUP_TO_LOCATION, REMOVE_GROUP_FROM_LOCATION, SelectLocationAction, UnSelectLocationAction, ReOrderLocationsAction, AddLocationRequestAction, AddLocationAction, UpdateLocationAction, UpdateLocationCustomFieldDataAction, DeleteLocationAction, AddUserToLocationAction, RemoveUserFromLocationAction, AddMemberToLocationAction, RemoveMemberFromLocationAction, AddGroupToLocationAction, RemoveGroupFromLocationAction } from './types';
import { INewLocationData, IUpdateableLocationData } from './types';
import { v4 as uuidv4 } from 'uuid';
import { CustomFieldDataHolder } from '../../custom-fields';
import moment from 'moment';

export function selectLocation(id: string, index: number): SelectLocationAction {
    return {
        type: SELECT_LOCATION,
        id,
        index,
    }
}

export function unSelectLocation(index: number): UnSelectLocationAction {
    return {
        type: UN_SELECT_LOCATION,
        index,
    }
}

export function reOrderLocations(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderLocationsAction {
    return {
        type: RE_ORDER_LOCATIONS,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addLocationRequest(payload: INewLocationData, parentId: string): AddLocationRequestAction {
    return {
        type: ADD_LOCATION_REQUEST,
        payload,
        parentId,
    }
}

export function addLocation(payload: INewLocationData, parentId: string): AddLocationAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_LOCATION,
        payload: {
            id: uuidv4(),
            ...payload,
            parent: parentId,
            children: [],
            users: [],
            members: [],
            groups: [],
            createdTime: now,
            lastUpdatedTime: now,
        },
        parentId,
        currentTime: now,
    }
}

export function updateLocation(payload: IUpdateableLocationData): UpdateLocationAction {
    return {
        type: UPDATE_LOCATION,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function updateLocationCustomFieldData(locationId: string, customFieldData: CustomFieldDataHolder): UpdateLocationCustomFieldDataAction {
    return {
        type: UPDATE_LOCATION_CUSTOM_FIELD_DATA,
        locationId,
        customFieldData,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function deleteLocation(id: string, parentId: string): DeleteLocationAction {
    return {
        type: DELETE_LOCATION,
        id,
        parentId,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function addUserToLocation(userId: string, locationId: string): AddUserToLocationAction {
    return {
        type: ADD_USER_TO_LOCATION,
        userId,
        locationId,
    };
}

export function removeUserFromLocation(userId: string, locationId: string): RemoveUserFromLocationAction {
    return {
        type: REMOVE_USER_FROM_LOCATION,
        userId,
        locationId,
    };
}

export function addMemberToLocation(memberId: string, locationId: string): AddMemberToLocationAction {
    return {
        type: ADD_MEMBER_TO_LOCATION,
        memberId,
        locationId,
    };
}

export function removeMemberFromLocation(memberId: string, locationId: string): RemoveMemberFromLocationAction {
    return {
        type: REMOVE_MEMBER_FROM_LOCATION,
        memberId,
        locationId,
    };
}

export function addGroupToLocation(groupId: string, locationId: string): AddGroupToLocationAction {
    return {
        type: ADD_GROUP_TO_LOCATION,
        groupId,
        locationId,
    };
}

export function removeGroupFromLocation(groupId: string, locationId: string): RemoveGroupFromLocationAction {
    return {
        type: REMOVE_GROUP_FROM_LOCATION,
        groupId,
        locationId,
    };
}