import React from 'react';
import styles from './Workflows.module.scss';
import { default as tabStyles } from '../../widgets/tabs/Tabs.module.scss';
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import { ApplicationState } from '../../shared/store/main';

import { Permissions } from '../../shared/store/permissions/types';

import Tabs from '../../widgets/tabs/Tabs';

import Configuration from './configuration/Configuration';
import List from './list/List';

import { translatePhrase } from '../../shared/helpers/translation';

import { connect } from 'react-redux';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewWorkflowConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;
    const canViewWorkflowList = state.permissions.myPermissions.general.Workflows === Permissions.WRITE || state.permissions.myPermissions.general.Workflows === Permissions.READ;
    
    return {
        showTranslations: false,
        translationsExpanded: false,
        canGoToConfiguration: canViewWorkflowConfiguration,
        canGoToList: canViewWorkflowList,
        hasWorkflows: state.workflows.allEntries.length > 0,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedWorkflows: React.FC<Props> = (props) => {
    
    if (!(props.canGoToConfiguration || props.canGoToList)) {
        return <Redirect to="/dashboard" />;
    }
    
    return (
        <section className={props.showTranslations && props.translationsExpanded ? styles.ConstrainedFocusSpace : styles.FocusSpace}>
            <Tabs>
                {props.canGoToList && <NavLink to="/workflows/list" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('List')} </NavLink>}
                {props.canGoToConfiguration && <NavLink to="/workflows/configuration" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Configuration')} </NavLink>}
            </Tabs>
            
            <Switch>
                <Route path="/workflows/configuration" component={Configuration} />
                <Route path="/workflows/list" component={List} />
                <Route render={() => <Redirect to={props.canGoToList && (props.hasWorkflows || !props.canGoToConfiguration) ? '/workflows/list' : '/workflows/configuration'} />}/>
            </Switch>
        </section>
    );
};

const Workflows = connect(mapStateToProps)(ConnectedWorkflows);

export default Workflows;