import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type AddYearsOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const AddYearsOperator: FC<AddYearsOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator prefixSymbol="add" operatorSymbol="year(s) to" {...props} />
    )
}

export default AddYearsOperator;