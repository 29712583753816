import React, { Component } from 'react';
import styles from './LocationsVertical.module.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from '@khanacademy/react-multi-select';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';
import { INewLocationData, IUpdateableLocationData, ILocation } from '../../../shared/store/structure/location/types';
import { CustomFieldDataType, CustomFieldOptionsDataType, CustomFieldValueType, FieldType } from '../../../shared/store/custom-fields';

export interface OwnProps {
    selectedId?: string,
    levelId: string,
    heading: string,
    isReadOnly: boolean,
    
    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    customFieldIds: Array<string>,

    customFieldsData: CustomFieldDataType,
    customFieldOptionsData: CustomFieldOptionsDataType,

    cardsList: Array<ILocation>,
    selectedCard: ILocation|undefined,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: INewLocationData) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: IUpdateableLocationData) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,

    customFields: {
        [customFieldId: string]: CustomFieldValueType,
    }
};

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedCard && prevProps.selectedId !== this.props.selectedId) {
            this.setState({
                customFields: this.props.selectedCard.customFields
            });
        }
    }

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;
        
        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }
    
    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name
        });
    }
    
    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }
    
    addCard = () => {
        
        this.props.addCard({
            name: this.state.modifyingCardName,
            customFields: this.state.customFields,
        });
        
        this.setState({
            modifyingCardName: '',
            isShowingAddForm: false,
            customFields: {},
        });
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        const customFields = {
            ...this.state.customFields,
            [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
        }

        this.setState({
            customFields,
        });
    }
    
    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            customFields: this.state.customFields,
        });
        
        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            isShowingAddForm: false,
            customFields: {},
        });
    }
    
    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }
        
        return true;
    }

    render() {

        const customFieldsMarkup = this.props.customFieldIds.map((fieldId, index) => {
            const field = this.props.customFieldsData.byId[fieldId];
            const choices = field.choices.map(choiceId => {
                return {
                    name: this.props.customFieldOptionsData.byId[choiceId].name,
                    value: choiceId,
                }
            });
            const customFieldValue = this.state.customFields[fieldId];

            if (field.isComputed) {
                return undefined;
            }
            
            switch(field.type) {
                case FieldType.SINGLE_SELECT:
                    if (Array.isArray(customFieldValue)) {
                        throw new Error('A single select field should not have an array value.');
                    }

                    if (typeof customFieldValue === 'boolean') {
                        throw new Error('A single select field should not have a boolean value.');
                    }

                    return (<InputText 
                        placeholder={field.name} 
                        onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} 
                        onChange={value => this.changeCustomField(field.id, value)} 
                        default={this.props.selectedId && customFieldValue ? this.props.customFieldOptionsData.byId[customFieldValue].name : ''} 
                        key={field.id} 
                        options={choices} 
                    />);
                
                case FieldType.NUMBER:
                    if (Array.isArray(customFieldValue)) {
                        throw new Error('A text field should not have an array value.');
                    }

                    if (typeof customFieldValue === 'boolean') {
                        throw new Error('A number field should not have a boolean value.');
                    }

                    return (<InputText 
                        placeholder={field.name} 
                        type="number"
                        onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} 
                        onChange={value => this.changeCustomField(field.id, value)} 
                        default={this.props.selectedId && customFieldValue ? String(customFieldValue): ''}
                        key={field.id}
                    />);
                
                case FieldType.TEXT:
                    if (Array.isArray(customFieldValue)) {
                        throw new Error('A text field should not have an array value.');
                    }

                    if (typeof customFieldValue === 'boolean') {
                        throw new Error('A text field should not have a boolean value.');
                    }
                    
                    return (<InputText 
                        placeholder={field.name} 
                        onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} 
                        onChange={value => this.changeCustomField(field.id, value)} 
                        default={this.props.selectedId && customFieldValue ? String(customFieldValue): ''}
                        key={field.id}
                    />);
                
                case FieldType.BOOLEAN:
                    const binaryOptions = ['Yes', 'No'];
                    
                    if (typeof customFieldValue !== 'boolean' && typeof customFieldValue !== 'undefined') {
                        throw new Error('A boolean field should have a boolean value.');
                    }
                    
                    return (<InputText 
                        placeholder={field.name} 
                        onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} 
                        onChange={value => this.changeCustomField(field.id, value === 'Yes')} 
                        default={this.props.selectedId ? customFieldValue ? 'Yes' : 'No' : ''}
                        key={field.id}
                        options={binaryOptions}
                    />);
                
                case FieldType.DATE:
                    if (Array.isArray(customFieldValue)) {
                        throw new Error('A date field should not have an array value.');
                    }

                    if (typeof customFieldValue === 'boolean') {
                        throw new Error('A date field should not have a boolean value.');
                    }

                    return (<div key={field.id} className={styles.dateHolder}>
                        <div className={styles.label}>{field.name}</div>
                        <DatePicker selected={customFieldValue ? new Date(customFieldValue) : undefined} dateFormat="dd-MMM-yyyy" onChange={value => this.changeCustomField(field.id, value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} /> 
                    </div>)
                
                case FieldType.MULTI_SELECT:
                    if (typeof customFieldValue === 'string') {
                        throw new Error('A multi select field should have an array value');
                    }

                    const multiSelectChoices = choices.map(choice => {
                        return {
                            label: choice.name, 
                            value: choice.value,
                        };
                    });

                    return (<div key={field.id} className={styles.multiSelectHolder}>
                        <MultiSelect
                            options={multiSelectChoices}
                            onSelectedChanged={this.changeCustomField.bind(this, field.id)}
                            selected={customFieldValue ? customFieldValue : []}
                            overrideStrings={{
                                selectSomeItems: 'Select ' + field.name + 's',
                                allItemsAreSelected: 'All ' + field.name + 's are selected',
                            }}
                        />
                    </div>)
            }
            
            return null;
        });

        const modifyForm = <ModifyForm key={this.props.selectedId} isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <InputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            {customFieldsMarkup}
        </ModifyForm>;

        return <CardsList 
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard} 
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard} 
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
        />
    }
}

export default CardTreeLevel;