import { NormalizedModel } from '../../normalized-model';

export const ADD_VARIABLE = 'ADD_VARIABLE';
export const DELETE_VARIABLE = 'DELETE_VARIABLE';
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';


export enum VariableType {
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    TEXT = 'TEXT',
    DATE = 'DATE',

    USER = 'USER',
    MEMBER = 'MEMBER',
    GROUP = 'GROUP',
    LOCATION = 'LOCATION',
    ROLE = 'ROLE',
    LEVEL = 'LEVEL',
    PROJECT = 'PROJECT',
    WORKFLOW = 'WORKFLOW',
    REPORT = 'REPORT',

    TEXT_LIST = 'TEXT_LIST',
    USERS_LIST = 'USERS_LIST',
    MEMBERS_LIST = 'MEMBERS_LIST',
    GROUPS_LIST = 'GROUPS_LIST',
    LOCATIONS_LIST = 'LOCATIONS_LIST',
    ROLES_LIST = 'ROLES_LIST',
    LEVELS_LIST = 'LEVELS_LIST',
    PROJECTS_LIST = 'PROJECTS_LIST',
    WORKFLOWS_LIST = 'WORKFLOWS_LIST',
    REPORTS_LIST = 'REPORTS_LIST',

    TABLE_DATA = 'TABLE_DATA',
}

export interface INewVariableData {
    name: string;
    type: VariableType,
}

export interface IUpdateableVariableData extends INewVariableData {
    id: string,
}

export interface IVariable extends IUpdateableVariableData {
    archived?: boolean,
    createdTime: string,
    lastUpdatedTime: string,
    lastSyncedTime?: string,
}

export interface VariableState extends NormalizedModel<IVariable> {
}

export interface AddVariableAction {
    type: typeof ADD_VARIABLE,
    payload: IUpdateableVariableData,
    createdTime: string,
}

export interface DeleteVariableAction {
    type: typeof DELETE_VARIABLE,
    id: string,
    parentId: string,
}

export interface UpdateVariableAction {
    type: typeof UPDATE_VARIABLE,
    payload: IUpdateableVariableData,
}

export type VariableActionTypes = AddVariableAction | DeleteVariableAction | UpdateVariableAction;