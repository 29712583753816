export const UPDATE_GENERAL_PERMISSION = 'UPDATE_GENERAL_PERMISSION';

export const UPDATE_MEMBER_PERMISSION = 'UPDATE_MEMBER_PERMISSION';
export const UPDATE_GROUP_PERMISSION = 'UPDATE_GROUP_PERMISSION';
export const UPDATE_WORKFLOW_PERMISSION = 'UPDATE_WORKFLOW_PERMISSION';
export const UPDATE_REPORT_PERMISSION = 'UPDATE_REPORT_PERMISSION';

export const UPDATE_PERMISSION_DATA = 'UPDATE_PERMISSION_DATA';

export enum Permissions {
    NONE = 'NONE',
    READ = 'READ',
    WRITE = 'WRITE',
}

export const DEFAULT_GENERAL_PERMISSIONS = {
    Hierarchy: Permissions.READ,
    Locations: Permissions.READ,
    Users: Permissions.READ,
    UserConfiguration: Permissions.READ,
    Members: Permissions.READ,
    MembersConfiguration: Permissions.READ,
    GroupsConfiguration: Permissions.READ,
    Groups: Permissions.READ,
    WorkflowsConfiguration: Permissions.READ,
    Workflows: Permissions.READ,
    OrganizationProfile: Permissions.READ,
    Languages: Permissions.READ,
    Reports: Permissions.READ,
    ReportsConfiguration: Permissions.READ,
}

export type PermissionSet = {
    general: {
        Hierarchy: Permissions,
        Locations: Permissions,
        Users: Permissions,
        UserConfiguration: Permissions,
        Members: Permissions,
        MembersConfiguration: Permissions,
        GroupsConfiguration: Permissions,
        Groups: Permissions,
        WorkflowsConfiguration: Permissions,
        Workflows: Permissions,
        OrganizationProfile: Permissions,
        Languages: Permissions,
        Reports: Permissions,
        ReportsConfiguration: Permissions,
        [id: string]: Permissions,
    },
    members: {
        [memberTypeId: string]: Permissions,
    },
    groups: {
        [groupTypeId: string]: Permissions,
    },
    workflows: {
        [workflowTypeId: string]: Permissions,
    },
    reports: {
        [reportTypeId: string]: Permissions,
    },
}

export interface PermissionState {
    myPermissions: PermissionSet,
    rolePermissions: {
        [id: string]: PermissionSet,
    },
    updatedIds: Set<string>,
}

export interface UpdateGeneralPermission {
    type: typeof UPDATE_GENERAL_PERMISSION,
    id: string,
    role: string,
    permission: Permissions,
}


export interface UpdateMemberPermission {
    type: typeof UPDATE_MEMBER_PERMISSION,
    memberTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateGroupPermission {
    type: typeof UPDATE_GROUP_PERMISSION,
    groupTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateWorkflowPermission {
    type: typeof UPDATE_WORKFLOW_PERMISSION,
    workflowTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateReportPermission {
    type: typeof UPDATE_REPORT_PERMISSION,
    reportTypeId: string,
    role: string,
    permission: Permissions,
}

export interface IUpdatePermissionData {
    type: typeof UPDATE_PERMISSION_DATA,
    data: PermissionState,
}

export type PermissionActionTypes = UpdateGeneralPermission & UpdateMemberPermission & UpdateGroupPermission & UpdateWorkflowPermission & UpdateReportPermission & IUpdatePermissionData;