import { ApplicationState } from '../../../main';
import { FlowchartProcessState, DefaultFlowchartProcessState, VariableValueType } from '../../types';

import { getNextPieceId, executePiece } from '../index';


export function getNextPieceIdForComputedField(applicationState: ApplicationState, processState: FlowchartProcessState, startPiece: string|undefined, getPieceValueFromAbove?: (pieceId: string) => VariableValueType): string|undefined {
    
    const getTempNextPieceIdShortHand = getNextPieceIdForComputedField.bind({}, applicationState, processState, startPiece);
    const getNextPieceIdShortHand = getTempNextPieceIdShortHand.bind({}, getPieceValueFromAbove);

    return getNextPieceId(applicationState, processState, startPiece, getNextPieceIdShortHand, getPieceValueFromAbove);
}

export type ExecutePieceReturnType = {
    canContinueExecution: boolean,
    returnValue: VariableValueType,
};

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForComputedField(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, pieceId: string): ExecutePieceReturnType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    switch(piece.type) {

        default:
            const canContinueExecution = executePiece(applicationState, processState, pieceId);

            return {
                canContinueExecution,
                returnValue: undefined,
            };
    }
}

export function getComputedFieldValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, startPiece: string|undefined) {
    let executionResult: ExecutePieceReturnType;

    do {
        const nextPieceId = getNextPieceIdForComputedField(applicationState, processState, startPiece);

        if (typeof nextPieceId === 'undefined') {
            throw new Error('The next piece does not exist');
        }

        executionResult = executePieceForComputedField(applicationState, processState, nextPieceId);
    } while (executionResult.canContinueExecution);

    return executionResult.returnValue;
}