import { IWidgetConfigurationState, UPDATE_WIDGET_SIZE, UPDATE_WIDGET_POSITION, REGISTER_WIDGET_CREATION, UNREGISTER_WIDGET_CREATION } from './types';
import { MyDataActions } from '../types';
import { FetchDataRquestAction } from '../../types';

export const initialState: IWidgetConfigurationState = {
    byId: {},
    updatedIds: new Set(),
    myWidgets: [],
    createdConfigurations: [],
    deletedConfigurations: [],
};

export function widgetsReducer(state = initialState, action: MyDataActions | FetchDataRquestAction) {

    let createdReverseLinks: Array<string> = [];
    let deletedReverseLinks: Array<string> = [];

    switch (action.type) {

        case UPDATE_WIDGET_SIZE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        width: action.width,
                        height: action.height,
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            };

        case UPDATE_WIDGET_POSITION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        x: action.x,
                        y: action.y,
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            };

        case REGISTER_WIDGET_CREATION:

            deletedReverseLinks = state.deletedConfigurations.filter(widgetId => widgetId !== action.widgetId);
            createdReverseLinks = Array.from((new Set(state.createdConfigurations)).add(action.widgetId));

            return {
                ...state,
                myWidgets: state.myWidgets.concat([action.widgetId]),
                createdConfigurations: createdReverseLinks,
                deletedConfigurations: deletedReverseLinks,
            };

        case UNREGISTER_WIDGET_CREATION:

            deletedReverseLinks = Array.from((new Set(state.deletedConfigurations)).add(action.widgetId));
            createdReverseLinks = state.createdConfigurations.filter(widgetId => widgetId !== action.widgetId);

            return {
                ...state,
                myWidgets: state.myWidgets.filter(widgetId => widgetId !== action.widgetId),
                createdConfigurations: createdReverseLinks,
                deletedConfigurations: deletedReverseLinks,
            };
        default:
            return state;
    }
}