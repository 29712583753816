import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type AdditionOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const AdditionOperator: FC<AdditionOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator operatorSymbol="+" {...props} />
    )
}

export default AdditionOperator;