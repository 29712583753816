import { WidgetState, ADD_WIDGET, UPDATE_WIDGET, DELETE_WIDGET, UPDATE_WIDGET_DATA, WidgetActionTypes, REGISTER_WIDGET_VARIABLE, UPDATE_WIDGET_START_PIECE, SET_ISOLATED_WIDGET_PIECE, REMOVE_ISOLATED_WIDGET_PIECE } from './types';

export const initialState: WidgetState = {
    byId: {},
    allEntries: [],
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    byRole: {},
};

export function widgetsReducer(state = initialState, action: WidgetActionTypes): WidgetState {

    let newRolesLinks: {
        [roleId: string]: Array<string>,
    } = {};

    switch (action.type) {
        case ADD_WIDGET:
            newRolesLinks = {
                ...state.byRole
            };

            for (let role of action.payload.roles) {
                newRolesLinks = {
                    ...newRolesLinks,
                    [role]: newRolesLinks[role] ? newRolesLinks[role].concat(action.payload.id) : [action.payload.id],
                };
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        variables: [action.payload.seedEntityVariable],
                        isolatedPieces: [],
                    },
                },
                byRole: newRolesLinks,
                allEntries: state.allEntries.concat([action.payload.id]),
            };
        
        case UPDATE_WIDGET:
            newRolesLinks = {
                ...state.byRole
            };

            for (let role of state.byId[action.payload.id].roles) {
                newRolesLinks = {
                    ...newRolesLinks,
                    [role]: newRolesLinks[role].filter(widgetId => widgetId !== action.payload.id),
                };
            }

            for (let role of action.payload.roles) {
                newRolesLinks = {
                    ...newRolesLinks,
                    [role]: newRolesLinks[role] ? newRolesLinks[role].concat(action.payload.id) : [action.payload.id],
                };
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    },
                },
                byRole: newRolesLinks,
            };

        case DELETE_WIDGET:
            newRolesLinks = {
                ...state.byRole
            };

            for (let role of state.byId[action.id].roles) {
                newRolesLinks = {
                    ...newRolesLinks,
                    [role]: newRolesLinks[role].filter(widgetId => widgetId !== action.id),
                };
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                byRole: newRolesLinks,
                allEntries: state.allEntries.filter(userId => userId !== action.id),
            };

        case UPDATE_WIDGET_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            };

        case UPDATE_WIDGET_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        startPiece: action.payload,
                    }
                }
            }

        case SET_ISOLATED_WIDGET_PIECE:
            const newWidgetIsolatedPieces = state.byId[action.widgetId].isolatedPieces.slice(0);
            const customFieldIsolatedPieceIndex = newWidgetIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);
        
            if (customFieldIsolatedPieceIndex < 0) {
                newWidgetIsolatedPieces.push(action.payload);
            } else {
                newWidgetIsolatedPieces[customFieldIsolatedPieceIndex] = action.payload;
            }
        
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        isolatedPieces: newWidgetIsolatedPieces,
                    }
                }
            }

        case REMOVE_ISOLATED_WIDGET_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        isolatedPieces: state.byId[action.widgetId].isolatedPieces.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                }
            }


        case REGISTER_WIDGET_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        variables: state.byId[action.widgetId].variables.concat([action.variableId]),
                    }
                }
            }
        
        default:
            return state;
    }
}