import { SELECT_GROUP_TYPE, UN_SELECT_GROUP_TYPE, RE_ORDER_GROUP_TYPES, ADD_GROUP_TYPE_REQUEST, ADD_GROUP_TYPE, DELETE_GROUP_TYPE, UPDATE_GROUP_TYPE, ADD_GROUP_TO_GROUP_TYPE, REMOVE_GROUP_FROM_GROUP_TYPE, SELECT_GROUP_TYPE_CUSTOM_FIELD, UN_SELECT_GROUP_TYPE_CUSTOM_FIELD, ADD_GROUP_TYPE_CUSTOM_FIELD, DELETE_GROUP_TYPE_CUSTOM_FIELD, UPDATE_GROUP_TYPE_CUSTOM_FIELD, SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION, UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION, RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION, ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION, DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION, UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION, INewGroupTypeData, IUpdateableGroupTypeData, UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE, REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE, ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP, UPDATE_GROUP_TYPE_MANAGEMENT, DISCARD_GROUP_TYPE_MANAGEMENT, SelectGroupTypeAction, UnSelectGroupTypeAction, ReOrderGroupTypesAction, AddGroupTypeRequestAction, AddGroupTypeAction, UpdateGroupTypeAction, DeleteGroupTypeAction, AddGroupToGroupTypeAction, RemoveGroupFromGroupTypeAction, SelectGroupTypeCustomFieldAction, UnSelectGroupTypeCustomFieldAction, AddGroupTypeCustomFieldAction, DeleteGroupTypeCustomFieldAction, UpdateGroupTypeCustomFieldAction, UpdateGroupTypeCustomFieldStartPieceAction, SetIsolatedGroupTypeCustomFieldPieceAction, RemoveIsolatedGroupTypeCustomFieldPieceAction, RegisterGroupTypeCustomFieldVariableAction, SelectGroupTypeCustomFieldOptionAction, UnSelectGroupTypeCustomFieldOptionAction, ReOrderGroupTypeCustomFieldOptionAction, AddGroupTypeCustomFieldOptionAction, DeleteGroupTypeCustomFieldOptionAction, UpdateGroupTypeCustomFieldOptionAction, AddGroupTypeManagementCustomFieldMapAction, UpdateGroupTypeManagementAction, DiscardGroupTypeManagementAction } from './types';
import { INewCustomFieldData, IUpdateableCustomFieldData, INewFieldChoiceData, IUpdateableFieldChoiceData } from '../../custom-fields';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { PiecePositionState } from '../../flowchart/pieces/types';

export function selectGroupType(id: string): SelectGroupTypeAction {
    return {
        type: SELECT_GROUP_TYPE,
        id,
    }
}

export function unSelectGroupType(): UnSelectGroupTypeAction {
    return {
        type: UN_SELECT_GROUP_TYPE,
    }
}

export function reOrderGroupTypes(sourceIndex: number, destinationIndex: number): ReOrderGroupTypesAction {
    return {
        type: RE_ORDER_GROUP_TYPES,
        sourceIndex,
        destinationIndex,
    }
}

export function addGroupTypeRequest(payload: INewGroupTypeData): AddGroupTypeRequestAction {
    return {
        type: ADD_GROUP_TYPE_REQUEST,
        payload,
    }
}

export function addGroupType(payload: INewGroupTypeData): AddGroupTypeAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_GROUP_TYPE,
        payload: {
            id: uuidv4(),
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            groups: [],
            customFields: [],
        },
    }
}

export function updateGroupType(payload: IUpdateableGroupTypeData): UpdateGroupTypeAction {
    return {
        type: UPDATE_GROUP_TYPE,
        payload,
    }
}

export function deleteGroupType(id: string): DeleteGroupTypeAction {
    return {
        type: DELETE_GROUP_TYPE,
        id,
    }
}

export function addGroupToGroupType(groupId: string, groupTypeId: string): AddGroupToGroupTypeAction {
    return {
        type: ADD_GROUP_TO_GROUP_TYPE,
        groupTypeId,
        groupId,
    }
}

export function removeGroupFromGroupType(groupId: string, groupTypeId: string): RemoveGroupFromGroupTypeAction {
    return {
        type: REMOVE_GROUP_FROM_GROUP_TYPE,
        groupTypeId,
        groupId,
    }
}

export function selectGroupTypeCustomField(id: string): SelectGroupTypeCustomFieldAction {
    return {
        type: SELECT_GROUP_TYPE_CUSTOM_FIELD,
        id,
    }
}

export function unSelectGroupTypeCustomField(): UnSelectGroupTypeCustomFieldAction {
    return {
        type: UN_SELECT_GROUP_TYPE_CUSTOM_FIELD,
    }
}

export function addGroupTypeCustomField(payload: INewCustomFieldData, groupTypeId: string): AddGroupTypeCustomFieldAction {
    return {
        type: ADD_GROUP_TYPE_CUSTOM_FIELD,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        groupTypeId,
    }
}

export function deleteGroupTypeCustomField(id: string, groupTypeId: string): DeleteGroupTypeCustomFieldAction {
    return {
        type: DELETE_GROUP_TYPE_CUSTOM_FIELD,
        id,
        groupTypeId,
    }
}

export function updateGroupTypeCustomField(payload: IUpdateableCustomFieldData): UpdateGroupTypeCustomFieldAction {
    return {
        type: UPDATE_GROUP_TYPE_CUSTOM_FIELD,
        payload
    }
}

export function updateGroupTypeCustomFieldStartPiece(payload: PiecePositionState, customFieldId: string): UpdateGroupTypeCustomFieldStartPieceAction {
    return {
        type: UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE,
        payload,
        customFieldId
    }
}

export function setIsolatedGroupTypeCustomFieldPiece(payload: PiecePositionState, customFieldId: string): SetIsolatedGroupTypeCustomFieldPieceAction {
    return {
        type: SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE,
        payload,
        customFieldId
    }
}

export function removeIsolatedGroupTypeCustomFieldPiece(pieceId: string, customFieldId: string): RemoveIsolatedGroupTypeCustomFieldPieceAction {
    return {
        type: REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE,
        pieceId,
        customFieldId
    }
}

export function registerGroupTypeCustomFieldVariable(variableId: string, customFieldId: string): RegisterGroupTypeCustomFieldVariableAction {
    return {
        type: REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE,
        variableId,
        customFieldId,
    }
}


export function selectGroupTypeCustomFieldOption(id: string): SelectGroupTypeCustomFieldOptionAction {
    return {
        type: SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION,
        id,
    }
}

export function unSelectGroupTypeCustomFieldOption(): UnSelectGroupTypeCustomFieldOptionAction {
    return {
        type: UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION,
    }
}

export function reOrderGroupTypeCustomFieldOption(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderGroupTypeCustomFieldOptionAction {
    return {
        type: RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addGroupTypeCustomFieldOption(payload: INewFieldChoiceData, parentId: string): AddGroupTypeCustomFieldOptionAction {
    return {
        type: ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        parentId,
    }
}

export function deleteGroupTypeCustomFieldOption(id: string, parentId: string): DeleteGroupTypeCustomFieldOptionAction {
    return {
        type: DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION,
        id,
        parentId,
    }
}

export function updateGroupTypeCustomFieldOption(payload: IUpdateableFieldChoiceData): UpdateGroupTypeCustomFieldOptionAction {
    return {
        type: UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION,
        payload,
    }
}

export function updateGroupTypeManagement(groupTypeId: string, workflowTypeId: string, lastQuestionPieceId: string): UpdateGroupTypeManagementAction {
    return {
        type: UPDATE_GROUP_TYPE_MANAGEMENT,
        groupTypeId,
        workflowTypeId,
        lastQuestionPieceId,
    }
}

export function addGroupTypeManagementCustomFieldMap(groupTypeId: string, groupTypeCustomFieldId: string, workflowTypeCustomFieldId: string): AddGroupTypeManagementCustomFieldMapAction {
    return {
        type: ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP,
        groupTypeId,
        groupTypeCustomFieldId,
        workflowTypeCustomFieldId,
    }
}

export function discardGroupTypeManagement(groupTypeId: string): DiscardGroupTypeManagementAction {
    return {
        type: DISCARD_GROUP_TYPE_MANAGEMENT,
        groupTypeId,
    }
}