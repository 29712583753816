import React, { Component } from 'react';
import styles from './Table.module.scss';

import DecrementingCounter from './DecrementingCounter';

import { translatePhrase } from '../../shared/helpers/translation';

import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as ViewIcon } from '../../assets/eye.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/trash.svg';
import { ReactComponent as ShareIcon } from '../../assets/share.svg';
import { copyStringToClipboard } from '../../shared/helpers/utilities';

export type TableHeading = {
    name: string,
    isSortable: boolean,
    isSticky?: boolean,
    width: number,
}

type TableEntry = JSX.Element | number | string;

export type TableRow = {
    id: string,
    entries: Array<TableEntry>,
    editForm?: JSX.Element,
    showViewInsteadOfEdit?: boolean,
    shareText?: string,
}

type OwnProps = {
    headings: Array<TableHeading>,
    entries: Array<TableRow>,

    sortedColumn?: string,
    sortType?: 'ASC'|'DESC',
    isReadOnly: boolean,
    areFiltersExpanded: boolean,
    isShowingModifyForm: boolean,

    onSort?: (name: string) => void,
    onDelete?: (id: string) => void,
    showModifyForm?: () => void,
};

type OwnState = {
    selectedRow: string|undefined,
    deleteTimer: number|undefined,
};

class Table extends Component<OwnProps, OwnState> {

    state: OwnState = {
        selectedRow: undefined,
        deleteTimer: undefined,
    };

    static defaultProps = {
        isReadOnly: false,
        areFiltersExpanded: false,
        isShowingModifyForm: false,
    };

    selectRow = (id: string) => {
        this.setState({
            selectedRow: id,
        });
    }
    
    deleteSelectedRow = () => {
        this.state.selectedRow && this.props.onDelete && this.props.onDelete(this.state.selectedRow);
    }
    
    markForDelete = () => {
        let that = this;
        const timeout = window.setTimeout(that.deleteSelectedRow, 5000);
        this.setState({
            deleteTimer: timeout
        });
    }
    
    cancelDelete = () => {
        clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined
        });
    }

    render() {
        const bodyMarkup = this.props.entries.map((row, index) => {
            const isMarkedForDelete = this.state.selectedRow && this.state.selectedRow === row.id && !!this.state.deleteTimer;

            const cellsMarkup = row.entries.map((entry, columnIndex) => {
                let cellClasses = styles.cell;
                let distanceFromLeft: string|number = 'auto';

                if (this.props.headings[columnIndex].isSticky) {
                    cellClasses = styles.cell + ' ' + styles.sticky;
                    distanceFromLeft = this.props.headings.slice(0, columnIndex).reduce((accumulatedValue, currentEntry) => accumulatedValue + currentEntry.width, 0);
                }

                return (<div 
                    key={columnIndex}
                    className={cellClasses} 
                    style={{width: this.props.headings[columnIndex].width, left: distanceFromLeft}}
                >
                    {entry}
                </div>);
            });
            
            return (
                <section key={row.id} className={isMarkedForDelete ? styles.inactiveRow : styles.row} onClick={e => this.selectRow(row.id)}>
                    {cellsMarkup}
                    {!this.props.isReadOnly && row.editForm && <div key="actions" className={styles.cell + ' ' + styles.actions + (this.state.selectedRow && this.state.selectedRow === row.id ? (' ' + styles.selectedAction) : '')}>
                        {!isMarkedForDelete ? 
                            <div>
                                {typeof row.showViewInsteadOfEdit !== 'undefined' && row.showViewInsteadOfEdit ? <ViewIcon onClick={this.props.showModifyForm} /> : <EditIcon onClick={this.props.showModifyForm} />}
                                <DeleteIcon onClick={this.markForDelete} />
                                {row.shareText && <ShareIcon onClick={copyStringToClipboard.bind(this, row.shareText)} />}
                            </div>
                        : <span><DecrementingCounter remaining={5} /> <button className={styles.undoDeleteButton} onClick={this.cancelDelete}>{translatePhrase('Undo')}</button></span>}
                        {this.props.isShowingModifyForm && this.state.selectedRow === row.id && <section className={this.props.areFiltersExpanded ? styles.editEntryFormHolder : styles.expandedEditEntryFormHolder}>{row.editForm}</section>}
                        
                    </div>}
                </section>
            );
        });
            

        let tableHeadingsMarkup = this.props.headings.map((heading, index) => {
            let sortingClasses = heading.isSortable ? ' ' + styles.sortable : '';

            if (this.props.sortedColumn && this.props.sortType && heading.name === this.props.sortedColumn) {
                sortingClasses += ' ' + (this.props.sortType === 'ASC' ? styles.sortedAsc : styles.sortedDesc);
            }

            let tableCellClasses = styles.cell + ' ' + sortingClasses;
            let distanceFromLeft: string|number = 'auto';

            if (heading.isSticky) {
                tableCellClasses += ' ' + styles.sticky;
                distanceFromLeft = this.props.headings.slice(0, index).reduce((accumulatedValue, currentEntry) => accumulatedValue + currentEntry.width, 0);
            }

        return (<div key={index} style={{width: heading.width, left: distanceFromLeft}} className={tableCellClasses} onClick={e => this.props.onSort && this.props.onSort(heading.name)}>{translatePhrase(heading.name)} {heading.isSortable && <SortIcon />}</div>)
        });

        if (!this.props.isReadOnly) {
            tableHeadingsMarkup.push(<div key="actions" className={styles.cell + ' ' + styles.actions}></div>);
        }

        return (
            <section className={styles.table}>
                <header>{tableHeadingsMarkup}</header>
                <section className={styles.tableBody}>
                    {bodyMarkup}
                </section>
            </section>
        );
    }
}

export default Table;