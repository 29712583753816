import React, { Component } from 'react';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece';
import styles from './step-piece/StepPiece.module.scss';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setReturnValue } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { valuePieceSlotTarget } from './utilities';


type ReturnPieceProps = {
    returnVariableText?: string,
    returnVariablePiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setReturnValue: (targetPieceId: string, draggedPieceId: string) => dispatch(setReturnValue(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReturnPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type ReturnPieceState = {
    isHoveringOverVariablePiece: boolean,
}

class ConnectedReturnPiece extends Component<Props, ReturnPieceState> {
    state = {
        isHoveringOverVariablePiece: false,
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            this.props.setReturnValue(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    handleReturnValueUpdate = (value: string) => {
        this.props.setReturnValue(this.props.pieceId, value);
    }

    render() {
        return (
            <StepPiece theme="aqua" {...this.props} hasLowerLock={false}>
                <div className={styles.text}>Return</div>
                {this.props.returnVariablePiece ? this.props.returnVariablePiece : <Input defaultText={this.props.returnVariableText} canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} onChange={this.handleReturnValueUpdate} />}
            </StepPiece>
        )
    }
}

const ReturnPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedReturnPiece);

export default ReturnPiece;