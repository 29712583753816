import React, { Component } from 'react';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/main';
import { setStructureProject, setStructureLevel, setStructureRole } from '../../../shared/store/flowchart/pieces/actions';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';

type OwnProps = {
    pieceId: string,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const structurePiece = state.flowchart.pieces.byId[ownProps.pieceId];

    if (structurePiece.type !== PieceType.STRUCTURE) {
        throw new Error('This piece must be a structure piece');
    }

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        structurePiece,
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        setStructureProject: (project: string|undefined) => dispatch(setStructureProject(ownProps.pieceId, project)),
        setStructureLevel: (project: string|undefined) => dispatch(setStructureLevel(ownProps.pieceId, project)),
        setStructureRole: (project: string|undefined) => dispatch(setStructureRole(ownProps.pieceId, project)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
};

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedStructurePiece extends Component<Props & FlowchartPieceProps, OwnState> {

    selectProject = (selected: string) => {
        this.props.setStructureProject(selected);
    }

    selectLevel = (selected: string) => {
        this.props.setStructureLevel(selected);
    }

    selectRole = (selected: string) => {
        this.props.setStructureRole(selected);
    }

    goBack = () => {
        if (!!this.props.structurePiece.role) {
            this.props.setStructureRole(undefined);
        } else if (!!this.props.structurePiece.level) {
            this.props.setStructureLevel(undefined);
        } else if (!!this.props.structurePiece.project) {
            this.props.setStructureProject(undefined);
        }
    }

    render() {
        let currentStructureHeading = '';
        let completeStructureHeading = '';
        let listEntries: Array<JSX.Element>;

        if (this.props.structurePiece.role) {
            if (!this.props.structurePiece.project || !this.props.structurePiece.level) {
                throw new Error('You cannot have a role without a project or level');
            }

            const projectName = this.props.projectsData.byId[this.props.structurePiece.project].name;
            const levelName = this.props.levelsData.byId[this.props.structurePiece.level].name;
            const roleName = this.props.rolesData.byId[this.props.structurePiece.role].name;

            currentStructureHeading = levelName;
            completeStructureHeading = `${projectName} > ${levelName} > ${roleName}`;

            listEntries = this.props.levelsData.byId[this.props.structurePiece.level].children.map(roleId => {
                return <ListItem key={roleId} theme="aqua" value={roleId} name={this.props.rolesData.byId[roleId].name} onClick={this.selectRole} />
            });
        } else if (this.props.structurePiece.level) {
            if (!this.props.structurePiece.project) {
            throw new Error('You cannot have a level without a project');
            }

            const projectName = this.props.projectsData.byId[this.props.structurePiece.project].name;
            const levelName = this.props.levelsData.byId[this.props.structurePiece.level].name;

            currentStructureHeading = levelName;
            completeStructureHeading = `${projectName} > ${levelName}`;

            listEntries = this.props.levelsData.byId[this.props.structurePiece.level].children.map(roleId => {
                return <ListItem key={roleId} theme="aqua" value={roleId} name={this.props.rolesData.byId[roleId].name} onClick={this.selectRole} />
            });
        } else if (this.props.structurePiece.project) {
            const projectName = this.props.projectsData.byId[this.props.structurePiece.project].name;

            currentStructureHeading = projectName;
            completeStructureHeading = projectName;

            listEntries = this.props.projectsData.byId[this.props.structurePiece.project].children.map(levelId => {
                return <ListItem key={levelId} theme="aqua" value={levelId} name={this.props.levelsData.byId[levelId].name} onClick={this.selectLevel} isExpandable />
            });
        } else {
            listEntries = this.props.projectsData.allEntries.map(projectId => {
                return <ListItem key={projectId} theme="aqua" value={projectId} name={this.props.projectsData.byId[projectId].name} onClick={this.selectProject} isExpandable />
            });
        }

        return (<FlowchartPiece {...this.props}>
            <div>
                <SelectBox isRounded selectionPromptText={completeStructureHeading || 'Select a project'} dismissDropDownAfterSelection={false} theme="aqua">
                    <DropDownList heading={currentStructureHeading} goBack={this.goBack} dismissAfterSelection={false} theme="aqua">
                        {listEntries}
                    </DropDownList>
                </SelectBox>
            </div>
        </FlowchartPiece>);
    }
}

const StructurePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStructurePiece);

export default StructurePiece;