import React, { Component } from 'react';
import styles from './Chat.module.scss';

import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import Contact from './Contact';

type OwnProps = {};

type Props = OwnProps;

type OwnState = {};

class ConnectedChat extends Component<Props, OwnState> {

    render() {
        return <section className={styles.FocusSpace}>
            <div className={styles.chatPage}>
                <section className={styles.chatSelector}>
                    <section className={styles.chatSearch}>
                        <SearchIcon />
                        <input type="text" placeholder="Search by name" />
                    </section>
                    <Contact name="Everyone (Broadcast)" time="09:15 AM" lastMessage="I'll send it by the end of the day" />
                    <Contact name="Below me (Broadcast)" time="09:15 AM" lastMessage="I'll send it by the end of the day" />
                    <Contact name="Emily Dove" time="09:15 AM" lastMessage="I'll send it by the end of the day" isSelected />
                    <Contact name="Ramin Nasibov" time="09:15 AM" lastMessage="I'll send it by the end of the day" />
                    <Contact name="Michael" time="09:15 AM" lastMessage="I'll send it by the end of the day" unReadMessages={2} />
                </section>
                <section className={styles.chatSpace}>
                    <section className={styles.receivedMessage}>Are you receiving this message? Let me know if you aren't - wait, you can't read this message if you aren't receiving it, can you?</section>
                    <section className={styles.sentMessage}>This is a test message I'm sending to check that the functionality works as expected</section>
                    <section className={styles.receivedMessage}>I'll send it by the end of the day</section>
                </section>
                <section className={styles.chatSummary}>
                    <section className={styles.selectedChatDetails}>
                        <div className={styles.selectedChatAvatar}></div>
                        <div className={styles.selectedChatName}>Emily Dove</div>
                    </section>
                    <div className={styles.separator}></div>
                </section>
            </div>
            <input className={styles.chatInput} placeholder="Type a message..." />
        </section>
    }
}

export default ConnectedChat;