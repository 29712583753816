import { takeEvery, put, select, all } from 'redux-saga/effects'
import { AddUserCustomFieldAction, ADD_USER, UPDATE_USER_REQUEST, DELETE_USER, ADD_USER_CUSTOM_FIELD, UPDATE_USER_CUSTOM_FIELD, AddUserAction, DeleteUserAction, UpdateUserRequestAction } from './types';
import { updateUserCustomFieldStartPiece, registerUserCustomFieldVariable, updateUser } from './actions';
import { addUserToLocation, removeUserFromLocation } from '../structure/location/actions';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { addPiece } from '../flowchart/pieces/actions';
import uuid from 'uuid';
import { PieceType } from '../flowchart/pieces/types';
import { ApplicationState } from '../main';

function* createSeedFlowchartForUserCustomField(action: AddUserCustomFieldAction) {

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        const state: ApplicationState = yield select();

        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {
        
            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'User',
                    type: VariableType.USER,
                })),

                put(registerUserCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateUserCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id)),
            ]);

        }

    }
}

function* provideReverseLocationLinkForNewUser(action: AddUserAction) {
    yield all(action.payload.locations.map(locationId => put(addUserToLocation(action.payload.id, locationId))));
}

function* removeReverseLocationLinkForOldUser(action: DeleteUserAction) {
    const state: ApplicationState = yield select();
    yield all(state.users.byId[action.id].locations.map(locationId => put(removeUserFromLocation(action.id, locationId))));
}

function* updateLocationsAndUser(action: UpdateUserRequestAction) {
    const state: ApplicationState = yield select();

    yield all(state.users.byId[action.payload.id].locations.map(locationId => put(removeUserFromLocation(action.payload.id, locationId))));

    yield all(action.payload.locations.map(locationId => put(addUserToLocation(action.payload.id, locationId))));

    yield put(updateUser(action.payload));
}

export function* watchUserCustomFieldChanges() {
    yield takeEvery(ADD_USER_CUSTOM_FIELD, createSeedFlowchartForUserCustomField);
    yield takeEvery(UPDATE_USER_CUSTOM_FIELD, createSeedFlowchartForUserCustomField);
}

export function* watchUserCreationChanges() {
    yield takeEvery(ADD_USER, provideReverseLocationLinkForNewUser);
}

export function* watchUserDeletionChanges() {
    yield takeEvery(DELETE_USER, removeReverseLocationLinkForOldUser);
}

export function* watchUserUpdateChanges() {
    yield takeEvery(UPDATE_USER_REQUEST, updateLocationsAndUser);
}