import React, { Component } from 'react';
import styles from './VariablePiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/main';
import { VariableType, IUpdateableVariableData } from '../../../shared/store/flowchart/variables/types';
import { getAllPropertiesOfType } from '../../../shared/store/flowchart/helpers/variables';
import { updateVariablePiece } from '../../../shared/store/flowchart/pieces/actions';
import { addVariable } from '../../../shared/store/flowchart/variables/actions';
import VariableModify from './VariableModify';
import { NestingData } from '../../../shared/store/flowchart/pieces/types';
import { isUUID } from '../../../shared/helpers/utilities';

type OwnProps = {
    pieceId: string,
    variableIds: Array<string>,
    selectedVariableId?: string,
    nesting: Array<NestingData>,

    registerVariable?: (variableId: string) => void,
};

const mapStateToProps = (state: ApplicationState) => {

    return {
        variablesData: state.flowchart.variables,
        state: state,
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        updateVariablePiece: (variable: string|undefined, nesting: Array<NestingData>|undefined) => dispatch(updateVariablePiece(ownProps.pieceId, variable, nesting)),
        addVariable: (variableData: IUpdateableVariableData) => dispatch(addVariable(variableData)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    expandedVariableId: string|undefined,
    isAddingVariable: boolean,
};

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedVariablePiece extends Component<Props & FlowchartPieceProps, OwnState> {

    static defaultProps = {
        nesting: [],
    };
    
    constructor(props: Readonly<Props & FlowchartPieceProps>) {
        super(props);

        const selectedVariable = props.selectedVariableId ? props.variablesData.byId[props.selectedVariableId] : undefined;

        this.state = {
            expandedVariableId: selectedVariable && this.isTypeExpandable(selectedVariable.type) ? selectedVariable.id : undefined,
            isAddingVariable: false,
        }
    }

    selectListEntry = (selected: string, isLastPieceExpanded: boolean, type?: string) => {
        if (this.state.expandedVariableId) {
            if (!this.props.selectedVariableId) {
                throw new Error('No variable is selected');
            }

            const nestingData: NestingData = {
                value: selected,
                type: type,
            };

            const newNesting = !isLastPieceExpanded ? this.props.nesting.slice(0, -1).concat([nestingData]) : this.props.nesting.concat([nestingData]);

            this.props.updateVariablePiece(this.props.selectedVariableId, newNesting);
        } else {
            this.props.updateVariablePiece(selected, []);

            const selectedVariable = this.props.variablesData.byId[selected];
    
            this.setState({
                expandedVariableId: this.isTypeExpandable(selectedVariable.type) ? selectedVariable.id : undefined,
            });
        }
    }

    goBack = () => {
        if (this.props.nesting.length > 0) {
            this.props.updateVariablePiece(this.props.selectedVariableId, this.props.nesting.slice(0, -1));
        } else {
            this.setState({
                expandedVariableId: undefined,
            });
        }
    }

    isTypeExpandable = (variableType: VariableType) => {
        if (variableType === VariableType.PROJECT || variableType === VariableType.LEVEL || variableType === VariableType.ROLE || variableType === VariableType.LOCATION || variableType === VariableType.USER || variableType === VariableType.MEMBER || variableType === VariableType.GROUP || variableType === VariableType.WORKFLOW) {
            return true;
        } else {
            return false;
        }
    }

    getReadableName = (data: NestingData) => {
        let name: string = '';
        if (isUUID(data.value)) {
            switch(data.type) {
                case 'GROUPS_LIST':
                    name = this.props.state.groups.types.byId[data.value].name + ' list';
                    break;
                case 'WORKFLOWS_LIST':
                    name = this.props.state.workflows.types.byId[data.value].name + ' list';
                    break;
                default:
                    throw new Error('Unknown type');
            }
        } else {
            name = data.value;
        }
        return name.split('_').map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
    }

    submitVariableData = (variableData: IUpdateableVariableData) => {
        this.props.addVariable(variableData);
        this.props.registerVariable && this.props.registerVariable(variableData.id);
        this.setState({
            isAddingVariable: false,
        });
    }

    hideVariableForm = () => {
        this.setState({
            isAddingVariable: false,
        });
    }

    showVariableForm = () => {
        this.setState({
            isAddingVariable: true,
        });
    }

    getGroupsList = () => {
        const groups: Array<{name: string, value: string, type: VariableType}> = this.props.state.groups.types.allEntries
        .map(groupTypeId => {
            const groupType = this.props.state.groups.types.byId[groupTypeId];

            return {
                name: groupType.name + ' list',
                value: groupType.id,
                type: VariableType.GROUPS_LIST,
            }
        });

        return groups;
    }

    getWorkflowsList = (affiliation: 'member'|'group') => {
        let qualifiedTypes: Array<string>;

        if (affiliation === 'member') {
            qualifiedTypes = this.props.state.workflows.types.allEntries.filter(typeId => {
                return this.props.state.workflows.types.byId[typeId].affiliation === 'member'
            });
        } else {
            qualifiedTypes = this.props.state.workflows.types.allEntries.filter(typeId => {
                return this.props.state.workflows.types.byId[typeId].affiliation === 'group'
            });
        }

        const workflows: Array<{name: string, value: string, type: VariableType}> = qualifiedTypes
            .map(workflowTypeId => {
                const workflowType = this.props.state.workflows.types.byId[workflowTypeId];

                return {
                    name: workflowType.name + ' list',
                    value: workflowType.id,
                    type: VariableType.WORKFLOWS_LIST,
                }
            });

        return workflows;
    }

    render() {
        const currentVariableName = this.props.selectedVariableId ? this.props.variablesData.byId[this.props.selectedVariableId].name : undefined;
        
        const completeVariableName = [currentVariableName].concat(this.props.nesting.map(nestingString => this.getReadableName(nestingString))).join(' > ');

        let variables: Array<{name: string, value: string, type: VariableType}>;
        let heading: string|undefined = undefined;
        let isLastPieceExpanded = false;

        if (this.state.expandedVariableId) {
            const expandedVariable = this.props.variablesData.byId[this.state.expandedVariableId];
            let expandedType = expandedVariable.type;
            isLastPieceExpanded = true;

            variables = getAllPropertiesOfType(expandedType, this.props.state).map(property => {
                return {
                    name: property.name,
                    value: property.name,
                    type: property.type
                }
            });

            switch(expandedType) {
                case VariableType.MEMBER:
                    variables = variables.concat(this.getGroupsList()).concat(this.getWorkflowsList('member'));
                    break;
                case VariableType.GROUP:
                    variables = variables.concat(this.getWorkflowsList('group'));
                    break;
            }

            heading = this.getReadableName({value: expandedVariable.name, type: expandedVariable.type});

            if (this.props.nesting.length > 0) {

                for (let i = 0; i < this.props.nesting.length; i += 1) {
                    const nestedField = variables.find(variable => variable.value === this.props.nesting[i].value);

                    if (!nestedField) {
                        throw new Error('Could not find a nested field with this property');
                    }

                    expandedType = nestedField.type;
                    isLastPieceExpanded = false;

                    if (this.isTypeExpandable(expandedType)) {
                        variables = getAllPropertiesOfType(expandedType, this.props.state).map(property => {
                            return {
                                name: property.name,
                                value: property.name,
                                type: property.type
                            }
                        });

                        switch(expandedType) {
                            case VariableType.MEMBER:
                                variables = variables.concat(this.getGroupsList()).concat(this.getWorkflowsList('member'));
                                break;
                            case VariableType.GROUP:
                                variables = variables.concat(this.getWorkflowsList('group'));
                                break;
                        }
    
                        heading = this.getReadableName(nestedField);
                        isLastPieceExpanded = true;
                    }

                }

            }
        } else {
            variables = this.props.variableIds.map(variableId => {
                const variable = this.props.variablesData.byId[variableId];
                return {
                    name: variable.name,
                    value: variable.id,
                    type: variable.type,
                };
            });
        }

        const pieceFooter = this.state.expandedVariableId ? undefined : this.state.isAddingVariable ? <VariableModify submit={this.submitVariableData} cancel={this.hideVariableForm} /> : <section className={styles.addPrompt} onClick={this.showVariableForm}>+ Add Variable</section>;

        return (<FlowchartPiece {...this.props}>
            <div>
                <SelectBox isRounded selectionPromptText={this.props.selectedVariableId ? completeVariableName : 'Select a variable'} dismissDropDownAfterSelection={false}>
                    <DropDownList heading={heading} goBack={this.goBack} footer={pieceFooter} dismissAfterSelection={false}>
                        {variables.map((variable, index) => <ListItem name={this.getReadableName({value: variable.name, type: variable.type})} value={variable.value} detail={variable.type} key={index} isExpandable={this.isTypeExpandable(variable.type)} onClick={(selected, type) => this.selectListEntry(selected, isLastPieceExpanded, type)} />)}
                    </DropDownList>
                </SelectBox>
            </div>
        </FlowchartPiece>);
    }
}

const VariablePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedVariablePiece);

export default VariablePiece;