import React, { Component } from 'react';
import styles from './Process.module.scss';
import { RouteComponentProps } from 'react-router';
import { Redirect, Link } from "react-router-dom";

import { Permissions } from '../../../shared/store/permissions/types';

import { selectWorkflowType } from '../../../shared/store/workflows/types/actions';
import { updateStatus, updateDueDate, updateProcessState, addToHistory, addWorkflow, updateWorkflow, navigateBack, navigateForward } from '../../../shared/store/workflows/actions';
import { WorkflowProcessState, IUpdateableWorkflowData } from '../../../shared/store/workflows/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { ApplicationState } from '../../../shared/store/main';
import { startOrResumeWorkflow, getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { getWorkflowQuestionValidation, getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';

import Question from './Question';
import Choose from './Choose';
import Group from './Group';
import WorkflowData from './WorkflowData';
import ShowTable, { ShowTableProps } from './ShowTable';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import Transfer from './Transfer';
import Button from '../../../widgets/form/Button';
import { WorkflowTypeCustomField } from '../../../shared/store/workflows/types/types';
import { FieldType, CustomFieldValueType, getReadableDataForCustomField, CustomFieldDataHolder } from '../../../shared/store/custom-fields';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { IUpdateableGroupData } from '../../../shared/store/groups/types';
import { updateLocationCustomFieldData } from '../../../shared/store/structure/location/actions';
import { updateUserCustomFieldData } from '../../../shared/store/users/actions';
import { updateMemberCustomFieldData, addMember } from '../../../shared/store/members/actions';
import { updateGroupCustomFieldData, addGroup, setMembersForGroupRequest } from '../../../shared/store/groups/actions';
import { IUpdateableMemberData } from '../../../shared/store/members/types';
import { getPieceValueType } from '../../../shared/store/flowchart/helpers';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;;

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        applicationState: state,
        myId: state.myData.id,
        workflowData: state.workflows,
        membersData: state.members,
        groupsData: state.groups,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
        updateStatus: (workflowId: string, statusId: string) => dispatch(updateStatus(workflowId, statusId)),
        updateDueDate: (workflowId: string, dueDate: string) => dispatch(updateDueDate(workflowId, dueDate)),
        updateWorkflowProcessState: (processState: WorkflowProcessState, workflowId: string) => dispatch(updateProcessState(processState, workflowId)),
        addToHistory: (processState: WorkflowProcessState, workflowId: string) => dispatch(addToHistory(processState, workflowId)),
        navigateForward: (workflowId: string) => dispatch(navigateForward(workflowId)),
        navigateBack: (workflowId: string) => dispatch(navigateBack(workflowId)),
        addMember: (memberData: IUpdateableMemberData) => dispatch(addMember(memberData)),
        addGroup: (groupData: IUpdateableGroupData) => dispatch(addGroup(groupData)),
        addWorkflow: (workflowData: IUpdateableWorkflowData) => dispatch(addWorkflow(workflowData)),

        setMembersForGroup: (groupId: string, memberTypes: 'representatives'|'all_members', memberIds: Array<string>) => dispatch(setMembersForGroupRequest(groupId, memberTypes, memberIds)),

        updateLocationCustomFieldData: (locationId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateLocationCustomFieldData(locationId, customFieldData)),
        updateUserCustomFieldData: (userId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateUserCustomFieldData(userId, customFieldData)),
        updateMemberCustomFieldData: (memberId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateMemberCustomFieldData(memberId, customFieldData)),
        updateGroupCustomFieldData: (groupId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateGroupCustomFieldData(groupId, customFieldData)),
        updateWorkflow: (workflowData: IUpdateableWorkflowData) => dispatch(updateWorkflow(workflowData)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

type OwnState = {
    userInputs: {
        [customFieldId: string]: CustomFieldValueType,
    },
    userInputsForList: {
        [listItem: string]: {
            [customFieldId: string]: CustomFieldValueType,
        }
    },
    errorMessages: {
        [questionId: string]: string,
    },
    errorMessagesForList: {
        [listItem: string]: {
            [questionId: string]: string,
        }
    },
    choiceInputs: {
        [questionId: string]: string,
    },
    choiceErrorMessages: {
        [questionId: string]: string,
    },
    answerKey: number,
}

class ConnectedWorkflowProcess extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            userInputs: {},
            errorMessages: {},
            errorMessagesForList: {},
            userInputsForList: {},
            choiceInputs: {},
            choiceErrorMessages: {},
            answerKey: 1,
        };
    }

    updateCustomFieldValue = (entityId: string, type: VariableType, fieldId: string, value: CustomFieldValueType, memberId?: string) => {
        if (type === VariableType.LOCATION) {
            this.props.updateLocationCustomFieldData(entityId, {[fieldId]: value});
        } else if (type === VariableType.USER) {
            this.props.updateUserCustomFieldData(entityId, {[fieldId]: value});
        } else if (type === VariableType.MEMBER) {
            this.props.updateMemberCustomFieldData(entityId, {[fieldId]: value});
        } else if (type === VariableType.GROUP) {
            this.props.updateGroupCustomFieldData(entityId, {[fieldId]: value});
        }
    }

    getWorkflowProcessState = () => {
        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        return processState;
    }

    startOrResumeWorkflow = () => {
        const processState = this.getWorkflowProcessState();
        startOrResumeWorkflow(this.props.applicationState, processState, this.props.match.params.id, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
    }

    continueAfterDisplay = () => {

        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];

        if (!workflow.history[workflow.historyIndex].displayingShowPieceId) {
            throw new Error('This can only be called when a display piece is being shown')
        }

        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].displayingShowPieceId,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            createdWorkflowId: undefined,
        }));

        startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
    }

    switchToNewWorkflow = (newWorkflowId: string|undefined) => {
        const processState = this.getWorkflowProcessState();
        processState.createdWorkflowId = undefined;

        this.props.updateWorkflowProcessState(processState, this.props.match.params.id);

        this.props.history.push(`/workflow/${newWorkflowId}/execute`)
    }

    exitTransferScreen = () => {
        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];
        const processState = this.getWorkflowProcessState();
        processState.lastComputedPiece = processState.displayingTransferPieceId;
        processState.displayingTransferPieceId = undefined;

        this.props.updateWorkflowProcessState(processState, workflowId);

        if (workflow.triggeringWorkflow) {
            const triggeringWorkflow = this.props.workflowData.byId[workflow.triggeringWorkflow];
            const triggeringWorkflowStatus = this.props.workflowData.types.statuses.byId[triggeringWorkflow.status];

            if (!triggeringWorkflowStatus.isTerminal && (triggeringWorkflow.user === this.props.myId || this.props.myId === 'SuperUser')) {
                this.props.history.push(`/workflow/${triggeringWorkflow.id}/execute`)
            }
        }

        this.props.history.push('/workflows/list')
    }

    getHeading = (workflowId: string|undefined) => {
        if (!workflowId) {
            throw new Error('Cannot get the heading of a workflow that does not exist');
        }

        const workflow = this.props.workflowData.byId[workflowId];
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        if (workflowType.affiliation === 'member') {
            const member = this.props.membersData.byId[workflow.affiliatedEntity];
            const memberType = this.props.membersData.types.byId[member.type];
            let memberName = member.customFields[memberType.nameFieldId];;

            const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            return workflowType.name + ' for ' + memberName;
        } else if (workflowType.affiliation === 'group') {
            const group = this.props.groupsData.byId[workflow.affiliatedEntity];
            const groupType = this.props.groupsData.types.byId[group.type];
            let groupName = group.customFields[groupType.nameFieldId];

            const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];

            groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

            return workflowType.name + ' for ' + groupName;
        } else if (workflowType.affiliation === 'none') {
            return workflowType.name;
        }

        
    }

    getShowDataFromPieceId = (showPieceId: string, workflowProcessState: WorkflowProcessState): ShowTableProps|string => {
        const showPiece = this.props.piecesData.byId[showPieceId];
        const workflowId = this.props.match.params.id;

        if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
            throw new Error('The piece must be a show piece');
        }

        if (!showPiece.variableToShow) {
            throw new Error('There is no variable to show');
        }

        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflowProcessState.customFields,
            lastComputedPiece: workflowProcessState.displayingShowPieceId,
            executionStack: workflowProcessState.executionStack,
            forIterationCounts: workflowProcessState.forIterationCounts,
            variables: workflowProcessState.variables,
            displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
            displayingShowPieceId: workflowProcessState.displayingShowPieceId,
            displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
            displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
            createdWorkflowId: workflowProcessState.createdWorkflowId,
        }));

        if (!isUUID(showPiece.variableToShow)) {
            return showPiece.variableToShow;
        }

        let variableValue = getWorkflowPieceValue(this.props.applicationState, processState, workflowId, showPiece.variableToShow);
        const showVariableType = getPieceValueType(showPiece.variableToShow, this.props.applicationState.flowchart.pieces, this.props.applicationState.flowchart.variables);

        let selectedTypeForShow: 'User'|'Member'|'Group'|'Workflow'|'Text' = 'Text';

        if (typeof showVariableType === 'undefined' || showVariableType === VariableType.TEXT || showVariableType === VariableType.TEXT_LIST) {
            return String(variableValue);
        } else if (showVariableType === VariableType.USER || showVariableType === VariableType.USERS_LIST) {
            selectedTypeForShow = 'User';
        } else if (showVariableType === VariableType.MEMBER || showVariableType === VariableType.MEMBERS_LIST) {
            selectedTypeForShow = 'Member';
        } else if (showVariableType === VariableType.GROUP || showVariableType === VariableType.GROUPS_LIST) {
            selectedTypeForShow = 'Group';
        } else if (showVariableType === VariableType.WORKFLOW || showVariableType === VariableType.WORKFLOWS_LIST) {
            selectedTypeForShow = 'Workflow';
        } else {
            return String(variableValue);
        }

        if (!Array.isArray(variableValue) && typeof variableValue !== 'string') {
            throw new Error('The variable value must be an iterable');
        }

        if (Array.isArray(variableValue)) {

            if (variableValue.length > 0 && Array.isArray(variableValue[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            variableValue = variableValue as Array<string>;
        }

        return {
            entityIds: typeof variableValue === 'string' ? [variableValue] : variableValue,
            type: selectedTypeForShow,
            typeId: showPiece.entityType,
            customFields: showPiece.customFieldIds || [],
        }
    }

    updateUserInput = (customFieldId: string, value: CustomFieldValueType) => {
        this.setState((prevState: Readonly<OwnState>) => {
            return {
                userInputs: {
                    ...prevState.userInputs,
                    [customFieldId]: value,
                },
                answerKey: (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') ? prevState.answerKey : prevState.answerKey + 1,
            };
        });
    }

    updateUserInputForChoice = (questionId: string, value: string) => {

        this.setState((prevState: Readonly<OwnState>) => {
            return {
                choiceInputs: {
                    ...prevState.choiceInputs,
                    [questionId]: value,
                },
                answerKey: (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') ? prevState.answerKey : prevState.answerKey + 1,
            };
        });
    }

    updateUserInputForList = (listId: string, customFieldId: string, value: CustomFieldValueType) => {
        const newState = {
            ...this.state,
            userInputsForList: {
                ...this.state.userInputsForList,
                [listId]: {
                    ...this.state.userInputsForList[listId],
                    [customFieldId]: value,
                },
            },
            answerKey: (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') ? this.state.answerKey : this.state.answerKey + 1,
        };

        this.setState(newState);
    }

    getAllQuestionsInGroup = (groupPieceId: string) => {
        const questionIds: Array<string> = [];
        const chooseIds: Array<string> = [];

        const groupPiece = this.props.piecesData.byId[groupPieceId];

        if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
            throw new Error('The id must be a group ID');
        }

        if (!groupPiece.innerPiece) {
            throw new Error('The group piece must have an inner piece');
        }

        let pieceIdToConsider = groupPiece.innerPiece;

        do {
            const pieceToConsider = this.props.piecesData.byId[pieceIdToConsider];

            if (pieceToConsider.type === PieceType.GROUPED_QUESTION) {
                questionIds.push(pieceIdToConsider);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_CHOOSE) {
                chooseIds.push(pieceIdToConsider);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.SECTION) {
                const questionsInSection = this.getAllQuestionsInGroup(pieceToConsider.id);
                questionIds.push.apply(questionIds, questionsInSection.questionIds);
                chooseIds.push.apply(chooseIds, questionsInSection.chooseIds);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_SHOW) {
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else {
                throw new Error('This piece can only be a grouped question, a grouped show, or a section');
            }
            
        } while (pieceIdToConsider);

        return {
            questionIds,
            chooseIds,
        };
    }

    updateWorkflowWithAnswer = (workflowId: string, customField: WorkflowTypeCustomField, questionId: string, processState: WorkflowProcessState, answer: CustomFieldValueType) => {
        const workflow = this.props.workflowData.byId[workflowId];
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        // Store the answer in a custom field
        const isForSingleMember = workflowType.affiliation === 'group' && customField.affiliation === 'member';
        let customFieldData = processState.customFields[customField.id]

        const questionPiece = this.props.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
            throw new Error('This is not a question piece');
        }

        if (isForSingleMember) {
            if (!questionPiece.memberVariablePiece) {
                throw new Error('This piece needs to have a member variable along with this custom field');
            }

            const memberVariablePiece = this.props.piecesData.byId[questionPiece.memberVariablePiece];
    
            if (memberVariablePiece.type !== PieceType.VARIABLE) {
                throw new Error('The answer variable must be pointed to a variable piece');
            }
    
            if (!memberVariablePiece.variable) {
                throw new Error('The variable value for the piece must point to an actual variable');
            }

            const memberId = processState.variables[memberVariablePiece.variable];

            if (typeof memberId !== 'string') {
                throw new Error('The member ID must be a string');
            }

            if (typeof customFieldData === 'undefined') {
                customFieldData = {};
            }

            if (Array.isArray(customFieldData) || typeof customFieldData !== 'object') {
                throw new Error('The custom field data must be an object');
            }

            customFieldData[memberId] = answer;
        } else {
            customFieldData = answer;
        }

        processState.customFields[customField.id] = customFieldData;

        return processState;
    }

    showErrorMessage = (message: string, questionId: string) => {
        this.setState(prevState => {
            return {
                errorMessages: {
                    ...prevState.errorMessages,
                    [questionId]: message,
                }
            }
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    errorMessages: {
                        ...prevState.errorMessages,
                        [questionId]: '',
                    }
                }
            });
        }, 5000);

        const questionElement = window.document.getElementById(questionId);
        if (!!questionElement) {
            questionElement.scrollIntoView();
        }
    }

    showErrorMessageForChoice = (message: string, questionId: string) => {
        this.setState(prevState => {
            return {
                choiceErrorMessages: {
                    ...prevState.choiceErrorMessages,
                    [questionId]: message,
                }
            }
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    choiceErrorMessages: {
                        ...prevState.choiceErrorMessages,
                        [questionId]: '',
                    }
                }
            });
        }, 5000);

        const questionElement = window.document.getElementById(questionId);
        if (!!questionElement) {
            questionElement.scrollIntoView();
        }
    }

    showErrorMessageForList = (listId: string, message: string, questionId: string) => {
        this.setState(prevState => {
            return {
                errorMessagesForList: {
                    ...prevState.errorMessagesForList,
                    [listId]: {
                        ...prevState.errorMessagesForList[listId],
                        [questionId]: message,
                    },
                }
            }
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    errorMessagesForList: {
                        ...prevState.errorMessagesForList,
                        [listId]: {
                            ...prevState.errorMessagesForList[listId],
                            [questionId]: '',
                        },
                    }
                }
            });
        }, 5000);

        const questionElement = window.document.getElementById(questionId + listId);
        if (!!questionElement) {
            questionElement.scrollIntoView();
        }
    }

    validateAnswer = (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => {
        const questionPiece = this.props.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
            throw new Error('The ID should point to a piece of the question type');
        }
    
        if (!questionPiece.customFieldId) {
            throw new Error('The question must be attached to a valid custom field');
        }

        const customField = this.props.workflowData.types.customFields.byId[questionPiece.customFieldId];

        const workflow = this.props.workflowData.byId[this.props.match.params.id];

        if (customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string' && isUUID(answer)) {
            answer = this.props.workflowData.types.customFieldOptions.byId[answer].name;
        } else if (customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
            answer = answer.map(optionId =>  isUUID(optionId) ? this.props.workflowData.types.customFieldOptions.byId[optionId].name : optionId);
        } else if (customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
            answer = Number(answer);
        } else if (customField.type === FieldType.BOOLEAN) {
            if (answer === 'Yes') {
                answer = true;
            } else if (answer === 'No') {
                answer = false;
            }
        }

        if (customField.type === FieldType.PHONE) {
            if (typeof answer !== 'undefined') {
                if (typeof answer !== 'string') {
                    throw new Error('The answer type must be string')
                }

                if (answer.split(' ').length !== 2) {
                    return 'Invalid phone number';
                }

                const phoneCountryCode = answer.split(' ')[0];
                const phoneNumber = answer.split(' ')[1];

                if (!['+91', '+1'].includes(phoneCountryCode)) {
                    return 'Invalid country code';
                }

                if (phoneNumber.length !== 10) {
                    return 'The phone number must have exactly 10 digits';
                }

            }
        }

        const allAnswers = {
            ...this.state.userInputsForList,
            ...this.state.userInputs,
        }

        const requiredProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
        const isRequired = questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredProcessState, workflow.id, questionId, answer, allAnswers, questionPiece.isRequiredPiece) : false;

        if (isRequired) {
            if (customField.type === FieldType.BOOLEAN) {
                if (typeof answer === 'undefined') {
                    return 'This answer is required';
                }
            } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
                return 'This answer is required';
            }
        }

        let errorMessage = getWorkflowQuestionValidation(this.props.applicationState, processState, questionPiece.innerPiece, workflow.id, questionId, answer, allAnswers);

        if (typeof errorMessage === 'undefined') {
            errorMessage = '';
        }

        if (typeof errorMessage !== 'string') {
            throw new Error('Invalid value for validation');
        }

        return errorMessage;
    }

    validateChoice = (questionId: string, answer: string, processState: WorkflowProcessState) => {
        const questionPiece = this.props.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.CHOOSE && questionPiece.type !== PieceType.GROUPED_CHOOSE) {
            throw new Error('The ID should point to a piece of the choose type');
        }
    
        if (!questionPiece.variablePiece) {
            throw new Error('The choose piece must point to a valid choice list');
        }
    
        if (!questionPiece.choiceVariable) {
            throw new Error('The choose piece must point to a valid choice variable');
        }

        const choiceListVariableType = getPieceValueType(questionPiece.variablePiece, this.props.piecesData, this.props.variablesData);

        const workflow = this.props.workflowData.byId[this.props.match.params.id];

        switch (choiceListVariableType) {
            case VariableType.PROJECTS_LIST:
            case VariableType.LEVELS_LIST:
            case VariableType.ROLES_LIST:
            case VariableType.LOCATIONS_LIST:
            case VariableType.USERS_LIST:
            case VariableType.MEMBERS_LIST:
            case VariableType.GROUPS_LIST:
            case VariableType.TEXT_LIST:
                break;
            default:
                throw new Error('Unknown type for list variable');
        }

        const allAnswers = {
            ...this.state.userInputsForList,
            ...this.state.userInputs,
        }

        const requiredProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
        const isRequired = questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredProcessState, workflow.id, questionId, answer, allAnswers, questionPiece.isRequiredPiece) : false;

        if (isRequired && typeof answer === 'undefined') {
            return 'This answer is required';
        }

        let errorMessage = getWorkflowQuestionValidation(this.props.applicationState, processState, questionPiece.innerPiece, workflow.id, questionId, answer, allAnswers);

        if (typeof errorMessage === 'undefined') {
            errorMessage = '';
        }

        if (typeof errorMessage !== 'string') {
            throw new Error('Invalid value for validation');
        }

        return errorMessage;
    }

    updateAnswers = (workflowId: string, questionIds: Array<string>, processState: WorkflowProcessState, userInputs: {[key: string]: CustomFieldValueType}, showErrorMessage: (message: string, questionId: string) => void) => {

        for (let i = 0; i < questionIds.length; i += 1) {
            const questionId = questionIds[i];
            const questionPiece = this.props.piecesData.byId[questionId];
    
            if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
                throw new Error('The ID should point to a piece of the question type');
            }
        
            if (!questionPiece.customFieldId) {
                throw new Error('The question must be attached to a valid custom field');
            }
        
            const customField = this.props.workflowData.types.customFields.byId[questionPiece.customFieldId];
            const updateAnswerShorthand = this.updateWorkflowWithAnswer.bind(this, workflowId, customField, questionId, processState);
            let errorMessage: CustomFieldValueType = '';

            errorMessage = this.validateAnswer(questionId, userInputs[questionPiece.customFieldId], processState);

            if (!!errorMessage) {
                if (typeof errorMessage !== 'string') {
                    throw new Error('An error message must be a string type');
                }
                showErrorMessage(errorMessage, questionId);
                return;
            }
    
            switch(customField.type) {
                case FieldType.BOOLEAN:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId] === 'Yes');
                    break;
    
                case FieldType.TEXT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;
    
                case FieldType.NUMBER:
                    processState = updateAnswerShorthand(Number(userInputs[questionPiece.customFieldId]));
                    break;

                case FieldType.DATE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;
                
                case FieldType.SINGLE_SELECT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;
                
                case FieldType.MULTI_SELECT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.LOCATION:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.PHONE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;
    
                default:
                    throw new Error('Answering has not been implemented for this type of question');
            }

        }

        return processState;
        
    }

    updateChoices = (questionIds: Array<string>, processState: WorkflowProcessState, choiceInputs: {[key: string]: string}) => {

        for (let i = 0; i < questionIds.length; i += 1) {
            const questionId = questionIds[i];
            const questionPiece = this.props.piecesData.byId[questionId];
    
            if (questionPiece.type !== PieceType.CHOOSE && questionPiece.type !== PieceType.GROUPED_CHOOSE) {
                throw new Error('The ID should point to a piece of the choose type');
            }
        
            if (!questionPiece.choiceVariable) {
                throw new Error('The choice must be pointed to a valid variable');
            }

            processState.variables[questionPiece.choiceVariable] = choiceInputs[questionId];

        }

        return processState;
        
    }

    submitChoice = (workflowId: string, questionId: string) => {
        const workflow = this.props.workflowData.byId[workflowId];
        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            createdWorkflowId: undefined,
        }));

        const updatedProcessState = this.updateChoices([questionId], processState, this.state.choiceInputs);

        if (typeof updatedProcessState === 'undefined') {
            return;
        }

        this.setState(prevState => {
            return {
                userInputs: {
                    ...prevState.userInputs,
                    [questionId]: ''
                }
            };
        });

        updatedProcessState.lastComputedPiece = questionId;

        startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
    }

    submitAnswer = (workflowId: string, questionId: string) => {
        const workflow = this.props.workflowData.byId[workflowId];
        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            createdWorkflowId: undefined,
        }));

        const updatedProcessState = this.updateAnswers(workflowId, [questionId], processState, this.state.userInputs, this.showErrorMessage);

        if (typeof updatedProcessState === 'undefined') {
            return;
        }

        this.setState(prevState => {
            return {
                userInputs: {
                    ...prevState.userInputs,
                    [questionId]: ''
                }
            };
        });

        updatedProcessState.lastComputedPiece = questionId;

        startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
    }

    submitGroup = (workflowId: string, groupId: string, questionIds: Array<string>, choiceIds: Array<string>) => {
        const workflow = this.props.workflowData.byId[workflowId];
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            createdWorkflowId: undefined,
        }));
        let updatedProcessState: WorkflowProcessState|undefined = {
            ...processState,
            variables: {
                ...processState.variables,
            },
            customFields: {
                ...processState.customFields,
            }
        };

        const groupPiece = this.props.piecesData.byId[groupId];

        const newState = {
            ...this.state,
            userInputs: {},
            userInputsForList: {}
        };

        if (groupPiece.type === PieceType.GROUP) {

            updatedProcessState = this.updateAnswers(workflowId, questionIds, updatedProcessState, this.state.userInputs, this.showErrorMessage);

            if (typeof updatedProcessState === 'undefined') {
                return;
            }

            updatedProcessState = this.updateChoices(choiceIds, updatedProcessState, this.state.choiceInputs);

            if (typeof updatedProcessState === 'undefined') {
                return;
            }

        } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {
            if (!groupPiece.iterableVariable) {
                throw new Error('This piece must have an iterable variable');
            }

            if (!groupPiece.loopVariable) {
                throw new Error('This piece must have a loop variable');
            }

            const loopVariable = groupPiece.loopVariable;
            
            let iterableValue = getWorkflowPieceValue(this.props.applicationState, processState, workflowId, groupPiece.iterableVariable);

            if (!Array.isArray(iterableValue)) {
                throw new Error('The iterable value must be an array');
            }

            if (Array.isArray(iterableValue)) {
    
                if (iterableValue.length > 0 && Array.isArray(iterableValue[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }
    
                iterableValue = iterableValue as Array<string>;
            }

            for (let i = 0; i < iterableValue.length; i += 1) {
                const listItem = iterableValue[i];
                updatedProcessState.variables[loopVariable] = listItem;
                let userInputs = this.state.userInputsForList[listItem];

                if (typeof userInputs === 'undefined') {
                    userInputs = {};
                }

                updatedProcessState = this.updateAnswers(workflowId, questionIds, updatedProcessState, userInputs, this.showErrorMessageForList.bind(this, listItem));
    
                if (typeof updatedProcessState === 'undefined') {
                    return;
                }
            }
        }

        if (typeof updatedProcessState === 'undefined') {
            return;
        }

        this.setState(newState);

        updatedProcessState.lastComputedPiece = groupId;

        startOrResumeWorkflow(this.props.applicationState, updatedProcessState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
    }
        
    render() {

        if (!this.props.match) {
            return <div></div>;
        }

        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];

        if (!workflow) {
            return <div></div>
        }
        const workflowStatus = this.props.workflowData.types.statuses.byId[workflow.status];
        const workflowProcessState = workflow.history[workflow.historyIndex];

        const heading = this.getHeading(workflowId);

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        if (isUUID(this.props.myId) && workflow.user !== this.props.myId) {
            return <div>
                <WorkflowData workflowId={workflowId} />
            </div>
        }

        let display: string, displayMarkup: JSX.Element|undefined;

        if (workflowProcessState.displayingQuestionPieceId) {
            const questionPiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

            if (questionPiece.type === PieceType.QUESTION) {
                display = 'question';
            } else {
                display = 'choose';
            }

        } else if (workflowProcessState.displayingShowPieceId) {
            display = 'show';
        } else if (workflowProcessState.displayingGroupPieceId) {
            display = 'group';
        } else if (workflowProcessState.displayingTransferPieceId) {
            display = 'transfer';
        } else if (workflowStatus.isTerminal) {
            display = 'end';
        } else if (workflowProcessState.createdWorkflowId) {
            display = 'switch';
            this.switchToNewWorkflow(workflowProcessState.createdWorkflowId)
        } else {
            display = 'start';
            this.startOrResumeWorkflow();
        }

        switch (display) {
            case 'start':
                displayMarkup = <div>
                    <section className={styles.promptText}>Click the button below to start the workflow</section>
                    <section className={styles.promptButton} onClick={this.startOrResumeWorkflow}>Start</section>
                </div>
                break;
            case 'question':
                if (workflowProcessState.displayingQuestionPieceId) {

                    const questionPiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

                    if (questionPiece.type !== PieceType.QUESTION) {
                        throw new Error('The piece must be a question piece');
                    }

                    if (!questionPiece.customFieldId) {
                        throw new Error('The question piece must have a custom field');
                    }

                    displayMarkup = <div key={workflowProcessState.displayingQuestionPieceId}>
                        <Question
                            key={this.state.answerKey}
                            workflowId={workflowId}
                            questionId={workflowProcessState.displayingQuestionPieceId}
                            userInput={this.state.userInputs[questionPiece.customFieldId]}
                            errorMessage={this.state.errorMessages[workflowProcessState.displayingQuestionPieceId]} 
                            validateAnswer={this.validateAnswer}
                            onInputChange={this.updateUserInput.bind(this, questionPiece.customFieldId)}
                        />
                        <section className={styles.submit} onClick={this.submitAnswer.bind(this, workflowId, workflowProcessState.displayingQuestionPieceId)}>Submit</section>
                    </div>;

                } else {
                    throw new Error('The question piece ID should not be undefined');
                }
                break;

            case 'choose':
                if (workflowProcessState.displayingQuestionPieceId) {

                    const questionPiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

                    if (questionPiece.type !== PieceType.CHOOSE) {
                        throw new Error('The piece must be a question piece');
                    }

                    if (!questionPiece.variablePiece) {
                        throw new Error('The question piece must have a choices list');
                    }

                    if (!questionPiece.choiceVariable) {
                        throw new Error('The question piece must have a choice variable');
                    }

                    displayMarkup = <div key={workflowProcessState.displayingQuestionPieceId}>
                        <Choose
                            key={this.state.answerKey}
                            workflowId={workflowId}
                            questionId={workflowProcessState.displayingQuestionPieceId}
                            userInput={this.state.choiceInputs[questionPiece.id]}
                            errorMessage={this.state.choiceErrorMessages[workflowProcessState.displayingQuestionPieceId]}
                            validateAnswer={this.validateChoice}
                            onInputChange={this.updateUserInputForChoice.bind(this, questionPiece.id)} 
                            choiceInputs={this.state.choiceInputs}
                        />
                        <section className={styles.submit} onClick={this.submitChoice.bind(this, workflowId, workflowProcessState.displayingQuestionPieceId)}>Submit</section>
                    </div>;

                } else {
                    throw new Error('The question piece ID should not be undefined');
                }
                break;
            case 'transfer':
                if (workflowProcessState.displayingTransferPieceId) {
                    displayMarkup = <Transfer workflowId={workflowId} transferId={workflowProcessState.displayingTransferPieceId} exitTransfer={this.exitTransferScreen} />
                } else {
                    throw new Error('The transfer piece ID should not be undefined');
                }
                break;
            case 'show':
                if (workflowProcessState.displayingShowPieceId) {
                    const showData = this.getShowDataFromPieceId(workflowProcessState.displayingShowPieceId, workflowProcessState);

                    displayMarkup = <div>
                        {typeof showData === 'string' ? 
                        <section className={styles.promptText}>{showData}</section> :
                        <section className={styles.showDataContainer}><ShowTable key={workflowProcessState.displayingShowPieceId} {...showData} /></section>}
                        <section className={styles.submit} onClick={this.continueAfterDisplay}>Continue</section>
                    </div>;
                } else {
                    throw new Error('The show piece ID should not be undefined');
                }
                break;
            case 'group':
                if (workflowProcessState.displayingGroupPieceId) {
                    const groupPiece = this.props.piecesData.byId[workflowProcessState.displayingGroupPieceId];
                    const { questionIds, chooseIds } = this.getAllQuestionsInGroup(workflowProcessState.displayingGroupPieceId);

                    if (groupPiece.type === PieceType.GROUP) {

                        displayMarkup = <div>
                            <Group
                                key={this.state.answerKey}
                                answerKey={this.state.answerKey}
                                workflowId={workflowId}
                                groupPieceId={groupPiece.id}
                                userInputs={this.state.userInputs}
                                userInputsForChoice={this.state.choiceInputs}
                                validateAnswer={this.validateAnswer}
                                validateChoice={this.validateChoice}
                                errorMessages={this.state.errorMessages}
                                errorMessagesForChoice={this.state.choiceErrorMessages}
                                getShowDataFromPieceId={this.getShowDataFromPieceId}
                                updateUserInput={this.updateUserInput}
                                updateUserInputForChoice={this.updateUserInputForChoice}
                             />
                            <section className={styles.submit} onClick={this.submitGroup.bind(this, workflowId, workflowProcessState.displayingGroupPieceId, questionIds, chooseIds)}>Continue</section>
                        </div>;
                    } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {
                
                        if (!groupPiece.iterableVariable) {
                            throw new Error('This piece must have an iterable variable');
                        }
                
                        if (!groupPiece.loopVariable) {
                            throw new Error('This piece must have a loop variable');
                        }
                
                        const loopVariable = groupPiece.loopVariable;
                        
                        let iterableValue = getWorkflowPieceValue(this.props.applicationState, workflowProcessState, workflowId, groupPiece.iterableVariable);

                        if (!Array.isArray(iterableValue)) {
                            throw new Error('The iterable value must be an array');
                        }

                        if (Array.isArray(iterableValue)) {
                
                            if (iterableValue.length > 0 && Array.isArray(iterableValue[0])) {
                                // Cannot be a multidimensional array
                                throw new Error('The value cannot be a multi-dimensional array')
                            }
                
                            iterableValue = iterableValue as Array<string>;
                        }

                        const groupElementsForList = iterableValue.map(overwrittenValue => {
                            return <Group
                                key={overwrittenValue}
                                answerKey={this.state.answerKey}
                                workflowId={workflowId}
                                groupPieceId={groupPiece.id}
                                listUserInputs={this.state.userInputsForList}
                                userInputs={this.state.userInputsForList[overwrittenValue] || {}}
                                validateAnswer={this.validateAnswer}
                                errorMessages={this.state.errorMessagesForList[overwrittenValue] || {}}
                                getShowDataFromPieceId={this.getShowDataFromPieceId}
                                updateUserInput={this.updateUserInputForList.bind(this, overwrittenValue)}
                                overWrittenVariable={loopVariable}
                                overWrittenValue={overwrittenValue}
                             />
                        });

                        displayMarkup = <div>
                            {groupElementsForList}
                            <section className={styles.submit} onClick={this.submitGroup.bind(this, workflowId, workflowProcessState.displayingGroupPieceId, questionIds, chooseIds)}>Continue</section>
                        </div>;
                    }

                } else {
                    throw new Error('The group piece ID should not be undefined');
                }
                break;
            case 'switch':
                displayMarkup = <div>
                    <section className={styles.promptText}>You will be switching to a new workflow: {this.getHeading(workflowProcessState.createdWorkflowId)}</section>
                    <section className={styles.promptButton} onClick={() => this.switchToNewWorkflow(workflowProcessState.createdWorkflowId)}>Start</section>
                </div>
                break;
            case 'end':

                let endWorkflowText = 'You have completed this workflow.';
                const endPieceId = workflowProcessState.lastComputedPiece;
                if (typeof endPieceId !== 'undefined') {
                    const endPiece = this.props.piecesData.byId[endPieceId];
                    try {
                        switch (endPiece.type) {
                            case PieceType.END:
                                if (endPiece.message) {
                                    const message = endPiece.message;
                                    if (isUUID(message)) {
                                        const messageVariableValue = getWorkflowPieceValue(this.props.applicationState, workflowProcessState, workflowId, message);
    
                                        if (typeof messageVariableValue === 'string') {
                                            endWorkflowText = messageVariableValue;
                                        }
                                    } else {
                                        endWorkflowText = message;
                                    }
                                }
                                break;
                            default: break;
                        }
                    } catch {

                    }
                }

                if (workflow.triggeringWorkflow) {
                    const triggeringWorkflow = this.props.workflowData.byId[workflow.triggeringWorkflow];
                    const triggeringWorkflowStatus = this.props.workflowData.types.statuses.byId[triggeringWorkflow.status];
        
                    if (!triggeringWorkflowStatus.isTerminal && (triggeringWorkflow.user === this.props.myId || this.props.myId === 'SuperUser')) {
                        displayMarkup = <div key={workflowId}>
                            <section className={styles.promptText}>You have completed this workflow. Click the button below to resume the triggering workflow.</section>
                            <WorkflowData workflowId={workflowId} />
                            <Link to={`/workflow/${triggeringWorkflow.id}/execute` }><section className={styles.promptButton}>Resume</section></Link>
                        </div>;
                        break;
                    }
                }

                displayMarkup = <div>
                    <section className={styles.promptText}>{endWorkflowText}</section>
                    <WorkflowData workflowId={workflowId} />
                </div>
        }

        const uniqueKeyForStep = workflowProcessState.lastComputedPiece + JSON.stringify(workflowProcessState.forIterationCounts);

        return (<section className={styles.FocusSpace} key={uniqueKeyForStep}>
            <header className={styles.processHeader}>
                {display !== 'start' && display !== 'end' && <Button text="Go Back" onClick={this.props.navigateBack.bind(this, workflowId)} isDisabled={workflow.historyIndex < 2 || (typeof workflow.restrictedHistoryIndex !== 'undefined' && workflow.historyIndex <= workflow.restrictedHistoryIndex + 1)} />}
                <h1 className={styles.processHeading}>{heading}</h1>
                {display !== 'start' && display !== 'end' && <Button text="Go Forward" onClick={this.props.navigateForward.bind(this, workflowId)} isDisabled={workflow.historyIndex > workflow.history.length - 2} />}
            </header>
            <div className={styles.displayContents}>{displayMarkup}</div>
        </section>);
        
    }
}

const WorkflowProcess = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowProcess) as any);

export default WorkflowProcess;