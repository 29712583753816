import React, { Component } from 'react';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece';
import styles from './step-piece/StepPiece.module.scss';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setError } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';


type ErrorPieceProps = {
    errorVariableText?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setErrorValue: (targetPieceId: string, errorValue: string) => dispatch(setError(targetPieceId, errorValue)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ErrorPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type ErrorPieceState = {
    isHoveringOverVariablePiece: boolean,
}

class ConnectedErrorPiece extends Component<Props, ErrorPieceState> {

    handleErrorValueUpdate = (value: string) => {
        this.props.setErrorValue(this.props.pieceId, value);
    }

    render() {
        return (
            <StepPiece theme="camo" {...this.props} hasLowerLock={false}>
                <div className={styles.text}>Error</div>
                <Input defaultText={this.props.errorVariableText} onChange={this.handleErrorValueUpdate} />
            </StepPiece>
        )
    }
}

const ErrorPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedErrorPiece);

export default ErrorPiece;