import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariableForShow, setEntityType, setCustomFieldsForShow } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { valuePieceSlotTarget } from './utilities';


type ShowPieceProps = {
    selectedType?: 'User'|'Member'|'Group'|'Workflow'|'Text',
    selectedFields?: Array<string>,
    selectedEntityType?: string,
    nextPiece?: JSX.Element,
    variablePiece?: JSX.Element,
    textToShow?: string,

    pieceName: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        rolesData: state.structure.roles,
        userData: state.users,
        memberTypes: state.members.types,
        groupTypes: state.groups.types,
        workflowTypes: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setVariableForShow: (pieceId: string, value: string) => dispatch(setVariableForShow(pieceId, value)),
        setEntityTypeForShow: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
        setCustomFields: (pieceId: string, value: Array<string>) => dispatch(setCustomFieldsForShow(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ShowPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type OwnState = {
    isHoveringOverVariablePiece: boolean,
}

class ConnectedShowQuestionPiece extends Component<Props, OwnState> {

    state = {
        isHoveringOverVariablePiece: false,
    };

    updateSelectedOptions = (field: string) => {
        let selectedFields = this.props.selectedFields ? this.props.selectedFields.slice(0) : [];

        if (selectedFields.includes(field)) {
            selectedFields = selectedFields.filter(selectedOption => field !== selectedOption);
        } else {
            selectedFields.push(field);
        }

        this.props.setCustomFields(this.props.pieceId, selectedFields);
    }

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleShowValueUpdate = (value: string) => {
        this.props.setVariableForShow(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            this.props.setVariableForShow(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    render() {
        const entityTypeSelectCallback = this.props.setEntityTypeForShow.bind(this, this.props.pieceId);

        let entityTypeSelectBox: JSX.Element|undefined;
        let customFieldsSelectBox: JSX.Element|undefined;
        let entityType: string|undefined;

        switch(this.props.selectedType) {

            case 'User':
                const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields.map(fieldId => {
                    const customField = this.props.userData.customFields.byId.hasOwnProperty(fieldId) ? this.props.userData.customFields.byId[fieldId].name : this.props.rolesData.customFields.byId[fieldId].name;
                    return customField;
                }).join(', ') : undefined;

                entityType = this.props.selectedEntityType;

                customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                    <DropDownList theme="aqua" dismissAfterSelection={false}>
                        {this.props.userData.customFields.allFields.map(userFieldId => <ListItem theme="aqua" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.updateSelectedOptions} />)}
                        {entityType ? this.props.rolesData.byId[entityType].customFields.map(roleFieldId => <ListItem theme="aqua" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.updateSelectedOptions} />) : undefined}
                    </DropDownList>
                </SelectBox>;

                entityType = this.props.selectedEntityType;

                break;
            
            case 'Member':
                entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType ? this.props.memberTypes.byId[this.props.selectedEntityType].name : undefined}>
                    <DropDownList theme="aqua">
                        {this.props.memberTypes.allEntries.map(memberTypeId => <ListItem theme="aqua" name={this.props.memberTypes.byId[memberTypeId].name} key={memberTypeId} value={memberTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;

                entityType = this.props.selectedEntityType;

                if (entityType) {
                    const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields.map(fieldId => this.props.memberTypes.customFields.byId[fieldId].name).join(', ') : undefined;

                    customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                        <DropDownList theme="aqua" dismissAfterSelection={false}>
                            {this.props.memberTypes.byId[entityType].customFields.map(memberFieldId => <ListItem theme="aqua" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.updateSelectedOptions} />)}
                        </DropDownList>
                    </SelectBox>;
                }

                break;
            
            case 'Group':
                entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType ? this.props.groupTypes.byId[this.props.selectedEntityType].name : undefined}>
                    <DropDownList theme="aqua">
                        {this.props.groupTypes.allEntries.map(groupTypeId => <ListItem theme="aqua" name={this.props.groupTypes.byId[groupTypeId].name} key={groupTypeId} value={groupTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;

                entityType = this.props.selectedEntityType;

                if (entityType) {
                    const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields.map(fieldId => this.props.groupTypes.customFields.byId[fieldId].name).join(', ') : undefined;

                    customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                        <DropDownList theme="aqua" dismissAfterSelection={false}>
                            {this.props.groupTypes.byId[entityType].customFields.map(groupFieldId => <ListItem theme="aqua" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.updateSelectedOptions} />)}
                        </DropDownList>
                    </SelectBox>;
                }
                break;
            
            case 'Workflow':
                entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType ? this.props.workflowTypes.byId[this.props.selectedEntityType].name : undefined}>
                    <DropDownList theme="aqua">
                        {this.props.workflowTypes.allEntries.map(workflowTypeId => <ListItem theme="aqua" name={this.props.workflowTypes.byId[workflowTypeId].name} key={workflowTypeId} value={workflowTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;

                entityType = this.props.selectedEntityType;

                if (entityType) {
                    const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields.map(fieldId => this.props.workflowTypes.customFields.byId[fieldId].name).join(', ') : undefined;

                    const workflowType = this.props.workflowTypes.byId[entityType];
                    let allowedFields: Array<string> = [];

                    if (workflowType.affiliation === 'member') {
                        allowedFields = workflowType.customFields;
                    } else {
                        allowedFields = workflowType.customFields.filter(fieldId => this.props.workflowTypes.customFields.byId[fieldId].affiliation === 'group');
                    }

                    customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                        <DropDownList theme="aqua" dismissAfterSelection={false}>
                            {allowedFields.map(workflowFieldId => <ListItem theme="aqua" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.updateSelectedOptions} />)}
                        </DropDownList>
                    </SelectBox>;
                }
                break;
            
            default:
                entityTypeSelectBox = undefined;
        }
    
        return (
            <div className={styles.visibleItems}>
                <div className={styles.text}>{this.props.pieceName}</div>
                {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} onChange={this.handleShowValueUpdate} defaultText={this.props.textToShow} />}
                <div className={styles.text}>which is a {this.props.selectedType} list {entityTypeSelectBox && 'of type'}</div>
                {entityTypeSelectBox}
                {customFieldsSelectBox && <div className={styles.text}>with fields</div>}
                {customFieldsSelectBox}
            </div>
        )

    }
}

const ShowQuestionPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedShowQuestionPiece);

export default ShowQuestionPiece;