import { ADD_MEMBER, UPDATE_MEMBER_REQUEST, UPDATE_MEMBER, DELETE_MEMBER, SEARCH_MEMBER_TABLE, GO_TO_PAGE_MEMBER_TABLE, SET_PAGE_SIZE_MEMBER_TABLE, SORT_MEMBER_TABLE, IUpdateableMemberData, UPDATE_MEMBER_WORKFLOWS, UPDATE_MEMBER_CUSTOM_FIELD_DATA, ADD_GROUP_TO_MEMBER, REMOVE_GROUP_FROM_MEMBER, FilterMemberTableAction, FILTER_MEMBER_TABLE, AddMemberAction, UpdateMemberRequestAction, UpdateMemberAction, AddGroupToMemberAction, RemoveGroupFromMemberAction, UpdateMemberWorkflowsAction, UpdateMemberCustomFieldDataAction, DeleteMemberAction, SearchMemberTableAction, GoToPageMemberTableAction, SetPageSizeMemberTableAction, SortMemberTableAction } from './types';
import moment from 'moment';
import { CustomFieldDataHolder } from '../custom-fields';

export function addMember(payload: IUpdateableMemberData): AddMemberAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_MEMBER,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            lastKnownLocation: undefined,
            lastMetTime: undefined,
            workflows: {},
        },
    }
}

export function updateMemberRequest(payload: IUpdateableMemberData): UpdateMemberRequestAction {
    return {
        type: UPDATE_MEMBER_REQUEST,
        payload,
    }
}

export function updateMember(payload: IUpdateableMemberData): UpdateMemberAction {
    return {
        type: UPDATE_MEMBER,
        payload,
    }
}

export function addGroupToMember(groupId: string, groupTypeId: string, memberId: string): AddGroupToMemberAction {
    return {
        type: ADD_GROUP_TO_MEMBER,
        groupId,
        groupTypeId,
        memberId,
    }
}

export function removeGroupFromMember(groupId: string, groupTypeId: string, memberId: string): RemoveGroupFromMemberAction {
    return {
        type: REMOVE_GROUP_FROM_MEMBER,
        groupId,
        groupTypeId,
        memberId,
    }
}

export function updateMemberWorkflows(memberId: string, workflowTypeId: string, workflowId: string): UpdateMemberWorkflowsAction {
    return {
        type: UPDATE_MEMBER_WORKFLOWS,
        memberId,
        workflowTypeId,
        workflowId,
    }
}

export function updateMemberCustomFieldData(memberId: string, customFieldData: CustomFieldDataHolder): UpdateMemberCustomFieldDataAction {
    return {
        type: UPDATE_MEMBER_CUSTOM_FIELD_DATA,
        memberId,
        customFieldData,
    }
}

export function deleteMember(id: string): DeleteMemberAction {
    return {
        type: DELETE_MEMBER,
        id,
    }
}

export function searchMemberTable(searchTerm: string): SearchMemberTableAction {
    return {
        type: SEARCH_MEMBER_TABLE,
        searchTerm,
    }
}

export function filterMemberTable(types: Array<string>, locations: Array<string>, customFields: {[customFieldId: string]: Array<string>}): FilterMemberTableAction {
    return {
        type: FILTER_MEMBER_TABLE,
        types,
        locations,
        customFields,
    }
}

export function goToPageMemberTable(pageNumber: number): GoToPageMemberTableAction {
    return {
        type: GO_TO_PAGE_MEMBER_TABLE,
        pageNumber,
    }
}

export function setPageSizeMemberTable(pageSize: number): SetPageSizeMemberTableAction {
    return {
        type: SET_PAGE_SIZE_MEMBER_TABLE,
        pageSize,
    }
}

export function sortMemberTable(column: string, order: 'ASC'|'DESC'): SortMemberTableAction {
    return {
        type: SORT_MEMBER_TABLE,
        column,
        order,
    }
}