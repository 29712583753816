import { SELECT_PROJECT, UN_SELECT_PROJECT, RE_ORDER_PROJECTS, ADD_PROJECT_REQUEST, ADD_PROJECT, DELETE_PROJECT, UPDATE_PROJECT, ADD_LEVEL_TO_PROJECT, REMOVE_LEVEL_FROM_PROJECT, ADD_LOCATION_TO_PROJECT, REMOVE_LOCATION_FROM_PROJECT, SelectProjectAction, UnSelectProjectAction, ReOrderProjectsAction, AddProjectRequestAction, AddProjectAction, UpdateProjectAction, DeleteProjectAction, AddLevelToProjectAction, RemoveLevelFromProjectAction, AddLocationToProjectAction, RemoveLocationFromProjectAction, UPDATE_SPECIAL_WORKFLOW_TYPE, UpdateSpecialWorkflowTypeAction } from './types';
import { INewProjectData, IUpdateableProjectData } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function selectProject(id: string): SelectProjectAction {
    return {
        type: SELECT_PROJECT,
        id,
    }
}

export function unSelectProject(): UnSelectProjectAction {
    return {
        type: UN_SELECT_PROJECT,
    }
}

export function reOrderProjects(sourceIndex: number, destinationIndex: number): ReOrderProjectsAction {
    return {
        type: RE_ORDER_PROJECTS,
        sourceIndex,
        destinationIndex,
    }
}

export function addProjectRequest(payload: INewProjectData): AddProjectRequestAction {
    return {
        type: ADD_PROJECT_REQUEST,
        payload,
    }
}

export function addProject(payload: INewProjectData): AddProjectAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');
    return {
        type: ADD_PROJECT,
        payload: {
            id: uuidv4(),
            ...payload,
            children: [],
            locations: [],
            'add-member': undefined,
            'add-group': undefined,
            'edit-member': undefined,
            'edit-group': undefined,
            createdTime: now,
            lastUpdatedTime: now,
        },
        currentTime: now,
    }
}

export function updateProject(payload: IUpdateableProjectData): UpdateProjectAction {
    return {
        type: UPDATE_PROJECT,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function deleteProject(id: string): DeleteProjectAction {
    return {
        type: DELETE_PROJECT,
        id,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function addLevelToProject(levelId: string, projectId: string): AddLevelToProjectAction {
    return {
        type: ADD_LEVEL_TO_PROJECT,
        levelId,
        projectId,
    };
}

export function removeLevelFromProject(levelId: string, projectId: string): RemoveLevelFromProjectAction {
    return {
        type: REMOVE_LEVEL_FROM_PROJECT,
        levelId,
        projectId,
    };
}

export function addLocationToProject(locationId: string, projectId: string): AddLocationToProjectAction {
    return {
        type: ADD_LOCATION_TO_PROJECT,
        locationId,
        projectId,
    };
}

export function removeLocationFromProject(locationId: string, projectId: string): RemoveLocationFromProjectAction {
    return {
        type: REMOVE_LOCATION_FROM_PROJECT,
        locationId,
        projectId,
    };
}

export function updateSpecialWorkflowTypeForProject(projectId: string, workflowTypeId: string|undefined, specialType: 'add-member'|'add-group'|'edit-member'|'edit-group'): UpdateSpecialWorkflowTypeAction {
    return {
        type: UPDATE_SPECIAL_WORKFLOW_TYPE,
        projectId,
        workflowTypeId,
        specialType,
    }
}