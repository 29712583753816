import { WorkflowState, ADD_WORKFLOW, UPDATE_WORKFLOW, DELETE_WORKFLOW, SEARCH_WORKFLOW_TABLE, GO_TO_PAGE_WORKFLOW_TABLE, SET_PAGE_SIZE_WORKFLOW_TABLE, SORT_WORKFLOW_TABLE, UPDATE_WORKFLOW_STATUS, UPDATE_WORKFLOW_PROCESS_STATE, WorkflowActionTypes, UPDATE_WORKFLOW_DATA, TRANSFER_WORKFLOW, WORKFLOW_NAVIGATE_FORWARD, WORKFLOW_NAVIGATE_BACK, ADD_TO_WORKFLOW_HISTORY, UPDATE_WORKFLOW_DUE_DATE, FILTER_WORKFLOW_TABLE, RESTRICT_WORKFLOW_NAVIGATION } from './types';
import { workflowTypesReducer, initialState as workflowTypesInitialState } from './types/reducer';
import { CustomFieldValueType } from '../custom-fields';

export const initialState: WorkflowState = {
    byId: {},
    allEntries: [],
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
    
    types: workflowTypesInitialState,
    pageSize: 10,
    currentPageNumber: 1,

    filters: {
        dues: [],
        types: [],
        statuses: [],
        users: [],
        affiliations: [],
        customFields: {},
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: undefined,
};

type ComputedFieldSeedDataType = {
    [computedFieldId: string]: {
        [variableId: string]: CustomFieldValueType;
    };
};

export function workflowsReducer(state = initialState, action: WorkflowActionTypes): WorkflowState {
    state = {
        ...state,
        types: workflowTypesReducer(state.types, action),
    }

    let lastUpdatedCustomFields: {
        [customFieldId: string]: string,
    } = {};

    let newState = {
        ...state,
    }

    switch(action.type) {

        // WORKFLOW ACTIONS

        case ADD_WORKFLOW:
            const workflowType = state.types.byId[action.payload.type];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,

                        trackingUsers: [action.payload.user],
                        history: [{
                            lastComputedPiece: undefined,
                            executionStack: [],
                            variables: {
                                [workflowType.seedEntityVariable]: action.payload.id,
                                [workflowType.seedAffiliationVariable]: action.payload.affiliatedEntity,
                            },
                            customFields: {},
                            forIterationCounts: {},
                            displayingQuestionPieceId: undefined,
                            displayingShowPieceId: undefined,
                            displayingGroupPieceId: undefined,
                            displayingTransferPieceId: undefined,
                            createdWorkflowId: undefined,
                        }],
                        historyIndex: 0,
                        lastUpdateTimeForFields: {},
                    },
                },
                types: {
                    ...state.types,
                    byId: {
                        ...state.types.byId,
                        [action.payload.type]: {
                            ...state.types.byId[action.payload.type],
                            workflows: state.types.byId[action.payload.type].workflows.concat([action.payload.id]),
                        }
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
            };
        
        case UPDATE_WORKFLOW:
            const isUserNew = !state.byId[action.payload.id].trackingUsers.includes(action.payload.user);
            const newTrackingUsers = isUserNew ? state.byId[action.payload.id].trackingUsers.concat([action.payload.user]) : state.byId[action.payload.id].trackingUsers;
            
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload,
                        trackingUsers: newTrackingUsers,
                    },
                },
            };

            if (newState.byId[action.payload.id].type !== action.payload.type) {
                newState = {
                    ...newState,
                    types: {
                        ...newState.types,
                        byId: {
                            ...newState.types.byId,
                            [newState.byId[action.payload.id].type]: {
                                ...newState.types.byId[newState.byId[action.payload.id].type],
                                workflows: newState.types.byId[newState.byId[action.payload.id].type].workflows.filter(workflowId => workflowId !== action.payload.id),
                            },
                            [action.payload.type]: {
                                ...newState.types.byId[action.payload.type],
                                workflows: newState.types.byId[action.payload.type].workflows.concat([action.payload.id]),
                            },
                        }
                    }
                }
            }

            return newState;

        case DELETE_WORKFLOW:
            const deletedWorkflow = state.byId[action.id];
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                types: {
                    ...state.types,
                    byId: {
                        ...state.types.byId,
                        [deletedWorkflow.type]: {
                            ...state.types.byId[deletedWorkflow.type],
                            workflows: state.types.byId[deletedWorkflow.type].workflows.filter(workflowId => workflowId !== action.id),
                        },
                    },
                },
                allEntries: state.allEntries.filter(memberId => memberId !== action.id),
            };


        case SEARCH_WORKFLOW_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_WORKFLOW_TABLE:
            return {
                ...state,
                filters: {
                    dues: action.dues,
                    types: action.types,
                    statuses: action.statuses,
                    users: action.users,
                    affiliations: action.affiliations,
                    customFields: action.customFields,
                },
                currentPageNumber: 1,
            }
        
        case GO_TO_PAGE_WORKFLOW_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }
        
        case SET_PAGE_SIZE_WORKFLOW_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        
        case SORT_WORKFLOW_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }
        
        // WORKFLOW PROCESS ACTIONS

        case UPDATE_WORKFLOW_STATUS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        status: action.statusId,
                    }
                }

            }

        case UPDATE_WORKFLOW_DUE_DATE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        dueDate: action.dueDate,
                    }
                }

            }

        case ADD_TO_WORKFLOW_HISTORY:
            const newHistory = state.byId[action.workflowId].history.slice(0, state.byId[action.workflowId].historyIndex + 1).concat(action.processState);

            lastUpdatedCustomFields = {
                ...state.byId[action.workflowId].lastUpdateTimeForFields,
            }

            for (let customFieldId in action.processState.customFields) {
                if (action.processState.customFields.hasOwnProperty(customFieldId)) {
                    let oldProcessState = state.byId[action.workflowId].history[state.byId[action.workflowId].historyIndex];

                    if (!state.byId[action.workflowId].lastUpdateTimeForFields.hasOwnProperty(customFieldId) || oldProcessState.customFields[customFieldId] !== action.processState.customFields[customFieldId]) {
                        lastUpdatedCustomFields[customFieldId] = action.updateTime;
                    }
                }
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        history: newHistory,
                        historyIndex: newHistory.length - 1,
                        lastUpdateTimeForFields: lastUpdatedCustomFields,
                    }
                }
            }

        case UPDATE_WORKFLOW_PROCESS_STATE:
            const updatedHistory = state.byId[action.workflowId].history.slice(0, state.byId[action.workflowId].historyIndex);
            updatedHistory[state.byId[action.workflowId].historyIndex] = {
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
                ...action.processState,
            };

            lastUpdatedCustomFields = {
                ...state.byId[action.workflowId].lastUpdateTimeForFields,
            }

            for (let customFieldId in action.processState.customFields) {
                if (action.processState.customFields.hasOwnProperty(customFieldId)) {
                    let oldProcessState = state.byId[action.workflowId].history[state.byId[action.workflowId].historyIndex];

                    if (!state.byId[action.workflowId].lastUpdateTimeForFields.hasOwnProperty(customFieldId) || oldProcessState.customFields[customFieldId] !== action.processState.customFields[customFieldId]) {
                        lastUpdatedCustomFields[customFieldId] = action.updateTime;
                    }
                }
            }
            
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        history: updatedHistory,
                        lastUpdateTimeForFields: lastUpdatedCustomFields,
                    }
                }
            }

        case RESTRICT_WORKFLOW_NAVIGATION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        restrictedHistoryIndex: state.byId[action.workflowId].historyIndex,
                    }
                }
            }

        case WORKFLOW_NAVIGATE_FORWARD:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        historyIndex: state.byId[action.workflowId].historyIndex + 1,
                    }
                }
            }

        case WORKFLOW_NAVIGATE_BACK:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        historyIndex: state.byId[action.workflowId].historyIndex - 1,
                    }
                }
            }

        case TRANSFER_WORKFLOW:
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.workflowId]: {
                            ...state.byId[action.workflowId],
                            user: action.user,
                            trackingUsers: state.byId[action.workflowId].trackingUsers.concat([action.user]),
                        }
                    }
    
                }

        case UPDATE_WORKFLOW_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                    createdCustomFieldIds: state.types.createdCustomFieldIds,
                    updatedCustomFieldIds: state.types.updatedCustomFieldIds,
                    deletedCustomFieldIds: state.types.deletedCustomFieldIds,
                    createdCustomFieldOptionIds: state.types.createdCustomFieldOptionIds,
                    updatedCustomFieldOptionIds: state.types.updatedCustomFieldOptionIds,
                    deletedCustomFieldOptionIds: state.types.deletedCustomFieldOptionIds,
                },
                filters: state.filters,
            }
        
        default:
            return state;
    }
}