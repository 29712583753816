import React, { Component } from 'react';
import styles from './MemberModify.module.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from '@khanacademy/react-multi-select';

import InputText from '../../../widgets/form/InputText';
import Button from '../../../widgets/form/Button';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';
import mapIcon from '../../../assets/map-pin.svg';

import { translatePhrase } from '../../../shared/helpers/translation';
import { FieldType, getReadableDataForCustomField } from '../../../shared/store/custom-fields';

import { ApplicationState } from '../../../shared/store/main';
import { IUpdateableMemberData } from '../../../shared/store/members/types';

import { connect } from 'react-redux';
import uuid from 'uuid';
import { ILocation } from '../../../shared/store/structure/location/types';
import { getAllLeafLocationsOfUser } from '../../../shared/helpers/locations';

type OwnProps = {
    memberId?: string,
    isReadOnly?: boolean,

    submit: (memberData: IUpdateableMemberData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedLocations = getAllLeafLocationsOfUser(state.myData.id);

    return {
        member: ownProps.memberId ? state.members.byId[ownProps.memberId] : undefined,
        membersData: state.members,
        memberTypesData: state.members.types,
        groupData: state.groups,
        
        locationsData: state.structure.locations,

        customFieldsData: state.members.types.customFields,
        customFieldOptionsData: state.members.types.customFieldOptions,

        groupsData: state.groups,
        groupTypesData: state.groups.types,

        allowedData: {
            locations: allAllowedLocations,
        },

        applicationState: state,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;


type OwnState = {
    memberData: IUpdateableMemberData,
    submitTimer: number|undefined,
    locationKey: number,
    errorMessage: string,
};

class ConnectedMemberModify extends Component<Props, OwnState> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        let memberData: IUpdateableMemberData;
        if (!props.member) {
            // This is a new member
            memberData = {
                id: uuid.v4(),
                type: '',
                location: '',
                groups: {},
                customFields: {},
            };
        } else {
            memberData = {
                id: props.member.id,
                type: props.member.type,
                location: props.member.location,
                groups: JSON.parse(JSON.stringify(props.member.groups)),
                customFields: JSON.parse(JSON.stringify(props.member.customFields)),
            }
        }
        
        this.state = {
            memberData: memberData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: ''
        };
    }
    
    changeType = (type: string) => {
        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            type: type
        };
        
        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }
    
    changeLocation = (location: string) => {
        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            location: location
        };
        
        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }

    changeGroup = (typeId: string, groupIds: Array<string>|undefined) => {
        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            groups: {
                ...this.state.memberData.groups,
                [typeId]: groupIds || [],
            }
        };
        
        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }
    
    showErrorMessage = (message: string) => {
        let that = this
        
        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 5000);
        
    }
    
    validateIUpdateableMemberData = () => {
        
        if (!this.state.memberData.type) {
            this.showErrorMessage('Enter a valid type');
            return;
        }
        
        if (!this.state.memberData.location) {
            this.showErrorMessage('Select a location');
            return;
        }

        this.props.groupTypesData.allEntries.forEach(typeId => {
            const groupType = this.props.groupTypesData.byId[typeId];

            if (groupType.isRequired) {
                if (!this.state.memberData.groups.hasOwnProperty(typeId)) {
                    this.showErrorMessage('Enter a valid ' + groupType.name);
                    return;
                }
            }
        });

        for (let i = 0; i < this.props.memberTypesData.byId[this.state.memberData.type].customFields.length; i += 1) {
            const fieldId = this.props.memberTypesData.byId[this.state.memberData.type].customFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.memberData.customFields[fieldId] && !Array.isArray(this.state.memberData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.memberData.customFields[fieldId] && Array.isArray(this.state.memberData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.memberData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (phoneNumber.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.memberData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }

            if (field.isUnique) {
                for (let j = 0; j < this.props.membersData.allEntries.length; j ++) {
                    const memberId = this.props.membersData.allEntries[j];
                    if (memberId !== this.state.memberData.id && this.props.membersData.byId[memberId].customFields[fieldId] === this.state.memberData.customFields[fieldId]) {
                        this.showErrorMessage(field.name + ' must be unique. There is another entry with the same value');
                        
                        return false;
                    }
                }
            }
        };
        
        return true;
    }
    
    submitMemberForm = () => {
        if (this.validateIUpdateableMemberData()) {
            this.markForSubmit();
        }
    }
    
    markForSubmit = () => {
        let that = this;
        
        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.memberData);
        }, 1000);
        
        this.setState({
            submitTimer: timeout
        });
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            customFields: {
                ...this.state.memberData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };
        
        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }

    getLocationForField = (fieldId: string) => {
        const geolocation = navigator.geolocation,
              locationKey = this.state.locationKey;

        geolocation.getCurrentPosition((position) => {
            let updatedIUpdateableMemberData: IUpdateableMemberData = {
                ...this.state.memberData,
                customFields: {
                    ...this.state.memberData.customFields,
                    [fieldId]: `${position.coords.latitude} ${position.coords.longitude}`
                }
            };
            
            this.setState({
                memberData: updatedIUpdateableMemberData,
                locationKey: locationKey + 2
            })
        });
    }
    
    render() {

        let groupFields: Array<JSX.Element> = [];

        if (this.state.memberData.type) {
            const memberType = this.props.memberTypesData.byId[this.state.memberData.type];

            groupFields = this.props.groupTypesData.allEntries
            .filter(typeId => {
                const groupType = this.props.groupTypesData.byId[typeId];

                if (memberType.project !== groupType.project) {
                    return false;
                }

                return true;
            })
            .map(typeId => {
                const groupType = this.props.groupTypesData.byId[typeId];
                
                const groupsList = groupType.groups.map(groupId => {
                    const group = this.props.groupsData.byId[groupId];
                    const groupType = this.props.groupTypesData.byId[group.type];
                    let groupName = group.customFields[groupType.nameFieldId];

                    const nameField = this.props.groupTypesData.customFields.byId[groupType.nameFieldId];

                    groupName = getReadableDataForCustomField(groupName, nameField, groupId, 'group');
    
                    return {
                        label: groupName,
                        value: groupId,
                    }
                });
    
                return <div key={typeId} className={styles.inputSegment}>
                    <MultiSelect
                        options={groupsList}
                        onSelectedChanged={this.changeGroup.bind(this, typeId)}
                        selected={this.state.memberData.groups[typeId] ? this.state.memberData.groups[typeId] : []}
                        overrideStrings={{
                            selectSomeItems: 'Select ' + groupType.name + 's',
                            allItemsAreSelected: 'All ' + groupType.name + 's are selected',
                        }}
                    />
                </div>
            });
        }

        let customFields: Array<JSX.Element|undefined> = [];

        if (this.state.memberData.type) {
            customFields = this.props.memberTypesData.byId[this.state.memberData.type].customFields.map(fieldId => {
                const field = this.props.customFieldsData.byId[fieldId];
                const choices = field.choices.map(choiceId => {
                    return {
                        name: this.props.customFieldOptionsData.byId[choiceId].name,
                        value: choiceId,
                    }
                });

                let customFieldValue = this.state.memberData.customFields[fieldId];

                if (field.isComputed) {
                    return undefined;
                }
                
                switch(field.type) {
                    case FieldType.SINGLE_SELECT:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A single select field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A single select field should not have a boolean value.');
                        }

                        return <div key={field.id} className={styles.inputSegment}>
                            <InputText placeholder={field.name} onChange={value => this.changeCustomField(field.id, value)} default={this.props.member && customFieldValue ? this.props.customFieldOptionsData.byId[customFieldValue].name : ''} options={choices} />
                        </div>
                    
                    case FieldType.NUMBER:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A text field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A number field should not have a boolean value.');
                        }

                        return <div key={field.id} className={styles.inputSegment}>
                            <InputText placeholder={field.name} type="number" onChange={value => this.changeCustomField(field.id, value)} default={this.props.member && customFieldValue ? String(customFieldValue): ''} />
                        </div>
                    
                    case FieldType.TEXT:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A text field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A text field should not have a boolean value.');
                        }

                        return <div key={field.id} className={styles.inputSegment}>
                            <InputText placeholder={field.name} onChange={value => this.changeCustomField(field.id, value)} default={this.props.member && customFieldValue ? String(customFieldValue): ''} />
                        </div>
                    
                    case FieldType.BOOLEAN:
                        const binaryOptions = ['Yes', 'No'];
                        
                        if (typeof customFieldValue !== 'boolean' && typeof customFieldValue !== 'undefined') {
                            if (String(customFieldValue).toLocaleLowerCase() === 'yes') {
                                customFieldValue = true;
                            } else if (String(customFieldValue).toLocaleLowerCase() === 'no') {
                                customFieldValue = false;
                            } else {
                                throw new Error('A boolean field should have a boolean value.');
                            }
                        }

                        return <div key={field.id} className={styles.inputSegment}>
                            <InputText placeholder={field.name} onChange={value => this.changeCustomField(field.id, value === 'Yes')} default={this.props.member ? customFieldValue ? 'Yes' : 'No' : ''} options={binaryOptions} />
                        </div>
                    
                    case FieldType.DATE:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A date field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A date field should not have a boolean value.');
                        }

                        return <div key={field.id} className={styles.inputSegment}>
                            <div className={styles.label}>{field.name}</div>
                            <DatePicker selected={customFieldValue ? new Date(customFieldValue) : undefined} dateFormat="dd-MMM-yyyy" onChange={value => this.changeCustomField(field.id, value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} /> 
                        </div>
                    
                    case FieldType.MULTI_SELECT:
                        if (typeof customFieldValue === 'string') {
                            throw new Error('A multi select field should have an array value');
                        }

                        const multiSelectChoices = choices.map(choice => {
                            return {
                                label: choice.name, 
                                value: choice.value,
                            };
                        });

                        return <div key={field.id} className={styles.inputSegment}>
                            <MultiSelect
                                options={multiSelectChoices}
                                onSelectedChanged={this.changeCustomField.bind(this, field.id)}
                                selected={customFieldValue ? customFieldValue : []}
                                overrideStrings={{
                                    selectSomeItems: 'Select ' + field.name + 's',
                                    allItemsAreSelected: 'All ' + field.name + 's are selected',
                                }}
                            />
                        </div>

                    case FieldType.PHONE:
                        if (typeof customFieldValue === 'undefined') {
                            customFieldValue = '';
                        }
                        
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A phone field should have a string value');
                        }

                        let phoneCountryCode = '+91';
                        let phoneNumber = '';

                        if (customFieldValue.split(' ').length > 1) {
                            phoneCountryCode = customFieldValue.split(' ')[0];
                            phoneNumber = customFieldValue.split(' ')[1];
                        }

                        return  <div className={styles.phoneSegment}>
                            <InputText icon={chevronIcon} default={phoneCountryCode} options={['+91', '+1']} isSearchDisabled onChange={value => this.changeCustomField(field.id, `${value} ${phoneNumber}`)} />
                            <InputText placeholder={field.name} type="tel" default={phoneNumber} onChange={value => this.changeCustomField(field.id, `${phoneCountryCode} ${value}`)} />
                        </div>

                    case FieldType.LOCATION:
                        if (typeof customFieldValue === 'undefined') {
                            customFieldValue = '';
                        }
                        
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A phone field should have a string value');
                        }

                        let latitude = '';
                        let longitude = '';

                        if (customFieldValue.split(' ').length > 1) {
                            latitude = customFieldValue.split(' ')[0];
                            longitude = customFieldValue.split(' ')[1];
                        }

                        return  <div className={styles.geoLocationSegment}>
                            <InputText placeholder={field.name + ' lat'} type="number" key={this.state.locationKey} default={latitude} onChange={value => this.changeCustomField(field.id, `${value} ${longitude}`)} />
                            <InputText placeholder={field.name + ' long'} type="number" key={this.state.locationKey + 1} default={longitude} onChange={value => this.changeCustomField(field.id, `${latitude} ${value}`)} />
                            <img src={mapIcon} onClick={this.getLocationForField.bind(this, fieldId)} alt="Location selector"/>
                        </div>
                }
                
                return undefined;
            });
        }

        const typesList = this.props.memberTypesData.allEntries.map(typeId => {
            return {
                name: this.props.memberTypesData.byId[typeId].name,
                value: typeId,
            };
        });

        let locationsList = this.props.allowedData.locations
        .map(locationId => {
            return {
                name: this.props.locationsData.byId[locationId].name,
                value: locationId,
            };
        });

        if (this.state.memberData.type) {
            const memberType = this.props.memberTypesData.byId[this.state.memberData.type];
            const allLocationsInProject = this.props.locationsData.byProject[memberType.project];

            locationsList = locationsList.filter(location => {
                let topLocation: ILocation = this.props.locationsData.byId[location.value];

                while (topLocation.parent && topLocation.parent in this.props.locationsData.byId) {
                    topLocation = this.props.locationsData.byId[topLocation.parent];
                }

                return allLocationsInProject.includes(topLocation.id);
            });
        }
        
        return (
            <section className={this.props.isReadOnly ? styles.viewOnlyMember : styles.modifyMember}>
                <header>
                    <h2 className={styles.formHeading}>{this.props.member ? this.props.isReadOnly ? 'View' : 'Edit' : 'Add'} Member</h2>
                    <button className={styles.cancelButton} onClick={this.props.cancel}><CancelIcon /> Cancel</button>
                </header>
                <section className={styles.errorMessage}>{this.state.errorMessage}</section>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Member Type" icon={chevronIcon} default={this.props.member ? this.props.memberTypesData.byId[this.props.member.type].name : ''} options={typesList} onChange={this.changeType} />
                    </div>
                    {this.state.memberData.type && <div className={styles.inputSegment} key={this.state.memberData.type}>
                        <InputText placeholder="Location" icon={chevronIcon} default={this.props.member && this.props.member.location ? this.props.locationsData.byId[this.props.member.location].name : ''} options={locationsList} onChange={this.changeLocation} />
                    </div>}

                    {groupFields}
                    {customFields}
                    
                </div>
                    
                {!this.props.isReadOnly && <div className={styles.buttonHolder}>
                    {this.state.submitTimer ? <button className={styles.confirmFormButton}>{this.props.member ? translatePhrase('Updated Member') : translatePhrase('Added Member')}</button> : <Button text={this.props.member ? translatePhrase('Update Member') : translatePhrase('Add Member')} onClick={this.submitMemberForm} />}
                </div>}
                
            </section>
        );
    }
}

const MemberModify = connect(mapStateToProps)(ConnectedMemberModify);

export default MemberModify;