import { MemberTypeState, SELECT_MEMBER_TYPE, UN_SELECT_MEMBER_TYPE, RE_ORDER_MEMBER_TYPES, ADD_MEMBER_TYPE, UPDATE_MEMBER_TYPE, DELETE_MEMBER_TYPE, SELECT_MEMBER_TYPE_CUSTOM_FIELD, UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD, ADD_MEMBER_TYPE_CUSTOM_FIELD, DELETE_MEMBER_TYPE_CUSTOM_FIELD, UPDATE_MEMBER_TYPE_CUSTOM_FIELD, SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION, UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION, RE_ORDER_MEMBER_TYPE_CUSTOM_FIELD_OPTION, ADD_MEMBER_TYPE_CUSTOM_FIELD_OPTION, DELETE_MEMBER_TYPE_CUSTOM_FIELD_OPTION, UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION, UPDATE_MEMBER_TYPE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE, REGISTER_MEMBER_TYPE_CUSTOM_FIELD_VARIABLE, ADD_MEMBER_TO_MEMBER_TYPE, REMOVE_MEMBER_FROM_MEMBER_TYPE, ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_ADD, UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_ADD, DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_ADD, UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE, DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE, ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_UPDATE } from './types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, updateStartPieceForCustomField, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, registerVariableForCustomField } from '../../custom-fields';
import { MemberActionTypes } from '../types';
import { reOrderList } from '../../../helpers/utilities';

export const initialState: MemberTypeState = {
    byId: {},
    allEntries: [],
    selected: undefined,

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    reverseLinks: {},

    customFields: {
        byId: {},
        allFields: [],
    },
    customFieldOptions: {
        byId: {},
        allOptions: [],
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),
};

export function memberTypesReducer(state = initialState, action: MemberActionTypes): MemberTypeState {
    let newState: MemberTypeState;
    let memberTypeManagement: undefined | {
        workflowTypeId: string,
        lastQuestionPiece: string,
        lastStorePiece: string,
        customFieldMap: {
            [memberTypeCustomFieldId: string]: string,  // ID of the custom field in the managed workflow
        },
    };

    switch(action.type) {

        // Member type actions
        
        case SELECT_MEMBER_TYPE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_MEMBER_TYPE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_MEMBER_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_MEMBER_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        members: [],
                        customFields: [],
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
            }
        
        case DELETE_MEMBER_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(entry => entry !== action.id),
                selected: undefined,
            }
        
        case UPDATE_MEMBER_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload,
                    },
                },
            }
        
        case ADD_MEMBER_TO_MEMBER_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        members: state.byId[action.memberTypeId].members.concat(action.memberId),
                    },
                },
            }
        
        case REMOVE_MEMBER_FROM_MEMBER_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        members: state.byId[action.memberTypeId].members.filter(memberId => memberId !== action.memberId),
                    },
                },
            }
        
        case SELECT_MEMBER_TYPE_CUSTOM_FIELD:
            return selectCustomField<MemberTypeState>(state, action.id);
        
        case UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD:
            return unSelectCustomField<MemberTypeState>(state);

        case ADD_MEMBER_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        customFields: state.byId[action.memberTypeId].customFields.concat([action.payload.id]),
                    }
                }
            }
            newState = addCustomField<MemberTypeState>(newState, action.payload, action.memberTypeId);
            return newState;
        
        case DELETE_MEMBER_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        customFields: state.byId[action.memberTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                }
            }
            return deleteCustomField<MemberTypeState>(newState, action.id);
        
        case UPDATE_MEMBER_TYPE_CUSTOM_FIELD:
            return updateCustomField<MemberTypeState>(state, action.payload);


        case UPDATE_MEMBER_TYPE_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<MemberTypeState>(state, action.customFieldId, action.payload);
    
        case SET_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<MemberTypeState>(state, action.customFieldId, action.payload);

        case REMOVE_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<MemberTypeState>(state, action.customFieldId, action.pieceId);

        case REGISTER_MEMBER_TYPE_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<MemberTypeState>(state, action.customFieldId, action.variableId);
        
        
        case SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<MemberTypeState>(state, action.id);
        
        case UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<MemberTypeState>(state);

        case RE_ORDER_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<MemberTypeState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<MemberTypeState>(state, action.payload, action.parentId);
        
        case DELETE_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<MemberTypeState>(state, action.id, action.parentId);

        case UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<MemberTypeState>(state, action.payload);

        case ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_ADD:
            memberTypeManagement = state.byId[action.memberTypeId].addManagement;

            if (typeof memberTypeManagement === 'undefined') {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        addManagement: {
                            ...memberTypeManagement,
                            customFieldMap: {
                                ...memberTypeManagement.customFieldMap,
                                [action.memberTypeCustomFieldId]: action.workflowTypeCustomFieldId,
                            }
                        },
                    },
                },
            }

        case UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_ADD:
            memberTypeManagement = state.byId[action.memberTypeId].addManagement;


            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        addManagement: {
                            ...memberTypeManagement,
                            workflowTypeId: action.workflowTypeId,
                            lastQuestionPiece: action.lastQuestionPieceId,
                            lastStorePiece: action.lastStorePieceId,
                            customFieldMap: {
                                ...(memberTypeManagement ? memberTypeManagement.customFieldMap : {})
                            }
                        },
                    },
                },
            }

        case DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_ADD:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        addManagement: undefined,
                    },
                },
            }

        case ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_UPDATE:
            memberTypeManagement = state.byId[action.memberTypeId].updateManagement;

            if (typeof memberTypeManagement === 'undefined') {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        updateManagement: {
                            ...memberTypeManagement,
                            customFieldMap: {
                                ...memberTypeManagement.customFieldMap,
                                [action.memberTypeCustomFieldId]: action.workflowTypeCustomFieldId,
                            }
                        },
                    },
                },
            }

        case UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE:
            memberTypeManagement = state.byId[action.memberTypeId].updateManagement;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        updateManagement: {
                            ...memberTypeManagement,
                            workflowTypeId: action.workflowTypeId,
                            lastQuestionPiece: action.lastQuestionPieceId,
                            lastStorePiece: action.lastStorePieceId,
                            customFieldMap: {
                                ...(memberTypeManagement ? memberTypeManagement.customFieldMap : {})
                            }
                        },
                    },
                },
            }

        case DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        updateManagement: undefined,
                    },
                },
            }
        
        
        default:
            return state;
    }
}