import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type LesserThanOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const LesserThanOperator: FC<LesserThanOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator operatorSymbol="<" isBoolean={true} {...props} />
    )
}

export default LesserThanOperator;