import { ADD_WIDGET, UPDATE_WIDGET, DELETE_WIDGET, IUpdateableWidgetData, REGISTER_WIDGET_VARIABLE, UPDATE_WIDGET_START_PIECE, SET_ISOLATED_WIDGET_PIECE, REMOVE_ISOLATED_WIDGET_PIECE, AddWidgetAction, UpdateWidgetAction, DeleteWidgetAction, UpdateWidgetStartPieceAction, SetIsolatedWidgetPieceAction, RemoveIsolatedWidgetPieceAction, RegisterWidgetVariableAction } from './types';
import moment from 'moment';
import { PiecePositionState } from '../flowchart/pieces/types';

export function addWidget(payload: IUpdateableWidgetData): AddWidgetAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_WIDGET,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            variables: [],
            isolatedPieces: [],
        },
    }
}

export function updateWidget(payload: IUpdateableWidgetData): UpdateWidgetAction {
    return {
        type: UPDATE_WIDGET,
        payload,
    }
}

export function deleteWidget(id: string): DeleteWidgetAction {
    return {
        type: DELETE_WIDGET,
        id,
    }
}

export function updateWidgetStartPiece(payload: PiecePositionState, widgetId: string): UpdateWidgetStartPieceAction {
    return {
        type: UPDATE_WIDGET_START_PIECE,
        payload,
        widgetId
    }
}

export function setIsolatedWidgetPiece(payload: PiecePositionState, widgetId: string): SetIsolatedWidgetPieceAction {
    return {
        type: SET_ISOLATED_WIDGET_PIECE,
        payload,
        widgetId
    }
}

export function removeIsolatedWidgetPiece(pieceId: string, widgetId: string): RemoveIsolatedWidgetPieceAction {
    return {
        type: REMOVE_ISOLATED_WIDGET_PIECE,
        pieceId,
        widgetId
    }
}

export function registerWidgetVariable(variableId: string, widgetId: string): RegisterWidgetVariableAction {
    return {
        type: REGISTER_WIDGET_VARIABLE,
        variableId,
        widgetId,
    }
}