import { CoOrdinates } from '../../helpers/common-types';
import { NormalizedModel, Synchronizable } from '../normalized-model';
import { MemberTypeState } from './types/types';
import { MemberTypeActionTypes } from './types/types';
import { CustomFieldValueType, CustomFieldDataHolder } from '../custom-fields';

export const ADD_MEMBER = 'ADD_MEMBER';
export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_WORKFLOWS = 'UPDATE_MEMBER_WORKFLOWS';
export const DELETE_MEMBER = 'DELETE_MEMBER';

export const ADD_GROUP_TO_MEMBER = 'ADD_GROUP_TO_MEMBER';
export const REMOVE_GROUP_FROM_MEMBER = 'REMOVE_GROUP_FROM_MEMBER';

export const UPDATE_MEMBER_CUSTOM_FIELD_DATA = 'UPDATE_MEMBER_CUSTOM_FIELD_DATA';

export const SEARCH_MEMBER_TABLE = 'SEARCH_MEMBER_TABLE';
export const FILTER_MEMBER_TABLE = 'FILTER_MEMBER_TABLE';
export const GO_TO_PAGE_MEMBER_TABLE = 'GO_TO_PAGE_MEMBER_TABLE';
export const SET_PAGE_SIZE_MEMBER_TABLE = 'SET_PAGE_SIZE_MEMBER_TABLE';
export const SORT_MEMBER_TABLE = 'SORT_MEMBER_TABLE';

export const UPDATE_MEMBER_DATA = 'UPDATE_MEMBER_DATA';

export interface INewMemberData {
    type: string,
    location: string,

    customFields: {
        [customFieldId: string]: CustomFieldValueType,
    }

    groups: {
        [groupTypeId: string]: Array<string>,
    },
}

export interface IUpdateableMemberData extends INewMemberData {
    id: string,
}

export interface IMember extends IUpdateableMemberData, Synchronizable {
    archived?: boolean,
    lastKnownLocation: CoOrdinates|undefined,
    lastMetTime: string|undefined,
    createdTime: string,

    workflows: {
        [workflowTypeId: string]: Array<string>,
    },
}

export interface MemberState extends NormalizedModel<IMember> {

    types: MemberTypeState,

    pageSize: number,
    currentPageNumber: number,
    filters: {
        types: Array<string>,
        locations: Array<string>,
        customFields: {
            [fieldId: string]: Array<string>,
        }
    },
    sort: {
        column: string|undefined,
        order: 'ASC'|'DESC',
    },
    searchTerm: string|undefined,
}

export interface AddMemberAction {
    type: typeof ADD_MEMBER,
    payload: IMember,
}

export interface UpdateMemberRequestAction {
    type: typeof UPDATE_MEMBER_REQUEST,
    payload: IUpdateableMemberData,
}

export interface UpdateMemberAction {
    type: typeof UPDATE_MEMBER,
    payload: IUpdateableMemberData,
}

export interface AddGroupToMemberAction {
    type: typeof ADD_GROUP_TO_MEMBER,
    memberId: string,
    groupId: string,
    groupTypeId: string,
}

export interface RemoveGroupFromMemberAction {
    type: typeof REMOVE_GROUP_FROM_MEMBER,
    memberId: string,
    groupId: string,
    groupTypeId: string,
}

export interface UpdateMemberWorkflowsAction {
    type: typeof UPDATE_MEMBER_WORKFLOWS,
    memberId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface DeleteMemberAction {
    type: typeof DELETE_MEMBER,
    id: string,
}

export interface UpdateMemberCustomFieldDataAction {
    type: typeof UPDATE_MEMBER_CUSTOM_FIELD_DATA,
    memberId: string,
    customFieldData: CustomFieldDataHolder
}


export interface SearchMemberTableAction {
    type: typeof SEARCH_MEMBER_TABLE,
    searchTerm: string,
}

export interface FilterMemberTableAction {
    type: typeof FILTER_MEMBER_TABLE,
    types: Array<string>,
    locations: Array<string>,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
}

export interface GoToPageMemberTableAction {
    type: typeof GO_TO_PAGE_MEMBER_TABLE,
    pageNumber: number,
}

export interface SetPageSizeMemberTableAction {
    type: typeof SET_PAGE_SIZE_MEMBER_TABLE,
    pageSize: number,
}

export interface SortMemberTableAction {
    type: typeof SORT_MEMBER_TABLE,
    column: string,
    order: 'ASC'|'DESC'
}


export interface IUpdateMemberData {
    type: typeof UPDATE_MEMBER_DATA,
    data: MemberState,
}

type MemberActions = AddMemberAction | UpdateMemberRequestAction | UpdateMemberAction | AddGroupToMemberAction | RemoveGroupFromMemberAction | UpdateMemberWorkflowsAction | DeleteMemberAction | UpdateMemberCustomFieldDataAction;

type MemberTableActions = SearchMemberTableAction | FilterMemberTableAction | GoToPageMemberTableAction | SetPageSizeMemberTableAction | SortMemberTableAction;

export type MemberActionTypes = MemberActions | MemberTypeActionTypes | MemberTableActions | IUpdateMemberData;