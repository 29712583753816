import { TranslationsState, UPDATE_TRANSLATION } from './types'
import { ADD_LANGUAGE } from '../languages/types';
import { InternationalizationActionTypes, UPDATE_INTERNATIONALIZATION_DATA } from '../types';

const initialState: TranslationsState = {  
    byLanguage: {},
    updatedWords: {},
    currentLanguage: undefined,
};

export function translationsReducer(state = initialState, action: InternationalizationActionTypes): TranslationsState {
    switch(action.type) {

        // Translation actions
        
        case UPDATE_TRANSLATION:
            let changedWords = action.language in state.updatedWords ? state.updatedWords[action.language] : [];
            changedWords = Array.from((new Set(changedWords)).add(action.originalWord));

            return {
                ...state,
                byLanguage: {
                    ...state.byLanguage,
                    [action.language]: {
                        ...state.byLanguage[action.language],
                        [action.originalWord]: action.translatedWord
                    }
                },
                updatedWords: {
                    [action.language]: changedWords,
                }
            };

        // Language actions
        
        case ADD_LANGUAGE:
            return {
                ...state,
                byLanguage: {
                    ...state.byLanguage,
                    [action.payload.id]: {}
                }
            }

            
        // Internationalization actions
        case UPDATE_INTERNATIONALIZATION_DATA:
            return {
                ...action.data.translations,
            }

        default:
            return state;
    }
}