import React from 'react';
import { WorkflowTypeState, IWorkflowType, WorkflowTypeCustomField } from '../../../shared/store/workflows/types/types';
import { VariableState, VariableType } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions, PiecePositionState } from '../../../shared/store/flowchart/pieces/types';
import StopPiece from '../../../components/flowchart/pieces/StopPiece';
import QuestionPiece from '../../../components/flowchart/pieces/QuestionPiece';
import StartPiece from '../../../components/flowchart/pieces/StartPiece';

import { isUUID } from '../../../shared/helpers/utilities';
import UpdateStatusPiece from '../../../components/flowchart/pieces/UpdateStatusPiece';
import UpdateDueDatePiece from '../../../components/flowchart/pieces/UpdateDueDatePiece';
import SequenceOperator from '../../../components/flowchart/pieces/operators/SequenceOperator';

import { getComponent } from './index';
import { getComponentForQuestionPiece } from './question';
import { Position } from '../../../shared/helpers/common-types';
import ShowPiece from '../pieces/ShowPiece';
import StartWorkflowPiece from '../pieces/StartWorkflowPiece';
import TransferPiece from '../pieces/TransferPiece';
import GroupPiece from '../pieces/GroupPiece';
import SectionPiece from '../pieces/SectionPiece';
import GroupedQuestionPiece from '../pieces/GroupedQuestionPiece';
import GroupedShowPiece from '../pieces/GroupedShowPiece';
import ErrorPiece from '../pieces/ErrorPiece';
import CustomFieldPiece from '../pieces/CustomFieldPiece';
import { getPieceValueType } from '../../../shared/store/flowchart/helpers';
import ReturnPiece from '../pieces/ReturnPiece';
import GroupedAnswerPiece from '../pieces/GroupedAnswerPiece';
import StatusPiece from '../pieces/StatusPiece';
import SuccessPiece from '../pieces/SuccessPiece';
import AddMemberPiece from '../pieces/AddMemberPiece';
import AddGroupPiece from '../pieces/AddGroupPiece';
import AddWorkflowPiece from '../pieces/AddWorkflowPiece';
import AddReportPiece from '../pieces/AddReportPiece';
import ChoosePiece from '../pieces/ChoosePiece';
import GroupedChoosePiece from '../pieces/GroupedChoosePiece';
import SetMembersInGroupPiece from '../pieces/SetMembersInGroupPiece';
import SwitchWorkflowPiece from '../pieces/SwitchWorkflowPiece';
import RestrictNavigationPiece from '../pieces/RestrictNavigationPiece';
import ArchivePiece from '../pieces/ArchivePiece';
import { setReportName, setReportUser, setReportStartDate, setReportEndDate } from '../../../shared/store/flowchart/pieces/actions';
import store from '../../../shared/store/main';

export function getComponentForWorkflowPiece(typeState: WorkflowTypeState, piecesState: PieceState, variablesState: VariableState, workflowType: IWorkflowType, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position) : JSX.Element {
    const workflowPiece = piecesState.byId[pieceId];

    const statuses = workflowType.statuses.map(statusId => typeState.statuses.byId[statusId]);
    const getTempInnerComponentShorthand = getComponentForWorkflowPiece.bind({}, typeState, piecesState, variablesState, workflowType);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, registerVariable);

    const getTemp3InnerComponentShorthand = getComponentForQuestionPiece.bind({}, typeState, piecesState, variablesState, workflowType);
    const getTemp4InnerComponentShorthand = getTemp3InnerComponentShorthand.bind({}, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece);
    const getQuestionComponentShorthand = getTemp4InnerComponentShorthand.bind({}, registerVariable);

    const detachNextPiece = flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined);
    const detachInnerPiece = flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined);

    let customFields, currentCustomField;

    let customField: WorkflowTypeCustomField|undefined;
    let isShowingMemberPiece: boolean;
    let customFieldMemberVariable: JSX.Element|undefined;

    switch(workflowPiece.type) {

        case PieceType.START:
            const startNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;
            return <StartPiece pieceId={pieceId} initialPosition={workflowType.startPiece && workflowType.startPiece.position} nextPiece={startNextPiece} isDragDisabled={!isEditable} removeIsolatedPiece={removeIsolatedPiece} />

        case PieceType.RESTRICT_NAVIGATION:
            const restrictNavigationNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            return <RestrictNavigationPiece
                pieceId={pieceId}

                nextPiece={restrictNavigationNextPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
             />
        
        case PieceType.END:
            const terminalStates = statuses.filter(status => status.isTerminal).map(status => {
                return {
                    name: status.name,
                    value: status.id,
                };
            });

            const messageVariablePiece = workflowPiece.message && isUUID(workflowPiece.message) ? getInnerComponentShorthand(workflowPiece.message, flowchartPieceActions.setMessage.bind({}, pieceId, undefined)) : undefined;
            const messageVariableText = workflowPiece.message;

            return <StopPiece
                pieceId={pieceId}

                terminalStates={terminalStates}
                stopState={workflowPiece.status ? typeState.statuses.byId[workflowPiece.status].name : undefined}
                messageVariablePiece={messageVariablePiece}
                messageText={messageVariableText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.RETURN:
            const returnVariablePiece = workflowPiece.returnValue && isUUID(workflowPiece.returnValue) ? getInnerComponentShorthand(workflowPiece.returnValue, flowchartPieceActions.setReturnVariable.bind({}, pieceId, undefined)) : undefined;
            const returnVariableText = workflowPiece.returnValue;

            return <ReturnPiece
                pieceId={pieceId}
                returnVariablePiece={returnVariablePiece}
                returnVariableText={returnVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        
        case PieceType.SUCCESS:

            return <SuccessPiece
                pieceId={pieceId}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        
        case PieceType.ERROR:
            const errorVariableText = workflowPiece.error;

            return <ErrorPiece
                pieceId={pieceId}
                errorVariableText={errorVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SHOW:
        case PieceType.GROUPED_SHOW:
            const showNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            const showPieceVariable = workflowPiece.variableToShow && isUUID(workflowPiece.variableToShow) ? getInnerComponentShorthand(workflowPiece.variableToShow, flowchartPieceActions.setVariableForShow.bind({}, pieceId, undefined)) : undefined;

            const showPieceText = workflowPiece.variableToShow && !isUUID(workflowPiece.variableToShow) ? workflowPiece.variableToShow : undefined;

            const showVariableType = workflowPiece.variableToShow && isUUID(workflowPiece.variableToShow) ? getPieceValueType(workflowPiece.variableToShow, piecesState, variablesState) : VariableType.TEXT;

            let selectedTypeForShow: 'User'|'Member'|'Group'|'Workflow'|'Text' = 'Text';

            if (typeof showVariableType === 'undefined') {
                selectedTypeForShow = 'Text';
            } else if (showVariableType === VariableType.TEXT || showVariableType === VariableType.TEXT_LIST) {
                selectedTypeForShow = 'Text';
            } else if (showVariableType === VariableType.USER || showVariableType === VariableType.USERS_LIST) {
                selectedTypeForShow = 'User';
            } else if (showVariableType === VariableType.MEMBER || showVariableType === VariableType.MEMBERS_LIST) {
                selectedTypeForShow = 'Member';
            } else if (showVariableType === VariableType.GROUP || showVariableType === VariableType.GROUPS_LIST) {
                selectedTypeForShow = 'Group';
            } else if (showVariableType === VariableType.WORKFLOW || showVariableType === VariableType.WORKFLOWS_LIST) {
                selectedTypeForShow = 'Workflow';
            }

            if (workflowPiece.type === PieceType.SHOW) {

                return <ShowPiece
                    pieceId={pieceId}
    
                    selectedType={selectedTypeForShow}
                    selectedEntityType={workflowPiece.entityType}
                    selectedFields={workflowPiece.customFieldIds}
                    variablePiece={showPieceVariable}
                    textToShow={showPieceText}
    
                    nextPiece={showNextPiece}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            } else if (workflowPiece.type === PieceType.GROUPED_SHOW) {

                return <GroupedShowPiece
                    pieceId={pieceId}

                    selectedType={selectedTypeForShow}
                    selectedEntityType={workflowPiece.entityType}
                    selectedFields={workflowPiece.customFieldIds}
                    variablePiece={showPieceVariable}
                    textToShow={showPieceText}

                    nextPiece={showNextPiece}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            } else {
                throw new Error('Unknown show piece type');
            }


        case PieceType.START_WORKFLOW:
            const startWorkflowNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            const startWorkflowDueDateVariable = workflowPiece.workflowDueDateVariable && isUUID(workflowPiece.workflowDueDateVariable) ? getInnerComponentShorthand(workflowPiece.workflowDueDateVariable, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;

            const startWorkflowAffiliationVariable = workflowPiece.affiliationVariable && isUUID(workflowPiece.affiliationVariable) ? getInnerComponentShorthand(workflowPiece.affiliationVariable, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;

            return <StartWorkflowPiece
                pieceId={pieceId}

                workflowIsAsync={workflowPiece.isAsync}
                workflowType={workflowPiece.workflowType}
                workflowStatus={workflowPiece.workflowStatus}
                workflowDueDatePiece={startWorkflowDueDateVariable}
                workflowAffiliationVariablePiece={startWorkflowAffiliationVariable}

                nextPiece={startWorkflowNextPiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />


        case PieceType.SWITCH_WORKFLOW:
            const switchWorkflowNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            const switchWorkflowVariable = workflowPiece.variablePiece && isUUID(workflowPiece.variablePiece) ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;

            return <SwitchWorkflowPiece
                pieceId={pieceId}

                workflowVariablePiece={switchWorkflowVariable}

                nextPiece={switchWorkflowNextPiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />
        
        case PieceType.UPDATE_STATUS:
            const states = statuses.filter(status => !status.isTerminal).map(status => {
                return {
                    name: status.name,
                    value: status.id,
                };
            });
            const updateStatusNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;
            
            return <UpdateStatusPiece
                pieceId={pieceId}
                states={states}
                updateState={workflowPiece.status ? typeState.statuses.byId[workflowPiece.status].name : undefined}
                nextPiece={updateStatusNextPiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.UPDATE_DUE_DATE:
            const updateDueDateNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            const dueDatePiece = workflowPiece.dueDate && isUUID(workflowPiece.dueDate) ? getInnerComponentShorthand(workflowPiece.dueDate, flowchartPieceActions.setVariableForShow.bind({}, pieceId, undefined)) : undefined;
            const dueDateText = !(workflowPiece.dueDate && isUUID(workflowPiece.dueDate)) ? workflowPiece.dueDate : undefined;

            return <UpdateDueDatePiece
                pieceId={pieceId}

                dueDatePiece={dueDatePiece}
                dueDateText={dueDateText}

                nextPiece={updateDueDateNextPiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />
            
        
        case PieceType.QUESTION:
        case PieceType.GROUPED_QUESTION:
            const questionPiece = workflowPiece.question && isUUID(workflowPiece.question) ? getInnerComponentShorthand(workflowPiece.question, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;
            const questionText = !(workflowPiece.question && isUUID(workflowPiece.question)) ? workflowPiece.question : undefined;

            const questionDefaultPiece = workflowPiece.default && isUUID(workflowPiece.default) ? getInnerComponentShorthand(workflowPiece.default, flowchartPieceActions.setDefaultPiece.bind({}, pieceId, undefined)) : undefined;
            const questionDefaultText = !(workflowPiece.default && isUUID(workflowPiece.default)) ? workflowPiece.default : undefined;

            const questionNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            const questionInnerPiece = workflowPiece.innerPiece ? getQuestionComponentShorthand(workflowPiece.innerPiece, detachInnerPiece) : undefined;

            customFields = workflowType.customFields.map(customFieldId => typeState.customFields.byId[customFieldId]);
            customFields = customFields.map(customField => {
                return {
                    name: customField.name,
                    value: customField.id,
                };
            });
            currentCustomField = workflowPiece.customFieldId ? typeState.customFields.byId[workflowPiece.customFieldId] : undefined;

            const questionMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            const questionIsRequiredPiece = workflowPiece.isRequiredPiece ? getInnerComponentShorthand(workflowPiece.isRequiredPiece, flowchartPieceActions.setRequiredPiece.bind({}, pieceId, undefined)) : undefined;

            const questionIsDisabledPiece = workflowPiece.isDisabledPiece ? getInnerComponentShorthand(workflowPiece.isDisabledPiece, flowchartPieceActions.setDisabledPiece.bind({}, pieceId, undefined)) : undefined;

            const isForSingleMember = workflowType.affiliation === 'group' && currentCustomField && currentCustomField.affiliation === 'member';

            if (workflowPiece.type === PieceType.QUESTION) {

                return <QuestionPiece 
                    pieceId={pieceId}
                    questionVariablePiece={questionPiece}
                    questionText={questionText}
                    customFields={customFields}

                    isRequiredPiece={questionIsRequiredPiece}
                    isDisabledPiece={questionIsDisabledPiece}
                    defaultPiece={questionDefaultPiece}
                    defaultText={questionDefaultText}
    
                    isForSingleMember={isForSingleMember}
                    memberVariable={questionMemberVariable}
                    selectedCustomField={currentCustomField ? currentCustomField.name : undefined}
    
                    nextPiece={questionNextPiece}
                    innerPiece={questionInnerPiece}
    
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            } else if (workflowPiece.type === PieceType.GROUPED_QUESTION) {

                return <GroupedQuestionPiece 
                    pieceId={pieceId}
                    questionVariablePiece={questionPiece}
                    questionText={questionText}
                    customFields={customFields}

                    isRequiredPiece={questionIsRequiredPiece}
                    isDisabledPiece={questionIsDisabledPiece}
                    defaultPiece={questionDefaultPiece}
                    defaultText={questionDefaultText}

                    isForSingleMember={isForSingleMember}
                    memberVariable={questionMemberVariable}
                    selectedCustomField={currentCustomField ? currentCustomField.name : undefined}

                    nextPiece={questionNextPiece}
                    innerPiece={questionInnerPiece}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />
            } else {
                throw new Error('Unknown question piece type');
            }
            
        
        case PieceType.CHOOSE:
        case PieceType.GROUPED_CHOOSE:
            const chooseListPiece = workflowPiece.variablePiece && isUUID(workflowPiece.variablePiece) ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;
            const chooseListType = workflowPiece.variablePiece && isUUID(workflowPiece.variablePiece) ? getPieceValueType(workflowPiece.variablePiece, piecesState, variablesState) : VariableType.TEXT_LIST;
            let selectedChooseListType: 'Project'|'Level'|'Role'|'Location'|'User'|'Member'|'Group'|'Text' = 'Text';

            const choiceVariableText = workflowPiece.choiceVariable ? variablesState.byId[workflowPiece.choiceVariable].name : undefined;

            if (chooseListType === VariableType.PROJECTS_LIST) {
                selectedChooseListType = 'Project';
            } else if (chooseListType === VariableType.LEVELS_LIST) {
                selectedChooseListType = 'Level';
            } else if (chooseListType === VariableType.ROLES_LIST) {
                selectedChooseListType = 'Role';
            } else if (chooseListType === VariableType.LOCATIONS_LIST) {
                selectedChooseListType = 'Location';
            } else if (chooseListType === VariableType.USERS_LIST) {
                selectedChooseListType = 'User';
            } else if (chooseListType === VariableType.MEMBERS_LIST) {
                selectedChooseListType = 'Member';
            } else if (chooseListType === VariableType.GROUPS_LIST) {
                selectedChooseListType = 'Group';
            }

            const chooseQuestionPiece = workflowPiece.question && isUUID(workflowPiece.question) ? getInnerComponentShorthand(workflowPiece.question, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;
            const chooseQuestionText = !(workflowPiece.question && isUUID(workflowPiece.question)) ? workflowPiece.question : undefined;

            const chooseDefaultPiece = workflowPiece.default && isUUID(workflowPiece.default) ? getInnerComponentShorthand(workflowPiece.default, flowchartPieceActions.setDefaultPiece.bind({}, pieceId, undefined)) : undefined;
            const chooseDefaultText = !(workflowPiece.default && isUUID(workflowPiece.default)) ? workflowPiece.default : undefined;

            const choiceVariables = workflowType.variables.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });


            const chooseNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            const chooseInnerPiece = workflowPiece.innerPiece ? getQuestionComponentShorthand(workflowPiece.innerPiece, detachInnerPiece) : undefined;

            const chooseIsRequiredPiece = workflowPiece.isRequiredPiece ? getInnerComponentShorthand(workflowPiece.isRequiredPiece, flowchartPieceActions.setRequiredPiece.bind({}, pieceId, undefined)) : undefined;

            const chooseIsDisabledPiece = workflowPiece.isDisabledPiece ? getInnerComponentShorthand(workflowPiece.isDisabledPiece, flowchartPieceActions.setDisabledPiece.bind({}, pieceId, undefined)) : undefined;

            if (workflowPiece.type === PieceType.CHOOSE) {

                return <ChoosePiece 
                    pieceId={pieceId}
                    questionVariablePiece={chooseQuestionPiece}
                    questionText={chooseQuestionText}

                    choiceListVariablePiece={chooseListPiece}
                    listType={selectedChooseListType}

                    isRequiredPiece={chooseIsRequiredPiece}
                    isDisabledPiece={chooseIsDisabledPiece}
                    defaultPiece={chooseDefaultPiece}
                    defaultText={chooseDefaultText}
    
                    selectedChoiceVariable={choiceVariableText}
                    variables={choiceVariables}

                    nextPiece={chooseNextPiece}
                    innerPiece={chooseInnerPiece}
    
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            } else if (workflowPiece.type === PieceType.GROUPED_CHOOSE) {

                return <GroupedChoosePiece 
                    pieceId={pieceId}
                    questionVariablePiece={chooseQuestionPiece}
                    questionText={chooseQuestionText}

                    choiceListVariablePiece={chooseListPiece}
                    listType={selectedChooseListType}

                    isRequiredPiece={chooseIsRequiredPiece}
                    isDisabledPiece={chooseIsDisabledPiece}
                    defaultPiece={chooseDefaultPiece}
                    defaultText={chooseDefaultText}
    
                    selectedChoiceVariable={choiceVariableText}
                    variables={choiceVariables}

                    nextPiece={chooseNextPiece}
                    innerPiece={chooseInnerPiece}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />
            } else {
                throw new Error('Unknown question piece type');
            }
        
        case PieceType.GROUP:
            const groupNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const groupInnerPiece = workflowPiece.innerPiece ? getInnerComponentShorthand(workflowPiece.innerPiece, flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined)) : undefined;

            return <GroupPiece 
                pieceId={pieceId}
                isForMembersList={false}

                nextPiece={groupNextPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            >
                {groupInnerPiece}
            </GroupPiece>
        
        case PieceType.SECTION:
            const sectionNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const sectionInnerPiece = workflowPiece.innerPiece ? getInnerComponentShorthand(workflowPiece.innerPiece, flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined)) : undefined;
            const sectionHeadingPiece = workflowPiece.heading && isUUID(workflowPiece.heading) ? getInnerComponentShorthand(workflowPiece.heading, flowchartPieceActions.setHeadingPiece.bind({}, pieceId, undefined)) : undefined;
            const sectionHeadingText = !(workflowPiece.heading && isUUID(workflowPiece.heading)) ? workflowPiece.heading : undefined;
            const sectionColumnsText = workflowPiece.columns;

            return <SectionPiece 
                pieceId={pieceId}
                nextPiece={sectionNextPiece}

                headingPiece={sectionHeadingPiece}
                headingText={sectionHeadingText}
                columns={sectionColumnsText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            >
                {sectionInnerPiece}
            </SectionPiece>
        
        case PieceType.GROUP_FOR_LIST:
            const nextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const innerPiece = workflowPiece.innerPiece ? getInnerComponentShorthand(workflowPiece.innerPiece, flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined)) : undefined;
            const iterablePiece = workflowPiece.iterableVariable ? getInnerComponentShorthand(workflowPiece.iterableVariable, flowchartPieceActions.setIterableVariable.bind({}, pieceId, undefined)) : undefined;

            const loopVariableOptions = workflowType.variables.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });
            const loopVariableName = workflowPiece.loopVariable ? variablesState.byId[workflowPiece.loopVariable].name : undefined;

            return <GroupPiece 
                pieceId={pieceId}
                isForMembersList={true}

                iterablePiece={iterablePiece}
                variables={loopVariableOptions}
                loopVariableName={loopVariableName}

                nextPiece={nextPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            >
                {innerPiece}
            </GroupPiece>

        case PieceType.SEQUENCE:
            const sequenceOptions = [{
                name: 'Type',
                value: 'type',
            }, {
                name: 'Affiliated Entity',
                value: 'affiliatedEntity',
            }]

            return <SequenceOperator pieceId={pieceId} options={sequenceOptions} />;

        case PieceType.TRANSFER_WORKFLOW:
            const transferNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

            return <TransferPiece
                pieceId={pieceId}
                projectId={workflowType.project}
                selectedRoles={workflowPiece.roles}

                nextPiece={transferNextPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.CUSTOM_FIELD:
            customField = workflowPiece.customField ? typeState.customFields.byId[workflowPiece.customField] : undefined;

            isShowingMemberPiece = customField && !workflowPiece.customFieldOption ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;

            customFieldMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            return <CustomFieldPiece 
                pieceId={pieceId}

                customFieldIds={workflowType.customFields}
                selectedCustomFieldId={workflowPiece.customField}
                selectedCustomFieldOptionId={workflowPiece.customFieldOption}
                type="Workflow"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={isShowingMemberPiece}
                memberVariablePiece={customFieldMemberVariable}
            />

        case PieceType.GROUPED_ANSWER:
            customField = workflowPiece.customField ? typeState.customFields.byId[workflowPiece.customField] : undefined;

            isShowingMemberPiece = customField ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;

            customFieldMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            return <GroupedAnswerPiece 
                pieceId={pieceId}

                customFieldIds={workflowType.customFields}
                selectedAnswerId={workflowPiece.customField}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={isShowingMemberPiece}
                memberVariablePiece={customFieldMemberVariable}
            />

    
        case PieceType.ADD_MEMBER:
            const addMemberVariables = workflowType.variables.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });
            const addMemberNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const addMemberLocationPiece = workflowPiece.locationPiece ? getInnerComponentShorthand(workflowPiece.locationPiece, flowchartPieceActions.setLocationPiece.bind({}, pieceId, undefined)) : undefined;
            const addMemberVariableName = workflowPiece.variable ? variablesState.byId[workflowPiece.variable].name : undefined;

            return <AddMemberPiece 
                pieceId={pieceId}
                nextPiece={addMemberNextPiece}

                selectedEntityType={workflowPiece.entityType}
                addingVariableName={addMemberVariableName}
                locationPiece={addMemberLocationPiece}
                variables={addMemberVariables}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

    
        case PieceType.ADD_GROUP:
            const addGroupVariables = workflowType.variables.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });
            const addGroupNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const addGroupLocationPiece = workflowPiece.locationPiece ? getInnerComponentShorthand(workflowPiece.locationPiece, flowchartPieceActions.setLocationPiece.bind({}, pieceId, undefined)) : undefined;
            const addGroupVariableName = workflowPiece.variable ? variablesState.byId[workflowPiece.variable].name : undefined;

            return <AddGroupPiece 
                pieceId={pieceId}
                nextPiece={addGroupNextPiece}

                selectedEntityType={workflowPiece.entityType}
                addingVariableName={addGroupVariableName}
                locationPiece={addGroupLocationPiece}
                variables={addGroupVariables}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

    
        case PieceType.ADD_WORKFLOW:
            const addWorkflowVariables = workflowType.variables.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });
            const addWorkflowNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const addWorkflowAffiliatedPiece = workflowPiece.affiliationVariable ? getInnerComponentShorthand(workflowPiece.affiliationVariable, flowchartPieceActions.setAffiliationVariablePiece.bind({}, pieceId, undefined)) : undefined;
            const addWorkflowVariableName = workflowPiece.variable ? variablesState.byId[workflowPiece.variable].name : undefined;

            return <AddWorkflowPiece 
                pieceId={pieceId}
                nextPiece={addWorkflowNextPiece}

                selectedEntityType={workflowPiece.workflowType}
                addingVariableName={addWorkflowVariableName}
                affiliatedPiece={addWorkflowAffiliatedPiece}
                variables={addWorkflowVariables}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

    
        case PieceType.ADD_REPORT:
            const addReportVariables = workflowType.variables.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });
            const addReportNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const addReportVariableName = workflowPiece.variable ? variablesState.byId[workflowPiece.variable].name : undefined;

            const reportNameDetach = () => store.dispatch(setReportName(pieceId, undefined));
            const reportUserDetach = () => store.dispatch(setReportUser(pieceId, undefined));
            const reportStartDateDetach = () => store.dispatch(setReportStartDate(pieceId, undefined));
            const reportEndDateDetach = () => store.dispatch(setReportEndDate(pieceId, undefined));

            const addReportNamePiece = workflowPiece.name && isUUID(workflowPiece.name) ? getInnerComponentShorthand(workflowPiece.name, reportNameDetach) : undefined;
            const addReportNameText = !(workflowPiece.name && isUUID(workflowPiece.name)) ? workflowPiece.name : undefined;

            const addReportUserPiece = workflowPiece.user && isUUID(workflowPiece.user) ? getInnerComponentShorthand(workflowPiece.user, reportUserDetach) : undefined;

            const addReportStartDatePiece = workflowPiece.startDate && isUUID(workflowPiece.startDate) ? getInnerComponentShorthand(workflowPiece.startDate, reportStartDateDetach) : undefined;
            const addReportStartDateText = !(workflowPiece.startDate && isUUID(workflowPiece.startDate)) ? workflowPiece.startDate : undefined;

            const addReportEndDatePiece = workflowPiece.endDate && isUUID(workflowPiece.endDate) ? getInnerComponentShorthand(workflowPiece.endDate, reportEndDateDetach) : undefined;
            const addReportEndDateText = !(workflowPiece.endDate && isUUID(workflowPiece.endDate)) ? workflowPiece.endDate : undefined;

            return <AddReportPiece 
                pieceId={pieceId}
                nextPiece={addReportNextPiece}

                selectedEntityType={workflowPiece.reportType}
                addingVariableName={addReportVariableName}
                namePiece={addReportNamePiece}
                nameText={addReportNameText}
                userPiece={addReportUserPiece}
                startDatePiece={addReportStartDatePiece}
                startDateText={addReportStartDateText}
                endDatePiece={addReportEndDatePiece}
                endDateText={addReportEndDateText}
                variables={addReportVariables}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.ARCHIVE:
            const archiveNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const variablePieceForArchive = workflowPiece.variablePiece ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
            
            return <ArchivePiece 
                pieceId={pieceId}
                nextPiece={archiveNextPiece}
                variablePiece={variablePieceForArchive}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SET_MEMBERS_IN_GROUP:

            const setMembersNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const variablePieceForSetMembers = workflowPiece.variablePiece ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
            const iterablePieceForSetMembers = workflowPiece.iterableVariable ? getInnerComponentShorthand(workflowPiece.iterableVariable, flowchartPieceActions.setIterableVariable.bind({}, pieceId, undefined)) : undefined;
            
            return <SetMembersInGroupPiece 
                pieceId={pieceId}
                nextPiece={setMembersNextPiece}

                memberType={workflowPiece.memberType}
                variablePiece={variablePieceForSetMembers}
                iterableVariablePiece={iterablePieceForSetMembers}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.STATUS:

            return <StatusPiece 
                pieceId={pieceId}

                statusIds={workflowType.statuses}
                selectedStatusId={workflowPiece.statusId}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />
        
        default:
            return getComponent(pieceId, piecesState, variablesState, workflowType.variables, getInnerComponentShorthand, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, detachPiece, initialPosition);
    }
}

export const piecesByCategory = {
    'Control': {
        color: '#14b1ab',
        pieces: [{
            name: 'For',
            type: PieceType.FOR,
        }, {
            name: 'End',
            type: PieceType.END,
        }, {
            name: 'Status',
            type: PieceType.STATUS,
        }, {
            name: 'Update Status',
            type: PieceType.UPDATE_STATUS,
        }, {
            name: 'Update Due Date',
            type: PieceType.UPDATE_DUE_DATE,
        }, {
            name: 'Split',
            type: PieceType.SPLIT,
        }, {
            name: 'Show',
            type: PieceType.SHOW,
        }, {
            name: 'Grouped Show',
            type: PieceType.GROUPED_SHOW,
        }, {
            name: 'Switch Workflow',
            type: PieceType.SWITCH_WORKFLOW,
        }, {
            name: 'Transfer',
            type: PieceType.TRANSFER_WORKFLOW,
        }, {
            name: 'Group',
            type: PieceType.GROUP,
        }, {
            name: 'Group for list',
            type: PieceType.GROUP_FOR_LIST,
        }, {
            name: 'Section',
            type: PieceType.SECTION,
        }, {
            name: 'Add Member',
            type: PieceType.ADD_MEMBER,
        }, {
            name: 'Add Group',
            type: PieceType.ADD_GROUP,
        }, {
            name: 'Add Workflow',
            type: PieceType.ADD_WORKFLOW,
        }, {
            name: 'Add Report',
            type: PieceType.ADD_REPORT,
        }, {
            name: 'Archive',
            type: PieceType.ARCHIVE,
        }, {
            name: 'Set Members in Group',
            type: PieceType.SET_MEMBERS_IN_GROUP,
        }, {
            name: 'Structure',
            type: PieceType.STRUCTURE,
        }, {
            name: 'Restrict Navigation',
            type: PieceType.RESTRICT_NAVIGATION,
        }],
    },
    'Constants': {
        color: '#efaa4b',
        pieces: [{
            name: 'Today',
            type: PieceType.TODAY,
        }, {
            name: 'True',
            type: PieceType.TRUE,
        }, {
            name: 'False',
            type: PieceType.FALSE,
        }, {
            name: 'My Groups',
            type: PieceType.MY_GROUPS,
        }, {
            name: 'My Members',
            type: PieceType.MY_MEMBERS,
        }, {
            name: 'Financial Year Months',
            type: PieceType.FINANCIAL_YEAR_MONTHS,
        }],
    },
    'Arithmetic Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Add',
            type: PieceType.ADD,
        }, {
            name: 'Subtract',
            type: PieceType.SUBTRACT,
        }, {
            name: 'Multiply',
            type: PieceType.MULTIPLY,
        }, {
            name: 'Divide',
            type: PieceType.DIVIDE,
        }, {
            name: 'Exponent',
            type: PieceType.EXPONENT
        }, {
            name: 'Sequence',
            type: PieceType.SEQUENCE,
        }],
    },
    'Boolean Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Lesser Than',
            type: PieceType.LESSER_THAN,
        }, {
            name: 'Greater Than',
            type: PieceType.GREATER_THAN,
        }, {
            name: 'Equal To',
            type: PieceType.EQUAL_TO,
        }, {
            name: 'Not Equal To',
            type: PieceType.NOT_EQUAL_TO,
        }, {
            name: 'In',
            type: PieceType.IN,
        }, {
            name: 'And',
            type: PieceType.AND,
        }, {
            name: 'Or',
            type: PieceType.OR,
        }, {
            name: 'Not',
            type: PieceType.NOT,
        }, {
            name: 'Variable to Boolean',
            type: PieceType.VARIABLE_TO_BOOLEAN,
        }, {
            name: 'Boolean to Variable',
            type: PieceType.BOOLEAN_TO_VARIABLE,
        }, {
            name: 'Is Defined',
            type: PieceType.IS_DEFINED,
        }],
    },
    'List Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Pick first element',
            type: PieceType.PICK_FIRST_ELEMENT,
        }, {
            name: 'Pick first N elements',
            type: PieceType.PICK_FIRST_N_ELEMENTS,
        }, {
            name: 'Pick last element',
            type: PieceType.PICK_LAST_ELEMENT,
        }, {
            name: 'Pick last N elements',
            type: PieceType.PICK_LAST_N_ELEMENTS,
        }, {
            name: 'Pick Nth element',
            type: PieceType.PICK_NTH_ELEMENT,
        }, {
            name: 'Add to list',
            type: PieceType.ADD_TO_LIST,
        }, {
            name: 'Remove from list',
            type: PieceType.REMOVE_FROM_LIST,
        }, {
            name: 'Length',
            type: PieceType.LENGTH,
        }],
    },
    'Date Operators': {
        color: '#efaa4b',
        pieces: [{
            name: 'Add Months',
            type: PieceType.ADD_MONTHS,
        }, {
            name: 'Add Years',
            type: PieceType.ADD_YEARS,
        }, {
            name: 'Subtract Months',
            type: PieceType.SUBTRACT_MONTHS,
        }, {
            name: 'Subtract Years',
            type: PieceType.SUBTRACT_YEARS,
        }, {
            name: 'Get Date',
            type: PieceType.GET_DATE,
        }, {
            name: 'Get Day',
            type: PieceType.GET_DAY,
        }, {
            name: 'Get Month',
            type: PieceType.GET_MONTH,
        }, {
            name: 'Get Readable Month',
            type: PieceType.GET_READABLE_MONTH,
        }, {
            name: 'Get Year',
            type: PieceType.GET_YEAR,
        }],
    },
    'Variables': {
        color: '#8891c8',
        pieces: [{
            name: 'Variable',
            type: PieceType.VARIABLE,
        }, {
            name: 'Set Variable',
            type: PieceType.SET_VARIABLE,
        }],
    },
    'Custom Fields': {
        color: '#d289c0',
        pieces: [{
            name: 'Custom Field',
            type: PieceType.CUSTOM_FIELD,
        }, {
            name: 'Get',
            type: PieceType.GET_VALUE,
        }, {
            name: 'Store',
            type: PieceType.STORE,
        }],
    },
    'Question': {
        color: '#889e46',
        pieces: [{
            name: 'Question',
            type: PieceType.QUESTION,
        }, {
            name: 'Grouped Question',
            type: PieceType.GROUPED_QUESTION,
        }, {
            name: 'Choose',
            type: PieceType.CHOOSE,
        }, {
            name: 'Grouped Choose',
            type: PieceType.GROUPED_CHOOSE,
        }, {
            name: 'Answer',
            type: PieceType.ANSWER,
        }, {
            name: 'Grouped Answer',
            type: PieceType.GROUPED_ANSWER,
        }, {
            name: 'Success',
            type: PieceType.SUCCESS,
        }, {
            name: 'Error',
            type: PieceType.ERROR,
        }],
    },
};