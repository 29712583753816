import { PieceType } from '../pieces/types';

import { isUUID } from '../../../helpers/utilities';
import { ApplicationState } from '../../main';
import { CustomFieldValueType, FieldType, CustomField, CustomFieldOptionsDataType } from '../../custom-fields';
import { WorkflowProcessState, IUpdateableWorkflowData } from '../../workflows/types';

import { getPieceValue, updateDisplayingQuestion, updateLastComputedPiece, updateWorkflowCustomFieldValueForMember, getNextPieceId, executePiece, updateDisplayingShowPiece, updateDisplayingGroupPiece, updateCreatedWorkflowId, updateDisplayingTransferPiece, updateWorkflowCustomFieldValue, updateVariableValue, getPieceValueType } from './index';

import uuid from 'uuid';
import moment from 'moment';
import { getWorkflowComputedFieldValue } from './custom-fields/workflow';
import { VariableType } from '../variables/types';
import { WorkflowTypeCustomField } from '../../workflows/types/types';
import { IUpdateableMemberData } from '../../members/types';
import { IUpdateableGroupData } from '../../groups/types';
import { VariableValueType } from '../types';
import store from '../../main';
import { restrictNavigation, deleteWorkflow } from '../../workflows/actions';
import { deleteMember } from '../../members/actions';
import { deleteGroup } from '../../groups/actions';
import { deleteReport, addReport } from '../../reports/actions';


export function getWorkflowPieceValue(applicationState: ApplicationState, processState: WorkflowProcessState, workflowId: string, pieceId: string) : VariableValueType  {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];
    const workflowsData = applicationState.workflows;
    const workflow = workflowsData.byId[workflowId];
    const workflowType = workflowsData.types.byId[workflow.type];

    const functionShortHand = getWorkflowPieceValue.bind({}, applicationState, processState, workflowId);

    switch(piece.type) {
        case PieceType.CUSTOM_FIELD:
            if (!piece.customField) {
                throw new Error('A custom field needs to be selected');
            }

            if (piece.customFieldOption) {
                return workflowsData.types.customFieldOptions.byId[piece.customFieldOption].name;
            }

            const customField = workflowsData.types.customFields.byId[piece.customField];

            if (customField.isComputed) {
                let startPiece: string|undefined;

                if (workflowType.startPiece) {
                    startPiece = workflowType.startPiece.piece;
                }

                const computedValue = getWorkflowComputedFieldValue(applicationState, processState, startPiece, workflowId);

                return computedValue;
            }

            if (workflowType.affiliation === 'group' && customField.affiliation === 'member') {
                const customFieldMemberData = processState.customFields[customField.id];

                if (Array.isArray(customFieldMemberData) || typeof customFieldMemberData !== 'object') {
                    throw new Error('This field must be an object with a different value for each member ID');
                }

                if (!piece.memberVariablePiece) {
                    throw new Error('The piece must have a member variable');
                }
    
                const memberVariablePiece = piecesData.byId[piece.memberVariablePiece];
    
                if (memberVariablePiece.type !== PieceType.VARIABLE) {
                    throw new Error('This piece must be a variable piece');
                }
    
                if (!memberVariablePiece.variable) {
                    throw new Error('The variable piece must point to a variable');
                }
    
                const customFieldMemberId = processState.variables[memberVariablePiece.variable];
    
                if (typeof customFieldMemberId !== 'string') {
                    throw new Error('The member ID must always be a string');
                }

                let customFieldValue = customFieldMemberData[customFieldMemberId];

                if (!Array.isArray(customFieldValue) && typeof customFieldValue === 'object') {
                    throw new Error('This field must be not be a collection of data for individual members');
                }

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (!!customFieldValue) {
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }
    
                        customFieldValue = workflowsData.types.customFieldOptions.byId[customFieldValue].name;
                        
                    }
                }

                if (customField.type === FieldType.MULTI_SELECT) {
                    if (!!customFieldValue) {
                        if (!Array.isArray(customFieldValue)) {
                            throw new Error('A multi select must have an array');
                        }
    
                        customFieldValue = customFieldValue.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);
                        
                    }
                }

                return customFieldValue;

            } else {
                let customFieldValue = processState.customFields[customField.id];

                if (!Array.isArray(customFieldValue) && typeof customFieldValue === 'object') {
                    throw new Error('This field must be not be a collection of data for individual members');
                }

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (!!customFieldValue) {
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }
    
                        customFieldValue = workflowsData.types.customFieldOptions.byId[customFieldValue].name;
                        
                    }
                }

                if (customField.type === FieldType.MULTI_SELECT) {
                    if (!!customFieldValue) {
                        if (!Array.isArray(customFieldValue)) {
                            throw new Error('A multi select must have an array');
                        }
    
                        customFieldValue = customFieldValue.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);
                        
                    }
                }

                return customFieldValue;
            }

        case PieceType.SEQUENCE:
            const allPreviousWorkflows = workflowsData.allEntries.slice(0, workflowsData.allEntries.indexOf(workflow.id) + 1);
            const selectedOptions = piece.selectedOptions;

            if (!selectedOptions || selectedOptions.length === 0) {
                return 1;  // There are no options, so all values are the first of thier kind
            }
        
            let sequenceNumber = 0;  // Initialize the number to zero before checking
        
            allPreviousWorkflows.forEach(previousWorkflowId => {
                const previousWorkflow = workflowsData.byId[previousWorkflowId];
                let matchesPreviousWorkflow = true;

                selectedOptions.forEach(option => {

                    switch(option) {
                        case 'type':
                            if (previousWorkflow.type !== workflow.type) {
                                matchesPreviousWorkflow = false;
                                return;
                            }
                            break;
                        case 'affiliatedEntity':
                            if (previousWorkflow.affiliatedEntity !== workflow.affiliatedEntity) {
                                matchesPreviousWorkflow = false;
                                return;
                            }
                            break;
                    }
                });

                if (matchesPreviousWorkflow) {
                    sequenceNumber += 1;
                }
            });
            
            return sequenceNumber;

        case PieceType.STATUS:
            return piece.statusId ? workflowsData.types.statuses.byId[piece.statusId].name : workflowsData.types.statuses.byId[workflow.status].name;
        
        default:
            return getPieceValue(applicationState, processState, pieceId, functionShortHand);
    }
}


function getNextPieceIdForWorkflow(applicationState: ApplicationState, processState: WorkflowProcessState, workflowId: string, startPiece: string|undefined): string|undefined {
    const workflowData = applicationState.workflows;

    const workflow = workflowData.byId[workflowId];
    const workflowStatus = workflowData.types.statuses.byId[workflow.status];

    const getNextPieceIdShortHand = getNextPieceIdForWorkflow.bind({}, applicationState, processState, workflowId, startPiece);

    const getPieceValueShortHand = getWorkflowPieceValue.bind({}, applicationState, processState, workflowId);

    if (workflowStatus.isTerminal) {
        return undefined;
    }

    return getNextPieceId(applicationState, processState, startPiece, getNextPieceIdShortHand, getPieceValueShortHand);
}

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForWorkflow(applicationState: ApplicationState, processState: WorkflowProcessState, workflowId: string, pieceId: string, updateStatus: (workflowId: string, statusId: string) => void, updateDueDate: (workflowId: string, dueDate: string) => void, updateCustomFieldValue: (entityId: string, type: VariableType, fieldId: string, value: CustomFieldValueType, memberId?: string) => void, addMember: (data: IUpdateableMemberData) => void, addGroup: (data: IUpdateableGroupData) => void, setMembersInGroup: (groupId: string, membersType: 'representatives'|'all_members', memberIds: Array<string>) => void, addWorkflow: (data: IUpdateableWorkflowData) => void) {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];


    switch(piece.type) {
        
        case PieceType.END:
            if (!piece.status) {
                throw new Error('The end piece must point to a status');
            }

            updateStatus(workflowId, piece.status);
            updateLastComputedPiece(processState, pieceId);
            return false;

        case PieceType.RESTRICT_NAVIGATION:
            store.dispatch(restrictNavigation(workflowId));
            updateLastComputedPiece(processState, pieceId);
            return true;
        
        case PieceType.UPDATE_STATUS:
            if (!piece.status) {
                throw new Error('The piece must point to a status');
            }

            updateStatus(workflowId, piece.status);
            updateLastComputedPiece(processState, pieceId);
            return true;
        
        case PieceType.UPDATE_DUE_DATE:
            if (!piece.dueDate) {
                throw new Error('The piece must have a due date');
            }

            const dueDate = piece.dueDate && isUUID(piece.dueDate) ? getWorkflowPieceValue(applicationState, processState, workflowId, piece.dueDate) : piece.dueDate;

            if (typeof dueDate !== 'string') {
                throw new Error('Invalid due date');
            }

            updateDueDate(workflowId, dueDate);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.ADD_MEMBER:
            if (typeof piece.entityType === 'undefined') {
                throw new Error('Member type needs to be defined');
            }

            if (typeof piece.variable === 'undefined') {
                throw new Error('This piece must point to a variable');
            }

            if (typeof piece.locationPiece !== 'string' || !isUUID(piece.locationPiece)) {
                throw new Error('The add member needs to have a location piece');
            }

            const memberLocation = getWorkflowPieceValue(applicationState, processState, workflowId, piece.locationPiece);

            if (typeof memberLocation !== 'string' || !isUUID(memberLocation)) {
                throw new Error('This value must be a UUID for a location')
            }
            const newMemberId = uuid.v4();

            addMember({
                id: newMemberId,
                type: piece.entityType,
                location: memberLocation,
                customFields: {},
                groups: {},
            });

            store.dispatch(restrictNavigation(workflowId));
            updateVariableValue(processState, piece.variable, newMemberId);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.ADD_GROUP:
            if (typeof piece.entityType === 'undefined') {
                throw new Error('Group type needs to be defined');
            }

            if (typeof piece.variable === 'undefined') {
                throw new Error('This piece must point to a variable');
            }

            if (typeof piece.locationPiece !== 'string' || !isUUID(piece.locationPiece)) {
                throw new Error('The add group needs to have a location piece');
            }

            const groupLocation = getWorkflowPieceValue(applicationState, processState, workflowId, piece.locationPiece);

            if (typeof groupLocation !== 'string' || !isUUID(groupLocation)) {
                throw new Error('This value must be a UUID for a location')
            }
            const newGroupId = uuid.v4();

            addGroup({
                id: newGroupId,
                type: piece.entityType,
                location: groupLocation,
                customFields: {},
                representatives: [],
                members: [],
            });

            store.dispatch(restrictNavigation(workflowId));
            updateVariableValue(processState, piece.variable, newGroupId);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.ADD_WORKFLOW:

            if (!piece.workflowType) {
                throw new Error('The start workflow piece must have a type');
            }

            if (typeof piece.variable === 'undefined') {
                throw new Error('This piece must point to a variable');
            }

            const newWorkflowType = applicationState.workflows.types.byId[piece.workflowType];

            const newWorkflowStatuses = newWorkflowType.statuses.map(statusId => applicationState.workflows.types.statuses.byId[statusId]).filter(workflowStatus => !workflowStatus.isTerminal);

            if (newWorkflowStatuses.length === 0) {
                throw new Error('A workflow of this type should have at least one non terminal status');
            }

            const dueInDays = newWorkflowStatuses[0].dueInDays || 7;

            const newWorkflowDueDate = moment().add(dueInDays, 'days').format('YYYY-MM-DD');

            let addWorkflowAffiliationValue: VariableValueType = '';

            if (newWorkflowType.affiliation !== 'none') {

                if (!piece.affiliationVariable) {
                    throw new Error('The start workflow piece must have an affiliation variable');
                }
    
                const addWorkflowAffiliationVariablePiece = piecesData.byId[piece.affiliationVariable];
    
                if (addWorkflowAffiliationVariablePiece.type !== PieceType.VARIABLE) {
                    throw new Error('This piece must be a variable piece');
                }
    
                addWorkflowAffiliationValue = getWorkflowPieceValue(applicationState, processState, workflowId, addWorkflowAffiliationVariablePiece.id);
    
                if (typeof addWorkflowAffiliationValue !== 'string' || !isUUID(addWorkflowAffiliationValue)) {
                    throw new Error('This value must be an ID for the affiliated entity');
                }

            }

            const addedWorkflowId = uuid.v4();

            addWorkflow({
                id: addedWorkflowId,
                type: piece.workflowType,
                status: newWorkflowStatuses[0].id,
                dueDate: newWorkflowDueDate,
                affiliatedEntity: addWorkflowAffiliationValue,
                user: applicationState.workflows.byId[workflowId].user,
                triggeringWorkflow: workflowId,
            });

            store.dispatch(restrictNavigation(workflowId));
            updateVariableValue(processState, piece.variable, addedWorkflowId);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.ADD_REPORT:

            if (!piece.name) {
                throw new Error('The add report piece must have a name');
            }

            if (!piece.reportType) {
                throw new Error('The add report piece must have a type');
            }

            if (!piece.user) {
                throw new Error('The add report piece must have a user');
            }

            if (!piece.startDate) {
                throw new Error('The add report piece must have a start date');
            }

            if (!piece.endDate) {
                throw new Error('The add report piece must have an end date');
            }

            if (typeof piece.variable === 'undefined') {
                throw new Error('This piece must point to a variable');
            }

            const newReportName = piece.name && isUUID(piece.name) ? getWorkflowPieceValue(applicationState, processState, workflowId, piece.name) : piece.name;
            const newReportType = applicationState.reports.types.byId[piece.reportType];
            const newReportUser = piece.user && isUUID(piece.user) ? getWorkflowPieceValue(applicationState, processState, workflowId, piece.user) : undefined;
            const newReportStartDate = piece.name && isUUID(piece.startDate) ? getWorkflowPieceValue(applicationState, processState, workflowId, piece.startDate) : piece.startDate;
            const newReportEndDate = piece.name && isUUID(piece.endDate) ? getWorkflowPieceValue(applicationState, processState, workflowId, piece.endDate) : piece.endDate;

            if (typeof newReportName !== 'string') {
                throw new Error('The new report name must be a string');
            }

            if (typeof newReportUser !== 'string' || !isUUID(newReportUser)) {
                throw new Error('The new report user must be a valid ID');
            }

            if (typeof newReportUser !== 'string' || !isUUID(newReportUser)) {
                throw new Error('The new report user must be a valid ID');
            }

            if (typeof newReportStartDate !== 'string' || !moment(newReportStartDate, 'YYYY-MM-DD').isValid()) {
                throw new Error('The new report start date is invalid');
            }

            if (typeof newReportEndDate !== 'string' || !moment(newReportEndDate, 'YYYY-MM-DD').isValid()) {
                throw new Error('The new report end date is invalid');
            }

            const addedReportId = uuid.v4();

            store.dispatch(addReport({
                id: addedReportId,
                name: newReportName,
                type: newReportType.id,
                user: newReportUser,
                startDate: newReportStartDate,
                endDate: newReportEndDate,
            }));

            store.dispatch(restrictNavigation(workflowId));
            updateVariableValue(processState, piece.variable, addedReportId);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.ARCHIVE:

            if (typeof piece.variablePiece === 'undefined') {
                throw new Error('This piece must have a group to point to');
            }

            const archiveVariable = getWorkflowPieceValue(applicationState, processState, workflowId, piece.variablePiece);
            const archiveVariableType = getPieceValueType(piece.variablePiece, applicationState.flowchart.pieces, applicationState.flowchart.variables);

            if (typeof archiveVariable !== 'string' || !isUUID(archiveVariable)) {
                throw new Error('The archiving variable must be a UUID');
            }

            switch (archiveVariableType) {
                case VariableType.MEMBER:
                    store.dispatch(deleteMember(archiveVariable));
                    break;
                case VariableType.GROUP:
                    store.dispatch(deleteGroup(archiveVariable));
                    break;
                case VariableType.WORKFLOW:
                    store.dispatch(deleteWorkflow(archiveVariable));
                    break;
                case VariableType.REPORT:
                    store.dispatch(deleteReport(archiveVariable));
                    break;
                default:
                    throw new Error('The given type cannot be archived');
            }

            store.dispatch(restrictNavigation(workflowId));
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.SET_MEMBERS_IN_GROUP:
            if (typeof piece.iterableVariable === 'undefined') {
                throw new Error('The iterable variable piece must be defined');
            }

            if (typeof piece.variablePiece === 'undefined') {
                throw new Error('This piece must have a group to point to');
            }

            if (typeof piece.memberType === 'undefined') {
                throw new Error('The piece must have a member type');
            }

            let membersToSet = getWorkflowPieceValue(applicationState, processState, workflowId, piece.iterableVariable);
            const groupToSet = getWorkflowPieceValue(applicationState, processState, workflowId, piece.variablePiece);

            if (typeof membersToSet !== 'string' && !Array.isArray(membersToSet)) {
                throw new Error('The members must be a string, or a list of strings');
            }

            if (Array.isArray(membersToSet)) {

                if (membersToSet.length > 0 && Array.isArray(membersToSet[0])) {
                    // Members to set cannot be multidimensional
                    throw new Error('The list value cannot be a multi-dimensional array')
                }

                membersToSet = membersToSet as Array<string>;
            }

            if (typeof groupToSet !== 'string' || !isUUID(groupToSet)) {
                throw new Error('This value must be a UUID for a group')
            }

            setMembersInGroup(groupToSet, piece.memberType, typeof membersToSet === 'string' ? [membersToSet] : membersToSet);
            updateLastComputedPiece(processState, pieceId);
            return true;
        
        case PieceType.STORE:

            if (typeof piece.dataToStore === 'undefined') {
                throw new Error('There is no data to store');
            }

            if (typeof piece.variablePiece === 'undefined') {
                throw new Error('There is no entity to store the value in');
            }

            if (!piece.customFieldId) {
                throw new Error('The store piece must have a custom field')
            }

            let rawDataValue = piece.dataToStore && isUUID(piece.dataToStore) ? getWorkflowPieceValue(applicationState, processState, workflowId, piece.dataToStore) : piece.dataToStore;
            let dataValue;

            if (Array.isArray(rawDataValue)) {

                if (rawDataValue.length > 0 && Array.isArray(rawDataValue[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                rawDataValue = rawDataValue as Array<string>;
            }

            if (rawDataValue === null) {
                rawDataValue = undefined;
            }

            const entityToStoreIn = getPieceValue(applicationState, processState, piece.variablePiece);

            if (!(typeof entityToStoreIn === 'string' && isUUID(entityToStoreIn))) {
                throw new Error('The entity ID must be a valid UUID')
            }

            const storeVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, applicationState.flowchart.pieces, applicationState.flowchart.variables) : VariableType.TEXT;

            if (typeof storeVariableType === 'undefined') {
                throw new Error('The entity does not have a type');
            }

            if (storeVariableType !== VariableType.USER && storeVariableType !== VariableType.MEMBER && storeVariableType !== VariableType.GROUP && storeVariableType !== VariableType.WORKFLOW && storeVariableType !== VariableType.LOCATION) {
                throw new Error('The entity is of a type that does not support custom fields');
            }

            let customField: WorkflowTypeCustomField | CustomField;
            let customFieldOptions: CustomFieldOptionsDataType;

            if (storeVariableType === VariableType.LOCATION) {
                customField = applicationState.structure.levels.customFields.byId[piece.customFieldId];
                customFieldOptions = applicationState.structure.levels.customFieldOptions;
            } else if (storeVariableType === VariableType.USER) {
                customField = applicationState.users.customFields.byId.hasOwnProperty(piece.customFieldId) ? applicationState.users.customFields.byId[piece.customFieldId] : applicationState.structure.roles.customFields.byId[piece.customFieldId];
                customFieldOptions = applicationState.users.customFields.byId.hasOwnProperty(piece.customFieldId) ? applicationState.users.customFieldOptions : applicationState.structure.roles.customFieldOptions;
            } else if (storeVariableType === VariableType.MEMBER) {
                customField = applicationState.members.types.customFields.byId[piece.customFieldId];
                customFieldOptions = applicationState.members.types.customFieldOptions;
            } else if (storeVariableType === VariableType.GROUP) {
                customField = applicationState.groups.types.customFields.byId[piece.customFieldId];
                customFieldOptions = applicationState.groups.types.customFieldOptions;
            } else if (storeVariableType === VariableType.WORKFLOW) {
                customField = applicationState.workflows.types.customFields.byId[piece.customFieldId];
                customFieldOptions = applicationState.workflows.types.customFieldOptions;
            } else {
                throw new Error('Unknown custom field type');
            }

            if (customField.type === FieldType.SINGLE_SELECT) {
                if (typeof rawDataValue === 'undefined') {
                    updateLastComputedPiece(processState, pieceId);
                    return true;
                }

                if (typeof rawDataValue !== 'string') {
                    throw new Error('The value for this field must be a choice ID');
                }

                const fieldId = customField.choices.find(choiceId => {
                    const field = customFieldOptions.byId[choiceId];

                    return field.name === rawDataValue;
                });

                if (typeof fieldId === 'undefined') {
                    throw new Error('No field with that name exists');
                }

                dataValue = fieldId;
            } else if (customField.type === FieldType.MULTI_SELECT) {

                const fieldIds = customField.choices.filter(choiceId => {
                    const field = customFieldOptions.byId[choiceId];
                    
                    if (typeof rawDataValue === 'undefined') {
                        return false;
                    }

                    if (!Array.isArray(rawDataValue)) {
                        throw new Error('The value for this field must be an array of choice IDs');
                    }

                    if (Array.isArray(rawDataValue)) {
        
                        if (rawDataValue.length > 0 && Array.isArray(rawDataValue[0])) {
                            // Cannot be a multidimensional array
                            throw new Error('The value cannot be a multi-dimensional array')
                        }
        
                        rawDataValue = rawDataValue as Array<string>;
                    }

                    return rawDataValue.includes(field.name);
                });

                dataValue = fieldIds;
            } else if (typeof rawDataValue === 'number') {
                dataValue = String(rawDataValue);
            } else {
                dataValue = rawDataValue;
            }

            let memberId;

            if (piece.memberVariablePiece) {
                const memberVariablePiece = piecesData.byId[piece.memberVariablePiece];

                if (memberVariablePiece.type !== PieceType.VARIABLE) {
                    throw new Error('This piece must be a variable piece');
                }

                if (!memberVariablePiece.variable) {
                    throw new Error('The variable piece must point to a variable');
                }

                memberId = processState.variables[memberVariablePiece.variable];

                if (typeof memberId !== 'string') {
                    throw new Error('The member ID must always be a string');
                }
            }

            if (entityToStoreIn === workflowId) {
                if (memberId) {
                    updateWorkflowCustomFieldValueForMember(processState, piece.customFieldId, dataValue, memberId);
                } else {
                    updateWorkflowCustomFieldValue(processState, piece.customFieldId, dataValue);
                }
            } else {

                if (storeVariableType === VariableType.WORKFLOW) {
                    throw new Error('You cannot store the custom field values in any workflow except the currently executing workflow!');
                }

                updateCustomFieldValue(entityToStoreIn, storeVariableType, piece.customFieldId, dataValue, memberId);
            }

            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.CHOOSE:
            updateDisplayingQuestion(processState, pieceId);
            return false;

        case PieceType.QUESTION:
            updateDisplayingQuestion(processState, pieceId);
            return false;

        case PieceType.SHOW:
            updateDisplayingShowPiece(processState, pieceId);
            return false;

        case PieceType.GROUP:
            updateDisplayingGroupPiece(processState, pieceId);
            return false;

        case PieceType.GROUP_FOR_LIST:
            updateDisplayingGroupPiece(processState, pieceId);
            return false;

        case PieceType.TRANSFER_WORKFLOW:
            updateDisplayingTransferPiece(processState, piece.id);
            return false;

        case PieceType.START_WORKFLOW:

            if (!piece.workflowType) {
                throw new Error('The start workflow piece must have a type');
            }

            if (!piece.workflowStatus) {
                throw new Error('The start workflow piece must have a type');
            }

            if (!piece.workflowDueDateVariable) {
                throw new Error('The start workflow piece must have a due date');
            }

            if (!piece.affiliationVariable) {
                throw new Error('The start workflow piece must have an affiliation variable');
            }

            const startWorkflowDueDateVariablePiece = piecesData.byId[piece.workflowDueDateVariable];

            const dueDateValue = getWorkflowPieceValue(applicationState, processState, workflowId, startWorkflowDueDateVariablePiece.id);

            if (typeof dueDateValue !== 'string') {
                throw new Error('This value must be a date formatted as a string');
            }

            const startWorkflowAffiliationVariablePiece = piecesData.byId[piece.affiliationVariable];

            if (startWorkflowAffiliationVariablePiece.type !== PieceType.VARIABLE) {
                throw new Error('This piece must be a variable piece');
            }

            const affiliationValue = getWorkflowPieceValue(applicationState, processState, workflowId, startWorkflowAffiliationVariablePiece.id);

            if (typeof affiliationValue !== 'string' || !isUUID(affiliationValue)) {
                throw new Error('This value must be an ID for the affiliated entity');
            }

            const newWorkflowId = uuid.v4();

            let newWorkflowData: IUpdateableWorkflowData;

            if (piece.isAsync) {
                newWorkflowData = {
                    id: newWorkflowId,
                    type: piece.workflowType,
                    status: piece.workflowStatus,
                    dueDate: dueDateValue,
                    affiliatedEntity: affiliationValue,
                    user: applicationState.workflows.byId[workflowId].user,
                };

                addWorkflow(newWorkflowData);
                updateCreatedWorkflowId(processState, newWorkflowId);
                updateLastComputedPiece(processState, pieceId);
                return true;
            } else {
                newWorkflowData = {
                    id: newWorkflowId,
                    type: piece.workflowType,
                    status: piece.workflowStatus,
                    dueDate: dueDateValue,
                    affiliatedEntity: affiliationValue,
                    user: applicationState.workflows.byId[workflowId].user,
                    triggeringWorkflow: workflowId,
                };

                addWorkflow(newWorkflowData);
                updateCreatedWorkflowId(processState, newWorkflowId);
                updateLastComputedPiece(processState, pieceId);
                return false;
            }

        case PieceType.SWITCH_WORKFLOW:

            if (!piece.variablePiece) {
                throw new Error('The start workflow piece must have an affiliation variable');
            }

            const workflowToSwitchTo = getWorkflowPieceValue(applicationState, processState, workflowId, piece.variablePiece);

            if (typeof workflowToSwitchTo !== 'string') {
                throw new Error('This variable must point to a workflow ID');
            }

            updateCreatedWorkflowId(processState, workflowToSwitchTo);
            updateLastComputedPiece(processState, pieceId);
            return false;

        default:
            const getPieceValueShortHand = getWorkflowPieceValue.bind({}, applicationState, processState, workflowId);
            return executePiece(applicationState, processState, pieceId, getPieceValueShortHand);
    }
}

export function startOrResumeWorkflow(applicationState: ApplicationState, processState: WorkflowProcessState, workflowId: string, updateStatus: (workflowId: string, statusId: string) => void, updateDueDate: (workflowId: string, dueDate: string) => void, updateCustomFieldValue: (entityId: string, type: VariableType, fieldId: string, value: CustomFieldValueType, memberId?: string) => void, addToHistory: (processState: WorkflowProcessState, workflowId: string) => void, addMember: (data: IUpdateableMemberData) => void, addGroup: (data: IUpdateableGroupData) => void, setMembersInGroup: (groupId: string, membersType: 'representatives'|'all_members', memberIds: Array<string>) => void, addWorkflow: (data: IUpdateableWorkflowData) => void) {
    let canContinueExecuting: boolean;
    const workflow = applicationState.workflows.byId[workflowId];
    const workflowType = applicationState.workflows.types.byId[workflow.type];

    if (!workflowType.startPiece) {
        throw new Error('The workflow must have a start piece to start or resume');
    }

    do {
        const nextPieceId = getNextPieceIdForWorkflow(applicationState, processState, workflowId, workflowType.startPiece.piece);

        if (typeof nextPieceId === 'undefined') {
            throw new Error('The next piece does not exist');
        }

        canContinueExecuting = executePieceForWorkflow(applicationState, processState, workflowId, nextPieceId, updateStatus, updateDueDate, updateCustomFieldValue, addMember, addGroup, setMembersInGroup, addWorkflow);
    } while (canContinueExecuting);

    addToHistory(processState, workflowId);
}