import React, { Component } from 'react';
import styles from './Languages.module.scss';
import { Redirect } from "react-router-dom";

import { selectLanguage, unSelectLanguage } from '../../shared/store/internationalization/languages/action';

import Translations from './Translations';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../shared/store/main';
import { Permissions } from '../../shared/store/permissions/types';

import LanguagesList from './LanguagesList';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const selectedLanguageId = state.internationalization.languages.selected;
    const canEditLanguages = state.permissions.myPermissions.general.Languages === Permissions.WRITE;
    const canViewLanguages = canEditLanguages || state.permissions.myPermissions.general.Languages === Permissions.READ;

    return {
        isReadable: canViewLanguages,
        selectedLanguage: selectedLanguageId,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectLanguage: (id: string) => dispatch(selectLanguage(id)),
        unSelectLanguage: () => dispatch(unSelectLanguage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedLocations extends Component<Props> {
        
    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        return (<section className={styles.FocusSpace}>
            <div className={styles.languagesHolder}>
                <div className={styles.allLanguages} onClick={this.props.unSelectLanguage}>
                    <LanguagesList heading="Languages" onSelectCard={this.props.selectLanguage} onUnSelectCard={this.props.unSelectLanguage} selectedId={this.props.selectedLanguage} />
                </div>
                {this.props.selectedLanguage && 
                <div className={styles.translationsArea + " ignore-react-onclickoutside"}>
                    <Translations languageId={this.props.selectedLanguage} />
                </div>}
            </div>
        </section>);
        
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;