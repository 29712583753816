import React, { Component, ChangeEvent } from 'react';
import styles from './OrganizationProfile.module.scss';
import ColorPicker from '../../widgets/form/ColorPicker';

import InputText from '../../widgets/form/InputText';
import { ApplicationState } from '../../shared/store/main';
import { OrganizationState } from '../../shared/store/organization/types';
import { Permissions } from '../../shared/store/permissions/types';

import chevronIcon from '../../assets/chevron-arrow-down.svg';
import editIcon from '../../assets/edit.svg';
import cancelIcon from '../../assets/cancel.svg';
import starIcon from '../../assets/star.svg';
import mapIcon from '../../assets/map-pin.svg';


import { connect } from 'react-redux';


type OwnProps = {};
interface OwnState {
    editingOrgProfile: boolean,
    editingOrgDetails: boolean,
    locationKey: number,
    organizationKey: number,
    organization: OrganizationState,
    organizationCleanCopy: OrganizationState | undefined,
};

const mapStateToProps = (state: ApplicationState) => {
    const canWriteOrganizationProfile = state.permissions.myPermissions.general.OrganizationProfile === Permissions.WRITE;
    const canReadOrganizationProfile = canWriteOrganizationProfile || state.permissions.myPermissions.general.OrganizationProfile === Permissions.READ;

    return {
        baseLanguage: 'English',
        otherLanguages: [{
            id: '1',
            name: 'English',
            archived: false,
        }, {
            id: '2',
            name: 'Kannada',
            archived: false,
        }],
        
        organization: state.organization,
        
        read: canReadOrganizationProfile,
        write: canWriteOrganizationProfile,
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;

// function mapDispatchToProps(dispatch) {
//     return {
//         updateOrgData: orgData => dispatch(updateOrgData(orgData))
//     };
// }

type Props = OwnProps & StateProps;

class ConnectedOrganizationProfile extends Component<Props, OwnState> {
        
    state: OwnState = {
        editingOrgProfile: false,
        editingOrgDetails: false,
        locationKey: 0,
        organizationKey: 0,
        organization: {
            name: this.props.organization.name,
            code: this.props.organization.code,
            category: this.props.organization.category,
            categories: this.props.organization.categories,
            size: this.props.organization.size,
            sizes: this.props.organization.sizes,
            image: this.props.organization.image,
            language: this.props.organization.language,
            phone: {
                countryCode: this.props.organization.phone.countryCode,
                number: this.props.organization.phone.number,
            },
            address: this.props.organization.address,
            coOrdinates: {
                latitude: this.props.organization.coOrdinates.latitude,
                longitude: this.props.organization.coOrdinates.longitude,
            },
            primary_color: this.props.organization.primary_color,
            primary_gradient: this.props.organization.primary_gradient,
            contrast_color: this.props.organization.contrast_color,
        },
        organizationCleanCopy: undefined
    }
    
    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (props.organization.code !== state.organization.code) {
            return {
                organization: {
                    name: props.organization.name,
                    code: props.organization.code,
                    category: props.organization.category,
                    categories: props.organization.categories,
                    size: props.organization.size,
                    sizes: props.organization.sizes,
                    image: props.organization.image,
                    language: props.organization.language,
                    phone: {
                        countryCode: props.organization.phone.countryCode,
                        number: props.organization.phone.number,
                    },
                    address: props.organization.address,
                    coOrdinates: {
                        latitude: props.organization.coOrdinates.latitude,
                        longitude: props.organization.coOrdinates.longitude,
                    },
                    primary_color: props.organization.primary_color,
                    primary_gradient: props.organization.primary_gradient,
                    contrast_color: props.organization.contrast_color,
                }
            }
        } else {
            return null;
        }
    }
    
    changePrimaryColor = (color: string, lighterColor: string) => {
        const primary_gradient = 'linear-gradient(to bottom, ' + color + ' 0%, ' + lighterColor + ' 100%)';
        document.documentElement.style.setProperty('--primary-color', color);
        document.documentElement.style.setProperty('--primary-gradient', primary_gradient);
        
        let updatedOrgData = {
            ...this.state.organization,
            primary_color: color,
            primary_gradient: primary_gradient
        };
        
        this.setState({
            organization: updatedOrgData
        });
    }

    getLocation = () => {
        const geolocation = navigator.geolocation,
              locationKey = this.state.locationKey;

        geolocation.getCurrentPosition((position) => {
            let updatedOrganization = {
                ...this.state.organization,
                coOrdinates: {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                }
            };
            
            this.setState({
                organization: updatedOrganization,
                locationKey: locationKey + 2
            })
        });
    }
    
    changeName = (name: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            name: name
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
        let updatedOrganization = {
            ...this.state.organization,
            category: e.target.value
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changeLanguage = (language: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            language: language
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changeSize = (size: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            size: size
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changePhoneCountryCode = (phoneCountryCode: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            phone: {
                ...this.state.organization.phone,
                countryCode: phoneCountryCode,
            }
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changePhoneNumber = (phoneNumber: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            phone: {
                ...this.state.organization.phone,
                number: phoneNumber,
            }
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changeAddress = (address: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            address: address
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changeLatitude = (latitude: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            coOrdinates: {
                ...this.state.organization.coOrdinates,
                latitude: parseInt(latitude)
            }
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changeLongitude = (longitude: string) => {
        let updatedOrganization = {
            ...this.state.organization,
            coOrdinates: {
                ...this.state.organization.coOrdinates,
                longitude: parseInt(longitude)
            }
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    changeContrastColor = (color: string) => {
        document.documentElement.style.setProperty('--contrast-color', color);
        
        let updatedOrganization = {
            ...this.state.organization,
            contrast_color: color
        };
        
        this.setState({
            organization: updatedOrganization
        });
    }
    
    editOrgProfile = () => {
        const cleanCopy = JSON.parse(JSON.stringify(this.state.organization));
        this.setState({
            editingOrgProfile: true,
            organizationCleanCopy: cleanCopy
        });
    }

    saveOrgProfile = () => {
        // this.props.updateOrgData(this.state.orgData);
        this.setState({
            editingOrgProfile: false,
            organizationCleanCopy: undefined
        });
    }

    cancelOrgProfile = () => {
        const cleanCopy = this.state.organizationCleanCopy,
              newKey = this.state.organizationKey + 1;

        if (cleanCopy === undefined) {
            return;
        }
        
        document.documentElement.style.setProperty('--primary-color', cleanCopy.primary_color);
        document.documentElement.style.setProperty('--primary-gradient', cleanCopy.primary_gradient);
        document.documentElement.style.setProperty('--contrast-color', cleanCopy.contrast_color);
        
        this.setState({
            editingOrgProfile: false,
            organization: cleanCopy,
            organizationKey: newKey,
            organizationCleanCopy: undefined
        });
    }

    uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        // this.readURL(e.target);
    }

    // readURL(input: HTMLInputElement) {   

    //     if (input.files && input.files[0]) {
    //         let reader = new FileReader();

    //         reader.onload = (e: ProgressEvent) => {
    //             const base64EncodedImage = e.target.result;
    //             let updatedOrganization = {
    //                 ...this.state.organization,
    //                 image: base64EncodedImage
    //             };

    //             this.setState({
    //                 organization: updatedOrganization
    //             });
    //         };

    //         reader.readAsDataURL(input.files[0]);
    //     }
    // }
    
    render() {
        const allColors = [{
            color: '#ff0000',
            lighterColor: '#ff4d4d'
        }, {
            color: '#00ff00',
            lighterColor: '#4dff4d'
        }, {
            color: '#0000ff',
            lighterColor: '#4d4dff'
        }];
        
        const allLanguages = this.props.otherLanguages.map(language => language.name).concat(this.props.baseLanguage);
        const allCategories = this.props.organization.categories ? this.props.organization.categories.map(category => <option key={category} value={category}>Category: {category}</option>) : [];
        return (
            <section className={styles.OrganisationProfile} key={this.state.organizationKey}>
                <section className={styles.OrganisationHeader}>
                    <section className={styles.orgBox}>
                        <input type="file" id="org-profile-img" onChange={this.uploadImage} className={styles.orgProfileInput + ' ' + styles.orgProfileImage} disabled={!this.state.editingOrgProfile} />
                        <label htmlFor="org-profile-img">
                            <img src={this.state.organization.image} alt="" id="org-profile-image" />
                        </label>
                        <div className={styles.details}>
                            <input className={styles.orgName} defaultValue={this.props.organization.name} disabled={!this.state.editingOrgProfile} />
                            <div className={styles.flexInput}>
                                <label htmlFor="org-code">Organization Code:</label>
                                <input type="text" id="org-code" defaultValue={!this.props.organization.code ? undefined : this.props.organization.code} className={styles.orgProfileInput + ' ' + styles.orgCodeInput} disabled />
                            </div>
                            <div className={styles.flexInput}>
                                <select defaultValue={this.state.organization.category} onChange={this.changeCategory} className={styles.orgCategory + ' ' + styles.orgProfileInput} disabled={!this.state.editingOrgProfile}>
                                    {allCategories}
                                </select>
                                <img src={chevronIcon} alt="" />
                            </div>
                        </div>
                        {this.props.write && !this.state.editingOrgProfile && <div className={styles.editIcon} onClick={this.editOrgProfile}>
                            <img src={editIcon} alt="" />
                        </div>}
                        {this.state.editingOrgProfile && <div className={styles.actionButtons}>
                            <button type="button" onClick={this.saveOrgProfile}>Done</button>
                            <button type="button" onClick={this.cancelOrgProfile}><img src={cancelIcon} alt="" /> Cancel</button>
                        </div>}
                    </section>
                    <section className={styles.orgBox}>
                        <div className={styles.label}>Current Plan:</div>
                        <div className={styles.plan}>Free Plan</div>
                    </section>
                </section>
                
                <div className={styles.contentHolder}>
                    <div className={styles.orgDetails}>
                        <section className={styles.Card} key={this.props.organization.code}>
                            <div className={styles.inputSegment}>
                                <InputText placeholder="Current Language" onChange={value => value && this.changeLanguage(value)} default={this.props.organization.language} isDisabled={!this.state.editingOrgProfile} isSearchDisabled options={allLanguages} />
                            </div>
                            <div className={styles.inputSegment}>
                                <InputText placeholder="Team Size" default={this.props.organization.size} isDisabled={!this.state.editingOrgProfile} onChange={this.changeSize} isSearchDisabled options={this.props.organization.sizes} />
                            </div>
                            <div className={styles.phoneSegment}>
                                <InputText icon={chevronIcon} default={this.props.organization.phone.countryCode} options={['+91', '+1']} isSearchDisabled onChange={this.changePhoneCountryCode} isDisabled={!this.state.editingOrgProfile} />
                                <InputText placeholder="Phone" type="number" default={this.props.organization.phone.number} isSearchDisabled onChange={this.changePhoneCountryCode} isDisabled={!this.state.editingOrgProfile} />
                            </div>
                    
                            <div className={styles.addressSegment}>
                                <InputText placeholder="Address" onChange={this.changeAddress} default={this.props.organization.address} isDisabled={!this.state.editingOrgProfile} />
                            </div>
                            <div className={styles.nullSegment}></div>

                            <div className={styles.geoLocationSegment}>
                                <InputText placeholder="Latitude" type="number" isDisabled={!this.state.editingOrgProfile} key={this.state.locationKey} default={String(this.state.organization.coOrdinates.latitude) || ''} onChange={this.changeLatitude} />
                                <InputText placeholder="Longitude" type="number" isDisabled={!this.state.editingOrgProfile} key={this.state.locationKey + 1} default={String(this.state.organization.coOrdinates.longitude) || ''} onChange={this.changeLongitude} />
                                <img src={mapIcon} onClick={this.getLocation} alt="Location selector"/>
                            </div>
                        </section>

                        <section className={styles.Card}>
                            <div className={styles.ColorsHolder}>
                                <div className={styles.ColorHolder}>
                                    <label>
                                        Primary Color
                                    </label>
                                    <ColorPicker isDisabled={!this.state.editingOrgProfile} colors={allColors} isShowingSliders={false} default={this.props.organization.primary_color} onChangeColor={this.changePrimaryColor} />
                                </div>
                                <div className={styles.ColorHolder}>
                                    <label>
                                        Contrast Color
                                    </label>
                                    <ColorPicker isDisabled={!this.state.editingOrgProfile} colors={allColors} isShowingSliders={false} default={this.props.organization.contrast_color} onChangeColor={this.changeContrastColor} />
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className={styles.upgradePlan}>
                        <div className={styles.adText}>Upgrade Your Plan To</div>
                        <div className={styles.planText}><img src={starIcon} alt="" /><span>Gold User</span></div>
                        <ul>
                            <li><span>10</span> Users</li>
                            <li><span>20</span> Members per User</li>
                            <li><span>Unlimited</span> Reports</li>
                            <li><span>24/7</span> Support</li>
                            <li><span>10</span> Users</li>
                            <li><span>20</span> Members per User</li>
                            <li><span>Unlimited</span> Reports</li>
                        </ul>
                        <div className={styles.bottomContent}>
                            <button type="button">Upgrade Plan</button>
                            <a href="/">Show All Plans</a>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

const OrganizationProfile = connect(mapStateToProps)(ConnectedOrganizationProfile);

export default OrganizationProfile;