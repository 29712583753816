import React, { Component, ChangeEvent } from 'react';
import moment from 'moment';

import ReportModify from './ReportModify';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/main';

import { addReport, updateReport, deleteReport, searchReportTable, goToPageReportTable, setPageSizeReportTable, sortReportTable, storeDataForReport } from '../../../shared/store/reports/actions';
import { IUpdateableReportData } from '../../../shared/store/reports/types';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import Button from '../../../widgets/form/Button';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { DefaultFlowchartProcessState, VariableValueType } from '../../../shared/store/flowchart/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { CSVLink } from 'react-csv';
import { getReportValue } from '../../../shared/store/flowchart/helpers/report';
import { getAllLocationsVisibleToUser } from '../../../shared/helpers/locations';

const mapStateToProps = (state: ApplicationState) => {
    const allProcessedReports = state.reports.allEntries.map(reportId => state.reports.byId[reportId]),
          currentPageNumber = state.reports.currentPageNumber,
          pageSize = state.reports.pageSize,
          totalPages = Math.ceil(allProcessedReports.length / pageSize);
    
    return {
        reportsList: allProcessedReports.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize),
        totalNoOfReports: allProcessedReports.length,
        pageSize: pageSize,
        pageNumber: currentPageNumber,
        totalPages: totalPages,
        
        reportFilters: state.reports.filters,
        reportSort: state.reports.sort,

        reportsData: state.reports,
        reportTypesData: state.reports.types,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        myId: state.myData.id,
        
        areFiltersExpanded: false,

        applicationState: state,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchReportTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageReportTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeReportTable(size)),
        sort: (column: string, order: 'ASC'|'DESC') => dispatch(sortReportTable(column, order)),
        
        addReport: (payload: IUpdateableReportData) => dispatch(addReport(payload)),
        deleteReport: (id: string) => dispatch(deleteReport(id)),
        updateReport: (payload: IUpdateableReportData) => dispatch(updateReport(payload)),
        storeDataForReport: (reportId: string, data: Array<Array<string>>) => dispatch(storeDataForReport(reportId, data)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
}

class ConnectedReportsTable extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
    }

    static defaultProps = {
        isReadOnly: false,
    }
    
    showAddForm = () => {
        this.setState({
            isShowingAddForm: true,
        });
    }

    showModifyForm = () => {
        this.setState({
            isShowingModifyForm: true,
        });
    }
    
    hideReportForm = () => {
        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: false,
        });
    }
    
    addReport = (reportData: IUpdateableReportData) => {
        this.props.addReport(reportData);
        this.setState({
            isShowingAddForm: false
        });
    }
    
    updateReport = (reportData: IUpdateableReportData) => {
        this.props.updateReport(reportData);
        this.setState({
            isShowingModifyForm: false
        });
    }
    
    deleteReport = (id: string) => {
        this.props.deleteReport(id);
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.searchTable(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.reportSort.column !== column;
        const sortOrder = !isNewColumn && this.props.reportSort.order === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }

    generateReportData = (reportId: string) => {
        const report = this.props.reportsData.byId[reportId];
        const reportType = this.props.reportTypesData.byId[report.type];

        let visibleUsers: Array<string> = [],
            visibleMembers: Array<string> = [],
            visibleGroups: Array<string> = [],
            visibleWorkflows: Array<string> = [];

        if (isUUID(report.user)) {
            const visibleLocationIds = getAllLocationsVisibleToUser(report.user);
        
            visibleLocationIds.forEach(locationId => {
                const location = this.props.applicationState.structure.locations.byId[locationId];
                visibleUsers = visibleUsers.concat(location.users);
                visibleMembers = visibleMembers.concat(location.members);
                visibleGroups = visibleGroups.concat(location.groups);
            });

            const reportUser = this.props.usersData.byId[report.user];

            for (const workflowTypeId in reportUser.workflows) {
                visibleWorkflows = visibleWorkflows.concat(reportUser.workflows[workflowTypeId]);
            }

            visibleMembers.forEach(memberId => {
                const member = this.props.membersData.byId[memberId];

                for (const workflowTypeId in member.workflows) {
                    visibleWorkflows = visibleWorkflows.concat(member.workflows[workflowTypeId]);
                }
            });

            visibleGroups.forEach(groupId => {
                const group = this.props.groupsData.byId[groupId];

                for (const workflowTypeId in group.workflows) {
                    visibleWorkflows = visibleWorkflows.concat(group.workflows[workflowTypeId]);
                }
            });

            visibleWorkflows = Array.from(new Set(visibleWorkflows));
        } else {
            visibleUsers = this.props.applicationState.users.allEntries;
            visibleMembers = this.props.applicationState.members.allEntries;
            visibleGroups = this.props.applicationState.groups.allEntries;
            visibleWorkflows = this.props.applicationState.workflows.allEntries;
        }
        
        const reportProcessState: DefaultFlowchartProcessState = {
            executionStack: [],
            forIterationCounts: {},
            customFields: {},
            variables: {
                [reportType.seedUserVariable]: report.user,
                [reportType.seedStartDateVariable]: report.startDate,
                [reportType.seedEndDateVariable]: report.endDate,
                [reportType.seedUsersVariable]: visibleUsers,
                [reportType.seedMembersVariable]: visibleMembers,
                [reportType.seedGroupsVariable]: visibleGroups,
                [reportType.seedWorkflowsVariable]: visibleWorkflows,
            },
            lastComputedPiece: undefined,
            displayingGroupPieceId: undefined,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingTransferPieceId: undefined,
            createdWorkflowId: undefined,
        };

        const startPiece = reportType.startPiece ? reportType.startPiece.piece : undefined;
        
        let reportData = getReportValue(this.props.applicationState, reportProcessState, reportId, startPiece);

        if (!Array.isArray(reportData)) {
            throw new Error('The report data must be a table');
        }

        if (reportData.length > 0 && !Array.isArray(reportData[0])) {
            throw new Error('The report data must be a table');
        }

        reportData = reportData as Array<Array<string>>;

        this.props.storeDataForReport(reportId, reportData);
    }
    
    render() {

        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: 'Name',
            isSortable: false,
            width: 250,
        }, {
            name: 'Type',
            isSortable: false,
            width: 170,
        }, {
            name: 'Start Date',
            isSortable: false,
            width: 200,
        }, {
            name: 'End Date',
            isSortable: false,
            width: 200,
        }, {
            name: 'User',
            isSortable: false,
            width: 130,
        }, {
            name: 'Action',
            isSortable: false,
            width: 200,
        }];

        const entries: Array<TableRow> = this.props.reportsList.map<TableRow>((report, index) => {
            const reportType = this.props.reportTypesData.byId[report.type];
            let userName: VariableValueType = '-';

            if (isUUID(report.user)) {
                const user = this.props.usersData.byId[report.user];
                userName = user.customFields[this.props.usersData.nameFieldId];

                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
                userName = getReadableDataForCustomField(userName, nameField, report.user, 'user');
            }

            const cells = [
                (this.props.pageNumber - 1) * this.props.pageSize + index + 1, // Sl. no
                report.name,
                reportType.name, // Type
                report.startDate ? moment(report.startDate).format('DD MMM YYYY') : '-', // Start Date
                report.endDate ? moment(report.endDate).format('DD MMM YYYY') : '-', // End Date
                userName, // User
                report.data ? <div><CSVLink data={report.data} filename={`${report.name}.csv`}><Button isOutline onClick={() => {}} text="Download" /></CSVLink></div> : <Button isOutline onClick={this.generateReportData.bind(this, report.id)} text="Generate report" />

            ];

            const reportEditForm = <ReportModify reportId={report.id} submit={this.updateReport} cancel={this.hideReportForm} />;

            return {
                id: report.id,
                entries: cells,
                editForm: reportEditForm,
                showViewInsteadOfEdit: false,
            }
        });

        return (
            <TableWithMeta 
                entityType="Report"
                headings={headings} 
                entries={entries} 
                
                sortedColumn={this.props.reportSort.column} 
                sortType={this.props.reportSort.order} 
                isReadOnly={this.props.isReadOnly}
                areFiltersExpanded={this.props.areFiltersExpanded}

                totalPages={this.props.totalPages}
                pageNumber={this.props.pageNumber}
                isAddAllowed={true}
                isShowingAddForm={this.state.isShowingAddForm} 
                isShowingModifyForm={this.state.isShowingModifyForm}
                addForm={<ReportModify submit={this.addReport} cancel={this.hideReportForm} />}

                onSort={this.handleSort} 
                onDelete={this.props.deleteReport} 
                showAddForm={this.showAddForm}
                showModifyForm={this.showModifyForm}  

                search={this.props.searchTable}
                changePageSize={this.props.changePageSize}
                goToPage={this.props.goToPage}
                sort={this.props.sort}
            />
        );
    }
}

const ReportsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedReportsTable);

export default ReportsTable;