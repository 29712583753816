import { PieceType, PieceState, SET_NEXT_PIECE, SET_INNER_PIECE, SET_LOOP_VARIABLE, SET_ITERABLE_VARIABLE, SET_OPERAND, SET_LEFT_OPERAND, SET_RIGHT_OPERAND, SET_MEMBER_VARIABLE, SET_DATA_STORE_VALUE, SET_DATA_SET_VALUE, SET_DATA_COPY_VARIABLE, SET_RETURN_VALUE, SET_QUESTION_DATA, SET_CONDITION_PIECE, SET_CONDITION_NEXT_PIECE, SET_CONDITION_PIECE_POSITION, SET_CONDITION_PIECES, START_PIECE_DRAG, STOP_PIECE_DRAG, SET_TARGET_PIECE, SET_UPDATE_STATUS_VALUE, SET_CUSTOM_FIELD, SET_VARIABLE_FOR_SET, UPDATE_VARIABLE_PIECE, ADD_PIECE, AllPieceTypes, SET_SELECTED_OPTIONS, SET_VARIABLE_FOR_SHOW, SET_ENTITY_TYPE, SET_CUSTOM_FIELDS_FOR_SHOW, DELETE_PIECE, SET_WORKFLOW_TYPE, SET_WORKFLOW_STATUS, SET_WORKFLOW_DUE_DATE, SET_WORKFLOW_AFFILIATION_VARIABLE, SET_WORKFLOW_IS_ASYNC, SET_TRANSFER_ROLES, UPDATE_CUSTOM_FIELD_PIECE, SET_VARIABLE_FOR_CUSTOM_FIELD, SET_UPDATE_DUE_DATE_VALUE, SET_ERROR, UPDATE_ANSWER_PIECE, UPDATE_STATUS_PIECE, ADD_FULL_PIECE, SET_QUESTION_REQUIRED_PIECE, SET_QUESTION_DISABLED_PIECE, SET_VARIABLE_FOR_ADD_MEMBER, SET_VARIABLE_FOR_ADD_GROUP, SET_VARIABLE_FOR_ADD_WORKFLOW, SET_LOCATION_PIECE, SET_VARIABLE_FOR_LIST, SET_VARIABLE_FOR_NEW_DATE, SET_PIECE_FOR_LIST, SET_QUESTION_CHOICE_LIST, SET_QUESTION_CHOICE_VARIABLE, SET_MEMBERS_TYPE_IN_GROUP, SET_VARIABLE_PIECE, SET_HEADING, SET_COLUMNS, SET_STRUCTURE_PROJECT, SET_STRUCTURE_LEVEL, SET_STRUCTURE_ROLE, SET_QUESTION_DEFAULT_PIECE, SET_YEAR, SET_MONTH, SET_DATE, SET_MESSAGE, SET_REPORT_NAME, SET_REPORT_TYPE, SET_REPORT_USER, SET_REPORT_START_DATE, SET_REPORT_END_DATE, SET_VARIABLE_FOR_ADD_REPORT } from './types';
import { UPDATE_FLOWCHART_DATA, FlowchartActionTypes } from '../types';

export const initialState: PieceState = {
    byId: {},
    allEntries: [],

    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),

    isDragging: false,
    lastDraggedPiece: undefined,
    targetPiece: undefined,
}

export function piecesReducer(state = initialState, action: FlowchartActionTypes) : PieceState {
    let newPieceData;
    
    switch(action.type) {
        case SET_NEXT_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.START:
                case PieceType.FOR:
                case PieceType.QUESTION:
                case PieceType.CHOICE:
                case PieceType.STORE:
                case PieceType.GET:
                case PieceType.SET_VARIABLE:
                case PieceType.UPDATE_STATUS:
                case PieceType.UPDATE_DUE_DATE:
                case PieceType.SPLIT:
                case PieceType.SHOW:
                case PieceType.START_WORKFLOW:
                case PieceType.SWITCH_WORKFLOW:
                case PieceType.TRANSFER_WORKFLOW:
                case PieceType.GROUP:
                case PieceType.GROUP_FOR_LIST:
                case PieceType.GROUPED_QUESTION:
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                case PieceType.GROUPED_SHOW:
                case PieceType.REPEAT:
                case PieceType.ADD_MEMBER:
                case PieceType.ADD_GROUP:
                case PieceType.ADD_WORKFLOW:
                case PieceType.ADD_REPORT:
                case PieceType.SET_MEMBERS_IN_GROUP:
                case PieceType.SECTION:
                case PieceType.ADD_TO_LIST:
                case PieceType.REMOVE_FROM_LIST:
                case PieceType.ADD_TO_TABLE:
                case PieceType.NEW_DATE:
                case PieceType.RESTRICT_NAVIGATION:
                case PieceType.ARCHIVE:
                    newPieceData.nextPiece = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_INNER_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.FOR:
                case PieceType.GROUP:
                case PieceType.GROUP_FOR_LIST:
                case PieceType.QUESTION:
                case PieceType.GROUPED_QUESTION:
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                case PieceType.SECTION:
                    newPieceData.innerPiece = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_CONDITION_PIECES:
            newPieceData = {
                ...state.byId[action.pieceId],
            };

            switch (newPieceData.type) {
                case PieceType.SPLIT:
                    newPieceData.ifPieceData = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            };
        
        case SET_CONDITION_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SPLIT:
                    newPieceData.ifPieceData = newPieceData.ifPieceData ? newPieceData.ifPieceData.map((ifPiece, index) => {
                        if (index === action.ifIndex) {
                            return {
                                ...ifPiece,
                                conditionPiece: action.value,
                            };
                        } else {
                            return ifPiece;
                        }
                    }) : [];
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            };
        
        case SET_CONDITION_PIECE_POSITION:

                newPieceData = {
                    ...state.byId[action.pieceId]
                };

                switch (newPieceData.type) {
                    case PieceType.SPLIT:
                        newPieceData.ifPieceData = newPieceData.ifPieceData ? newPieceData.ifPieceData.map((ifPiece, index) => {
                            if (index === action.ifIndex) {
                                return {
                                    ...ifPiece,
                                    x: action.x,
                                    y: action.y,
                                };
                            } else {
                                return ifPiece;
                            }
                        }) : [];
                        newPieceData.lastUpdatedTime = action.updateTime;
                        break;
                    default:
                        break;
                }
    
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.pieceId]: {
                            ...newPieceData
                        }
                    },
                    updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
                }
        
        case SET_CONDITION_NEXT_PIECE:

                newPieceData = {
                    ...state.byId[action.pieceId]
                };

                switch (newPieceData.type) {
                    case PieceType.SPLIT:
                        newPieceData.ifPieceData = newPieceData.ifPieceData ? newPieceData.ifPieceData.map((ifPiece, index) => {
                            if (index === action.ifIndex) {
                                return {
                                    ...ifPiece,
                                    nextPiece: action.value,
                                };
                            } else {
                                return {
                                    ...ifPiece
                                };
                            }
                        }) : [];
                        newPieceData.lastUpdatedTime = action.updateTime;
                        break;
                    default:
                        break;
                }
    
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.pieceId]: {
                            ...newPieceData
                        }
                    },
                    updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
                }
        
        case SET_LOOP_VARIABLE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.FOR:
                case PieceType.GROUP_FOR_LIST:
                    newPieceData.loopVariable = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_ITERABLE_VARIABLE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.FOR:
                case PieceType.GROUP_FOR_LIST:
                case PieceType.SET_MEMBERS_IN_GROUP:
                    newPieceData.iterableVariable = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_LOCATION_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_MEMBER:
                case PieceType.ADD_GROUP:
                    newPieceData.locationPiece = action.location;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_OPERAND:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.NOT:
                case PieceType.VARIABLE_TO_BOOLEAN:
                case PieceType.BOOLEAN_TO_VARIABLE:
                case PieceType.PICK_FIRST_ELEMENT:
                case PieceType.PICK_LAST_ELEMENT:
                case PieceType.IS_DEFINED:
                case PieceType.LENGTH:
                case PieceType.GET_DATE:
                case PieceType.GET_DAY:
                case PieceType.GET_MONTH:
                case PieceType.GET_READABLE_MONTH:
                case PieceType.GET_YEAR:
                    newPieceData.operand = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_LEFT_OPERAND:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD:
                case PieceType.SUBTRACT:
                case PieceType.MULTIPLY:
                case PieceType.DIVIDE:
                case PieceType.EXPONENT:
                case PieceType.LESSER_THAN:
                case PieceType.GREATER_THAN:
                case PieceType.EQUAL_TO:
                case PieceType.NOT_EQUAL_TO:
                case PieceType.IN:
                case PieceType.AND:
                case PieceType.OR:
                case PieceType.PICK_FIRST_N_ELEMENTS:
                case PieceType.PICK_LAST_N_ELEMENTS:
                case PieceType.PICK_NTH_ELEMENT:
                case PieceType.ADD_MONTHS:
                case PieceType.ADD_YEARS:
                case PieceType.SUBTRACT_MONTHS:
                case PieceType.SUBTRACT_YEARS:
                    newPieceData.leftOperand = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_RIGHT_OPERAND:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD:
                case PieceType.SUBTRACT:
                case PieceType.MULTIPLY:
                case PieceType.DIVIDE:
                case PieceType.EXPONENT:
                case PieceType.LESSER_THAN:
                case PieceType.GREATER_THAN:
                case PieceType.EQUAL_TO:
                case PieceType.NOT_EQUAL_TO:
                case PieceType.IN:
                case PieceType.AND:
                case PieceType.OR:
                case PieceType.PICK_FIRST_N_ELEMENTS:
                case PieceType.PICK_LAST_N_ELEMENTS:
                case PieceType.PICK_NTH_ELEMENT:
                case PieceType.ADD_MONTHS:
                case PieceType.ADD_YEARS:
                case PieceType.SUBTRACT_MONTHS:
                case PieceType.SUBTRACT_YEARS:
                    newPieceData.rightOperand = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_LIST:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_TO_LIST:
                case PieceType.REMOVE_FROM_LIST:
                case PieceType.ADD_TO_TABLE:
                    newPieceData.listVariable = action.variable;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_NEW_DATE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.NEW_DATE:
                    newPieceData.variablePiece = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_PIECE_FOR_LIST:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_TO_LIST:
                case PieceType.REMOVE_FROM_LIST:
                case PieceType.ADD_TO_TABLE:
                    newPieceData.dataToSet = action.dataToSet;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_QUESTION_DATA:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.QUESTION:
                case PieceType.GROUPED_QUESTION:
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                    newPieceData.question = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_QUESTION_REQUIRED_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.QUESTION:
                case PieceType.GROUPED_QUESTION:
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                    newPieceData.isRequiredPiece = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_QUESTION_DISABLED_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.QUESTION:
                case PieceType.GROUPED_QUESTION:
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                    newPieceData.isDisabledPiece = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_QUESTION_DEFAULT_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.QUESTION:
                case PieceType.GROUPED_QUESTION:
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                    newPieceData.default = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_CUSTOM_FIELD:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.GET:
                case PieceType.GET_VALUE:
                case PieceType.STORE:
                case PieceType.QUESTION:
                case PieceType.GROUPED_QUESTION:
                    newPieceData.customFieldId = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_SET:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SET_VARIABLE:
                    newPieceData.variableToSet = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_ADD_MEMBER:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_MEMBER:
                    newPieceData.variable = action.variable;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_ADD_GROUP:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_GROUP:
                    newPieceData.variable = action.variable;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_ADD_WORKFLOW:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_WORKFLOW:
                    newPieceData.variable = action.variable;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_ADD_REPORT:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_REPORT:
                    newPieceData.variable = action.variable;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_QUESTION_CHOICE_LIST:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                    newPieceData.variablePiece = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_HEADING:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SECTION:
                    newPieceData.heading = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_COLUMNS:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SECTION:
                    newPieceData.columns = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_STRUCTURE_PROJECT:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.STRUCTURE:
                    newPieceData.project = action.project;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_STRUCTURE_LEVEL:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.STRUCTURE:
                    newPieceData.level = action.level;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_STRUCTURE_ROLE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.STRUCTURE:
                    newPieceData.role = action.role;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_MEMBERS_TYPE_IN_GROUP:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SET_MEMBERS_IN_GROUP:
                    newPieceData.memberType = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_QUESTION_CHOICE_VARIABLE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.CHOOSE:
                case PieceType.GROUPED_CHOOSE:
                    newPieceData.choiceVariable = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_MEMBER_VARIABLE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.QUESTION:
                case PieceType.GROUPED_QUESTION:
                case PieceType.GROUPED_ANSWER:
                case PieceType.CUSTOM_FIELD:
                case PieceType.GET:
                case PieceType.GET_VALUE:
                case PieceType.STORE:
                    newPieceData.memberVariablePiece = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_DATA_STORE_VALUE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.STORE:
                    newPieceData.dataToStore = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_DATA_SET_VALUE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SET_VARIABLE:
                    newPieceData.dataToSet = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_DATA_COPY_VARIABLE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.GET:
                    newPieceData.variableToCopy = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_RETURN_VALUE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.RETURN:
                    newPieceData.returnValue = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_ERROR:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ERROR:
                    newPieceData.error = action.error;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }
        
        case SET_MESSAGE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.END:
                    newPieceData.message = action.message;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_UPDATE_STATUS_VALUE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.UPDATE_STATUS:
                case PieceType.END:
                    newPieceData.status = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_UPDATE_DUE_DATE_VALUE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.UPDATE_DUE_DATE:
                    newPieceData.dueDate = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_SELECTED_OPTIONS:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SEQUENCE:
                    newPieceData.selectedOptions = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_SHOW:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SHOW:
                case PieceType.GROUPED_SHOW:
                    newPieceData.variableToShow = action.value;
                    newPieceData.entityType = undefined;
                    newPieceData.customFieldIds = undefined;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_PIECE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SET_MEMBERS_IN_GROUP:
                case PieceType.SWITCH_WORKFLOW:
                case PieceType.NEW_DATE:
                case PieceType.ARCHIVE:
                    newPieceData.variablePiece = action.variablePiece;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_VARIABLE_FOR_CUSTOM_FIELD:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.GET:
                case PieceType.GET_VALUE:
                case PieceType.STORE:
                    newPieceData.variablePiece = action.value;
                    newPieceData.entityType = undefined;
                    newPieceData.customFieldId = undefined;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_ENTITY_TYPE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SHOW:
                case PieceType.GROUPED_SHOW:
                    newPieceData.entityType = action.value;
                    newPieceData.customFieldIds = undefined;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                case PieceType.GET:
                case PieceType.GET_VALUE:
                case PieceType.STORE:
                    newPieceData.entityType = action.value;
                    newPieceData.customFieldId = undefined;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                case PieceType.ADD_MEMBER:
                case PieceType.ADD_GROUP:
                    newPieceData.entityType = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_CUSTOM_FIELDS_FOR_SHOW:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.SHOW:
                case PieceType.GROUPED_SHOW:
                    newPieceData.customFieldIds = action.value;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_TRANSFER_ROLES:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.TRANSFER_WORKFLOW:
                    newPieceData.roles = action.roles;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case START_PIECE_DRAG:

            return {
                ...state,
                isDragging: true,
                lastDraggedPiece: action.pieceId,
            }

        case STOP_PIECE_DRAG:
            
            return {
                ...state,
                isDragging: false,
            }

        case SET_TARGET_PIECE:

            return {
                ...state,
                targetPiece: action.pieceId,
            }

        case UPDATE_CUSTOM_FIELD_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.CUSTOM_FIELD,
                        customField: action.customField,
                        customFieldOption: action.customFieldOption,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case UPDATE_ANSWER_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.GROUPED_ANSWER,
                        customField: action.customField,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case UPDATE_STATUS_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.STATUS,
                        statusId: action.statusId,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case UPDATE_VARIABLE_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.VARIABLE,
                        variable: action.variable,
                        nesting: action.nesting,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_YEAR:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.NEW_DATE,
                        yearVariablePiece: action.value,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_MONTH:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.NEW_DATE,
                        monthVariablePiece: action.value,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_DATE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.NEW_DATE,
                        dateVariablePiece: action.value,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_WORKFLOW_TYPE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_WORKFLOW:
                case PieceType.START_WORKFLOW:
                    newPieceData.workflowType = action.workflowType;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_WORKFLOW_STATUS:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.START_WORKFLOW,
                        workflowStatus: action.status,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_WORKFLOW_DUE_DATE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.START_WORKFLOW,
                        workflowDueDateVariable: action.dueDate,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_WORKFLOW_AFFILIATION_VARIABLE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_WORKFLOW:
                case PieceType.START_WORKFLOW:
                    newPieceData.affiliationVariable = action.affiliationVariable;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_WORKFLOW_IS_ASYNC:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...state.byId[action.pieceId],
                        type: PieceType.START_WORKFLOW,
                        isAsync: action.isAsync,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_REPORT_NAME:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_REPORT:
                    newPieceData.name = action.name;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_REPORT_TYPE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_REPORT:
                    newPieceData.reportType = action.reportType;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_REPORT_USER:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_REPORT:
                    newPieceData.user = action.user;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_REPORT_START_DATE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_REPORT:
                    newPieceData.startDate = action.startDate;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case SET_REPORT_END_DATE:

            newPieceData = {
                ...state.byId[action.pieceId]
            };

            switch (newPieceData.type) {
                case PieceType.ADD_REPORT:
                    newPieceData.endDate = action.endDate;
                    newPieceData.lastUpdatedTime = action.updateTime;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceData,
                    }
                },
                updatedIds: !state.createdIds.has(action.pieceId) ? new Set(Array.from(state.updatedIds).concat([action.pieceId])) : state.updatedIds,
            }

        case ADD_PIECE:
            const newPieceType = {
                id: action.pieceId,
                type: action.pieceType,
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceId]: {
                        ...newPieceType,
                        createdTime: action.createdTime,
                        lastUpdatedTime: action.createdTime,
                    } as AllPieceTypes
                },
                allEntries: state.allEntries.concat([action.pieceId]),
                createdIds: new Set(Array.from(state.createdIds).concat([action.pieceId])),
            }

        case ADD_FULL_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.pieceData.id]: action.pieceData
                },
                allEntries: state.allEntries.concat([action.pieceData.id]),
                createdIds: new Set(Array.from(state.createdIds).concat([action.pieceData.id])),
            }

        case DELETE_PIECE:
            const newState = {
                ...state,
                byId: {
                    ...state.byId,
                },
                allEntries: state.allEntries.filter(pieceId => pieceId !== action.pieceId),
                deletedIds: new Set(Array.from(state.updatedIds).concat([action.pieceId])),
                selected: undefined,
                lastDraggedPiece: undefined,
            }

            delete newState.byId[action.pieceId];

            return newState;

        // Flowchart actions

        case UPDATE_FLOWCHART_DATA:
            return {
                ...action.data.pieces,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }
            
        default: return state;
    }
}