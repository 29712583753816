import React, { Component } from 'react';
import styles from './SelectBox.module.scss';

import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import SelectBoxDraggablePiece from './SelectBoxDraggablePiece';


type SelectBoxProps = {
    theme?: 'indigo'|'dark-indigo'|'aqua'|'dark-aqua'|'pink'|'dark-pink'|'camo'|'dark-camo',
    selectionPromptText?: string,
    isRounded?: boolean,
    isBlock?: boolean,

    hasVariableSlot: boolean,
    variablePiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = SelectBoxProps & StateProps;

type SelectBoxState = {
    isShowingOptions: boolean,
}

class ConnectedSelectBox extends Component<Props, SelectBoxState> {
    state = {
        isShowingOptions: false
    };

    static defaultProps = {
        isRounded: false,
        dismissDropDownAfterSelection: true,
        hasVariableSlot: false,
    };

    toggleOptionsVisibility = () => {
        this.setState((prevState, props) => {
            return {
                isShowingOptions: !prevState.isShowingOptions
            };
        });
    }
    
    hideOptionsVisibility = () => {
        this.setState({
            isShowingOptions: false
        });
    }

    render() {

        return (
            <div className={this.props.isBlock ? styles.blockSelectBoxHolder : styles.selectBoxHolder}>
                <SelectBoxDraggablePiece theme={this.props.theme} selectionPromptText={this.props.selectionPromptText} isRounded={this.props.isRounded} isBlock={this.props.isBlock} toggleOptionsVisibility={this.toggleOptionsVisibility} hideOptionsVisibility={this.hideOptionsVisibility} variablePiece={this.props.variablePiece} hasVariableSlot={this.props.hasVariableSlot} />
                {this.state.isShowingOptions && <div className={styles.optionsHolder}>
                    {this.props.children}
                </div>}
            </div>
        )
    }
}

const SelectBox = connect(mapStateToProps)(ConnectedSelectBox);

export default SelectBox;