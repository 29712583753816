import React from 'react';
import styles from './Button.module.scss';

import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {
    isOutline?: boolean,
    isDisabled?: boolean,
    text: string,

    onClick: () => void,
}

const Button: React.FC<OwnProps> = (props) => {
    return <button type="button" className={props.isDisabled ? styles.disabledButton : props.isOutline ? styles.outlineButton : styles.primaryButton} onClick={() => !props.isDisabled && props.onClick()}>{translatePhrase(props.text)}</button>
}

export default Button;