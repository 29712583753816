import { WorkflowTypeState, SELECT_WORKFLOW_TYPE, UN_SELECT_WORKFLOW_TYPE, RE_ORDER_WORKFLOW_TYPES, ADD_WORKFLOW_TYPE, UPDATE_WORKFLOW_TYPE, DELETE_WORKFLOW_TYPE, SELECT_WORKFLOW_TYPE_CUSTOM_FIELD, UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD, ADD_WORKFLOW_TYPE_CUSTOM_FIELD, DELETE_WORKFLOW_TYPE_CUSTOM_FIELD, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD, SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, UPDATE_WORKFLOW_TYPE_START_PIECE, SET_ISOLATED_WORKFLOW_TYPE_PIECE, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE, REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE, REGISTER_WORKFLOW_TYPE_VARIABLE, REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE } from './types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, updateStartPieceForCustomField, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, registerVariableForCustomField } from '../../custom-fields';
import { WorkflowActionTypes } from '../types';
import { reOrderList } from '../../../helpers/utilities';
import { ADD_STATUS, DELETE_STATUS } from './statuses/types';
import { statusesReducer, initialState as workflowStatusInitialState } from './statuses/reducer';

export const initialState: WorkflowTypeState = {  
    byId: {},
    allEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    statuses: workflowStatusInitialState,
    customFields: {
        byId: {},
        allFields: []
    },
    customFieldOptions: {
        byId: {},
        allOptions: []
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),
};

export function workflowTypesReducer(state = initialState, action: WorkflowActionTypes): WorkflowTypeState {
    state = {
        ...state,
        statuses: statusesReducer(state.statuses, action),
    }

    let newState: WorkflowTypeState;

    switch(action.type) {

        // Workflow type actions
        
        case SELECT_WORKFLOW_TYPE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_WORKFLOW_TYPE:
            return {
                ...state,
                selected: undefined,
                statuses: {
                    ...state.statuses,
                    selected: undefined,
                }
            }

        case RE_ORDER_WORKFLOW_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_WORKFLOW_TYPE:
            return  {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        isolatedPieces: [],
                        workflows: [],
                        statuses: [],
                        customFields: [],
                        pieces: [],
                        variables: [],
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
            }
        
        case DELETE_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(entry => entry !== action.id),
                selected: undefined,
            }
        
        case UPDATE_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload,
                    },
                },
            }

        case UPDATE_WORKFLOW_TYPE_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        startPiece: action.payload,
                    }
                }
            }

        case SET_ISOLATED_WORKFLOW_TYPE_PIECE:
            const newIsolatedPieces = state.byId[action.workflowTypeId].isolatedPieces.slice(0);
            const isolatedPieceIndex = newIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);

            if (isolatedPieceIndex < 0) {
                newIsolatedPieces.push(action.payload);
            } else {
                newIsolatedPieces[isolatedPieceIndex] = action.payload;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        isolatedPieces: newIsolatedPieces,
                    }
                }
            }

        case REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        isolatedPieces: state.byId[action.workflowTypeId].isolatedPieces.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                }
            }

        case REGISTER_WORKFLOW_TYPE_VARIABLE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        variables: state.byId[action.workflowTypeId].variables.concat([action.variableId]),
                    }
                }
            }
        
        case SELECT_WORKFLOW_TYPE_CUSTOM_FIELD:
            return selectCustomField<WorkflowTypeState>(state, action.id);
        
        case UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD:
            return unSelectCustomField<WorkflowTypeState>(state);

        case ADD_WORKFLOW_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        customFields: state.byId[action.workflowTypeId].customFields.concat([action.payload.id]),
                    }
                }
            }
            return addCustomField<WorkflowTypeState>(newState, action.payload, action.workflowTypeId);
        
        case DELETE_WORKFLOW_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        customFields: state.byId[action.workflowTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                }
            }
            return deleteCustomField<WorkflowTypeState>(newState, action.id);
        
        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD:
            return updateCustomField<WorkflowTypeState>(state, action.payload);


        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<WorkflowTypeState>(state, action.customFieldId, action.payload);
    
        case SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<WorkflowTypeState>(state, action.customFieldId, action.payload);

        case REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<WorkflowTypeState>(state, action.customFieldId, action.pieceId);

        case REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<WorkflowTypeState>(state, action.customFieldId, action.variableId);
            
        
        case SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<WorkflowTypeState>(state, action.id);
        
        case UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<WorkflowTypeState>(state);

        case RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<WorkflowTypeState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<WorkflowTypeState>(state, action.payload, action.parentId);
        
        case DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<WorkflowTypeState>(state, action.id, action.parentId);

        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<WorkflowTypeState>(state, action.payload);

        
        // Workflow type status actions
        
        case ADD_STATUS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        statuses: state.byId[action.parentId].statuses.concat([action.payload.id]),
                    }
                }
            }

        case DELETE_STATUS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        statuses: state.byId[action.parentId].statuses.filter(statusId => statusId !== action.id),
                    }
                }
            }
        
        default:
            return state;
    }
}