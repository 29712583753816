import React, { Component, ChangeEvent } from 'react';
import styles from './WorkflowsTable.module.scss';
import moment from 'moment';

import WorkflowModify from './WorkflowModify';
import WorkflowFilter from './WorkflowFilter';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/main';

import TooltipList from '../../../widgets/tooltip-list/TooltipList';

import { addWorkflow, updateWorkflow, deleteWorkflow, transferWorkflow, searchWorkflowTable, goToPageWorkflowTable, setPageSizeWorkflowTable, sortWorkflowTable } from '../../../shared/store/workflows/actions';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import Button from '../../../widgets/form/Button';
import { getReadableDataForCustomField, FieldType, CustomFieldValueType } from '../../../shared/store/custom-fields';
import { VariableValueType } from '../../../shared/store/flowchart/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { getAllLocationsVisibleToUser } from '../../../shared/helpers/locations';

const mapStateToProps = (state: ApplicationState) => {

    let visibleUsers: Array<string> = [],
        visibleMembers: Array<string> = [],
        visibleGroups: Array<string> = [],
        visibleWorkflows: Array<string> = [];

    if (isUUID(state.myData.id)) {
        const visibleLocationIds = getAllLocationsVisibleToUser(state.myData.id);
    
        visibleLocationIds.forEach(locationId => {
            const location = state.structure.locations.byId[locationId];
            visibleUsers = visibleUsers.concat(location.users);
            visibleMembers = visibleMembers.concat(location.members);
            visibleGroups = visibleGroups.concat(location.groups);
        });

        const loggedInUser = state.users.byId[state.myData.id];

        for (const workflowTypeId in loggedInUser.workflows) {
            visibleWorkflows = visibleWorkflows.concat(loggedInUser.workflows[workflowTypeId]);
        }

        visibleUsers.forEach(userId => {
            const user = state.users.byId[userId];

            for (const workflowTypeId in user.workflows) {
                visibleWorkflows = visibleWorkflows.concat(user.workflows[workflowTypeId]);
            }
        });

        visibleMembers.forEach(memberId => {
            const member = state.members.byId[memberId];

            for (const workflowTypeId in member.workflows) {
                visibleWorkflows = visibleWorkflows.concat(member.workflows[workflowTypeId]);
            }
        });

        visibleGroups.forEach(groupId => {
            const group = state.groups.byId[groupId];

            for (const workflowTypeId in group.workflows) {
                visibleWorkflows = visibleWorkflows.concat(group.workflows[workflowTypeId]);
            }
        });

        visibleWorkflows = Array.from(new Set(visibleWorkflows));
    } else {
        visibleWorkflows = state.workflows.allEntries;
    }

    const allProcessedWorkflows = visibleWorkflows.map(workflowId => state.workflows.byId[workflowId]),
          currentPageNumber = state.workflows.currentPageNumber,
          pageSize = state.workflows.pageSize,
          workflowSearchTerm = state.workflows.searchTerm,
          filters = state.workflows.filters;

    let hasFilters = filters.dues.length > 0 || filters.types.length > 0 || filters.users.length > 0 || filters.statuses.length > 0 || filters.affiliations.length > 0 || Object.keys(filters.customFields).length > 0;

    let filteredWorkflows = !hasFilters ? allProcessedWorkflows : allProcessedWorkflows.filter(workflow => {
        const now = moment().format('YYYY-MM-DDTHH:mm:ss');

        if (filters.dues.length === 1 && !!workflow.dueDate) {
            if (filters.dues[0] === 'due') {
                return workflow.dueDate <= now;
            } else if (filters.dues[0] === 'overdue') {
                return workflow.dueDate > now;
            } else {
                return true;
            }
        }

        if (filters.users.length > 0 && !filters.users.includes(workflow.user)) {
            return false;
        }

        if (filters.types.length > 0 && !filters.types.includes(workflow.type)) {
            return false;
        }

        if (filters.statuses.length > 0 && !filters.statuses.includes(workflow.status)) {
            return false;
        }

        if (filters.affiliations.length > 0 && !filters.affiliations.includes(workflow.affiliatedEntity)) {
            return false;
        }

        for (const customFieldId in filters.customFields) {
            if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                let customField = state.workflows.types.customFields.byId[customFieldId];
                const customFieldValue = workflow.history[workflow.historyIndex].customFields[customFieldId];

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (typeof customFieldValue !== 'string') {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                        return false;
                    }
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    if (!Array.isArray(customFieldValue)) {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                        return false;
                    }
                }

            }
        }

        return true;
    });

    if (workflowSearchTerm) {
        filteredWorkflows = filteredWorkflows.filter(workflow => {
            const workflowType = state.workflows.types.byId[workflow.type];
            const workflowStatus = state.workflows.types.statuses.byId[workflow.status];

            let show = false;
            const typeName = workflowType.name;
            const statusName = workflowStatus.name;
            let affiliationName = '';

            const user = state.users.byId[workflow.user];
            const userNameField = state.users.customFields.byId[state.users.nameFieldId];
            const userName = getReadableDataForCustomField(user.customFields[userNameField.id], userNameField, user.id, 'user');

            if (workflowType.affiliation === 'member') {
                const member = state.members.byId[workflow.affiliatedEntity];
                const memberType = state.members.types.byId[member.type];
                const nameField = state.members.types.customFields.byId[memberType.nameFieldId];

                affiliationName = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member');
            } else if (workflowType.affiliation === 'group') {
                const group = state.groups.byId[workflow.affiliatedEntity];
                const groupType = state.groups.types.byId[group.type];
                const nameField = state.groups.types.customFields.byId[groupType.nameFieldId];

                affiliationName = getReadableDataForCustomField(group.customFields[nameField.id], nameField, group.id, 'group');
            }

            if (userName.toLocaleLowerCase().includes(workflowSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (typeName.toLocaleLowerCase().includes(workflowSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (statusName.toLocaleLowerCase().includes(workflowSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (!!affiliationName && affiliationName.toLocaleLowerCase().includes(workflowSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (filters.types.length === 1) {
                
                const typeFields = workflowType.customFields.filter(fieldId => {
                    const customField = state.workflows.types.customFields.byId[fieldId];

                    if (customField.affiliation === 'member') {
                        return false;
                    }
    
                    return customField.isInTable && (customField.type === FieldType.TEXT || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
                });
    
                for (const customFieldId of typeFields) {
                    const customField = state.workflows.types.customFields.byId[customFieldId];

                    // The member affiliated fields have already been filtered out above, so the workflow custom field value is guaranteed to be a normal cust field value type
                    const fieldValue = getReadableDataForCustomField(workflow.history[workflow.historyIndex].customFields[customFieldId] as CustomFieldValueType, customField, workflow.id, 'workflow');
    
                    if (fieldValue.toLocaleLowerCase().includes(workflowSearchTerm.toLocaleLowerCase())) {
                        show = true;
                    }
                }
            }

            return show;
        });
    }
    
    return {
        workflowsList: filteredWorkflows.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize),
        totalNoOfWorkflows: filteredWorkflows.length,
        pageSize: pageSize,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(filteredWorkflows.length / pageSize),
        
        searchTerm: workflowSearchTerm,
        workflowFilters: state.workflows.filters,
        workflowSort: state.workflows.sort,

        workflowTypesData: state.workflows.types,
        workflowStatusesData: state.workflows.types.statuses,
        customFieldsData: state.workflows.types.customFields,
        rolesData: state.structure.roles,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        myId: state.myData.id,
        
        areFiltersExpanded: false,

        applicationState: state,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchWorkflowTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageWorkflowTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeWorkflowTable(size)),
        sort: (column: string, order: 'ASC'|'DESC') => dispatch(sortWorkflowTable(column, order)),
        
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
        deleteWorkflow: (id: string) => dispatch(deleteWorkflow(id)),
        transferWorkflow: (id: string, user: string) => dispatch(transferWorkflow(id, user)),
        updateWorkflow: (payload: IUpdateableWorkflowData) => dispatch(updateWorkflow(payload)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    isShowingFilterForm: boolean,
    isShowingTransferList: boolean,
}

class ConnectedWorkflowsTable extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingFilterForm: false,
        isShowingTransferList: false,
    }

    static defaultProps = {
        isReadOnly: false,
    }

    showTransferList = () => {
        this.setState({
            isShowingTransferList: true,
        });
    }

    hideTransferList = () => {
        this.setState({
            isShowingTransferList: false,
        });
    }
    
    showAddForm = () => {
        this.setState({
            isShowingAddForm: true,
        });
    }
    
    showFilterForm = () => {
        this.setState({
            isShowingFilterForm: true,
        });
    }

    showModifyForm = () => {
        this.setState({
            isShowingModifyForm: true,
        });
    }
    
    hideWorkflowForm = () => {
        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: false,
        });
    }
    
    hideFilterForm = () => {
        this.setState({
            isShowingFilterForm: false,
        });
    }
    
    addWorkflow = (workflowData: IUpdateableWorkflowData) => {
        this.props.addWorkflow(workflowData);
        this.setState({
            isShowingAddForm: false
        });
    }
    
    updateWorkflow = (workflowData: IUpdateableWorkflowData) => {
        this.props.updateWorkflow(workflowData);
        this.setState({
            isShowingModifyForm: false
        });
    }
    
    deleteWorkflow = (id: string) => {
        this.props.deleteWorkflow(id);
    }

    transferWorkflow = (workflowId: string, userId: string) => {
        this.props.transferWorkflow(workflowId, userId);
        this.setState({
            isShowingTransferList: false
        });
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.searchTable(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.workflowSort.column !== column;
        const sortOrder = !isNewColumn && this.props.workflowSort.order === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }
    
    render() {

        const MAX_NO_OF_WORKFLOWS = 500;

        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: 'Type',
            isSortable: false,
            width: 170,
        }, {
            name: 'Status',
            isSortable: false,
            width: 200,
        }, {
            name: 'Due Date',
            isSortable: false,
            width: 200,
        }, {
            name: 'User',
            isSortable: false,
            width: 130,
        }, {
            name: 'Affiliated with',
            isSortable: false,
            width: 200,
        }];

        if (this.props.workflowFilters.types.length === 1) {
            const workflowType = this.props.workflowTypesData.byId[this.props.workflowFilters.types[0]];
            workflowType.customFields.forEach(customFieldId => {
                const customField = this.props.customFieldsData.byId[customFieldId];
                const width = customField.type === FieldType.TEXT ? 200 : 150;
    
                if (customField.isInTable) {
                    headings.push({
                        name: customField.name,
                        isSortable: false,
                        width: width,
                    });
                }
            });
        }

        headings.push({
            name: 'Action',
            isSortable: false,
            width: 200,
        });

        const entries: Array<TableRow> = this.props.workflowsList.map<TableRow>((workflow, index) => {
            const workflowType = this.props.workflowTypesData.byId[workflow.type];
            const workflowStatus = this.props.workflowStatusesData.byId[workflow.status];
            let workflowAffiliatedName = '-';
            let userName: VariableValueType = '-';

            if (isUUID(workflow.user)) {
                const user = this.props.usersData.byId[workflow.user];
                userName = user.customFields[this.props.usersData.nameFieldId];

                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, nameField, user.id, 'user');
            }

            if (workflowType.affiliation === 'member') {
                const member = this.props.membersData.byId[workflow.affiliatedEntity];
                const memberType = this.props.membersData.types.byId[member.type];
                let memberName = member.customFields[memberType.nameFieldId];

                const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

                memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

                workflowAffiliatedName = memberName;
            } else if (workflowType.affiliation === 'group') {
                const group = this.props.groupsData.byId[workflow.affiliatedEntity];
                const groupType = this.props.groupsData.types.byId[group.type];
                let groupName = group.customFields[groupType.nameFieldId];

                const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];

                groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

                workflowAffiliatedName = groupName;
            } else if (workflowType.affiliation === 'none') {
                workflowAffiliatedName = '-';
            }

            const cells: Array<string|number|JSX.Element> = [
                (this.props.pageNumber - 1) * this.props.pageSize + index + 1, // Sl. no
                workflowType.name, // Type
                workflowStatus.name, // Status
                workflow.dueDate ? moment(workflow.dueDate).format('DD MMM YYYY') : '-', // Due Date
                userName, // User
                workflowAffiliatedName, // Affiliated with

            ];

            if (this.props.workflowFilters.types.length === 1) {
                const workflowType = this.props.workflowTypesData.byId[this.props.workflowFilters.types[0]];
                workflowType.customFields.forEach(customFieldId => {
                    const customField = this.props.customFieldsData.byId[customFieldId];

                    if (!customField.isInTable) {
                        return;
                    }
    
                    let customFieldValue = workflow.history[workflow.historyIndex].customFields[customFieldId];
    
                    let cellValue = getReadableDataForCustomField(customFieldValue as CustomFieldValueType, customField, workflow.id, 'workflow');
    
                    cells.push(cellValue);
                });
            }

            let buttonText = 'View Data';

            if ((!isUUID(this.props.myId) || workflow.user === this.props.myId) && !workflowStatus.isTerminal) {
                buttonText = workflow.history[workflow.historyIndex].lastComputedPiece ? 'Continue' : 'Start';
            }

            if (!workflowStatus.isTerminal) {
                const usersList = this.props.usersData.allEntries
                .filter(userId => userId !== workflow.user)
                .map(userId => {
                    const user = this.props.usersData.byId[userId];
                    const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                    const role = this.props.rolesData.byId[user.role];
                    const userName = getReadableDataForCustomField(user.customFields[nameField.id], nameField, user.id, 'user');

                    return <section className={styles.userEntry} onClick={this.transferWorkflow.bind(this, workflow.id, user.id)}>
                        {userName} ({role.name})
                    </section>
                });

                cells.push(<div>
                    <Link to={`/workflow/${workflow.id}/execute`}><Button isOutline onClick={() => {}} text={buttonText} /></Link>
                    <Button isOutline onClick={this.showTransferList} text="Transfer" />
                    {this.state.isShowingTransferList && <div className={styles.usersHolder}>
                        <TooltipList handleClickOutside={this.hideTransferList} listElements={usersList} />
                    </div>}
                </div>);
            } else {
                cells.push(<Link to={`/workflow/${workflow.id}/execute`}><Button isOutline onClick={() => {}} text={buttonText} /></Link>);
            }

            const workflowEditForm = <WorkflowModify workflowId={workflow.id} submit={this.updateWorkflow} cancel={this.hideWorkflowForm} isReadOnly />;

            return {
                id: workflow.id,
                entries: cells,
                editForm: workflowEditForm,
                shareText: `Type: ${workflowType.name}\nStatus: ${workflowStatus.name}\nAffiliated with: ${workflowAffiliatedName}`,
                showViewInsteadOfEdit: true,
            }
        });

        return (
            <TableWithMeta 
                entityType="Workflow"
                headings={headings} 
                entries={entries} 
                
                sortedColumn={this.props.workflowSort.column} 
                sortType={this.props.workflowSort.order} 
                isReadOnly={this.props.isReadOnly}
                areFiltersExpanded={this.props.areFiltersExpanded}

                totalPages={this.props.totalPages}
                pageNumber={this.props.pageNumber}
                searchTerm={this.props.searchTerm}
                isAddAllowed={this.props.totalNoOfWorkflows < MAX_NO_OF_WORKFLOWS}
                isShowingAddForm={this.state.isShowingAddForm} 
                isShowingModifyForm={this.state.isShowingModifyForm}
                addForm={<WorkflowModify submit={this.addWorkflow} cancel={this.hideWorkflowForm} />}

                isShowingFilterForm={this.state.isShowingFilterForm}
                showFilterForm={this.showFilterForm}
                filterForm={<WorkflowFilter closeFilter={this.hideFilterForm} />}

                onSort={this.handleSort} 
                onDelete={this.props.deleteWorkflow} 
                showAddForm={this.showAddForm}
                showModifyForm={this.showModifyForm}  

                search={this.props.searchTable}
                changePageSize={this.props.changePageSize}
                goToPage={this.props.goToPage}
                sort={this.props.sort}
            />
        );
    }
}

const WorkflowsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowsTable);

export default WorkflowsTable;