import React, { FC } from 'react';
import UnaryOperator, { UnaryOperatorProps } from './UnaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type GetReadableMonthOperatorProps = Omit<Omit<Omit<UnaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'isOperatorBoolean'>;

const GetReadableMonthOperator: FC<GetReadableMonthOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <UnaryOperator operatorSymbol="Get readable month" {...props} />
    )
}

export default GetReadableMonthOperator;