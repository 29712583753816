import React, { Component } from 'react';
import styles from './TriToggle.module.scss';

export type TriToggleOptions = 'positive'|'middle'|'negative';

type OwnProps = {
    toggleId: string,
    toggleState: TriToggleOptions,

    onToggle: (id: string, state: TriToggleOptions) => void;
}

type OwnState = {
    leftToRight: boolean,
}

class Toggle extends Component<OwnProps, OwnState> {
    state = {
        leftToRight: true,
    };

    handleToggleClick = () => {
        if (this.props.toggleState === 'positive') {
            this.setState({
                leftToRight: false,
            });
            this.props.onToggle(this.props.toggleId, 'middle');
        } else if (this.props.toggleState === 'negative') {
            this.setState({
                leftToRight: true,
            });
            this.props.onToggle(this.props.toggleId, 'middle');
        } else {
            if (this.state.leftToRight) {
                this.props.onToggle(this.props.toggleId, 'positive');
            } else {
                this.props.onToggle(this.props.toggleId, 'negative');
            }
        }
    }

    render() {
        let toggleClass: string;

        switch (this.props.toggleState) {
            case 'positive':
                toggleClass = styles.positive;
                break;
            case 'middle':
                toggleClass = styles.middle;
                break;
            case 'negative':
                toggleClass = styles.negative;
                break;
            default:
                throw new Error('It must be one of the above')
        }
        
        return (
            <section className={toggleClass} onClick={this.handleToggleClick}>
                <div className={styles.slider}></div>
            </section>
        );

    }
}

export default Toggle;