import { StructureActionTypes, UPDATE_STRUCTURE_DATA } from '../types'
import { SELECT_PROJECT, UN_SELECT_PROJECT, ADD_PROJECT, DELETE_PROJECT, UPDATE_PROJECT, RE_ORDER_PROJECTS, ADD_LEVEL_TO_PROJECT, REMOVE_LEVEL_FROM_PROJECT , ADD_LOCATION_TO_PROJECT, REMOVE_LOCATION_FROM_PROJECT, ProjectState, IProject, UPDATE_SPECIAL_WORKFLOW_TYPE} from './types';
import { RE_ORDER_LEVELS } from '../level/types';
import { reOrderList } from '../../../helpers/utilities';
import { addEntity, updateEntity, deleteEntity } from '../../normalized-model';

const initialState: ProjectState = {  
    byId:{},
    allEntries:[],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function projectsReducer(state = initialState, action: StructureActionTypes) {
    switch(action.type) {

        case SELECT_PROJECT:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_PROJECT:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_PROJECTS:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_PROJECT:
            return addEntity<ProjectState, IProject>(state, action.payload);
        
        case UPDATE_PROJECT:
            return updateEntity<ProjectState, IProject>(state, action.payload);
        
        case DELETE_PROJECT:
            state = deleteEntity<ProjectState, IProject>(state, action.id);

            return {
                ...state,
                selected: undefined,
            };

        case ADD_LEVEL_TO_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        children: state.byId[action.projectId].children.concat([action.levelId]),
                    }
                }
            }

        case REMOVE_LEVEL_FROM_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        children: state.byId[action.projectId].children.filter(levelId => levelId !== action.levelId),
                    }
                }
            }

        case ADD_LOCATION_TO_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        locations: state.byId[action.projectId].locations.concat([action.locationId]),
                    }
                }
            }

        case REMOVE_LOCATION_FROM_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        locations: state.byId[action.projectId].locations.filter(locationId => locationId !== action.locationId),
                    }
                }
            }

        case UPDATE_SPECIAL_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        [action.specialType]: action.workflowTypeId,
                    }
                }
            }
        

        // LEVEL ACTIONS

        case RE_ORDER_LEVELS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        children: reOrderList(state.byId[action.parentId].children, action.sourceIndex, action.destinationIndex),
                    }
                }
            }

        // Structure actions

        case UPDATE_STRUCTURE_DATA:
            return {
                ...action.data.projects,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }
        

        default:
            return state
    }
}