import React from 'react';
import styles from './Structure.module.scss';
import { default as tabStyles } from '../../widgets/tabs/Tabs.module.scss';
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import { ApplicationState } from '../../shared/store/main';

import { Permissions } from '../../shared/store/permissions/types';

import Tabs from '../../widgets/tabs/Tabs';
import Hierarchy from './hierarchy/Hierarchy';
import Locations from './locations/Locations';
import PermissionsPage from './permissions/Permissions';

import { translatePhrase } from '../../shared/helpers/translation';

import { connect } from 'react-redux';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;
    const canViewLocations = state.permissions.myPermissions.general.Locations === Permissions.WRITE || state.permissions.myPermissions.general.Locations === Permissions.READ;
    
    return {
        canGoToHierarchy: canViewHierarchy,
        canGoToLocations: canViewLocations,
        myId: state.myData.id,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedStructure: React.FC<Props> = (props) => {
    
    if (!(props.canGoToHierarchy || props.canGoToLocations)) {
        return <Redirect to="/dashboard" />;
    }
    
    return (
        <section className={styles.FocusSpace}>
            <Tabs>
                {props.canGoToHierarchy && <NavLink to="/structure/hierarchy" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Hierarchy')} </NavLink>}
                {props.canGoToLocations && <NavLink to="/structure/locations" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Locations')} </NavLink>}
                {props.canGoToHierarchy && <NavLink to="/structure/permissions" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Permissions')} </NavLink>}
            </Tabs>
            
            <Switch>
                <Route path="/structure/hierarchy" component={Hierarchy} />
                <Route path="/structure/locations" component={Locations} />
                <Route path="/structure/permissions" component={PermissionsPage} />
                <Route render={() => <Redirect to={props.canGoToHierarchy ? '/structure/hierarchy' : '/structure/locations'} />}/>
            </Switch>
        </section>
    );
};

const Structure = connect(mapStateToProps)(ConnectedStructure);

export default Structure;