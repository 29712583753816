import React, { Component, ChangeEvent } from 'react';
import styles from './PageHeader.module.scss';
import { Link } from "react-router-dom";
import { RouteComponentProps, withRouter } from 'react-router';

import { ApplicationState } from '../../shared/store/main';
import { connect } from 'react-redux';

import { translatePhrase } from '../../shared/helpers/translation';

import { ReactComponent as ProfileIcon } from '../../assets/profile.svg';
import { ReactComponent as LanguageIcon } from '../../assets/languages.svg';
import logoutIcon from '../../assets/logout.svg';
import searchIcon from '../../assets/search.svg';
import { isUUID } from '../../shared/helpers/utilities';
import Button from '../../widgets/form/Button';
import { Dispatch } from 'redux';
import { startDataPush } from '../../shared/store/my-data/actions';

import { selectMemberType } from '../../shared/store/members/types/actions';
import { selectGroupType } from '../../shared/store/groups/types/actions';
import { selectWorkflowType } from '../../shared/store/workflows/types/actions';
import { getReadableDataForCustomField } from '../../shared/store/custom-fields';


type OwnProps = {
};

type OwnState = {
    showingDropdown: boolean,
    searchTerm: string,
    searchedPages: Array<JSX.Element>,
    searchedUsers: Array<JSX.Element>,
    searchedMemberTypes: Array<JSX.Element>,
    searchedGroupTypes: Array<JSX.Element>,
    searchedWorkflowTypes: Array<JSX.Element>,

    searchedMembers: Array<JSX.Element>,
    searchedGroups: Array<JSX.Element>,

    saveText: string,
}

const mapStateToProps = (state: ApplicationState) => {
    const userName = '';
    return {
        userId: state.myData.id ? isUUID(state.myData.id) ? state.myData.id : 'Super User' : '',
        representation: state.myData.id ? isUUID(state.myData.id) ? userName.split(' ').slice(0, 2).map(word => (word[0] || '').toUpperCase()).join('') : 'SU' : '',
        isSaving: state.myData.isPushingData,

        applicationState: state,
        usersData: state.users,
    }
    // const user = state.present.usersList.find(user => user.id === state.present.myId);
    
    // return {
    //     name: user ? user.name : 'Super User',
    //     representation : user ? user.name.split(' ').map(word => word[0]).join('') : 'SU',
    //     todosList: state.present.todosList.filter(card => !card.archived),
    //     workflowsList: state.present.workflowsList.filter(card => !card.archived),
    //     questionsList: state.present.questionsList.filter(card => !card.archived),
    //     usersList: state.present.usersList.filter(card => !card.archived),
    //     membersList: state.present.membersList.filter(card => !card.archived),
        
    //     readProfile: state.present.myPermissions.OrganizationProfile === Permissions.READ || state.present.myPermissions.OrganizationProfile === Permissions.WRITE,
    //     readLanguages: state.present.myPermissions.Languages === Permissions.READ || state.present.myPermissions.Languages === Permissions.WRITE,
    // }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        save: () => dispatch(startDataPush()),

        selectMemberType: (id: string) => dispatch(selectMemberType(id)),
        selectGroupType: (id: string) => dispatch(selectGroupType(id)),
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

class ConnectedPageHeader extends Component<Props, OwnState> {

    state: OwnState = {
        showingDropdown: false,
        searchTerm: '',
        searchedPages: [],
        searchedMemberTypes: [],
        searchedGroupTypes: [],
        searchedWorkflowTypes: [],
        searchedUsers: [],
        searchedMembers: [],
        searchedGroups: [],

        saveText: 'Save',
    };

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (props.isSaving && state.saveText === 'Save') {
            return {
                saveText: 'Saving...',
            };
        } else if (!props.isSaving && state.saveText === 'Saving...') {
            return {
                saveText: 'Save',
            }
        }

        return null;
    }

    selectPage = (pageLink: string) => {
        this.clearSearch();
        this.props.history.push(pageLink);
    }

    selectMemberType = (memberTypeId: string) => {
        this.props.selectMemberType(memberTypeId);
        this.clearSearch();
        this.props.history.push('/members/configuration');
    }

    selectGroupType = (groupTypeId: string) => {
        this.props.selectGroupType(groupTypeId);
        this.clearSearch();
        this.props.history.push('/groups/configuration');
    }

    selectWorkflowType = (workflowTypeId: string) => {
        this.props.selectWorkflowType(workflowTypeId);
        this.clearSearch();
        this.props.history.push('/workflows/configuration');
    }

    generateResults = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            this.clearSearch();
            return;
        }
        
        const searchTerm = e.target.value.toLocaleLowerCase().trim();
        
        const pageLinks = [{
            name: 'Hierarchy',
            link: '/structure/hierarchy',
        }, {
            name: 'Locations',
            link: '/structure/locations',
        }, {
            name: 'Permissions',
            link: '/structure/permissions',
        }, {
            name: 'User List',
            link: '/users/list',
        }, {
            name: 'User Configuration',
            link: '/users/configuration',
        }, {
            name: 'Member List',
            link: '/members/list',
        }, {
            name: 'Member Configuration',
            link: '/members/configuration',
        }, {
            name: 'Group List',
            link: '/groups/list',
        }, {
            name: 'Group Configuration',
            link: '/groups/configuration',
        }, {
            name: 'Workflow List',
            link: '/workflows/list',
        }, {
            name: 'Workflow Configuration',
            link: '/workflows/configuration',
        }, {
            name: 'Chat',
            link: '/chat',
        }];

        const searchedPages: Array<JSX.Element> = pageLinks
        .filter(page => page.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        .map(page => {
            const startIndex = page.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={page.name} className={styles.namedResult} onClick={this.selectPage.bind({}, page.link)}><span>{page.name.substring(0, startIndex)}</span> <em>{page.name.substring(startIndex, endIndex)}</em> <span>{page.name.substring(endIndex)}</span></section>
        });

        const searchedMemberTypes: Array<JSX.Element> = this.props.applicationState.members.types.allEntries
        .map(memberTypeId => this.props.applicationState.members.types.byId[memberTypeId])
        .filter(memberType => memberType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        .map(memberType => {
            const startIndex = memberType.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={memberType.id} className={styles.namedResult} onClick={this.selectMemberType.bind(this, memberType.id)}><span>{memberType.name.substring(0, startIndex)}</span> <em>{memberType.name.substring(startIndex, endIndex)}</em> <span>{memberType.name.substring(endIndex)}</span></section>
        });

        const searchedGroupTypes: Array<JSX.Element> = this.props.applicationState.groups.types.allEntries
        .map(groupTypeId => this.props.applicationState.groups.types.byId[groupTypeId])
        .filter(groupType => groupType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        .map(groupType => {
            const startIndex = groupType.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={groupType.id} className={styles.namedResult} onClick={this.selectGroupType.bind(this, groupType.id)}><span>{groupType.name.substring(0, startIndex)}</span> <em>{groupType.name.substring(startIndex, endIndex)}</em> <span>{groupType.name.substring(endIndex)}</span></section>
        });

        const searchedWorkflowTypes: Array<JSX.Element> = this.props.applicationState.workflows.types.allEntries
        .map(workflowTypeId => this.props.applicationState.workflows.types.byId[workflowTypeId])
        .filter(workflowType => workflowType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        .map(workflowType => {
            const startIndex = workflowType.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={workflowType.id} className={styles.namedResult} onClick={this.selectWorkflowType.bind(this, workflowType.id)}><span>{workflowType.name.substring(0, startIndex)}</span> <em>{workflowType.name.substring(startIndex, endIndex)}</em> <span>{workflowType.name.substring(endIndex)}</span></section>
        });

        this.setState({
            searchTerm: e.target.value,
            searchedPages,
            searchedMemberTypes,
            searchedGroupTypes,
            searchedWorkflowTypes,
        });
    }
    
    clearSearch = () => {
        this.setState({
            searchTerm: '',
            searchedPages: [],
            searchedMemberTypes: [],
            searchedGroupTypes: [],
            searchedWorkflowTypes: [],
            searchedUsers: [],
            searchedMembers: [],
            searchedGroups: [],
        });
    }
    
    toggleDropdownVisibility = () => {
        this.setState((prevState, props) => {
            return {showingDropdown: !prevState.showingDropdown};
        });
    }
    
    render() {
        let userName = '';
        let userNameAbbreviation = '';

        if (isUUID(this.props.userId)) {
            const user = this.props.usersData.byId[this.props.userId];
            let rawUserName = user.customFields[this.props.usersData.nameFieldId];;

            const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

            userName = getReadableDataForCustomField(rawUserName, nameField, this.props.userId, 'user');

            userNameAbbreviation = userName.split(' ').map(word => word.length > 0 ? word[0].toUpperCase() : '').join('');
        } else {
            userName = 'Super User';
            userNameAbbreviation = 'SU';
        }
        return (
            <section className={styles.PageHeader}>
                <div className={styles.SearchBar}>
                    <img src={searchIcon} alt="search icon" />
                    <input type="text" placeholder={translatePhrase('Search') + '...'} value={this.state.searchTerm} onChange={this.generateResults} />
                    <div className={styles.results}>
                        {this.state.searchedPages && this.state.searchedPages.length > 0 && 
                        <div>
                            <header>Pages</header>
                            {this.state.searchedPages}
                        </div>}
                        {this.state.searchedUsers && this.state.searchedUsers.length > 0 && 
                        <div>
                            <header>Users</header>
                            {this.state.searchedUsers}
                        </div>}
                        {this.state.searchedMembers && this.state.searchedMembers.length > 0 && 
                        <div>
                            <header>Members</header>
                            {this.state.searchedMembers}
                        </div>}
                        {this.state.searchedGroups && this.state.searchedGroups.length > 0 && 
                        <div>
                            <header>Groups</header>
                            {this.state.searchedGroups}
                        </div>}
                        {this.state.searchedMemberTypes && this.state.searchedMemberTypes.length > 0 && 
                        <div>
                            <header>Member Types</header>
                            {this.state.searchedMemberTypes}
                        </div>}
                        {this.state.searchedGroupTypes && this.state.searchedGroupTypes.length > 0 && 
                        <div>
                            <header>Group Types</header>
                            {this.state.searchedGroupTypes}
                        </div>}
                        {this.state.searchedWorkflowTypes && this.state.searchedWorkflowTypes.length > 0 && 
                        <div>
                            <header>Workflow Types</header>
                            {this.state.searchedWorkflowTypes}
                        </div>}
                    </div>
                </div>
                <div className={styles.ProfileUtilitiesHolder}>
                    <div className={styles.buttonHolder + ' ignore-react-onclickoutside'}><Button text={this.state.saveText} onClick={this.props.save} /></div>
                    <section className={styles.nameHolder} onClick={this.toggleDropdownVisibility}>
                        <div className={styles.name}>{translatePhrase('Hi')}, {userName} </div>
                        <div className={styles.nameRepresentation}> <span> {userNameAbbreviation} </span> </div>
                    </section>
                    <section className={styles.help}><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1EZU54s8pD4weLKrjR93EMpqD8KaP32fuocDj_ZYoLxE/edit?usp=sharing">?</a></section>
                    <picture onClick={e => window.location.assign('/login/')} className={styles.icon + ' ' + styles.logout}>
                        <img src={logoutIcon} alt="logout icon" />
                    </picture>

                    {this.state.showingDropdown && <section className={styles.dropdownHolder} onClick={this.toggleDropdownVisibility}>
                        <Link to="/profile" className={styles.dropdownOption}>
                            <ProfileIcon />
                            <div className={styles.text}>{translatePhrase('Profile')}</div>
                        </Link>
                        <Link to="/languages" className={styles.dropdownOption}>
                            <LanguageIcon />
                            <div className={styles.text}>{translatePhrase('Language')}</div>
                        </Link>
                    </section>}
                </div>
            </section>
        );
    }
}

const PageHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedPageHeader) as any);

export default PageHeader;