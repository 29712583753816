import { ADD_REPORT_TYPE, UPDATE_REPORT_TYPE, DELETE_REPORT_TYPE, IUpdateableReportTypeData, REGISTER_REPORT_TYPE_VARIABLE, UPDATE_REPORT_TYPE_START_PIECE, SET_ISOLATED_REPORT_TYPE_PIECE, REMOVE_ISOLATED_REPORT_TYPE_PIECE, AddReportTypeAction, CellState, ADD_REPORT_TYPE_ROW, ADD_REPORT_TYPE_COLUMN, SELECT_REPORT_TYPE, UN_SELECT_REPORT_TYPE, RE_ORDER_REPORT_TYPES, INewReportTypeData, UpdateReportTypeAction, DeleteReportTypeAction, IAddReportTypeRowAction, IAddReportTypeColumnAction, UpdateReportTypeStartPieceAction, SetIsolatedReportTypePieceAction, RemoveIsolatedReportTypePieceAction, RegisterReportTypeVariableAction } from './types';
import moment from 'moment';
import { PiecePositionState } from '../../flowchart/pieces/types';
import uuid from 'uuid';

export function selectReportType(id: string) {
    return {
        type: SELECT_REPORT_TYPE,
        id,
    }
}

export function unSelectReportType() {
    return {
        type: UN_SELECT_REPORT_TYPE,
    }
}

export function reOrderReportTypes(sourceIndex: number, destinationIndex: number) {
    return {
        type: RE_ORDER_REPORT_TYPES,
        sourceIndex,
        destinationIndex,
    }
}

export function addReportType(payload: INewReportTypeData): AddReportTypeAction {

    const NO_OF_ROWS = 2;
    const NO_OF_COLUMNS = 2;

    const initialCells: {
        [reportTypeId: string]: CellState
    } = {};

    for (let i = 0; i < NO_OF_ROWS; i += 1) {
        for (let j = 0; j < NO_OF_COLUMNS; j += 1) {
            const newCellId = uuid.v4();

            initialCells[newCellId] = {
                id: newCellId,
                rowIndex: i,
                columnIndex: j,
                rowSpan: 1,
                columnSpan: 1,
                content: '',
            };
        }
    }

    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_REPORT_TYPE,
        payload: {
            id: uuid.v4(),
            createdTime: now,
            lastUpdatedTime: now,
            variables: [
                payload.seedUserVariable,
                payload.seedUsersVariable,
                payload.seedMembersVariable,
                payload.seedGroupsVariable,
                payload.seedWorkflowsVariable,
                payload.seedStartDateVariable,
                payload.seedEndDateVariable,
            ],
            isolatedPieces: [],

            noOfRows: NO_OF_ROWS,
            noOfColumns: NO_OF_COLUMNS,
            cells: {
                byId: initialCells,
                allEntries: Object.keys(initialCells),
                selected: [],
            },
            ...payload,
        },
        currentTime: now,
    }
}

export function updateReportType(payload: IUpdateableReportTypeData): UpdateReportTypeAction {
    return {
        type: UPDATE_REPORT_TYPE,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function deleteReportType(id: string): DeleteReportTypeAction {
    return {
        type: DELETE_REPORT_TYPE,
        id,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function addReportTypeRow(reportTypeId: string, noOfRows: number, noOfColumns: number): IAddReportTypeRowAction {
    const newCells: Array<CellState> = [];

    for (let i = 0; i < noOfColumns; i += 1) {
        newCells.push({
            id: uuid.v4(),
            rowIndex: noOfRows,
            columnIndex: i,
            rowSpan: 1,
            columnSpan: 1,
            content: '',
        });
    }

    return {
        type: ADD_REPORT_TYPE_ROW,
        reportTypeId,
        newCells,
    };
}

export function addReportTypeColumn(reportTypeId: string, noOfRows: number, noOfColumns: number): IAddReportTypeColumnAction {
    const newCells: Array<CellState> = [];

    for (let i = 0; i < noOfRows; i += 1) {
        newCells.push({
            id: uuid.v4(),
            rowIndex: i,
            columnIndex: noOfColumns,
            rowSpan: 1,
            columnSpan: 1,
            content: '',
        });
    }

    return {
        type: ADD_REPORT_TYPE_COLUMN,
        reportTypeId,
        newCells,
    };
}

export function updateReportTypeStartPiece(payload: PiecePositionState, reportTypeId: string): UpdateReportTypeStartPieceAction {
    return {
        type: UPDATE_REPORT_TYPE_START_PIECE,
        payload,
        reportTypeId
    }
}

export function setIsolatedReportTypePiece(payload: PiecePositionState, reportTypeId: string): SetIsolatedReportTypePieceAction {
    return {
        type: SET_ISOLATED_REPORT_TYPE_PIECE,
        payload,
        reportTypeId
    }
}

export function removeIsolatedReportTypePiece(pieceId: string, reportTypeId: string): RemoveIsolatedReportTypePieceAction {
    return {
        type: REMOVE_ISOLATED_REPORT_TYPE_PIECE,
        pieceId,
        reportTypeId
    }
}

export function registerReportTypeVariable(variableId: string, reportTypeId: string): RegisterReportTypeVariableAction {
    return {
        type: REGISTER_REPORT_TYPE_VARIABLE,
        variableId,
        reportTypeId,
    }
}