import React, { Component } from 'react';
import styles from './StopPiece.module.scss';
import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setUpdateStatusValue, setTargetPiece, setMessage } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';


type StopPieceProps = {
    nextPiece?: JSX.Element,
    terminalStates: Array<Option>,
    stopState?: string,

    messageText?: string,
    messageVariablePiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setMessage: (pieceId: string, value: string|undefined) => dispatch(setMessage(pieceId, value)),
        setUpdateStatusValue: (pieceId: string, value: string) => dispatch(setUpdateStatusValue(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StopPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type StopPieceState = {
    isHoveringOverMessagePiece: boolean,
}

class ConnectedStopPiece extends Component<Props, StopPieceState> {
    state = {
        isHoveringOverMessagePiece: false,
    };

    handleHoverOverMessagePiece = () => {
        this.setState({
            isHoveringOverMessagePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMessagePiece = () => {
        this.setState({
            isHoveringOverMessagePiece: false,
        });
    };

    handleMessageValueUpdate = (value: string) => {
        this.props.setMessage(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverMessagePiece)) {

            this.props.setMessage(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMessagePiece: false,
            });
        }
    }

    render() {
        const terminalStatesMarkup = this.props.terminalStates.map(terminalState => <ListItem key={terminalState.name} theme="aqua" name={terminalState.name} value={terminalState.value} onClick={this.props.setUpdateStatusValue.bind(this, this.props.pieceId)} />);
        return (
            <FlowchartPiece {...this.props}>
                <section className={styles.stopPiece}>
                    <div className={styles.text}>End</div>
                    <SelectBox theme="dark-aqua" selectionPromptText={this.props.stopState ? this.props.stopState : "Select a status"}>
                        <DropDownList theme="aqua">
                            {terminalStatesMarkup}
                        </DropDownList>
                    </SelectBox>
                    <div className={styles.text}>with message</div>
                    {this.props.messageVariablePiece ? this.props.messageVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMessagePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverMessagePiece} onMouseOut={this.handleHoverOutOfMessagePiece} onChange={this.handleMessageValueUpdate} placeholderText="Message" defaultText={this.props.messageText} minSize={10} />}
                </section>
            </FlowchartPiece>
        )
    }
}

const StopPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStopPiece);

export default StopPiece;