import React, { Component } from 'react';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/main';
import { updateGroupedAnswerPiece, setTargetPiece, setMemberVariable } from '../../../shared/store/flowchart/pieces/actions';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';

type OwnProps = {
    pieceId: string,
    customFieldIds: Array<string>,
    selectedAnswerId: string|undefined,
    
    isShowingMemberVariablePiece: boolean
    memberVariablePiece?: JSX.Element,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        state: state,
        customField: !!ownProps.selectedAnswerId ? state.workflows.types.customFields.byId[ownProps.selectedAnswerId] : undefined,
        customFieldData: state.workflows.types.customFields,
        customFieldOptionsData: state.workflows.types.customFieldOptions,


        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        updateGroupedAnswerPiece: (customField: string|undefined) => dispatch(updateGroupedAnswerPiece(ownProps.pieceId, customField)),
        
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    isHoveringOverMemberPiece: boolean,
};

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedGroupedAnswerPiece extends Component<Props & FlowchartPieceProps, OwnState> {

    state = {
        isHoveringOverMemberPiece: false,
    }

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverMemberPiece) {

            this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMemberPiece: false,
            });
        }
    }

    render() {
        const currentGroupedAnswerName = this.props.customField ? this.props.customField.name : 'Select Answer';

        let listEntries: Array<Option>;

        const variablePiece = this.props.memberVariablePiece || <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} />

        listEntries = this.props.customFieldIds.map(customFieldId => {
            const customField = this.props.customFieldData.byId[customFieldId];
            return {
                name: customField.name,
                value: customField.id,
            }
        });

        return (<FlowchartPiece {...this.props}>
            <div>
                <SelectBox isRounded selectionPromptText={this.props.isShowingMemberVariablePiece ? currentGroupedAnswerName + ' for ' : currentGroupedAnswerName} dismissDropDownAfterSelection={false} theme="camo" hasVariableSlot={this.props.isShowingMemberVariablePiece} variablePiece={variablePiece}>
                    <DropDownList theme="camo">
                        {listEntries.map(listEntry => <ListItem name={listEntry.name} value={listEntry.value} key={listEntry.value} theme="camo" onClick={(selected) => this.props.updateGroupedAnswerPiece(selected)} />)}
                    </DropDownList>
                </SelectBox>
            </div>
        </FlowchartPiece>);
    }
}

const GroupedAnswerPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupedAnswerPiece);

export default GroupedAnswerPiece;