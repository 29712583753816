import React, { Component, ChangeEvent } from 'react';
import styles from './GroupsTable.module.scss';

import GroupModify from './GroupModify';
import GroupFilter from './GroupFilter';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/main';

import { addGroup, updateGroup, deleteGroup, searchGroupTable, goToPageGroupTable, setPageSizeGroupTable, sortGroupTable } from '../../../shared/store/groups/actions';
import { IUpdateableGroupData } from '../../../shared/store/groups/types';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField, FieldType } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';
import { IProject } from '../../../shared/store/structure/project/types';
import Button from '../../../widgets/form/Button';
import TooltipList from '../../../widgets/tooltip-list/TooltipList';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';
import { addWorkflow } from '../../../shared/store/workflows/actions';
import uuid from 'uuid';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { getAllLocationsVisibleToUser } from '../../../shared/helpers/locations';

const mapStateToProps = (state: ApplicationState) => {

    let visibleGroups: Array<string> = [];

    if (isUUID(state.myData.id)) {
        const visibleLocationIds = getAllLocationsVisibleToUser(state.myData.id);
    
        visibleLocationIds.forEach(locationId => {
            const location = state.structure.locations.byId[locationId];
            visibleGroups = visibleGroups.concat(location.groups);
        });
    } else {
        visibleGroups = state.groups.allEntries;
    }

    const allProcessedGroups = visibleGroups.map(groupId => state.groups.byId[groupId]),
          currentPageNumber = state.groups.currentPageNumber,
          pageSize = state.groups.pageSize,
          totalPages = Math.ceil(allProcessedGroups.length / pageSize),
          groupSearchTerm = state.groups.searchTerm,
          filters = state.groups.filters;

    let hasFilters = filters.types.length > 0 || filters.locations.length > 0 || Object.keys(filters.customFields).length > 0;

    let filteredGroups = !hasFilters ? allProcessedGroups : allProcessedGroups.filter(group => {

        if (filters.types.length > 0 && !filters.types.includes(group.type)) {
            return false;
        }

        if (filters.locations.length > 0 && !filters.locations.includes(group.location)) {
            return false;
        }

        for (const customFieldId in filters.customFields) {
            if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                let customField = state.groups.types.customFields.byId[customFieldId];
                const customFieldValue = group.customFields[customFieldId];

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (typeof customFieldValue !== 'string') {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                        return false;
                    }
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    if (!Array.isArray(customFieldValue)) {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                        return false;
                    }
                }

            }
        }

        return true;
    });

    if (groupSearchTerm) {
        filteredGroups = filteredGroups.filter(group => {
            const groupType = state.groups.types.byId[group.type];

            const typeName = groupType.name;
            const locationName = state.structure.locations.byId[group.location].name;

            if (typeName.toLocaleLowerCase().includes(groupSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (locationName.toLocaleLowerCase().includes(groupSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            const nameField = state.groups.types.customFields.byId[groupType.nameFieldId];
            const nameValue = getReadableDataForCustomField(group.customFields[nameField.id], nameField, group.id, 'group');

            if (nameValue.toLocaleLowerCase().includes(groupSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            const subTitleField = state.groups.types.customFields.byId[groupType.subTitleFieldId];
            const subTitleValue = getReadableDataForCustomField(group.customFields[subTitleField.id], subTitleField, group.id, 'group');

            if (subTitleValue.toLocaleLowerCase().includes(groupSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (filters.types.length === 1) {

                const typeFields = groupType.customFields.filter(fieldId => {
                    const customField = state.groups.types.customFields.byId[fieldId];
    
                    return customField.isInTable && (customField.type === FieldType.TEXT || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
                });
    
                for (const customFieldId of typeFields) {
                    if (customFieldId !== groupType.nameFieldId && customFieldId !== groupType.subTitleFieldId) {
                        const customField = state.groups.types.customFields.byId[customFieldId];
                        const fieldValue = getReadableDataForCustomField(group.customFields[customFieldId], customField, group.id, 'group');
        
                        if (fieldValue.toLocaleLowerCase().includes(groupSearchTerm.toLocaleLowerCase())) {
                            return true
                        }
                    }
                }
            }

            return false;
        });
    }
    
    const currentUser = isUUID(state.myData.id) ? state.users.byId[state.myData.id] : undefined;
    let addGroupWorkflow: string|undefined;
    let project: IProject|undefined;

    if (typeof currentUser !== 'undefined') {
        project = state.structure.projects.byId[currentUser.project];
    } else if (state.structure.projects.allEntries.length === 1) {
        project = state.structure.projects.byId[state.structure.projects.allEntries[0]];
    }

    if (typeof project !== 'undefined') {
        addGroupWorkflow = project['add-group'];
    }
    
    return {
        groupsList: filteredGroups.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize),
        totalNoOfGroups: filteredGroups.length,
        pageSize: pageSize,
        pageNumber: currentPageNumber,
        totalPages: totalPages,

        usersData: state.users,
        
        searchTerm: groupSearchTerm,
        groupFilters: state.groups.filters,
        groupSort: state.groups.sort,

        projectsData: state.structure.projects,
        
        groupTypesData: state.groups.types,
        customFieldsData: state.groups.types.customFields,
        locationsData: state.structure.locations,
        membersData: state.members,
        workflowTypesData: state.workflows.types,
        
        areFiltersExpanded: false,

        applicationState: state,
        addGroupWorkflow,
        myId: state.myData.id,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchGroupTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageGroupTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeGroupTable(size)),
        sort: (column: string, order: 'ASC'|'DESC') => dispatch(sortGroupTable(column, order)),
        
        addGroup: (payload: IUpdateableGroupData) => dispatch(addGroup(payload)),
        deleteGroup: (id: string) => dispatch(deleteGroup(id)),
        updateGroup: (payload: IUpdateableGroupData) => dispatch(updateGroup(payload)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

type OwnState = {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    isShowingFilterForm: boolean,
    isShowingFireWorkflowList: boolean,
    selectedGroup: string|undefined,
}

class ConnectedGroupsTable extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingFilterForm: false,
        isShowingFireWorkflowList: false,
        selectedGroup: undefined,
    }

    static defaultProps = {
        isReadOnly: false,
    }

    showFireWorkflowOptions = (groupId: string) => {
        this.setState({
            selectedGroup: groupId,
            isShowingFireWorkflowList: true,
        })
    }

    hideFireWorkflowOptions = () => {
        this.setState({
            selectedGroup: undefined,
            isShowingFireWorkflowList: false,
        })
    }
    
    showAddForm = () => {
        this.setState({
            isShowingAddForm: true,
        });
    }
    
    showFilterForm = () => {
        this.setState({
            isShowingFilterForm: true,
        });
    }

    showModifyForm = () => {
        this.setState({
            isShowingModifyForm: true,
        });
    }
    
    hideGroupForm = () => {
        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: false,
        });
    }
    
    hideFilterForm = () => {
        this.setState({
            isShowingFilterForm: false,
        });
    }
    
    addGroup = (groupData: IUpdateableGroupData) => {
        this.props.addGroup(groupData);
        this.setState({
            isShowingAddForm: false
        });
    }
    
    updateGroup = (groupData: IUpdateableGroupData) => {
        this.props.updateGroup(groupData);
        this.setState({
            isShowingModifyForm: false
        });
    }
    
    deleteGroup = (id: string) => {
        this.props.deleteGroup(id);
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.searchTable(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.groupSort.column !== column;
        const sortOrder = !isNewColumn && this.props.groupSort.order === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }

    fireNewWorkflow = (workflowTypeId: string, groupId: string) => {
        const newWorkflowId = uuid.v4();
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
        const defaultStatusId = workflowType.statuses.filter(statusId => !this.props.workflowTypesData.statuses.byId[statusId].isTerminal)[0];
        const defaultStatus = this.props.workflowTypesData.statuses.byId[defaultStatusId];

        let defaultDueDate = moment().add(defaultStatus.dueInDays, 'days').format('YYYY-MM-DD');
        this.props.addWorkflow({
            id: newWorkflowId,
            type: workflowTypeId,
            status: defaultStatusId,
            dueDate: defaultDueDate,
            affiliatedEntity: groupId,
            user: isUUID(this.props.myId) ? this.props.myId : this.props.usersData.allEntries[0],
        });

        this.props.history.push(`/workflow/${newWorkflowId}/execute`)
    }
    
    render() {

        const MAX_NO_OF_GROUPS = 500;

        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: 'Name',
            isSortable: false,
            isSticky: true,
            width: 150,
        }, {
            name: 'Details',
            isSortable: false,
            isSticky: false,
            width: 150,
        }, {
            name: 'Type',
            isSortable: false,
            width: 170,
        }, {
            name: 'User',
            isSortable: false,
            width: 130,
        }, {
            name: 'Representatives',
            isSortable: false,
            width: 400,
        }];

        if (this.props.groupFilters.types.length === 1) {
            const groupType = this.props.groupTypesData.byId[this.props.groupFilters.types[0]];
            groupType.customFields.forEach(customFieldId => {
                const customField = this.props.customFieldsData.byId[customFieldId];
                const width = customField.type === FieldType.TEXT ? 200 : 150;

                if (customFieldId === groupType.nameFieldId || customFieldId === groupType.subTitleFieldId) {
                    return;
                }
    
                if (customField.isInTable) {
                    headings.push({
                        name: customField.name,
                        isSortable: false,
                        width: width,
                    });
                }
            });
        }

        headings.push({
            name: 'Action',
            isSortable: false,
            width: 200,
        });

        const entries: Array<TableRow> = this.props.groupsList.map<TableRow>((group, index) => {
            const groupType = this.props.groupTypesData.byId[group.type];

            const nameField = this.props.groupTypesData.customFields.byId[groupType.nameFieldId];
            let groupName = group.customFields[groupType.nameFieldId];

            groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

            let groupSubTitle = group.customFields[groupType.subTitleFieldId];
            const subTitleField = this.props.groupTypesData.customFields.byId[groupType.subTitleFieldId];

            groupSubTitle = getReadableDataForCustomField(groupSubTitle, subTitleField, group.id, 'group');

            const cells: Array<string|number|JSX.Element> = [
                (this.props.pageNumber - 1) * this.props.pageSize + index + 1, // Sl. no
                groupName, // Name
                groupSubTitle, // Details
                this.props.groupTypesData.byId[group.type].name, // Type
                this.props.locationsData.byId[group.location].name, // Location
                group.representatives.map(memberId => {
                    const member = this.props.membersData.byId[memberId];

                    const memberType = this.props.membersData.types.byId[member.type];
                    let memberName = member.customFields[memberType.nameFieldId];;

                    const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

                    memberName = getReadableDataForCustomField(memberName, nameField, memberId, 'member');

                    return memberName;
                }).join(', '), // Representatives
            ];

            if (this.props.groupFilters.types.length === 1) {
                const groupType = this.props.groupTypesData.byId[this.props.groupFilters.types[0]];
                groupType.customFields.forEach(customFieldId => {
                    const customField = this.props.customFieldsData.byId[customFieldId];

                    if (!customField.isInTable) {
                        return;
                    }

                    if (customFieldId === groupType.nameFieldId || customFieldId === groupType.subTitleFieldId) {
                        return;
                    }
    
                    let customFieldValue = group.customFields[customFieldId];
    
                    let cellValue = getReadableDataForCustomField(customFieldValue, customField, group.id, 'group');
    
                    cells.push(cellValue);
                });
            }

            const tooltipEntries = this.props.workflowTypesData.allEntries
            .filter(workflowTypeId => {
                const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
                const nonTerminalStatuses = workflowType.statuses.filter(statusId => !this.props.workflowTypesData.statuses.byId[statusId].isTerminal);

                return nonTerminalStatuses.length > 0 && workflowType.affiliation === 'group';
            })
            .map(workflowTypeId => {
                const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
                return <div className={styles.listItem} onClick={this.fireNewWorkflow.bind(this, workflowType.id, group.id)}>{workflowType.name}</div>
            });
            
            cells.push(<div className={styles.listHolder}>
                <Button isOutline onClick={this.showFireWorkflowOptions.bind(this, group.id)} text="Fire Workflow" />
                {this.state.selectedGroup === group.id && this.state.isShowingFireWorkflowList && <div className={styles.tooltipHolder}>
                    <TooltipList handleClickOutside={this.hideFireWorkflowOptions} listElements={tooltipEntries} />
                </div>}
            </div>);

            const groupEditForm = !this.props.isReadOnly ? (<GroupModify groupId={group.id} submit={this.updateGroup} cancel={this.hideGroupForm} />) : undefined;

            const projectForGroupType = this.props.projectsData.byId[groupType.project];
            const showViewInsteadOfEdit = !!projectForGroupType['edit-group'];

            return {
                id: group.id,
                entries: cells,
                editForm: groupEditForm,
                shareText: `Name: ${groupName}\nDescription: ${groupSubTitle}`,
                showViewInsteadOfEdit,
            }
        });

        return (
            <TableWithMeta 
                entityType="Group"
                headings={headings} 
                entries={entries}
                
                sortedColumn={this.props.groupSort.column} 
                sortType={this.props.groupSort.order} 
                isReadOnly={this.props.isReadOnly}
                areFiltersExpanded={this.props.areFiltersExpanded}

                totalPages={this.props.totalPages}
                pageNumber={this.props.pageNumber}
                searchTerm={this.props.searchTerm}
                isAddAllowed={this.props.totalNoOfGroups < MAX_NO_OF_GROUPS}
                isShowingAddForm={this.state.isShowingAddForm} 
                isShowingModifyForm={this.state.isShowingModifyForm}
                addForm={<GroupModify submit={this.addGroup} cancel={this.hideGroupForm} />}
                workflowForAdd={this.props.addGroupWorkflow}

                isShowingFilterForm={this.state.isShowingFilterForm}
                showFilterForm={this.showFilterForm}
                filterForm={<GroupFilter closeFilter={this.hideFilterForm} />}

                onSort={this.handleSort} 
                onDelete={this.props.deleteGroup} 
                showAddForm={this.showAddForm}
                showModifyForm={this.showModifyForm}  

                search={this.props.searchTable}
                changePageSize={this.props.changePageSize}
                goToPage={this.props.goToPage}
                sort={this.props.sort}
            />
        );
    }
}

const GroupsTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupsTable));

export default GroupsTable;