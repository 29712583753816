import { takeEvery, put, select } from 'redux-saga/effects'
import { AddLocationAction, DeleteLocationAction, ADD_LOCATION, DELETE_LOCATION } from './types';
import { ApplicationState } from '../../main';
import { addLocationToProject, removeLocationFromProject } from '../project/actions';

function* addReverseLinkForParentProject(action: AddLocationAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.structure.projects.byId) {
        yield put(addLocationToProject(action.payload.id, action.parentId));
    }
}

function* removeReverseLinkForParentProject(action: DeleteLocationAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.structure.projects.byId) {
        yield put(removeLocationFromProject(action.id, action.parentId));
    }
}

export function* watchLocationCreationChanges() {
    yield takeEvery(ADD_LOCATION, addReverseLinkForParentProject);
}

export function* watchLocationDeletionChanges() {
    yield takeEvery(DELETE_LOCATION, removeReverseLinkForParentProject);
}