import { OrganizationState, OrganizationActionTypes, UPDATE_ORGANIZATION } from './types';

const initialState: OrganizationState = {
    name: 'Organization Name',
    code: 'ORG_CODE',
    category: 'NGO',
    categories: ['NGO'],
    size: '1 to 10',
    sizes: ['1 to 10'],
    image: '',
    language: 'English',
    phone: {
        countryCode: '+91',
        number: '9999999999'
    },
    address: '',
    coOrdinates: {
        latitude: 0,
        longitude: 0,
    },
    primary_color: '',
    primary_gradient: '',
    contrast_color: '',
}

export function organizationReducer(state = initialState, action: OrganizationActionTypes) {
    switch(action.type) {
        case UPDATE_ORGANIZATION:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}