import React, { Component } from 'react';
import styles from './MemberFilter.module.scss';
import MultiSelect from '@khanacademy/react-multi-select';

import Button from '../../../widgets/form/Button';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/main';

import { filterMemberTable } from '../../../shared/store/members/actions';

import { connect } from 'react-redux';
import { FieldType } from '../../../shared/store/custom-fields';
import { Dispatch } from 'redux';

type OwnProps = {
    closeFilter: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries;
    
    let allAllowedLocations: Array<string> = []
    
    allAllowedProjects.forEach(projectId => {
        const projectData = state.structure.projects.byId[projectId];
        const leafLevelIndex = projectData.children.map(levelId => state.structure.levels.byId[levelId]).filter(level => !level.archived).length - 1;

        let locations = state.structure.locations.byProject[projectId].map(locationId => state.structure.locations.byId[locationId]);
        
        for (let i = 0; i < leafLevelIndex; i += 1) {
            let newLocations: Array<string> = [];
            
            locations.forEach(location => {
                if (location.children && location.children.length > 0) {
                    newLocations = newLocations.concat(location.children);
                }
            });
            
            locations = newLocations.map(locationId => state.structure.locations.byId[locationId]);
        }
        allAllowedLocations = allAllowedLocations.concat(locations.map(location => location.id));
    });

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        membersData: state.members,
        customFieldsData: state.members.types.customFields,
        customFieldOptionsData: state.members.types.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        allAllowedLocations,

        filters: state.members.filters,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterMemberTable: (types: Array<string>, locations: Array<string>, customFields: {[customFieldId: string]: Array<string>}) => dispatch(filterMemberTable(types, locations, customFields)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    types: Array<string>,
    locations: Array<string>,

    customFields: {
        [customFieldId: string]: Array<string>,
    }
};

class ConnectedMemberModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            types: props.filters.types,
            locations: props.filters.locations,
            customFields: props.filters.customFields,
        };
    }
    
    changeTypes = (types: Array<string>) => {
        
        this.setState({
            types,
            customFields: {},
        });
    }
    
    changeLocations = (locations: Array<string>) => {
        
        this.setState({
            locations,
        });
    }

    changeCustomFieldOptions = (customFieldId: string, options: Array<string>) => {
        const newCustomFields = {
            ...this.state.customFields,
            [customFieldId]: options,
        };

        this.setState({
            customFields: newCustomFields,
        });
    }

    submitFilters = () => {
        this.props.filterMemberTable(
            this.state.types,
            this.state.locations,
            this.state.customFields,
        );
        this.props.closeFilter();
    }

    clearFilters = () => {
        this.setState({
            types: [],
            locations: [],
            customFields: {},
        });
        this.props.filterMemberTable([], [], {});
    }
    
    render() {

        const typesList = this.props.membersData.types.allEntries.map(typeId => {
            return {
                label: this.props.membersData.types.byId[typeId].name,
                value: typeId,
            };
        });

        const locationsList = this.props.allAllowedLocations
        .map(locationId => {
            const location = this.props.locationsData.byId[locationId];
            const parent = location.parent && location.parent in this.props.locationsData.byId ? this.props.locationsData.byId[location.parent] : undefined;

            return {
                label: parent ? `${location.name} (${parent.name})` : location.name,
                value: locationId,
            };
        });

        let memberType = this.state.types.length === 1 ? this.props.membersData.types.byId[this.state.types[0]] : undefined;
        let customFieldsMarkup: Array<JSX.Element> | undefined;

        if (typeof memberType !== 'undefined' && this.state.types.length === 1) {
            const validMemberType = memberType;
            customFieldsMarkup = this.props.customFieldsData.allFields
            .filter(customFieldId => {
                if (customFieldId === this.props.membersData.types.byId[validMemberType.id].nameFieldId || customFieldId === this.props.membersData.types.byId[validMemberType.id].subTitleFieldId || customFieldId === this.props.membersData.types.byId[validMemberType.id].locationFieldId) {
                    return false;
                }

                const customField = this.props.customFieldsData.byId[customFieldId];

                return customField.isInTable && (customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
            })
            .map(customFieldId => {
                const customField = this.props.customFieldsData.byId[customFieldId];
                const customFieldOptions = customField.choices.map(optionId => {
                    return {
                        label: this.props.customFieldOptionsData.byId[optionId].name,
                        value: optionId,
                    };
                });

                return <div className={styles.inputSegment} key={customFieldId}>
                    <MultiSelect
                        options={customFieldOptions}
                        onSelectedChanged={this.changeCustomFieldOptions.bind(this, customFieldId)}
                        selected={this.state.customFields[customFieldId] || []}
                        overrideStrings={{
                            selectSomeItems: `Select all ${customField.name}s`,
                            allItemsAreSelected: `All ${customField.name}s are selected`,
                        }}
                    />
                </div>
            });
        }
        
        return (
            <section className={styles.filterMembers}>
                <header>
                    <h2 className={styles.formHeading}>Filter Members</h2>
                    <button className={styles.cancelButton} onClick={this.props.closeFilter}><CancelIcon /> {translatePhrase('Cancel')}</button>
                </header>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={typesList}
                            onSelectedChanged={this.changeTypes}
                            selected={this.state.types}
                            overrideStrings={{
                                selectSomeItems: 'Select all Types',
                                allItemsAreSelected: 'All Types are selected',
                            }}
                        />
                    </div>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={locationsList}
                            onSelectedChanged={this.changeLocations}
                            selected={this.state.locations}
                            overrideStrings={{
                                selectSomeItems: 'Select all Locations',
                                allItemsAreSelected: 'All Locations are selected',
                            }}
                        />
                    </div>

                    {customFieldsMarkup}
                    
                </div>
                    
                <div className={styles.buttonHolder}>
                    <Button text={translatePhrase('Apply filter')} onClick={this.submitFilters} />
                    <Button isOutline text={translatePhrase('Clear filters')} onClick={this.clearFilters} />
                </div>
                
            </section>
        );
    }
}

const MemberModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedMemberModify);

export default MemberModify;