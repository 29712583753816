import React, { Component, ChangeEvent } from 'react';
import styles from './Flowchart.module.scss';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect } from "react-router-dom";

import { Permissions } from '../../../shared/store/permissions/types';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';

import { selectWorkflowType, updateWorkflowTypeStartPiece, setIsolatedWorkflowTypePiece, removeIsolatedWorkflowTypePiece, registerWorkflowTypeVariable } from '../../../shared/store/workflows/types/actions';
import { setNextPiece, setInnerPiece, setConditionPiece, setConditionNextPiece, setLoopVariable, setIterableVariable, setOperand, setLeftOperand, setRightOperand, setQuestionData, setMemberVariable, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setDataStoreValue, setDataSetValue, setDataCopyVariable, setReturnValue, addPiece, addFullPiece, deletePiece, setVariableForShow, setVariableForCustomField, setLocationPiece, setPieceForList, setVariablePiece, setWorkflowAffiliationVariable, setHeading, setDate, setMonth, setYear, setMessage } from '../../../shared/store/flowchart/pieces/actions';
import { FlowchartPieceActions, PieceType, AllPieceTypes } from '../../../shared/store/flowchart/pieces/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import uuid from 'uuid';

import { ApplicationState } from '../../../shared/store/main';
import { CategoryValue, copyPiece } from '../../flowchart/helpers/index';
import { getComponentForWorkflowPiece, piecesByCategory as workflowPiecesByCategory } from '../../flowchart/helpers/workflow';
import { PiecePositionState } from '../../../shared/store/flowchart/pieces/types';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;;

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        isDragging: state.flowchart.pieces.isDragging,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        workflowTypesData: state.workflows.types,

        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    const flowchartPieceActions: FlowchartPieceActions = {
        setNextPiece: (pieceId, value) => dispatch(setNextPiece(pieceId, value)),
        setInnerPiece: (pieceId, value) => dispatch(setInnerPiece(pieceId, value)),
        setConditionPiece: (pieceId, index, value) => dispatch(setConditionPiece(pieceId, index, value)),
        setConditionNextPiece: (pieceId, index, value) => dispatch(setConditionNextPiece(pieceId, index, value)),
        setLoopVariable: (pieceId, value) => dispatch(setLoopVariable(pieceId, value)),
        setIterableVariable: (pieceId, value) => dispatch(setIterableVariable(pieceId, value)),
        setOperand: (pieceId, value) => dispatch(setOperand(pieceId, value)),
        setLeftOperand: (pieceId, value) => dispatch(setLeftOperand(pieceId, value)),
        setRightOperand: (pieceId, value) => dispatch(setRightOperand(pieceId, value)),
        setQuestionData: (pieceId, value) => dispatch(setQuestionData(pieceId, value)),
        setMemberVariable: (pieceId, value) => dispatch(setMemberVariable(pieceId, value)),
        setRequiredPiece: (pieceId, value) => dispatch(setQuestionRequiredPiece(pieceId, value)),
        setDisabledPiece: (pieceId, value) => dispatch(setQuestionDisabledPiece(pieceId, value)),
        setDefaultPiece: (pieceId, value) => dispatch(setQuestionDefaultPiece(pieceId, value)),
        setDataStoreValue: (pieceId, value) => dispatch(setDataStoreValue(pieceId, value)),
        setDataSetValue: (pieceId, value) => dispatch(setDataSetValue(pieceId, value)),
        setDataCopyVariable: (pieceId, value) => dispatch(setDataCopyVariable(pieceId, value)),
        setDataForList: (pieceId, value) => dispatch(setPieceForList(pieceId, value)),
        setReturnVariable: (pieceId, value) => dispatch(setReturnValue(pieceId, value)),
        setLocationPiece: (pieceId, value) => dispatch(setLocationPiece(pieceId, value)),
        setVariableForShow: (pieceId, value) => dispatch(setVariableForShow(pieceId, value)),
        setVariableForCustomField: (pieceId, value) => dispatch(setVariableForCustomField(pieceId, value)),
        setVariablePiece: (pieceId, value) => dispatch(setVariablePiece(pieceId, value)),
        setAffiliationVariablePiece: (pieceId, value) => dispatch(setWorkflowAffiliationVariable(pieceId, value)),
        setHeadingPiece: (pieceId, value) => dispatch(setHeading(pieceId, value)),
        setMessage: (pieceId, value) => dispatch(setMessage(pieceId, value)),

        setDatePiece: (pieceId, value) => dispatch(setDate(pieceId, value)),
        setMonthPiece: (pieceId, value) => dispatch(setMonth(pieceId, value)),
        setYearPiece: (pieceId, value) => dispatch(setYear(pieceId, value)),

        setStartPieceData: (workflowTypeId: string, payload: PiecePositionState) => dispatch(updateWorkflowTypeStartPiece(payload, workflowTypeId)),
    };

    return {
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
        flowchartPieceActions,
        setIsolatedWorkflowTypePiece: (workflowTypeId: string, payload: PiecePositionState) => dispatch(setIsolatedWorkflowTypePiece(payload, workflowTypeId)),
        removeIsolatedWorkflowTypePiece: (workflowTypeId: string, pieceId: string) => dispatch(removeIsolatedWorkflowTypePiece(pieceId, workflowTypeId)),
        registerWorkflowTypeVariable: (workflowTypeId: string, variableId: string) => dispatch(registerWorkflowTypeVariable(variableId, workflowTypeId)),

        addPiece: (pieceId: string, pieceType: PieceType) => dispatch(addPiece(pieceId, pieceType)),
        addFullPiece: (pieceData: AllPieceTypes) => dispatch(addFullPiece(pieceData)),
        deletePiece: (pieceId: string) => dispatch(deletePiece(pieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

type OwnState = {
    isWaitingForDrag: boolean,

    expandedCategory: string|undefined,
    searchTerm: string,
}

class ConnectedWorkflowTypeFlowchart extends Component<Props, OwnState> {

    state = {
        isWaitingForDrag: false,
        expandedCategory: undefined,
        searchTerm: '',
    };

    deletePiece = (pieceId: string) => {
        const workflowTypeId = this.props.match.params.id;
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

        const pieceFound = !!workflowType.isolatedPieces.find(isolatedPiece => isolatedPiece.piece === pieceId);

        if (!pieceFound) {
            // You can only delete isolated pieces
            return;
        }
        this.props.removeIsolatedWorkflowTypePiece(workflowTypeId, pieceId);
        this.props.deletePiece(pieceId);
    }

    copyPiece = (pieceId: string, attachedPiece: boolean = false) => {
        const pieceToCopy = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const workflowTypeId = this.props.match.params.id;

        if (pieceToCopy.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = copyPiece(this.props.piecesData, this.props.addFullPiece, pieceId)

        if (!attachedPiece) {
            this.props.setIsolatedWorkflowTypePiece(workflowTypeId, {
                piece: newId,
                position: {
                    x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                    y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                },
            });
        }

        return newId;
    }

    handleKeyPress = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') {
            return;
        }
        
        switch(e.key) {
            case 'Backspace':
            case 'Delete':
                this.props.lastDraggedPiece && this.deletePiece(this.props.lastDraggedPiece);
                return;
            case 'D':
            case 'd':
                this.props.lastDraggedPiece && this.copyPiece(this.props.lastDraggedPiece);
                return;
        }
    }

    componentWillMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        // The drag event interferes with the click event. Put the change in flowchart state as the last thing in the event queue so that the click event is fired before pointer events are removed from the flowchart
        window.setTimeout(() => {
            this.setState({
                isWaitingForDrag: this.props.isDragging,
            });
        }, 500);

    }

    expandCategory = (category: string) => {
        this.setState(prevState => {
            return {
                expandedCategory: prevState.expandedCategory === category ? undefined : category,
            }
        });
    }

    addPiece = (pieceType: PieceType) => {
        const workflowTypeId = this.props.match.params.id;
        const newId = uuid.v4();
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        this.props.addPiece(newId, pieceType);
        this.props.setIsolatedWorkflowTypePiece(workflowTypeId, {
            piece: newId,
            position: {
                x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
            },
        });
    }

    updateSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: e.target.value,
        });
    }
        
    render() {

        if (!this.props.match) {
            return <div></div>;
        }
        
        const workflowTypeId = this.props.match.params.id;
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        if (!workflowType) {
            return <div></div>
        }

        const startPiece = workflowType.startPiece ? getComponentForWorkflowPiece(this.props.workflowTypesData, this.props.piecesData, this.props.variablesData, workflowType, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.removeIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.registerWorkflowTypeVariable.bind(this, workflowTypeId), workflowType.startPiece.piece, undefined) : undefined;

        const isolatedPieces = workflowType.isolatedPieces.map(isolatedPieceData => {
            const isolatedPiece = isolatedPieceData.piece ? getComponentForWorkflowPiece(this.props.workflowTypesData, this.props.piecesData, this.props.variablesData, workflowType, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.removeIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.registerWorkflowTypeVariable.bind(this, workflowTypeId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

            return isolatedPiece;
        });

        const piecesByCategory = workflowPiecesByCategory;

        return (<section className={styles.FocusSpace}>
            <div id="flowchart-holder" className={(this.state.isWaitingForDrag ? styles.waitingForDragFlowchartHolder : styles.normalFlowchartHolder) + (!this.props.isWritable ? ' react-drag-disabled' : '')}>
                <h1 className={styles.flowchartHeading}>{workflowType.name}</h1>
                <Link to="/workflows/configuration"><section className={styles.back} onClick={e => this.props.selectWorkflowType(workflowTypeId)}>{'< Back to Configuration'}</section></Link>
                {this.props.isWritable && <section className={styles.piecesCollection}>
                    <header className={styles.collectionHeader}>Click to add</header>
                    <section className={styles.searchSection}><input type="text" placeholder="Search for pieces" className={styles.searchInput} value={this.state.searchTerm} onChange={this.updateSearchTerm} /></section>
                    {Object.keys(piecesByCategory).map(category => {
                        return <section key={category}>
                            <section className={styles.categoryHeading} onClick={() => this.expandCategory(category)}>
                                <div className={styles.categoryIndicator} style={{background: (piecesByCategory as {[key : string]: CategoryValue})[category].color}}></div>
                                <div className={styles.categoryName}>{category}</div>
                                <div className={this.state.expandedCategory === category ? styles.expandedCategoryChevron : styles.categoryChevron}><ChevronDownIcon /></div>
                            </section>
                            {(!!this.state.searchTerm || this.state.expandedCategory === category) && <div>
                                {category in piecesByCategory && (piecesByCategory as {[key : string]: CategoryValue})[category].pieces.map(piece => (!!this.state.searchTerm && !piece.name.toLocaleLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())) ? undefined : <section className={styles.pieceEntry} key={piece.name} onClick={() => this.addPiece(piece.type)}>{piece.name}</section>)}
                            </div>}
                        </section>
                    })}
                </section>}
                {startPiece}
                {isolatedPieces.map((isolatedPiece, i) => <div className={styles.pieceHolder} key={i}>{isolatedPiece}</div>)}
            </div>
        </section>);
        
    }
}

const WorkflowTypeFlowchart = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowTypeFlowchart) as any);

export default WorkflowTypeFlowchart;