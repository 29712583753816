import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CardType } from '../../../widgets/card/Card';

import { ApplicationState } from '../../../shared/store/main';
import { INewProjectData, IUpdateableProjectData } from '../../../shared/store/structure/project/types';
import { reOrderProjects, addProject, updateProject, deleteProject } from '../../../shared/store/structure/project/actions';
import { Permissions } from '../../../shared/helpers/permissions';
import { translatePhrase } from '../../../shared/helpers/translation';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState 
} from '../CardTreeVertical';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewProjectData) => void,
    updateCard: (payload: IUpdateableProjectData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const allProjects: Array<CardType> = state.structure.projects.allEntries.map(projectId => {
        const project = state.structure.projects.byId[projectId];

        return {
            id: projectId,
            name: translatePhrase(project.name),
        }
    });

    const canEditHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE;
    const canViewHierarchy = canEditHierarchy || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;

    return {
        read: canViewHierarchy,
        write: canEditHierarchy,
        restrictStructureChanges: state.users.allEntries.length > 0 || state.members.allEntries.length > 0 || state.groups.allEntries.length > 0,

        cardsList: allProjects,
        selectedCard: ownProps.selectedId ? allProjects.find(project => project.id === ownProps.selectedId) : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderProjects(sourceIndex, destinationIndex)),
        addCard: (payload: INewProjectData) => dispatch(addProject(payload)),
        deleteCard: (id: string) => dispatch(deleteProject(id)),
        updateCard: (payload: IUpdateableProjectData) => dispatch(updateProject(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedProjectsList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const ProjectsList = connect(mapStateToProps, mapDispatchToProps)(ConnectedProjectsList);

export default ProjectsList;