import React from 'react';
import styles from './Option.module.scss';


type OwnProps = {
    name: string,
    value: string,
    search: string,
    isSelected: boolean,

    onClick: (name: string, value: string) => void,
}

const Option: React.FC<OwnProps> = (props) => {
    const startIndex = props.name.toLocaleLowerCase().indexOf(props.search.toLocaleLowerCase());
    const endIndex = startIndex + props.search.length;
    let optionMarkup;
    
    if (props.search === '' || startIndex === endIndex) {
        optionMarkup = <span>{props.name}</span>;
    } else {
        optionMarkup = <span>{props.name.substring(0, startIndex)}<em>{props.name.substring(startIndex, endIndex)}</em>{props.name.substring(endIndex)}</span>
    }
    
    return <section className={props.isSelected ? styles.selectedOption : styles.option} onClick={() => props.onClick(props.name, props.value)}>{optionMarkup}</section>
}

export default Option;