import { PieceType } from '../pieces/types';

import { ApplicationState } from '../../main';
import { CustomFieldValueType, FieldType } from '../../custom-fields';

import { getPieceValue, getNextPieceId, executePiece } from './index';
import { WorkflowProcessState, CustomFieldDataForIndividualMembers } from '../../workflows/types';
import { getWorkflowComputedFieldValue } from './custom-fields/workflow';
import { VariableValueType } from '../types';


export type AnswersCollection = {
    [key: string]: CustomFieldValueType | CustomFieldDataForIndividualMembers,
};


export function getWorkflowQuestionValidationValue(applicationState: ApplicationState, processState: WorkflowProcessState, workflowId: string, questionId: string, answer: CustomFieldValueType, answers: AnswersCollection, pieceId: string) : VariableValueType  {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];
    const workflowsData = applicationState.workflows;
    const workflow = workflowsData.byId[workflowId];
    const workflowType = workflowsData.types.byId[workflow.type];

    const functionShortHandTemp = getWorkflowQuestionValidationValue.bind({}, applicationState, processState, workflowId, questionId);
    const functionShortHand = functionShortHandTemp.bind({}, answer, answers);

    switch(piece.type) {
        case PieceType.ANSWER:
            return answer;

        case PieceType.GROUPED_ANSWER:
            if (!piece.customField) {
                throw new Error('A custom field needs to be selected');
            }

            const groupedAnswerField = workflowsData.types.customFields.byId[piece.customField];

            if (workflowType.affiliation === 'group' && groupedAnswerField.affiliation === 'member') {

                if (!piece.memberVariablePiece) {
                    throw new Error('The piece must have a member variable');
                }
    
                const memberVariablePiece = piecesData.byId[piece.memberVariablePiece];
    
                if (memberVariablePiece.type !== PieceType.VARIABLE) {
                    throw new Error('This piece must be a variable piece');
                }
    
                if (!memberVariablePiece.variable) {
                    throw new Error('The variable piece must point to a variable');
                }
    
                const customFieldMemberId = processState.variables[memberVariablePiece.variable];
    
                if (typeof customFieldMemberId !== 'string') {
                    throw new Error('The member ID must always be a string');
                }

                const rawAnswersInList = answers[customFieldMemberId];
                let answersInList: {
                    [customFieldId: string]: CustomFieldValueType,
                }

                if (!Array.isArray(rawAnswersInList) && typeof rawAnswersInList == 'object') {
                    answersInList = rawAnswersInList;
                } else {
                    answersInList = {};
                }
                let customFieldValue = answersInList[piece.customField];

                if (!Array.isArray(customFieldValue) && typeof customFieldValue === 'object') {
                    throw new Error('This field must be not be a collection of data for individual members');
                }

                if (groupedAnswerField.type === FieldType.SINGLE_SELECT) {
                    if (!!customFieldValue) {
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }

                        customFieldValue = workflowsData.types.customFieldOptions.byId[customFieldValue].name;
                    }

                }

                if (groupedAnswerField.type === FieldType.MULTI_SELECT) {
                    if (!!customFieldValue) {
                        if (!Array.isArray(customFieldValue)) {
                            throw new Error('A multi select must have an array');
                        }
    
                        customFieldValue = customFieldValue.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);
                        
                    }
                }

                if (groupedAnswerField.type === FieldType.BOOLEAN) {
                    if (customFieldValue === 'Yes') {
                        customFieldValue = true;
                    } else if (customFieldValue === 'No') {
                        customFieldValue = false;
                    } else {
                        customFieldValue = undefined;
                    }
                }

                return customFieldValue;

            } else {

                const rawAnswer = answers[piece.customField];
                let answer: VariableValueType;

                if (!Array.isArray(rawAnswer) && typeof rawAnswer === 'object') {
                    throw new Error('This answer must be not be a collection of data for individual members');
                }

                answer = rawAnswer;

                if (groupedAnswerField.type === FieldType.SINGLE_SELECT) {
                    if (!!answer) {
                        if (typeof answer !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }
    
                        answer = workflowsData.types.customFieldOptions.byId[answer].name;
                        
                    }
                }

                if (groupedAnswerField.type === FieldType.MULTI_SELECT) {
                    if (!!answer) {
                        if (!Array.isArray(answer)) {
                            throw new Error('A multi select must have an array');
                        }
    
                        answer = answer.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);
                        
                    }
                }

                if (groupedAnswerField.type === FieldType.BOOLEAN) {
                    if (answer === 'Yes') {
                        answer = true;
                    } else if (answer === 'No') {
                        answer = false;
                    } else {
                        answer = undefined;
                    }
                }

                return answer;
            }
        
        case PieceType.CUSTOM_FIELD:
            if (!piece.customField) {
                throw new Error('A custom field needs to be selected');
            }

            if (piece.customFieldOption) {
                return workflowsData.types.customFieldOptions.byId[piece.customFieldOption].name;
            }

            const customField = workflowsData.types.customFields.byId[piece.customField];

            if (customField.isComputed) {
                let startPiece: string|undefined;

                if (workflowType.startPiece) {
                    startPiece = workflowType.startPiece.piece;
                }

                const computedValue = getWorkflowComputedFieldValue(applicationState, processState, startPiece, workflowId);

                return computedValue;
            }

            if (workflowType.affiliation === 'group' && customField.affiliation === 'member') {
                const customFieldMemberData = processState.customFields[customField.id];

                if (typeof customFieldMemberData === 'undefined') {
                    return undefined;
                }

                if (Array.isArray(customFieldMemberData) || typeof customFieldMemberData !== 'object') {
                    throw new Error('This field must be an object with a different value for each member ID');
                }

                if (!piece.memberVariablePiece) {
                    throw new Error('The piece must have a member variable');
                }
    
                const memberVariablePiece = piecesData.byId[piece.memberVariablePiece];
    
                if (memberVariablePiece.type !== PieceType.VARIABLE) {
                    throw new Error('This piece must be a variable piece');
                }
    
                if (!memberVariablePiece.variable) {
                    throw new Error('The variable piece must point to a variable');
                }
    
                const customFieldMemberId = processState.variables[memberVariablePiece.variable];
    
                if (typeof customFieldMemberId !== 'string') {
                    throw new Error('The member ID must always be a string');
                }

                let customFieldValue = customFieldMemberData[customFieldMemberId];

                if (!Array.isArray(customFieldValue) && typeof customFieldValue === 'object') {
                    throw new Error('This field must be not be a collection of data for individual members');
                }

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (!!customFieldValue) {
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }
    
                        customFieldValue = workflowsData.types.customFieldOptions.byId[customFieldValue].name;
                        
                    }
                }

                if (customField.type === FieldType.MULTI_SELECT) {
                    if (!!customFieldValue) {
                        if (!Array.isArray(customFieldValue)) {
                            throw new Error('A multi select must have an array');
                        }
    
                        customFieldValue = customFieldValue.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);
                        
                    }
                }

                if (customField.type === FieldType.BOOLEAN) {
                    if (customFieldValue === 'Yes') {
                        customFieldValue = true;
                    } else if (customFieldValue === 'No') {
                        customFieldValue = false;
                    } else {
                        customFieldValue = undefined;
                    }
                }

                return customFieldValue;

            } else {
                const rawCustomFieldValue = processState.customFields[customField.id]
                let customFieldValue: VariableValueType;

                if (!Array.isArray(rawCustomFieldValue) && typeof rawCustomFieldValue === 'object') {
                    throw new Error('This field must be not be a collection of data for individual members');
                }

                customFieldValue = rawCustomFieldValue;

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (!!customFieldValue) {
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }
    
                        customFieldValue = workflowsData.types.customFieldOptions.byId[customFieldValue].name;
                        
                    }
                }

                if (customField.type === FieldType.MULTI_SELECT) {
                    if (!!customFieldValue) {
                        if (!Array.isArray(customFieldValue)) {
                            throw new Error('A multi select must have an array');
                        }
    
                        customFieldValue = customFieldValue.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);
                        
                    }
                }

                if (customField.type === FieldType.BOOLEAN) {
                    if (customFieldValue === 'Yes') {
                        customFieldValue = true;
                    } else if (customFieldValue === 'No') {
                        customFieldValue = false;
                    } else {
                        customFieldValue = undefined;
                    }
                }

                return customFieldValue;
            }
        
        case PieceType.SEQUENCE:
            const allPreviousWorkflows = workflowsData.allEntries.slice(0, workflowsData.allEntries.indexOf(workflow.id) + 1);
            const selectedOptions = piece.selectedOptions;

            if (!selectedOptions || selectedOptions.length === 0) {
                return 1;  // There are no options, so all values are the first of thier kind
            }
        
            let sequenceNumber = 0;  // Initialize the number to zero before checking
        
            allPreviousWorkflows.forEach(previousWorkflowId => {
                const previousWorkflow = workflowsData.byId[previousWorkflowId];
                let matchesPreviousWorkflow = true;

                selectedOptions.forEach(option => {

                    switch(option) {
                        case 'type':
                            if (previousWorkflow.type !== workflow.type) {
                                matchesPreviousWorkflow = false;
                                return;
                            }
                            break;
                        case 'affiliatedEntity':
                            if (previousWorkflow.affiliatedEntity !== workflow.affiliatedEntity) {
                                matchesPreviousWorkflow = false;
                                return;
                            }
                            break;
                    }
                });

                if (matchesPreviousWorkflow) {
                    sequenceNumber += 1;
                }
            });
            
            return sequenceNumber;

        case PieceType.STATUS:
            return piece.statusId ? workflowsData.types.statuses.byId[piece.statusId].name : workflowsData.types.statuses.byId[workflow.status].name;
        
        default:
            return getPieceValue(applicationState, processState, pieceId, functionShortHand);
    }
}

function getNextPieceIdForQuestionValidation(applicationState: ApplicationState, processState: WorkflowProcessState, startPiece: string|undefined, workflowId: string, questionId: string, answer: CustomFieldValueType, answers: AnswersCollection): string|undefined {
    
    const getNextPieceIdTemp = getNextPieceIdForQuestionValidation.bind({}, applicationState, processState, startPiece, workflowId);
    const getNextPieceIdShortHand = getNextPieceIdTemp.bind({}, questionId, answer, answers);

    const getPieceValueShortHandTemp = getWorkflowQuestionValidationValue.bind({}, applicationState, processState, workflowId, questionId);
    const getPieceValueShortHand = getPieceValueShortHandTemp.bind({}, answer, answers);

    return getNextPieceId(applicationState, processState, startPiece, getNextPieceIdShortHand, getPieceValueShortHand);
}

type ExecutePieceReturnType = {
    canContinueExecution: boolean,
    returnValue: CustomFieldValueType,
};


// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForQuestionValidation(applicationState: ApplicationState, processState: WorkflowProcessState, pieceId: string, workflowId: string, questionId: string, answer: CustomFieldValueType, answers: AnswersCollection): ExecutePieceReturnType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const getPieceValueShortHandTemp = getWorkflowQuestionValidationValue.bind({}, applicationState, processState, workflowId, questionId);
    const getPieceValueShortHand = getPieceValueShortHandTemp.bind({}, answer, answers);

    switch(piece.type) {

        case PieceType.ERROR:
            if (!piece.error) {
                throw new Error('The error piece must have an error message');
            }
            
            return {
                canContinueExecution: false,
                returnValue: piece.error,
            };

        case PieceType.SUCCESS:
            
            return {
                canContinueExecution: false,
                returnValue: undefined,
            };

        default:
            const canContinueExecution = executePiece(applicationState, processState, pieceId, getPieceValueShortHand);

            return {
                canContinueExecution,
                returnValue: undefined,
            };
    }
}

export function getWorkflowQuestionValidation(applicationState: ApplicationState, processState: WorkflowProcessState, startPiece: string|undefined, workflowId: string, questionId: string, answer: CustomFieldValueType, answers: AnswersCollection) {
    let executionResult: ExecutePieceReturnType;
    let pieceToExecute = startPiece;

    if (!pieceToExecute) {
        return undefined;
    }

    try {
        executionResult = executePieceForQuestionValidation(applicationState, processState, pieceToExecute, workflowId, questionId, answer, answers);
    } catch (e) {
        console.warn(`The question ${questionId} has invalid validation`);
        return undefined;
    }
    

    do {
        pieceToExecute = getNextPieceIdForQuestionValidation(applicationState, processState, pieceToExecute, workflowId, questionId, answer, answers);

        if (!pieceToExecute) {
            return undefined;
        }
    
        try {
            executionResult = executePieceForQuestionValidation(applicationState, processState, pieceToExecute, workflowId, questionId, answer, answers);
        } catch (e) {
            console.warn(`The question ${questionId} has invalid validation`);
            return undefined;
        }

    } while (executionResult.canContinueExecution);

    return executionResult.returnValue;
}