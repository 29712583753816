import { PiecePositionState } from "../flowchart/pieces/types";
import { NormalizedModel, Synchronizable } from '../normalized-model';

export const ADD_WIDGET = 'ADD_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const DELETE_WIDGET = 'DELETE_WIDGET';

export const UPDATE_WIDGET_DATA = 'UPDATE_WIDGET_DATA';

export const UPDATE_WIDGET_START_PIECE = 'UPDATE_WIDGET_START_PIECE';
export const SET_ISOLATED_WIDGET_PIECE = 'SET_ISOLATED_WIDGET_PIECE';
export const REMOVE_ISOLATED_WIDGET_PIECE = 'REMOVE_ISOLATED_WIDGET_PIECE';
export const REGISTER_WIDGET_VARIABLE = 'REGISTER_WIDGET_VARIABLE';

export interface INewWidgetData {
    name: string,

    type: string,
    typeId: string,
    customFields: Array<string>,

    roles: Array<string>,

    seedEntityVariable: string,

    displayType: 'table'|'bar'|'line'|'donut',
    aggregation: 'none'|'count'|'sum'|'average',
}

export interface IUpdateableWidgetData extends INewWidgetData {
    id: string,
}

export interface IWidget extends IUpdateableWidgetData, Synchronizable {
    archived?: boolean,
    createdTime: string,
    startPiece?: PiecePositionState,
    variables: Array<string>,
    isolatedPieces: Array<PiecePositionState>,
}

export interface WidgetState extends NormalizedModel<IWidget> {
    byRole: {
        [roleId: string]: Array<string>,
    },
}

export interface AddWidgetAction {
    type: typeof ADD_WIDGET,
    payload: IWidget,
}

export interface UpdateWidgetAction {
    type: typeof UPDATE_WIDGET,
    payload: IUpdateableWidgetData,
}

export interface DeleteWidgetAction {
    type: typeof DELETE_WIDGET,
    id: string,
}

export interface IUpdateWidgetData {
    type: typeof UPDATE_WIDGET_DATA,
    data: WidgetState,
}


export interface UpdateWidgetStartPieceAction {
    type: typeof UPDATE_WIDGET_START_PIECE,
    payload: PiecePositionState,
    widgetId: string,
}

export interface SetIsolatedWidgetPieceAction {
    type: typeof SET_ISOLATED_WIDGET_PIECE,
    payload: PiecePositionState,
    widgetId: string,
}

export interface RemoveIsolatedWidgetPieceAction {
    type: typeof REMOVE_ISOLATED_WIDGET_PIECE,
    pieceId: string,
    widgetId: string,
}

export interface RegisterWidgetVariableAction {
    type: typeof REGISTER_WIDGET_VARIABLE,
    variableId: string,
    widgetId: string,
}

export type WidgetActionTypes = AddWidgetAction | UpdateWidgetAction | DeleteWidgetAction | IUpdateWidgetData | UpdateWidgetStartPieceAction | SetIsolatedWidgetPieceAction | RemoveIsolatedWidgetPieceAction | RegisterWidgetVariableAction;