import React from 'react';
import styles from './Toggle.module.scss';


type OwnProps = {
    toggleId: string,
    isOn: boolean,

    onToggle: (id: string) => void;
}

const Toggle: React.FC<OwnProps> = (props) => {
    
    return (
        <section className={props.isOn ? styles.on : styles.off} onClick={() => props.onToggle(props.toggleId)}>
            <div className={styles.slider}></div>
        </section>
    );
}

export default Toggle;