import { UserState, UserActionTypes, ADD_USER, UPDATE_USER, DELETE_USER, SEARCH_USER_TABLE, GO_TO_PAGE_USER_TABLE, SET_PAGE_SIZE_USER_TABLE, SORT_USER_TABLE, SELECT_USER_CUSTOM_FIELD, UN_SELECT_USER_CUSTOM_FIELD, ADD_USER_CUSTOM_FIELD, DELETE_USER_CUSTOM_FIELD, UPDATE_USER_CUSTOM_FIELD, SELECT_USER_CUSTOM_FIELD_OPTION, UN_SELECT_USER_CUSTOM_FIELD_OPTION, RE_ORDER_USER_CUSTOM_FIELD_OPTION, ADD_USER_CUSTOM_FIELD_OPTION, DELETE_USER_CUSTOM_FIELD_OPTION, UPDATE_USER_CUSTOM_FIELD_OPTION, UPDATE_USER_DATA, UPDATE_USER_WORKFLOWS, UPDATE_USER_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_USER_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE, REGISTER_USER_CUSTOM_FIELD_VARIABLE, UPDATE_USER_CUSTOM_FIELD_DATA, IUser, FILTER_USER_TABLE } from './types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, updateStartPieceForCustomField, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, FieldType, registerVariableForCustomField } from '../custom-fields';
import uuid from 'uuid';
import { addEntity, deleteEntity, updateEntity } from '../normalized-model';

const nameId = uuid.v4();
const subTitleId = uuid.v4();
const locationId = uuid.v4();

const initialState: UserState = {
    byId: {},
    allEntries: [],
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    pageSize: 10,
    currentPageNumber: 1,

    filters: {
        projects: [],
        levels: [],
        roles: [],
        locations: [],
        customFields: {}
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: undefined,

    customFields: {  
        byId: {
            [nameId]: {
                id: nameId,
                name: 'Name',
                parentId: undefined,
                type: FieldType.TEXT,
                isComputed: false,
                isInTable: true,
                isUnique: false,
                isEditable: true,
                isDeletable: false,
                seedEntityVariable: uuid.v4(),
                choices: [],
                
                variables: [],
                isolatedPieces: [],
            },
            [subTitleId]: {
                id: subTitleId,
                name: 'Sub Title',
                parentId: undefined,
                type: FieldType.TEXT,
                isComputed: false,
                isInTable: false,
                isUnique: false,
                isEditable: true,
                isDeletable: false,
                seedEntityVariable: uuid.v4(),
                choices: [],
                
                variables: [],
                isolatedPieces: [],
            },
            [locationId]: {
                id: locationId,
                name: 'Last seen',
                parentId: undefined,
                type: FieldType.LOCATION,
                isComputed: false,
                isInTable: false,
                isUnique: false,
                isEditable: false,
                isDeletable: false,
                seedEntityVariable: uuid.v4(),
                choices: [],
                
                variables: [],
                isolatedPieces: [],
            }
        },
        allFields: [nameId, subTitleId, locationId]
    },
    customFieldOptions: {  
        byId: {},
        allOptions: []
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),

    nameFieldId: nameId,
    subTitleFieldId: subTitleId,
    locationFieldId: locationId,
};

export function usersReducer(state = initialState, action: UserActionTypes): UserState {
    switch(action.type) {

        case ADD_USER:
            return addEntity<UserState, IUser>(state, action.payload);
        
        case UPDATE_USER:
            return updateEntity<UserState, IUser>(state, action.payload);
        
        case DELETE_USER:
            return deleteEntity<UserState, IUser>(state, action.id);

        case UPDATE_USER_WORKFLOWS:
            const doesWorkflowTypeExist = action.workflowTypeId in state.byId[action.userId].workflows;
            let connectedWorkflows: Array<string>;

            if (doesWorkflowTypeExist) {
                connectedWorkflows = state.byId[action.userId].workflows[action.workflowTypeId];
            } else {
                connectedWorkflows = [];
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.userId]: {
                        ...state.byId[action.userId],
                        workflows: {
                            ...state.byId[action.userId].workflows,
                            [action.workflowTypeId]: connectedWorkflows.concat([action.workflowId]),
                        }
                    }
                }
            }

        case UPDATE_USER_CUSTOM_FIELD_DATA:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.userId]: {
                        ...state.byId[action.userId],
                        customFields: {
                            ...state.byId[action.userId].customFields,
                            ...action.customFieldData,
                        }
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.userId]),
            }


        case SEARCH_USER_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_USER_TABLE:
            return {
                ...state,
                filters: {
                    projects: action.projects,
                    levels: action.levels,
                    roles: action.roles,
                    locations: action.locations,
                    customFields: action.customFields,
                },
                currentPageNumber: 1,
            }
        
        case GO_TO_PAGE_USER_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }
        
        case SET_PAGE_SIZE_USER_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        
        case SORT_USER_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }
        
        case SELECT_USER_CUSTOM_FIELD:
            return selectCustomField<UserState>(state, action.id);
        
        case UN_SELECT_USER_CUSTOM_FIELD:
            return unSelectCustomField<UserState>(state);

        case ADD_USER_CUSTOM_FIELD:
            return addCustomField<UserState>(state, action.payload, undefined);
        
        case DELETE_USER_CUSTOM_FIELD:
            return deleteCustomField<UserState>(state, action.id);
        
        case UPDATE_USER_CUSTOM_FIELD:
            return updateCustomField<UserState>(state, action.payload);


        case UPDATE_USER_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<UserState>(state, action.customFieldId, action.payload);
    
        case SET_ISOLATED_USER_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<UserState>(state, action.customFieldId, action.payload);

        case REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<UserState>(state, action.customFieldId, action.pieceId);

        case REGISTER_USER_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<UserState>(state, action.customFieldId, action.variableId);
        
        
        case SELECT_USER_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<UserState>(state, action.id);
        
        case UN_SELECT_USER_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<UserState>(state);

        case RE_ORDER_USER_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<UserState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_USER_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<UserState>(state, action.payload, action.parentId);
        
        case DELETE_USER_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<UserState>(state, action.id, action.parentId);

        case UPDATE_USER_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<UserState>(state, action.payload);

        case UPDATE_USER_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                filters: state.filters,
                createdCustomFieldIds: state.createdCustomFieldIds,
                updatedCustomFieldIds: state.updatedCustomFieldIds,
                deletedCustomFieldIds: state.deletedCustomFieldIds,
                createdCustomFieldOptionIds: state.createdCustomFieldOptionIds,
                updatedCustomFieldOptionIds: state.updatedCustomFieldOptionIds,
                deletedCustomFieldOptionIds: state.deletedCustomFieldOptionIds,
            }
        
        default:
            return state;
    }
}