import { START_DATA_PUSH, COMPLETE_DATA_PUSH } from './types';

export function startDataPush() {
    return {
        type: START_DATA_PUSH,
    }
}

export function completeDataPush() {
    return {
        type: COMPLETE_DATA_PUSH,
    }
}