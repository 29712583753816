import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type NotEqualToOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const NotEqualToOperator: FC<NotEqualToOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator operatorSymbol="≠" isBoolean={true} {...props} />
    )
}

export default NotEqualToOperator;