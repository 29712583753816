import React, { Component, FocusEvent, ChangeEvent } from 'react';
import styles from './Translations.module.scss';
import { Redirect } from "react-router-dom";

import { updateTranslation } from '../../shared/store/internationalization/translations/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../shared/store/main';
import { Permissions } from '../../shared/store/permissions/types';
import { getTranslatablePhrasesFromFlowchart } from '../../shared/helpers/translation';
import { hardCodedWords } from './hardcodedWords';
import { FieldType } from '../../shared/store/custom-fields';
import { CSVLink } from 'react-csv';
import { ReactComponent as TableIcon } from '../../assets/table-grid.svg';
import { ReactComponent as ExportIcon } from '../../assets/export.svg';
import Toggle from '../../widgets/form/Toggle';
import Papa from 'papaparse';

type OwnProps = {
    languageId: string,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const canEditLanguages = state.permissions.myPermissions.general.Languages === Permissions.WRITE;
    const canViewLanguages = canEditLanguages || state.permissions.myPermissions.general.Languages === Permissions.READ;

    return {
        isReadable: canViewLanguages,
        applicationState: state,
        translations: state.internationalization.translations.byLanguage[ownProps.languageId] || {},
        language: state.internationalization.languages.byId[ownProps.languageId],
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateTranslation: (originalWord: string, translatedWord: string, languageId: string) => dispatch(updateTranslation(originalWord, translatedWord, languageId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    showAllWords: boolean,
}

class ConnectedLocations extends Component<Props, OwnState> {
    state = {
        showAllWords: false,
    }

    changeTranslation = (originalWord: string, e: FocusEvent<HTMLInputElement>) => {
        const translation = e.currentTarget.value;

        this.props.updateTranslation(originalWord, translation, this.props.languageId);
    }

    toggleWordDisplay = () => {
        this.setState(prevState => {
            return {
                showAllWords: !prevState.showAllWords,
            }
        })
    }

    getTranslateableWords = () => {
        const masterSet: Set<string> = new Set(hardCodedWords);

        Object.keys(FieldType).forEach(name => {
            const readableName = name.split('_').join(' ');
            masterSet.add(readableName);
        });

        // Adding widget names
        this.props.applicationState.widgets.allEntries.forEach(widgetId => masterSet.add(this.props.applicationState.widgets.byId[widgetId].name));

        // Adding project names
        this.props.applicationState.structure.projects.allEntries.forEach(projectId => masterSet.add(this.props.applicationState.structure.projects.byId[projectId].name));

        // Adding level names
        this.props.applicationState.structure.levels.allEntries.forEach(levelId => masterSet.add(this.props.applicationState.structure.levels.byId[levelId].name));

        // Adding level custom fields
        this.props.applicationState.structure.levels.customFields.allFields.forEach(fieldId => {
            const customField = this.props.applicationState.structure.levels.customFields.byId[fieldId];
            masterSet.add(customField.name.trim());

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.add(phrase.trim()));
            }
        });

        // Adding level custom field options
        this.props.applicationState.structure.levels.customFieldOptions.allOptions.forEach(optionId => masterSet.add(this.props.applicationState.structure.levels.customFieldOptions.byId[optionId].name.trim()));

        // Adding role names
        this.props.applicationState.structure.roles.allEntries.forEach(roleId => masterSet.add(this.props.applicationState.structure.roles.byId[roleId].name));

        // Adding role custom fields
        this.props.applicationState.structure.roles.customFields.allFields.forEach(fieldId => {
            const customField = this.props.applicationState.structure.roles.customFields.byId[fieldId];
            masterSet.add(customField.name.trim());

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.add(phrase.trim()));
            }
        });

        // Adding role custom field options
        this.props.applicationState.structure.roles.customFieldOptions.allOptions.forEach(optionId => masterSet.add(this.props.applicationState.structure.roles.customFieldOptions.byId[optionId].name.trim()));

        // Adding location names
        this.props.applicationState.structure.locations.allEntries.forEach(locationId => masterSet.add(this.props.applicationState.structure.locations.byId[locationId].name));

        // Adding user custom fields
        this.props.applicationState.users.customFields.allFields.forEach(fieldId => {
            const customField = this.props.applicationState.users.customFields.byId[fieldId];
            masterSet.add(customField.name.trim());

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.add(phrase.trim()));
            }
        });

        // Adding user custom field options
        this.props.applicationState.users.customFieldOptions.allOptions.forEach(optionId => masterSet.add(this.props.applicationState.users.customFieldOptions.byId[optionId].name.trim()));

        // Adding member types
        this.props.applicationState.members.types.allEntries.forEach(typeId => masterSet.add(this.props.applicationState.members.types.byId[typeId].name.trim()));

        // Adding member custom fields
        this.props.applicationState.members.types.customFields.allFields.forEach(fieldId => {
            const customField = this.props.applicationState.members.types.customFields.byId[fieldId];
            masterSet.add(customField.name.trim());

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.add(phrase.trim()));
            }
        });

        // Adding member custom field options
        this.props.applicationState.members.types.customFieldOptions.allOptions.forEach(optionId => masterSet.add(this.props.applicationState.members.types.customFieldOptions.byId[optionId].name.trim()));

        // Adding group types
        this.props.applicationState.groups.types.allEntries.forEach(typeId => masterSet.add(this.props.applicationState.groups.types.byId[typeId].name.trim()));

        // Adding group custom fields
        this.props.applicationState.groups.types.customFields.allFields.forEach(fieldId => {
            const customField = this.props.applicationState.groups.types.customFields.byId[fieldId];
            masterSet.add(customField.name.trim());

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.add(phrase.trim()));
            }
        });

        // Adding group custom field options
        this.props.applicationState.groups.types.customFieldOptions.allOptions.forEach(optionId => masterSet.add(this.props.applicationState.groups.types.customFieldOptions.byId[optionId].name.trim()));

        // Adding workflow types
        this.props.applicationState.workflows.types.allEntries.forEach(typeId => {
            const workflowType = this.props.applicationState.workflows.types.byId[typeId];
            masterSet.add(workflowType.name.trim());

            if (workflowType.startPiece) {
                getTranslatablePhrasesFromFlowchart(workflowType.startPiece.piece).forEach(phrase => masterSet.add(phrase.trim()));
            }
        });

        // Adding workflow statuses
        this.props.applicationState.workflows.types.statuses.allEntries.forEach(statusId => {
            const workflowTypeStatus = this.props.applicationState.workflows.types.statuses.byId[statusId];
            masterSet.add(workflowTypeStatus.name.trim());
        });

        // Adding workflow type fields
        this.props.applicationState.workflows.types.customFields.allFields.forEach(fieldId => {
            const customField = this.props.applicationState.workflows.types.customFields.byId[fieldId];
            masterSet.add(customField.name.trim());

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.add(phrase.trim()));
            }
        });

        // Adding workflow custom field options
        this.props.applicationState.workflows.types.customFieldOptions.allOptions.forEach(optionId => masterSet.add(this.props.applicationState.workflows.types.customFieldOptions.byId[optionId].name.trim()));

        return masterSet;
    }

    importTranslations = (importData: Array<Array<string>>) => {

        const dataWithoutHeader = importData.slice(1);

        for (const rowData of dataWithoutHeader) {
            if (rowData.length === 2) {
                const originalPhrase = rowData[0];
                const translation = rowData[1];

                if (!!translation) {
                    this.props.updateTranslation(originalPhrase, translation, this.props.languageId);
                }
            }
        }
    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;
                    this.importTranslations(csvData);
                }
            });
        }
    }
        
    render() {        

        let translatables = Array.from(this.getTranslateableWords());
        const csvData: Array<Array<string>> = [['Original phrase', 'Translated phrase']];

        if (!this.state.showAllWords) {
            translatables = translatables.filter(originalPhrase => !this.props.translations[originalPhrase]);
        }

        for (const translatable of translatables) {
            csvData.push([translatable, this.props.translations[translatable]]);
        }

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        return <div>
            <div className={styles.translationOptions}>
                <section className={styles.toggleFilter}>
                    <div className={styles.text}>Show Untranslated</div>
                    <Toggle toggleId="showing-all-words" isOn={this.state.showAllWords} onToggle={this.toggleWordDisplay} />
                    <div className={styles.text}>Show All</div>
                </section>
                <section className={styles.csvOptions}>
                    <CSVLink data={csvData} filename={`${this.props.language.name} translations.csv`}><button className={styles.fixedButton + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}><TableIcon /> Download translatables</button></CSVLink>
                    <label htmlFor="import-file-input" className={styles.fixedButton + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}> <ExportIcon /> Import}</label>
                </section>
            </div>

            <input type="file" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />

            <section className={styles.translationMasterCard}>
                <div className={styles.header}>
                    <section className={styles.headerCell}>Original phrase</section>
                    <section className={styles.headerCell}>Translated phrase</section>
                </div>
                <div className={styles.body}>
                    {translatables
                    .map(originalPhrase => <div key={originalPhrase} className={styles.translationRow}>
                        <section className={styles.originalCell}>{originalPhrase}</section>
                        <section className={styles.translatedCell}>
                            <input type="text" className={styles.translationInput} defaultValue={this.props.translations[originalPhrase]} onBlur={this.changeTranslation.bind(this, originalPhrase)} placeholder="Enter translation here" />
                        </section>
                    </div>)}
                </div>
            </section>
        </div>
        
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;