import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { INewWorkflowTypeData, IUpdateableWorkflowTypeData } from '../../../shared/store/workflows/types/types';
import { updateSpecialWorkflowTypeForProject } from '../../../shared/store/structure/project/actions';
import { reOrderWorkflowTypes, addWorkflowType, updateWorkflowType, deleteWorkflowType } from '../../../shared/store/workflows/types/actions';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState,
    WorkflowCardType
} from './WorkflowTypesVertical';
import { Permissions } from '../../../shared/store/permissions/types';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: IUpdateableWorkflowTypeData) => void,
    updateCard: (payload: IUpdateableWorkflowTypeData) => void,
    updateSpecialWorkflowTypeForProject: (projectId: string, specialType: 'add-member'|'add-group'|'edit-member'|'edit-group', workflowTypeId: string|undefined) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const generalWorkflowTypePermission = state.permissions.myPermissions.general.WorkflowsConfiguration;

    const allWorkflowTypes: Array<WorkflowCardType> = state.workflows.types.allEntries
    .filter(workflowTypeId => {
        const specificPermission = state.permissions.myPermissions.workflows[workflowTypeId];
        const permission = typeof specificPermission === 'undefined' ? generalWorkflowTypePermission : specificPermission;

        return permission !== Permissions.NONE;
    })
    .map(workflowTypeId => {
        const workflowType = state.workflows.types.byId[workflowTypeId];
        const specificPermission = state.permissions.myPermissions.workflows[workflowTypeId];

        const coreText = workflowType.isCore ? 'Core' : 'Secondary';
        let affiliationText;

        if (workflowType.affiliation === 'none') {
            affiliationText = 'Not affiliated';
        } else if (workflowType.affiliation === 'member') {
            if (!!workflowType.affiliatedEntity) {
                affiliationText = 'Affiliated with ' + state.members.types.byId[workflowType.affiliatedEntity].name;
            } else {
                affiliationText = 'Affiliated with member';
            }
        } else if (workflowType.affiliation === 'group') {
            if (!!workflowType.affiliatedEntity) {
                affiliationText = 'Affiliated with ' + state.groups.types.byId[workflowType.affiliatedEntity].name;
            } else {
                affiliationText = 'Affiliated with group';
            }
        }

        return {
            id: workflowTypeId,
            name: workflowType.name,
            details: `${coreText}, ${affiliationText}`,
            isReadOnly: workflowType.isManaged || (typeof specificPermission !== 'undefined' && specificPermission === Permissions.READ),
            affiliation: workflowType.affiliation,
            affiliatedEntity: workflowType.affiliatedEntity,
            isCore: workflowType.isCore,
            project: workflowType.project,
            seedEntityVariable: workflowType.seedEntityVariable,
            seedAffiliationVariable: workflowType.seedAffiliationVariable,
            startPiece: workflowType.startPiece,
        }
    });

    let specialType: 'none'|'add-member'|'add-group'|'edit-member'|'edit-group' = 'none';

    const selectedCard = allWorkflowTypes.find(workflowType => workflowType.id === ownProps.selectedId);

    if (ownProps.selectedId && !!selectedCard) {
        const projectData = state.structure.projects.byId[selectedCard.project];

        if (typeof projectData !== 'undefined') {
            if (projectData['add-member'] === selectedCard.id) {
                specialType = 'add-member';
            } else if (projectData['add-group'] === selectedCard.id) {
                specialType = 'add-group';
            } else if (projectData['edit-member'] === selectedCard.id) {
                specialType = 'edit-member';
            } else if (projectData['edit-group'] === selectedCard.id) {
                specialType = 'edit-group';
            }
        }
        
    }

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        projectsData: state.structure.projects,
        memberTypesData: state.members.types,
        groupTypesData: state.groups.types,
        workflowTypesData: state.workflows.types,
        cardsList: allWorkflowTypes,
        selectedCard,
        originalSpecialType: specialType,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderWorkflowTypes(sourceIndex, destinationIndex)),
        addCard: (payload: INewWorkflowTypeData) => dispatch(addWorkflowType(payload)),
        deleteCard: (id: string) => dispatch(deleteWorkflowType(id)),
        updateCard: (payload: IUpdateableWorkflowTypeData) => dispatch(updateWorkflowType(payload)),
        updateSpecialWorkflowTypeForProject: (projectId: string, specialType: 'add-member'|'add-group'|'edit-member'|'edit-group', workflowTypeId: string|undefined) => dispatch(updateSpecialWorkflowTypeForProject(projectId, workflowTypeId, specialType))
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedWorkflowTypesList extends CardTreeVertical<Props, OwnState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isShowingAddForm: false,
            isShowingModifyForm: false,
            modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
            modifyingCardProject: props.selectedCard ? props.selectedCard.project : '',
            modifyingCardIsCore: props.selectedCard && !props.selectedCard.isCore ? 'No' : 'Yes',
            modifyingCardAffiliation: props.selectedCard ? props.selectedCard.affiliation : 'none',
            modifyingCardAffiliatedEntity: props.selectedCard ? props.selectedCard.affiliatedEntity : '',

            modifyingSpecialType: props.originalSpecialType,
        
            seedAffiliationVariable: props.selectedCard ? props.selectedCard.seedAffiliationVariable : '',
            lastSelectedCard: props.selectedCard ? props.selectedCard.id : undefined,
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const WorkflowTypesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowTypesList);

export default WorkflowTypesList;