import { ADD_USER, UPDATE_USER, UPDATE_USER_REQUEST, DELETE_USER, SEARCH_USER_TABLE, GO_TO_PAGE_USER_TABLE, SET_PAGE_SIZE_USER_TABLE, SORT_USER_TABLE, SELECT_USER_CUSTOM_FIELD, UN_SELECT_USER_CUSTOM_FIELD, ADD_USER_CUSTOM_FIELD, DELETE_USER_CUSTOM_FIELD, UPDATE_USER_CUSTOM_FIELD, SELECT_USER_CUSTOM_FIELD_OPTION, UN_SELECT_USER_CUSTOM_FIELD_OPTION, RE_ORDER_USER_CUSTOM_FIELD_OPTION, ADD_USER_CUSTOM_FIELD_OPTION, DELETE_USER_CUSTOM_FIELD_OPTION, UPDATE_USER_CUSTOM_FIELD_OPTION, IUpdateableUserData, UPDATE_USER_WORKFLOWS, UPDATE_USER_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_USER_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE, REGISTER_USER_CUSTOM_FIELD_VARIABLE, UPDATE_USER_CUSTOM_FIELD_DATA, AddUserAction, UpdateUserRequestAction, UpdateUserAction, DeleteUserAction, UpdateUserWorkflowAction, UpdateUserCustomFieldDataAction, SearchUserTableAction, GoToPageUserTableAction, SetPageSizeUserTableAction, SortUserTableAction, SelectUserCustomFieldAction, UnSelectUserCustomFieldAction, AddUserCustomFieldAction, DeleteUserCustomFieldAction, UpdateUserCustomFieldAction, UpdateUserCustomFieldStartPieceAction, SetIsolatedUserCustomFieldPieceAction, RemoveIsolatedUserCustomFieldPieceAction, RegisterUserCustomFieldVariableAction, SelectUserCustomFieldOptionAction, UnSelectUserCustomFieldOptionAction, ReOrderUserCustomFieldOptionAction, AddUserCustomFieldOptionAction, DeleteUserCustomFieldOptionAction, UpdateUserCustomFieldOptionAction, FilterUserTableAction, FILTER_USER_TABLE } from './types';
import { INewCustomFieldData, IUpdateableCustomFieldData, INewFieldChoiceData, IUpdateableFieldChoiceData, CustomFieldDataHolder } from '../custom-fields';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { PiecePositionState } from '../flowchart/pieces/types';

export function addUser(payload: IUpdateableUserData): AddUserAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_USER,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            workflows: {},
        },
        currentTime: now,
    }
}

export function updateUserRequest(payload: IUpdateableUserData): UpdateUserRequestAction {
    return {
        type: UPDATE_USER_REQUEST,
        payload,
    }
}

export function updateUser(payload: IUpdateableUserData): UpdateUserAction {
    return {
        type: UPDATE_USER,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function deleteUser(id: string): DeleteUserAction {
    return {
        type: DELETE_USER,
        id,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}



export function updateUserWorkflows(userId: string, workflowTypeId: string, workflowId: string): UpdateUserWorkflowAction {
    return {
        type: UPDATE_USER_WORKFLOWS,
        userId,
        workflowTypeId,
        workflowId,
    }
}

export function updateUserCustomFieldData(userId: string, customFieldData: CustomFieldDataHolder): UpdateUserCustomFieldDataAction {
    return {
        type: UPDATE_USER_CUSTOM_FIELD_DATA,
        userId,
        customFieldData,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}


export function searchUserTable(searchTerm: string): SearchUserTableAction {
    return {
        type: SEARCH_USER_TABLE,
        searchTerm,
    }
}

export function filterUserTable(projects: Array<string>, levels: Array<string>, roles: Array<string>, locations: Array<string>, customFields: {[customFieldId: string]: Array<string>}): FilterUserTableAction {
    return {
        type: FILTER_USER_TABLE,
        projects,
        levels,
        roles,
        locations,
        customFields,
    }
}

export function goToPageUserTable(pageNumber: number): GoToPageUserTableAction {
    return {
        type: GO_TO_PAGE_USER_TABLE,
        pageNumber,
    }
}

export function setPageSizeUserTable(pageSize: number): SetPageSizeUserTableAction {
    return {
        type: SET_PAGE_SIZE_USER_TABLE,
        pageSize,
    }
}

export function sortUserTable(column: string, order: 'ASC'|'DESC'): SortUserTableAction {
    return {
        type: SORT_USER_TABLE,
        column,
        order,
    }
}

export function selectUserCustomField(id: string): SelectUserCustomFieldAction {
    return {
        type: SELECT_USER_CUSTOM_FIELD,
        id,
    }
}

export function unSelectUserCustomField(): UnSelectUserCustomFieldAction {
    return {
        type: UN_SELECT_USER_CUSTOM_FIELD,
    }
}

export function addUserCustomField(payload: INewCustomFieldData): AddUserCustomFieldAction {
    const now =  moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_USER_CUSTOM_FIELD,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        currentTime: now,
    }
}

export function deleteUserCustomField(id: string): DeleteUserCustomFieldAction {
    return {
        type: DELETE_USER_CUSTOM_FIELD,
        id,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function updateUserCustomField(payload: IUpdateableCustomFieldData): UpdateUserCustomFieldAction {
    return {
        type: UPDATE_USER_CUSTOM_FIELD,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function updateUserCustomFieldStartPiece(payload: PiecePositionState, customFieldId: string): UpdateUserCustomFieldStartPieceAction {
    return {
        type: UPDATE_USER_CUSTOM_FIELD_START_PIECE,
        payload,
        customFieldId
    }
}

export function setIsolatedUserCustomFieldPiece(payload: PiecePositionState, customFieldId: string): SetIsolatedUserCustomFieldPieceAction {
    return {
        type: SET_ISOLATED_USER_CUSTOM_FIELD_PIECE,
        payload,
        customFieldId
    }
}

export function removeIsolatedUserCustomFieldPiece(pieceId: string, customFieldId: string): RemoveIsolatedUserCustomFieldPieceAction {
    return {
        type: REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE,
        pieceId,
        customFieldId
    }
}

export function registerUserCustomFieldVariable(variableId: string, customFieldId: string): RegisterUserCustomFieldVariableAction {
    return {
        type: REGISTER_USER_CUSTOM_FIELD_VARIABLE,
        variableId,
        customFieldId,
    }
}


export function selectUserCustomFieldOption(id: string): SelectUserCustomFieldOptionAction {
    return {
        type: SELECT_USER_CUSTOM_FIELD_OPTION,
        id,
    }
}

export function unSelectUserCustomFieldOption(): UnSelectUserCustomFieldOptionAction {
    return {
        type: UN_SELECT_USER_CUSTOM_FIELD_OPTION,
    }
}

export function reOrderUserCustomFieldOption(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderUserCustomFieldOptionAction {
    return {
        type: RE_ORDER_USER_CUSTOM_FIELD_OPTION,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addUserCustomFieldOption(payload: INewFieldChoiceData, parentId: string): AddUserCustomFieldOptionAction {
    const now =  moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_USER_CUSTOM_FIELD_OPTION,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        parentId,
        currentTime: now,
    }
}

export function deleteUserCustomFieldOption(id: string, parentId: string): DeleteUserCustomFieldOptionAction {
    return {
        type: DELETE_USER_CUSTOM_FIELD_OPTION,
        id,
        parentId,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function updateUserCustomFieldOption(payload: IUpdateableFieldChoiceData): UpdateUserCustomFieldOptionAction {
    return {
        type: UPDATE_USER_CUSTOM_FIELD_OPTION,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}