import React from 'react';
import styles from './Sidebar.module.scss';
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { ApplicationState } from '../../shared/store/main';

import logo from '../../assets/mini-logo.svg';
import { ReactComponent as DashboardIcon } from '../../assets/navigation/dashboard.svg';
import { ReactComponent as StructureIcon } from '../../assets/navigation/structure.svg';
import { ReactComponent as UsersIcon } from '../../assets/navigation/users.svg';
import { ReactComponent as MembersIcon } from '../../assets/navigation/members.svg';
import { ReactComponent as GroupsIcon } from '../../assets/navigation/groups.svg';
import { ReactComponent as WorkflowIcon } from '../../assets/navigation/workflow.svg';
import { ReactComponent as ReportsIcon } from '../../assets/navigation/reports.svg';

import { connect } from 'react-redux';

import { translatePhrase } from '../../shared/helpers/translation';
import { Permissions } from '../../shared/helpers/permissions';


type OwnProps = {
    logo?: string,
};

const mapStateToProps = (state: ApplicationState) => {
    const canViewHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;
    const canViewLocations = state.permissions.myPermissions.general.Locations === Permissions.WRITE || state.permissions.myPermissions.general.Locations === Permissions.READ;

    const canViewUsersList = state.permissions.myPermissions.general.Users === Permissions.WRITE || state.permissions.myPermissions.general.Users === Permissions.READ;
    const canViewUserConfiguration = state.permissions.myPermissions.general.UserConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.UserConfiguration === Permissions.READ;

    const canViewMembersList = state.permissions.myPermissions.general.Members === Permissions.WRITE || state.permissions.myPermissions.general.Members === Permissions.READ;
    const canViewMembersConfiguration = state.permissions.myPermissions.general.MembersConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.MembersConfiguration === Permissions.READ;

    const canViewGroupsList = state.permissions.myPermissions.general.Groups === Permissions.WRITE || state.permissions.myPermissions.general.Groups === Permissions.READ;
    const canViewGroupsConfiguration = state.permissions.myPermissions.general.GroupsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.GroupsConfiguration === Permissions.READ;

    const canViewWorkflowsList = state.permissions.myPermissions.general.Workflows === Permissions.WRITE || state.permissions.myPermissions.general.Workflows === Permissions.READ;
    const canViewWorkflowsConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;

    return {
        structurePermissions: canViewHierarchy || canViewLocations,
        userPermissions: canViewUsersList || canViewUserConfiguration,
        memberPermissions: canViewMembersList || canViewMembersConfiguration,
        groupPermissions: canViewGroupsList || canViewGroupsConfiguration,
        workflowPermissions: canViewWorkflowsList || canViewWorkflowsConfiguration,
        myId: state.myData.id,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedSidebar: React.FC<Props> = (props) => {
    return (
        <section className={styles.SideBar} id="navigation-side-menu">
            <Link to="/structure">
                <picture className={styles.Logo}>
                    <img src={props.logo ? props.logo : logo} alt="oaas logo" />
                </picture>
            </Link>
            <nav>
                <NavLink to="/dashboard" className={styles.NavigationLink} activeClassName={styles.active}>
                    <DashboardIcon />
                    <span>{translatePhrase('Dashboard')}</span>
                </NavLink>
                {props.structurePermissions && <NavLink to="/structure" className={styles.NavigationLink} activeClassName={styles.active}>
                    <StructureIcon />
                    <span>{translatePhrase('Structure')}</span>
                </NavLink>}
                {props.userPermissions && <NavLink to="/users" className={styles.NavigationLink} activeClassName={styles.active}>
                    <UsersIcon />
                    <span>{translatePhrase('Users')}</span>
                </NavLink>}
                {props.memberPermissions && <NavLink to="/members" className={styles.NavigationLink} activeClassName={styles.active}>
                    <MembersIcon />
                    <span>{translatePhrase('Members')}</span>
                </NavLink>}
                {props.groupPermissions && <NavLink to="/groups" className={styles.NavigationLink} activeClassName={styles.active}>
                    <GroupsIcon />
                    <span>{translatePhrase('Groups')}</span>
                </NavLink>}
                {props.workflowPermissions && <NavLink to="/workflows" className={styles.NavigationLink} activeClassName={styles.active}>
                    <WorkflowIcon />
                    <span>{translatePhrase('Workflows')}</span>
                </NavLink>}
                {props.workflowPermissions && <NavLink to="/reports" className={styles.NavigationLink} activeClassName={styles.active}>
                    <ReportsIcon />
                    <span>{translatePhrase('Reports')}</span>
                </NavLink>}
            </nav>
        </section>
    );
}

const Sidebar = withRouter(connect(mapStateToProps)(ConnectedSidebar) as any);

export default Sidebar;