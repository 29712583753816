import React, { Component, ChangeEvent } from 'react';
import styles from './Transfer.module.scss';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';

import { transferWorkflow } from '../../../shared/store/workflows/actions';

import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { ILocation } from '../../../shared/store/structure/location/types';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';

type OwnProps = {
    workflowId: string,
    transferId: string,

    exitTransfer: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const transferPiece = state.flowchart.pieces.byId[ownProps.transferId];

    if (transferPiece.type !== PieceType.TRANSFER_WORKFLOW) {
        throw new Error('The ID should point to a piece of the transfer type');
    }

    if (!transferPiece.roles) {
        throw new Error('The transfer must be attached to one or more roles');
    }

    const roles = transferPiece.roles;
    let locationIds: Array<string|undefined> = state.users.byId[state.workflows.byId[ownProps.workflowId].user].locations;

    // This will store the ID of the locations of the owner, as well as the IDs of all the ancestors of that location in the location tree within this list
    const currentOwnerLocationHierarchy: Array<string> = [];

    locationIds.forEach(locationId => {

        while(typeof locationId !== 'undefined' && !(locationId in state.structure.projects.byId)) {
            const location: ILocation = state.structure.locations.byId[locationId];

            if (currentOwnerLocationHierarchy.includes(location.id)) {
                break;
            }

            currentOwnerLocationHierarchy.push(location.id);

            locationId = location.parent;
        }

    });

    let users = state.users.allEntries
    .filter(userId => {
        const user = state.users.byId[userId];
        return userId !== state.myData.id && roles.includes(user.role) && currentOwnerLocationHierarchy.some(hierarchy => user.locations.includes(hierarchy));
    })
    .map(userId => {
        return {
            value: userId,
            role: state.structure.roles.byId[state.users.byId[userId].role].name,
            locations: state.users.byId[userId].locations.map(locationId => state.structure.locations.byId[locationId].name).join(', '),
        };
    });

    if (roles.includes('previous')) {
        state.workflows.byId[ownProps.workflowId].trackingUsers.forEach(userId => {
            const userData = {
                value: userId,
                role: state.structure.roles.byId[state.users.byId[userId].role].name,
                locations: state.users.byId[userId].locations.map(locationId => state.structure.locations.byId[locationId].name).join(', '),
            };

            !users.some(user => user.value === userId) && users.push(userData);
        })
    }

    return {
        userChoices: users,
        usersData: state.users,

        applicationState: state,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        transferWorkflow: (userId: string) => dispatch(transferWorkflow(ownProps.workflowId, userId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    userInput: string,
    errorMessage: string,
    transferred: boolean,
}

class ConnectedTransfer extends Component<Props, OwnState> {
    state: OwnState = {
        userInput: '',
        errorMessage: '',
        transferred: false,
    };

    selectChoice = (value: string) => {
        this.setState({
            userInput: value,
        });
    }

    showErrorMessage = (message: string) => {
        this.setState({
            errorMessage: message,
        });

        window.setTimeout(() => {
            this.setState({
                errorMessage: '',
            });
        }, 5000);
    }

    handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            userInput: event.currentTarget.value,
        });
    }

    submitUser = () => {
        if (!this.state.userInput) {
            this.showErrorMessage('Please select a user');
            return;
        }

        this.props.transferWorkflow(this.state.userInput);

        this.setState({
            transferred: true,
        });
    }

    render() {

        if (this.state.transferred) {
            return <div key={this.props.transferId}>
                <section className={styles.transfer}>This workflow has been transferred.</section>
                <section className={styles.submit} onClick={this.props.exitTransfer}>Go back</section>
            </div>
        }

        const usersMarkup = <section className={styles.choicesList}>
            {this.props.userChoices.map(userChoice => {
                const user = this.props.usersData.byId[userChoice.value];
                let userName = user.customFields[this.props.usersData.nameFieldId];

                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, nameField, user.id, 'user');

                return (<section key={userChoice.value} className={this.state.userInput === userChoice.value ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(userChoice.value)}>
                    <div className={styles.text}>{userName}</div>
                    <div className={styles.subText}>(Role: {userChoice.role}, Locations: {userChoice.locations})</div>
                </section>)
            })}
        </section>;

        return <div key={this.props.transferId}>
            <section className={styles.transfer}>Select the user to transfer</section>
            <section className={styles.errorMessage}>{this.state.errorMessage}</section>
            {usersMarkup}
            <section className={styles.submit} onClick={this.submitUser}>Transfer</section>
        </div>
    }

}

const Transfer = connect(mapStateToProps, mapDispatchToProps)(ConnectedTransfer);

export default Transfer;