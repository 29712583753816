import { ADD_REPORT, DELETE_REPORT, UPDATE_REPORT, UPDATE_REPORT_DATA, SEARCH_REPORT_TABLE, GO_TO_PAGE_REPORT_TABLE, 
    SET_PAGE_SIZE_REPORT_TABLE, SORT_REPORT_TABLE, ReportState, ReportActionTypes, IReport, STORE_DATA_FOR_REPORT, FILTER_REPORT_TABLE } from './types';
import { addEntity, updateEntity, deleteEntity } from '../normalized-model';
import { reportTypesReducer, initialState as reportTypesInitialState } from './types/reducer';

const initialState: ReportState = {  
    byId:{},
    allEntries:[],
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    types: reportTypesInitialState,

    pageSize: 10,
    currentPageNumber: 1,

    filters: {
        types: [],
        users: [],
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: undefined,
};

export function reportsReducer(state = initialState, action: ReportActionTypes): ReportState {
    state = {
        ...state,
        types: reportTypesReducer(state.types, action),
    };
    switch(action.type) {

        case ADD_REPORT:
            return addEntity<ReportState, IReport>(state, action.payload);
        
        case UPDATE_REPORT:
            return updateEntity<ReportState, IReport>(state, action.payload);
        
        case DELETE_REPORT:
            return deleteEntity<ReportState, IReport>(state, action.id);

        case STORE_DATA_FOR_REPORT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportId]: {
                        ...state.byId[action.reportId],
                        data: action.data,
                    },
                },
            }


        case SEARCH_REPORT_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
            }

        case FILTER_REPORT_TABLE:
            return {
                ...state,
                filters: {
                    types: action.types,
                    users: action.users,
                }
            }
        
        case GO_TO_PAGE_REPORT_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }
        
        case SET_PAGE_SIZE_REPORT_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        
        case SORT_REPORT_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

            
        // Internationalization actions
        case UPDATE_REPORT_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                
                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                },
            }
        

        default:
            return state
    }
}