import React, { FC } from 'react';
import UnaryOperator, { UnaryOperatorProps } from './UnaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type GetDayOperatorProps = Omit<Omit<Omit<UnaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'isOperatorBoolean'>;

const GetDayOperator: FC<GetDayOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <UnaryOperator operatorSymbol="Get day" {...props} />
    )
}

export default GetDayOperator;