import React from 'react';
import styles from './Card.module.scss';
import { Link } from "react-router-dom";

import { CardType } from './Card';

import { ReactComponent as StructureIcon } from '../../assets/navigation/structure.svg';

import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {
    card: CardType,
    link: string,
};

export type FlowchartCardType = {
    id: string,
    name: string,
    isOn: boolean,

    onFlowchart: (id: string) => void,
}

const FlowchartCard: React.FC<OwnProps> = (props) => {
    
    return (
        <li className={styles.normal}>
            <div className={styles.details}> 
                <div className={styles.title}>{translatePhrase(props.card.name)}</div> 
            </div>
            <Link to={props.link}><div className={styles.iconHolder}><StructureIcon /></div></Link>
        </li>
    );
}

export default FlowchartCard;