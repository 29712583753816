import React, { Component } from 'react';
import styles from './FlowchartPiece.module.scss';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { Position } from '../../../shared/helpers/common-types';
import { PiecePositionState } from '../../../shared/store/flowchart/pieces/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { startPieceDrag, stopPieceDrag } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';

export interface OwnProps {
    pieceId: string,
    isDragDisabled?: boolean,
    initialPosition?: Position,
    
    onUpdatePosition?: (x: number, y: number) => void,
    detachPiece?: () => void,
    isolatePiece?: (pieceState: PiecePositionState) => void,
    removeIsolatedPiece?: (pieceId: string) => void,
};

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        draggedPiece: state.flowchart.pieces.lastDraggedPiece,
        targetPiece: state.flowchart.pieces.targetPiece,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        startPieceDrag: (pieceId: string) => dispatch(startPieceDrag(pieceId)),
        stopPieceDrag: () => dispatch(stopPieceDrag()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type State = {
    startingX: number,
    startingY: number,
}

class ConnectedFlowchartPiece extends Component<Props, State> {
    state = {
        startingX: 0,
        startingY: 0,
    };

    handleDragStart = (e: DraggableEvent, data: DraggableData) => {

        e.stopPropagation();

        this.setState({
            startingX: data.x,
            startingY: data.y,
        });

        this.props.startPieceDrag(this.props.pieceId);
    }

    handleDragEnd = (e: DraggableEvent, data: DraggableData) => {

        const deltaX = Math.abs(data.x - this.state.startingX);
        const deltaY = Math.abs(data.y - this.state.startingY);

        if (deltaX < 5 && deltaY < 5) {
            this.props.stopPieceDrag();
            return;  // Don't bother tracking changes for small movements
        }

        if (this.props.onUpdatePosition) {
            this.props.onUpdatePosition(data.x, data.y);
        }

        if (this.props.detachPiece) {
            this.props.detachPiece();
        }

        if (this.props.isolatePiece) {
            let x, y;

            if ('clientX' in e) {
                x = e.clientX - 60;  // Subtract sidebar width
            } else {
                x = data.x;
            }

            if ('clientY' in e) {
                y = e.clientY - 70;  // Subtract header height
            } else {
                y = data.y;
            }

            this.props.isolatePiece({
                piece: this.props.pieceId,
                position: {
                    x,
                    y,
                },
            });
        }

        this.props.stopPieceDrag();
    }

    render() {
        return (<Draggable cancel={'input'} defaultPosition={this.props.initialPosition} disabled={this.props.isDragDisabled} onStart={this.handleDragStart} onStop={this.handleDragEnd}>
            <div className={this.props.isDragging && this.props.draggedPiece === this.props.pieceId ? styles.draggedPiece : styles.normalPiece}>{this.props.children}</div>
        </Draggable>);
    }
    
}

const FlowchartPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedFlowchartPiece);

export default FlowchartPiece