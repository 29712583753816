import React, { FC } from 'react';
import styles from './Contact.module.scss';

type OwnProps = {
    name: string,
    time: string,
    isSelected?: boolean,
    lastMessage: string,
    unReadMessages?: number,
};

type OwnState = {};

const Contact: FC<OwnProps> = (props) => {
    return <section className={props.isSelected ? styles.selectedContact : styles.contact}>
        <div className={styles.avatarHolder}>
            <div className={styles.avatar}></div>
        </div>
        <div className={styles.textHolder}>
            <div className={styles.textRow}>
                <section className={styles.name}>{props.name}</section>
                <section className={props.unReadMessages ? styles.activeTime : styles.time}>{props.time}</section>
            </div>
            <div className={styles.textRow}>
                <section className={styles.lastMessage}>{props.lastMessage}</section>
                {props.unReadMessages && <section className={styles.unReadMessages}>{props.unReadMessages}</section>}
            </div>
        </div>
    </section>
}

export default Contact;