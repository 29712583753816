import React, { Component, ChangeEvent } from 'react';
import styles from './Flowchart.module.scss';
import { RouteComponentProps } from 'react-router';
import { Link, Redirect } from "react-router-dom";

import { Permissions } from '../../../shared/store/permissions/types';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';

import { updateWorkflowTypeCustomFieldStartPiece, setIsolatedWorkflowTypeCustomFieldPiece, removeIsolatedWorkflowTypeCustomFieldPiece, registerWorkflowTypeCustomFieldVariable } from '../../../shared/store/workflows/types/actions';
import { setNextPiece, setInnerPiece, setConditionPiece, setConditionNextPiece, setLoopVariable, setIterableVariable, setOperand, setLeftOperand, setRightOperand, setQuestionData, setMemberVariable, setDataStoreValue, setDataSetValue, setDataCopyVariable, setReturnValue, addPiece, deletePiece, setVariableForShow, setVariableForCustomField, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setLocationPiece, setPieceForList, setVariablePiece, setWorkflowAffiliationVariable, setHeading, setDate, setMonth, setYear, setMessage, addFullPiece } from '../../../shared/store/flowchart/pieces/actions';
import { FlowchartPieceActions, PiecePositionState, PieceType, AllPieceTypes } from '../../../shared/store/flowchart/pieces/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import uuid from 'uuid';

import { ApplicationState } from '../../../shared/store/main';
import { CategoryValue, copyPiece } from '../../flowchart/helpers/index';
import { piecesByCategory as customFieldPiecesByCategory, getComponentForLevelComputedField, getComponentForRoleComputedField, getComponentForUserComputedField, getComponentForMemberComputedField, getComponentForGroupComputedField, getComponentForWorkflowComputedField } from '../../flowchart/helpers/custom-field';
import { setIsolatedLevelCustomFieldPiece, removeIsolatedLevelCustomFieldPiece, registerLevelCustomFieldVariable } from '../../../shared/store/structure/level/actions';
import { setIsolatedRoleCustomFieldPiece, removeIsolatedRoleCustomFieldPiece, registerRoleCustomFieldVariable } from '../../../shared/store/structure/role/actions';
import { setIsolatedUserCustomFieldPiece, removeIsolatedUserCustomFieldPiece, registerUserCustomFieldVariable } from '../../../shared/store/users/actions';
import { setIsolatedMemberTypeCustomFieldPiece, removeIsolatedMemberTypeCustomFieldPiece, registerMemberTypeCustomFieldVariable } from '../../../shared/store/members/types/actions';
import { setIsolatedGroupTypeCustomFieldPiece, removeIsolatedGroupTypeCustomFieldPiece, registerGroupTypeCustomFieldVariable } from '../../../shared/store/groups/types/actions';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        isDragging: state.flowchart.pieces.isDragging,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        applicationState: state,

        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece,
    }
}



const mapDispatchToProps = (dispatch: Dispatch) => {
    const flowchartPieceActions: FlowchartPieceActions = {
        setNextPiece: (pieceId, value) => dispatch(setNextPiece(pieceId, value)),
        setInnerPiece: (pieceId, value) => dispatch(setInnerPiece(pieceId, value)),
        setConditionPiece: (pieceId, index, value) => dispatch(setConditionPiece(pieceId, index, value)),
        setConditionNextPiece: (pieceId, index, value) => dispatch(setConditionNextPiece(pieceId, index, value)),
        setLoopVariable: (pieceId, value) => dispatch(setLoopVariable(pieceId, value)),
        setIterableVariable: (pieceId, value) => dispatch(setIterableVariable(pieceId, value)),
        setOperand: (pieceId, value) => dispatch(setOperand(pieceId, value)),
        setLeftOperand: (pieceId, value) => dispatch(setLeftOperand(pieceId, value)),
        setRightOperand: (pieceId, value) => dispatch(setRightOperand(pieceId, value)),
        setQuestionData: (pieceId, value) => dispatch(setQuestionData(pieceId, value)),
        setMemberVariable: (pieceId, value) => dispatch(setMemberVariable(pieceId, value)),
        setRequiredPiece: (pieceId, value) => dispatch(setQuestionRequiredPiece(pieceId, value)),
        setDisabledPiece: (pieceId, value) => dispatch(setQuestionDisabledPiece(pieceId, value)),
        setDefaultPiece: (pieceId, value) => dispatch(setQuestionDefaultPiece(pieceId, value)),
        setDataStoreValue: (pieceId, value) => dispatch(setDataStoreValue(pieceId, value)),
        setDataSetValue: (pieceId, value) => dispatch(setDataSetValue(pieceId, value)),
        setDataCopyVariable: (pieceId, value) => dispatch(setDataCopyVariable(pieceId, value)),
        setDataForList: (pieceId, value) => dispatch(setPieceForList(pieceId, value)),
        setReturnVariable: (pieceId, value) => dispatch(setReturnValue(pieceId, value)),
        setLocationPiece: (pieceId, value) => dispatch(setLocationPiece(pieceId, value)),
        setVariableForShow: (pieceId, value) => dispatch(setVariableForShow(pieceId, value)),
        setVariableForCustomField: (pieceId, value) => dispatch(setVariableForCustomField(pieceId, value)),
        setVariablePiece: (pieceId, value) => dispatch(setVariablePiece(pieceId, value)),
        setAffiliationVariablePiece: (pieceId, value) => dispatch(setWorkflowAffiliationVariable(pieceId, value)),
        setHeadingPiece: (pieceId, value) => dispatch(setHeading(pieceId, value)),
        setMessage: (pieceId, value) => dispatch(setMessage(pieceId, value)),

        setDatePiece: (pieceId, value) => dispatch(setDate(pieceId, value)),
        setMonthPiece: (pieceId, value) => dispatch(setMonth(pieceId, value)),
        setYearPiece: (pieceId, value) => dispatch(setYear(pieceId, value)),

        setStartPieceData: (customFieldId: string, payload: PiecePositionState) => dispatch(updateWorkflowTypeCustomFieldStartPiece(payload, customFieldId)),
    };

    return {
        flowchartPieceActions,
        setIsolatedLevelCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedLevelCustomFieldPiece(payload, customFieldId)),
        removeIsolatedLevelCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedLevelCustomFieldPiece(pieceId, customFieldId)),
        registerLevelCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerLevelCustomFieldVariable(variableId, customFieldId)),

        setIsolatedRoleCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedRoleCustomFieldPiece(payload, customFieldId)),
        removeIsolatedRoleCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedRoleCustomFieldPiece(pieceId, customFieldId)),
        registerRoleCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerRoleCustomFieldVariable(variableId, customFieldId)),

        setIsolatedUserCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedUserCustomFieldPiece(payload, customFieldId)),
        removeIsolatedUserCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedUserCustomFieldPiece(pieceId, customFieldId)),
        registerUserCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerUserCustomFieldVariable(variableId, customFieldId)),

        setIsolatedMemberTypeCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedMemberTypeCustomFieldPiece(payload, customFieldId)),
        removeIsolatedMemberTypeCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedMemberTypeCustomFieldPiece(pieceId, customFieldId)),
        registerMemberTypeCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerMemberTypeCustomFieldVariable(variableId, customFieldId)),

        setIsolatedGroupTypeCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedGroupTypeCustomFieldPiece(payload, customFieldId)),
        removeIsolatedGroupTypeCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedGroupTypeCustomFieldPiece(pieceId, customFieldId)),
        registerGroupTypeCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerGroupTypeCustomFieldVariable(variableId, customFieldId)),

        setIsolatedWorkflowTypeCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedWorkflowTypeCustomFieldPiece(payload, customFieldId)),
        removeIsolatedWorkflowTypeCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedWorkflowTypeCustomFieldPiece(pieceId, customFieldId)),
        registerWorkflowTypeCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerWorkflowTypeCustomFieldVariable(variableId, customFieldId)),

        addPiece: (pieceId: string, pieceType: PieceType) => dispatch(addPiece(pieceId, pieceType)),
        addFullPiece: (pieceData: AllPieceTypes) => dispatch(addFullPiece(pieceData)),
        deletePiece: (pieceId: string) => dispatch(deletePiece(pieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{fieldId: string}>;

type OwnState = {
    isWaitingForDrag: boolean,

    expandedCategory: string|undefined,
    searchTerm: string,
}

class ConnectedComputedFieldFlowchart extends Component<Props, OwnState> {

    state = {
        isWaitingForDrag: false,
        expandedCategory: undefined,
        searchTerm: '',
    };

    getEntityType = (customFieldId: string) => {
        let entityType: 'level'|'role'|'user'|'member'|'group'|'workflow'|undefined;

        if (this.props.applicationState.structure.levels.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'level';
        } else if (this.props.applicationState.structure.roles.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'role';
        } else if (this.props.applicationState.users.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'user';
        } else if (this.props.applicationState.members.types.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'member';
        } else if (this.props.applicationState.groups.types.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'group';
        } else if (this.props.applicationState.workflows.types.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'workflow';
        }

        return entityType;
    }

    getCustomField = (fieldId: string) => {
        const entityType = this.getEntityType(fieldId);

        switch(entityType) {
            case 'level':
                return this.props.applicationState.structure.levels.customFields.byId[fieldId];
            case 'role':
                return this.props.applicationState.structure.roles.customFields.byId[fieldId];
            case 'user':
                return this.props.applicationState.users.customFields.byId[fieldId];
            case 'member':
                return this.props.applicationState.members.types.customFields.byId[fieldId];
            case 'group':
                return this.props.applicationState.groups.types.customFields.byId[fieldId];
            case 'workflow':
                return this.props.applicationState.workflows.types.customFields.byId[fieldId];
            default:
                throw new Error('Custom field with this ID does not exist');
        }

    }

    deletePiece = (pieceId: string) => {
        
        const customFieldId = this.props.match.params.fieldId;
        const customField = this.getCustomField(customFieldId);

        const pieceFound = !!customField.isolatedPieces.find(isolatedPiece => isolatedPiece.piece === pieceId);

        if (!pieceFound) {
            // You can only delete isolated pieces
            return;
        }

        const entityType = this.getEntityType(customFieldId);

        switch(entityType) {
            case 'level':
                this.props.removeIsolatedLevelCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'role':
                this.props.removeIsolatedRoleCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'user':
                this.props.removeIsolatedUserCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'member':
                this.props.removeIsolatedMemberTypeCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'group':
                this.props.removeIsolatedGroupTypeCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'workflow':
                this.props.removeIsolatedWorkflowTypeCustomFieldPiece(customFieldId, pieceId);
                break;
            default:
                throw new Error('Custom field with this ID does not exist');
        }
        
        this.props.deletePiece(pieceId);
    }

    copyPiece = (pieceId: string, attachedPiece: boolean = false) => {
        const pieceToCopy = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');
        
        const customFieldId = this.props.match.params.fieldId;

        if (pieceToCopy.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = copyPiece(this.props.piecesData, this.props.addFullPiece, pieceId)

        if (!attachedPiece) {
            const entityType = this.getEntityType(customFieldId);

            switch(entityType) {
                case 'level':
                    this.props.setIsolatedLevelCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'role':
                    this.props.setIsolatedRoleCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'user':
                    this.props.setIsolatedUserCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'member':
                    this.props.setIsolatedMemberTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'group':
                    this.props.setIsolatedGroupTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'workflow':
                    this.props.setIsolatedWorkflowTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                default:
                    throw new Error('Custom field with this ID does not exist');
            }
        }

        return newId;
    }

    handleKeyPress = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') {
            return;
        }
        
        switch(e.key) {
            case 'Backspace':
            case 'Delete':
                this.props.lastDraggedPiece && this.deletePiece(this.props.lastDraggedPiece);
                return;
            case 'D':
            case 'd':
                this.props.lastDraggedPiece && this.copyPiece(this.props.lastDraggedPiece);
                return;
        }
    }

    componentWillMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        // The drag event interferes with the click event. Put the change in flowchart state as the last thing in the event queue so that the click event is fired before pointer events are removed from the flowchart
        window.setTimeout(() => {
            this.setState({
                isWaitingForDrag: this.props.isDragging,
            });
        }, 500);

    }

    expandCategory = (category: string) => {
        this.setState(prevState => {
            return {
                expandedCategory: prevState.expandedCategory === category ? undefined : category,
            }
        });
    }

    addPiece = (pieceType: PieceType) => {
        const customFieldId = this.props.match.params.fieldId;
        const newId = uuid.v4();
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        this.props.addPiece(newId, pieceType);

        const entityType = this.getEntityType(customFieldId);

        switch(entityType) {
            case 'level':
                this.props.setIsolatedLevelCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'role':
                this.props.setIsolatedRoleCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'user':
                this.props.setIsolatedUserCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'member':
                this.props.setIsolatedMemberTypeCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'group':
                this.props.setIsolatedGroupTypeCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'workflow':
                this.props.setIsolatedWorkflowTypeCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            default:
                throw new Error('Custom field with this ID does not exist');
        }

        
    }

    updateSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: e.target.value,
        });
    }
        
    render() {

        if (!this.props.match) {
            return <div></div>;
        }
        
        const customFieldId = this.props.match.params.fieldId;

        if (!customFieldId) {
            return <div></div>
        }


        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        let entityType = this.getEntityType(customFieldId);

        if (typeof entityType === 'undefined') {
            return <div></div>;
        }

        let startPiece: JSX.Element | undefined;
        let isolatedPieces: Array<JSX.Element | undefined> = [];

        const customField = this.getCustomField(customFieldId);
        let backLink = '';

        switch(entityType) {
            case 'level':
                startPiece = customField.startPiece ? getComponentForLevelComputedField(customFieldId, this.props.applicationState.structure.levels, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.registerLevelCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;
    
                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForLevelComputedField(customFieldId, this.props.applicationState.structure.levels, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.registerLevelCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
        
                    return isolatedPiece;
                });

                backLink = '/structure/hierarchy';
                break;

            case 'role':
                startPiece = customField.startPiece ? getComponentForRoleComputedField(customFieldId, this.props.applicationState.structure.roles, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.registerRoleCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;
    
                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForRoleComputedField(customFieldId, this.props.applicationState.structure.roles, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.registerRoleCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
        
                    return isolatedPiece;
                });

                backLink = '/structure/hierarchy';
                break;

            case 'user':
                startPiece = customField.startPiece ? getComponentForUserComputedField(customFieldId, this.props.applicationState.users, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.registerUserCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;
    
                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForUserComputedField(customFieldId, this.props.applicationState.users, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.registerUserCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
        
                    return isolatedPiece;
                });

                backLink = '/users/configuration';
                break;
                
            case 'member':
                startPiece = customField.startPiece ? getComponentForMemberComputedField(customFieldId, this.props.applicationState.members.types, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerMemberTypeCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;
        
                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForMemberComputedField(customFieldId, this.props.applicationState.members.types, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerMemberTypeCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
        
                    return isolatedPiece;
                });

                backLink = '/members/configuration';
                break;
                
            case 'group':
                startPiece = customField.startPiece ? getComponentForGroupComputedField(customFieldId, this.props.applicationState.groups.types, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerGroupTypeCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;
        
                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForGroupComputedField(customFieldId, this.props.applicationState.groups.types, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerGroupTypeCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
        
                    return isolatedPiece;
                });

                backLink = '/groups/configuration';
                break;
            
            case 'workflow':
                startPiece = customField.startPiece ? getComponentForWorkflowComputedField(customFieldId, this.props.applicationState.workflows.types, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerWorkflowTypeCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;
        
                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForWorkflowComputedField(customFieldId, this.props.applicationState.workflows.types, this.props.piecesData, this.props.variablesData, customField.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerWorkflowTypeCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
        
                    return isolatedPiece;
                });

                backLink = '/workflows/configuration';
                break;
            default:
                break;
        }

        const piecesByCategory = customFieldPiecesByCategory;

        return (<section className={styles.FocusSpace}>
            <div id="flowchart-holder" className={(this.state.isWaitingForDrag ? styles.waitingForDragFlowchartHolder : styles.normalFlowchartHolder) + (!this.props.isWritable ? ' react-drag-disabled' : '')}>
                <h1 className={styles.flowchartHeading}>{customField.name}</h1>
                <Link to={backLink}><section className={styles.back}>{'< Back to Configuration'}</section></Link>
                {this.props.isWritable && <section className={styles.piecesCollection}>
                    <header className={styles.collectionHeader}>Click to add</header>
                    <section className={styles.searchSection}><input type="text" placeholder="Search for pieces" className={styles.searchInput} value={this.state.searchTerm} onChange={this.updateSearchTerm} /></section>
                    {Object.keys(piecesByCategory).map(category => {
                        return <section key={category}>
                            <section className={styles.categoryHeading} onClick={() => this.expandCategory(category)}>
                                <div className={styles.categoryIndicator} style={{background: (piecesByCategory as {[key : string]: CategoryValue})[category].color}}></div>
                                <div className={styles.categoryName}>{category}</div>
                                <div className={this.state.expandedCategory === category ? styles.expandedCategoryChevron : styles.categoryChevron}><ChevronDownIcon /></div>
                            </section>
                            {(!!this.state.searchTerm || this.state.expandedCategory === category) && <div>
                                {category in piecesByCategory && (piecesByCategory as {[key : string]: CategoryValue})[category].pieces.map(piece => (!!this.state.searchTerm && !piece.name.toLocaleLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())) ? undefined : <section className={styles.pieceEntry} key={piece.name} onClick={() => this.addPiece(piece.type)}>{piece.name}</section>)}
                            </div>}
                        </section>
                    })}
                </section>}
                {startPiece}
                {isolatedPieces.map((isolatedPiece, i) => <div className={styles.pieceHolder} key={i}>{isolatedPiece}</div>)}
            </div>
        </section>);
        
    }
}

const ComputedFieldFlowchart = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedComputedFieldFlowchart) as any);

export default ComputedFieldFlowchart;