import React, { Component, ChangeEvent } from 'react';
import styles from './Question.module.scss';

import DatePicker from "react-datepicker";

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';

import { updateStatus, updateProcessState, addToHistory, addWorkflow } from '../../../shared/store/workflows/actions';
import { WorkflowProcessState, IUpdateableWorkflowData, IWorkflow } from '../../../shared/store/workflows/types';

import { FieldType, CustomFieldValueType } from '../../../shared/store/custom-fields';

import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';
import InputText from '../../../widgets/form/InputText';
import mapIcon from '../../../assets/map-pin.svg';

export type QuestionProps = {
    workflowId: string,
    questionId: string,
    userInput: CustomFieldValueType,
    errorMessage?: string,
    overWrittenVariable?: string,
    overWrittenValue?: string,

    isDisabled?: boolean,
    isRequired?: boolean,
    isSectioned?: boolean,

    validateAnswer: (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => string,
    onInputChange: (value: CustomFieldValueType) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: QuestionProps) => {
    const questionPiece = state.flowchart.pieces.byId[ownProps.questionId];

    if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
        throw new Error('The ID should point to a piece of the question type');
    }

    if (!questionPiece.customFieldId) {
        throw new Error('The question must be attached to a valid custom field');
    }

    const customField = state.workflows.types.customFields.byId[questionPiece.customFieldId];

    return {
        applicationState: state,
        piecesData: state.flowchart.pieces,
        workflowData: state.workflows,
        membersData: state.members,
        questionPiece: questionPiece,
        customField,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateStatus: (workflowId: string, statusId: string) => dispatch(updateStatus(workflowId, statusId)),
        updateWorkflowProcessState: (processState: WorkflowProcessState, workflowId: string) => dispatch(updateProcessState(processState, workflowId)),
        addToHistory: (processState: WorkflowProcessState, workflowId: string) => dispatch(addToHistory(processState, workflowId)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = QuestionProps & StateProps & DispatchProps;

type OwnState = {
    questionText: string,
    allowedChoices: Array<string>,
    isDisabled: boolean,
    isRequired: boolean,
    locationKey: number,
}

class ConnectedQuestion extends Component<Props, OwnState> {

    constructor (props: Readonly<Props>) {
        super(props);

        const workflow = this.props.workflowData.byId[this.props.workflowId];
        let processState = this.getWorkflowProcessState(workflow);

        let allowedChoices: Array<string> = [];

        if (props.customField.type === FieldType.SINGLE_SELECT || props.customField.type === FieldType.MULTI_SELECT) {
            allowedChoices = props.customField.choices.filter(optionId => {
                const errorMessage = props.validateAnswer(props.questionId, props.workflowData.types.customFieldOptions.byId[optionId].name, processState);

                return !errorMessage;
            });
        }

        let expandedQuestion = this.props.questionPiece.question && isUUID(this.props.questionPiece.question) ? getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, this.props.questionPiece.question) : this.props.questionPiece.question;

        if (typeof this.props.userInput === 'undefined') {
            let defaultValue = this.props.questionPiece.default && isUUID(this.props.questionPiece.default) ? getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, this.props.questionPiece.default) : this.props.questionPiece.default;

            if (Array.isArray(defaultValue)) {

                if (defaultValue.length > 0 && Array.isArray(defaultValue[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                defaultValue = defaultValue as Array<string>;
            }

            if (typeof defaultValue !== 'undefined') {
                if (props.customField.type === FieldType.SINGLE_SELECT) {
                    defaultValue = props.customField.choices.find(optionId => {
                        const option = props.workflowData.types.customFieldOptions.byId[optionId];

                        return option.name === defaultValue;
                    });
                } else if (props.customField.type === FieldType.MULTI_SELECT) {
                    defaultValue = props.customField.choices.filter(optionId => {
                        const option = props.workflowData.types.customFieldOptions.byId[optionId];

                        if (!Array.isArray(defaultValue)) {
                            throw new Error('The default value must be an array of strings');
                        }

                        if (Array.isArray(defaultValue)) {
            
                            if (defaultValue.length > 0 && Array.isArray(defaultValue[0])) {
                                // Cannot be a multidimensional array
                                throw new Error('The value cannot be a multi-dimensional array')
                            }
            
                            defaultValue = defaultValue as Array<string>;
                        }

                        return defaultValue.includes(option.name);
                    });
                } else if (props.customField.type === FieldType.BOOLEAN) {
                    defaultValue = defaultValue ? 'Yes' : 'No';
                }

                this.props.onInputChange(defaultValue);
            }
        }

        if (expandedQuestion === '' || typeof expandedQuestion === 'undefined') {
            expandedQuestion = props.customField.name;
        }

        if (typeof expandedQuestion !== 'string') {
            throw new Error('A question value needs to be a string');
        }

        if (expandedQuestion === '') {
            expandedQuestion = props.customField.name;
        }

        let isDisabled: boolean,
            isRequired: boolean,
            answer = props.userInput;

        if (props.customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string') {
            answer = this.props.workflowData.types.customFieldOptions.byId[answer].name;
        } else if (props.customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
            answer = answer.map(optionId => this.props.workflowData.types.customFieldOptions.byId[optionId].name);
        } else if (props.customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
            answer = Number(answer);
        } else if (props.customField.type === FieldType.BOOLEAN) {
            answer = answer === 'Yes';
        }

        if (typeof props.isDisabled === 'undefined') {
            const disabledWorkflowProcessState: WorkflowProcessState = this.getWorkflowProcessState(workflow);
            isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
        } else {
            isDisabled = props.isDisabled;
        }

        if (typeof props.isRequired === 'undefined') {
            const requiredWorkflowProcessState: WorkflowProcessState = this.getWorkflowProcessState(workflow);
            isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
        } else {
            isRequired = props.isRequired;
        }

        this.state = {
            questionText: expandedQuestion,
            allowedChoices,
            isDisabled,
            isRequired,
            locationKey: 0,
        };
    }

    getCoOrdinates = () => {
        const geolocation = navigator.geolocation;

        geolocation.getCurrentPosition((position) => {
            this.handleLocationChange(`${position.coords.latitude} ${position.coords.longitude}`);
            this.setState(prevState => {
                return {
                    locationKey: prevState.locationKey + 1,
                }
            })
        });
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (props.isDisabled !== state.isDisabled || props.isRequired !== state.isRequired) {
            const workflow = props.workflowData.byId[props.workflowId];

            let isDisabled: boolean, isRequired: boolean, answer = props.userInput;

            if (props.customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string') {
                answer = props.workflowData.types.customFieldOptions.byId[answer].name;
            } else if (props.customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
                answer = answer.map(optionId => props.workflowData.types.customFieldOptions.byId[optionId].name);
            } else if (props.customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
                answer = Number(answer);
            } else if (props.customField.type === FieldType.BOOLEAN) {
                answer = answer === 'Yes';
            }

            if (typeof props.isDisabled === 'undefined') {
                let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                    customFields: workflow.history[workflow.historyIndex].customFields,
                    lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                    executionStack: workflow.history[workflow.historyIndex].executionStack,
                    forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                    variables: workflow.history[workflow.historyIndex].variables,
                    displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
                    displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
                    displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
                    displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
                    createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
                }));
    
                if (props.overWrittenVariable) {
                    processState.variables[props.overWrittenVariable] = props.overWrittenValue;
                }

                isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, processState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
            } else {
                isDisabled = props.isDisabled;
            }

            if (typeof props.isRequired === 'undefined') {
                let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                    customFields: workflow.history[workflow.historyIndex].customFields,
                    lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                    executionStack: workflow.history[workflow.historyIndex].executionStack,
                    forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                    variables: workflow.history[workflow.historyIndex].variables,
                    displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
                    displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
                    displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
                    displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
                    createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
                }));
    
                if (props.overWrittenVariable) {
                    processState.variables[props.overWrittenVariable] = props.overWrittenValue;
                }

                isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, processState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
            } else {
                isRequired = props.isRequired;
            }

            return {
                isDisabled,
                isRequired,
            };
        }

        return null;
    }

    getWorkflowProcessState = (workflow: IWorkflow) => {
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        return processState;
    }

    selectChoice = (value: string) => {
        this.props.onInputChange(value);
    }

    selectMultipleChoice = (value: string) => {
        const newValue = Array.isArray(this.props.userInput) ? this.props.userInput : [];

        if (newValue.includes(value)) {
            newValue.splice(newValue.indexOf(value), 1);
        } else {
            newValue.push(value);
        }

        this.props.onInputChange(newValue);
    }

    handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.onInputChange(event.currentTarget.value);
    }

    handlePhoneChange = (value: string) => {
        this.props.onInputChange(value);
    }

    handleLocationChange = (value: string) => {
        this.props.onInputChange(value);
    }

    handleDateChange = (value: string|undefined) => {
        this.props.onInputChange(value);
    }

    render() {
        let answerMarkup: JSX.Element;
        let customFieldValue = this.props.userInput;

        switch(this.props.customField.type) {
            case FieldType.BOOLEAN:
                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    <section className={this.props.userInput === 'Yes' ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice('Yes')}>Yes</section>
                    <section className={this.props.userInput === 'No' ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice('No')}>No</section>
                </section>;
                break;

            case FieldType.SINGLE_SELECT:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{this.props.workflowData.types.customFieldOptions.byId[choiceId].name}</section>)
                    })}
                </section>;
                break;

            case FieldType.MULTI_SELECT:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        return (<section key={choiceId} className={Array.isArray(this.props.userInput) && this.props.userInput.includes(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectMultipleChoice(choiceId)}>{this.props.workflowData.types.customFieldOptions.byId[choiceId].name}</section>)
                    })}
                </section>;
                break;
            
            case FieldType.PHONE:
                if (typeof customFieldValue === 'undefined') {
                    customFieldValue = '';
                }
                
                if (typeof customFieldValue !== 'string') {
                    throw new Error('A phone field should have a string value');
                }

                let phoneCountryCode = '+91';
                let phoneNumber = '';

                if (customFieldValue.split(' ').length > 1) {
                    phoneCountryCode = customFieldValue.split(' ')[0];
                    phoneNumber = customFieldValue.split(' ')[1];
                }
                
                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedPhoneHolder : styles.phoneHolder}>
                    <InputText options={['+91', '+1']} default={phoneCountryCode} isDisabled onChange={value => this.handlePhoneChange(`${value} ${phoneNumber}`)} />
                    <input className={styles.input} type="text" defaultValue={phoneNumber} onChange={e => this.handlePhoneChange(`${phoneCountryCode} ${e.currentTarget.value}`)} />
                </section>
                break;
            
            case FieldType.LOCATION:
                if (typeof customFieldValue === 'undefined') {
                    customFieldValue = '';
                }
                
                if (typeof customFieldValue !== 'string') {
                    throw new Error('A phone field should have a string value');
                }

                let latitude = '';
                let longitude = '';

                if (customFieldValue.split(' ').length > 1) {
                    latitude = customFieldValue.split(' ')[0];
                    longitude = customFieldValue.split(' ')[1];
                }
                
                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedLocationHolder : styles.locationHolder} key={this.state.locationKey}>
                    <input className={styles.input} type="text" defaultValue={latitude} onChange={e => this.handleLocationChange(`${e.currentTarget.value} ${longitude}`)} />
                    <input className={styles.input} type="text" defaultValue={longitude} onChange={e => this.handleLocationChange(`${latitude} ${e.currentTarget.value}`)} />
                    <img src={mapIcon} alt="Get data" className={styles.locationFetch} onClick={this.getCoOrdinates} />
                </section>
                break;
            
            case FieldType.NUMBER:
            case FieldType.TEXT:
                answerMarkup = <input className={this.props.isSectioned ? styles.sectionedInput : styles.input} type="text" onChange={this.handleInputChange} defaultValue={typeof this.props.userInput === 'string' ? this.props.userInput : ''} />
                break;
            
            case FieldType.DATE:
                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedDateHolder : styles.dateHolder}>
                    <DatePicker dateFormat="dd-MMM-yyyy" selected={typeof this.props.userInput === 'string' ? new Date(this.props.userInput) : undefined} onChange={value => this.handleDateChange(value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} />
                </section>
                break;

            case FieldType.FILE:
                answerMarkup = <input className={this.props.isSectioned ? styles.sectionedInput : styles.input} type="file" onChange={this.handleInputChange} />
                break;

            default:
                answerMarkup = <div></div>;
                break;
        }

        return <div key={this.state.questionText} id={this.props.overWrittenValue ? this.props.questionId + this.props.overWrittenValue : this.props.questionId} className={this.state.isDisabled ? styles.disabledQuestion : styles.question}>
            <section className={this.props.isSectioned ? styles.sectionedQuestionText : styles.questionText}>{this.state.questionText}{this.props.isRequired && <sup className={styles.requiredIndicator}>*</sup>}</section>
            {this.props.errorMessage && <section className={styles.errorMessage}>{this.props.errorMessage}</section>}
            {answerMarkup}
        </div>
    }

}

const Question = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestion);

export default Question;