import { Permissions, PermissionActionTypes, PermissionSet, PermissionState, UPDATE_GENERAL_PERMISSION, DEFAULT_GENERAL_PERMISSIONS, UPDATE_PERMISSION_DATA, UPDATE_MEMBER_PERMISSION, UPDATE_GROUP_PERMISSION, UPDATE_WORKFLOW_PERMISSION, UPDATE_REPORT_PERMISSION } from './types';

const initialState: PermissionState = {
    myPermissions: {
        general: {
            Hierarchy: Permissions.READ,
            Locations: Permissions.READ,
            Users: Permissions.READ,
            UserConfiguration: Permissions.READ,
            Members: Permissions.READ,
            MembersConfiguration: Permissions.READ,
            GroupsConfiguration: Permissions.READ,
            Groups: Permissions.READ,
            Workflows: Permissions.READ,
            WorkflowsConfiguration: Permissions.READ,
            OrganizationProfile: Permissions.READ,
            Languages: Permissions.READ,
            Reports: Permissions.READ,
            ReportsConfiguration: Permissions.READ,
        },
        members: {},
        groups: {},
        workflows: {},
        reports: {},
    },
    rolePermissions: {},
    updatedIds: new Set(),
};

function getOldPermissions(state: PermissionState, action: PermissionActionTypes) {
    const oldPermissions: PermissionSet = {
        general: state.rolePermissions[action.role] && state.rolePermissions[action.role].general ? state.rolePermissions[action.role].general : DEFAULT_GENERAL_PERMISSIONS,

        members: state.rolePermissions[action.role] && state.rolePermissions[action.role].members ? state.rolePermissions[action.role].members : {},
        groups: state.rolePermissions[action.role] && state.rolePermissions[action.role].groups ? state.rolePermissions[action.role].groups : {},
        workflows: state.rolePermissions[action.role] && state.rolePermissions[action.role].workflows ? state.rolePermissions[action.role].workflows : {},
        reports: state.rolePermissions[action.role] && state.rolePermissions[action.role].reports ? state.rolePermissions[action.role].reports : {},
    };

    return oldPermissions;
}

export function permissionsReducer(state = initialState, action: PermissionActionTypes) {
    let oldPermissions: PermissionSet, newPermissions: PermissionSet;

    switch(action.type) {
        case UPDATE_GENERAL_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                general: {
                    ...oldPermissions.general,
                    [action.id]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_MEMBER_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                members: {
                    ...oldPermissions.members,
                    [action.memberTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_GROUP_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                groups: {
                    ...oldPermissions.groups,
                    [action.groupTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_WORKFLOW_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                workflows: {
                    ...oldPermissions.workflows,
                    [action.workflowTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_REPORT_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                reports: {
                    ...oldPermissions.reports,
                    [action.reportTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_PERMISSION_DATA:
            return {
                ...action.data,
                updatedIds: state.updatedIds,
            }
        default:
            return state;
    }
}