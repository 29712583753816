import React, { Component } from 'react';
import styles from './Operator.module.scss';
import Input from '../../Input';
import leftArrow from '../../../../assets/flowchart/triangle-left.svg';
import rightArrow from '../../../../assets/flowchart/triangle-right.svg';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setLeftOperand, setRightOperand } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/main';
import { valuePieceSlotTarget, booleanPieceSlotTarget } from '../utilities';


export type BinaryOperatorProps = {
    leftOperandPiece?: JSX.Element,
    rightOperandPiece?: JSX.Element,
    leftOperandText?: string,
    rightOperandText?: string,

    prefixSymbol?: string,
    operatorSymbol: string,
    suffixSymbol?: string,
    isBoolean: boolean,
    areOperatorsBoolean: boolean,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setLeftOperand: (targetPieceId: string, draggedPieceId: string) => dispatch(setLeftOperand(targetPieceId, draggedPieceId)),
        setRightOperand: (targetPieceId: string, draggedPieceId: string) => dispatch(setRightOperand(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = BinaryOperatorProps & StateProps & DispatchProps & FlowchartPieceProps;

type StorePieceState = {
    isHoveringOverLeftOperand: boolean,
    isHoveringOverRightOperand: boolean,
}

class ConnectedBinaryOperator extends Component<Props, StorePieceState> {

    state = {
        isHoveringOverLeftOperand: false,
        isHoveringOverRightOperand: false,
    }

    static defaultProps = {
        isBoolean: false,
        prefixSymbol: '',
        suffixSymbol: '',
        areOperatorsBoolean: false,
    };

    handleHoverOverLeftOperand = () => {
        this.setState({
            isHoveringOverLeftOperand: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        if (this.props.areOperatorsBoolean) {
            booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
        } else {
            valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
        }
    };

    handleHoverOutOfLeftOperandPiece = () => {
        this.setState({
            isHoveringOverLeftOperand: false,
        });
    };

    handleLeftOperandValueUpdate = (value: string) => {
        this.props.setLeftOperand(this.props.pieceId, value);
    }

    handleHoverOverRightOperand = () => {
        this.setState({
            isHoveringOverRightOperand: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        if (this.props.areOperatorsBoolean) {
            booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
        } else {
            valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
        }
    };

    handleHoverOutOfRightOperandPiece = () => {
        this.setState({
            isHoveringOverRightOperand: false,
        });
    };

    handleRightOperandValueUpdate = (value: string) => {
        this.props.setRightOperand(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverLeftOperand || this.state.isHoveringOverRightOperand)) {

            if (this.state.isHoveringOverLeftOperand) {
                this.props.setLeftOperand(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverRightOperand) {
                this.props.setRightOperand(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverLeftOperand: false,
                isHoveringOverRightOperand: false,
            });
        }
    }

    render() {
        return (
            <FlowchartPiece {...this.props}>
                <div>
                    <div className={this.props.isBoolean ? styles.booleanBinaryOperator : styles.binaryOperator}>
                        {this.props.prefixSymbol && <div className={styles.operatorSymbol}>{this.props.prefixSymbol}</div>}
                        {this.props.leftOperandPiece ? this.props.leftOperandPiece : 
                            this.props.areOperatorsBoolean ? 
                                <div className={(this.state.isHoveringOverLeftOperand && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverLeftOperand} onMouseOut={this.handleHoverOutOfLeftOperandPiece}></div>
                            : 
                                <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverLeftOperand && !!this.props.targetPiece} defaultText={this.props.leftOperandText} onMouseOver={this.handleHoverOverLeftOperand} onMouseOut={this.handleHoverOutOfLeftOperandPiece} onChange={this.handleLeftOperandValueUpdate} />
                            }
                        
                        <div className={styles.operatorSymbol}>{this.props.operatorSymbol}</div>
                        
                        {this.props.rightOperandPiece ? this.props.rightOperandPiece : 
                            this.props.areOperatorsBoolean ? 
                                <div className={(this.state.isHoveringOverLeftOperand && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverRightOperand} onMouseOut={this.handleHoverOutOfRightOperandPiece}></div>
                            : 
                                <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverRightOperand && !!this.props.targetPiece} defaultText={this.props.rightOperandText} onMouseOver={this.handleHoverOverRightOperand} onMouseOut={this.handleHoverOutOfRightOperandPiece} onChange={this.handleRightOperandValueUpdate} />
                        }

                        {this.props.suffixSymbol && <div className={styles.operatorSymbol}>{this.props.suffixSymbol}</div>}

                        {this.props.isBoolean && <div className={styles.booleanLeftPiece}><img className={styles.booleanPieceImage} alt="Left piece of boolean operator" src={leftArrow} /></div>}
                        {this.props.isBoolean && <div className={styles.booleanRightPiece}><img className={styles.booleanPieceImage} alt="Right piece of boolean operator" src={rightArrow} /></div>}
                    </div>
                </div>
            </FlowchartPiece>
        )
    }
}

const BinaryOperator = connect(mapStateToProps, mapDispatchToProps)(ConnectedBinaryOperator);

export default BinaryOperator;