import React, { FC } from 'react';
import UnaryOperator, { UnaryOperatorProps } from './UnaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type PickFirstElementOperatorProps = Omit<Omit<Omit<UnaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'isOperatorBoolean'>;

const PickFirstElementOperator: FC<PickFirstElementOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <UnaryOperator operatorSymbol="first of " {...props} />
    )
}

export default PickFirstElementOperator;