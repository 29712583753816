import React, { Component } from 'react';
import styles from './DropDownList.module.scss';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';

type DropDownListProps = {
    theme?: 'indigo'|'aqua'|'pink'|'camo',
    heading?: string,
    footer?: JSX.Element,

    canGoBack?: boolean,
    dismissAfterSelection?: boolean,
    
    goBack?: () => void,
};

class DropDownList extends Component<DropDownListProps> {
    static defaultProps = {
        canGoBack: false,
        dismissAfterSelection: true,
    };

    render() {
        let themeClass;
    
        switch(this.props.theme) {
            case 'indigo':
                themeClass = styles.indigoThemedList;
                break;
            case 'aqua':
                themeClass = styles.aquaThemedList;
                break;
            case 'pink':
                themeClass = styles.pinkThemedList;
                break;
            case 'camo':
                themeClass = styles.camoThemedList;
                break;
            default:
                themeClass = styles.indigoThemedList;
                break;
        }
    
        return (
            <section className={this.props.dismissAfterSelection ? themeClass : themeClass + ' ignore-options-onclickoutside'}>
                {this.props.heading && <section className={styles.headingHolder}>
                    {this.props.goBack && <div className={styles.backButton} onClick={this.props.goBack}><ChevronDownIcon /></div>}
                    <section className={styles.heading}>{this.props.heading}</section>
                </section>}
                {this.props.children}
                {this.props.footer}
            </section>
        )

    }
}

export default DropDownList;