import { AllPieceTypes, PieceState } from '../store/flowchart/pieces/types';
import { IVariable, VariableState } from '../store/flowchart/variables/types';
import { ApplicationState } from '../store/main';
import { IProject, ProjectState } from '../store/structure/project/types';
import { ILevel, LevelState } from '../store/structure/level/types';
import { IRole, RoleState } from '../store/structure/role/types';
import { ILocation, LocationState } from '../store/structure/location/types';
import { NormalizedModel, Synchronizable } from '../store/normalized-model';
import { CustomField, CustomFieldState, FieldChoice, CustomFieldDataType, CustomFieldOptionsDataType } from '../store/custom-fields';
import { IUser, UserState } from '../store/users/types';

interface DataFragmentToPush<T> {
    created: Array<T>;
    updated: Array<T>;
    deleted: Array<string>;
}

export interface DataToPush {
    structure: {
        projects: DataFragmentToPush<IProject>,
        levels: DataFragmentToPush<ILevel>,
        roles: DataFragmentToPush<IRole>,
        locations: DataFragmentToPush<ILocation>,

        levelCustomFields: DataFragmentToPush<CustomField>,
        levelCustomFieldOptions: DataFragmentToPush<FieldChoice>,

        roleCustomFields: DataFragmentToPush<CustomField>,
        roleCustomFieldOptions: DataFragmentToPush<FieldChoice>,
    };
    users: {
        entries: DataFragmentToPush<IUser>,
        customFields: DataFragmentToPush<CustomField>,
        customFieldOptions: DataFragmentToPush<FieldChoice>,
    }
    flowchart: {
        pieces: DataFragmentToPush<AllPieceTypes>,
        variables: DataFragmentToPush<IVariable>,
    };
}

function getEmptyDataFragment<T>(): DataFragmentToPush<T> {
    return {
        created: [],
        updated: [],
        deleted: [],
    };
}

interface FetchableById<T> {
    byId: {
        [id: string]: T
    };
}

function populateChangedData<T extends FetchableById<U>, U>(state: T,
                                                            createdIds: Set<string>, updatedIds: Set<string>, deletedIds: Set<string>) {
    const dataFragment: DataFragmentToPush<U> = {
        created: [],
        updated: [],
        deleted: [],
    };

    for (const entityId of Array.from(createdIds)) {
        dataFragment.created.push(state.byId[entityId]);
    }

    for (const entityId of Array.from(updatedIds)) {
        if (!(entityId in createdIds)) {
            dataFragment.updated.push(state.byId[entityId]);
        }
    }

    dataFragment.deleted = Array.from(deletedIds);

    return dataFragment;
}

function populateChangedModel<T extends NormalizedModel<U>, U extends Synchronizable>(state: T) {
    const dataFragment = populateChangedData<T, U>(state, state.createdIds, state.updatedIds, state.deletedIds);
    return dataFragment;
}

function populateChangedCustomField<T extends CustomFieldState>(state: T) {
    const dataFragment = populateChangedData<CustomFieldDataType, CustomField>(state.customFields,
        state.createdCustomFieldIds, state.updatedCustomFieldIds, state.deletedCustomFieldIds);

    return dataFragment;
}

function populateChangedCustomFieldOption<T extends CustomFieldState>(state: T) {
    const dataFragment = populateChangedData<CustomFieldOptionsDataType, FieldChoice>(state.customFieldOptions,
        state.createdCustomFieldOptionIds, state.updatedCustomFieldOptionIds, state.deletedCustomFieldOptionIds);

    return dataFragment;
}

function hasData<T>(dataFragment: DataFragmentToPush<T>) {
    return dataFragment.created.length > 0 || dataFragment.updated.length > 0 || dataFragment.deleted.length > 0;
}

export function collectDataToPush(applicationState: ApplicationState) {

    const dataToPush: DataToPush = {
        structure: {
            projects: getEmptyDataFragment<IProject>(),
            levels: getEmptyDataFragment<ILevel>(),
            roles: getEmptyDataFragment<IRole>(),
            locations: getEmptyDataFragment<ILocation>(),

            levelCustomFields: getEmptyDataFragment<CustomField>(),
            levelCustomFieldOptions: getEmptyDataFragment<FieldChoice>(),

            roleCustomFields: getEmptyDataFragment<CustomField>(),
            roleCustomFieldOptions: getEmptyDataFragment<FieldChoice>(),
        },
        users: {
            entries: getEmptyDataFragment<IUser>(),
            customFields: getEmptyDataFragment<CustomField>(),
            customFieldOptions: getEmptyDataFragment<FieldChoice>(),
        },
        flowchart: {
            pieces: getEmptyDataFragment<AllPieceTypes>(),
            variables: getEmptyDataFragment<IVariable>(),
        },
    };

    let hasDataToPush = false;

    dataToPush.structure.projects = populateChangedModel<ProjectState, IProject>(applicationState.structure.projects);
    hasDataToPush = hasDataToPush || hasData<IProject>(dataToPush.structure.projects);


    dataToPush.structure.levels = populateChangedModel<LevelState, ILevel>(applicationState.structure.levels);
    dataToPush.structure.levelCustomFields = populateChangedCustomField<LevelState>(applicationState.structure.levels);
    dataToPush.structure.levelCustomFieldOptions = populateChangedCustomFieldOption<LevelState>(applicationState.structure.levels);

    hasDataToPush = hasDataToPush || hasData<ILevel>(dataToPush.structure.levels)
                        || hasData<CustomField>(dataToPush.structure.levelCustomFields)
                        || hasData<FieldChoice>(dataToPush.structure.levelCustomFieldOptions);


    dataToPush.structure.roles = populateChangedModel<RoleState, IRole>(applicationState.structure.roles);
    dataToPush.structure.roleCustomFields = populateChangedCustomField<RoleState>(applicationState.structure.roles);
    dataToPush.structure.roleCustomFieldOptions = populateChangedCustomFieldOption<RoleState>(applicationState.structure.roles);

    hasDataToPush = hasDataToPush || hasData<IRole>(dataToPush.structure.roles)
                        || hasData<CustomField>(dataToPush.structure.roleCustomFields)
                        || hasData<FieldChoice>(dataToPush.structure.roleCustomFieldOptions);

    dataToPush.structure.locations = populateChangedModel<LocationState, ILocation>(applicationState.structure.locations);
    hasDataToPush = hasDataToPush || hasData<ILocation>(dataToPush.structure.locations);

    
    dataToPush.users.entries = populateChangedModel<UserState, IUser>(applicationState.users);
    dataToPush.users.customFields = populateChangedCustomField<UserState>(applicationState.users);
    dataToPush.users.customFieldOptions = populateChangedCustomFieldOption<UserState>(applicationState.users);

    hasDataToPush = hasDataToPush || hasData<IUser>(dataToPush.users.entries)
                        || hasData<CustomField>(dataToPush.users.customFields)
                        || hasData<FieldChoice>(dataToPush.users.customFieldOptions);

    dataToPush.flowchart.pieces = populateChangedModel<PieceState, AllPieceTypes>(applicationState.flowchart.pieces);
    hasDataToPush = hasDataToPush || hasData<AllPieceTypes>(dataToPush.flowchart.pieces);

    dataToPush.flowchart.variables = populateChangedModel<VariableState, IVariable>(applicationState.flowchart.variables);
    hasDataToPush = hasDataToPush || hasData<IVariable>(dataToPush.flowchart.variables);

    if (hasDataToPush) {
        console.log(dataToPush);
    } else {
        console.log('No data to push');
    }
}
