import { VariableType } from '../../variables/types';

import { isUUID } from '../../../../helpers/utilities';
import { IMember } from '../../../members/types';
import { ApplicationState } from '../../../main';
import { IGroup } from '../../../groups/types';
import { IUser } from '../../../users/types';
import { IWorkflow } from '../../../workflows/types';

import { NestingData } from '../../pieces/types';
import { ILocation } from '../../../structure/location/types';
import { IProject } from '../../../structure/project/types';
import { ILevel } from '../../../structure/level/types';
import { IRole } from '../../../structure/role/types';
import { VariableValueType } from '../../types';

function getTypeForProperty(variableType: VariableType, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch(variableType) {

        case VariableType.PROJECT:
            switch(property) {
                case 'name':
                    return VariableType.TEXT;
                case 'locations':
                    return VariableType.LOCATIONS_LIST;
                default:
                    throw new Error('Unknown property on location');
            }
            
        case VariableType.LEVEL:
                switch(property) {
                    case 'name':
                        return VariableType.TEXT;
                    default:
                        throw new Error('Unknown property on location');
                }
            
        case VariableType.ROLE:
                switch(property) {
                    case 'name':
                        return VariableType.TEXT;
                    default:
                        throw new Error('Unknown property on location');
                }
                    
        case VariableType.LOCATION:
            switch(property) {
                case 'name':
                    return VariableType.TEXT;
                case 'parent':
                    return VariableType.LOCATION;
                case 'children':
                    return VariableType.LOCATIONS_LIST;
                case 'users':
                    return VariableType.USERS_LIST;
                case 'members':
                    return VariableType.MEMBERS_LIST;
                case 'groups':
                    return VariableType.GROUPS_LIST;
                default:
                    throw new Error('Unknown property on location');
            }

        case VariableType.USER:
            switch(property) {
                case 'project':
                    return VariableType.PROJECT;
                case 'level':
                    return VariableType.LEVEL;
                case 'role':
                    return VariableType.ROLE;
                case 'locations':
                    return VariableType.LOCATIONS_LIST;
                default:
                    throw new Error('Unknown property on user');
            }

        case VariableType.MEMBER:
            switch(property) {
                case 'location':
                    return VariableType.LOCATION;
                default:
                    throw new Error('Unknown property on member');
            }

        case VariableType.GROUP:
            switch(property) {
                case 'location':
                    return VariableType.LOCATION;
                case 'all_members':
                    return VariableType.MEMBERS_LIST;
                case 'representatives':
                    return VariableType.MEMBERS_LIST;
                default:
                    throw new Error('Unknown property on group');
            }

        case VariableType.WORKFLOW:
            switch(property) {
                case 'user':
                    return VariableType.USER;
                case 'triggering_workflow':
                    return VariableType.WORKFLOW;
                case 'due_date':
                    return VariableType.DATE;
                case 'member':
                    return VariableType.MEMBER;
                case 'group':
                    return VariableType.GROUP;
                default:
                    throw new Error('Unknown property on group');
            }
        default:
            throw new Error('There are no properties for the given type');
    }
}

function getProjectProperty(project: IProject, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;
    
    switch(property) {
        case 'name':
            return project.name;
        case 'locations':
            return project.locations;
        default:
            throw new Error('Unknown property on location');
    }
}

function getLevelProperty(level: ILevel, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;
    
    switch(property) {
        case 'name':
            return level.name;
        default:
            throw new Error('Unknown property on location');
    }
}

function getRoleProperty(role: IRole, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;
    
    switch(property) {
        case 'name':
            return role.name;
        default:
            throw new Error('Unknown property on location');
    }
}

function getLocationProperty(location: ILocation, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;
    
    switch(property) {
        case 'name':
            return location.name;
        case 'parent':
            if (typeof location.parent === 'undefined') {
                throw new Error('This location does not have a parent');
            }

            return location.parent;
        case 'users':
            return location.users;
        case 'members':
            return location.members;
        case 'groups':
            return location.groups;
        case 'children':
            return location.children
        default:
            throw new Error('Unknown property on location');
    }
}

function getUserProperty(user: IUser, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch(property) {
        case 'project':
            return user.project;
        case 'level':
            return user.level;
        case 'role':
            return user.role;
        case 'locations':
            return user.locations;
        default:
            throw new Error('Unknown property on user');
    }
}

function getMemberProperty(member: IMember, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;
    
    switch(property) {
        case 'location':
            return member.location;
        default:
            throw new Error('Unknown property on member');
    }
}

function getGroupProperty(group: IGroup, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch(property) {
        case 'location':
            return group.location;
        case 'representatives':
            return group.representatives;
        case 'all_members':
            return group.members;
        default:
            throw new Error('Unknown property on group');
    }
}

function getWorkflowProperty(workflow: IWorkflow, propertyData: NestingData|string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;
    
    switch(property) {
        case 'user':
            return workflow.user;
        case 'triggering_workflow':
            return workflow.triggeringWorkflow;
        case 'due_date':
            return workflow.dueDate;
        case 'member':
            return workflow.affiliatedEntity;
        case 'group':
            return workflow.affiliatedEntity;
        default:
            throw new Error('Unknown property on workflow');
    }
}

export function getAllPropertiesOfType(type: VariableType, applicationState: ApplicationState) {

    switch(type) {
        case VariableType.PROJECT:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.PROJECT, 'name'),
            }, {
                name: 'locations',
                type: getTypeForProperty(VariableType.PROJECT, 'locations'),
            }];
        case VariableType.LEVEL:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.LEVEL, 'name'),
            }];
        case VariableType.ROLE:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.ROLE, 'name'),
            }];
        case VariableType.LOCATION:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.LOCATION, 'name'),
            }, {
                name: 'parent',
                type: getTypeForProperty(VariableType.LOCATION, 'parent'),
            }, {
                name: 'children',
                type: getTypeForProperty(VariableType.LOCATION, 'children'),
            }, {
                name: 'users',
                type: getTypeForProperty(VariableType.LOCATION, 'users'),
            }, {
                name: 'members',
                type: getTypeForProperty(VariableType.LOCATION, 'members'),
            }, {
                name: 'groups',
                type: getTypeForProperty(VariableType.LOCATION, 'groups'),
            }];
        
        case VariableType.USER:
            return [{
                name: 'project',
                type: getTypeForProperty(VariableType.USER, 'project'),
            }, {
                name: 'level',
                type: getTypeForProperty(VariableType.USER, 'level'),
            }, {
                name: 'role',
                type: getTypeForProperty(VariableType.USER, 'role'),
            }, {
                name: 'locations',
                type: getTypeForProperty(VariableType.USER, 'locations'),
            }];

        case VariableType.MEMBER:
            return [{
                name: 'location',
                type: getTypeForProperty(VariableType.MEMBER, 'location'),
            }];
        case VariableType.GROUP:
            return [{
                name: 'location',
                type: getTypeForProperty(VariableType.GROUP, 'location'),
            }, {
                name: 'representatives',
                type: getTypeForProperty(VariableType.GROUP, 'representatives'),
            }, {
                name: 'all_members',
                type: getTypeForProperty(VariableType.GROUP, 'all_members'),
            }];
        case VariableType.WORKFLOW:
            return [{
                name: 'user',
                type: getTypeForProperty(VariableType.WORKFLOW, 'user'),
            }, {
                name: 'triggering_workflow',
                type: getTypeForProperty(VariableType.WORKFLOW, 'triggering_workflow'),
            }, {
                name: 'due_date',
                type: getTypeForProperty(VariableType.WORKFLOW, 'due_date'),
            }];
        default:
            throw new Error('The given type has no properties');
    }
}


// The value of a given variable may be a simple date, or string, or whatever - but sometimes it is an ID for another entity, with many levels of nesting. This function will give you the true final value for a variable
export function getTrueValueFromVariableValue(variableValue: VariableValueType, variableType: VariableType, nesting: Array<NestingData>, applicationState: ApplicationState): VariableValueType {
    const structureData = applicationState.structure;
    const usersData = applicationState.users;
    const membersData = applicationState.members;
    const groupsData = applicationState.groups;
    const workflowsData = applicationState.workflows;
    let expandedVariableValue: VariableValueType;
    let newVariableType: VariableType;

    // If there are nested values, expand them
    if (typeof variableValue === 'string' && isUUID(variableValue) && nesting.length > 0) {

        switch(variableType) {
            case VariableType.PROJECT:
                expandedVariableValue = getProjectProperty(structureData.projects.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.LEVEL:
                expandedVariableValue = getLevelProperty(structureData.levels.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.ROLE:
                expandedVariableValue = getRoleProperty(structureData.roles.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.LOCATION:
                expandedVariableValue = getLocationProperty(structureData.locations.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.USER:
                expandedVariableValue = getUserProperty(usersData.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.MEMBER:

                if (nesting.length === 1) {

                    const propertyData = nesting[0];
                    const member = membersData.byId[variableValue];

                    if (isUUID(propertyData.value)) {
                        switch(propertyData.type) {
                            case 'GROUPS_LIST':
                                return member.groups[propertyData.value] || [];
                            case 'WORKFLOWS_LIST':
                                return member.workflows[propertyData.value] || [];
                            default:
                                throw new Error('Unknown type on member');
                        }
                    } else {
                        expandedVariableValue = getMemberProperty(member, nesting[0]);
                        return expandedVariableValue;
                    }

                } else {
                    expandedVariableValue = getMemberProperty(membersData.byId[variableValue], nesting[0]);
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.GROUP:

                if (nesting.length === 1) {

                    const propertyData = nesting[0];
                    const group = groupsData.byId[variableValue];

                    if (isUUID(propertyData.value)) {
                        switch(propertyData.type) {
                            case 'WORKFLOWS_LIST':
                                return group.workflows[propertyData.value] || [];
                            default:
                                throw new Error('Unknown type on member');
                        }
                    } else {
                        expandedVariableValue = getGroupProperty(group, nesting[0]);
                        return expandedVariableValue;
                    }

                } else {
                    expandedVariableValue = getGroupProperty(groupsData.byId[variableValue], nesting[0]);
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.WORKFLOW:
                expandedVariableValue = getWorkflowProperty(workflowsData.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }


            default:
                throw new Error('ID of unknown type');
        }

    }

    if (typeof variableValue === 'string' && !isNaN(Number(variableValue)) && variableType === VariableType.NUMBER) {
        return Number(variableValue);
    }

    return variableValue;

}