import { WidgetActionTypes, IWidgetConfigurationState } from './widgets/types';

export const UPDATE_MY_DATA = 'UPDATE_MY_DATA';
export const START_DATA_PUSH = 'START_DATA_PUSH';
export const COMPLETE_DATA_PUSH = 'COMPLETE_DATA_PUSH';

export interface MyDataState {
    id: string,
    token: string,
    isPushingData: boolean,
    isLoaded: boolean,

    widgets: IWidgetConfigurationState,
}

interface IUpdateMyDataAction {
    type: typeof UPDATE_MY_DATA,
    data: MyDataState,
}

interface IStartDataPush {
    type: typeof START_DATA_PUSH,
}

interface ICompleteDataPush {
    type: typeof COMPLETE_DATA_PUSH,
}

export type MyDataActions = IUpdateMyDataAction | IStartDataPush | ICompleteDataPush | WidgetActionTypes;