import { SELECT_STATUS, UN_SELECT_STATUS, RE_ORDER_STATUSES, ADD_STATUS, DELETE_STATUS, UPDATE_STATUS, INewStatusData, IUpdateableStatusData, SelectStatusAction, UnSelectStatusAction, ReOrderStatusAction, AddStatusAction, DeleteStatusAction, UpdateStatusAction } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function selectStatus(id: string): SelectStatusAction {
    return {
        type: SELECT_STATUS,
        id,
    }
}

export function unSelectStatus(): UnSelectStatusAction {
    return {
        type: UN_SELECT_STATUS,
    }
}

export function reOrderStatuses(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderStatusAction {
    return {
        type: RE_ORDER_STATUSES,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addStatus(payload: INewStatusData, parentId: string): AddStatusAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_STATUS,
        payload: {
            id: uuidv4(),
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
        },
        parentId,
    }
}

export function deleteStatus(id: string, parentId: string): DeleteStatusAction {
    return {
        type: DELETE_STATUS,
        id,
        parentId,
    }
}

export function updateStatus(payload: IUpdateableStatusData): UpdateStatusAction {
    return {
        type: UPDATE_STATUS,
        payload,
    }
}