import React, { Component } from 'react';
import styles from './UserModify.module.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from '@khanacademy/react-multi-select';

import InputText from '../../../widgets/form/InputText';
import Button from '../../../widgets/form/Button';
import eyeIcon from '../../../assets/eye.svg';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';
import mapIcon from '../../../assets/map-pin.svg';

import { translatePhrase } from '../../../shared/helpers/translation';
import { FieldType, CustomFieldOptionsDataType, CustomFieldDataType } from '../../../shared/store/custom-fields';

import { ApplicationState } from '../../../shared/store/main';
import { IUpdateableUserData } from '../../../shared/store/users/types';

import { connect } from 'react-redux';
import uuid from 'uuid';

type OwnProps = {
    userId?: string,

    submit: (userData: IUpdateableUserData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries,
        allAllowedLevels = state.structure.levels.allEntries;
    
    let allAllowedLocations: Array<string> = Object.keys(state.structure.locations.byId);

    return {
        user: ownProps.userId ? state.users.byId[ownProps.userId] : undefined,
        usersData: state.users,
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        customFieldsData: state.users.customFields,
        customFieldOptionsData: state.users.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        languagesData: state.internationalization.languages,
        languages: state.internationalization.languages.allEntries.map(languageId => {
            return {
                name: state.internationalization.languages.byId[languageId].name,
                value: languageId,
            }
        }).concat([{name: 'English', value: '0'}]),

        allowedData: {
            projects: allAllowedProjects,
            levels: allAllowedLevels,
            locations: allAllowedLocations,
        }
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;


type OwnState = {
    userData: IUpdateableUserData,
    submitTimer: number|undefined,
    locationKey: number,
    errorMessage: string,
};

class ConnectedUserModify extends Component<Props, OwnState> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        let userData: IUpdateableUserData;
        if (!props.user) {
            // This is a new user
            userData = {
                id: uuid.v4(),
                project: '',
                level: '',
                role: '',
                locations: [],
                isAppUser: true,
                phone: {
                    countryCode: '+91',
                    number: '',
                },
                language: '',
                password: '',
                customFields: {}
            };
        } else {
            userData = {
                id: props.user.id,
                project: props.user.project,
                level: props.user.level,
                role: props.user.role,
                locations: props.user.locations,
                isAppUser: props.user.isAppUser,
                phone: {
                    countryCode: props.user.phone.countryCode,
                    number: props.user.phone.number,
                },
                language: props.user.language,
                password: props.user.password,
                customFields: JSON.parse(JSON.stringify(props.user.customFields)),
            }
        }
        
        this.state = {
            userData: userData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: ''
        };
    }
    
    changeProject = (project: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            project: project,
            level: '',
            role: '',
            locations: [],
        };
        
        this.setState({
            userData: updatedIUpdateableUserData,
        });
    }
    
    changeLevel = (level: string) => {
        const allLevelsInOrder = this.props.projectsData.byId[this.state.userData.project].children;
        const isLowestLevel = allLevelsInOrder.findIndex(levelId => levelId === level) === allLevelsInOrder.length - 1;

        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            level: level,
            role: '',
            locations: [],
            isAppUser: isLowestLevel,
        };
        
        this.setState({
            userData: updatedIUpdateableUserData,
        });
    }
    
    changeRole = (role: string) => {
        
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            role: role,
            locations: [],
        };
        
        this.setState({
            userData: updatedIUpdateableUserData,
        });
    }
    
    changeLocations = (locations: Array<string>) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            locations: locations
        };
        
        this.setState({
            userData: updatedIUpdateableUserData
        });
    }
    
    changePhoneCountryCode = (phoneCountryCode: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            phone: {
                ...this.state.userData.phone,
                countryCode: phoneCountryCode
            }
        };
        
        this.setState({
            userData: updatedIUpdateableUserData
        });
    }
    
    changePhoneNumber = (phoneNumber: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            phone: {
                ...this.state.userData.phone,
                number: phoneNumber,
            }
        };
        
        this.setState({
            userData: updatedIUpdateableUserData
        });
    }
    
    changeLanguage = (language: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            language: language
        };
        
        this.setState({
            userData: updatedIUpdateableUserData
        });
    }
    
    changePassword = (password: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            password: password
        };
        
        this.setState({
            userData: updatedIUpdateableUserData
        });
    }
    
    showErrorMessage = (message: string) => {
        let that = this
        
        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 5000);
        
    }
    
    validateIUpdateableUserData = () => {
        
        if (!this.state.userData.project) {
            this.showErrorMessage('Select a project');
            return;
        }
        
        if (!this.state.userData.level) {
            this.showErrorMessage('Select a level');
            return;
        }
        
        if (!this.state.userData.role) {
            this.showErrorMessage('Select a role');
            return;
        }
        
        if (this.state.userData.locations.length === 0) {
            this.showErrorMessage('Select a location');
            return;
        }
        
        if (!this.state.userData.phone.number) {
            this.showErrorMessage('Enter a phone number');
            return;
        }
        
        if (this.state.userData.phone.number.length !== 10) {
            this.showErrorMessage('Enter a phone number with 10 digits');
            return;
        }
        
        // TODO put these in a saga

        // if (!this.state.userData.id && this.props.usersList.filter(user => !user.archived).find(user => user.phoneCountryCode === this.state.userData.phoneCountryCode && user.phoneNumber === this.state.userData.phoneNumber)) {
        //     this.showErrorMessage('This phone number is already being used');
        //     return;
        // }
        
        // if (!this.state.userData.id && this.state.userData.email && this.props.usersList.filter(user => !user.archived).find(user => user.email === this.state.userData.email)) {
        //     this.showErrorMessage('This email is already being used');
        //     return;
        // }
        
        if (!this.state.userData.password) {
            this.showErrorMessage('Enter a password');
            return;
        }

        for (let i = 0; i < this.props.customFieldsData.allFields.length; i += 1) {
            const fieldId = this.props.customFieldsData.allFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.userData.customFields[fieldId] && !Array.isArray(this.state.userData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.userData.customFields[fieldId] && Array.isArray(this.state.userData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.userData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (phoneNumber.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.userData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }

            if (field.isUnique) {
                for (let j = 0; j < this.props.usersData.allEntries.length; j ++) {
                    const userId = this.props.usersData.allEntries[j];
                    if (userId !== this.state.userData.id && this.props.usersData.byId[userId].customFields[fieldId] === this.state.userData.customFields[fieldId]) {
                        this.showErrorMessage(field.name + ' must be unique. There is another entry with the same value');
                        return;
                    }
                }
            }
        };
        
        return true
    }
    
    submitUserForm = () => {
        if (this.validateIUpdateableUserData()) {
            this.markForSubmit();
            
        }
    }
    
    markForSubmit = () => {
        let that = this;
        
        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.userData);
        }, 1000);
        
        this.setState({
            submitTimer: timeout
        });
    }

    getLevelsOfProject = () => {

        if (!this.state.userData.project) {
            return [];
        }

        const allLevelsInProject = this.props.projectsData.byId[this.state.userData.project].children;
        const allAllowedLevels = allLevelsInProject.filter(levelId => this.props.allowedData.levels.includes(levelId));

        const allowedLevelsData = allAllowedLevels.map(levelId => {
            return {
                name: translatePhrase(this.props.levelsData.byId[levelId].name),
                value: levelId,
            };
        });

        return allowedLevelsData;
    }

    getRolesOfLevel = () => {

        if (!this.state.userData.level) {
            return [];
        }

        const allRolesInLevel = this.props.levelsData.byId[this.state.userData.level].children;

        const allowedRolesData = allRolesInLevel.map(roleId => {
            return {
                name: translatePhrase(this.props.rolesData.byId[roleId].name),
                value: roleId,
            };
        });

        return allowedRolesData;
    }
    
    getLocationsAtLevel = () => {

        if (!this.state.userData.project || !this.state.userData.level) {
            return [];
        }

        const index = this.props.projectsData.byId[this.state.userData.project].children.findIndex(levelId => levelId === this.state.userData.level);
        
        let locations = this.props.locationsData.byProject[this.state.userData.project].map(locationId => this.props.locationsData.byId[locationId]);
        
        for (let i = 0; i < index; i += 1) {
            let newLocations: Array<string> = [];
            
            locations.forEach(location => {
                if (location.children && location.children.length > 0) {
                    newLocations = newLocations.concat(location.children);
                }
            });
            
            locations = newLocations.map(locationId => this.props.locationsData.byId[locationId]);
        }
        
        let allowedLocations = locations.filter(location => this.props.allowedData.locations.includes(location.id));

        const locationsData = allowedLocations.map(location => {
            return {
                name: translatePhrase(location.name),
                value: location.id,
            };
        });
        
        return locationsData;
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            customFields: {
                ...this.state.userData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };
        
        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    getLocationForField = (fieldId: string) => {
        const geolocation = navigator.geolocation,
              locationKey = this.state.locationKey;

        geolocation.getCurrentPosition((position) => {
            let updatedIUpdateableUserData: IUpdateableUserData = {
                ...this.state.userData,
                customFields: {
                    ...this.state.userData.customFields,
                    [fieldId]: `${position.coords.latitude} ${position.coords.longitude}`
                }
            };
            
            this.setState({
                userData: updatedIUpdateableUserData,
                locationKey: locationKey + 2
            })
        });
    }

    changeRoleCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.roleCustomFieldsData.byId[fieldId];

        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            customFields: {
                ...this.state.userData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };
        
        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    getMarkupForCustomField = (fieldId: string, customFieldsData: CustomFieldDataType, customFieldOptionsData: CustomFieldOptionsDataType, changeField: (fieldId: string, value: string | string[] | undefined | boolean) => void) => {
        const field = customFieldsData.byId[fieldId];
        const choices = field.choices.map(choiceId => {
            return {
                name: translatePhrase(customFieldOptionsData.byId[choiceId].name),
                value: choiceId,
            }
        });
        
        let customFieldValue = this.state.userData.customFields[fieldId];

        if (field.isComputed) {
            return undefined;
        }
        
        switch(field.type) {
            case FieldType.SINGLE_SELECT:
                if (Array.isArray(customFieldValue)) {
                    throw new Error('A single select field should not have an array value.');
                }

                if (typeof customFieldValue === 'boolean') {
                    throw new Error('A single select field should not have a boolean value.');
                }

                return <div key={field.id} className={styles.inputSegment}>
                    <InputText placeholder={field.name} onChange={value => changeField(field.id, value)} default={this.props.user && customFieldValue ? translatePhrase(customFieldOptionsData.byId[customFieldValue].name) : ''} options={choices} />
                </div>
            
            case FieldType.NUMBER:
                if (Array.isArray(customFieldValue)) {
                    throw new Error('A text field should not have an array value.');
                }

                if (typeof customFieldValue === 'boolean') {
                    throw new Error('A number field should not have a boolean value.');
                }

                return <div key={field.id} className={styles.inputSegment}>
                    <InputText placeholder={field.name} type="number" onChange={value => changeField(field.id, value)} default={this.props.user && customFieldValue ? String(customFieldValue): ''} />
                </div>
            
            case FieldType.TEXT:
                if (Array.isArray(customFieldValue)) {
                    throw new Error('A text field should not have an array value.');
                }

                if (typeof customFieldValue === 'boolean') {
                    throw new Error('A text field should not have a boolean value.');
                }

                return <div key={field.id} className={styles.inputSegment}>
                    <InputText placeholder={field.name} onChange={value => changeField(field.id, value)} default={this.props.user && customFieldValue ? String(customFieldValue): ''} />
                </div>
            
            case FieldType.BOOLEAN:
                const binaryOptions = [{
                    name: translatePhrase('Yes'),
                    value: 'Yes',
                }, {
                    name: translatePhrase('No'),
                    value: 'No',
                }];
                        
                if (typeof customFieldValue !== 'boolean' && typeof customFieldValue !== 'undefined') {
                    if (String(customFieldValue).toLocaleLowerCase() === 'yes') {
                        customFieldValue = true;
                    } else if (String(customFieldValue).toLocaleLowerCase() === 'no') {
                        customFieldValue = false;
                    } else {
                        throw new Error('A boolean field should have a boolean value.');
                    }
                }

                return <div key={field.id} className={styles.inputSegment}>
                    <InputText placeholder={field.name} onChange={value => changeField(field.id, value === 'Yes')} default={this.props.user ? customFieldValue ? 'Yes' : 'No' : ''} options={binaryOptions} />
                </div>
            
            case FieldType.DATE:
                if (Array.isArray(customFieldValue)) {
                    throw new Error('A date field should not have an array value.');
                }

                if (typeof customFieldValue === 'boolean') {
                    throw new Error('A date field should not have a boolean value.');
                }

                return <div key={field.id} className={styles.inputSegment}>
                    <div className={styles.label}>{field.name}</div>
                    <DatePicker selected={customFieldValue ? new Date(customFieldValue) : undefined} dateFormat="dd-MMM-yyyy" onChange={value => changeField(field.id, value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} /> 
                </div>
            
            case FieldType.MULTI_SELECT:
                if (typeof customFieldValue === 'string') {
                    throw new Error('A multi select field should have an array value');
                }

                const multiSelectChoices = choices.map(choice => {
                    return {
                        label: translatePhrase(choice.name), 
                        value: choice.value,
                    };
                });

                return <div key={field.id} className={styles.inputSegment}>
                    <MultiSelect
                        options={multiSelectChoices}
                        onSelectedChanged={changeField.bind(this, field.id)}
                        selected={customFieldValue ? customFieldValue : []}
                        overrideStrings={{
                            selectSomeItems: `${translatePhrase('Select all')}: ${translatePhrase(field.name)}`,
                            allItemsAreSelected: `${translatePhrase('All selected')}: ${translatePhrase(field.name)}`,
                        }}
                    />
                </div>

            case FieldType.PHONE:
                if (typeof customFieldValue === 'undefined') {
                    customFieldValue = '';
                }
                
                if (typeof customFieldValue !== 'string') {
                    throw new Error('A phone field should have a string value');
                }

                let phoneCountryCode = '+91';
                let phoneNumber = '';

                if (customFieldValue.split(' ').length > 1) {
                    phoneCountryCode = customFieldValue.split(' ')[0];
                    phoneNumber = customFieldValue.split(' ')[1];
                }

                return  <div className={styles.phoneSegment}>
                    <InputText icon={chevronIcon} default={phoneCountryCode} options={['+91', '+1']} isSearchDisabled onChange={value => changeField(field.id, `${value} ${phoneNumber}`)} />
                    <InputText placeholder={field.name} type="tel" default={phoneNumber} onChange={value => changeField(field.id, `${phoneCountryCode} ${value}`)} />
                </div>

            case FieldType.LOCATION:
                if (typeof customFieldValue === 'undefined') {
                    customFieldValue = '';
                }
                
                if (typeof customFieldValue !== 'string') {
                    throw new Error('A phone field should have a string value');
                }

                let latitude = '';
                let longitude = '';

                if (customFieldValue.split(' ').length > 1) {
                    latitude = customFieldValue.split(' ')[0];
                    longitude = customFieldValue.split(' ')[1];
                }

                return  <div className={styles.geoLocationSegment}>
                    <InputText placeholder={`${translatePhrase(field.name)} ${translatePhrase('latitude')}`} type="number" key={this.state.locationKey} default={latitude} onChange={value => changeField(field.id, `${value} ${longitude}`)} />
                    <InputText placeholder={`${translatePhrase(field.name)} ${translatePhrase('longitude')}`} type="number" key={this.state.locationKey + 1} default={longitude} onChange={value => changeField(field.id, `${latitude} ${value}`)} />
                    <img src={mapIcon} onClick={this.getLocationForField.bind(this, fieldId)} alt="Location selector"/>
                </div>

        }

        return null;
    }
    
    render() {

        const customFields = this.props.customFieldsData.allFields.map(fieldId => this.getMarkupForCustomField(fieldId, this.props.customFieldsData, this.props.customFieldOptionsData, this.changeCustomField));
        let roleCustomFields: Array<JSX.Element|null|undefined> = [];

        if (this.state.userData.role) {
            const role = this.props.rolesData.byId[this.state.userData.role];

            roleCustomFields = role.customFields.map(fieldId => this.getMarkupForCustomField(fieldId, this.props.roleCustomFieldsData, this.props.roleCustomFieldOptionsData, this.changeRoleCustomField));
        }

        const projectsList = this.props.allowedData.projects.map(projectId => {
            return {
                name: translatePhrase(this.props.projectsData.byId[projectId].name),
                value: projectId,
            };
        });

        const levelsList = this.getLevelsOfProject();
        const rolesList = this.getRolesOfLevel();
        const locationsList = this.getLocationsAtLevel().map(location => {
            return {
                label: location.name,
                value: location.value,
            };
        });
        
        return (
            <section className={styles.modifyUser}>
                <header>
                    <h2 className={styles.formHeading}>{this.props.user ? translatePhrase('Edit User') : translatePhrase('Add User')} </h2>
                    <button className={styles.cancelButton} onClick={this.props.cancel}><CancelIcon /> Cancel</button>
                </header>
                <section className={styles.errorMessage}>{this.state.errorMessage}</section>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Project" icon={chevronIcon} default={this.props.user ? translatePhrase(this.props.projectsData.byId[this.props.user.project].name) : ''} options={projectsList} onChange={this.changeProject} />
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Level" isDisabled={!this.state.userData.project} key={this.state.userData.project} icon={chevronIcon} default={this.props.user ? translatePhrase(this.props.levelsData.byId[this.props.user.level].name) : ''} options={levelsList} onChange={this.changeLevel} />
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Role" isDisabled={!this.state.userData.level} key={this.state.userData.level} icon={chevronIcon} default={this.props.user ? translatePhrase(this.props.rolesData.byId[this.props.user.role].name) : ''} options={rolesList} onChange={this.changeRole} />
                    </div>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={locationsList}
                            onSelectedChanged={this.changeLocations}
                            selected={this.state.userData.locations}
                            overrideStrings={{
                                selectSomeItems: `${translatePhrase('Select all')}: ${translatePhrase('Locations')}`,
                                allItemsAreSelected: `${translatePhrase('All selected')}: ${translatePhrase('Locations')}`,
                            }}
                        />
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Language" icon={chevronIcon} default={this.props.user ? this.props.user.language === '0' ? 'English' : this.props.languagesData.byId[this.props.user.language].name : ''} onChange={this.changeLanguage} options={this.props.languages} />
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Password" icon={eyeIcon} type="password" default={this.props.user ? this.props.user.password : ''} onChange={this.changePassword} />
                    </div>
                    <div className={styles.phoneSegment}>
                        <InputText icon={chevronIcon} default={this.props.user ? this.props.user.phone.countryCode : '+91'} options={['+91', '+1']} isSearchDisabled onChange={this.changePhoneCountryCode} />
                        <InputText placeholder="Phone" type="tel" default={this.props.user ? this.props.user.phone.number : ''} onChange={this.changePhoneNumber} />
                    </div>

                    {customFields}
                    {roleCustomFields}
                    
                </div>
                    
                <div className={styles.buttonHolder}>
                    {this.state.submitTimer ? <button className={styles.confirmFormButton}>{this.props.user ? translatePhrase('Updated User') : translatePhrase('Added User')}</button> : <Button text={this.props.user ? translatePhrase('Update User') : translatePhrase('Add User')} onClick={this.submitUserForm} />}
                </div>
                
            </section>
        );
    }
}

const UserModify = connect(mapStateToProps)(ConnectedUserModify);

export default UserModify;