import { ADD_GROUP, UPDATE_GROUP, DELETE_GROUP, SEARCH_GROUP_TABLE, GO_TO_PAGE_GROUP_TABLE, SET_PAGE_SIZE_GROUP_TABLE, SORT_GROUP_TABLE, IUpdateableGroupData, UPDATE_GROUP_WORKFLOWS, UPDATE_GROUP_CUSTOM_FIELD_DATA, SET_MEMBERS_FOR_GROUP_REQUEST, SET_MEMBERS_FOR_GROUP, UPDATE_GROUP_REQUEST, ADD_MEMBER_TO_GROUP, REMOVE_MEMBER_FROM_GROUP, FilterGroupTableAction, FILTER_GROUP_TABLE, AddGroupAction, UpdateGroupRequestAction, UpdateGroupAction, AddMemberToGroupAction, RemoveMemberFromGroupAction, UpdateGroupWorkflowsAction, UpdateGroupCustomFieldDataAction, SetMembersForGroupRequestAction, SetMembersForGroupAction, DeleteGroupAction, SearchGroupTableAction, GoToPageGroupTableAction, SetPageSizeGroupTableAction, SortGroupTableAction } from './types';
import moment from 'moment';
import { CustomFieldDataHolder } from '../custom-fields';

export function addGroup(payload: IUpdateableGroupData): AddGroupAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_GROUP,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            workflows: {},
        },
    }
}

export function updateGroupRequest(payload: IUpdateableGroupData): UpdateGroupRequestAction {
    return {
        type: UPDATE_GROUP_REQUEST,
        payload,
    }
}

export function updateGroup(payload: IUpdateableGroupData): UpdateGroupAction {
    return {
        type: UPDATE_GROUP,
        payload,
    }
}

export function addMemberToGroup(groupId: string, memberId: string): AddMemberToGroupAction {
    return {
        type: ADD_MEMBER_TO_GROUP,
        groupId,
        memberId,
    }
}

export function removeMemberFromGroup(groupId: string, memberId: string): RemoveMemberFromGroupAction {
    return {
        type: REMOVE_MEMBER_FROM_GROUP,
        groupId,
        memberId,
    }
}

export function updateGroupWorkflows(groupId: string, workflowTypeId: string, workflowId: string): UpdateGroupWorkflowsAction {
    return {
        type: UPDATE_GROUP_WORKFLOWS,
        groupId,
        workflowTypeId,
        workflowId,
    }
}

export function updateGroupCustomFieldData(groupId: string, customFieldData: CustomFieldDataHolder): UpdateGroupCustomFieldDataAction {
    return {
        type: UPDATE_GROUP_CUSTOM_FIELD_DATA,
        groupId,
        customFieldData,
    }
}

export function setMembersForGroupRequest(groupId: string, memberTypes: 'representatives'|'all_members', memberIds: Array<string>): SetMembersForGroupRequestAction {
    return {
        type: SET_MEMBERS_FOR_GROUP_REQUEST,
        groupId,
        memberTypes,
        memberIds,
    }
}

export function setMembersForGroup(groupId: string, memberTypes: 'representatives'|'all_members', memberIds: Array<string>): SetMembersForGroupAction {
    return {
        type: SET_MEMBERS_FOR_GROUP,
        groupId,
        memberTypes,
        memberIds,
    }
}

export function deleteGroup(id: string): DeleteGroupAction {
    return {
        type: DELETE_GROUP,
        id,
    }
}

export function searchGroupTable(searchTerm: string): SearchGroupTableAction {
    return {
        type: SEARCH_GROUP_TABLE,
        searchTerm,
    }
}

export function filterGroupTable(types: Array<string>, locations: Array<string>, customFields: {[customFieldId: string]: Array<string>}): FilterGroupTableAction {
    return {
        type: FILTER_GROUP_TABLE,
        types,
        locations,
        customFields,
    }
}

export function goToPageGroupTable(pageNumber: number): GoToPageGroupTableAction {
    return {
        type: GO_TO_PAGE_GROUP_TABLE,
        pageNumber,
    }
}

export function setPageSizeGroupTable(pageSize: number): SetPageSizeGroupTableAction {
    return {
        type: SET_PAGE_SIZE_GROUP_TABLE,
        pageSize,
    }
}

export function sortGroupTable(column: string, order: 'ASC'|'DESC'): SortGroupTableAction {
    return {
        type: SORT_GROUP_TABLE,
        column,
        order,
    }
}