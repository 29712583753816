import React, { Component } from 'react';
import styles from './GroupModify.module.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from '@khanacademy/react-multi-select';

import InputText from '../../../widgets/form/InputText';
import Button from '../../../widgets/form/Button';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';
import mapIcon from '../../../assets/map-pin.svg';

import { translatePhrase } from '../../../shared/helpers/translation';
import { FieldType, getReadableDataForCustomField } from '../../../shared/store/custom-fields';

import { ApplicationState } from '../../../shared/store/main';
import { IUpdateableGroupData } from '../../../shared/store/groups/types';

import { connect } from 'react-redux';
import uuid from 'uuid';

type OwnProps = {
    groupId?: string,

    submit: (groupData: IUpdateableGroupData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    
    let allAllowedLocations: Array<string> = Object.keys(state.structure.locations.byId);

    return {
        group: ownProps.groupId ? state.groups.byId[ownProps.groupId] : undefined,
        groupsData: state.groups,

        applicationState: state,
        
        projectsData: state.structure.projects,
        locationsData: state.structure.locations,
        memberData: state.members,
        groupTypesData: state.groups.types,
        customFieldsData: state.groups.types.customFields,
        customFieldOptionsData: state.groups.types.customFieldOptions,

        allowedData: {
            locations: allAllowedLocations,
        }
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;


type OwnState = {
    groupData: IUpdateableGroupData,
    submitTimer: number|undefined,
    locationKey: number,
    errorMessage: string,
};

class ConnectedGroupModify extends Component<Props, OwnState> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        let groupData: IUpdateableGroupData;
        if (!props.group) {
            // This is a new group
            groupData = {
                id: uuid.v4(),
                type: '',
                location: '',
                members: [],
                representatives: [],
                customFields: {}
            };
        } else {
            groupData = {
                id: props.group.id,
                type: props.group.type,
                location: props.group.location,
                members: props.group.members,
                representatives: props.group.representatives,
                customFields: JSON.parse(JSON.stringify(props.group.customFields)),
            }
        }
        
        this.state = {
            groupData: groupData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: ''
        };
    }
    
    changeType = (type: string) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            type: type
        };
        
        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }
    
    changeLocation = (user: string) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            location: user
        };
        
        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }
    
    changeMembers = (members: Array<string>) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            members: members
        };
        
        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }
    
    changeRepresentatives = (representatives: Array<string>) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            representatives: representatives
        };
        
        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }
    
    showErrorMessage = (message: string) => {
        let that = this
        
        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 5000);
        
    }
    
    getLocationsAtLevel = () => {

        if (!this.state.groupData.type) {
            return [];
        }

        const groupType = this.props.groupTypesData.byId[this.state.groupData.type];

        const index = this.props.projectsData.byId[groupType.project].children.findIndex(levelId => levelId === groupType.level);
        
        let locations = this.props.locationsData.byProject[groupType.project].map(locationId => this.props.locationsData.byId[locationId]);
        
        for (let i = 0; i < index; i += 1) {
            let newLocations: Array<string> = [];
            
            locations.forEach(location => {
                if (location.children && location.children.length > 0) {
                    newLocations = newLocations.concat(location.children);
                }
            });
            
            locations = newLocations.map(locationId => this.props.locationsData.byId[locationId]);
        }
        
        let allowedLocations = locations.filter(location => this.props.allowedData.locations.includes(location.id));

        const locationsData = allowedLocations.map(location => {
            return {
                name: location.name,
                value: location.id,
            };
        });
        
        return locationsData;
    }
    
    validateIUpdateableGroupData = () => {
        
        if (!this.state.groupData.type) {
            this.showErrorMessage('Select a valid type');
            return;
        }
        
        if (!this.state.groupData.location) {
            this.showErrorMessage('Select a valid user');
            return;
        }

        for (let i = 0; i < this.props.groupTypesData.byId[this.state.groupData.type].customFields.length; i += 1) {
            const fieldId = this.props.groupTypesData.byId[this.state.groupData.type].customFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.groupData.customFields[fieldId] && !Array.isArray(this.state.groupData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.groupData.customFields[fieldId] && Array.isArray(this.state.groupData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.groupData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (phoneNumber.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.groupData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }

            if (field.isUnique) {
                for (let j = 0; j < this.props.groupsData.allEntries.length; j ++) {
                    const groupId = this.props.groupsData.allEntries[j];
                    if (groupId !== this.state.groupData.id && this.props.groupsData.byId[groupId].customFields[fieldId] === this.state.groupData.customFields[fieldId]) {
                        this.showErrorMessage(field.name + ' must be unique. There is another entry with the same value');
                        return;
                    }
                }
            }
        };
        
        return true
    }
    
    submitGroupForm = () => {
        if (this.validateIUpdateableGroupData()) {
            this.markForSubmit();
            
        }
    }
    
    markForSubmit = () => {
        let that = this;
        
        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.groupData);
        }, 1000);
        
        this.setState({
            submitTimer: timeout
        });
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            customFields: {
                ...this.state.groupData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };
        
        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }

    getLocationForField = (fieldId: string) => {
        const geolocation = navigator.geolocation,
              locationKey = this.state.locationKey;

        geolocation.getCurrentPosition((position) => {
            let updatedIUpdateableGroupData: IUpdateableGroupData = {
                ...this.state.groupData,
                customFields: {
                    ...this.state.groupData.customFields,
                    [fieldId]: `${position.coords.latitude} ${position.coords.longitude}`
                }
            };
            
            this.setState({
                groupData: updatedIUpdateableGroupData,
                locationKey: locationKey + 2
            })
        });
    }
    
    render() {

        const typesList = this.props.groupTypesData.allEntries.map(typeId => {
            return {
                name: this.props.groupTypesData.byId[typeId].name,
                value: typeId,
            };
        });

        let membersList: Array<{label: string, value: string}> = [];
        const locationsList = this.getLocationsAtLevel();

        if (this.state.groupData.type) {
            const groupType = this.props.groupTypesData.byId[this.state.groupData.type];

            const allowedMemberTypes = this.props.memberData.types.allEntries.filter(typeId => {
                const memberType = this.props.memberData.types.byId[typeId];

                return memberType.project === groupType.project;
            });

            membersList = this.props.memberData.allEntries
            .filter(memberId => {
                const memberData = this.props.memberData.byId[memberId];

                return allowedMemberTypes.includes(memberData.type);
            })
            .map(memberId => {
                const member = this.props.memberData.byId[memberId];

                const memberType = this.props.memberData.types.byId[member.type];
                let memberName = member.customFields[memberType.nameFieldId];
                
                memberName = getReadableDataForCustomField(memberName, this.props.memberData.types.customFields.byId[memberType.nameFieldId], memberId, 'member');

                return {
                    label: memberName,
                    value: memberId,
                };
            });
        }

        const selectedMembersList = membersList.filter(member => this.state.groupData.members.includes(member.value));

        let customFields: Array<JSX.Element|undefined> = [];

        if (this.state.groupData.type) {
            customFields = this.props.groupTypesData.byId[this.state.groupData.type].customFields.map(fieldId => {
                const field = this.props.customFieldsData.byId[fieldId];
                const choices = field.choices.map(choiceId => {
                    return {
                        name: this.props.customFieldOptionsData.byId[choiceId].name,
                        value: choiceId,
                    }
                });

                let customFieldValue = this.state.groupData.customFields[fieldId];

                if (field.isComputed) {
                    return undefined;
                }
                
                switch(field.type) {
                    case FieldType.SINGLE_SELECT:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A single select field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A single select field should not have a boolean value.');
                        }
    
                        return <div key={field.id} className={styles.inputSegment}>
                            <InputText placeholder={field.name} onChange={value => this.changeCustomField(field.id, value)} default={this.props.group && customFieldValue ? this.props.customFieldOptionsData.byId[customFieldValue].name : ''} options={choices} />
                        </div>
                    
                    case FieldType.NUMBER:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A text field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A number field should not have a boolean value.');
                        }
    
                        return <div key={field.id} className={styles.inputSegment}>
                            <InputText placeholder={field.name} type="number" onChange={value => this.changeCustomField(field.id, value)} default={this.props.group && customFieldValue ? String(customFieldValue): ''} />
                        </div>
                    
                    case FieldType.TEXT:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A text field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A text field should not have a boolean value.');
                        }
    
                        return <div key={field.id} className={styles.inputSegment}>
                            <InputText placeholder={field.name} onChange={value => this.changeCustomField(field.id, value)} default={this.props.group && customFieldValue ? String(customFieldValue) : ''} />
                        </div>
                
                case FieldType.BOOLEAN:
                    const binaryOptions = ['Yes', 'No'];
                        
                    if (typeof customFieldValue !== 'boolean' && typeof customFieldValue !== 'undefined') {
                        if (String(customFieldValue).toLocaleLowerCase() === 'yes') {
                            customFieldValue = true;
                        } else if (String(customFieldValue).toLocaleLowerCase() === 'no') {
                            customFieldValue = false;
                        } else {
                            throw new Error('A boolean field should have a boolean value.');
                        }
                    }

                    return <div key={field.id} className={styles.inputSegment}>
                        <InputText placeholder={field.name} onChange={value => this.changeCustomField(field.id, value === 'Yes')} default={this.props.group ? customFieldValue ? 'Yes' : 'No' : ''} options={binaryOptions} />
                    </div>
                    
                    case FieldType.DATE:
                        if (Array.isArray(customFieldValue)) {
                            throw new Error('A date field should not have an array value.');
                        }

                        if (typeof customFieldValue === 'boolean') {
                            throw new Error('A date field should not have a boolean value.');
                        }
    
                        return <div key={field.id} className={styles.inputSegment}>
                            <div className={styles.label}>{field.name}</div>
                            <DatePicker selected={customFieldValue ? new Date(customFieldValue) : undefined} dateFormat="dd-MMM-yyyy" onChange={value => this.changeCustomField(field.id, value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} /> 
                        </div>
                    
                    case FieldType.MULTI_SELECT:
                        if (typeof customFieldValue === 'string') {
                            throw new Error('A multi select field should have an array value');
                        }
    
                        const multiSelectChoices = choices.map(choice => {
                            return {
                                label: choice.name, 
                                value: choice.value,
                            };
                        });
    
                        return <div key={field.id} className={styles.inputSegment}>
                            <MultiSelect
                                options={multiSelectChoices}
                                onSelectedChanged={this.changeCustomField.bind(this, field.id)}
                                selected={customFieldValue ? customFieldValue : []}
                                overrideStrings={{
                                    selectSomeItems: 'Select ' + field.name + 's',
                                    allItemsAreSelected: 'All ' + field.name + 's are selected',
                                }}
                            />
                        </div>

                    case FieldType.PHONE:
                        if (typeof customFieldValue === 'undefined') {
                            customFieldValue = '';
                        }
                        
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A phone field should have a string value');
                        }

                        let phoneCountryCode = '+91';
                        let phoneNumber = '';

                        if (customFieldValue.split(' ').length > 1) {
                            phoneCountryCode = customFieldValue.split(' ')[0];
                            phoneNumber = customFieldValue.split(' ')[1];
                        }

                        return  <div className={styles.phoneSegment}>
                            <InputText icon={chevronIcon} default={phoneCountryCode} options={['+91', '+1']} isSearchDisabled onChange={value => this.changeCustomField(field.id, `${value} ${phoneNumber}`)} />
                            <InputText placeholder={field.name} type="tel" default={phoneNumber} onChange={value => this.changeCustomField(field.id, `${phoneCountryCode} ${value}`)} />
                        </div>

                    case FieldType.LOCATION:
                        if (typeof customFieldValue === 'undefined') {
                            customFieldValue = '';
                        }
                        
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A phone field should have a string value');
                        }

                        let latitude = '';
                        let longitude = '';

                        if (customFieldValue.split(' ').length > 1) {
                            latitude = customFieldValue.split(' ')[0];
                            longitude = customFieldValue.split(' ')[1];
                        }

                        return  <div className={styles.geoLocationSegment}>
                            <InputText placeholder={field.name + ' lat'} type="number" key={this.state.locationKey} default={latitude} onChange={value => this.changeCustomField(field.id, `${value} ${longitude}`)} />
                            <InputText placeholder={field.name + ' long'} type="number" key={this.state.locationKey + 1} default={longitude} onChange={value => this.changeCustomField(field.id, `${latitude} ${value}`)} />
                            <img src={mapIcon} onClick={this.getLocationForField.bind(this, fieldId)} alt="Location selector"/>
                        </div>
                }
                
                return undefined;
            });

        }
        
        return (
            <section className={styles.modifyGroup}>
                <header>
                    <h2 className={styles.formHeading}>{this.props.group ? 'Edit' : 'Add'} Group</h2>
                    <button className={styles.cancelButton} onClick={this.props.cancel}><CancelIcon /> Cancel</button>
                </header>
                <section className={styles.errorMessage}>{this.state.errorMessage}</section>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Group Type" icon={chevronIcon} default={this.props.group ? this.props.groupTypesData.byId[this.props.group.type].name : ''} options={typesList} onChange={this.changeType} />
                    </div>
                    {this.state.groupData.type && <div className={styles.inputSegment} key={this.state.groupData.type}>
                        <InputText placeholder="Location" icon={chevronIcon} default={this.props.group ? this.props.locationsData.byId[this.props.group.location].name : ''} options={locationsList} onChange={this.changeLocation} />
                    </div>}

                    {this.state.groupData.type && <div className={styles.wideInputSegment}>
                        <MultiSelect
                            options={membersList}
                            onSelectedChanged={this.changeMembers}
                            selected={this.state.groupData.members}
                            overrideStrings={{
                                selectSomeItems: 'Select Members',
                                allItemsAreSelected: 'All Members are selected',
                            }}
                        />
                    </div>}

                    {this.state.groupData.type && <div className={styles.wideInputSegment}>
                        <MultiSelect
                            options={selectedMembersList}
                            onSelectedChanged={this.changeRepresentatives}
                            selected={this.state.groupData.representatives}
                            overrideStrings={{
                                selectSomeItems: 'Select Representatives',
                                allItemsAreSelected: 'All Representatives are selected',
                            }}
                        />
                    </div>}

                    <div>{/* Empty element to make sure the nth-child css selector works */}</div>

                    {customFields}
                    
                </div>
                    
                <div className={styles.buttonHolder}>
                    {this.state.submitTimer ? <button className={styles.confirmFormButton}>{this.props.group ? translatePhrase('Updated Group') : translatePhrase('Added Group')}</button> : <Button text={this.props.group ? translatePhrase('Update Group') : translatePhrase('Add Group')} onClick={this.submitGroupForm} />}
                </div>
                
            </section>
        );
    }
}

const GroupModify = connect(mapStateToProps)(ConnectedGroupModify);

export default GroupModify;