export type HexColor = {
    h: number,
    s: number,
    l: number,
}

export type ThemeColor = {
    color: string,
    lighterColor: string,
}
    
const hexToHSL = (hexValue: string) : HexColor => {
    let hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
        result,
        red,
        green,
        blue;

    result = hexRegex.exec(hexValue);
    
    if (!result || !hexRegex.test(hexValue)) {
        throw new Error('The hex value has the wrong format');
    }

    red = parseInt(result[1], 16) / 255;
    green = parseInt(result[2], 16) / 255;
    blue = parseInt(result[3], 16) / 255;

    let max = Math.max(red, green, blue), 
        min = Math.min(red, green, blue);

    let hue, saturation, lightness = (max + min) / 2;

    if(max === min){
        hue = saturation = 0; // achromatic
    } else {
        var difference = max - min;
        saturation = lightness > 0.5 ? difference / (2 - max - min) : difference / (max + min);

        switch(max){
            case red: hue = (green - blue) / difference + (green < blue ? 6 : 0); break;
            case green: hue = (blue - red) / difference + 2; break;
            case blue: hue = (red - green) / difference + 4; break;
            default: throw new Error('The hex value has the wrong format');
        }

        hue /= 6;
    }

    return {
        h: hue,
        s: saturation,
        l: lightness
    };
}

const HSLToHex = (hue: number, saturation: number, lightness: number): string => {
    hue /= 360;
    saturation /= 100;
    lightness /= 100;

    let red, green, blue;

    if (saturation === 0) {
        red = green = blue = lightness; // achromatic
    } else {
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
        const p = 2 * lightness - q;
        red = hue2rgb(p, q, hue + 1 / 3);
        green = hue2rgb(p, q, hue);
        blue = hue2rgb(p, q, hue - 1 / 3);
    }
    const toHex = (x: number) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}

export { hexToHSL, HSLToHex };