import React, { Component, ChangeEvent } from 'react';
import styles from './MembersTable.module.scss';

import MemberModify from './MemberModify';
import MemberFilter from './MemberFilter';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/main';

import { addMember, deleteMember, searchMemberTable, goToPageMemberTable, setPageSizeMemberTable, sortMemberTable, updateMember } from '../../../shared/store/members/actions';
import { IUpdateableMemberData } from '../../../shared/store/members/types';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField, FieldType } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';
import { IProject } from '../../../shared/store/structure/project/types';
import Button from '../../../widgets/form/Button';
import TooltipList from '../../../widgets/tooltip-list/TooltipList';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';
import { addWorkflow } from '../../../shared/store/workflows/actions';
import uuid from 'uuid';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import { getAllLocationsVisibleToUser } from '../../../shared/helpers/locations';

const mapStateToProps = (state: ApplicationState) => {

    let visibleMembers: Array<string> = [];

    if (isUUID(state.myData.id)) {
        const visibleLocationIds = getAllLocationsVisibleToUser(state.myData.id);
    
        visibleLocationIds.forEach(locationId => {
            const location = state.structure.locations.byId[locationId];
            visibleMembers = visibleMembers.concat(location.members);
        });
    } else {
        visibleMembers = state.members.allEntries;
    }

    const allProcessedMembers = visibleMembers.map(memberId => state.members.byId[memberId]),
          currentPageNumber = state.members.currentPageNumber,
          pageSize = state.members.pageSize,
          memberSearchTerm = state.members.searchTerm,
          filters = state.members.filters;

    let hasFilters = filters.types.length > 0 || filters.locations.length > 0 || Object.keys(filters.customFields).length > 0;

    let filteredMembers = !hasFilters ? allProcessedMembers : allProcessedMembers.filter(member => {

        if (filters.types.length > 0 && !filters.types.includes(member.type)) {
            return false;
        }

        if (filters.locations.length > 0 && !filters.locations.includes(member.location)) {
            return false;
        }

        for (const customFieldId in filters.customFields) {
            if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                let customField = state.members.types.customFields.byId[customFieldId];
                const customFieldValue = member.customFields[customFieldId];

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (typeof customFieldValue !== 'string') {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                        return false;
                    }
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    if (!Array.isArray(customFieldValue)) {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                        return false;
                    }
                }

            }
        }

        return true;
    });

    if (memberSearchTerm) {
        filteredMembers = filteredMembers.filter(member => {
            const memberType = state.members.types.byId[member.type];

            const typeName = memberType.name;
            const locationName = state.structure.locations.byId[member.location].name;

            if (typeName.toLocaleLowerCase().includes(memberSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (locationName.toLocaleLowerCase().includes(memberSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            const nameField = state.members.types.customFields.byId[memberType.nameFieldId];
            const nameValue = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member');

            if (nameValue.toLocaleLowerCase().includes(memberSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            const subTitleField = state.members.types.customFields.byId[memberType.subTitleFieldId];
            const subTitleValue = getReadableDataForCustomField(member.customFields[subTitleField.id], subTitleField, member.id, 'member');

            if (subTitleValue.toLocaleLowerCase().includes(memberSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (filters.types.length === 1) {
                
                const typeFields = memberType.customFields.filter(fieldId => {
                    const customField = state.members.types.customFields.byId[fieldId];
    
                    return customField.isInTable && (customField.type === FieldType.TEXT || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
                });
    
                for (const customFieldId of typeFields) {
                    if (customFieldId !== memberType.nameFieldId && customFieldId !== memberType.subTitleFieldId) {
                        const customField = state.members.types.customFields.byId[customFieldId];
                        const fieldValue = getReadableDataForCustomField(member.customFields[customFieldId], customField, member.id, 'member');
        
                        if (fieldValue.toLocaleLowerCase().includes(memberSearchTerm.toLocaleLowerCase())) {
                            return true;
                        }
                    }
                }
            }

            return false;
        });
    }
    
    const currentUser = isUUID(state.myData.id) ? state.users.byId[state.myData.id] : undefined;
    let addMemberWorkflow: string|undefined;
    let project: IProject|undefined;

    if (typeof currentUser !== 'undefined') {
        project = state.structure.projects.byId[currentUser.project];
    } else if (state.structure.projects.allEntries.length === 1) {
        project = state.structure.projects.byId[state.structure.projects.allEntries[0]];
    }

    if (typeof project !== 'undefined') {
        addMemberWorkflow = project['add-member'];
    }

    return {
        membersList: filteredMembers.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize),
        totalNoOfMembers: filteredMembers.length,
        pageSize: pageSize,
        searchTerm: memberSearchTerm,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(filteredMembers.length / pageSize),

        usersData: state.users,
        
        memberFilters: filters,
        memberSort: state.members.sort,

        memberTypesData: state.members.types,
        customFieldsData: state.members.types.customFields,
        customFieldOptionssData: state.members.types.customFieldOptions,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        workflowTypesData: state.workflows.types,
        
        areFiltersExpanded: false,

        applicationState: state,
        addMemberWorkflow,
        myId: state.myData.id,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchMemberTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageMemberTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeMemberTable(size)),
        sort: (column: string, order: 'ASC'|'DESC') => dispatch(sortMemberTable(column, order)),
        
        addMember: (payload: IUpdateableMemberData) => dispatch(addMember(payload)),
        updateMember: (payload: IUpdateableMemberData) => dispatch(updateMember(payload)),
        deleteMember: (id: string) => dispatch(deleteMember(id)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

type OwnState = {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    isShowingFilterForm: boolean,
    isShowingFireWorkflowList: boolean,
    selectedMember: string|undefined,
}

class ConnectedMembersTable extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingFilterForm: false,
        isShowingFireWorkflowList: false,
        selectedMember: undefined,
    }

    static defaultProps = {
        isReadOnly: false,
    }

    showFireWorkflowOptions = (memberId: string) => {
        this.setState({
            selectedMember: memberId,
            isShowingFireWorkflowList: true,
        })
    }

    hideFireWorkflowOptions = () => {
        this.setState({
            selectedMember: undefined,
            isShowingFireWorkflowList: false,
        })
    }
    
    showAddForm = () => {
        this.setState({
            isShowingAddForm: true,
        });
    }
    
    showFilterForm = () => {
        this.setState({
            isShowingFilterForm: true,
        });
    }

    showModifyForm = () => {
        this.setState({
            isShowingModifyForm: true,
        });
    }
    
    hideMemberForm = () => {
        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: false,
        });
    }
    
    hideFilterForm = () => {
        this.setState({
            isShowingFilterForm: false,
        });
    }
    
    addMember = (memberData: IUpdateableMemberData) => {
        this.props.addMember(memberData);
        this.setState({
            isShowingAddForm: false
        });
    }
    
    updateMember = (memberData: IUpdateableMemberData) => {
        this.setState({
            isShowingModifyForm: false
        });
        this.props.updateMember(memberData);
    }
    
    deleteMember = (id: string) => {
        this.props.deleteMember(id);
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.searchTable(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.memberSort.column !== column;
        const sortOrder = !isNewColumn && this.props.memberSort.order === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }

    fireNewWorkflow = (workflowTypeId: string, memberId: string) => {
        const newWorkflowId = uuid.v4();
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
        const defaultStatusId = workflowType.statuses.filter(statusId => !this.props.workflowTypesData.statuses.byId[statusId].isTerminal)[0];
        const defaultStatus = this.props.workflowTypesData.statuses.byId[defaultStatusId];

        let defaultDueDate = moment().add(defaultStatus.dueInDays, 'days').format('YYYY-MM-DD');
        this.props.addWorkflow({
            id: newWorkflowId,
            type: workflowTypeId,
            status: defaultStatusId,
            dueDate: defaultDueDate,
            affiliatedEntity: memberId,
            user: isUUID(this.props.myId) ? this.props.myId : this.props.usersData.allEntries[0],
        });

        this.props.history.push(`/workflow/${newWorkflowId}/execute`)
    }
    
    render() {

        const MAX_NO_OF_MEMBERS = 50;

        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: 'Name',
            isSortable: false,
            isSticky: true,
            width: 150,
        }, {
            name: 'Details',
            isSortable: false,
            isSticky: false,
            width: 150,
        }, {
            name: 'Type',
            isSortable: false,
            width: 150,
        }, {
            name: 'Location',
            isSortable: false,
            width: 170,
        }];

        if (this.props.memberFilters.types.length === 1) {
            const memberType = this.props.memberTypesData.byId[this.props.memberFilters.types[0]];
            memberType.customFields.forEach(customFieldId => {
                const customField = this.props.customFieldsData.byId[customFieldId];
                const width = customField.type === FieldType.TEXT ? 200 : 150;

                if (customFieldId === memberType.nameFieldId || customFieldId === memberType.subTitleFieldId) {
                    return;
                }
    
                if (customField.isInTable) {
                    headings.push({
                        name: customField.name,
                        isSortable: false,
                        width: width,
                    });
                }
            });
        }

        headings.push({
            name: 'Action',
            isSortable: false,
            width: 200,
        });

        const entries: Array<TableRow> = this.props.membersList.map<TableRow>((member, index) => {
            const memberType = this.props.memberTypesData.byId[member.type];
            let memberName = member.customFields[memberType.nameFieldId];
            const nameField = this.props.memberTypesData.customFields.byId[memberType.nameFieldId];
            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            let memberSubTitle = member.customFields[memberType.subTitleFieldId];
            const subTitleField = this.props.memberTypesData.customFields.byId[memberType.subTitleFieldId];

            memberSubTitle = getReadableDataForCustomField(memberSubTitle, subTitleField, member.id, 'member');

            const cells: Array<string|number|JSX.Element> = [
                (this.props.pageNumber - 1) * this.props.pageSize + index + 1, // Sl. no
                memberName, // Name
                memberSubTitle, // Subtitle
                this.props.memberTypesData.byId[member.type].name, // Type
                member.location ? this.props.locationsData.byId[member.location].name : '-', // Location
            ];

            if (this.props.memberFilters.types.length === 1) {
                const memberType = this.props.memberTypesData.byId[this.props.memberFilters.types[0]];
                memberType.customFields.forEach(customFieldId => {
                    const customField = this.props.customFieldsData.byId[customFieldId];

                    if (!customField.isInTable) {
                        return;
                    }

                    if (customFieldId === memberType.nameFieldId || customFieldId === memberType.subTitleFieldId) {
                        return;
                    }
    
                    let customFieldValue = member.customFields[customFieldId];
    
                    let cellValue = getReadableDataForCustomField(customFieldValue, customField, member.id, 'member');
    
                    cells.push(cellValue);
                });
            }

            const tooltipEntries = this.props.workflowTypesData.allEntries
            .filter(workflowTypeId => {
                const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
                const nonTerminalStatuses = workflowType.statuses.filter(statusId => !this.props.workflowTypesData.statuses.byId[statusId].isTerminal);

                return nonTerminalStatuses.length > 0 && workflowType.affiliation === 'member';
            })
            .map(workflowTypeId => {
                const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
                return <div className={styles.listItem} onClick={this.fireNewWorkflow.bind(this, workflowType.id, member.id)}>{workflowType.name}</div>
            });
            
            cells.push(<div className={styles.listHolder}>
                <Button isOutline onClick={this.showFireWorkflowOptions.bind(this, member.id)} text="Fire Workflow" />
                {this.state.selectedMember === member.id && this.state.isShowingFireWorkflowList && <div className={styles.tooltipHolder}>
                    <TooltipList handleClickOutside={this.hideFireWorkflowOptions} listElements={tooltipEntries} />
                </div>}
            </div>);
            
            const projectForMemberType = this.props.projectsData.byId[memberType.project];
            const showViewInsteadOfEdit = !!projectForMemberType['edit-member'];

            const memberEditForm = !this.props.isReadOnly ? (<MemberModify memberId={member.id} submit={this.updateMember} cancel={this.hideMemberForm} isReadOnly={showViewInsteadOfEdit} />) : undefined;

            const row: TableRow = {
                id: member.id,
                entries: cells,
                editForm: memberEditForm,
                shareText: `Name: ${memberName}\nDescription: ${memberSubTitle}`,
                showViewInsteadOfEdit,
            }

            return row;
        });

        return (
            <TableWithMeta 
                entityType="Member"
                headings={headings} 
                entries={entries} 
                
                sortedColumn={this.props.memberSort.column} 
                sortType={this.props.memberSort.order} 
                isReadOnly={this.props.isReadOnly}
                areFiltersExpanded={this.props.areFiltersExpanded}

                totalPages={this.props.totalPages}
                pageNumber={this.props.pageNumber}
                searchTerm={this.props.searchTerm}
                isAddAllowed={this.props.totalNoOfMembers < MAX_NO_OF_MEMBERS}
                isShowingAddForm={this.state.isShowingAddForm} 
                isShowingModifyForm={this.state.isShowingModifyForm}
                addForm={<MemberModify submit={this.addMember} cancel={this.hideMemberForm} />}
                workflowForAdd={this.props.addMemberWorkflow}

                isShowingFilterForm={this.state.isShowingFilterForm}
                showFilterForm={this.showFilterForm}
                filterForm={<MemberFilter closeFilter={this.hideFilterForm} />}

                onSort={this.handleSort} 
                onDelete={this.props.deleteMember} 
                showAddForm={this.showAddForm}
                showModifyForm={this.showModifyForm}  

                search={this.props.searchTable}
                changePageSize={this.props.changePageSize}
                goToPage={this.props.goToPage}
                sort={this.props.sort}
            />
        );
    }
}

const MembersTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedMembersTable));

export default MembersTable;