import React, { Component, ChangeEvent } from 'react';
import styles from './Table.module.scss';

import { RouteComponentProps, withRouter } from 'react-router';
import Table, { TableHeading, TableRow } from '../../widgets/table/Table';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../shared/store/main';

import { translatePhrase } from '../../shared/helpers/translation';

import firstPageIcon from '../../assets/first-page.svg';
import prevPageIcon from '../../assets/prev-page.svg';
import nextPageIcon from '../../assets/next-page.svg';
import lastPageIcon from '../../assets/last-page.svg';

import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as ChevronIcon } from '../../assets/chevron-arrow-down.svg';
import { ReactComponent as FilterIcon } from '../../assets/filter.svg';
import { IUpdateableWorkflowData } from '../../shared/store/workflows/types';
import { addWorkflow } from '../../shared/store/workflows/actions';
import uuid from 'uuid';
import moment from 'moment';
import { isUUID } from '../../shared/helpers/utilities';

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    let defaultUserId = '';

    if (ownProps.workflowForAdd && ownProps.workflowForAdd in state.workflows.types.byId) {
        const workflowType = state.workflows.types.byId[ownProps.workflowForAdd];

        for (const userId of state.users.allEntries) {
            const user = state.users.byId[userId];

            if (user.project === workflowType.project) {
                defaultUserId = userId;
                break;
            }
        }
    }
    
    return {
        myId: state.myData.id,
        workflowTypesData: state.workflows.types,
        defaultUserId,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
    };
}

type OwnProps = {
    entityType: string,
    headings: Array<TableHeading>,
    entries: Array<TableRow>,

    sortedColumn?: string,
    sortType?: 'ASC'|'DESC',
    isReadOnly: boolean,
    areFiltersExpanded: boolean,

    searchTerm?: string,
    totalPages: number,
    pageNumber: number,
    isAddAllowed: boolean,
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    addForm: JSX.Element|undefined,

    isShowingFilterForm?: boolean,
    filterForm?: JSX.Element,

    workflowForAdd?: string,

    onSort: (name: string) => void,
    onDelete: (id: string) => void,
    showAddForm: () => void,
    showModifyForm: () => void,
    showFilterForm?: () => void,

    search: (searchTerm: string) => void,
    changePageSize: (pageSize: number) => void,
    goToPage: (pageNumber: number) => void,
    sort: (column: string, order: 'ASC'|'DESC') => void,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

class ConnectedTableWithMeta extends Component<Props> {

    static defaultProps = {
        isReadOnly: false,
        isAddAllowed: true,
        showViewInsteadOfEdit: false,
    }

    handleAddClick = () => {
        if (typeof this.props.workflowForAdd === 'undefined') {
            this.props.showAddForm();
            return;
        }

        const nonTerminalStatuses = this.props.workflowTypesData.byId[this.props.workflowForAdd].statuses
        .filter(statusId => {
            const status = this.props.workflowTypesData.statuses.byId[statusId];

            return !status.isTerminal;
        })
        .map(statusId => this.props.workflowTypesData.statuses.byId[statusId]);

        if (nonTerminalStatuses.length === 0) {
            this.props.showAddForm();
            return;
        }

        const firstStatus = nonTerminalStatuses[0];
        const newWorkflowId = uuid.v4();

        this.props.addWorkflow({
            id: newWorkflowId,
            type: this.props.workflowForAdd,
            user: isUUID(this.props.myId) ? this.props.myId : this.props.defaultUserId,
            status: firstStatus.id,
            dueDate: moment().add(firstStatus.dueInDays, 'days').format('YYYY-MM-DD'),
            affiliatedEntity: '',
        });

        this.props.history.push(`/workflow/${newWorkflowId}/execute`)
    }

    handleFilterClick = () => {
        this.props.showFilterForm && this.props.showFilterForm();
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.search(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.sortedColumn !== column;
        const sortOrder = !isNewColumn && this.props.sortType === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }
    
    render() {

        let pages = [], startIndex, endIndex;

        if (this.props.totalPages < 5) {
            startIndex = 1;
            endIndex = this.props.totalPages;
        } else if (this.props.pageNumber < 5) {
            startIndex = 1;
            endIndex = 5
        } else if (this.props.pageNumber > (this.props.totalPages - 5)) {
            startIndex = this.props.totalPages - 4;
            endIndex = this.props.totalPages;
        } else {
            startIndex = this.props.pageNumber - 2;
            endIndex = this.props.pageNumber + 2;
        }

        for (let i = startIndex; i <= endIndex; i += 1) {
            pages.push(<section key={i} className={this.props.pageNumber === i ? styles.activePageLink : styles.pageLink} onClick={e => this.props.goToPage(i)}>{i}</section>);
        }

        return (
            <div className={styles.entireTableHolder}>
                <section className={this.props.areFiltersExpanded ? styles.meta : styles.expandedMeta}>
                    <SearchIcon />
                    <input className={styles.searchBox} type="text" defaultValue={this.props.searchTerm} onChange={this.handleSearch} placeholder={translatePhrase('Search')} />
                    <div className={styles.pageSize}>
                        <div>{translatePhrase('Show')}:</div>
                        <select className="table-page-size" onChange={this.handleSizeChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                        </select>
                        <ChevronIcon />
                    </div>
                    {this.props.showFilterForm && <FilterIcon onClick={this.handleFilterClick} />}
                    {this.props.isShowingFilterForm && <section className={styles.newEntryFormHolder}>
                        {this.props.filterForm}
                    </section>}
                    {!this.props.isReadOnly && this.props.isAddAllowed && <button className={styles.addEntryButton} onClick={this.handleAddClick}>+ {translatePhrase(`Add ${this.props.entityType}`)}</button>}
                    {this.props.isShowingAddForm && <section className={styles.newEntryFormHolder}>
                        {this.props.addForm}
                    </section>}
                </section>
                <Table headings={this.props.headings} entries={this.props.entries} sortedColumn={this.props.sortedColumn} sortType={this.props.sortType} onSort={this.handleSort} onDelete={this.props.onDelete} showModifyForm={this.props.showModifyForm} isShowingModifyForm={this.props.isShowingModifyForm} />
                {pages.length > 1 && <div className={styles.pageNumbersHolder}>
                    <section className={styles.pageNavButton} onClick={e => this.props.goToPage(1)}><img src={firstPageIcon} alt=""/></section>
                    <section className={styles.pageNavButton} onClick={e => this.props.pageNumber > 1 ? this.props.goToPage(this.props.pageNumber - 1) : null}><img src={prevPageIcon} alt=""/></section>
                    {pages}
                    <section className={styles.pageNavButton} onClick={e => this.props.pageNumber < this.props.totalPages ? this.props.goToPage(this.props.pageNumber + 1) : null}><img src={nextPageIcon} alt=""/></section>
                    <section className={styles.pageNavButton} onClick={e => this.props.goToPage(this.props.totalPages)}><img src={lastPageIcon} alt=""/></section>
                </div>}
            </div>
        );
    }
}

const TableWithMeta = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedTableWithMeta));

export default TableWithMeta;