import React, { Component } from 'react';
import styles from './WorkflowFilter.module.scss';
import MultiSelect from '@khanacademy/react-multi-select';

import Button from '../../../widgets/form/Button';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/main';

import { filterWorkflowTable } from '../../../shared/store/workflows/actions';

import { connect } from 'react-redux';
import { FieldType, getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { Dispatch } from 'redux';

type OwnProps = {
    closeFilter: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries;
    
    let allAllowedLocations: Array<string> = []
    
    allAllowedProjects.forEach(projectId => {
        const projectData = state.structure.projects.byId[projectId];
        const leafLevelIndex = projectData.children.map(levelId => state.structure.levels.byId[levelId]).filter(level => !level.archived).length - 1;

        let locations = state.structure.locations.byProject[projectId].map(locationId => state.structure.locations.byId[locationId]);
        
        for (let i = 0; i < leafLevelIndex; i += 1) {
            let newLocations: Array<string> = [];
            
            locations.forEach(location => {
                if (location.children && location.children.length > 0) {
                    newLocations = newLocations.concat(location.children);
                }
            });
            
            locations = newLocations.map(locationId => state.structure.locations.byId[locationId]);
        }
        allAllowedLocations = allAllowedLocations.concat(locations.map(location => location.id));
    });

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        workflowsData: state.workflows,
        customFieldsData: state.workflows.types.customFields,
        customFieldOptionsData: state.workflows.types.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        allAllowedLocations,

        filters: state.workflows.filters,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterWorkflowTable: (dues: Array<string>, types: Array<string>, statuses: Array<string>, users: Array<string>, affiliations: Array<string>, customFields: {[customFieldId: string]: Array<string>}) => dispatch(filterWorkflowTable(dues, types, statuses, users, affiliations, customFields)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    dues: Array<string>,
    types: Array<string>,
    statuses: Array<string>,
    users: Array<string>,
    affiliations: Array<string>,

    customFields: {
        [customFieldId: string]: Array<string>,
    }
};

class ConnectedWorkflowModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            dues: props.filters.dues,
            types: props.filters.types,
            statuses: props.filters.statuses,
            users: props.filters.users,
            affiliations: props.filters.affiliations,
            customFields: props.filters.customFields,
        };
    }
    
    changeDues = (dues: Array<string>) => {
        
        this.setState({
            dues,
        });
    }
    
    changeUsers = (users: Array<string>) => {
        
        this.setState({
            users,
        });
    }
    
    changeTypes = (types: Array<string>) => {
        
        this.setState({
            types,
            statuses: [],
            affiliations: [],
            customFields: {},
        });
    }
    
    changeStatuses = (statuses: Array<string>) => {
        
        this.setState({
            statuses,
        });
    }
    
    changeAffiliations = (affiliations: Array<string>) => {
        
        this.setState({
            affiliations,
        });
    }

    changeCustomFieldOptions = (customFieldId: string, options: Array<string>) => {
        const newCustomFields = {
            ...this.state.customFields,
            [customFieldId]: options,
        };

        this.setState({
            customFields: newCustomFields,
        });
    }

    submitFilters = () => {
        this.props.filterWorkflowTable(
            this.state.dues,
            this.state.types,
            this.state.statuses,
            this.state.users,
            this.state.affiliations,
            this.state.customFields,
        );
        this.props.closeFilter();
    }

    clearFilters = () => {
        this.setState({
            dues: [],
            types: [],
            statuses: [],
            users: [],
            affiliations: [],
            customFields: {},
        });
        this.props.filterWorkflowTable([], [], [], [], [], {});
    }
    
    render() {

        const duesList = [{
            label: 'Due',
            value: 'due',
        }, {
            label: 'Overdue',
            value: 'Overdue',
        }];

        const typesList = this.props.workflowsData.types.allEntries.map(typeId => {
            return {
                label: this.props.workflowsData.types.byId[typeId].name,
                value: typeId,
            };
        });

        const usersList = this.props.usersData.allEntries.map(userId => {
            const user = this.props.usersData.byId[userId];
            const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

            return {
                label: getReadableDataForCustomField(user.customFields[nameField.id], nameField, userId, 'user'),
                value: userId,
            };
        });

        let workflowType = this.state.types.length === 1 ? this.props.workflowsData.types.byId[this.state.types[0]] : undefined;
        let customFieldsMarkup: Array<JSX.Element> | undefined;

        let statusesList: Array<{label: string, value: string}> = [],
            affiliationsList: Array<{label: string, value: string}> = [];

        if (typeof workflowType !== 'undefined' && this.state.types.length === 1) {
            statusesList = workflowType.statuses.map(statusId => {
                const status = this.props.workflowsData.types.statuses.byId[statusId];

                return {
                    label: status.name,
                    value: statusId,
                };
            });

            if (workflowType.affiliation === 'member') {
                affiliationsList = this.props.membersData.allEntries.map(memberId => {
                    const member = this.props.membersData.byId[memberId];
                    const memberType = this.props.membersData.types.byId[member.type];
                    const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];
        
                    return {
                        label: getReadableDataForCustomField(member.customFields[nameField.id], nameField, memberId, 'member'),
                        value: memberId,
                    };
                });
            } else if (workflowType.affiliation === 'group') {
                affiliationsList = this.props.groupsData.allEntries.map(groupId => {
                    const group = this.props.groupsData.byId[groupId];
                    const groupType = this.props.groupsData.types.byId[group.type];
                    const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];
        
                    return {
                        label: getReadableDataForCustomField(group.customFields[nameField.id], nameField, groupId, 'group'),
                        value: groupId,
                    };
                });
            }

            customFieldsMarkup = this.props.customFieldsData.allFields
            .filter(customFieldId => {
                const customField = this.props.customFieldsData.byId[customFieldId];

                return customField.isInTable && (customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
            })
            .map(customFieldId => {
                const customField = this.props.customFieldsData.byId[customFieldId];
                const customFieldOptions = customField.choices.map(optionId => {
                    return {
                        label: this.props.customFieldOptionsData.byId[optionId].name,
                        value: optionId,
                    };
                });

                return <div className={styles.inputSegment} key={customFieldId}>
                    <MultiSelect
                        options={customFieldOptions}
                        onSelectedChanged={this.changeCustomFieldOptions.bind(this, customFieldId)}
                        selected={this.state.customFields[customFieldId] || []}
                        overrideStrings={{
                            selectSomeItems: `Select all ${customField.name}s`,
                            allItemsAreSelected: `All ${customField.name}s are selected`,
                        }}
                    />
                </div>
            });
        }
        
        return (
            <section className={styles.filterWorkflows}>
                <header>
                    <h2 className={styles.formHeading}>Filter Workflows</h2>
                    <button className={styles.cancelButton} onClick={this.props.closeFilter}><CancelIcon /> {translatePhrase('Cancel')}</button>
                </header>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={duesList}
                            onSelectedChanged={this.changeDues}
                            selected={this.state.dues}
                            overrideStrings={{
                                selectSomeItems: 'Select all Dues',
                                allItemsAreSelected: 'All Dues are selected',
                            }}
                        />
                    </div>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={usersList}
                            onSelectedChanged={this.changeUsers}
                            selected={this.state.users}
                            overrideStrings={{
                                selectSomeItems: 'Select all Users',
                                allItemsAreSelected: 'All Users are selected',
                            }}
                        />
                    </div>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={typesList}
                            onSelectedChanged={this.changeTypes}
                            selected={this.state.types}
                            overrideStrings={{
                                selectSomeItems: 'Select all Types',
                                allItemsAreSelected: 'All Types are selected',
                            }}
                        />
                    </div>
                    {this.state.types.length === 1 && <div className={styles.inputSegment}>
                        <MultiSelect
                            options={statusesList}
                            onSelectedChanged={this.changeStatuses}
                            selected={this.state.statuses}
                            overrideStrings={{
                                selectSomeItems: 'Select all Statuses',
                                allItemsAreSelected: 'All Statuses are selected',
                            }}
                        />
                    </div>}
                    {this.state.types.length === 1 && <div className={styles.inputSegment}>
                        <MultiSelect
                            options={affiliationsList}
                            onSelectedChanged={this.changeAffiliations}
                            selected={this.state.affiliations}
                            overrideStrings={{
                                selectSomeItems: 'Select all Affiliations',
                                allItemsAreSelected: 'All Affiliations are selected',
                            }}
                        />
                    </div>}

                    {customFieldsMarkup}
                    
                </div>
                    
                <div className={styles.buttonHolder}>
                    <Button text={translatePhrase('Apply filter')} onClick={this.submitFilters} />
                    <Button isOutline text={translatePhrase('Clear filters')} onClick={this.clearFilters} />
                </div>
                
            </section>
        );
    }
}

const WorkflowModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowModify);

export default WorkflowModify;