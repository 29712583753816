import React, { Component } from 'react';
import { CardType } from '../../../widgets/card/Card';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';

export interface OwnProps {
    selectedId?: string,
    parentId: string,
    heading: string,
    isReadOnly: boolean,
    
    
    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
}

type WorkflowStatusType = {
    id: string,
    name: string,
    isTerminal: boolean,
    dueInDays?: number,
};

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    groupTypesList: Array<CardType>,
    cardsList: Array<WorkflowStatusType>,
    selectedCard: WorkflowStatusType|undefined,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: {name: string, isTerminal: boolean, dueInDays?: number}) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: WorkflowStatusType) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardIsTerminal: string,
    modifyingCardDueInDays: number,
};

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;
        
        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }
    
    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardIsTerminal: this.props.selectedCard.isTerminal ? 'Yes' : 'No',
        });
    }
    
    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    updateCardIsTerminal = (value: string) => {

        this.setState({
            modifyingCardIsTerminal: value,
        });
    }

    updateCardDueInDays = (value: string) => {

        this.setState({
            modifyingCardDueInDays: Number(value),
        });
    }
    
    addCard = () => {
        
        this.props.addCard({
            name: this.state.modifyingCardName,
            isTerminal: this.state.modifyingCardIsTerminal === 'Yes',
            dueInDays: this.state.modifyingCardDueInDays ? this.state.modifyingCardDueInDays : 0,
        });
        
        this.setState({
            modifyingCardName: '',
            modifyingCardIsTerminal: 'No',
            isShowingAddForm: false
        });
    }
    
    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            isTerminal: this.state.modifyingCardIsTerminal === 'Yes',
            dueInDays: this.state.modifyingCardDueInDays ? this.state.modifyingCardDueInDays : 0,
        });
        
        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardIsTerminal: 'No',
            isShowingAddForm: false
        });
    }
    
    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        if (!this.state.modifyingCardIsTerminal) {
            return 'Enter a valid Is Closed';
        }

        if (this.state.modifyingCardIsTerminal !== 'Yes' && !this.state.modifyingCardDueInDays) {
            return 'Enter a valid Due in days';
        }
        
        return true;
    }

    render() {
        const binaryOptions = ['Yes', 'No'];

        const modifyForm = <ModifyForm isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <InputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <InputText placeholder="Is Closed?" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardIsTerminal} default={this.props.selectedCard && this.props.selectedCard.isTerminal ? 'Yes' : 'No'} options={binaryOptions} />

            {this.state.modifyingCardIsTerminal === 'No' && <InputText placeholder="Due in days" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardDueInDays} default={this.state.isShowingModifyForm && this.props.selectedCard ? String(this.props.selectedCard.dueInDays) : '7'} />}
        </ModifyForm>;

        return <CardsList 
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard} 
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard} 
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
        />
    }
}

export default CardTreeLevel;