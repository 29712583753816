import React, { Component } from 'react';
import styles from './ShowData.module.scss';
import Table, { TableHeading, TableRow } from '../../../widgets/table/Table';
import { CSVLink } from 'react-csv';
import ChartistGraph from 'react-chartist';

import { connect } from 'react-redux';

import { getReadableDataForCustomField, CustomFieldValueType, CustomField, CustomFieldOptionsDataType } from '../../../shared/store/custom-fields';

import { ApplicationState } from '../../../shared/store/main';
import { WorkflowTypeCustomField } from '../../../shared/store/workflows/types/types';
import { IWorkflow } from '../../../shared/store/workflows/types';
import { IUser } from '../../../shared/store/users/types';
import { IMember } from '../../../shared/store/members/types';
import { IGroup } from '../../../shared/store/groups/types';

import { ReactComponent as LineChartIcon } from '../../../assets/line-chart.svg';
import { ReactComponent as BarChartIcon } from '../../../assets/bar-chart.svg';
import { ReactComponent as DonutChartIcon } from '../../../assets/donut-chart.svg';
import { ReactComponent as TableIcon } from '../../../assets/table-grid.svg';
import { ReactComponent as ExportIcon } from '../../../assets/export.svg';

import moment from 'moment';

export type ShowTableProps = {
    entityIds: Array<string>,
    type: string,
    typeId?: string,
    customFields: Array<string>,
    
    aggregation?: 'none'|'count'|'sum'|'average',
    roles?: Array<string>,
    startingDisplayType?: 'table'|'bar'|'line'|'donut',

    header?: JSX.Element,
    
};

const mapStateToProps = (state: ApplicationState, ownProps: ShowTableProps) => {

    return {
        rolesData: state.structure.roles,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        workflowData: state.workflows,

        applicationState: state,
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = ShowTableProps & StateProps;

type OwnState = {
    dataType: 'table'|'bar'|'line'|'donut',
    searchTerm: '',
    filters: {
        [customFieldId: string]: Array<string>,
    }
}

class ConnectedShowTable extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            dataType: props.startingDisplayType || 'table',
            searchTerm: '',
            filters: {},
        }
    }

    getCommonCustomFieldValue = (entity: IUser|IMember|IGroup, type: 'user'|'member'|'group', customField: CustomField, optionsData: CustomFieldOptionsDataType) => {
        let customFieldValue = entity.customFields[customField.id];

        return getReadableDataForCustomField(customFieldValue, customField, entity.id, type);
    }

    getWorkflowCustomFieldValue = (workflow: IWorkflow, customField: WorkflowTypeCustomField) => {
        let customFieldValue = workflow.history[workflow.historyIndex].customFields[customField.id] as CustomFieldValueType;

        return getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow');
    }

    changeDisplay(dataType: string) {
        switch(dataType) {
            case 'table':
                this.setState({
                    dataType: 'table',
                    searchTerm: '',
                    filters: {},
                });
                break;
            case 'bar':
                this.setState({
                    dataType: 'bar',
                    searchTerm: '',
                    filters: {},
                });
                break;
            case 'line':
                this.setState({
                    dataType: 'line',
                    searchTerm: '',
                    filters: {},
                });
                break;
            case 'donut':
                this.setState({
                    dataType: 'donut',
                    searchTerm: '',
                    filters: {},
                });
                break;
        }
    }

    render() {
    
        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: false,
            width: 90,
        }, {
            name: 'Name',
            isSortable: false,
            isSticky: false,
            width: 120,
        }];

        const csvData: Array<Array<string>> = [['Sl. no', 'Name']];

        let entries: Array<TableRow> = [];

        let chartLabels: Array<string> = [];
        let chartData: Array<number> = [];
        const chartCounts: {[key: string]: number} = {};

        let entityType = '';
    
        switch(this.props.type) {
            case 'User':
                this.props.customFields.forEach(fieldId => {
                    const fieldHeading: TableHeading = {
                        name: this.props.usersData.customFields.byId.hasOwnProperty(fieldId) ? this.props.usersData.customFields.byId[fieldId].name : this.props.rolesData.customFields.byId[fieldId].name,
                        isSortable: false,
                        width: 150,
                    }

                    headings.push(fieldHeading);
                    csvData[0].push(fieldHeading.name);
                });

                entries = this.props.entityIds.map(((userId, index) => {
                    const user = this.props.usersData.byId[userId];
                    let userName = user.customFields[this.props.usersData.nameFieldId];

                    userName = getReadableDataForCustomField(userName, this.props.usersData.customFields.byId[this.props.usersData.nameFieldId], user.id, 'user');

                    const cells: Array<JSX.Element|number|string> = [index + 1, userName];

                    csvData.push([String(index + 1), userName]);

                    this.props.customFields.forEach(fieldId => {
                        const field = this.props.usersData.customFields.byId.hasOwnProperty(fieldId) ? this.props.usersData.customFields.byId[fieldId] : this.props.rolesData.customFields.byId[fieldId];
                        const fieldOptions = this.props.usersData.customFields.byId.hasOwnProperty(fieldId) ? this.props.usersData.customFieldOptions : this.props.rolesData.customFieldOptions;
                        const value = this.getCommonCustomFieldValue(user, 'user', field, fieldOptions);
                        const readableValue = Array.isArray(value) ? value.join(', ') : value;

                        cells.push(readableValue);

                        const lastRowIndex = csvData.length - 1;
                        csvData[lastRowIndex].push(readableValue);
                    });

                    if (this.props.customFields.length === 1) {
                        const fieldId = this.props.customFields[0];
                        const field = this.props.usersData.customFields.byId.hasOwnProperty(fieldId) ? this.props.usersData.customFields.byId[fieldId] : this.props.rolesData.customFields.byId[fieldId];
                        const fieldOptions = this.props.usersData.customFields.byId.hasOwnProperty(fieldId) ? this.props.usersData.customFieldOptions : this.props.rolesData.customFieldOptions;
                        const value = this.getCommonCustomFieldValue(user, 'user', field, fieldOptions);

                        (Array.isArray(value) ? value : [value]).forEach(value => {
                            if (value in chartCounts) {
                                chartCounts[value] += 1;
                            } else {
                                chartCounts[value] = 1;
                            }
                        });
                    }

                    return {
                        id: user.id,
                        entries: cells,
                    }
                }));

                if (this.props.customFields.length === 1) {

                    for (let property in chartCounts) {
                        if (chartCounts.hasOwnProperty(property)) {
                            chartLabels.push(property);
                            chartData.push(chartCounts[property]);
                        }
                    }
                }

                break;

            case 'Member':
                entityType = this.props.typeId ? this.props.membersData.types.byId[this.props.typeId].name : '';
                this.props.customFields.forEach(fieldId => {
                    const fieldHeading: TableHeading = {
                        name: this.props.membersData.types.customFields.byId[fieldId].name,
                        isSortable: false,
                        width: 150,
                    }

                    headings.push(fieldHeading);
                    csvData[0].push(fieldHeading.name);
                });

                entries = this.props.entityIds.map(((memberId, index) => {
                    const member = this.props.membersData.byId[memberId];
                    const memberType = this.props.membersData.types.byId[member.type];
                    let memberName = member.customFields[memberType.nameFieldId];

                    const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

                    memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

                    const cells: Array<JSX.Element|number|string> = [index + 1, memberName];

                    csvData.push([String(index + 1), memberName]);

                    this.props.customFields.forEach(fieldId => {
                        const value = this.getCommonCustomFieldValue(member, 'member', this.props.membersData.types.customFields.byId[fieldId], this.props.membersData.types.customFieldOptions);
                        const readableValue = Array.isArray(value) ? value.join(', ') : value;

                        cells.push(readableValue);

                        const lastRowIndex = csvData.length - 1;
                        csvData[lastRowIndex].push(readableValue);
                    });

                    if (this.props.customFields.length === 1) {
                        const value = this.getCommonCustomFieldValue(member, 'member',this.props.membersData.types.customFields.byId[this.props.customFields[0]], this.props.membersData.types.customFieldOptions);

                        (Array.isArray(value) ? value : [value]).forEach(value => {
                            if (value in chartCounts) {
                                chartCounts[value] += 1;
                            } else {
                                chartCounts[value] = 1;
                            }
                        });
                    }

                    return {
                        id: member.id,
                        entries: cells,
                    }
                }));

                if (this.props.customFields.length === 1) {

                    for (let property in chartCounts) {
                        if (chartCounts.hasOwnProperty(property)) {
                            chartLabels.push(property);
                            chartData.push(chartCounts[property]);
                        }
                    }
                }

                break;

            case 'Group':
                entityType = this.props.typeId ? this.props.groupsData.types.byId[this.props.typeId].name : '';

                this.props.customFields.forEach(fieldId => {
                    const fieldHeading: TableHeading = {
                        name: this.props.groupsData.types.customFields.byId[fieldId].name,
                        isSortable: false,
                        width: 150,
                    }

                    headings.push(fieldHeading);
                    csvData[0].push(fieldHeading.name);
                });

                entries = this.props.entityIds.map(((memberId, index) => {
                    const group = this.props.groupsData.byId[memberId];
                    const groupType = this.props.groupsData.types.byId[group.type];
                    let groupName = group.customFields[groupType.nameFieldId];

                    const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];

                    groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

                    const cells: Array<JSX.Element|number|string> = [index + 1, groupName];

                    csvData.push([String(index + 1), groupName]);

                    this.props.customFields.forEach(fieldId => {
                        const value = this.getCommonCustomFieldValue(group, 'group', this.props.groupsData.types.customFields.byId[fieldId], this.props.groupsData.types.customFieldOptions);
                        const readableValue = Array.isArray(value) ? value.join(', ') : value;

                        cells.push(readableValue);

                        const lastRowIndex = csvData.length - 1;
                        csvData[lastRowIndex].push(readableValue);
                    });

                    if (this.props.customFields.length === 1) {
                        const value = this.getCommonCustomFieldValue(group, 'group',this.props.groupsData.types.customFields.byId[this.props.customFields[0]], this.props.groupsData.types.customFieldOptions);

                        (Array.isArray(value) ? value : [value]).forEach(value => {
                            if (value in chartCounts) {
                                chartCounts[value] += 1;
                            } else {
                                chartCounts[value] = 1;
                            }
                        });
                    }

                    return {
                        id: group.id,
                        entries: cells,
                    }
                }));

                if (this.props.customFields.length === 1) {

                    for (let property in chartCounts) {
                        if (chartCounts.hasOwnProperty(property)) {
                            chartLabels.push(property);
                            chartData.push(chartCounts[property]);
                        }
                    }
                }

                break;

            case 'Workflow':
                entityType = this.props.typeId ? this.props.workflowData.types.byId[this.props.typeId].name : '';
                headings.pop();
                headings.push({
                    name: 'Created time',
                    isSortable: false,
                    width: 100,
                });
                csvData[0].pop();
                csvData[0].push('Created time');

                this.props.customFields.forEach(fieldId => {
                    const fieldHeading: TableHeading = {
                        name: this.props.workflowData.types.customFields.byId[fieldId].name,
                        isSortable: false,
                        width: 150,
                    }

                    headings.push(fieldHeading);
                    csvData[0].push(fieldHeading.name);
                });

                entries = this.props.entityIds.map(((memberId, index) => {
                    const workflow = this.props.workflowData.byId[memberId];
                    const createdTime = moment(workflow.createdTime).format('DD MMM YYYY hh:mm:ss A');
                    const cells: Array<JSX.Element|number|string> = [index + 1, createdTime];

                    csvData.push([String(index + 1), createdTime]);

                    this.props.customFields.forEach(fieldId => {
                        const value = this.getWorkflowCustomFieldValue(workflow, this.props.workflowData.types.customFields.byId[fieldId]);
                        const readableValue = Array.isArray(value) ? value.join(', ') : value;

                        cells.push(readableValue);

                        const lastRowIndex = csvData.length - 1;
                        csvData[lastRowIndex].push(readableValue);
                    });

                    if (this.props.customFields.length === 1) {
                        const value = this.getWorkflowCustomFieldValue(workflow,this.props.workflowData.types.customFields.byId[this.props.customFields[0]]);

                        (Array.isArray(value) ? value : [value]).forEach(value => {
                            if (value in chartCounts) {
                                chartCounts[value] += 1;
                            } else {
                                chartCounts[value] = 1;
                            }
                        });
                    }

                    return {
                        id: workflow.id,
                        entries: cells,
                    }
                }));

                if (this.props.customFields.length === 1) {

                    for (let property in chartCounts) {
                        if (chartCounts.hasOwnProperty(property)) {
                            chartLabels.push(property);
                            chartData.push(chartCounts[property]);
                        }
                    }
                }

                break;
            
            default:
                throw new Error('Unknown type');
        }
        
        const chartistData = {
            labels: chartLabels,
            series: [chartData],
        };

        const donutData = {
            labels: chartLabels,
            series: chartData,
        }
        
        const donutOptions = {
            donut: true,
            donutWidth: 20,
            startAngle: 240
        };

        const isAggregated = this.props.aggregation && this.props.aggregation !== 'none';
        let aggregationValue: number = 0;

        if (isAggregated) {
            const dataToAggregate = csvData.map(csvRow => csvRow[2]).slice(1);
            let numericValues: Array<number>;

            switch(this.props.aggregation) {
                case 'count':
                    aggregationValue = dataToAggregate.length;
                    break;
                case 'sum':
                    numericValues = dataToAggregate.map(value => Number(value));
                    aggregationValue = numericValues.reduce((sum, num) => {
                        if (!isNaN(num)) {
                            return sum + num;
                        } else {
                            return sum;
                        }
                    });
                    break;
                case 'average':
                    numericValues = dataToAggregate.map(value => Number(value));
                    aggregationValue = numericValues.reduce((sum, num) => {
                        if (!isNaN(num)) {
                            return sum + num;
                        } else {
                            return sum;
                        }
                    });
                    if (numericValues.length > 0) {
                        aggregationValue /= numericValues.length;
                    } else {
                        aggregationValue = 0;
                    }
                    break;

                default:
                    throw new Error('Incorrect aggregation');
            }
        }

        return <section className={styles.showDataContainer}>
            {this.props.header}
            <section className={styles.subHeader}>
                <span>{this.props.type}</span>
                {entityType && <span className={styles.separator}></span>}
                {entityType && <span>{entityType}</span>}
                {this.props.roles && this.props.roles.length > 0 && <span className={styles.separator}></span>}
                {this.props.roles && this.props.roles.length > 0 && <span>{this.props.roles.map(roleId => this.props.rolesData.byId[roleId].name).join(', ')}</span>}
            </section>
            <div className={styles.dataContainer}>
                {isAggregated && <div className={styles.numberHolder}>
                    {aggregationValue}
                </div>}
                {this.props.customFields.length === 1 && !isAggregated && <div className={styles.dataFormats}>
                    <section className={this.state.dataType === 'line' ? styles.activeFormat : styles.format} onClick={this.changeDisplay.bind(this, 'line')}>
                        <LineChartIcon />
                        <span>Line chart</span>
                    </section>
                    <section className={this.state.dataType === 'bar' ? styles.activeFormat : styles.format} onClick={this.changeDisplay.bind(this, 'bar')}>
                        <BarChartIcon />
                        <span>Bar chart</span>
                    </section>
                    <section className={this.state.dataType === 'donut' ? styles.activeFormat : styles.format} onClick={this.changeDisplay.bind(this, 'donut')}>
                        <DonutChartIcon />
                        <span>Donut chart</span>
                    </section>
                    <section className={this.state.dataType === 'table' ? styles.activeFormat : styles.format} onClick={this.changeDisplay.bind(this, 'table')}>
                        <TableIcon />
                        <span>Table</span>
                    </section>
                </div>}
                {!isAggregated && <div className={styles.dataContent}>
                    {this.state.dataType === 'table' && <section className={styles.normalTable}>
                        <Table 
                            headings={headings}
                            entries={entries}
                            isReadOnly
                        />
                    </section>}
                    {this.state.dataType === 'bar' && <section className={styles.chartContainer}><ChartistGraph data={chartistData} type="Bar" /></section>}
                    {this.state.dataType === 'line' && <section className={styles.chartContainer}><ChartistGraph data={chartistData} type="Line" /></section>}
                    {this.state.dataType === 'donut' && <section className={styles.chartContainer}><ChartistGraph data={donutData} options={donutOptions} type="Pie" /></section>}
                </div>}
            </div>
            <div className={styles.downloadButtonsHolder}>
                <CSVLink data={csvData} filename={'Data export.csv'}><button className={styles.downloadTable}> <ExportIcon /> Export CSV</button></CSVLink>
            </div>
        </section>

    }
}

const ShowTable = connect(mapStateToProps)(ConnectedShowTable);

export default ShowTable;