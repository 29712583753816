import React, { Component, ChangeEvent } from 'react';
import styles from './VariableModify.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';

import { ApplicationState } from '../../../shared/store/main';

import { connect } from 'react-redux';
import uuid from 'uuid';
import { IUpdateableVariableData, VariableType } from '../../../shared/store/flowchart/variables/types';

type OwnProps = {
    variableId?: string,

    submit: (variablePieceData: IUpdateableVariableData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        variable: ownProps.variableId ? state.flowchart.variables.byId[ownProps.variableId] : undefined,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;


type OwnState = {
    variableData: IUpdateableVariableData,
    submitTimer: number|undefined,
    locationKey: number,
    errorMessage: string,
};

class ConnectedVariableModify extends Component<Props, OwnState> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        let variableData: IUpdateableVariableData;
        if (!props.variable) {
            // This is a new variable
            variableData = {
                id: uuid.v4(),
                name: '',
                type: VariableType.TEXT,
            };
        } else {
            variableData = {
                id: props.variable.id,
                name: props.variable.name,
                type: props.variable.type,
            };
        }
        
        this.state = {
            variableData: variableData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: ''
        };
    }
    
    changeName = (name: string) => {
        let updatedIUpdateableVariableData: IUpdateableVariableData = {
            ...this.state.variableData,
            name: name
        };
        
        this.setState({
            variableData: updatedIUpdateableVariableData
        });
    }

    changeType = (type: string) => {
        const variableType = type as keyof typeof VariableType;

        let updatedIUpdateableVariableData: IUpdateableVariableData = {
            ...this.state.variableData,
            type: VariableType[variableType],
        };
        
        this.setState({
            variableData: updatedIUpdateableVariableData
        });
    }
    
    showErrorMessage = (message: string) => {
        let that = this
        
        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 5000);
        
    }
    
    validateVariableData = () => {
        
        if (!this.state.variableData.name) {
            this.showErrorMessage('Enter a valid name');
            return;
        }
        
        if (!this.state.variableData.type) {
            this.showErrorMessage('Select a type');
            return;
        }
        
        return true
    }
    
    submitVariableForm = () => {
        if (this.validateVariableData()) {
            this.props.submit(this.state.variableData);
        }
    }

    handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.changeName(e.currentTarget.value);
    }
    
    render() {
        const typeOptions = Object.keys(VariableType).map(name => {
            return {
                name: name.split('_').join(' '),
                value: name,
            }
        });
        
        return (
            <section className={styles.modifyVariable}>
                <section className={styles.heading}>{this.props.variable ? 'Update ' + this.props.variable.name : 'New Variable'}</section>
                <div className={styles.typeContainer}>
                    <SelectBox isBlock selectionPromptText={this.state.variableData.type}>
                        <DropDownList>
                            {typeOptions.map((type, index) => <ListItem name={type.name} value={type.value} key={index} onClick={this.changeType} />)}
                        </DropDownList>
                    </SelectBox>
                </div>
                <div className={styles.nameContainer}>
                    <input className={styles.nameInput} type="text" onChange={this.handleNameChange} defaultValue={this.state.variableData.name} placeholder="Enter the variable name" />
                </div>
                <div className={styles.buttonsHolder}>
                    <section className={styles.outlineButton} onClick={this.props.cancel}>Cancel</section>
                    <section className={styles.filledButton} onClick={this.submitVariableForm}>{this.props.variable ? 'Update' : 'Add'}</section>
                </div>
            </section>
        );
    }
}

const VariableModify = connect(mapStateToProps)(ConnectedVariableModify);

export default VariableModify;