import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type SubtractYearsOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const SubtractYearsOperator: FC<SubtractYearsOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator prefixSymbol="subtract" operatorSymbol="year(s) from" {...props} />
    )
}

export default SubtractYearsOperator;