import { GroupState, ADD_GROUP, UPDATE_GROUP, DELETE_GROUP, SEARCH_GROUP_TABLE, GO_TO_PAGE_GROUP_TABLE, SET_PAGE_SIZE_GROUP_TABLE, SORT_GROUP_TABLE, UPDATE_GROUP_WORKFLOWS, UPDATE_GROUP_CUSTOM_FIELD_DATA, SET_MEMBERS_FOR_GROUP, ADD_MEMBER_TO_GROUP, REMOVE_MEMBER_FROM_GROUP, GroupActionTypes, UPDATE_GROUP_DATA, FILTER_GROUP_TABLE } from './types';
import { groupTypesReducer, initialState as groupTypesInitialState } from './types/reducer';

export const initialState: GroupState = {
    byId: {},
    allEntries: [],

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    types: groupTypesInitialState,
    pageSize: 10,
    currentPageNumber: 1,

    filters: {
        types: [],
        locations: [],
        customFields: {},
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: undefined,
};

export function groupsReducer(state = initialState, action: GroupActionTypes): GroupState {
    state = {
        ...state,
        types: groupTypesReducer(state.types, action),
    };

    let connectedWorkflows: Array<string> = [];
    let connectedMembers: Array<string> = [];

    switch(action.type) {
        case ADD_GROUP:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        workflows: {},
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
                createdIds: new Set([...state.createdIds, action.payload.id]),
            };
        
        case UPDATE_GROUP:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.payload.id]),
            };
            
        case UPDATE_GROUP_WORKFLOWS:
            connectedWorkflows = state.byId[action.groupId].workflows[action.workflowTypeId] || [];
            connectedWorkflows = Array.from((new Set(connectedWorkflows)).add(action.workflowId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        workflows: {
                            ...state.byId[action.groupId].workflows,
                            [action.workflowTypeId]: connectedWorkflows,
                        }
                    }
                },
            }

        case ADD_MEMBER_TO_GROUP:
            connectedMembers = state.byId[action.groupId].members || [];
            connectedMembers = Array.from((new Set(connectedMembers)).add(action.groupId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        members: connectedMembers,
                    },
                },
            }

        case REMOVE_MEMBER_FROM_GROUP:
            connectedMembers = state.byId[action.groupId].members || [];
            connectedMembers = connectedMembers.filter(groupId => groupId !== action.groupId);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        members: connectedMembers,
                    }
                },
            }

        case SET_MEMBERS_FOR_GROUP:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        representatives: action.memberTypes === 'representatives' ? action.memberIds : state.byId[action.groupId].representatives,
                        members: action.memberTypes === 'all_members' ? action.memberIds : Array.from(new Set(state.byId[action.groupId].members.concat(action.memberIds))),
                    }
                },
            }


        case UPDATE_GROUP_CUSTOM_FIELD_DATA:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        customFields: {
                            ...state.byId[action.groupId].customFields,
                            ...action.customFieldData,
                        }
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.groupId]),
            }

        case DELETE_GROUP:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(memberId => memberId !== action.id),
                deletedIds: new Set([...state.deletedIds, action.id]),
            };


        case SEARCH_GROUP_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_GROUP_TABLE:
            return {
                ...state,
                filters: {
                    types: action.types,
                    locations: action.locations,
                    customFields: action.customFields,
                },
                currentPageNumber: 1,
            }
        
        case GO_TO_PAGE_GROUP_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }
        
        case SET_PAGE_SIZE_GROUP_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        
        case SORT_GROUP_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

        case UPDATE_GROUP_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                filters: state.filters,
                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                    createdCustomFieldIds: state.types.createdCustomFieldIds,
                    updatedCustomFieldIds: state.types.updatedCustomFieldIds,
                    deletedCustomFieldIds: state.types.deletedCustomFieldIds,
                    createdCustomFieldOptionIds: state.types.createdCustomFieldOptionIds,
                    updatedCustomFieldOptionIds: state.types.updatedCustomFieldOptionIds,
                    deletedCustomFieldOptionIds: state.types.deletedCustomFieldOptionIds,
                },
            }
        
        default:
            return state;
    }
}