import React, { Component } from 'react';
import styles from './Configuration.module.scss';
import { Link, Redirect } from "react-router-dom";

import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';

import { Permissions } from '../../../shared/store/permissions/types';
import { FieldType, INewFieldChoiceData } from '../../../shared/store/custom-fields';

import { translatePhrase } from '../../../shared/helpers/translation';

import { selectWorkflowType, unSelectWorkflowType, addWorkflowTypeCustomField, addWorkflowTypeCustomFieldOption } from '../../../shared/store/workflows/types/actions';
import { selectStatus, unSelectStatus } from '../../../shared/store/workflows/types/statuses/actions';
import { INewWorkflowTypeCustomFieldData, IUpdateableWorkflowTypeCustomFieldData } from '../../../shared/store/workflows/types/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import WorkflowTypeCustomFieldVertical from './WorkflowTypeCustomFieldVertical';
import WorkflowTypesList from './WorkflowTypesList';
import StatusesList from './WorkflowTypeStatuses';

import { ApplicationState } from '../../../shared/store/main';
import uuid from 'uuid';
import { CSVLink } from 'react-csv';
import { ReactComponent as ExportIcon } from '../../../assets/export.svg';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;
    const selectedWorkflowTypeId = state.workflows.types.selected;
    const selectedWorkflowStatusId = state.workflows.types.statuses.selected;
    const selectedWorkflowType = selectedWorkflowTypeId ? state.workflows.types.byId[selectedWorkflowTypeId] : undefined;
    const customFieldIds = selectedWorkflowTypeId ? state.workflows.types.byId[selectedWorkflowTypeId].customFields : [];

    return {
        selectedWorkflowType: selectedWorkflowTypeId,
        canHaveGroupAffiliations: !!selectedWorkflowType && selectedWorkflowType.affiliation === 'group',
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        customFieldIds,
        selectedStatus: selectedWorkflowStatusId,
        workflowTypesData: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
        unSelectWorkflowType: () => dispatch(unSelectWorkflowType()),

        selectWorkflowStatus: (id: string) => dispatch(selectStatus(id)),
        unSelectWorkflowStatus: () => dispatch(unSelectStatus()),

        addWorkflowTypeCustomField: (payload: INewWorkflowTypeCustomFieldData, workflowTypeId: string) => dispatch(addWorkflowTypeCustomField(payload, workflowTypeId)),
        addWorkflowTypeCustomFieldOption: (payload: INewFieldChoiceData, customFieldId: string) => dispatch(addWorkflowTypeCustomFieldOption(payload, customFieldId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalType: string,
    modifyingVerticalIsComputed: string,
    modifyingVerticalIsInTable: string,
    modifyingVerticalIsUnique: string,
    modifyingVerticalAffiliation: 'member'|'group',
}

class ConnectedLocations extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalType: '',
        modifyingVerticalIsComputed: 'No',
        modifyingVerticalIsInTable: 'No',
        modifyingVerticalIsUnique: 'No',
        modifyingVerticalAffiliation: 'member',
    };

    toggleCustomFieldAddForm = () => {
        this.setState((prevState) => {
            return {
                isShowingAddForm: !prevState.isShowingAddForm,
            };
        });
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldAffiliation = (value: string) => {
        const affiliationValue : 'member'|'group' = value === 'member' ? value : 'group';
        this.setState({
            modifyingVerticalAffiliation: affiliationValue,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }

    updateFieldIsUnique = (value: string) => {
        this.setState({
            modifyingVerticalIsUnique: value,
        });
    }

    addCustomField = () => {
        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        if (!this.props.selectedWorkflowType) {
            return;
        }

        this.props.addWorkflowTypeCustomField({
            name: this.state.modifyingVerticalName,
            type: FieldType[fieldType],
            isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
            isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
            isEditable: true,
            isDeletable: true,
            isUnique: this.state.modifyingVerticalIsUnique === 'Yes',
            affiliation: this.state.modifyingVerticalAffiliation,

            seedEntityVariable: uuid.v4(),
            seedAffiliationVariable: uuid.v4(),
        }, this.props.selectedWorkflowType);

        this.setState({
            isShowingAddForm: false,
            modifyingVerticalName: '',
            modifyingVerticalType: '',
            modifyingVerticalIsComputed: 'No',
            modifyingVerticalIsInTable: 'No',
            modifyingVerticalIsUnique: 'No',
        });
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];
        const affiliationTypes = ['member', 'group'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsComputed)) {
            return 'Enter a valid computed field'
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        if (this.state.modifyingVerticalIsComputed !== 'Yes') {
    
            if (!binaryTypes.includes(this.state.modifyingVerticalIsUnique)) {
                return 'Enter a valid unique field'
            }

        }

        if (!affiliationTypes.includes(this.state.modifyingVerticalAffiliation)) {
            return 'Enter a valid affiliation'
        }
        
        return true;
    }

    getCustomFieldsData = () => {
        const csvData: Array<Array<string>> = [
            ['Name', 'Type', 'Is Computed', 'Is In Table', 'Is Unique', 'Choices', 'Affiliation'],
        ];

        this.props.customFieldIds.forEach(customFieldId => {
            const customField = this.props.workflowTypesData.customFields.byId[customFieldId];
            const choiceNames = JSON.stringify(customField.choices.map(choiceId => this.props.workflowTypesData.customFieldOptions.byId[choiceId].name));

            csvData.push([customField.name, customField.type, customField.isComputed && customField.startPiece ? customField.startPiece.piece : 'No', customField.isInTable ? 'Yes' : 'No', customField.isUnique ? 'Yes' : 'No', choiceNames, customField.affiliation]);
        });

        return csvData;
    }

    storeDataToCustomFields = (data: Array<Array<string>>) => {
        if (!!this.props.selectedWorkflowType) {

            for (let i = 0; i < data.length; i += 1) {
                const [ name, type, isComputed, isInTable, isUnique, choices, affiliation ] = data[i];

                const newCustomFieldId = uuid.v4();
    
                const newCustomFieldData: IUpdateableWorkflowTypeCustomFieldData = {
                    id: newCustomFieldId,
                    name,
                    type: type as FieldType,
                    isComputed: isComputed === 'Yes',
                    isInTable: isInTable === 'Yes',
                    isUnique: isUnique === 'Yes',
                    isEditable: true,
                    isDeletable: true,
                    seedEntityVariable: uuid.v4(),
                    affiliation: affiliation === 'member' || affiliation === 'group' ? affiliation : 'member'
                };
    
                this.props.addWorkflowTypeCustomField(newCustomFieldData, this.props.selectedWorkflowType);

                let allChoices = JSON.parse(choices);

                if (!Array.isArray(allChoices)) {
                    throw new Error('The choices field must be an array');
                }

                const allValidChoices: Array<string> = allChoices.map(choice => typeof choice === 'string' ? choice : '').filter(choice => !!choice);

                if (allValidChoices.length > 0) {

                    for (let j = 0; j < allValidChoices.length; j += 1) {
                        const newCustomFieldOptionData: INewFieldChoiceData = {
                            name: allValidChoices[j],
                        }

                        this.props.addWorkflowTypeCustomFieldOption(newCustomFieldOptionData, newCustomFieldId);
                    }

                }


            }

        }
    }
        
    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const binaryOptions = ['Yes', 'No'];
        const affiliationOptions = [{
            name: 'Member',
            value: 'member',
        }, {
            name: 'Group',
            value: 'group',
        }];
        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: name.split('_').join(' '),
                value: name,
            }
        });

        const csvData = this.getCustomFieldsData();

        const addFieldForm = <ModifyForm isNew={true} submitForm={this.addCustomField} cancelForm={this.toggleCustomFieldAddForm} validateForm={this.validateVerticalForm}>
            <InputText placeholder="Name" onEnterPress={this.addCustomField} onChange={this.updateFieldName} />
            <InputText placeholder="Type" onEnterPress={this.addCustomField} onChange={this.updateFieldType} options={typeOptions} />
            <InputText placeholder="Is Computed" onEnterPress={this.addCustomField} onChange={this.updateFieldIsComputed} options={binaryOptions} default="No" />
            {this.state.modifyingVerticalIsComputed !== 'Yes' && <InputText placeholder="Is In Table" onEnterPress={this.addCustomField} onChange={this.updateFieldIsInTable} options={binaryOptions} default="No" />}
            {this.state.modifyingVerticalIsComputed !== 'Yes' && <InputText placeholder="Is Unique" onEnterPress={this.addCustomField} onChange={this.updateFieldIsUnique} options={binaryOptions} default="No" />}
            {this.props.canHaveGroupAffiliations && <InputText placeholder="Affiliation" onEnterPress={this.addCustomField} onChange={this.updateFieldAffiliation} options={affiliationOptions} default="Member" />}
        </ModifyForm>;

        if (this.props.selectedWorkflowType) {

        }

        return (<div className={styles.workflowsHolder}>
            <div className={styles.allWorkflowTypes}>
                <WorkflowTypesList heading="Workflow Types" onSelectCard={this.props.selectWorkflowType} onUnSelectCard={this.props.unSelectWorkflowType} selectedId={this.props.selectedWorkflowType} />
            </div>
            {this.props.selectedWorkflowType && 
            <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                <StatusesList 
                    heading="Statuses"
                    selectedId={this.props.selectedStatus}
                    parentId={this.props.selectedWorkflowType} 
                    onSelectCard={this.props.selectWorkflowStatus}
                    onUnSelectCard={this.props.unSelectWorkflowStatus}
                />
                {this.props.customFieldIds.map(id => <WorkflowTypeCustomFieldVertical workflowTypeId={this.props.selectedWorkflowType || ''} customFieldId={id} key={id} />)}
                {this.props.isWritable && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                    {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}>+ {translatePhrase('Add Custom Field')}</section>}
                </section>}
            </div>}
            {this.props.selectedWorkflowType && <Link to={`/flowchart/workflow/${this.props.selectedWorkflowType}`}><section className={styles.goToFlowchart + ' ignore-react-onclickoutside'}>Go to Flowchart</section></Link>}
            {this.props.selectedWorkflowType && this.props.customFieldIds.length && <CSVLink data={csvData} filename={`${this.props.workflowTypesData.byId[this.props.selectedWorkflowType].name} fields export.csv`}><button className={styles.exportFields + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}> <ExportIcon /> Export</button></CSVLink>}
        </div>);
        
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;