import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type PickLastNElementsOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const PickLastNElementsOperator: FC<PickLastNElementsOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator prefixSymbol="last" operatorSymbol="of" {...props} />
    )
}

export default PickLastNElementsOperator;