import { takeEvery, put, select, all } from 'redux-saga/effects'
import { ADD_MEMBER_TYPE, AddMemberTypeAction, AddMemberTypeCustomFieldAction, UpdateMemberTypeCustomFieldAction, ADD_MEMBER_TYPE_CUSTOM_FIELD, UPDATE_MEMBER_TYPE_CUSTOM_FIELD } from './types/types';
import { addMemberTypeCustomField, updateMemberTypeCustomFieldStartPiece, registerMemberTypeCustomFieldVariable, addMemberToMemberType, removeMemberFromMemberType, updateMemberTypeManagementForAdd, addMemberTypeManagementCustomFieldMapForAdd, updateMemberTypeManagementForUpdate, addMemberTypeManagementCustomFieldMapForUpdate } from './types/actions';
import { FieldType, IUpdateableCustomFieldData } from '../custom-fields';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { addPiece } from '../flowchart/pieces/actions';
import uuid from 'uuid';
import { PieceType, IStorePiece, IEndPiece, IVariablePiece, IAddMemberPiece, IPickFirstElementPiece, ICustomFieldPiece, IGroupPiece, IGroupedQuestionPiece, IGetValuePiece } from '../flowchart/pieces/types';
import { addFullPiece, setNextPiece } from '../flowchart/pieces/actions';
import { ApplicationState } from '../main';
import { AddMemberAction, DeleteMemberAction, UpdateMemberRequestAction, ADD_MEMBER, DELETE_MEMBER, UPDATE_MEMBER_REQUEST, IMember } from './types';
import { addMemberToLocation, removeMemberFromLocation } from '../structure/location/actions';
import { updateMember } from './actions';
import { addMemberToGroup, removeMemberFromGroup } from '../groups/actions';
import { addWorkflowType, addWorkflowTypeCustomField, registerWorkflowTypeVariable } from '../workflows/types/actions';
import { IUpdateableWorkflowTypeData, IUpdateableWorkflowTypeCustomFieldData } from '../workflows/types/types';
import { addStatus } from '../workflows/types/statuses/actions';
import { INewStatusData } from '../workflows/types/statuses/types';
import moment from 'moment';

function* createDefaultCustomFieldsAndManagementFlow(action: AddMemberTypeAction) {
    const managedAddWorkflowTypeId = uuid.v4();
    const managedAddWorkflowTypeData: IUpdateableWorkflowTypeData = {
        id: managedAddWorkflowTypeId,
        name: `Create ${action.payload.name} member`,
        project: action.payload.project,
        affiliation: 'none',
        affiliatedEntity: '',
        isCore: true,
        isManaged: true,
        seedAffiliationVariable: uuid.v4(),
        seedEntityVariable: uuid.v4(),
        startPiece: {
            piece: uuid.v4(),
            position: {
                x: 0,
                y: 0,
            },
        },
    };

    const managedUpdateWorkflowTypeId = uuid.v4();
    const managedUpdateWorkflowTypeData: IUpdateableWorkflowTypeData = {
        id: managedUpdateWorkflowTypeId,
        name: `Update ${action.payload.name} member`,
        project: action.payload.project,
        affiliation: 'member',
        affiliatedEntity: action.payload.id,
        isCore: true,
        isManaged: true,
        seedAffiliationVariable: uuid.v4(),
        seedEntityVariable: uuid.v4(),
        startPiece: {
            piece: uuid.v4(),
            position: {
                x: 0,
                y: 0,
            },
        },
    };

    const managedOpenStatus: INewStatusData = {
        name: 'Open',
        isTerminal: false,
        dueInDays: 7,
    };

    const managedClosedStatus: INewStatusData = {
        name: 'Closed',
        isTerminal: true,
    };

    yield all([
        put(updateMemberTypeManagementForAdd(action.payload.id, managedAddWorkflowTypeId, '', '')),
        put(addWorkflowType(managedAddWorkflowTypeData)),
        put(addStatus(managedOpenStatus, managedAddWorkflowTypeId)),
        put(addStatus(managedClosedStatus, managedAddWorkflowTypeId)),

        put(updateMemberTypeManagementForUpdate(action.payload.id, managedUpdateWorkflowTypeId, '', '')),
        put(addWorkflowType(managedUpdateWorkflowTypeData)),
        put(addStatus(managedOpenStatus, managedUpdateWorkflowTypeId)),
        put(addStatus(managedClosedStatus, managedUpdateWorkflowTypeId)),
    ]);

    const nameCustomField: IUpdateableCustomFieldData = {
        id: action.payload.nameFieldId,
        name: 'Name',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        isUnique: false,
        seedEntityVariable: uuid.v4(),
    };
    
    const subTitleCustomField: IUpdateableCustomFieldData = {
        id: action.payload.subTitleFieldId,
        name: 'Sub Title',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        isUnique: false,
        seedEntityVariable: uuid.v4(),
    };

    const locationCustomField: IUpdateableCustomFieldData = {
        id: action.payload.locationFieldId,
        name: 'Last seen',
        type: FieldType.LOCATION,
        isComputed: false,
        isEditable: false,
        isDeletable: false,
        isInTable: false,
        isUnique: false,
        seedEntityVariable: uuid.v4(),
    };

    yield put(addMemberTypeCustomField(nameCustomField, action.payload.id));
    yield put(addMemberTypeCustomField(subTitleCustomField, action.payload.id));
    yield put(addMemberTypeCustomField(locationCustomField, action.payload.id));
}

export function* watchMemberTypeCreationRequest() {
    yield takeEvery(ADD_MEMBER_TYPE, createDefaultCustomFieldsAndManagementFlow);
}

function* createCustomFieldForManagedMemberTypeFlow(action: AddMemberTypeCustomFieldAction) {
    const state: ApplicationState = yield select();
    const memberType = state.members.types.byId[action.memberTypeId];
    let allPutActions = [];

    if (typeof memberType.addManagement !== 'undefined') {
        const managedAddWorkflowType = state.workflows.types.byId[memberType.addManagement.workflowTypeId];
        const newAddCustomFieldId = uuid.v4();
        
        const addWorkflowTypeCustomFieldData: IUpdateableWorkflowTypeCustomFieldData = {
            ...action.payload,
            id: newAddCustomFieldId,
            seedEntityVariable: uuid.v4(),
            affiliation: 'member',
        };

        allPutActions.push(put(addWorkflowTypeCustomField(addWorkflowTypeCustomFieldData, memberType.addManagement.workflowTypeId)));
        allPutActions.push(put(addMemberTypeManagementCustomFieldMapForAdd(action.memberTypeId, action.payload.id, newAddCustomFieldId)));

        const now = moment().format('YYYY-MM-DD');

        const questionPieceData: IGroupedQuestionPiece = {
            id: uuid.v4(),
            type: PieceType.GROUPED_QUESTION,
            customFieldId: newAddCustomFieldId,
            createdTime: now,
            lastUpdatedTime: now,
        };

        // This is a custom field piece used when storing the data in the member
        const customFieldPieceData: ICustomFieldPiece = {
            id: uuid.v4(),
            type: PieceType.CUSTOM_FIELD,
            customField: newAddCustomFieldId,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // This is the variable piece used when storing the data in the member. Note that the variable value for this piece is yet to be filled in.
        const memberVariablePieceData: IVariablePiece = {
            id: uuid.v4(),
            type: PieceType.VARIABLE,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // Use the custom field piece, as well as the member piece when creating the store piece
        const storePieceData: IStorePiece = {
            id: uuid.v4(),
            type: PieceType.STORE,
            entityType: memberType.id,
            customFieldId: action.payload.id,
            dataToStore: customFieldPieceData.id,
            variablePiece: memberVariablePieceData.id,
            createdTime: now,
            lastUpdatedTime: now,
        };

        if (memberType.addManagement.lastQuestionPiece === '' || memberType.addManagement.lastStorePiece === '') {
            // This is the first custom field that is being added. 

            const terminalStatusId = managedAddWorkflowType.statuses.find(statusId => state.workflows.types.statuses.byId[statusId].isTerminal);

            // We are creating an end piece
            const endPieceData: IEndPiece = {
                id: uuid.v4(),
                type: PieceType.END,
                status: terminalStatusId,
                createdTime: now,
                lastUpdatedTime: now,
            };
            allPutActions.push(put(addFullPiece(endPieceData)));

            // The 'New Member' Variable is added. This is used when adding a member
            const newMemberVariableId = uuid.v4();
            allPutActions.push(put(addVariable({
                id: newMemberVariableId,
                name: 'New Member',
                type: VariableType.MEMBER,
            })));
            allPutActions.push(put(registerWorkflowTypeVariable(newMemberVariableId, managedAddWorkflowType.id)));

            // Now that the variable has been created, fill it into the variable piece used in "store"
            memberVariablePieceData.variable = newMemberVariableId;

            // We are getting a list of locations from the workflow's user...
            const locationsPieceData: IVariablePiece = {
                id: uuid.v4(),
                type: PieceType.VARIABLE,
                variable: managedAddWorkflowType.seedEntityVariable,
                nesting: [{
                    value: 'user',
                }, {
                    value: 'locations',
                }],
                createdTime: now,
                lastUpdatedTime: now,
            }

            allPutActions.push(put(addFullPiece(locationsPieceData)));

            // ...and picking the first of those locations (for use when adding a member)
            const firstLocationPieceData: IPickFirstElementPiece = {
                id: uuid.v4(),
                type: PieceType.PICK_FIRST_ELEMENT,
                operand: locationsPieceData.id,
                createdTime: now,
                lastUpdatedTime: now,
            }

            allPutActions.push(put(addFullPiece(firstLocationPieceData)));

            // The add member piece uses the newly created variable, and the first location of the user
            const addMemberPieceData: IAddMemberPiece = {
                id: uuid.v4(),
                type: PieceType.ADD_MEMBER,

                nextPiece: storePieceData.id,
                variable: newMemberVariableId,
                entityType: memberType.id,
                locationPiece: firstLocationPieceData.id,

                createdTime: now,
                lastUpdatedTime: now,
            }

            allPutActions.push(put(addFullPiece(addMemberPieceData)));
            storePieceData.nextPiece = endPieceData.id;

            // Create a new group piece that stores all the questions
            const groupPieceData: IGroupPiece = {
                id: uuid.v4(),
                type: PieceType.GROUP,
                nextPiece: addMemberPieceData.id,
                innerPiece: questionPieceData.id,
                createdTime: now,
                lastUpdatedTime: now,
            }
            
            // The new group piece is the first piece in the flowchart
            allPutActions.push(put(addFullPiece(groupPieceData)));
            allPutActions.push(put(setNextPiece(managedAddWorkflowType.startPiece.piece, groupPieceData.id)));
        } else {
            const previousLastQuestionPiece = state.flowchart.pieces.byId[memberType.addManagement.lastQuestionPiece];

            allPutActions.push(put(setNextPiece(memberType.addManagement.lastQuestionPiece, questionPieceData.id)));

            if ('nextPiece' in previousLastQuestionPiece) {
                questionPieceData.nextPiece = previousLastQuestionPiece.nextPiece;
            }

            const previousLastStorePiece = state.flowchart.pieces.byId[memberType.addManagement.lastStorePiece];

            if ('variablePiece' in previousLastStorePiece && typeof previousLastStorePiece.variablePiece !== 'undefined') {
                const previousLastStoreVariablePiece = state.flowchart.pieces.byId[previousLastStorePiece.variablePiece];
                if ('variable' in previousLastStoreVariablePiece) {
                    // Get the variable from the last store piece's variable piece
                    memberVariablePieceData.variable = previousLastStoreVariablePiece.variable;
                }
            }

            allPutActions.push(put(setNextPiece(memberType.addManagement.lastStorePiece, storePieceData.id)));

            if ('nextPiece' in previousLastStorePiece) {
                storePieceData.nextPiece = previousLastStorePiece.nextPiece;
            }
        }

        allPutActions.push(put(addFullPiece(questionPieceData)));
        allPutActions.push(put(addFullPiece(customFieldPieceData)));
        allPutActions.push(put(addFullPiece(memberVariablePieceData)));
        allPutActions.push(put(addFullPiece(storePieceData)));

        allPutActions.push(put(updateMemberTypeManagementForAdd(action.memberTypeId, managedAddWorkflowType.id, questionPieceData.id, storePieceData.id)));

    }

    if (typeof memberType.updateManagement !== 'undefined') {
        const managedUpdateWorkflowType = state.workflows.types.byId[memberType.updateManagement.workflowTypeId];
        const newUpdateCustomFieldId = uuid.v4();
        
        const updateWorkflowTypeCustomFieldData: IUpdateableWorkflowTypeCustomFieldData = {
            ...action.payload,
            id: newUpdateCustomFieldId,
            seedEntityVariable: uuid.v4(),
            affiliation: 'member',
        };

        allPutActions.push(put(addWorkflowTypeCustomField(updateWorkflowTypeCustomFieldData, memberType.updateManagement.workflowTypeId)));
        allPutActions.push(put(addMemberTypeManagementCustomFieldMapForUpdate(action.memberTypeId, action.payload.id, newUpdateCustomFieldId)));

        const now = moment().format('YYYY-MM-DD');

        // This is the variable piece used when getting the default data from the member.
        const memberVariablePieceDataForGet: IVariablePiece = {
            id: uuid.v4(),
            type: PieceType.VARIABLE,
            variable: managedUpdateWorkflowType.seedAffiliationVariable,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // This is the variable piece used when storing the data in the member.
        const getValuePieceData: IGetValuePiece = {
            id: uuid.v4(),
            type: PieceType.GET_VALUE,
            variablePiece: memberVariablePieceDataForGet.id,
            entityType: memberType.id,
            customFieldId: action.payload.id,
            createdTime: now,
            lastUpdatedTime: now,
        }

        const questionPieceData: IGroupedQuestionPiece = {
            id: uuid.v4(),
            type: PieceType.GROUPED_QUESTION,
            customFieldId: newUpdateCustomFieldId,
            default: getValuePieceData.id,
            createdTime: now,
            lastUpdatedTime: now,
        };

        // This is a custom field piece used when storing the data in the member
        const customFieldPieceData: ICustomFieldPiece = {
            id: uuid.v4(),
            type: PieceType.CUSTOM_FIELD,
            customField: newUpdateCustomFieldId,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // This is the variable piece used when storing the data in the member.
        const memberVariablePieceDataForStore: IVariablePiece = {
            id: uuid.v4(),
            type: PieceType.VARIABLE,
            variable: managedUpdateWorkflowType.seedAffiliationVariable,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // Use the custom field piece, as well as the member piece when creating the store piece
        const storePieceData: IStorePiece = {
            id: uuid.v4(),
            type: PieceType.STORE,
            entityType: memberType.id,
            customFieldId: action.payload.id,
            dataToStore: customFieldPieceData.id,
            variablePiece: memberVariablePieceDataForStore.id,
            createdTime: now,
            lastUpdatedTime: now,
        };

        if (memberType.updateManagement.lastQuestionPiece === '' || memberType.updateManagement.lastStorePiece === '') {
            // This is the first custom field that is being added. 

            const terminalStatusId = managedUpdateWorkflowType.statuses.find(statusId => state.workflows.types.statuses.byId[statusId].isTerminal);

            // We are creating an end piece
            const endPieceData: IEndPiece = {
                id: uuid.v4(),
                type: PieceType.END,
                status: terminalStatusId,
                createdTime: now,
                lastUpdatedTime: now,
            };
            allPutActions.push(put(addFullPiece(endPieceData)));

            storePieceData.nextPiece = endPieceData.id;

            // Create a new group piece that stores all the questions
            const groupPieceData: IGroupPiece = {
                id: uuid.v4(),
                type: PieceType.GROUP,
                nextPiece: storePieceData.id,
                innerPiece: questionPieceData.id,
                createdTime: now,
                lastUpdatedTime: now,
            }
            
            // The new group piece is the first piece in the flowchart
            allPutActions.push(put(addFullPiece(groupPieceData)));
            allPutActions.push(put(setNextPiece(managedUpdateWorkflowType.startPiece.piece, groupPieceData.id)));
        } else {
            const previousLastQuestionPiece = state.flowchart.pieces.byId[memberType.updateManagement.lastQuestionPiece];

            allPutActions.push(put(setNextPiece(memberType.updateManagement.lastQuestionPiece, questionPieceData.id)));

            if ('nextPiece' in previousLastQuestionPiece) {
                questionPieceData.nextPiece = previousLastQuestionPiece.nextPiece;
            }

            const previousLastStorePiece = state.flowchart.pieces.byId[memberType.updateManagement.lastStorePiece];

            if ('variablePiece' in previousLastStorePiece && typeof previousLastStorePiece.variablePiece !== 'undefined') {
                const previousLastStoreVariablePiece = state.flowchart.pieces.byId[previousLastStorePiece.variablePiece];
                if ('variable' in previousLastStoreVariablePiece) {
                    // Get the variable from the last store piece's variable piece
                    memberVariablePieceDataForStore.variable = previousLastStoreVariablePiece.variable;
                }
            }

            allPutActions.push(put(setNextPiece(memberType.updateManagement.lastStorePiece, storePieceData.id)));

            if ('nextPiece' in previousLastStorePiece) {
                storePieceData.nextPiece = previousLastStorePiece.nextPiece;
            }
        }

        allPutActions.push(put(addFullPiece(memberVariablePieceDataForGet)));
        allPutActions.push(put(addFullPiece(getValuePieceData)));
        allPutActions.push(put(addFullPiece(questionPieceData)));
        allPutActions.push(put(addFullPiece(customFieldPieceData)));
        allPutActions.push(put(addFullPiece(memberVariablePieceDataForStore)));
        allPutActions.push(put(addFullPiece(storePieceData)));

        allPutActions.push(put(updateMemberTypeManagementForUpdate(action.memberTypeId, managedUpdateWorkflowType.id, questionPieceData.id, storePieceData.id)));

    }

    if (allPutActions.length > 0) {
        yield all(allPutActions);
    }
}

function* createSeedFlowchartForMemberTypeCustomField(action: AddMemberTypeCustomFieldAction|UpdateMemberTypeCustomFieldAction) {

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        const state: ApplicationState = yield select();

        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {

            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'Member',
                    type: VariableType.MEMBER,
                })),

                put(registerMemberTypeCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateMemberTypeCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id)),
            ]);

        }

    }
}

function* provideReverseLinksForNewMember(action: AddMemberAction) {
    let allPutActions = [];

    allPutActions.push(put(addMemberToLocation(action.payload.id, action.payload.location)));
    allPutActions.push(put(addMemberToMemberType(action.payload.id, action.payload.type)));

    const groupTypeIds = new Set(Object.keys(action.payload.groups));

    for (const groupTypeId of groupTypeIds) {
        const newGroupIds = new Set(action.payload.groups[groupTypeId]);

        for (const newGroupId of newGroupIds) {
            allPutActions.push(put(addMemberToGroup(newGroupId, action.payload.id)));
        }
    }

    yield all(allPutActions);
}

function* removeReverseLinksForMember(action: DeleteMemberAction) {
    const currentMember: IMember = yield select(state => state.members.byId[action.id]);
    let allPutActions = [];

    allPutActions.push(put(removeMemberFromLocation(action.id, currentMember.location)));
    allPutActions.push(put(removeMemberFromMemberType(action.id, currentMember.type)));

    const groupTypeIds = new Set(Object.keys(currentMember.groups));

    for (const groupTypeId of groupTypeIds) {
        const oldGroupIds = new Set(currentMember.groups[groupTypeId]);

        for (const oldGroupId of oldGroupIds) {
            allPutActions.push(put(removeMemberFromGroup(oldGroupId, action.id)));
        }
    }

    yield all(allPutActions);
}

function* updateLocationsAndGroups(action: UpdateMemberRequestAction) {
    const currentMember: IMember = yield select(state => state.members.byId[action.payload.id]);

    let allPutActions = [];

    if (action.payload.location !== currentMember.location) {
        allPutActions.push(put(removeMemberFromLocation(action.payload.id, currentMember.location)));
        allPutActions.push(put(addMemberToLocation(action.payload.id, action.payload.location)));
    }

    if (action.payload.type !== currentMember.type) {
        allPutActions.push(put(removeMemberFromMemberType(action.payload.id, currentMember.type)));
        allPutActions.push(put(addMemberToMemberType(action.payload.id, action.payload.type)));
    }

    const groupTypeIds = new Set([...Object.keys(currentMember.groups), ...Object.keys(action.payload.groups)]);

    for (const groupTypeId of groupTypeIds) {
        const oldGroupIds = new Set(currentMember.groups[groupTypeId]);
        const newGroupIds = new Set(action.payload.groups[groupTypeId]);

        for (const oldGroupId of oldGroupIds) {
            if (!newGroupIds.has(oldGroupId)) {
                allPutActions.push(put(removeMemberFromGroup(oldGroupId, action.payload.id)));
            }
        }

        for (const newGroupId of newGroupIds) {
            if (!oldGroupIds.has(newGroupId)) {
                allPutActions.push(put(addMemberToGroup(newGroupId, action.payload.id)));
            }
        }
    }

    allPutActions.push(put(updateMember(action.payload)));

    yield all(allPutActions);
}

export function* watchMemberTypeCustomFieldCreation() {
    yield takeEvery(ADD_MEMBER_TYPE_CUSTOM_FIELD, createCustomFieldForManagedMemberTypeFlow);
}

export function* watchMemberTypeCustomFieldChanges() {
    yield takeEvery([ADD_MEMBER_TYPE_CUSTOM_FIELD, UPDATE_MEMBER_TYPE_CUSTOM_FIELD], createSeedFlowchartForMemberTypeCustomField);
}

export function* watchMemberCreationChanges() {
    yield takeEvery(ADD_MEMBER, provideReverseLinksForNewMember);
}

export function* watchMemberDeletionChanges() {
    yield takeEvery(DELETE_MEMBER, removeReverseLinksForMember);
}

export function* watchMemberUpdateChanges() {
    yield takeEvery(UPDATE_MEMBER_REQUEST, updateLocationsAndGroups);
}