import React, { Component } from 'react';
import styles from './Hierarchy.module.scss';
import { Redirect } from "react-router-dom";

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectProject, unSelectProject } from '../../../shared/store/structure/project/actions';
import { selectLevel, unSelectLevel, addLevelCustomField } from '../../../shared/store/structure/level/actions';
import { selectRole, unSelectRole, addRoleCustomField } from '../../../shared/store/structure/role/actions';

import { Permissions } from '../../../shared/store/permissions/types';

import { ApplicationState } from '../../../shared/store/main';
import ProjectsList from './ProjectsList';
import LevelsList from './LevelsList';
import RolesList from './RolesList';
import { INewCustomFieldData, FieldType } from '../../../shared/store/custom-fields';
import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';
import { translatePhrase } from '../../../shared/helpers/translation';
import LevelCustomFieldVertical from './LevelCustomFieldVertical';
import RoleCustomFieldVertical from './RoleCustomFieldVertical';
import uuid from 'uuid';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE
    const canViewHierarchy = canEditHierarchy || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;
    const selectedLevelId = state.structure.levels.selected;
    const levelCustomFieldIds = selectedLevelId ? state.structure.levels.byId[selectedLevelId].customFields : [];

    const selectedRoleId = state.structure.roles.selected;
    const roleCustomFieldIds = selectedRoleId ? state.structure.roles.byId[selectedRoleId].customFields : [];

    return {
        isReadable: canViewHierarchy,
        isWritable: canEditHierarchy,

        selectedProject: state.structure.projects.selected,
        selectedLevel: state.structure.levels.selected,
        selectedRole: state.structure.roles.selected,
        levelCustomFieldIds,
        roleCustomFieldIds,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectProject: (id: string) => dispatch(selectProject(id)),
        unSelectProject: () => dispatch(unSelectProject()),

        selectLevel: (id: string) => dispatch(selectLevel(id)),
        unSelectLevel: () => dispatch(unSelectLevel()),
        addLevelCustomField: (payload: INewCustomFieldData, levelId: string) => dispatch(addLevelCustomField(payload, levelId)),
        addRoleCustomField: (payload: INewCustomFieldData, roleId: string) => dispatch(addRoleCustomField(payload, roleId)),

        selectRole: (id: string) => dispatch(selectRole(id)),
        unSelectRole: () => dispatch(unSelectRole()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalType: string,
    modifyingVerticalIsComputed: string,
    modifyingVerticalIsInTable: string,
    modifyingVerticalIsUnique: string,
}

class ConnectedLevels extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalType: '',
        modifyingVerticalIsComputed: 'No',
        modifyingVerticalIsInTable: 'No',
        modifyingVerticalIsUnique: 'No',
    };

    toggleCustomFieldAddForm = () => {
        this.setState((prevState) => {
            return {
                isShowingAddForm: !prevState.isShowingAddForm,
            };
        });
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }

    updateFieldIsUnique = (value: string) => {
        this.setState({
            modifyingVerticalIsUnique: value,
        });
    }

    addCustomField = () => {
        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        if (this.props.selectedRole) {
            this.props.addRoleCustomField({
                name: this.state.modifyingVerticalName,
                type: FieldType[fieldType],
                isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
                isEditable: true,
                isDeletable: true,
                isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
                isUnique: this.state.modifyingVerticalIsUnique === 'Yes',
                seedEntityVariable: uuid.v4(),
            }, this.props.selectedRole);
    
            this.setState({
                isShowingAddForm: false,
                modifyingVerticalName: '',
                modifyingVerticalType: '',
                modifyingVerticalIsComputed: 'No',
                modifyingVerticalIsInTable: 'No',
                modifyingVerticalIsUnique: 'No',
            });
        } else if (this.props.selectedLevel) {
            this.props.addLevelCustomField({
                name: this.state.modifyingVerticalName,
                type: FieldType[fieldType],
                isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
                isEditable: true,
                isDeletable: true,
                isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
                isUnique: this.state.modifyingVerticalIsUnique === 'Yes',
                seedEntityVariable: uuid.v4(),
            }, this.props.selectedLevel);
    
            this.setState({
                isShowingAddForm: false,
                modifyingVerticalName: '',
                modifyingVerticalType: '',
                modifyingVerticalIsComputed: 'No',
                modifyingVerticalIsInTable: 'No',
                modifyingVerticalIsUnique: 'No',
            });
        }
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        if (this.state.modifyingVerticalIsComputed !== 'Yes') {
    
            if (!binaryTypes.includes(this.state.modifyingVerticalIsUnique)) {
                return 'Enter a valid unique field'
            }

        }
        
        return true;
    }
        
    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const binaryOptions = [{
            name: translatePhrase('Yes'),
            value: 'Yes'
        }, {
            name: translatePhrase('No'),
            value: 'No'
        }];

        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: translatePhrase(name.split('_').join(' ')),
                value: name,
            }
        });

        const addFieldForm = <ModifyForm isNew={true} submitForm={this.addCustomField} cancelForm={this.toggleCustomFieldAddForm} validateForm={this.validateVerticalForm}>
            <InputText placeholder="Name" onEnterPress={this.addCustomField} onChange={this.updateFieldName} />
            <InputText placeholder="Type" onEnterPress={this.addCustomField} onChange={this.updateFieldType} options={typeOptions} />
            <InputText placeholder="Is Computed" onEnterPress={this.addCustomField} onChange={this.updateFieldIsComputed} options={binaryOptions} default={translatePhrase('No')} />
            {this.state.modifyingVerticalIsComputed !== 'Yes' && <InputText placeholder="Is In Table" onEnterPress={this.addCustomField} onChange={this.updateFieldIsInTable} options={binaryOptions} default={translatePhrase('No')} />}
            {this.state.modifyingVerticalIsComputed !== 'Yes' && <InputText placeholder="Is Unique" onEnterPress={this.addCustomField} onChange={this.updateFieldIsUnique} options={binaryOptions} default={translatePhrase('No')} />}
        </ModifyForm>;
    
        return (<div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
            <ProjectsList heading="Projects" onSelectCard={this.props.selectProject} onUnSelectCard={this.props.unSelectProject} selectedId={this.props.selectedProject} isReadOnly={!this.props.isWritable} />
            {this.props.selectedProject && <LevelsList heading="Levels" projectId={this.props.selectedProject} onSelectCard={this.props.selectLevel} onUnSelectCard={this.props.unSelectLevel} selectedId={this.props.selectedLevel} isReadOnly={!this.props.isWritable} />}
            {this.props.selectedLevel && <RolesList heading="Roles" levelId={this.props.selectedLevel} onSelectCard={this.props.selectRole} onUnSelectCard={this.props.unSelectRole} selectedId={this.props.selectedRole} isReadOnly={!this.props.isWritable} />}

            {this.props.selectedLevel && !this.props.selectedRole && this.props.levelCustomFieldIds.map(id => <LevelCustomFieldVertical levelId={this.props.selectedLevel || ''} customFieldId={id} key={id} isReadOnly={!this.props.isWritable} />)}
            {this.props.isWritable && this.props.selectedLevel && !this.props.selectedRole && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}>+ {translatePhrase('Add Level Custom Field')}</section>}
            </section>}

            {this.props.selectedRole && this.props.roleCustomFieldIds.map(id => <RoleCustomFieldVertical roleId={this.props.selectedRole || ''} customFieldId={id} key={id} isReadOnly={!this.props.isWritable} />)}
            {this.props.isWritable && this.props.selectedRole && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}>+ {translatePhrase('Add Role Custom Field')}</section>}
            </section>}
        </div>);
    }
     
}

const Levels = connect(mapStateToProps, mapDispatchToProps)(ConnectedLevels);

export default Levels;