export const hardCodedWords = [

    //// Desktop words

    // Header
    'Search',
    'Save',
    'Saving',
    'Hi',
    'Profile',
    'Language',

    // Sidebar
    'Dashboard',
    'Structure',
    'Users',
    'Members',
    'Groups',
    'Workflows',
    'Reports',

    // Cards List
    'Click to add new card',
    'Add',
    'Cancel',
    'Added',
    'Update',
    'Updated',
    'Undo',

    // Custom fields
    'Type',
    'Yes',
    'No',
    'Is Computed',
    'Is In Table',
    'Is Unique',
    'Enter a valid name',
    'Enter a valid type',
    'Enter a valid computed field',
    'Enter a valid in-table field',
    'Enter a valid unique field',
    'Add Custom Field',
    'Country Code',
    'Phone Number',
    'Latitude',
    'Longitude',

    // Structure - Hierarchy
    'Hierarchy',
    'Projects',
    'Levels',
    'Roles',
    'Name',
    'Add Level Custom Field',
    'Add Role Custom Field',

    // Structure - Locations
    'Locations',
    'Download template',
    'Import',
    'This row does not have data for this level',
    'The parent location must be defined for lower level locations',
    'This row does not have data for this custom field',
    'Errors during import. Please download this file to fix errors and re-upload',
    'Error file',
    'Dismiss',

    // Structure - Permissions
    'Permissions',
    'General',
    'User Configuration',
    'Members Configuration',
    'Groups Configuration',
    'Workflows Configuration',
    'Reports Configuration',
    'Organization Profile',
    'Languages',
    'Member',
    'Group',
    'Workflow',
    'Report',

    // Users
    'List',
    'Configuration',
    'Show',
    'Filter Users',
    'Select all',
    'All selected',
    'Apply filter',
    'Clear filters',
    'Add User',
    'Edit User',
    'Update User',
    'Added User',
    'Updated User',

    'Sl. no',
    'Project',
    'Level',
    'Location',
    'Role',
    'Phone',
    'Password',
    'latitude',
    'longitude',

    // Members
    'Filter Members',
    'Add Member',
    'Edit Member',
    'Update Member',
    'Added Member',
    'Updated Member',

    // Groups
    'Filter Groups',
    'Add Group',
    'Edit Group',
    'Update Group',
    'Added Group',
    'Updated Group',

    // Workflows
    'Filter Workflows',
    'Add Workflow',
    'Edit Workflow',
    'Update Workflow',
    'Added Workflow',
    'Updated Workflow',


    //// Mobile words
    'Search by Member, Groups & Sub Title',
    'Search by Name, Sl no. etc',
    'No widgets to display',
    'Show Entities',
    'Search results',
    'Tap here to exit',
    'Total',
    'Search within the list below',
    'Fire Workflow',
    'Filter',
    'Reset',
    'Close',
    'Apply',
    'Due',
    'Overdue',
    'Flows',
    'All Flows',
    'My Flows',
    'Status',
    'Active',
    'Completed',
    'Show More',
    'Show Less',
    'Submit',
    'Start',
    'Resume',
    'View Details',
    'Completed',
    'Type to search',
    'Created time',
    'Due date',
    'User',
    'Select Member',
    'Select Group',

    'Sync Data',
    'Edit Profile',
    'Fetch Data',
    'Success',
    'Oops!, something went wrong',
    'Near Me',
    'Navigate',
    'Chat',
    'Activity Log',
    'Sync Log',
    'Change Password',
    'Confirm your old Password',
    'New Password',
    'Logout',
];