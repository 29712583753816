import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { watchDataFetchRequest, watchDataPushRequest, watchSaveDataRequest } from './sagas'

import { watchWidgetCreationRequest, watchWidgetDeletionRequest } from './widgets/sagas';
import { watchReportTypeCreationRequest } from './reports/types/sagas';
import { watchLevelCreationChanges, watchLevelDeletionChanges, watchLevelCustomFieldChanges } from './structure/level/sagas';
import { watchRoleCreationChanges, watchRoleDeletionChanges, watchRoleCustomFieldChanges } from './structure/role/sagas';
import { watchLocationCreationChanges, watchLocationDeletionChanges } from './structure/location/sagas';

import { watchUserCreationChanges, watchUserDeletionChanges, watchUserUpdateChanges, watchUserCustomFieldChanges } from './users/sagas';
import { watchMemberTypeCreationRequest, watchMemberTypeCustomFieldChanges, watchMemberCreationChanges, watchMemberDeletionChanges, watchMemberUpdateChanges, watchMemberTypeCustomFieldCreation } from './members/sagas';
import { watchGroupTypeCreationRequest, watchGroupTypeCustomFieldChanges, watchGroupCreationChanges, watchGroupDeletionChanges, watchGroupUpdateChanges, watchGroupMemberUpdateChanges } from './groups/sagas';
import { watchWorkflowTypeCreation, watchWorkflowTypeCustomFieldCreation, watchWorkflowChanges } from './workflows/sagas'

import { organizationReducer } from './organization/reducers';
import { OrganizationState } from './organization/types';

import { internationalizationReducer } from './internationalization/reducers';
import { InternationalizationState } from './internationalization/types';

import { permissionsReducer } from './permissions/reducers';
import { PermissionState } from './permissions/types';

import { structureReducer } from './structure/reducers';
import { StructureState } from './structure/types';

import { usersReducer } from './users/reducer';
import { UserState } from './users/types';

import { flowchartReducer } from './flowchart/reducers';
import { FlowchartState } from './flowchart/types';

import { membersReducer } from './members/reducer';
import { MemberState } from './members/types';

import { groupsReducer } from './groups/reducer';
import { GroupState } from './groups/types';

import { workflowsReducer } from './workflows/reducer';
import { WorkflowState } from './workflows/types';

import { widgetsReducer } from './widgets/reducer';
import { WidgetState } from './widgets/types';

import { reportsReducer } from './reports/reducer';
import { ReportState } from './reports/types';

import { myDataReducer } from './my-data/reducer';
import { MyDataState } from './my-data/types';


declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

export interface ApplicationState {
    internationalization: InternationalizationState,
    permissions: PermissionState,
    organization: OrganizationState,
    structure: StructureState,
    flowchart: FlowchartState,
    users: UserState,
    members: MemberState,
    groups: GroupState,
    workflows: WorkflowState,
    widgets: WidgetState,
    reports: ReportState,
    myData: MyDataState,
}

const rootReducer = combineReducers<ApplicationState>({
    internationalization: internationalizationReducer,
    permissions: permissionsReducer,
    organization: organizationReducer,
    structure: structureReducer,
    flowchart: flowchartReducer,
    users: usersReducer,
    members: membersReducer,
    groups: groupsReducer,
    workflows: workflowsReducer,
    widgets: widgetsReducer,
    reports: reportsReducer,
    myData: myDataReducer,
});

const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(watchDataFetchRequest);
sagaMiddleware.run(watchDataPushRequest);
sagaMiddleware.run(watchSaveDataRequest);

sagaMiddleware.run(watchReportTypeCreationRequest);

sagaMiddleware.run(watchWidgetCreationRequest);
sagaMiddleware.run(watchWidgetDeletionRequest);

sagaMiddleware.run(watchLevelCreationChanges);
sagaMiddleware.run(watchLevelDeletionChanges);
sagaMiddleware.run(watchLevelCustomFieldChanges);

sagaMiddleware.run(watchRoleCreationChanges);
sagaMiddleware.run(watchRoleDeletionChanges);
sagaMiddleware.run(watchRoleCustomFieldChanges);

sagaMiddleware.run(watchLocationCreationChanges);
sagaMiddleware.run(watchLocationDeletionChanges);

sagaMiddleware.run(watchUserCreationChanges);
sagaMiddleware.run(watchUserDeletionChanges);
sagaMiddleware.run(watchUserUpdateChanges);
sagaMiddleware.run(watchUserCustomFieldChanges);

sagaMiddleware.run(watchMemberTypeCreationRequest);
sagaMiddleware.run(watchMemberTypeCustomFieldCreation);
sagaMiddleware.run(watchMemberTypeCustomFieldChanges);
sagaMiddleware.run(watchMemberCreationChanges);
sagaMiddleware.run(watchMemberDeletionChanges);
sagaMiddleware.run(watchMemberUpdateChanges);

sagaMiddleware.run(watchGroupTypeCreationRequest);
sagaMiddleware.run(watchGroupTypeCustomFieldChanges);
sagaMiddleware.run(watchGroupCreationChanges);
sagaMiddleware.run(watchGroupDeletionChanges);
sagaMiddleware.run(watchGroupUpdateChanges);
sagaMiddleware.run(watchGroupMemberUpdateChanges);

sagaMiddleware.run(watchWorkflowTypeCreation);
sagaMiddleware.run(watchWorkflowTypeCustomFieldCreation);
sagaMiddleware.run(watchWorkflowChanges);

export default store;