import React, { Component } from 'react';
import styles from './DecrementingCounter.module.scss';

type OwnProps = {
    remaining: number,
};

type OwnState = {
    remainingSeconds: number,
};

class DecrementingCounter extends Component<OwnProps, OwnState> {
    constructor(props: Readonly<OwnProps>) {
        super(props);
        
        this.state = {
            remainingSeconds: props.remaining
        };
        
        window.setInterval(this.decrementSeconds, 1000);
    }
    
    decrementSeconds = () => {
        this.setState((prevState, props) => {
            return {remainingSeconds: prevState.remainingSeconds > 0 ? prevState.remainingSeconds - 1 : 0};
        });
    }
    
    render() {
        return <span className={styles.counter}>00:{this.state.remainingSeconds < 10 ? '0' + this.state.remainingSeconds : this.state.remainingSeconds}</span>;
    }
}

export default DecrementingCounter;