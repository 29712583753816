import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { INewLevelData, IUpdateableLevelData } from '../../../shared/store/structure/level/types';
import { reOrderLevels, addLevel, updateLevel, deleteLevel } from '../../../shared/store/structure/level/actions';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState 
} from '../CardTreeVertical';
import { translatePhrase } from '../../../shared/helpers/translation';

interface OwnProps extends CardTreeOwnProps {
    projectId?: string,
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewLevelData) => void,
    updateCard: (payload: IUpdateableLevelData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    let allLevelIds: Array<string> = [];

    if (ownProps.projectId) {
        allLevelIds = state.structure.projects.byId[ownProps.projectId].children;
    } else {
        allLevelIds = state.structure.levels.allEntries;
    }

    const allLevels: Array<IUpdateableLevelData> = allLevelIds.map(levelId => {
        return {
            ...state.structure.levels.byId[levelId],
            name: translatePhrase(state.structure.levels.byId[levelId].name),
        }
    });

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        cardsList: allLevels,
        selectedCard: ownProps.selectedId ? allLevels.find(level => level.id === ownProps.selectedId) : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderLevels(sourceIndex, destinationIndex, ownProps.projectId || '')),
        addCard: (payload: INewLevelData) => dispatch(addLevel(payload, ownProps.projectId || '')),
        deleteCard: (id: string) => dispatch(deleteLevel(id, ownProps.projectId || '')),
        updateCard: (payload: IUpdateableLevelData) => dispatch(updateLevel(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedLevelsList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const LevelsList = connect(mapStateToProps, mapDispatchToProps)(ConnectedLevelsList);

export default LevelsList;