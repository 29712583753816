import React, { Component } from 'react';
import styles from './UserFilter.module.scss';
import MultiSelect from '@khanacademy/react-multi-select';

import Button from '../../../widgets/form/Button';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/main';

import { filterUserTable } from '../../../shared/store/users/actions';

import { connect } from 'react-redux';
import { FieldType } from '../../../shared/store/custom-fields';
import { Dispatch } from 'redux';

type OwnProps = {
    closeFilter: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        usersData: state.users,
        customFieldsData: state.users.customFields,
        customFieldOptionsData: state.users.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,

        filters: state.users.filters,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterUserTable: (projects: Array<string>, levels: Array<string>, roles: Array<string>, locations: Array<string>, customFields: {[customFieldId: string]: Array<string>}) => dispatch(filterUserTable(projects, levels, roles, locations, customFields)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    projects: Array<string>,
    levels: Array<string>,
    roles: Array<string>,
    locations: Array<string>,

    customFields: {
        [customFieldId: string]: Array<string>,
    }
};

class ConnectedUserModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            projects: props.filters.projects,
            levels: props.filters.levels,
            roles: props.filters.roles,
            locations: props.filters.locations,
            customFields: props.filters.customFields,
        };
    }
    
    changeProjects = (projects: Array<string>) => {
        
        this.setState({
            projects,
        });
    }
    
    changeLevels = (levels: Array<string>) => {
        
        this.setState({
            levels,
        });
    }
    
    changeRoles = (roles: Array<string>) => {
        
        this.setState({
            roles,
        });
    }
    
    changeLocations = (locations: Array<string>) => {
        
        this.setState({
            locations,
        });
    }

    changeCustomFieldOptions = (customFieldId: string, options: Array<string>) => {
        const newCustomFields = {
            ...this.state.customFields,
            [customFieldId]: options,
        };

        this.setState({
            customFields: newCustomFields,
        });
    }

    submitFilters = () => {
        this.props.filterUserTable(
            this.state.projects,
            this.state.levels,
            this.state.roles,
            this.state.locations,
            this.state.customFields,
        );
        this.props.closeFilter();
    }

    clearFilters = () => {
        this.setState({
            projects: [],
            levels: [],
            roles: [],
            locations: [],
            customFields: {},
        });
        this.props.filterUserTable([], [], [], [], {});
    }
    
    render() {

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                label: translatePhrase(this.props.projectsData.byId[projectId].name),
                value: projectId,
            };
        });

        const levelsList = this.props.levelsData.allEntries.map(levelId => {
            return {
                label: translatePhrase(this.props.levelsData.byId[levelId].name),
                value: levelId,
            };
        });

        const rolesList = this.props.rolesData.allEntries.map(roleId => {
            return {
                label: translatePhrase(this.props.rolesData.byId[roleId].name),
                value: roleId,
            };
        });

        const locationsList = this.props.locationsData.allEntries.map(locationId => {
            const location = this.props.locationsData.byId[locationId];
            return {
                label: translatePhrase(location.name),
                value: locationId,
            };
        });
        
        return (
            <section className={styles.filterUsers}>
                <header>
                    <h2 className={styles.formHeading}>{translatePhrase('Filter Users')}</h2>
                    <button className={styles.cancelButton} onClick={this.props.closeFilter}><CancelIcon /> {translatePhrase('Cancel')}</button>
                </header>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={projectsList}
                            onSelectedChanged={this.changeProjects}
                            selected={this.state.projects}
                            overrideStrings={{
                                selectSomeItems: `${translatePhrase('Select all')}: ${translatePhrase('Projects')}`,
                                allItemsAreSelected: `${translatePhrase('All selected')}: ${translatePhrase('Projects')}`,
                            }}
                        />
                    </div>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={levelsList}
                            onSelectedChanged={this.changeLevels}
                            selected={this.state.levels}
                            overrideStrings={{
                                selectSomeItems: `${translatePhrase('Select all')}: ${translatePhrase('Levels')}`,
                                allItemsAreSelected: `${translatePhrase('All selected')}: ${translatePhrase('Levels')}`,
                            }}
                        />
                    </div>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={rolesList}
                            onSelectedChanged={this.changeRoles}
                            selected={this.state.roles}
                            overrideStrings={{
                                selectSomeItems: `${translatePhrase('Select all')}: ${translatePhrase('Roles')}`,
                                allItemsAreSelected: `${translatePhrase('All selected')}: ${translatePhrase('Roles')}`,
                            }}
                        />
                    </div>
                    <div className={styles.inputSegment}>
                        <MultiSelect
                            options={locationsList}
                            onSelectedChanged={this.changeLocations}
                            selected={this.state.locations}
                            overrideStrings={{
                                selectSomeItems: `${translatePhrase('Select all')}: ${translatePhrase('Locations')}`,
                                allItemsAreSelected: `${translatePhrase('All selected')}: ${translatePhrase('Locations')}`,
                            }}
                        />
                    </div>

                    {this.props.customFieldsData.allFields
                    .filter(customFieldId => {
                        if (customFieldId === this.props.usersData.nameFieldId || customFieldId === this.props.usersData.subTitleFieldId || customFieldId === this.props.usersData.locationFieldId) {
                            return false;
                        }

                        const customField = this.props.customFieldsData.byId[customFieldId];

                        return customField.isInTable && (customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
                    })
                    .map(customFieldId => {
                        const customField = this.props.customFieldsData.byId[customFieldId];
                        const customFieldOptions = customField.choices.map(optionId => {
                            return {
                                label: translatePhrase(this.props.customFieldOptionsData.byId[optionId].name),
                                value: optionId,
                            };
                        });

                        return <div className={styles.inputSegment} key={customFieldId}>
                            <MultiSelect
                                options={customFieldOptions}
                                onSelectedChanged={this.changeCustomFieldOptions.bind(this, customFieldId)}
                                selected={this.state.customFields[customFieldId] || []}
                                overrideStrings={{
                                    selectSomeItems: `${translatePhrase('Select all')}: ${translatePhrase(customField.name)}`,
                                    allItemsAreSelected: `${translatePhrase('All selected')}: ${translatePhrase(customField.name)}`,
                                }}
                            />
                        </div>
                    })}
                    
                </div>
                    
                <div className={styles.buttonHolder}>
                    <Button text={translatePhrase('Apply filter')} onClick={this.submitFilters} />
                    <Button isOutline text={translatePhrase('Clear filters')} onClick={this.clearFilters} />
                </div>
                
            </section>
        );
    }
}

const UserModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserModify);

export default UserModify;