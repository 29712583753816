import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CardType } from '../../widgets/card/Card';

import { ApplicationState } from '../../shared/store/main';
import { INewLanguageData, IUpdateableLanguageData } from '../../shared/store/internationalization/languages/types';
import { reOrderLanguages, addLanguage, deleteLanguage, updateLanguage } from '../../shared/store/internationalization/languages/action';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState 
} from '../structure/CardTreeVertical';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewLanguageData) => void,
    updateCard: (payload: IUpdateableLanguageData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const allLanguages: Array<CardType> = state.internationalization.languages.allEntries.map(languageId => {
        const language = state.internationalization.languages.byId[languageId];

        return {
            id: languageId,
            name: language.name,
        }
    })

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        cardsList: allLanguages,
        selectedCard: ownProps.selectedId ? allLanguages.find(language => language.id === ownProps.selectedId) : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderLanguages(sourceIndex, destinationIndex)),
        addCard: (payload: INewLanguageData) => dispatch(addLanguage(payload)),
        deleteCard: (id: string) => dispatch(deleteLanguage(id)),
        updateCard: (payload: IUpdateableLanguageData) => dispatch(updateLanguage(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedLanguagesList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const LanguagesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedLanguagesList);

export default LanguagesList;