import React, { Component, MouseEvent } from 'react';
import styles from './Card.module.scss';
import { Draggable } from 'react-beautiful-dnd';
import onClickOutside from 'react-onclickoutside';

import { translatePhrase } from '../../shared/helpers/translation';

import CountDown from './CountDown';

import { ReactComponent as DeleteIcon } from '../../assets/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as UndoIcon } from '../../assets/undo.svg';


export interface CardType {
    id: string,
    name: string,
    details?: string,
    isReadOnly?: boolean,
}

export type OptionalCardType = CardType & {isRequired: boolean};

type OwnProps = {
    card: CardType,

    isPotentiallyDraggable: boolean, // This checks if you will need to use the draggable library to render the card
    isDragDisabled: boolean, // This is only relevant if the draggable library is used. It checks whether the draggable library allows the cards to be dragged.

    isActive: boolean,
    isReadonly: boolean,
    isDeleteRestricted: boolean,
    isShowingEditForm: boolean,
    isShowingIndex: boolean,
    index: number,

    modifyForm: JSX.Element|undefined,

    onSelectCard: (id: string) => void,
    onUnselectCard: () => void,
    onEditCard: () => void,
    onDeleteCard: (id: string) => void
}

type OwnState = {
    isShowingEditForm: boolean,
    deleteTimer: number|undefined
}

class Card extends Component<OwnProps, OwnState> {

    constructor(props: Readonly<OwnProps>) {
        super(props);

        this.state = {
            deleteTimer: undefined,
            isShowingEditForm: props.isShowingEditForm
        }
    }

    static defaultProps = {
        isPotentiallyDraggable: false,
        isDragDisabled: true,
        isActive: false,
        isReadonly: false,
        isDeleteRestricted: false,
        isShowingEditForm: false,
        isShowingIndex: true,
        modifyForm: undefined,
    }
    
    static getDerivedStateFromProps(props: Readonly<OwnProps>, state: Readonly<OwnState>) {
        if (props.isShowingEditForm !== state.isShowingEditForm) {
            
            return {
                isShowingEditForm: props.isShowingEditForm
            };

        } else {
            return null;
        }
    }
    
    handleClickOutside = (event: MouseEvent) => {
        if (this.props.isActive) {
            this.props.onUnselectCard && this.props.onUnselectCard();
        }
    }

    editCard = () => {
        this.props.onEditCard();
        this.setState({
            isShowingEditForm: true
        });
    }
    
    deleteCard = () => {
        this.props.onDeleteCard(this.props.card.id);
    }
    
    markForDelete = () => {
        let that = this;
        const timeout = window.setTimeout(that.deleteCard, 5000);
        
        this.setState({
            deleteTimer: timeout
        });
    }
    
    cancelDelete = () => {
        window.clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined
        });
    }
    
    render() {
        /** These classes ensure that any clicks on the Card component will not 
            trigger the "handleClickOutside"  functions of the default as well 
            as the top level components. 
        
            EXTRA: "onClickOutside" is a function that wraps a component inside 
            another component. You can define your own classes that ignore 
            outside clicks when creating a wrapped component. Refer 
            "EnhancedTopCard" in this file to see how.
        */
        const onClickOutsideClasses = ' ignore-react-onclickoutside ignore-top-level-onclickoutside';

        const cardMarkup = <div className={(this.state.deleteTimer ? styles.deleting : (this.props.isActive ? styles.active : styles.normal)) + onClickOutsideClasses}>
                    {this.props.isShowingIndex && <div className={styles.index}> {this.props.index + 1} </div>}
                    <div className={styles.details}> 
                        {this.state.deleteTimer ? <div className={styles.title}>{translatePhrase('Undo')} <UndoIcon /> <CountDown remaining={5} /></div> : <div className={styles.title}>
                            <span title={this.props.card.name}>{this.props.card.name}</span>
                        </div>}
                        {!this.state.deleteTimer && this.props.card.details && <div className={styles.subTitle}>
                            <span title={this.props.card.details}>{this.props.card.details}</span>
                        </div> }
                    </div>
                    {!this.props.isReadonly && <div className={styles.modify} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.edit} onClick={this.editCard}><EditIcon /></div>
                        {!this.props.isDeleteRestricted && <div className={styles.delete} onClick={this.markForDelete}><DeleteIcon /></div>}
                    </div>}
            </div>
        const cardInnards = this.props.isActive && this.state.isShowingEditForm ? this.props.modifyForm : cardMarkup;
        
        return (
            this.props.isPotentiallyDraggable ?
                <Draggable key={this.props.card.id} draggableId={this.props.card.id} index={this.props.index} isDragDisabled={this.props.isDragDisabled}>
                    {(provided, snapshot) => {
                        return <li 
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={(e) => this.state.deleteTimer ? this.cancelDelete() : this.props.onSelectCard(this.props.card.id)}
                            className={styles.listItem}
                        >
                            {cardInnards}    
                        </li>
                    }}
                </Draggable>
            :
                <li
                    onClick={(e) => this.state.deleteTimer ? this.cancelDelete() : this.props.onSelectCard(this.props.card.id)}
                    className={styles.listItem}
                >
                    {cardInnards}    
                </li>
        );
        
    }
}

const EnhancedCard = onClickOutside(Card, {
    excludeScrollbar: true
});

class EnhancedTopCard extends Component<OwnProps> {
    render() {
        return <EnhancedCard outsideClickIgnoreClass={'ignore-top-level-onclickoutside'} {...this.props} />;
    }
}

export default EnhancedCard;

export { EnhancedTopCard };