import { ADD_WORKFLOW, UPDATE_WORKFLOW, DELETE_WORKFLOW, SEARCH_WORKFLOW_TABLE, GO_TO_PAGE_WORKFLOW_TABLE, SET_PAGE_SIZE_WORKFLOW_TABLE, SORT_WORKFLOW_TABLE, UPDATE_WORKFLOW_STATUS, UPDATE_WORKFLOW_PROCESS_STATE, IUpdateableWorkflowData, WorkflowProcessState, TRANSFER_WORKFLOW, WORKFLOW_NAVIGATE_FORWARD, WORKFLOW_NAVIGATE_BACK, ADD_TO_WORKFLOW_HISTORY, UPDATE_WORKFLOW_DUE_DATE, FilterWorkflowTableAction, FILTER_WORKFLOW_TABLE, AddWorkflowAction, TransferWorkflowAction, UpdateWorkflowAction, DeleteWorkflowAction, SearchWorkflowTableAction, GoToPageWorkflowTableAction, SetPageSizeWorkflowTableAction, SortWorkflowTableAction, UpdateWorkflowStatusAction, UpdateWorkflowDueDateAction, UpdateWorkflowProcessStateAction, AddToWorkflowHistory, WorkflowNavigateForwardAction, WorkflowNavigateBackAction, RestrictWorkflowNavigationAction, RESTRICT_WORKFLOW_NAVIGATION } from './types';
import moment from 'moment';

export function addWorkflow(payload: IUpdateableWorkflowData): AddWorkflowAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_WORKFLOW,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            lastUpdateTimeForFields: {},
            trackingUsers: [],
            history: [],
            historyIndex: 0,
        },
    }
}

export function transferWorkflow(workflowId: string, user: string): TransferWorkflowAction {
    return {
        type: TRANSFER_WORKFLOW,
        workflowId,
        user,
    }
}

export function updateWorkflow(payload: IUpdateableWorkflowData): UpdateWorkflowAction {
    return {
        type: UPDATE_WORKFLOW,
        payload,
    }
}

export function deleteWorkflow(id: string): DeleteWorkflowAction {
    return {
        type: DELETE_WORKFLOW,
        id,
    }
}

export function searchWorkflowTable(searchTerm: string): SearchWorkflowTableAction {
    return {
        type: SEARCH_WORKFLOW_TABLE,
        searchTerm,
    }
}

export function filterWorkflowTable(dues: Array<string>, types: Array<string>, statuses: Array<string>, users: Array<string>, affiliations: Array<string>, customFields: {[customFieldId: string]: Array<string>}): FilterWorkflowTableAction {
    return {
        type: FILTER_WORKFLOW_TABLE,
        dues,
        types,
        statuses,
        users,
        affiliations,
        customFields,
    }
}

export function goToPageWorkflowTable(pageNumber: number): GoToPageWorkflowTableAction {
    return {
        type: GO_TO_PAGE_WORKFLOW_TABLE,
        pageNumber,
    }
}

export function setPageSizeWorkflowTable(pageSize: number): SetPageSizeWorkflowTableAction {
    return {
        type: SET_PAGE_SIZE_WORKFLOW_TABLE,
        pageSize,
    }
}

export function sortWorkflowTable(column: string, order: 'ASC'|'DESC'): SortWorkflowTableAction {
    return {
        type: SORT_WORKFLOW_TABLE,
        column,
        order,
    }
}


export function updateStatus(workflowId: string, statusId: string): UpdateWorkflowStatusAction {
    return {
        type: UPDATE_WORKFLOW_STATUS,
        workflowId,
        statusId,
    }
}

export function updateDueDate(workflowId: string, dueDate: string): UpdateWorkflowDueDateAction {
    return {
        type: UPDATE_WORKFLOW_DUE_DATE,
        workflowId,
        dueDate,
    }
}

export function updateProcessState(processState: WorkflowProcessState, workflowId: string): UpdateWorkflowProcessStateAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: UPDATE_WORKFLOW_PROCESS_STATE,
        workflowId,
        processState,
        updateTime: now,
    }
}

export function addToHistory(processState: WorkflowProcessState, workflowId: string): AddToWorkflowHistory {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_TO_WORKFLOW_HISTORY,
        processState,
        workflowId,
        updateTime: now,
    }
}

export function restrictNavigation(workflowId: string): RestrictWorkflowNavigationAction {
    return {
        type: RESTRICT_WORKFLOW_NAVIGATION,
        workflowId,
    }
}

export function navigateForward(workflowId: string): WorkflowNavigateForwardAction {
    return {
        type: WORKFLOW_NAVIGATE_FORWARD,
        workflowId,
    }
}

export function navigateBack(workflowId: string): WorkflowNavigateBackAction {
    return {
        type: WORKFLOW_NAVIGATE_BACK,
        workflowId,
    }
}