import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type AddMonthsOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const AddMonthsOperator: FC<AddMonthsOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator prefixSymbol="add" operatorSymbol="month(s) to" {...props} />
    )
}

export default AddMonthsOperator;