import React, { Component } from 'react';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/main';
import { updateStatusPiece } from '../../../shared/store/flowchart/pieces/actions';

type OwnProps = {
    pieceId: string,
    statusIds: Array<string>,
    selectedStatusId: string|undefined,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        status: !!ownProps.selectedStatusId ? state.workflows.types.statuses.byId[ownProps.selectedStatusId] : undefined,
        statusesData: state.workflows.types.statuses,
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        updateStatusPiece: (statusId: string|undefined) => dispatch(updateStatusPiece(ownProps.pieceId, statusId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    isHoveringOverMemberPiece: boolean,
};

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedGroupedAnswerPiece extends Component<Props & FlowchartPieceProps, OwnState> {

    state = {
        isHoveringOverMemberPiece: false,
    }

    render() {
        const statusPrompt = this.props.status ? ('Status > ' + this.props.status.name) : 'Status';

        let listEntries: Array<Option>;

        listEntries = this.props.statusIds.map(statusId => {
            const status = this.props.statusesData.byId[statusId];
            return {
                name: status.name,
                value: status.id,
            };
        });

        listEntries = [{
            name: 'Current Status',
            value: '',
        }].concat(listEntries);

        return (<FlowchartPiece {...this.props}>
            <div>
                <SelectBox isRounded selectionPromptText={statusPrompt} theme="aqua">
                    <DropDownList theme="aqua">
                        {listEntries.map(listEntry => <ListItem name={listEntry.name} value={listEntry.value} key={listEntry.value} theme="aqua" onClick={this.props.updateStatusPiece} />)}
                    </DropDownList>
                </SelectBox>
            </div>
        </FlowchartPiece>);
    }
}

const GroupedAnswerPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupedAnswerPiece);

export default GroupedAnswerPiece;