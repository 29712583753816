import { SELECT_ROLE, UN_SELECT_ROLE, RE_ORDER_ROLES, ADD_ROLE_REQUEST, ADD_ROLE, DELETE_ROLE, UPDATE_ROLE, SELECT_ROLE_CUSTOM_FIELD, UN_SELECT_ROLE_CUSTOM_FIELD, ADD_ROLE_CUSTOM_FIELD, DELETE_ROLE_CUSTOM_FIELD, UPDATE_ROLE_CUSTOM_FIELD, SELECT_ROLE_CUSTOM_FIELD_OPTION, UN_SELECT_ROLE_CUSTOM_FIELD_OPTION, RE_ORDER_ROLE_CUSTOM_FIELD_OPTION, ADD_ROLE_CUSTOM_FIELD_OPTION, DELETE_ROLE_CUSTOM_FIELD_OPTION, UPDATE_ROLE_CUSTOM_FIELD_OPTION, UPDATE_ROLE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_ROLE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_ROLE_CUSTOM_FIELD_PIECE, REGISTER_ROLE_CUSTOM_FIELD_VARIABLE, SelectRoleAction, UnSelectRoleAction, ReOrderRolesAction, AddRoleRequestAction, AddRoleAction, UpdateRoleAction, DeleteRoleAction, SelectRoleCustomFieldAction, UnSelectRoleCustomFieldAction, AddRoleCustomFieldAction, DeleteRoleCustomFieldAction, UpdateRoleCustomFieldAction, UpdateRoleCustomFieldStartPieceAction, SetIsolatedRoleCustomFieldPieceAction, RemoveIsolatedRoleCustomFieldPieceAction, RegisterRoleCustomFieldVariableAction, SelectRoleCustomFieldOptionAction, UnSelectRoleCustomFieldOptionAction, ReOrderRoleCustomFieldOptionAction, AddRoleCustomFieldOptionAction, DeleteRoleCustomFieldOptionAction, UpdateRoleCustomFieldOptionAction } from './types';
import { INewRoleData, IUpdateableRoleData } from './types';
import { v4 as uuidv4 } from 'uuid';
import { INewCustomFieldData, IUpdateableCustomFieldData, INewFieldChoiceData, IUpdateableFieldChoiceData } from '../../custom-fields';
import { PiecePositionState } from '../../flowchart/pieces/types';
import moment from 'moment';

export function selectRole(id: string): SelectRoleAction {
    return {
        type: SELECT_ROLE,
        id,
    }
}

export function unSelectRole(): UnSelectRoleAction {
    return {
        type: UN_SELECT_ROLE,
    }
}

export function reOrderRoles(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderRolesAction {
    return {
        type: RE_ORDER_ROLES,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addRoleRequest(payload: INewRoleData, parentId: string): AddRoleRequestAction {
    return {
        type: ADD_ROLE_REQUEST,
        payload,
        parentId,
    }
}

export function addRole(payload: INewRoleData, parentId: string): AddRoleAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');
    
    return {
        type: ADD_ROLE,
        payload: {
            id: uuidv4(),
            ...payload,
            level: parentId,
            customFields: [],
            createdTime: now,
            lastUpdatedTime: now,
        },
        parentId,
        currentTime: now,
    }
}

export function updateRole(payload: IUpdateableRoleData): UpdateRoleAction {
    return {
        type: UPDATE_ROLE,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function deleteRole(id: string, parentId: string): DeleteRoleAction {
    return {
        type: DELETE_ROLE,
        id,
        parentId,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}



export function selectRoleCustomField(id: string): SelectRoleCustomFieldAction {
    return {
        type: SELECT_ROLE_CUSTOM_FIELD,
        id,
    }
}

export function unSelectRoleCustomField(): UnSelectRoleCustomFieldAction {
    return {
        type: UN_SELECT_ROLE_CUSTOM_FIELD,
    }
}

export function addRoleCustomField(payload: INewCustomFieldData, roleId: string): AddRoleCustomFieldAction {
    const now =  moment().format('YYYY-MM-DD');

    return {
        type: ADD_ROLE_CUSTOM_FIELD,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        roleId,
        currentTime: now,
    }
}

export function deleteRoleCustomField(id: string, roleId: string): DeleteRoleCustomFieldAction {
    return {
        type: DELETE_ROLE_CUSTOM_FIELD,
        id,
        roleId,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function updateRoleCustomField(payload: IUpdateableCustomFieldData): UpdateRoleCustomFieldAction {
    return {
        type: UPDATE_ROLE_CUSTOM_FIELD,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function updateRoleCustomFieldStartPiece(payload: PiecePositionState, customFieldId: string): UpdateRoleCustomFieldStartPieceAction {
    return {
        type: UPDATE_ROLE_CUSTOM_FIELD_START_PIECE,
        payload,
        customFieldId
    }
}

export function setIsolatedRoleCustomFieldPiece(payload: PiecePositionState, customFieldId: string): SetIsolatedRoleCustomFieldPieceAction {
    return {
        type: SET_ISOLATED_ROLE_CUSTOM_FIELD_PIECE,
        payload,
        customFieldId
    }
}

export function removeIsolatedRoleCustomFieldPiece(pieceId: string, customFieldId: string): RemoveIsolatedRoleCustomFieldPieceAction {
    return {
        type: REMOVE_ISOLATED_ROLE_CUSTOM_FIELD_PIECE,
        pieceId,
        customFieldId
    }
}

export function registerRoleCustomFieldVariable(variableId: string, customFieldId: string): RegisterRoleCustomFieldVariableAction {
    return {
        type: REGISTER_ROLE_CUSTOM_FIELD_VARIABLE,
        variableId,
        customFieldId,
    }
}


export function selectRoleCustomFieldOption(id: string): SelectRoleCustomFieldOptionAction {
    return {
        type: SELECT_ROLE_CUSTOM_FIELD_OPTION,
        id,
    }
}

export function unSelectRoleCustomFieldOption(): UnSelectRoleCustomFieldOptionAction {
    return {
        type: UN_SELECT_ROLE_CUSTOM_FIELD_OPTION,
    }
}

export function reOrderRoleCustomFieldOption(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderRoleCustomFieldOptionAction {
    return {
        type: RE_ORDER_ROLE_CUSTOM_FIELD_OPTION,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addRoleCustomFieldOption(payload: INewFieldChoiceData, parentId: string): AddRoleCustomFieldOptionAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_ROLE_CUSTOM_FIELD_OPTION,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        parentId,
        currentTime: now,
    }
}

export function deleteRoleCustomFieldOption(id: string, parentId: string): DeleteRoleCustomFieldOptionAction {
    return {
        type: DELETE_ROLE_CUSTOM_FIELD_OPTION,
        id,
        parentId,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}

export function updateRoleCustomFieldOption(payload: IUpdateableFieldChoiceData): UpdateRoleCustomFieldOptionAction {
    return {
        type: UPDATE_ROLE_CUSTOM_FIELD_OPTION,
        payload,
        currentTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
}