import React, { FC } from 'react';
import UnaryOperator, { UnaryOperatorProps } from './UnaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type GetDateOperatorProps = Omit<Omit<Omit<UnaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'isOperatorBoolean'>;

const GetDateOperator: FC<GetDateOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <UnaryOperator operatorSymbol="Get date" {...props} />
    )
}

export default GetDateOperator;