import React, { Component, ChangeEvent, KeyboardEvent } from 'react';
import styles from './Input.module.scss';


type InputProps = {
    type?: 'text'|'number'|'any',
    isDisabled: boolean,
    defaultText?: string,
    placeholderText?: string,
    minSize?: number,
    canReceiveDrag?: boolean,

    onChange?: (value: string) => void,
    onMouseOver?: () => void,
    onMouseOut?: () => void,
}

type InputState = {
    size: number,
    isErroneous: boolean,
    errorMessage: string,
}

class Input extends Component<InputProps, InputState> {

    constructor(props: Readonly<InputProps>) {
        super(props);

        this.state = {
            size: this.props.defaultText ? this.props.defaultText.length : this.props.minSize ? this.props.minSize : 1,
            isErroneous: false,
            errorMessage: '',
        };
    }

    static defaultProps = {
        isDisabled: false,
        canReceiveDrag: false,
    }

    interruptKeyPropagation = (e: KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
    }

    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value,
                that = this;

        function setInputSize(inputValue: string) {
        
            if (!inputValue) {
                that.setState({
                    size: that.props.minSize ? that.props.minSize : 1,
                });
    
                return;
            }
    
            that.setState({
                size: inputValue.length
            });
        }

        function validateInputType(inputValue: string) {

            if (that.props.type === 'number' && isNaN(Number(inputValue))) {
                that.setState({
                    isErroneous: true,
                    errorMessage: 'The format does not fit the number type'
                });
            } else {
                that.setState({
                    isErroneous: false,
                    errorMessage: ''
                })
            }

        }

        setInputSize(inputValue);
        validateInputType(inputValue);
        this.props.onChange && this.props.onChange(inputValue);
    }

    render() {
        return (
            <div className={styles.inputHolder} onMouseOver={this.props.onMouseOver} onMouseOut={this.props.onMouseOut}>
                <input disabled={this.props.isDisabled} type="text" onChange={this.handleChange} onKeyDown={this.interruptKeyPropagation} className={this.props.canReceiveDrag ? styles.dragReceivingInput : this.state.isErroneous ? styles.erroneousInput : styles.input} size={this.state.size} defaultValue={this.props.defaultText} placeholder={this.props.placeholderText} />
                {this.state.isErroneous && <div className={styles.errorMessage}>{this.state.errorMessage}</div>}
            </div>
        )
    }
}

export default Input;