import React, { Component } from 'react';
import styles from './Widget.module.scss';

import { ApplicationState } from '../../shared/store/main';
import CountDown from '../../widgets/card/CountDown';
import ShowTable from '../workflow/process/ShowTable';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { ReactComponent as DeleteIcon } from '../../assets/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as UndoIcon } from '../../assets/undo.svg';

type OwnProps = {
    entityIds: Array<string>,
    type: string,
    typeId?: string,
    customFields: Array<string>,

    widgetId: string,
    heading: string,
    roles: Array<string>,
    startingDisplayType?: 'table'|'bar'|'line'|'donut',
    isReadOnly: boolean,

    deleteWidget?: (widgetId: string) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        widget: state.widgets.byId[ownProps.widgetId]
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

type OwnState = {
    dataType: 'table'|'bar'|'line'|'donut',
    deleteTimer: number|undefined;
    deletingWidgets: Array<string>,
}

class ConnectedWidget extends Component<Props, OwnState> {

    static defaultProps = {
        isReadOnly: true,
    };

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            dataType: props.startingDisplayType || 'table',
            deleteTimer: undefined,
            deletingWidgets: [],
        }
    }
    
    deleteWidgetFromId = (deletingWidgetId: string) => {
        this.props.deleteWidget && this.props.deleteWidget(deletingWidgetId);
        this.setState(prevState => {
            return {
                deleteTimer: undefined,
                deletingWidgets: prevState.deletingWidgets.filter(widgetId => deletingWidgetId !== widgetId),
            }
        })
    }
    
    markForDelete = (widgetId: string) => {
        let that = this;
        const timeout = window.setTimeout(() => {
            that.deleteWidgetFromId(widgetId);
        }, 5000);

        this.setState(prevState => {
            return {
                deleteTimer: timeout,
                deletingWidgets: prevState.deletingWidgets.concat([widgetId]),
            };
        });
    }
    
    cancelDelete = (deletingWidgetId: string) => {
        clearTimeout(this.state.deleteTimer);
        this.setState(prevState => {
            return {
                deleteTimer: undefined,
                deletingWidgets: prevState.deletingWidgets.filter(widgetId => deletingWidgetId !== widgetId),
            }
        });
    }

    render() {

        const header = <header className={styles.showHeader}>
            <h3 className={styles.showHeading}>{this.props.heading}</h3>
            {!this.props.isReadOnly && <Link to={`dashboard/modify-widget/${this.props.widgetId}`}><button className={styles.updateButton}><EditIcon /></button></Link>}
            {!this.props.isReadOnly && this.props.widgetId && (this.state.deletingWidgets.includes(this.props.widgetId) ? <button onClick={this.cancelDelete.bind(this, this.props.widgetId)} className={styles.deleteButton}><UndoIcon /> <CountDown remaining={5} color="white" /></button> : <button onClick={this.markForDelete.bind(this, this.props.widgetId)} className={styles.deleteButton}><DeleteIcon /></button>)}
        </header>;

        return <ShowTable {...this.props} header={header} aggregation={this.props.widget.aggregation} />

    }
}

const Widget = connect(mapStateToProps)(ConnectedWidget);

export default Widget;