import { PieceType } from '../store/flowchart/pieces/types';
import { isUUID } from './utilities';
import store from '../store/main';


export function getTranslatablePhrasesFromFlowchart(pieceId: string) {
    const piecesData = store.getState().flowchart.pieces;
    const pieceToAnalyze = piecesData.byId[pieceId];

    let translatablePhrases: Array<string> = [];
    const getTranslatablePhrasesShorthand = getTranslatablePhrasesFromFlowchart;

    if ('nextPiece' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.nextPiece === 'string') {
            translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.nextPiece));
        }
    }

    if ('innerPiece' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.innerPiece === 'string') {
            translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.innerPiece));
        }
    }

    if (pieceToAnalyze.type === PieceType.SPLIT) {

        if (typeof pieceToAnalyze.ifPieceData !== 'undefined') {
            for (let ifDatum of pieceToAnalyze.ifPieceData) {
                
                if (typeof ifDatum.nextPiece === 'string') {
                    translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(ifDatum.nextPiece));
                }
            }
        }
    }

    if ('iterableVariable' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.iterableVariable === 'string') {
            if (isUUID(pieceToAnalyze.iterableVariable)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.iterableVariable));
            }
        }
    }

    if ('operand' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.operand === 'string') {
            if (isUUID(pieceToAnalyze.operand)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.operand));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.operand);
            }
        }
    }

    if ('leftOperand' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.leftOperand === 'string') {
            if (isUUID(pieceToAnalyze.leftOperand)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.leftOperand));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.leftOperand);
            }
        }
    }

    if ('rightOperand' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.rightOperand === 'string') {
            if (isUUID(pieceToAnalyze.rightOperand)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.rightOperand));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.rightOperand);
            }
        }
    }

    if ('heading' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.heading === 'string') {
            if (isUUID(pieceToAnalyze.heading)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.heading));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.heading);
            }
        }
    }

    if ('question' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.question === 'string') {
            if (isUUID(pieceToAnalyze.question)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.question));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.question);
            }
        }
    }

    if ('error' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.error === 'string') {
            if (isUUID(pieceToAnalyze.error)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.error));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.error);
            }
        }
    }

    if ('dataToSet' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.dataToSet === 'string') {
            if (isUUID(pieceToAnalyze.dataToSet)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.dataToSet));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.dataToSet);
            }
        }
    }

    if ('returnValue' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.returnValue === 'string') {
            if (isUUID(pieceToAnalyze.returnValue)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.returnValue));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.returnValue);
            }
        }
    }

    if ('variableToShow' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.variableToShow === 'string') {
            if (isUUID(pieceToAnalyze.variableToShow)) {
                translatablePhrases =  translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.variableToShow));
            } else {
                translatablePhrases =  translatablePhrases.concat(pieceToAnalyze.variableToShow);
            }
        }
    }

    return translatablePhrases.filter(phrase => isNaN(Number(phrase)));
}

export function translatePhrase(phrase: string) {
    const state = store.getState();
    const myId = state.myData.id;

    if (!isUUID(myId)) {
        return phrase;
    }

    const languageId = state.users.byId[myId].language;

    const languageTranslations = state.internationalization.translations.byLanguage[languageId];

    if (typeof languageTranslations === 'undefined') {
        return phrase;
    }

    if (!(phrase in languageTranslations)) {
        return phrase;
    }

    if (!languageTranslations[phrase]) {
        return phrase;
    }

    return languageTranslations[phrase];
}