import React, { Component, ChangeEvent } from 'react';
import styles from './Question.module.scss';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';

import { updateStatus, updateProcessState, addToHistory, addWorkflow } from '../../../shared/store/workflows/actions';
import { WorkflowProcessState, IUpdateableWorkflowData } from '../../../shared/store/workflows/types';

import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { IUser } from '../../../shared/store/users/types';
import { IGroup } from '../../../shared/store/groups/types';
import { CustomField, CustomFieldOptionsDataType, getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { IMember } from '../../../shared/store/members/types';
import { getPieceValueType } from '../../../shared/store/flowchart/helpers';

export type ChoosePieceProps = {
    workflowId: string,
    questionId: string,
    userInput: string,
    errorMessage?: string,
    overWrittenVariable?: string,
    overWrittenValue?: string,

    isDisabled?: boolean,
    isRequired?: boolean,
    isSectioned?: boolean,

    choiceInputs: {
        [questionId: string]: string,
    },

    validateAnswer: (questionId: string, answer: string, processState: WorkflowProcessState) => string,
    onInputChange: (value: string) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: ChoosePieceProps) => {
    const questionPiece = state.flowchart.pieces.byId[ownProps.questionId];

    if (questionPiece.type !== PieceType.CHOOSE && questionPiece.type !== PieceType.GROUPED_CHOOSE) {
        throw new Error('The ID should point to a piece of the question type');
    }

    if (!questionPiece.choiceVariable) {
        throw new Error('The question must point to a valid choice variable');
    }

    if (!questionPiece.variablePiece) {
        throw new Error('The question must point to a valid choice list variable');
    }

    return {
        applicationState: state,
        piecesData: state.flowchart.pieces,
        workflowData: state.workflows,
        membersData: state.members,
        questionPiece: questionPiece,
        choicesVariablePieceId: questionPiece.variablePiece,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateStatus: (workflowId: string, statusId: string) => dispatch(updateStatus(workflowId, statusId)),
        updateWorkflowProcessState: (processState: WorkflowProcessState, workflowId: string) => dispatch(updateProcessState(processState, workflowId)),
        addToHistory: (processState: WorkflowProcessState, workflowId: string) => dispatch(addToHistory(processState, workflowId)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ChoosePieceProps & StateProps & DispatchProps;

type OwnState = {
    questionText: string,
    allowedChoices: Array<string>,
    isDisabled: boolean,
    isRequired: boolean,
}

class ConnectedQuestion extends Component<Props, OwnState> {

    constructor (props: Readonly<Props>) {
        super(props);

        const workflow = this.props.workflowData.byId[this.props.workflowId];
        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        for (let questionId in this.props.choiceInputs) {
            if (this.props.choiceInputs.hasOwnProperty(questionId)) {
                const choosePiece = this.props.piecesData.byId[questionId];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('The piece must be a choose piece');
                }

                if (typeof choosePiece.choiceVariable === 'undefined') {
                    throw new Error('The piece must point to a choice variable');
                }

                processState.variables[choosePiece.choiceVariable] = this.props.choiceInputs[questionId];
            }
        }

        let choicesValue = getWorkflowPieceValue(this.props.applicationState, JSON.parse(JSON.stringify(processState)), this.props.workflowId, this.props.choicesVariablePieceId);

        if (typeof choicesValue === 'undefined') {
            choicesValue = [];
        }

        if (!Array.isArray(choicesValue)) {
            throw new Error('The choices list must point to an array of values')
        }

        if (Array.isArray(choicesValue)) {

            if (choicesValue.length > 0 && Array.isArray(choicesValue[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            choicesValue = choicesValue as Array<string>;
        }

        let allowedChoices = choicesValue;

        allowedChoices = allowedChoices.filter(choice => {
            const errorMessage = props.validateAnswer(props.questionId, choice, processState);

            return !errorMessage;
        });

        let expandedQuestion = this.props.questionPiece.question && isUUID(this.props.questionPiece.question) ? getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, this.props.questionPiece.question) : this.props.questionPiece.question;

        if (typeof expandedQuestion !== 'string') {
            throw new Error('A question value needs to be a string');
        }

        if (typeof this.props.userInput === 'undefined') {
            let defaultValue = this.props.questionPiece.default && isUUID(this.props.questionPiece.default) ? getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, this.props.questionPiece.default) : this.props.questionPiece.default;
            
            if (!!defaultValue && typeof defaultValue === 'string') {
                this.props.onInputChange(defaultValue);
            }
        }

        let isDisabled: boolean,
            isRequired: boolean,
            answer = props.userInput;

        if (typeof props.isDisabled === 'undefined') {
            const disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
        } else {
            isDisabled = props.isDisabled;
        }

        if (typeof props.isRequired === 'undefined') {
            const requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
        } else {
            isRequired = props.isRequired;
        }

        this.state = {
            questionText: expandedQuestion,
            allowedChoices,
            isDisabled,
            isRequired,
        };
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (props.isDisabled !== state.isDisabled || props.isRequired !== state.isRequired) {
            const workflow = props.workflowData.byId[props.workflowId];
            let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflow.history[workflow.historyIndex].customFields,
                lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                executionStack: workflow.history[workflow.historyIndex].executionStack,
                forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                variables: workflow.history[workflow.historyIndex].variables,
                displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
                displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
                displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
                displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
                createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
            }));

            if (props.overWrittenVariable) {
                processState.variables[props.overWrittenVariable] = props.overWrittenValue;
            }

            let isDisabled: boolean, isRequired: boolean,
            answer = props.userInput;

            if (typeof props.isDisabled === 'undefined') {
                const disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));

                isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, disabledWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
            } else {
                isDisabled = props.isDisabled;
            }

            if (typeof props.isRequired === 'undefined') {
                let requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));

                isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, requiredWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
            } else {
                isRequired = props.isRequired;
            }

            return {
                isDisabled,
                isRequired,
            };
        }

        return null;
    }

    selectChoice = (value: string) => {
        this.props.onInputChange(value);
    }

    handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.onInputChange(event.currentTarget.value);
    }

    handlePhoneChange = (value: string) => {
        this.props.onInputChange(value);
    }

    handleLocationChange = (value: string) => {
        this.props.onInputChange(value);
    }

    getCommonCustomFieldValue = (entity: IUser|IMember|IGroup, type: 'user'|'member'|'group', customField: CustomField, optionsData: CustomFieldOptionsDataType) => {
        let customFieldValue = entity.customFields[customField.id];

        return getReadableDataForCustomField(customFieldValue, customField, entity.id, type);
    }

    render() {
        let answerMarkup: JSX.Element;

        const chooseListType = getPieceValueType(this.props.choicesVariablePieceId, this.props.applicationState.flowchart.pieces, this.props.applicationState.flowchart.variables);

        switch(chooseListType) {
            case VariableType.PROJECTS_LIST:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{this.props.applicationState.structure.projects.byId[choiceId].name}</section>)
                    })}
                </section>;
                break;

            case VariableType.LEVELS_LIST:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{this.props.applicationState.structure.levels.byId[choiceId].name}</section>)
                    })}
                </section>;
                break;
        
            case VariableType.ROLES_LIST:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{this.props.applicationState.structure.roles.byId[choiceId].name}</section>)
                    })}
                </section>;
                break;

            case VariableType.LOCATIONS_LIST:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{this.props.applicationState.structure.locations.byId[choiceId].name}</section>)
                    })}
                </section>;
                break;

            case VariableType.USERS_LIST:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        const user = this.props.applicationState.users.byId[choiceId];
                        const nameField = this.props.applicationState.users.customFields.byId[this.props.applicationState.users.nameFieldId];
                        let nameOfUser = this.getCommonCustomFieldValue(user, 'user', nameField, this.props.applicationState.users.customFieldOptions);

                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{nameOfUser}</section>)
                    })}
                </section>;
                break;

            case VariableType.MEMBERS_LIST:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        const member = this.props.applicationState.members.byId[choiceId];
                        const memberType = this.props.applicationState.members.types.byId[member.type];
                        const nameField = this.props.applicationState.members.types.customFields.byId[memberType.nameFieldId];
                        let nameOfMember = this.getCommonCustomFieldValue(member, 'member', nameField, this.props.applicationState.members.types.customFieldOptions);

                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{nameOfMember}</section>)
                    })}
                </section>;
                break;

            case VariableType.GROUPS_LIST:

                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        const group = this.props.applicationState.groups.byId[choiceId];
                        const groupType = this.props.applicationState.groups.types.byId[group.type];
                        const nameField = this.props.applicationState.groups.types.customFields.byId[groupType.nameFieldId];
                        let nameOfGroup = this.getCommonCustomFieldValue(group, 'group', nameField, this.props.applicationState.groups.types.customFieldOptions);

                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{nameOfGroup}</section>)
                    })}
                </section>;
                break;
        
            default:
                answerMarkup = <section className={this.props.isSectioned ? styles.sectionedChoicesList : styles.choicesList}>
                    {this.state.allowedChoices.map(choiceId => {
                        return (<section key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>{choiceId}</section>)
                    })}
                </section>;
                break;
        }

        return <div key={this.state.questionText} id={this.props.overWrittenValue ? this.props.questionId + this.props.overWrittenValue : this.props.questionId} className={this.state.isDisabled ? styles.disabledQuestion : styles.question}>
            <section className={this.props.isSectioned ? styles.sectionedQuestionText : styles.questionText}>{this.state.questionText}{this.props.isRequired && <sup className={styles.requiredIndicator}>*</sup>}</section>
            {this.props.errorMessage && <section className={styles.errorMessage}>{this.props.errorMessage}</section>}
            {answerMarkup}
        </div>
    }

}

const Question = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestion);

export default Question;