import React, { FC } from 'react';
import GroupedStepPiece from './step-piece/GroupedStepPiece'
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import ShowPieceEssentials from './ShowPieceEssentials';


type ShowPieceProps = {
    selectedType?: 'User'|'Member'|'Group'|'Workflow'|'Text',
    selectedFields?: Array<string>,
    selectedEntityType?: string,
    nextPiece?: JSX.Element,
    variablePiece?: JSX.Element,
    textToShow?: string,
}

type Props = ShowPieceProps & FlowchartPieceProps;

const ShowPiece: FC<Props> = (props) => {
    return <GroupedStepPiece theme="aqua" {...props}>
        <ShowPieceEssentials pieceName="grouped show" {...props} />
    </GroupedStepPiece>
};

export default ShowPiece;