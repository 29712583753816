import { GroupTypeState, GroupTypeActionTypes } from './types/types';
import { CustomFieldValueType, CustomFieldDataHolder } from '../custom-fields';
import { NormalizedModel, Synchronizable } from '../normalized-model';

export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const ADD_MEMBER_TO_GROUP = 'ADD_MEMBER_TO_GROUP';
export const REMOVE_MEMBER_FROM_GROUP = 'REMOVE_MEMBER_FROM_GROUP';
export const UPDATE_GROUP_WORKFLOWS = 'UPDATE_GROUP_WORKFLOWS';
export const DELETE_GROUP = 'DELETE_GROUP';

export const UPDATE_GROUP_CUSTOM_FIELD_DATA = 'UPDATE_GROUP_CUSTOM_FIELD_DATA';
export const SET_MEMBERS_FOR_GROUP_REQUEST = 'SET_MEMBERS_FOR_GROUP_REQUEST';
export const SET_MEMBERS_FOR_GROUP = 'SET_MEMBERS_FOR_GROUP';

export const SEARCH_GROUP_TABLE = 'SEARCH_GROUP_TABLE';
export const FILTER_GROUP_TABLE = 'FILTER_GROUP_TABLE';
export const GO_TO_PAGE_GROUP_TABLE = 'GO_TO_PAGE_GROUP_TABLE';
export const SET_PAGE_SIZE_GROUP_TABLE = 'SET_PAGE_SIZE_GROUP_TABLE';
export const SORT_GROUP_TABLE = 'SORT_GROUP_TABLE';

export const UPDATE_GROUP_DATA = 'UPDATE_GROUP_DATA';

export interface INewGroupData {
    type: string,

    location: string,
    members: Array<string>,
    representatives: Array<string>,

    customFields: {
        [customFieldId: string]: CustomFieldValueType,
    }
}

export interface IUpdateableGroupData extends INewGroupData {
    id: string,
}

export interface IGroup extends IUpdateableGroupData, Synchronizable {
    archived?: boolean,
    createdTime: string,

    workflows: {
        [workflowTypeId: string]: Array<string>,
    },
}

export interface GroupState extends NormalizedModel<IGroup> {

    types: GroupTypeState,

    pageSize: number,
    currentPageNumber: number,
    filters: {
        types: Array<string>,
        locations: Array<string>,
        customFields: {
            [fieldId: string]: Array<string>,
        }
    },
    sort: {
        column: string|undefined,
        order: 'ASC'|'DESC',
    },
    searchTerm: string|undefined,
}

export interface AddGroupAction {
    type: typeof ADD_GROUP,
    payload: IGroup,
}

export interface UpdateGroupRequestAction {
    type: typeof UPDATE_GROUP_REQUEST,
    payload: IUpdateableGroupData,
}

export interface UpdateGroupAction {
    type: typeof UPDATE_GROUP,
    payload: IUpdateableGroupData,
}

export interface AddMemberToGroupAction {
    type: typeof ADD_MEMBER_TO_GROUP,
    groupId: string,
    memberId: string,
}

export interface RemoveMemberFromGroupAction {
    type: typeof REMOVE_MEMBER_FROM_GROUP,
    groupId: string,
    memberId: string,
}

export interface UpdateGroupWorkflowsAction {
    type: typeof UPDATE_GROUP_WORKFLOWS,
    groupId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface UpdateGroupCustomFieldDataAction {
    type: typeof UPDATE_GROUP_CUSTOM_FIELD_DATA,
    groupId: string,
    customFieldData: CustomFieldDataHolder
}

export interface SetMembersForGroupRequestAction {
    type: typeof SET_MEMBERS_FOR_GROUP_REQUEST,
    memberIds: Array<string>,
    memberTypes: 'representatives'|'all_members',
    groupId: string,
}

export interface SetMembersForGroupAction {
    type: typeof SET_MEMBERS_FOR_GROUP,
    memberIds: Array<string>,
    memberTypes: 'representatives'|'all_members',
    groupId: string,
}

export interface DeleteGroupAction {
    type: typeof DELETE_GROUP,
    id: string,
}


export interface SearchGroupTableAction {
    type: typeof SEARCH_GROUP_TABLE,
    searchTerm: string,
}

export interface FilterGroupTableAction {
    type: typeof FILTER_GROUP_TABLE,
    types: Array<string>,
    locations: Array<string>,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
}

export interface GoToPageGroupTableAction {
    type: typeof GO_TO_PAGE_GROUP_TABLE,
    pageNumber: number,
}

export interface SetPageSizeGroupTableAction {
    type: typeof SET_PAGE_SIZE_GROUP_TABLE,
    pageSize: number,
}

export interface SortGroupTableAction {
    type: typeof SORT_GROUP_TABLE,
    column: string,
    order: 'ASC'|'DESC'
}


export interface IUpdateGroupData {
    type: typeof UPDATE_GROUP_DATA,
    data: GroupState,
}

type GroupActions = AddGroupAction | UpdateGroupRequestAction | UpdateGroupAction | AddMemberToGroupAction | RemoveMemberFromGroupAction | UpdateGroupWorkflowsAction | UpdateGroupCustomFieldDataAction | SetMembersForGroupRequestAction | SetMembersForGroupAction | DeleteGroupAction;

type GroupTableActions = SearchGroupTableAction | FilterGroupTableAction | GoToPageGroupTableAction | SetPageSizeGroupTableAction | SortGroupTableAction;

export type GroupActionTypes = GroupActions | GroupTypeActionTypes | GroupTableActions | IUpdateGroupData;