import React, { Component } from 'react';
import styles from './CardsList.module.scss';

import { translatePhrase } from '../../shared/helpers/translation';

import TriToggleCard, { TriToggleCardType } from './TriToggleCard';


type OwnProps = {
    heading: string,

    isReadOnly: boolean,
    cards: Array<TriToggleCardType>,

    negativeText?: string,
    middleText?: string,
    positiveText?: string,
}

type OwnState = {
}

export default class CardsList extends Component<OwnProps> {

    static defaultProps = {
        isReadOnly: false,
    }
    
    render() {
    
        let cards: Array<JSX.Element> = this.props.cards.map((card, index) => {
            return <TriToggleCard 
                key={card.id} 
                isReadOnly={this.props.isReadOnly}
                card={card} 
                isActive={false}
                toggleState={card.toggleState}
                onToggle={card.onToggle}
                negativeText={this.props.negativeText}
                middleText={this.props.middleText}
                positiveText={this.props.positiveText}
            />
        });

        const className = styles.cardsList + ' ignore-react-onclickoutside ignore-top-level-onclickoutside';

        const cardsListMarkup = <div>
            <header> 
                <div className={styles.heading}>{translatePhrase(this.props.heading)} </div>
            </header>
            {cards.length > 0 && <div className={styles.content}>
                <ul className={styles.structureList}>
                    {cards}
                </ul>
            </div>}
        </div>

        return <section 
            className={className} 
        >
            {cardsListMarkup}
        </section>;
    }
        
}