import { SELECT_LANGUAGE, UN_SELECT_LANGUAGE, ADD_LANGUAGE, DELETE_LANGUAGE, UPDATE_LANGUAGE, RE_ORDER_LANGUAGES, LanguageState } from './types';
import { InternationalizationActionTypes, UPDATE_INTERNATIONALIZATION_DATA } from '../types';
import { reOrderList } from '../../../helpers/utilities';

const initialState: LanguageState = {  
    byId:{},
    allEntries:[],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function languagesReducer(state = initialState, action: InternationalizationActionTypes): LanguageState {
    switch(action.type) {

        case SELECT_LANGUAGE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_LANGUAGE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_LANGUAGES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_LANGUAGE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
            }
        
        case DELETE_LANGUAGE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(entry => entry !== action.id),
                selected: undefined,
            }
        
        case UPDATE_LANGUAGE:
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.id]: {
                            ...state.byId[action.payload.id],
                            ...action.payload,
                        },
                    },
                }

            
        // Internationalization actions
        case UPDATE_INTERNATIONALIZATION_DATA:
            return {
                ...action.data.languages,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }
        

        default:
            return state
    }
}