import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const UN_SELECT_LANGUAGE = 'UN_SELECT_LANGUAGE';
export const RE_ORDER_LANGUAGES = 'RE_ORDER_LANGUAGES';
export const ADD_LANGUAGE_REQUEST = 'ADD_LANGUAGE_REQUEST';
export const ADD_LANGUAGE = 'ADD_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';


export interface INewLanguageData {
    name: string;
}

export interface IUpdateableLanguageData extends INewLanguageData {
    id: string,
}

export interface ILanguage extends IUpdateableLanguageData, Synchronizable {
    archived?: boolean,
}

export interface LanguageState extends NormalizedModel<ILanguage> {
    selected: string|undefined,
}

export interface SelectLanguageAction {
    type: typeof SELECT_LANGUAGE,
    id: string,
}

export interface UnSelectLanguageAction {
    type: typeof UN_SELECT_LANGUAGE,
}

export interface ReOrderLanguagesAction {
    type: typeof RE_ORDER_LANGUAGES,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddLanguageRequestAction {
    type: typeof ADD_LANGUAGE_REQUEST,
    payload: INewLanguageData,
}

export interface AddLanguageAction {
    type: typeof ADD_LANGUAGE,
    payload: ILanguage,
}

export interface DeleteLanguageAction {
    type: typeof DELETE_LANGUAGE,
    id: string,
}

export interface UpdateLanguageAction {
    type: typeof UPDATE_LANGUAGE,
    payload: IUpdateableLanguageData,
}

export type LanguageActionTypes = SelectLanguageAction | UnSelectLanguageAction | ReOrderLanguagesAction | AddLanguageRequestAction | AddLanguageAction | DeleteLanguageAction | UpdateLanguageAction;