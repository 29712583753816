import React, { Component } from 'react';
import styles from './OaaSApp.module.scss';
import { Switch, Route } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../shared/store/main';
import { connect } from 'react-redux';

import PageHeader from './page-header/PageHeader';
import Sidebar from './sidebar/Sidebar';
import OrganizationProfile from './organization-profile/OrganizationProfile';
import Languages from './languages/Languages';

import Dashboard from './dashboard/Dashboard';
import ModifyWidget from './dashboard/ModifyWidget';
import Structure from './structure/Structure';
import Users from './users/Users';
import Members from './members/Members';
import Groups from './groups/Groups';
import Workflows from './workflows/Workflows';
import WorkflowTypeFlowchart from './workflows/flowchart/WorkflowTypeFlowchart';
import ComputedFieldFlowchart from './workflows/flowchart/ComputedFieldFlowchart';
import WorkflowProcess from './workflow/process/Process';
import Reports from './reports/Reports';
import Chat from './chat/Chat';
import { Dispatch } from 'redux';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import { initiateFetchAppData } from '../shared/store/actions'

type OwnProps = {};

type OwnState = {
    code: string,
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        organization: state.organization
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchAppData: () => dispatch(initiateFetchAppData())
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;


type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

class ConnectedOaaSApp extends Component<Props, OwnState> {

    state : OwnState = {
        code: '0',
    }
    
    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (state.code && props.organization.code !== state.code) {
            
            document.documentElement.style.setProperty('--primary-color', props.organization.primary_color);
            document.documentElement.style.setProperty('--contrast-color', props.organization.contrast_color);
            document.documentElement.style.setProperty('--primary-gradient', props.organization.primary_gradient);
            
            return {
                code: props.organization.code
            }
        } else {
            return null;
        }
    }
    
    componentDidMount() {
        this.props.fetchAppData();
    }
    
    render() {
        const routes = [{
            path: '/profile',
            name: 'Profile',
            Component: OrganizationProfile,
        }, {
            path: '/dashboard/modify-widget/:id',
            name: 'Update Widget',
            Component: ModifyWidget,
        }, {
            path: '/dashboard/modify-widget',
            name: 'Add Widget',
            Component: ModifyWidget,
        }, {
            path: '/dashboard',
            name: 'Dashboard',
            Component: Dashboard,
        }, {
            path: '/structure',
            name: 'Structure',
            Component: Structure,
        }, {
            path: '/users',
            name: 'Users',
            Component: Users,
        }, {
            path: '/members',
            name: 'Members',
            Component: Members,
        }, {
            path: '/groups',
            name: 'Groups',
            Component: Groups,
        }, {
            path: '/workflows',
            name: 'Workflows',
            Component: Workflows,
        }, {
            path: '/flowchart/workflow/:id',
            name: 'WorkflowTypeFlowchart',
            Component: WorkflowTypeFlowchart,
        }, {
            path: '/flowchart/field/:fieldId',
            name: 'ComputedFieldFlowchart',
            Component: ComputedFieldFlowchart,
        }, {
            path: '/workflow/:id/execute',
            name: 'WorkflowProcess',
            Component: WorkflowProcess,
        }, {
            path: '/reports',
            name: 'Reports',
            Component: Reports,
        }, {
            path: '/languages',
            name: 'Languages',
            Component: Languages,
        }, {
            path: '/chat',
            name: 'Chat',
            Component: Chat,
        }];

        return (
            <div className={styles.backgroundColorProvider}>
                <Sidebar />
                <PageHeader />
                <TransitionGroup>
                    <CSSTransition
                        key={this.props.location.key}
                        timeout={{ enter: 300, exit: 300 }}
                        classNames={{
                            enter: styles.pageEnter,
                            enterActive: styles.pageEnterActive,
                            exit: styles.pageExit,
                            exitActive: styles.pageExitActive,
                        }}
                    >
                        <Switch>
                            {routes.map(({path, Component}) => <Route key={path} path={path} children={<Component />} />)}
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
        
    }
}

const OaaSApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedOaaSApp) as any);
export default OaaSApp;