import { MyDataState, MyDataActions, UPDATE_MY_DATA, START_DATA_PUSH, COMPLETE_DATA_PUSH } from './types';
import { widgetsReducer, initialState as widgetsInitialState } from './widgets/reducer';
import { FetchDataRquestAction, FETCH_DATA_REQUEST } from '../types';

const initialState: MyDataState = {
    id: '',
    token: '',
    isPushingData: false,
    isLoaded: false,

    widgets: widgetsInitialState,
}

export function myDataReducer(state = initialState, action: MyDataActions | FetchDataRquestAction) {
    state = {
        ...state,
        widgets: widgetsReducer(state.widgets, action),
    }

    switch(action.type) {
        case UPDATE_MY_DATA:
            return {
                ...action.data,
                widgets: {
                    ...action.data.widgets,
                    updatedIds: state.widgets.updatedIds,
                    createdConfigurations: state.widgets.createdConfigurations,
                    deletedConfigurations: state.widgets.deletedConfigurations,
                },
                isLoaded: true,
            }
        
        case FETCH_DATA_REQUEST:
            return {
                ...state,
                isLoaded: false,
            }

        case START_DATA_PUSH:
            return {
                ...state,
                isPushingData: true,
            }

        case COMPLETE_DATA_PUSH:
            return {
                ...state,
                isPushingData: false,
            }
                
        default:
            return state;
    }
}