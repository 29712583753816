import React, { FC } from 'react';
import StepPiece from './step-piece/StepPiece';
import styles from './step-piece/StepPiece.module.scss';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';


type SuccessPieceProps = {
    errorVariableText?: string,
}

type Props = SuccessPieceProps & FlowchartPieceProps;

const SuccessPiece: FC<Props> = (props) => {

    return (
        <StepPiece theme="camo" {...props} hasLowerLock={false}>
            <div className={styles.text}>Success</div>
        </StepPiece>
    )
}

export default SuccessPiece;