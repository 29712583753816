import React, { Component } from 'react';
import styles from './Dashboard.module.scss';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { Resizable } from 're-resizable';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { updateWidgetSize, updateWidgetPosition } from '../../shared/store/my-data/widgets/actions';
import { deleteWidget } from '../../shared/store/widgets/actions';
import { getWidgetValue } from '../../shared/store/flowchart/helpers/widget';

import { ApplicationState } from '../../shared/store/main';
import { Direction } from 're-resizable/lib/resizer';

import Widget from './Widget';
import { DefaultFlowchartProcessState } from '../../shared/store/flowchart/types';
import { translatePhrase } from '../../shared/helpers/translation';
import { isUUID } from '../../shared/helpers/utilities';
import { getAllLocationsVisibleToUser } from '../../shared/helpers/locations';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {

    return {
        myRole: state.users.byId.hasOwnProperty(state.myData.id) ? state.users.byId[state.myData.id].role : undefined,
        applicationState: state,
        widgetsData: state.widgets,
        myWidgetsData: state.myData.widgets,
        myId: state.myData.id,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateWidgetSize: (width: number, height: number, widgetId: string,) => dispatch(updateWidgetSize(width, height, widgetId)),
        updateWidgetPosition: (x: number, y: number, widgetId: string,) => dispatch(updateWidgetPosition(x, y, widgetId)),
        deleteWidget: (id: string) => dispatch(deleteWidget(id)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isResizing: boolean,
}

class ConnectedDashboard extends Component<Props, OwnState> {
    state = {
        isResizing: false,
    };

    handleResizeStart = () => {
        this.setState({
            isResizing: true,
        });
    };

    handleResizeEnd = (widgetId: string, event: MouseEvent | TouchEvent, direction: Direction, refToElement: HTMLDivElement) => {
        this.setState({
            isResizing: false,
        });

        this.props.updateWidgetSize(refToElement.clientWidth, refToElement.clientHeight, widgetId);
    };

    handleDrag = (e: DraggableEvent) => {
        if (this.state.isResizing) {
            e.stopPropagation();
            return false;
        }
    }

    handleDragEnd = (widgetId: string, e: DraggableEvent, data: DraggableData) => {
        this.props.updateWidgetPosition(data.x, data.y, widgetId);
    }

    getWidgetMarkup(widgetId: string, isReadOnly: boolean) {
        const widget = this.props.widgetsData.byId[widgetId];
        const widgetConfiguration = this.props.myWidgetsData.byId[widgetId] || {};

        let visibleUsers: Array<string> = [],
            visibleMembers: Array<string> = [],
            visibleGroups: Array<string> = [],
            visibleWorkflows: Array<string> = [];

        if (isUUID(this.props.myId)) {
            const visibleLocationIds = getAllLocationsVisibleToUser(this.props.myId);
        
            visibleLocationIds.forEach(locationId => {
                const location = this.props.applicationState.structure.locations.byId[locationId];
                visibleUsers = visibleUsers.concat(location.users);
                visibleMembers = visibleMembers.concat(location.members);
                visibleGroups = visibleGroups.concat(location.groups);
            });

            const widgetUser = this.props.applicationState.users.byId[this.props.myId];

            for (const workflowTypeId in widgetUser.workflows) {
                visibleWorkflows = visibleWorkflows.concat(widgetUser.workflows[workflowTypeId]);
            }

            visibleUsers.forEach(userId => {
                const user = this.props.applicationState.users.byId[userId];
    
                for (const workflowTypeId in user.workflows) {
                    visibleWorkflows = visibleWorkflows.concat(user.workflows[workflowTypeId]);
                }
            });

            visibleMembers.forEach(memberId => {
                const member = this.props.applicationState.members.byId[memberId];

                for (const workflowTypeId in member.workflows) {
                    visibleWorkflows = visibleWorkflows.concat(member.workflows[workflowTypeId]);
                }
            });

            visibleGroups.forEach(groupId => {
                const group = this.props.applicationState.groups.byId[groupId];

                for (const workflowTypeId in group.workflows) {
                    visibleWorkflows = visibleWorkflows.concat(group.workflows[workflowTypeId]);
                }
            });

            visibleWorkflows = Array.from(new Set(visibleWorkflows));
        } else {
            visibleUsers = this.props.applicationState.users.allEntries;
            visibleMembers = this.props.applicationState.members.allEntries;
            visibleGroups = this.props.applicationState.groups.allEntries;
            visibleWorkflows = this.props.applicationState.workflows.allEntries;
        }

        let entityIds: Array<string> = [];

        switch (widget.type) {
            case 'User':
                if (widget.typeId) {
                    entityIds = visibleUsers.filter(userId => this.props.applicationState.users.byId[userId].role === widget.typeId).slice();
                } else {
                    entityIds = visibleUsers.slice();
                }
                break;
            
            case 'Member':
                if (widget.typeId) {
                    entityIds = visibleMembers.filter(memberId => this.props.applicationState.members.byId[memberId].type === widget.typeId);
                } else {
                    entityIds = visibleMembers.slice();
                }
                break;
            
            case 'Group':
                if (widget.typeId) {
                    entityIds = visibleGroups.filter(groupId => this.props.applicationState.groups.byId[groupId].type === widget.typeId);
                } else {
                    entityIds = visibleGroups.slice();
                }
                break;
            
            case 'Workflow':
                if (widget.typeId) {
                    entityIds = visibleWorkflows.filter(workflowId => this.props.applicationState.workflows.byId[workflowId].type === widget.typeId);
                } else {
                    entityIds = visibleWorkflows.slice();
                }
                break;
        }

        const filteredEntityIds = entityIds.filter(entityId => {
            const processState: DefaultFlowchartProcessState = {
                variables: {
                    [widget.seedEntityVariable]: entityId,
                },
                customFields: {},
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            }

            try {
                const filterResult = widget.startPiece ? !!getWidgetValue(this.props.applicationState, processState, widget.id, widget.startPiece.piece) : true;
    
                return filterResult;
            } catch {
                return true;
            }
        });

        return <Draggable key={widgetId} disabled={this.state.isResizing} onDrag={this.handleDrag} onStop={this.handleDragEnd.bind(this, widgetId)} defaultPosition={(widgetConfiguration.x && widgetConfiguration.y && {x: Math.abs(widgetConfiguration.x), y: Math.abs(widgetConfiguration.y)}) || undefined}>
            <div>
                <Resizable
                    defaultSize={{
                        width: widgetConfiguration.width ? widgetConfiguration.width : 'auto',
                        height: widgetConfiguration.height ? widgetConfiguration.height : 'auto',
                    }}
                    onResizeStart={this.handleResizeStart}
                    onResizeStop={this.handleResizeEnd.bind(this, widgetId)}
                >
                    <div className={styles.showDataHolder}>
                        <Widget
                            heading={widget.name}
                            isReadOnly={isReadOnly}
                            widgetId={widgetId}
                            type={widget.type}
                            typeId={widget.typeId}
                            customFields={widget.customFields}
                            entityIds={filteredEntityIds}
                            roles={widget.roles}
                            startingDisplayType={widget.displayType}
                            deleteWidget={this.props.deleteWidget}
                        />
                    </div>
                </Resizable>
            </div>
        </Draggable>
    }

    render() {

        if (!this.props.applicationState.myData.isLoaded) {
            return <div></div>;
        }

        const widgetIdsInRole = this.props.myRole && this.props.myRole in this.props.widgetsData.byRole ? this.props.widgetsData.byRole[this.props.myRole] : [];
        const newWidgetIds = widgetIdsInRole.filter(widgetId => !this.props.myWidgetsData.myWidgets.includes(widgetId));

        const myWidgetsMarkup = this.props.myWidgetsData.myWidgets.map(widgetId => this.getWidgetMarkup(widgetId, !!0));
        const roleWidgetsMarkup = newWidgetIds.map(widgetId => this.getWidgetMarkup(widgetId, true));

        return (<section className={styles.FocusSpace}>
            <header className={styles.pageHeader}>
                <h2 className={styles.heading}>{translatePhrase('Dashboard')}</h2>
                <Link to="/dashboard/modify-widget"><button className={styles.addButton}>+ {translatePhrase('Add Widget')}</button></Link>
            </header>
            <section className={styles.widgets}>
                {myWidgetsMarkup}
                {roleWidgetsMarkup}
            </section>
        </section>);
    }
}

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(ConnectedDashboard);

export default Dashboard;