import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setWorkflowType, setWorkflowStatus, setWorkflowDueDate, setWorkflowAffiliationVariable, setStartWorkflowIsAsync } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { valuePieceSlotTarget } from './utilities';


type StartWorkflowPieceProps = {
    nextPiece?: JSX.Element,

    workflowType?: string,
    workflowStatus?: string,
    workflowIsAsync?: boolean,
    workflowDueDatePiece?: JSX.Element,
    workflowAffiliationVariablePiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        workflowTypes: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setStartWorkflowType: (pieceId: string, workflowType: string) => dispatch(setWorkflowType(pieceId, workflowType)),
        setStartWorkflowStatus: (pieceId: string, status: string) => dispatch(setWorkflowStatus(pieceId, status)),
        setStartWorkflowDueDate: (pieceId: string, dueDate: string) => dispatch(setWorkflowDueDate(pieceId, dueDate)),
        setStartWorkflowAffiliationVariable: (pieceId: string, affiliationVariable: string) => dispatch(setWorkflowAffiliationVariable(pieceId, affiliationVariable)),
        setStartWorkflowIsAsync: (pieceId: string, isAsync: boolean) => dispatch(setStartWorkflowIsAsync(pieceId, isAsync)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StartWorkflowPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type OwnState = {
    isHoveringOverDueDateVariablePiece: boolean,
    isHoveringOverAffiliationVariablePiece: boolean,
}

class ConnectedStartWorkflowPiece extends Component<Props, OwnState> {

    state = {
        isHoveringOverDueDateVariablePiece: false,
        isHoveringOverAffiliationVariablePiece: false,
    };

    handleHoverOverAffiliationVariablePiece = () => {
        this.setState({
            isHoveringOverAffiliationVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfAffiliationVariablePiece = () => {
        this.setState({
            isHoveringOverAffiliationVariablePiece: false,
        });
    };

    handleHoverOverDueDateVariablePiece = () => {
        this.setState({
            isHoveringOverDueDateVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDueDateVariablePiece = () => {
        this.setState({
            isHoveringOverDueDateVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverDueDateVariablePiece || this.state.isHoveringOverAffiliationVariablePiece)) {

            if (this.state.isHoveringOverAffiliationVariablePiece) {
                this.props.setStartWorkflowAffiliationVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverDueDateVariablePiece) {
                this.props.setStartWorkflowDueDate(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverAffiliationVariablePiece: false,
                isHoveringOverDueDateVariablePiece: false,
            });
        }
    }

    setSynchrony = (pieceId: string, value: string) => {
        this.props.setStartWorkflowIsAsync(pieceId, value === 'true');
    }

    render() {

        let workflowTypesMarkup: Array<JSX.Element>;

        workflowTypesMarkup = this.props.workflowTypes.allEntries.map(workflowTypeId => <ListItem key={workflowTypeId} theme="aqua" name={this.props.workflowTypes.byId[workflowTypeId].name} value={workflowTypeId} onClick={this.props.setStartWorkflowType.bind(this, this.props.pieceId)} />);

        
        let workflowStatesMarkup: Array<JSX.Element> | undefined;

        if (this.props.workflowType) {
            const workflowType = this.props.workflowTypes.byId[this.props.workflowType];

            workflowStatesMarkup = workflowType.statuses.filter(statusId => !this.props.workflowTypes.statuses.byId[statusId].isTerminal).map(statusId => <ListItem key={statusId} theme="aqua" name={this.props.workflowTypes.statuses.byId[statusId].name} value={statusId} onClick={this.props.setStartWorkflowStatus.bind(this, this.props.pieceId)} />);
        }


        const workflowTypesBox = <SelectBox theme="dark-aqua" selectionPromptText={this.props.workflowType ? this.props.workflowTypes.byId[this.props.workflowType].name : "Select a workflow type"}>
            <DropDownList theme="aqua">
                {workflowTypesMarkup}
            </DropDownList>
        </SelectBox>

        const workflowStatesBox = <SelectBox theme="dark-aqua" selectionPromptText={this.props.workflowStatus ? this.props.workflowTypes.statuses.byId[this.props.workflowStatus].name : "Select a workflow status"}>
            <DropDownList theme="aqua">
                {workflowStatesMarkup}
            </DropDownList>
        </SelectBox>

        const synchronityBox = <SelectBox theme="dark-aqua" selectionPromptText={this.props.workflowIsAsync ? 'Asynchronously' : 'Synchronously'}>
            <DropDownList theme="aqua">
                <ListItem theme="aqua" name="Synchronously" value="false" onClick={this.setSynchrony.bind(this, this.props.pieceId)} />
                <ListItem theme="aqua" name="Asynchronously" value="true" onClick={this.setSynchrony.bind(this, this.props.pieceId)} />
            </DropDownList>
        </SelectBox>
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.text}>trigger</div>
                {workflowTypesBox}
                <div className={styles.text}>with status</div>
                {workflowStatesBox}
                <div className={styles.text}>with due date</div>
                {this.props.workflowDueDatePiece ? this.props.workflowDueDatePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverDueDateVariablePiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverDueDateVariablePiece} onMouseOut={this.handleHoverOutOfDueDateVariablePiece} />}
                <div className={styles.text}>affiliated with</div>
                {this.props.workflowAffiliationVariablePiece ? this.props.workflowAffiliationVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverAffiliationVariablePiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverAffiliationVariablePiece} onMouseOut={this.handleHoverOutOfAffiliationVariablePiece} />}
                <div className={styles.text}>fired</div>
                {synchronityBox}
            </StepPiece>
        )

    }
}

const StartWorkflowPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStartWorkflowPiece);

export default StartWorkflowPiece;