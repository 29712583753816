import { reOrderList, getReadableDate } from '../helpers/utilities';
import { PiecePositionState } from './flowchart/pieces/types';
import { VariableValueType, DefaultFlowchartProcessState } from './flowchart/types';
import { getLevelComputedFieldValue } from './flowchart/helpers/custom-fields/level';
import { getRoleComputedFieldValue } from './flowchart/helpers/custom-fields/role';
import { getUserComputedFieldValue } from './flowchart/helpers/custom-fields/user';
import { getMemberComputedFieldValue } from './flowchart/helpers/custom-fields/member';
import { getGroupComputedFieldValue } from './flowchart/helpers/custom-fields/group';
import { getWorkflowComputedFieldValue } from './flowchart/helpers/custom-fields/workflow';
import store from './main'
import { WorkflowTypeCustomField } from './workflows/types/types';
import { translatePhrase } from '../helpers/translation';
import moment from 'moment';

export enum FieldType {
    NUMBER = 'NUMBER',
    TEXT = 'TEXT',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    FILE = 'FILE',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',

    PHONE = 'PHONE',
    LOCATION = 'LOCATION',
}

export type CustomFieldValueType = string|Array<string>|undefined|boolean|number;

export type CustomFieldDataHolder = {
    [fieldId: string]: CustomFieldValueType
}

export interface INewFieldChoiceData {
    name: string,
}

export interface IUpdateableFieldChoiceData extends INewFieldChoiceData {
    id: string,
}

export interface FieldChoice extends IUpdateableFieldChoiceData {
    archived?: boolean,
}

export interface INewCustomFieldData {
    name: string,
    type: FieldType,
    isComputed: boolean,
    isUnique: boolean,
    isInTable: boolean,
    isEditable: boolean,
    isDeletable: boolean,
    seedEntityVariable: string,
}

export interface IUpdateableCustomFieldData extends INewCustomFieldData {
    id: string,
}

export interface CustomField extends IUpdateableCustomFieldData {
    archived?: boolean,
    parentId: string|undefined,
    choices: Array<string>,

    startPiece?: PiecePositionState,
    variables: Array<string>,
    isolatedPieces: Array<PiecePositionState>,
}

export type CustomFieldDataType = {
    byId: {
        [id: string]: CustomField
    },
    allFields: Array<string>,
}

export type CustomFieldOptionsDataType = {
    byId: {
        [id: string]: FieldChoice
    },
    allOptions: Array<string>,
}

export interface CustomFieldState {
    selectedField: string|undefined,
    selectedOption: string|undefined,
    customFields: CustomFieldDataType,
    customFieldOptions: CustomFieldOptionsDataType,

    createdCustomFieldIds: Set<string>,
    updatedCustomFieldIds: Set<string>,
    deletedCustomFieldIds: Set<string>,
    createdCustomFieldOptionIds: Set<string>,
    updatedCustomFieldOptionIds: Set<string>,
    deletedCustomFieldOptionIds: Set<string>,
}

export function selectCustomField<T extends CustomFieldState>(state: T, id: string): T {
    return {
        ...state,
        selectedField: id,
    }
}

export function unSelectCustomField<T extends CustomFieldState>(state: T): T {
    return {
        ...state,
        selectedField: undefined,
    }
}

export function addCustomField<T extends CustomFieldState>(state: T, payload: IUpdateableCustomFieldData, parentId: string|undefined): T {
    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [payload.id]: {
                    ...payload,
                    parentId,
                    choices: [],
                    isolatedPieces: [],
                },
            },
            allFields: state.customFields.allFields.concat([payload.id]),
        },
        createdCustomFieldIds: new Set([...state.createdCustomFieldIds, payload.id]),
    }
}

export function deleteCustomField<T extends CustomFieldState>(state: T, id: string): T {
    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [id]: {
                    ...state.customFields.byId[id],
                    archived: true,
                }
            },
            allFields: state.customFields.allFields.filter(fieldId => fieldId !== id),
        },
        deletedCustomFieldIds: new Set([...state.deletedCustomFieldIds, id]),
    }
}

export function updateCustomField<T extends CustomFieldState>(state: T, payload: IUpdateableCustomFieldData): T {
    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [payload.id]: {
                    ...state.customFields.byId[payload.id],
                    ...payload,
                }
            },
        },
        updatedCustomFieldIds: new Set([...state.updatedCustomFieldIds, payload.id]),
    }
}

export function selectCustomFieldOption<T extends CustomFieldState>(state: T, id: string): T {
    return {
        ...state,
        selectedOption: id,
    }
}

export function unSelectCustomFieldOption<T extends CustomFieldState>(state: T): T {
    return {
        ...state,
        selectedOption: undefined,
    }
}

export function reOrderCustomFieldOptions<T extends CustomFieldState>(state: T, sourceIndex: number, destinationIndex: number, parentId: string): T {
    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [parentId]: {
                    ...state.customFields.byId[parentId],
                    choices: reOrderList(state.customFields.byId[parentId].choices, sourceIndex, destinationIndex),
                }
            }
        }
    }
}

export function updateStartPieceForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, pieceState: PiecePositionState): T {

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    startPiece: pieceState,
                }
            }
        },
        updatedCustomFieldIds: new Set([...state.updatedCustomFieldIds, customFieldId]),
    }
}

export function setIsolatedPieceForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, pieceState: PiecePositionState): T {

    const newCustomFieldIsolatedPieces = state.customFields.byId[customFieldId].isolatedPieces.slice(0);
    const customFieldIsolatedPieceIndex = newCustomFieldIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === pieceState.piece);

    if (customFieldIsolatedPieceIndex < 0) {
        newCustomFieldIsolatedPieces.push(pieceState);
    } else {
        newCustomFieldIsolatedPieces[customFieldIsolatedPieceIndex] = pieceState;
    }

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    isolatedPieces: newCustomFieldIsolatedPieces,
                }
            }
        },
        updatedCustomFieldIds: new Set([...state.updatedCustomFieldIds, customFieldId]),
    }
}

export function removeIsolatedPieceForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, pieceId: string): T {

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    isolatedPieces: state.customFields.byId[customFieldId].isolatedPieces.filter(pieceData => pieceData.piece !== pieceId),
                }
            }
        },
        updatedCustomFieldIds: new Set([...state.updatedCustomFieldIds, customFieldId]),
    }
}

export function registerVariableForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, variableId: string): T {

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    variables: state.customFields.byId[customFieldId].variables ? state.customFields.byId[customFieldId].variables.concat([variableId]) : [variableId],
                }
            }
        },
        updatedCustomFieldIds: new Set([...state.updatedCustomFieldIds, customFieldId]),
    }
}

export function addCustomFieldOption<T extends CustomFieldState>(state: T, payload: IUpdateableFieldChoiceData, parentId: string): T {
    return {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {
                ...state.customFieldOptions.byId,
                [payload.id]: payload,
            },
            allOptions: state.customFieldOptions.allOptions.concat([payload.id]),
        },
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [parentId]: {
                    ...state.customFields.byId[parentId],
                    choices: state.customFields.byId[parentId].choices.concat([payload.id])
                }
            }
        },
        updatedCustomFieldIds: new Set([...state.updatedCustomFieldIds, parentId]),
        createdCustomFieldOptionIds: new Set([...state.createdCustomFieldOptionIds, payload.id]),
    }
}

export function deleteCustomFieldOption<T extends CustomFieldState>(state: T, id: string, parentId: string): T {
    return {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {
                ...state.customFieldOptions.byId,
                [id]: {
                    ...state.customFieldOptions.byId[id],
                    archived: true,
                },
            },
            allOptions: state.customFieldOptions.allOptions.filter(fieldId => fieldId !== id),
        },
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [parentId]: {
                    ...state.customFields.byId[parentId],
                    choices: state.customFields.byId[parentId].choices.filter(optionId => optionId !== id),
                }
            }
        },
        updatedCustomFieldIds: new Set([...state.updatedCustomFieldIds, parentId]),
        deletedCustomFieldOptionIds: new Set([...state.deletedCustomFieldOptionIds, id]),
    }
}

export function updateCustomFieldOption<T extends CustomFieldState>(state: T, payload: IUpdateableFieldChoiceData): T {
    return {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {
                ...state.customFieldOptions.byId,
                [payload.id]: {
                    ...state.customFieldOptions.byId[payload.id],
                    ...payload,
                },
            },
        },
        updatedCustomFieldOptionIds: new Set([...state.updatedCustomFieldOptionIds, payload.id]),
    }
}

function getOptionsData(entityType: 'level'|'role'|'user'|'member'|'group'|'workflow') {

    const applicationState = store.getState();
    let optionsData: CustomFieldOptionsDataType;

    switch (entityType) {
        case 'level':
            optionsData = applicationState.structure.levels.customFieldOptions;
            break;
        case 'role':
            optionsData = applicationState.structure.roles.customFieldOptions;
            break;
        case 'user':
            optionsData = applicationState.users.customFieldOptions;
            break;
        case 'member':
            optionsData = applicationState.members.types.customFieldOptions;
            break;
        case 'group':
            optionsData = applicationState.groups.types.customFieldOptions;
            break;
        case 'workflow':
            optionsData = applicationState.workflows.types.customFieldOptions;
            break;
        default:
            throw new Error('Unknown entity type');
    }

    return optionsData;

}

export function getReadableDataForCustomField(value: CustomFieldValueType, customField: CustomField, entityId: string, entityType: 'level'|'role'|'user'|'member'|'group'|'workflow') {
    let cellValue = '-';
    const optionsData = getOptionsData(entityType);

    if (customField.isComputed) {

        if (typeof customField.startPiece === 'undefined') {
            throw new Error('A computed field must have a start piece');
        }

        let customFieldValue: VariableValueType;
        let processState: DefaultFlowchartProcessState;
        const applicationState = store.getState();

        switch (entityType) {
            case 'level':
                const location = applicationState.structure.locations.byId[entityId];
                processState = {
                    customFields: {...location.customFields},
                    variables: {
                        [customField.seedEntityVariable]: location.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                try {
                    customFieldValue = getLevelComputedFieldValue(applicationState, processState, customField.startPiece.piece, location.id);
                } catch {
                    customFieldValue = '-'
                }
                
                break;
            case 'role':
                const roleUser = applicationState.users.byId[entityId];
                processState = {
                    customFields: {...roleUser.customFields},
                    variables: {
                        [customField.seedEntityVariable]: roleUser.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                try {
                    customFieldValue = getRoleComputedFieldValue(applicationState, processState, customField.startPiece.piece, roleUser.role);
                } catch {
                    customFieldValue = '-'
                }
                
                break;
            case 'user':
                const normalUser = applicationState.users.byId[entityId];
                processState = {
                    customFields: {...normalUser.customFields},
                    variables: {
                        [customField.seedEntityVariable]: normalUser.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                try {
                    customFieldValue = getUserComputedFieldValue(applicationState, processState, customField.startPiece.piece, normalUser.id);
                } catch {
                    customFieldValue = '-'
                }
                
                break;
            case 'member':
                const member = applicationState.members.byId[entityId];
                processState = {
                    customFields: {...member.customFields},
                    variables: {
                        [customField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                try {
                    customFieldValue = getMemberComputedFieldValue(applicationState, processState, customField.startPiece.piece, member.id);
                } catch {
                    customFieldValue = '-'
                }
                
                break;
            case 'group':
                const group = applicationState.groups.byId[entityId];
                processState = {
                    customFields: {...group.customFields},
                    variables: {
                        [customField.seedEntityVariable]: group.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                try {
                    customFieldValue = getGroupComputedFieldValue(applicationState, processState, customField.startPiece.piece, group.id);
                } catch {
                    customFieldValue = '-'
                }
                
                break;
            case 'workflow':
                const workflowCustomField = customField as WorkflowTypeCustomField;
                const workflow = applicationState.workflows.byId[entityId];
                const workflowProcessState = {
                    customFields: {...workflow.history[workflow.historyIndex].customFields},
                    variables: {
                        [workflowCustomField.seedEntityVariable]: workflow.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                if (workflowCustomField.seedAffiliationVariable) {
                    workflowProcessState.variables[workflowCustomField.seedAffiliationVariable] = workflow.affiliatedEntity;
                }

                try {
                    customFieldValue = getWorkflowComputedFieldValue(applicationState, workflowProcessState, customField.startPiece.piece, workflow.id)
                } catch {
                    customFieldValue = '-'
                }

                break;
            default:
                throw new Error('Unknown entity type');
        }
    
        if (Array.isArray(customFieldValue)) {
    
            if (customFieldValue.length > 0 && Array.isArray(customFieldValue[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }
    
            customFieldValue = customFieldValue as Array<string>;
        }

        value = customFieldValue;

    }

    switch (customField.type) {
        case FieldType.SINGLE_SELECT:
            if (Array.isArray(value)) {
                throw new Error('A single select field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A single select field should not have a boolean value.');
            }

            if (value) {
                cellValue = translatePhrase(optionsData.byId[value].name);
            }

            break;
        
        case FieldType.MULTI_SELECT:
            if (typeof value === 'undefined') {
                break;
            }

            if (!Array.isArray(value)) {
                throw new Error('A multi select field should have an array value');
            }

            if (value) {
                cellValue = value.map(singleValue => translatePhrase(optionsData.byId[singleValue].name)).join(', ');
            }

            break;
        
        case FieldType.DATE:
            if (Array.isArray(value)) {
                throw new Error('A date field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A date field should not have a boolean value.');
            }

            if (typeof value === 'number') {
                throw new Error('A date field should not have a numeric value.');
            }
            
            if (value) {
                cellValue = getReadableDate(value);
            }
        
            break;
        
        case FieldType.BOOLEAN:
            if (typeof value === 'boolean') {
                cellValue = value ? 'Yes' : 'No';
            }

            break;

        case FieldType.LOCATION:
            if (typeof value === 'string' && value.split(' ').length === 2) {
                cellValue = value.split(' ').join(', ');
            }
            break;

        default:
            if (Array.isArray(value)) {
                throw new Error('A non multi-select field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A default field should not have a boolean value.');
            }
            
            if (value) {
                cellValue = String(value);
            }
    }

    return cellValue;
}

export function getCustomFieldValueForInput (customField: CustomField, dataInput: string, customFieldOptions: CustomFieldOptionsDataType) {
    let customFieldValue: CustomFieldValueType;
    switch (customField.type) {
        case FieldType.SINGLE_SELECT:
            if (dataInput.length > 0) {
                const selectedOption = customField.choices.find(optionId => {
                    const option = customFieldOptions.byId[optionId];

                     return option.name === dataInput;
                });

                if (typeof selectedOption !== 'undefined') {
                    customFieldValue = selectedOption;
                } else {
                    throw new Error(`${customField.name}: Value not recognized`);
                }
            }
            break;
        
        case FieldType.MULTI_SELECT:
            if (dataInput.length > 0) {
                const listOfValues = dataInput.split(',').map(word => word.trim());

                const options = customField.choices.map(optionId => customFieldOptions.byId[optionId]);
                const selectedOptionIds: Array<string> = [];

                for (const value of listOfValues) {
                    const option = options.find(option => option.name === value);
                    if (typeof option === 'undefined') {
                        throw new Error(`${customField.name}: Value not recognized`);
                    }

                    selectedOptionIds.push(option.id);
                }

                customFieldValue = selectedOptionIds;
            }
            break;
        
        case FieldType.BOOLEAN:
            if (dataInput.toLocaleLowerCase() === 'yes' || dataInput.toLocaleLowerCase() === 'true') {
                customFieldValue = true;
            } else if (dataInput.toLocaleLowerCase() === 'no' || dataInput.toLocaleLowerCase() === 'false') {
                customFieldValue = false;
            } else if (dataInput.length > 0) {
                throw new Error(`${customField.name}: Unknown value for boolean`)
            }
            break;
        
        case FieldType.LOCATION:
            if (dataInput.length > 0) {
                const coOrdinates = dataInput.split(',').map(word => word.trim());

                if (coOrdinates.length !== 2) {
                    throw new Error(`${customField.name}: There must be exactly two values in co-ordinates`);
                }

                if (isNaN(Number(coOrdinates[0])) || isNaN(Number(coOrdinates[1]))) {
                    throw new Error(`${customField.name}: Both co-ordinates must be a number`);
                }

                customFieldValue = `${coOrdinates[0]} ${coOrdinates[1]}`;
            }

            break;
        
        case FieldType.PHONE:
            if (dataInput.length > 0) {
                const phoneNumber = dataInput.split(' ').map(word => word.trim());

                if (phoneNumber.length !== 2) {
                    throw new Error(`${customField.name}: There must be exactly two values in the phone number`);
                }

                if (isNaN(Number(phoneNumber[1])) || phoneNumber[1].length !== 10) {
                    throw new Error(`${customField.name}: The phone number format is incorrect`);
                }

                customFieldValue = `${phoneNumber[0]} ${phoneNumber[1]}`;
            }
            break;

        case FieldType.NUMBER:
            if (!isNaN(Number(dataInput))) {
                customFieldValue = Number(dataInput);
            }
            break;

        case FieldType.DATE:
            if (dataInput.length > 0) {
                if (moment(dataInput, 'YYYY-MM-DD').isValid()) {
                    customFieldValue = dataInput;
                } else {
                    throw new Error('Invalid date format. Expected format: YYYY-MM-DD');
                }
            }
            break;
        
        default:
            customFieldValue = dataInput;
            break;
    }

    return customFieldValue;
}