import { ADD_VARIABLE, DELETE_VARIABLE, UPDATE_VARIABLE } from './types';
import { FlowchartActionTypes, UPDATE_FLOWCHART_DATA } from '../types';
import { VariableState } from './types';

export const initialState: VariableState = {  
    byId: {},
    allEntries:[],
    
    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),
};

export function variablesReducer(state = initialState, action: FlowchartActionTypes): VariableState {
    switch(action.type) {

        case ADD_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        createdTime: action.createdTime,
                        lastUpdatedTime: action.createdTime,
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
                createdIds: new Set(Array.from(state.createdIds).concat([action.payload.id])),
            }
        
        case DELETE_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(entry => entry !== action.id),
                deletedIds: new Set(Array.from(state.updatedIds).concat([action.id])),
            }
        
        case UPDATE_VARIABLE:
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.id]: {
                            ...state.byId[action.payload.id],
                            ...action.payload,
                        },
                    },
                    updatedIds: !state.createdIds.has(action.payload.id) ? new Set(Array.from(state.updatedIds).concat([action.payload.id])) : state.updatedIds,
                }

        // Flowchart actions

        case UPDATE_FLOWCHART_DATA:
            return {
                ...action.data.variables,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }
        

        default:
            return state
    }
}