import { NormalizedModel, Synchronizable } from '../../../normalized-model';

export const SELECT_STATUS = 'SELECT_STATUS';
export const UN_SELECT_STATUS = 'UN_SELECT_STATUS';
export const RE_ORDER_STATUSES = 'RE_ORDER_STATUSES';
export const ADD_STATUS = 'ADD_STATUS';
export const DELETE_STATUS = 'DELETE_STATUS';
export const UPDATE_STATUS = 'UPDATE_STATUS';

export interface INewStatusData {
    name: string;
    isTerminal: boolean,
    dueInDays?: number,
}

export interface IUpdateableStatusData extends INewStatusData {
    id: string,
}

export interface IStatus extends IUpdateableStatusData, Synchronizable {
    archived?: boolean,
}

export interface StatusState extends NormalizedModel<IStatus> {
    selected: string|undefined,
}


export interface SelectStatusAction {
    type: typeof SELECT_STATUS,
    id: string,
}

export interface UnSelectStatusAction {
    type: typeof UN_SELECT_STATUS,
}

export interface ReOrderStatusAction {
    type: typeof RE_ORDER_STATUSES,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddStatusAction {
    type: typeof ADD_STATUS,
    payload: IStatus,
    parentId: string,
}

export interface DeleteStatusAction {
    type: typeof DELETE_STATUS,
    id: string,
    parentId: string,
}

export interface UpdateStatusAction {
    type: typeof UPDATE_STATUS,
    payload: IUpdateableStatusData,
}

export type StatusActions = SelectStatusAction | UnSelectStatusAction | ReOrderStatusAction | AddStatusAction | DeleteStatusAction | UpdateStatusAction;