import React, { Component, MouseEvent } from 'react';
import styles from './CardsList.module.scss';

import { translatePhrase } from '../../shared/helpers/translation';

import CountDown from './CountDown';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import EnhancedCard, { CardType, EnhancedTopCard } from './Card';

import { ReactComponent as DeleteIcon } from '../../assets/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as UndoIcon } from '../../assets/undo.svg';

function onCardDragEnd(reOrderCallback: (sourceIndex: number, destinationIndex: number) => void, result: DropResult) {
    const { destination, source } = result;

    if (!destination) {
        return;
    }

    if (destination.index === source.index) {
        return;
    }
    
    reOrderCallback(source.index, destination.index);
}


type OwnProps = {
    isSelected: boolean,
    isShowingEditVerticalForm: boolean,
    isShowingAddForm: boolean,
    isPotentiallyDraggable: boolean,
    modifyForm?: JSX.Element,
    modifyVerticalForm?: JSX.Element,
    heading: string,

    isReadOnly: boolean,
    isDeleteRestricted: boolean,
    isAddRestricted: boolean,
    isShowingEditForm: boolean,

    cards?: Array<CardType>,
    selectedCard?: CardType,

    isTopLevel: boolean,
    isShowingIndex: boolean,

    onSelectCard: (id: string) => void,
    onUnselectCard: () => void,
    onReorderCards?: (sourceIndex: number, destinationIndex: number) => void,
    onEditCard: () => void,
    onDeleteCard: (id: string) => void,

    onEditVertical?: () => void,
    onDeleteVertical?: () => void,
    onAddCard?: () => void,
}

type OwnState = {
    deleteTimer: number|undefined
}

export default class CardsList extends Component<OwnProps, OwnState> {
    state: OwnState = {
        deleteTimer: undefined
    }

    static defaultProps = {
        isSelected: true,
        isShowingEditVerticalForm: false,
        isShowingAddForm: false,
        isPotentiallyDraggable: true,
        isTopLevel: false,
        isShowingIndex: true,
    
        isReadOnly: false,
        isDeleteRestricted: false,
        isAddRestricted: false,
        isShowingEditForm: false,
    }
    
    deleteCardList = () => {
        this.props.onDeleteVertical && this.props.onDeleteVertical();
    }
    
    markForDelete = () => {
        let that = this;
        const timeout = window.setTimeout(that.deleteCardList, 5000);
        this.setState({
            deleteTimer: timeout
        });
    }
    
    cancelDelete = () => {
        window.clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined
        });
    }

    handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        this.state.deleteTimer && this.cancelDelete();
    }

    handleDragEnd = (result: DropResult) => {
        this.props.onReorderCards && onCardDragEnd(this.props.onReorderCards, result)
    }
    
    render() {
    
        let cards: Array<JSX.Element> = [];

        if (this.props.cards) {

            if (this.props.isTopLevel) {
                cards = this.props.cards.map((card, index) => {
                    return <EnhancedTopCard 
                        key={card.id} 
                        isReadonly={this.props.isReadOnly || !!card.isReadOnly}
                        isDeleteRestricted={this.props.isDeleteRestricted} 
                        isDragDisabled={this.props.isReadOnly || this.props.isDeleteRestricted} 
                        index={index} 
                        card={card} 
                        onSelectCard={() => {this.props.onSelectCard(card.id)}} 
                        onUnselectCard={this.props.onUnselectCard} 
                        onDeleteCard={() => {this.props.onDeleteCard(card.id)}} 
                        onEditCard={this.props.onEditCard} 
                        isActive={this.props.selectedCard ? this.props.selectedCard.id === card.id : false} 
                        modifyForm={this.props.modifyForm} 
                        isShowingIndex={this.props.isShowingIndex}
                        isShowingEditForm={this.props.isShowingEditForm} 
                        isPotentiallyDraggable={this.props.isPotentiallyDraggable}
                    />
                });
            } else {
                cards = this.props.cards.map((card, index) => {
                    return <EnhancedCard 
                        key={card.id} 
                        isReadonly={this.props.isReadOnly || !!card.isReadOnly}
                        isDeleteRestricted={this.props.isDeleteRestricted} 
                        isDragDisabled={this.props.isReadOnly || this.props.isDeleteRestricted} 
                        index={index} 
                        card={card} 
                        onSelectCard={() => {this.props.onSelectCard(card.id)}} 
                        onUnselectCard={this.props.onUnselectCard} 
                        onDeleteCard={() => {this.props.onDeleteCard(card.id)}} 
                        onEditCard={this.props.onEditCard} 
                        isActive={this.props.selectedCard ? this.props.selectedCard.id === card.id : false} 
                        modifyForm={this.props.modifyForm} 
                        isShowingIndex={this.props.isShowingIndex}
                        isShowingEditForm={this.props.isShowingEditForm} 
                        isPotentiallyDraggable={this.props.isPotentiallyDraggable}
                    />
                });
            }

        }

        const className = this.props.isShowingEditVerticalForm ? styles.editingCardsList : styles.cardsList + ' ignore-react-onclickoutside ignore-top-level-onclickoutside';

        const cardsListMarkup = this.state.deleteTimer ? 
            <div className={styles.undoDelete} onClick={this.cancelDelete}>{translatePhrase('Undo')} <UndoIcon /> <CountDown remaining={5} /></div> 
        : 
            <div>{this.props.isShowingEditVerticalForm ? 
                    this.props.modifyVerticalForm 
                : 
                    <div>
                        <header> 
                            <div className={styles.heading}>{translatePhrase(this.props.heading)} </div>
                            {!this.props.isReadOnly && this.props.modifyVerticalForm && <EditIcon onClick={this.props.onEditVertical} />}
                            {!this.props.isReadOnly && !this.props.isDeleteRestricted && this.props.modifyVerticalForm && <DeleteIcon onClick={this.markForDelete} />}
                        </header>
                        {this.props.children
                            ?
                                <div className={styles.content}>
                                    <ul className={styles.structureList}>
                                    {this.props.children}
                                    </ul>
                                </div>
                            :
                                cards.length > 0 && <div className={styles.content}>
                                    <ul className={styles.structureList}>
                                        {cards}
                                    </ul>
                                </div>
                        }
                    </div>}
            </div>
        
        const cardsListFooter = !this.props.isAddRestricted && !this.state.deleteTimer && !this.props.isShowingEditVerticalForm && !this.props.isReadOnly && this.props.modifyForm && <footer>
            {!this.props.isShowingAddForm && <section 
                onClick={this.props.onAddCard} 
                className={styles.addCard}
            > + {translatePhrase('Click to add new card')} </section>}
            {this.props.isShowingAddForm && this.props.isSelected && this.props.modifyForm}
        </footer>;

        if (!this.props.isPotentiallyDraggable) {
            return <section 
                className={className} 
                onClick={this.handleClick}
            >
                {cardsListMarkup}
                {cardsListFooter}
            </section>;
        } else {
            return <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId={this.props.heading}>
                    {(provided, snapshot) => (
                        <section 
                            className={className} 
                            ref={provided.innerRef}
                            onClick={this.handleClick}
                        >
                            {cardsListMarkup}
                            {provided.placeholder}
                            {cardsListFooter}
                        </section>)
                    }
                </Droppable>
            </DragDropContext>
        }
    }
        
}