import { SELECT_LANGUAGE, UN_SELECT_LANGUAGE, RE_ORDER_LANGUAGES, ADD_LANGUAGE_REQUEST, ADD_LANGUAGE, DELETE_LANGUAGE, UPDATE_LANGUAGE, SelectLanguageAction, UnSelectLanguageAction, ReOrderLanguagesAction, AddLanguageRequestAction, AddLanguageAction, UpdateLanguageAction, DeleteLanguageAction } from './types';
import { INewLanguageData, IUpdateableLanguageData } from './types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export function selectLanguage(id: string): SelectLanguageAction {
    return {
        type: SELECT_LANGUAGE,
        id,
    }
}

export function unSelectLanguage(): UnSelectLanguageAction {
    return {
        type: UN_SELECT_LANGUAGE,
    }
}

export function reOrderLanguages(sourceIndex: number, destinationIndex: number): ReOrderLanguagesAction {
    return {
        type: RE_ORDER_LANGUAGES,
        sourceIndex,
        destinationIndex,
    }
}

export function addLanguageRequest(payload: INewLanguageData): AddLanguageRequestAction {
    return {
        type: ADD_LANGUAGE_REQUEST,
        payload,
    }
}

export function addLanguage(payload: INewLanguageData): AddLanguageAction {
    const now = moment().format('YYYY-MM-DDTHH:mm:ss');

    return {
        type: ADD_LANGUAGE,
        payload: {
            id: uuidv4(),
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
        },
    }
}

export function updateLanguage(payload: IUpdateableLanguageData): UpdateLanguageAction {
    return {
        type: UPDATE_LANGUAGE,
        payload,
    }
}

export function deleteLanguage(id: string): DeleteLanguageAction {
    return {
        type: DELETE_LANGUAGE,
        id,
    }
}