import React, { Component } from 'react';
import styles from './Permissions.module.scss';
import { Redirect } from "react-router-dom";

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectRole, unSelectRole } from '../../../shared/store/structure/role/actions';

import { updateGeneralPermission, updateMemberPermission, updateGroupPermission, updateWorkflowPermission, updateReportPermission } from '../../../shared/store/permissions/actions';
import { Permissions, PermissionSet, DEFAULT_GENERAL_PERMISSIONS } from '../../../shared/store/permissions/types';

import { TriToggleCardType } from '../../../widgets/card/TriToggleCard';
import TriToggleCardsList from '../../../widgets/card/TriToggleCardsList';

import RolesList from '../hierarchy/RolesList';

import { ApplicationState } from '../../../shared/store/main';
import { TriToggleOptions } from '../../../widgets/form/TriToggle';
import CardsList from '../../../widgets/card/CardsList';
import { CardType } from '../../../widgets/card/Card';
import { translatePhrase } from '../../../shared/helpers/translation';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE;
    const canViewHierarchy = canEditHierarchy || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;
    const selectedRole = state.structure.roles.selected;
    const selectedRoleProject = selectedRole ? state.structure.levels.byId[state.structure.roles.byId[selectedRole].level].project : undefined;

    let permissionsForRole: PermissionSet|undefined = selectedRole ? state.permissions.rolePermissions[selectedRole] : undefined;

    if (!permissionsForRole) {
        permissionsForRole = {
            general: DEFAULT_GENERAL_PERMISSIONS,
            members: {},
            groups: {},
            workflows: {},
            reports: {},
        };
    }

    return {
        isReadable: canViewHierarchy,
        isWritable: canEditHierarchy,
        
        memberTypesData: state.members.types,
        groupTypesData: state.groups.types,
        workflowTypesData: state.workflows.types,
        reportTypesData: state.reports.types,

        selectedRole,
        selectedRoleProject,
        permissionsForRole,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectRole: (id: string) => dispatch(selectRole(id)),
        unSelectRole: () => dispatch(unSelectRole()),

        updateGeneralPermission: (id: string, role: string, permission: Permissions) => dispatch(updateGeneralPermission(id, role, permission)),
        updateMemberPermission: (memberTypeId: string, role: string, permission: Permissions) => dispatch(updateMemberPermission(memberTypeId, role, permission)),
        updateGroupPermission: (groupTypeId: string, role: string, permission: Permissions) => dispatch(updateGroupPermission(groupTypeId, role, permission)),
        updateWorkflowPermission: (workflowTypeId: string, role: string, permission: Permissions) => dispatch(updateWorkflowPermission(workflowTypeId, role, permission)),
        updateReportPermission: (reportTypeId: string, role: string, permission: Permissions) => dispatch(updateReportPermission(reportTypeId, role, permission)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

function convertCamelCaseToWords(camelCasedString: string) {
    return camelCasedString.replace( /([A-Z])/g, ' $1' ).trim();
}

type OwnState = {
    type: string|undefined,
};

class ConnectedPermissions extends Component<Props, OwnState> {
    state = {
        type: 'general',
    };

    handleBaseToggle = (id: string, state: TriToggleOptions, dispatchFunction: (id: string, role: string, permission: Permissions) => void) => {
        if (!this.props.permissionsForRole || !this.props.selectedRole) {
            return;
        }

        switch(state) {
            case 'positive':
                dispatchFunction(id, this.props.selectedRole, Permissions.WRITE);
                break;
            case 'middle':
                dispatchFunction(id, this.props.selectedRole, Permissions.READ);
                break;
            case 'negative':
                dispatchFunction(id, this.props.selectedRole, Permissions.NONE);
                break;
            default:
                throw new Error('There are no other toggle states');
        }
    }

    handleGeneralToggle = (permissionName: string, state: TriToggleOptions) => {
        this.handleBaseToggle(permissionName, state, this.props.updateGeneralPermission);
    }

    handleMemberToggle = (memberTypeId: string, state: TriToggleOptions) => {
        this.handleBaseToggle(memberTypeId, state, this.props.updateMemberPermission);
    }

    handleGroupToggle = (groupTypeId: string, state: TriToggleOptions) => {
        this.handleBaseToggle(groupTypeId, state, this.props.updateGroupPermission);
    }

    handleWorkflowToggle = (workflowTypeId: string, state: TriToggleOptions) => {
        this.handleBaseToggle(workflowTypeId, state, this.props.updateWorkflowPermission);
    }

    handleReportToggle = (reportTypeId: string, state: TriToggleOptions) => {
        this.handleBaseToggle(reportTypeId, state, this.props.updateReportPermission);
    }

    handleTypeSelect = (type?: string) => {
        this.setState({
            type,
        });
    }

    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const permissionsForRole = this.props.permissionsForRole;
    
        let generalCardsMarkup: JSX.Element | undefined = undefined;
        let memberCardsMarkup: JSX.Element | undefined = undefined;
        let groupCardsMarkup: JSX.Element | undefined = undefined;
        let workflowCardsMarkup: JSX.Element | undefined = undefined;
        let reportCardsMarkup: JSX.Element | undefined = undefined;

        const generalPermissionCards: Array<TriToggleCardType> = [];
        const memberPermissionCards: Array<TriToggleCardType> = [];
        const groupPermissionCards: Array<TriToggleCardType> = [];
        const workflowPermissionCards: Array<TriToggleCardType> = [];
        const reportPermissionCards: Array<TriToggleCardType> = [];

        if (this.props.selectedRole) {
            
            Object.keys(permissionsForRole.general).forEach(permissionName => {
                const isWritable = permissionsForRole.general[permissionName] === Permissions.WRITE;
                const isReadable = permissionsForRole.general[permissionName] === Permissions.READ || isWritable;

                generalPermissionCards.push({
                    id: permissionName,
                    name: translatePhrase(convertCamelCaseToWords(permissionName)),
                    toggleState: isWritable ? 'positive' : isReadable ? 'middle' : 'negative',
                    onToggle: this.handleGeneralToggle,
                });
            }); 

            if (this.props.selectedRoleProject) {
                let allowedMemberTypes: Array<string> = [];
                allowedMemberTypes = this.props.memberTypesData.allEntries.filter(memberTypeId => {
                    const memberType = this.props.memberTypesData.byId[memberTypeId];

                    return memberType.project === this.props.selectedRoleProject;
                });

                allowedMemberTypes.forEach(memberTypeId => {
                    let isWritable = permissionsForRole.general.MembersConfiguration === Permissions.WRITE;
                    let isReadable = permissionsForRole.general.MembersConfiguration === Permissions.READ || isWritable;

                    if (memberTypeId in permissionsForRole.members) {
                        isWritable = permissionsForRole.members[memberTypeId] === Permissions.WRITE;
                        isReadable = permissionsForRole.members[memberTypeId] === Permissions.READ || isWritable;
                    }

                    const memberType = this.props.memberTypesData.byId[memberTypeId];

                    memberPermissionCards.push({
                        id: memberTypeId,
                        name: translatePhrase(memberType.name),
                        toggleState: isWritable ? 'positive' : isReadable ? 'middle' : 'negative',
                        onToggle: this.handleMemberToggle,
                    });
                });


                let allowedGroupTypes: Array<string> = [];
                allowedGroupTypes = this.props.groupTypesData.allEntries.filter(groupTypeId => {
                    const groupType = this.props.groupTypesData.byId[groupTypeId];

                    return groupType.project === this.props.selectedRoleProject;
                });

                allowedGroupTypes.forEach(groupTypeId => {
                    let isWritable = permissionsForRole.general.GroupsConfiguration === Permissions.WRITE;
                    let isReadable = permissionsForRole.general.GroupsConfiguration === Permissions.READ || isWritable;

                    if (groupTypeId in permissionsForRole.groups) {
                        isWritable = permissionsForRole.groups[groupTypeId] === Permissions.WRITE;
                        isReadable = permissionsForRole.groups[groupTypeId] === Permissions.READ || isWritable;
                    }

                    const groupType = this.props.groupTypesData.byId[groupTypeId];

                    groupPermissionCards.push({
                        id: groupTypeId,
                        name: translatePhrase(groupType.name),
                        toggleState: isWritable ? 'positive' : isReadable ? 'middle' : 'negative',
                        onToggle: this.handleGroupToggle,
                    });
                });


                let allowedWorkflowTypes: Array<string> = [];
                allowedWorkflowTypes = this.props.workflowTypesData.allEntries.filter(workflowTypeId => {
                    const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

                    return workflowType.project === this.props.selectedRoleProject;
                });

                allowedWorkflowTypes.forEach(workflowTypeId => {
                    let isWritable = permissionsForRole.general.WorkflowsConfiguration === Permissions.WRITE;
                    let isReadable = permissionsForRole.general.WorkflowsConfiguration === Permissions.READ || isWritable;

                    if (workflowTypeId in permissionsForRole.workflows) {
                        isWritable = permissionsForRole.workflows[workflowTypeId] === Permissions.WRITE;
                        isReadable = permissionsForRole.workflows[workflowTypeId] === Permissions.READ || isWritable;
                    }

                    const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

                    workflowPermissionCards.push({
                        id: workflowTypeId,
                        name: translatePhrase(workflowType.name),
                        toggleState: isWritable ? 'positive' : isReadable ? 'middle' : 'negative',
                        onToggle: this.handleWorkflowToggle,
                    });
                });


                let allowedReportTypes: Array<string> = [];
                allowedReportTypes = this.props.reportTypesData.allEntries.filter(reportTypeId => {
                    const reportType = this.props.reportTypesData.byId[reportTypeId];

                    return reportType.project === this.props.selectedRoleProject;
                });

                allowedReportTypes.forEach(reportTypeId => {
                    let isWritable = permissionsForRole.general.ReportsConfiguration === Permissions.WRITE;
                    let isReadable = permissionsForRole.general.ReportsConfiguration === Permissions.READ || isWritable;

                    if (reportTypeId in permissionsForRole.reports) {
                        isWritable = permissionsForRole.reports[reportTypeId] === Permissions.WRITE;
                        isReadable = permissionsForRole.reports[reportTypeId] === Permissions.READ || isWritable;
                    }

                    const reportType = this.props.reportTypesData.byId[reportTypeId];

                    reportPermissionCards.push({
                        id: reportTypeId,
                        name: translatePhrase(reportType.name),
                        toggleState: isWritable ? 'positive' : isReadable ? 'middle' : 'negative',
                        onToggle: this.handleReportToggle,
                    });
                });
            }

            generalCardsMarkup = <TriToggleCardsList
                heading="General permissions"
                cards={generalPermissionCards}
                isReadOnly={!this.props.isWritable}
                negativeText="NONE"
                middleText="READ"
                positiveText="WRITE"
            />

            memberCardsMarkup = <TriToggleCardsList
                heading="Member type permissions"
                cards={memberPermissionCards}
                isReadOnly={!this.props.isWritable}
                negativeText="NONE"
                middleText="READ"
                positiveText="WRITE"
            />

            groupCardsMarkup = <TriToggleCardsList
                heading="Group type permissions"
                cards={groupPermissionCards}
                isReadOnly={!this.props.isWritable}
                negativeText="NONE"
                middleText="READ"
                positiveText="WRITE"
            />

            workflowCardsMarkup = <TriToggleCardsList
                heading="Workflow type permissions"
                cards={workflowPermissionCards}
                isReadOnly={!this.props.isWritable}
                negativeText="NONE"
                middleText="READ"
                positiveText="WRITE"
            />

            reportCardsMarkup = <TriToggleCardsList
                heading="Report type permissions"
                cards={reportPermissionCards}
                isReadOnly={!this.props.isWritable}
                negativeText="NONE"
                middleText="READ"
                positiveText="WRITE"
            />
        }

        const permissionCards: Array<CardType> = [{
            id: 'general',
            name: translatePhrase('General'),
        }];

        if (memberPermissionCards.length > 0) {
            permissionCards.push({
                id: 'member',
                name: translatePhrase('Member'),
            });
        }

        if (groupPermissionCards.length > 0) {
            permissionCards.push({
                id: 'group',
                name: translatePhrase('Group'),
            });
        }

        if (workflowPermissionCards.length > 0) {
            permissionCards.push({
                id: 'workflow',
                name: translatePhrase('Workflow'),
            });
        }

        if (reportPermissionCards.length > 0) {
            permissionCards.push({
                id: 'report',
                name: translatePhrase('Report'),
            });
        }

        let selectedCard: CardType|undefined;

        if (this.state.type) {
            selectedCard = permissionCards.find(permissionCard => permissionCard.id === this.state.type);
        }

        const permissionTypesList = <CardsList
            heading="Types"
            cards={permissionCards}
            onSelectCard={this.handleTypeSelect}
            onUnselectCard={this.handleTypeSelect}
            selectedCard={selectedCard}
            onEditCard={() => {}}
            onDeleteCard={() => {}}
            isReadOnly
        />
    
        return (<div className={styles.permissionsHolder}>
            <div className={styles.allRoles} onClick={this.props.unSelectRole}>
                <RolesList heading="Roles" onSelectCard={this.props.selectRole} onUnSelectCard={this.props.unSelectRole} selectedId={this.props.selectedRole} isReadOnly isShowingProjectName />
            </div>
            {this.props.selectedRole && 
            <div className={styles.cardsTree}>
                {permissionTypesList}
                {this.state.type === 'general' && generalCardsMarkup}
                {this.state.type === 'member' && memberPermissionCards.length > 0 && memberCardsMarkup}
                {this.state.type === 'group' && groupPermissionCards.length > 0 && groupCardsMarkup}
                {this.state.type === 'workflow' && workflowPermissionCards.length > 0 && workflowCardsMarkup}
                {this.state.type === 'report' && reportPermissionCards.length > 0 && reportCardsMarkup}
            </div>}
        </div>);

    }
     
}

const PermissionsComponent = connect(mapStateToProps, mapDispatchToProps)(ConnectedPermissions);

export default PermissionsComponent;