import { GroupTypeState, SELECT_GROUP_TYPE, UN_SELECT_GROUP_TYPE, RE_ORDER_GROUP_TYPES, ADD_GROUP_TYPE, UPDATE_GROUP_TYPE, DELETE_GROUP_TYPE, ADD_GROUP_TO_GROUP_TYPE, REMOVE_GROUP_FROM_GROUP_TYPE, SELECT_GROUP_TYPE_CUSTOM_FIELD, UN_SELECT_GROUP_TYPE_CUSTOM_FIELD, ADD_GROUP_TYPE_CUSTOM_FIELD, DELETE_GROUP_TYPE_CUSTOM_FIELD, UPDATE_GROUP_TYPE_CUSTOM_FIELD, SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION, UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION, RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION, ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION, DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION, UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION, UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE, REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE, UPDATE_GROUP_TYPE_MANAGEMENT, DISCARD_GROUP_TYPE_MANAGEMENT, ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP } from './types';
import { GroupActionTypes } from '../types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, updateStartPieceForCustomField, registerVariableForCustomField } from '../../custom-fields';
import { reOrderList } from '../../../helpers/utilities';

export const initialState: GroupTypeState = {
    byId: {},
    allEntries: [],
    selected: undefined,

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    customFields: {
        byId: {},
        allFields: [],
    },
    customFieldOptions: {
        byId: {},
        allOptions: [],
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),
};

export function groupTypesReducer(state = initialState, action: GroupActionTypes): GroupTypeState {
    let newState: GroupTypeState;
    let groupTypeManagement: undefined | {
        workflowTypeId: string,
        lastQuestionPiece: string,
        customFieldMap: {
            [groupTypeCustomFieldId: string]: string,  // ID of the custom field in the managed workflow
        },
    };

    switch(action.type) {

        // Group type actions
        
        case SELECT_GROUP_TYPE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_GROUP_TYPE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_GROUP_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_GROUP_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        groups: [],
                        customFields: [],
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
            }
        
        case DELETE_GROUP_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(entry => entry !== action.id),
                selected: undefined,
            }
        
        case UPDATE_GROUP_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload,
                    },
                },
            }
        
        case ADD_GROUP_TO_GROUP_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        groups: state.byId[action.groupTypeId].groups.concat(action.groupId),
                    },
                },
            }
        
        case REMOVE_GROUP_FROM_GROUP_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        groups: state.byId[action.groupTypeId].groups.filter(groupId => groupId !== action.groupId),
                    },
                },
            }
        
        case SELECT_GROUP_TYPE_CUSTOM_FIELD:
            return selectCustomField<GroupTypeState>(state, action.id);
        
        case UN_SELECT_GROUP_TYPE_CUSTOM_FIELD:
            return unSelectCustomField<GroupTypeState>(state);

        case ADD_GROUP_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        customFields: state.byId[action.groupTypeId].customFields.concat([action.payload.id]),
                    }
                }
            }
            return addCustomField<GroupTypeState>(newState, action.payload, action.groupTypeId);
        
        case DELETE_GROUP_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        customFields: state.byId[action.groupTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                }
            }
            return deleteCustomField<GroupTypeState>(newState, action.id);
        
        case UPDATE_GROUP_TYPE_CUSTOM_FIELD:
            return updateCustomField<GroupTypeState>(state, action.payload);


        case UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<GroupTypeState>(state, action.customFieldId, action.payload);
    
        case SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<GroupTypeState>(state, action.customFieldId, action.payload);

        case REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<GroupTypeState>(state, action.customFieldId, action.pieceId);

        case REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<GroupTypeState>(state, action.customFieldId, action.variableId);
        
        
        case SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<GroupTypeState>(state, action.id);
        
        case UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<GroupTypeState>(state);

        case RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<GroupTypeState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<GroupTypeState>(state, action.payload, action.parentId);
        
        case DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<GroupTypeState>(state, action.id, action.parentId);

        case UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<GroupTypeState>(state, action.payload);

        case ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP:
            groupTypeManagement = state.byId[action.groupTypeId].management;

            if (typeof groupTypeManagement === 'undefined') {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        management: {
                            ...groupTypeManagement,
                            customFieldMap: {
                                ...groupTypeManagement.customFieldMap,
                                [action.groupTypeCustomFieldId]: action.workflowTypeCustomFieldId,
                            }
                        },
                    },
                },
            }

        case UPDATE_GROUP_TYPE_MANAGEMENT:
            groupTypeManagement = state.byId[action.groupTypeId].management;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        management: {
                            ...groupTypeManagement,
                            workflowTypeId: action.workflowTypeId,
                            lastQuestionPiece: action.lastQuestionPieceId,
                            customFieldMap: {
                                ...(groupTypeManagement ? groupTypeManagement.customFieldMap : {})
                            }
                        },
                    },
                },
            }

        case DISCARD_GROUP_TYPE_MANAGEMENT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        management: undefined,
                    },
                },
            }
        
        default:
            return state;
    }
}