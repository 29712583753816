import React from 'react';
import styles from './Tabs.module.scss';


type OwnProps = {
    hasSidebar?: boolean,
};

const Tabs: React.FC<OwnProps> = (props) => {
    return (
        <section className={props.hasSidebar ? styles.TabsWithSidebar : styles.Tabs}>
            {props.children}
        </section>
    );
}

export default Tabs;