import React, { Component, ChangeEvent } from 'react';
import styles from './Locations.module.scss';
import { Redirect } from "react-router-dom";

import { selectProject, unSelectProject } from '../../../shared/store/structure/project/actions';
import { selectLocation, unSelectLocation, addLocation } from '../../../shared/store/structure/location/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import ProjectsList from '../hierarchy/ProjectsList';
import LocationsList from './LocationsList';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import uuid from 'uuid';
import { ReactComponent as TableIcon } from '../../../assets/table-grid.svg';
import { ReactComponent as ExportIcon } from '../../../assets/export.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';
import { INewLocationData, IUpdateableLocationData, ILocation } from '../../../shared/store/structure/location/types';
import { getCustomFieldValueForInput } from '../../../shared/store/custom-fields';
import store from '../../../shared/store/main';
import { LocationState } from 'history';
import { translatePhrase } from '../../../shared/helpers/translation';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const selectedProjectId = state.structure.projects.selected,
        selectedLocationIds = state.structure.locations.selected;
            
    const allLevelsForProject = selectedProjectId ? state.structure.projects.byId[selectedProjectId].children.map(levelId => state.structure.levels.byId[levelId]) : [];

    return {
        isReadable: true,
        selectedProject: selectedProjectId ? state.structure.projects.byId[selectedProjectId] : undefined,
        levelsData: state.structure.levels,
        selectedLocations: selectedLocationIds,
        allLevelsForProject,
        noOfLevelsInProject : selectedProjectId ? state.structure.projects.byId[selectedProjectId].children.length : 0,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectProject: (id: string) => dispatch(selectProject(id)),
        unSelectProject: () => dispatch(unSelectProject()),

        selectLocation: (id: string, index: number) => dispatch(selectLocation(id, index)),
        unSelectLocation: (index: number) => dispatch(unSelectLocation(index)),
        addLocation: (payload: INewLocationData, parentId: string) => dispatch(addLocation(payload, parentId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    erroneousData: Array<Array<string>>,
}

class ConnectedLocations extends Component<Props, OwnState> {
    state = {
        erroneousData: [],
    };

    onSelectLocation = (locationIndex: number, id: string) => {
        this.props.selectLocation(id, locationIndex - 1);
    }

    onUnSelectLocation = (locationIndex: number) => {
        this.props.unSelectLocation(locationIndex - 1);
    }

    importRow = (locationData: Array<string>) => {
        const selectedProject = this.props.selectedProject;

        if (!selectedProject) {
            throw new Error('There must be a project selected');
        }

        let columnIndex = 0;
        let rawImportInput: string;
        let parentLocation: ILocation|undefined;
        let locationStateData = store.getState().structure.locations;

        // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
        function getLocationFromName (siblingLocationIds: Array<string>, locationName: string, locationStateData: LocationState) {
            const currentLocationId = siblingLocationIds.find(locationId =>  locationStateData.byId[locationId].name === locationName);

            return typeof currentLocationId !== 'undefined' ? locationStateData.byId[currentLocationId] : undefined;
        }

        
        // Loop through each level
        for (const levelId of selectedProject.children) {
            const level = this.props.levelsData.byId[levelId];

            if (locationData.length <= columnIndex) {
                throw new Error(`This row does not have data for this level': ${level.name}`);
            }

            rawImportInput = locationData[columnIndex];
            let currentLocation: ILocation|undefined;

            // Get current location data
            if (columnIndex === 0) {
                currentLocation = getLocationFromName(locationStateData.byProject[selectedProject.id], rawImportInput, locationStateData);
            } else {
                if (typeof parentLocation === 'undefined') {
                    throw new Error('The parent location must be defined for lower level locations');
                }

                currentLocation = getLocationFromName(parentLocation.children, rawImportInput, locationStateData);
            }

            // If the current location does not exist, prepare the new location data.
            if (typeof currentLocation === 'undefined') {
                const newLocationId = uuid.v4();
                const newLocationData: IUpdateableLocationData = {
                    id: newLocationId,
                    name: rawImportInput,
                    customFields: {},
                };

                // Loop through the custom fields, and store the value in the new Location Data
                for (const customFieldId of level.customFields) {
                    const customField = this.props.levelsData.customFields.byId[customFieldId];
    
                    if (!customField.isComputed) {
                        columnIndex += 1;
    
                        if (locationData.length <= columnIndex) {
                            throw new Error(`This row does not have data for this custom field: ${customField.name}`);
                        }
    
                        rawImportInput = locationData[columnIndex];
        
                        // Custom field values of the new location is stored
                        const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, this.props.levelsData.customFieldOptions);
                        newLocationData.customFields[customField.id] = customFieldValue;
                    }
    
                }

                const parentId = typeof parentLocation === 'undefined' ? selectedProject.id : parentLocation.id;

                this.props.addLocation(newLocationData, parentId);
                locationStateData = store.getState().structure.locations;
                currentLocation = locationStateData.byId[newLocationId];
            } else {
                // If the location already exists, you can skip over all its custom field values when importing the data
                columnIndex += level.customFields.filter(customFieldId => !this.props.levelsData.customFields.byId[customFieldId].isComputed).length;
            }

            columnIndex += 1;
            parentLocation = currentLocation;
        }

    }

    importLocations = (importData: Array<Array<string>>) => {

        const erroneousData: Array<Array<string>> = []

        if (!this.props.selectedProject) {
            throw new Error('There must be a project selected');
        }

        const errorFileHeading = this.getImportFileHeader().concat(['Errors']);
        const noOfColumns = errorFileHeading.length - 1;
        erroneousData.push(errorFileHeading);

        const dataWithoutHeader = importData.slice(1);

        for (const rowData of dataWithoutHeader) {
            if (rowData.length > 1 || (rowData.length === 1 && rowData[0].trim() !== '')) {
                try {
                    this.importRow(rowData);
                } catch (e) {
                    const erroneousRow = Array(noOfColumns).fill('').map((entry, index) => {
                        if (index < rowData.length) {
                            return rowData[index];
                        } else {
                            return '';
                        }
                    });
                    erroneousRow.push(e.message);
                    erroneousData.push(erroneousRow);
                }
            }
        }

        this.setState({
            erroneousData,
        });

    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;
                    this.importLocations(csvData);
                }
            });
        }
    }

    getImportFileHeader = () => {
        let templateMarkup: Array<string> = [];

        if (this.props.selectedProject) {

            for (const levelId of this.props.selectedProject.children) {
                const level = this.props.levelsData.byId[levelId];

                templateMarkup.push(`Name of ${level.name}`);

                for (const customFieldId of level.customFields) {
                    const customField = this.props.levelsData.customFields.byId[customFieldId];

                    if (!customField.isComputed) {
                        templateMarkup.push(customField.name);
                    }

                }
            }

        }

        return templateMarkup;

    }

    dismissErrorMessage = () => {
        this.setState({
            erroneousData: [],
        });
    }
        
    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        let locationsMarkup: Array<JSX.Element> = [];
        const templateMarkup: Array<string> = this.getImportFileHeader();

        if (this.props.selectedProject) {

            const parentIds = [this.props.selectedProject.id].concat(this.props.selectedLocations.slice(0, this.props.noOfLevelsInProject - 1));

            locationsMarkup = parentIds.map((parentId, index) => {
                return <LocationsList 
                    key={parentId} 
                    heading={this.props.allLevelsForProject[index].name} 
                    levelId={this.props.allLevelsForProject[index].id} 
                    parentId={parentId} 
                    onSelectCard={this.onSelectLocation.bind(this, index)} 
                    onUnSelectCard={this.onUnSelectLocation.bind(this, index)} 
                    selectedId={this.props.selectedLocations[index]} 
                />;
            });

            locationsMarkup = locationsMarkup.slice(0, this.props.noOfLevelsInProject);

        }

        return (<div className={styles.locationsHolder}>
            <div className={styles.allProjects} onClick={this.props.unSelectProject}>
                <ProjectsList heading="Projects" onSelectCard={this.props.selectProject} onUnSelectCard={this.props.unSelectProject} selectedId={this.props.selectedProject ? this.props.selectedProject.id : undefined} isReadOnly />
            </div>
            {this.props.selectedProject && 
            <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                {locationsMarkup}
            </div>}
            {this.props.selectedProject && <CSVLink data={[templateMarkup]} filename={`Locations template.csv`}><button className={styles.importTemplate + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}> <TableIcon /> {translatePhrase('Download template')}</button></CSVLink>}

            <input type="file" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />

            {this.props.selectedProject && <label htmlFor="import-file-input" className={styles.importButton + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}> <ExportIcon /> {translatePhrase('Import')}</label>}

            {this.state.erroneousData.length > 1 && <div className={styles.errorMessageHolder + ' ignore-react-onclickoutside'}>
            {translatePhrase('Errors during import. Please download this file to fix errors and re-upload')}: <CSVLink data={this.state.erroneousData} filename={`Import errors.csv`}><span className={styles.highlight}>{translatePhrase('Error file')}</span></CSVLink>.

                <div className={styles.dismissal} onClick={this.dismissErrorMessage} >
                    <CancelIcon/>
                    <span>{translatePhrase('Dismiss')}</span>
                </div>
            </div>}
            
        </div>);
        
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;