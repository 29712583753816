import { INewCustomFieldData, CustomFieldState, IUpdateableFieldChoiceData } from '../../custom-fields';

import { NormalizedModel, Synchronizable } from '../../normalized-model';
import { StatusState } from './statuses/types';

import { PiecePositionState } from '../../flowchart/pieces/types';

export const SELECT_WORKFLOW_TYPE = 'SELECT_WORKFLOW_TYPE';
export const UN_SELECT_WORKFLOW_TYPE = 'UN_SELECT_WORKFLOW_TYPE';
export const RE_ORDER_WORKFLOW_TYPES = 'RE_ORDER_WORKFLOW_TYPES';
export const ADD_WORKFLOW_TYPE_REQUEST = 'ADD_WORKFLOW_TYPE_REQUEST';
export const ADD_WORKFLOW_TYPE = 'ADD_WORKFLOW_TYPE';
export const DELETE_WORKFLOW_TYPE = 'DELETE_WORKFLOW_TYPE';
export const UPDATE_WORKFLOW_TYPE = 'UPDATE_WORKFLOW_TYPE';

export const UPDATE_WORKFLOW_TYPE_START_PIECE = 'UPDATE_WORKFLOW_TYPE_START_PIECE';
export const SET_ISOLATED_WORKFLOW_TYPE_PIECE = 'SET_ISOLATED_WORKFLOW_TYPE_PIECE';
export const REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE = 'REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE';
export const REGISTER_WORKFLOW_TYPE_VARIABLE = 'REGISTER_WORKFLOW_TYPE_VARIABLE';

export const SELECT_WORKFLOW_TYPE_CUSTOM_FIELD = 'SELECT_WORKFLOW_TYPE_CUSTOM_FIELD';
export const UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD = 'UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD';
export const ADD_WORKFLOW_TYPE_CUSTOM_FIELD = 'ADD_WORKFLOW_TYPE_CUSTOM_FIELD';
export const DELETE_WORKFLOW_TYPE_CUSTOM_FIELD = 'DELETE_WORKFLOW_TYPE_CUSTOM_FIELD';
export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD';

export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE';
export const REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE = 'REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE';

export const SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';


export interface INewWorkflowTypeCustomFieldData extends INewCustomFieldData {
    affiliation: 'member'|'group',

    seedAffiliationVariable?: string,
}

export interface IUpdateableWorkflowTypeCustomFieldData extends INewWorkflowTypeCustomFieldData {
    id: string,
}

export interface WorkflowTypeCustomField extends IUpdateableWorkflowTypeCustomFieldData {
    archived?: boolean,
    parentId: string|undefined,
    startPiece?: PiecePositionState,
    variables: Array<string>,
    isolatedPieces: Array<PiecePositionState>,
    choices: Array<string>,
}

type CustomFieldDataType = {
    byId: {
        [id: string]: WorkflowTypeCustomField
    },
    allFields: Array<string>,
}

export interface WorkflowTypeCustomFieldState extends CustomFieldState {
    customFields: CustomFieldDataType,
}

export interface INewWorkflowTypeData {
    name: string;
    affiliatedEntity: string,
    affiliation: 'member'|'group'|'none',
    isCore: boolean,
    isManaged?: boolean,
    project: string,
    startPiece: PiecePositionState,

    seedEntityVariable: string,
    seedAffiliationVariable: string,
}

export interface IUpdateableWorkflowTypeData extends INewWorkflowTypeData {
    id: string;
}

export interface IWorkflowType extends IUpdateableWorkflowTypeData, Synchronizable {
    archived?: boolean,

    isolatedPieces: Array<PiecePositionState>,
    workflows: Array<string>,
    statuses: Array<string>,
    pieces: Array<string>,
    variables: Array<string>,
    customFields: Array<string>,
}


export interface WorkflowTypeState extends NormalizedModel<IWorkflowType>, WorkflowTypeCustomFieldState {
    selected: string|undefined,
    statuses: StatusState,

    addMemberWorkflowType?: string,
    editMemberWorkflowType?: string,
    addGroupWorkflowType?: string,
    editGroupWorkflowType?: string,
}

export interface SelectWorkflowTypeAction {
    type: typeof SELECT_WORKFLOW_TYPE,
    id: string,
}

export interface UnSelectWorkflowTypeAction {
    type: typeof UN_SELECT_WORKFLOW_TYPE,
}

export interface ReOrderWorkflowTypesAction {
    type: typeof RE_ORDER_WORKFLOW_TYPES,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddWorkflowTypeRequestAction {
    type: typeof ADD_WORKFLOW_TYPE_REQUEST,
    payload: INewWorkflowTypeData,
}

export interface AddWorkflowTypeAction {
    type: typeof ADD_WORKFLOW_TYPE,
    payload: IWorkflowType,
}

export interface DeleteWorkflowTypeAction {
    type: typeof DELETE_WORKFLOW_TYPE,
    id: string,
}

export interface UpdateWorkflowTypeAction {
    type: typeof UPDATE_WORKFLOW_TYPE,
    payload: IUpdateableWorkflowTypeData,
}


export interface UpdateWorkflowTypeStartPieceAction {
    type: typeof UPDATE_WORKFLOW_TYPE_START_PIECE,
    payload: PiecePositionState,
    workflowTypeId: string,
}

export interface SetIsolatedWorkflowTypePieceAction {
    type: typeof SET_ISOLATED_WORKFLOW_TYPE_PIECE,
    payload: PiecePositionState,
    workflowTypeId: string,
}

export interface RemoveIsolatedWorkflowTypePieceAction {
    type: typeof REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE,
    pieceId: string,
    workflowTypeId: string,
}

export interface RegisterWorkflowTypeVariableAction {
    type: typeof REGISTER_WORKFLOW_TYPE_VARIABLE,
    variableId: string,
    workflowTypeId: string,
}



export interface SelectWorkflowTypeCustomFieldAction {
    type: typeof SELECT_WORKFLOW_TYPE_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectWorkflowTypeCustomFieldAction {
    type: typeof UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD,
}

export interface AddWorkflowTypeCustomFieldAction {
    type: typeof ADD_WORKFLOW_TYPE_CUSTOM_FIELD,
    payload: IUpdateableWorkflowTypeCustomFieldData,
    workflowTypeId: string,
}

export interface DeleteWorkflowTypeCustomFieldAction {
    type: typeof DELETE_WORKFLOW_TYPE_CUSTOM_FIELD,
    id: string,
    workflowTypeId: string,
}

export interface UpdateWorkflowTypeCustomFieldAction {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD,
    payload: IUpdateableWorkflowTypeCustomFieldData,
}


export interface UpdateWorkflowTypeCustomFieldStartPieceAction {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface SetIsolatedWorkflowTypeCustomFieldPieceAction {
    type: typeof SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface RemoveIsolatedWorkflowTypeCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
}

export interface RegisterWorkflowTypeCustomFieldVariableAction {
    type: typeof REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
}


export interface SelectWorkflowTypeCustomFieldOptionAction {
    type: typeof SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectWorkflowTypeCustomFieldOptionAction {
    type: typeof UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
}

export interface ReOrderWorkflowTypeCustomFieldOptionAction {
    type: typeof RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddWorkflowTypeCustomFieldOptionAction {
    type: typeof ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
}

export interface DeleteWorkflowTypeCustomFieldOptionAction {
    type: typeof DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
}

export interface UpdateWorkflowTypeCustomFieldOptionAction {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
}

type CustomFieldActions = SelectWorkflowTypeCustomFieldAction | UnSelectWorkflowTypeCustomFieldAction | AddWorkflowTypeCustomFieldAction | DeleteWorkflowTypeCustomFieldAction | UpdateWorkflowTypeCustomFieldAction;

type CustomFieldChoiceActions = SelectWorkflowTypeCustomFieldOptionAction | UnSelectWorkflowTypeCustomFieldOptionAction | ReOrderWorkflowTypeCustomFieldOptionAction | AddWorkflowTypeCustomFieldOptionAction | DeleteWorkflowTypeCustomFieldOptionAction | UpdateWorkflowTypeCustomFieldOptionAction;

export type WorkflowTypeActionTypes = SelectWorkflowTypeAction | UnSelectWorkflowTypeAction | ReOrderWorkflowTypesAction | AddWorkflowTypeRequestAction | AddWorkflowTypeAction | DeleteWorkflowTypeAction | UpdateWorkflowTypeAction | UpdateWorkflowTypeStartPieceAction | SetIsolatedWorkflowTypePieceAction | RemoveIsolatedWorkflowTypePieceAction | RegisterWorkflowTypeVariableAction | UpdateWorkflowTypeCustomFieldStartPieceAction | SetIsolatedWorkflowTypeCustomFieldPieceAction | RemoveIsolatedWorkflowTypeCustomFieldPieceAction | RegisterWorkflowTypeCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions;