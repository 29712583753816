export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';

export interface TranslationsState {
    byLanguage: {
        [languageId: string]: {
            [word: string]: string,
        },
    },
    updatedWords: {
        [languageId: string]: Array<string>,
    }
    currentLanguage: string|undefined,
}

interface UpdateTranslationAction {
    type: typeof UPDATE_TRANSLATION,
    originalWord: string,
    translatedWord: string,
    language: string,
}

export type TranslationActionTypes = UpdateTranslationAction;