import React, { FC } from 'react';
import styles from './ConstantPiece.module.scss';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';


export type ConstantPieceProps = {
    constantName: string,
}

type Props = ConstantPieceProps & FlowchartPieceProps;

const ConstantPiece: FC<Props> = (props) => {
    return (
        <FlowchartPiece {...props}>
            <div className={styles.constantPiece}><div className={styles.text}>{props.constantName}</div></div>
        </FlowchartPiece>
    )
}

export default ConstantPiece;