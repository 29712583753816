import React from 'react';
import styles from './Members.module.scss';
import { default as tabStyles } from '../../widgets/tabs/Tabs.module.scss';
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import { ApplicationState } from '../../shared/store/main';

import { Permissions } from '../../shared/store/permissions/types';

import Tabs from '../../widgets/tabs/Tabs';

import Configuration from './configuration/Configuration';
import List from './list/List';

import { translatePhrase } from '../../shared/helpers/translation';

import { connect } from 'react-redux';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewMembersConfiguration = state.permissions.myPermissions.general.MembersConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.MembersConfiguration === Permissions.READ;
    const canViewMemberList = state.permissions.myPermissions.general.Members === Permissions.WRITE || state.permissions.myPermissions.general.Members === Permissions.READ;
    
    return {
        showTranslations: false,
        translationsExpanded: false,
        canGoToConfiguration: canViewMembersConfiguration,
        canGoToList: canViewMemberList,
        hasMembers: state.members.allEntries.length > 0,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedMembers: React.FC<Props> = (props) => {
    
    if (!(props.canGoToConfiguration || props.canGoToList)) {
        return <Redirect to="/dashboard" />;
    }
    
    return (
        <section className={props.showTranslations && props.translationsExpanded ? styles.ConstrainedFocusSpace : styles.FocusSpace}>
            <Tabs>
                {props.canGoToList && <NavLink to="/members/list" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('List')} </NavLink>}
                {props.canGoToConfiguration && <NavLink to="/members/configuration" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Configuration')} </NavLink>}
            </Tabs>
            
            <Switch>
                <Route path="/members/configuration" component={Configuration} />
                <Route path="/members/list" component={List} />
                <Route render={() => <Redirect to={props.canGoToList && (props.hasMembers || !props.canGoToConfiguration) ? '/members/list' : '/members/configuration'} />}/>
            </Switch>
        </section>
    );
};

const Members = connect(mapStateToProps)(ConnectedMembers);

export default Members;