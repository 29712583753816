import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setDataStoreValue, setMemberVariable, setCustomField, setVariableForCustomField, setEntityType } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { valuePieceSlotTarget } from './utilities';


type StorePieceProps = {
    selectedType?: string,
    selectedEntityType?: string,
    
    nextPiece?: JSX.Element,
    variablePiece?: JSX.Element,
    dataVariablePiece?: JSX.Element,
    textToStore?: string,
    memberVariable?: JSX.Element,

    storingCustomField?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        userData: state.users,
        memberTypes: state.members.types,
        groupTypes: state.groups.types,
        workflowTypes: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setDataStoreValue: (targetPieceId: string, draggedPieceId: string) => dispatch(setDataStoreValue(targetPieceId, draggedPieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
        setCustomField: (pieceId: string, value: string) => dispatch(setCustomField(pieceId, value)),
        setVariableForCustomField: (pieceId: string, value: string) => dispatch(setVariableForCustomField(pieceId, value)),
        setEntityType: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StorePieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type StorePieceState = {
    isHoveringOverMemberPiece: boolean,
    isHoveringOverVariablePiece: boolean,
    isHoveringOverDataVariablePiece: boolean,
}

class ConnectedStorePiece extends Component<Props, StorePieceState> {

    state = {
        isHoveringOverMemberPiece: false,
        isHoveringOverVariablePiece: false,
        isHoveringOverDataVariablePiece: false,
    };

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleSetValueUpdate = (value: string) => {
        this.props.setDataStoreValue(this.props.pieceId, value);
    }

    handleHoverOverDataVariablePiece = () => {
        this.setState({
            isHoveringOverDataVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDataVariablePiece = () => {
        this.setState({
            isHoveringOverDataVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverMemberPiece || this.state.isHoveringOverVariablePiece || this.state.isHoveringOverDataVariablePiece)) {

            if (this.state.isHoveringOverMemberPiece) {
                this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariableForCustomField(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverDataVariablePiece) {
                this.props.setDataStoreValue(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMemberPiece: false,
                isHoveringOverVariablePiece: false,
                isHoveringOverDataVariablePiece: false,
            });
        }
    }

    render() {

        const entityTypeSelectCallback = this.props.setEntityType.bind(this, this.props.pieceId);

        let entityTypeSelectBox: JSX.Element|undefined;
        let customFieldsSelectBox: JSX.Element|undefined;
        let entityType: string|undefined;

        switch(this.props.selectedType) {

            case 'Location':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.memberTypes.byId.hasOwnProperty(entityType) ? this.props.memberTypes.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.levelsData.allEntries.map(levelId => {
                            const levelData = this.props.levelsData.byId[levelId];

                            return <ListItem theme="pink" name={levelData.name + ' (' + this.props.projectsData.byId[levelData.project].name + ')'} key={levelId} value={levelId} onClick={entityTypeSelectCallback} />
                        })}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.levelsData.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField ? this.props.levelsData.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink" dismissAfterSelection={false}>
                            {this.props.levelsData.byId[entityType].customFields.map(levelFieldId => <ListItem theme="pink" name={this.props.levelsData.customFields.byId[levelFieldId].name} key={levelFieldId} value={levelFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }

                break;

            case 'User':
                entityType = this.props.selectedEntityType;

                const customFieldName = entityType ? this.props.userData.customFields.byId.hasOwnProperty(entityType) ? this.props.userData.customFields.byId[entityType].name : this.props.rolesData.customFields.byId.hasOwnProperty(entityType) ? this.props.rolesData.customFields.byId[entityType].name : 'Select Fields' : 'Select Fields';

                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={customFieldName}>
                    <DropDownList theme="pink">
                        {this.props.userData.customFields.allFields.map(userFieldId => <ListItem theme="pink" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        {entityType && (this.props.userData.customFields.byId.hasOwnProperty(entityType) || this.props.rolesData.customFields.byId.hasOwnProperty(entityType)) ? this.props.rolesData.byId[entityType].customFields.map(roleFieldId => <ListItem theme="pink" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />) : undefined}
                    </DropDownList>
                </SelectBox>;

                break;
            
            case 'Member':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.memberTypes.byId.hasOwnProperty(entityType) ? this.props.memberTypes.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.memberTypes.allEntries.map(memberTypeId => <ListItem theme="pink" name={this.props.memberTypes.byId[memberTypeId].name} key={memberTypeId} value={memberTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.memberTypes.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField ? this.props.memberTypes.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink">
                            {this.props.memberTypes.byId[entityType].customFields.map(memberFieldId => <ListItem theme="pink" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }

                break;
            
            case 'Group':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.groupTypes.byId.hasOwnProperty(entityType) ? this.props.groupTypes.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.groupTypes.allEntries.map(groupTypeId => <ListItem theme="pink" name={this.props.groupTypes.byId[groupTypeId].name} key={groupTypeId} value={groupTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.groupTypes.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField ? this.props.groupTypes.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink">
                            {this.props.groupTypes.byId[entityType].customFields.map(groupFieldId => <ListItem theme="pink" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }
                break;
            
            case 'Workflow':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType) ? this.props.workflowTypes.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.workflowTypes.allEntries.map(workflowTypeId => <ListItem theme="pink" name={this.props.workflowTypes.byId[workflowTypeId].name} key={workflowTypeId} value={workflowTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField ? this.props.workflowTypes.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink">
                            {this.props.workflowTypes.byId[entityType].customFields.map(workflowFieldId => <ListItem theme="pink" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }
                break;
            
            default:
                entityTypeSelectBox = undefined;
        }

        const isForSingleMember = this.props.selectedType === 'Workflow' && this.props.selectedEntityType && this.props.storingCustomField && this.props.workflowTypes.byId[this.props.selectedEntityType].affiliation === 'group' && this.props.workflowTypes.customFields.byId[this.props.storingCustomField].affiliation === 'member';

        return (
            <StepPiece theme="pink" {...this.props}>
                <div className={styles.text}>store</div>
                {this.props.dataVariablePiece ? this.props.dataVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverDataVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverDataVariablePiece} onMouseOut={this.handleHoverOutOfDataVariablePiece} defaultText={this.props.textToStore} onChange={this.handleSetValueUpdate} placeholderText="Variable or data to store" minSize={24} />}
                {(this.props.dataVariablePiece || this.props.textToStore) && <div className={styles.text}>in</div>}

                {(this.props.dataVariablePiece || this.props.textToStore) && (this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} defaultText={this.props.textToStore} onChange={this.handleSetValueUpdate} placeholderText="Entity variable (e.g. the 'Workflow' variable)" minSize={44} />)}
                {this.props.selectedType && <div className={styles.text}>which is a {this.props.selectedType} of type</div>}
                {this.props.selectedType && entityTypeSelectBox}
                {this.props.selectedEntityType && <div className={styles.text}>in the custom field:</div>}
                {customFieldsSelectBox}

                {isForSingleMember && <div className={styles.text}>for</div>}
                {isForSingleMember && (this.props.memberVariable ? this.props.memberVariable : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} placeholderText="Member variable" minSize={15} />)}
            </StepPiece>
        )

    }
}

const StorePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStorePiece);

export default StorePiece;