import React, { Component } from 'react';
import styles from './container-piece/ContainerPiece.module.scss';
import Input from '../Input';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setLoopVariable, setIterableVariable } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import ContainerPiece from './container-piece/ContainerPiece';
import { valuePieceSlotTarget, groupedNextPieceTarget } from './utilities';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';


type GroupPieceProps = {
    variables?: Array<Option>,
    loopVariableName?: string,

    nextPiece?: JSX.Element,
    iterablePiece?: JSX.Element,

    isForMembersList?: boolean,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setLoopVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setLoopVariable(targetPieceId, draggedPieceId)),
        setIterableVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setIterableVariable(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GroupPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type GroupPieceState = {
    isHoveringOverIterablePiece: boolean,
}

class ConnectedGroupPiece extends Component<Props, GroupPieceState>  {

    state = {
        isHoveringOverIterablePiece: false,
    };

    handleHoverOverInnerPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        groupedNextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);

        switch (this.props.lastDraggedPiece.type) {
            case PieceType.SECTION:
                this.props.setTargetPiece(this.props.pieceId);
                break;
    
            default:
                break;
        }
    };

    handleHoverOverIterablePiece = () => {
        this.setState({
            isHoveringOverIterablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfIterablePiece = () => {
        this.setState({
            isHoveringOverIterablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverIterablePiece)) {
            if (this.state.isHoveringOverIterablePiece) {
                this.props.setIterableVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverIterablePiece: false,
            });
        }
    }

    render() {

        const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.loopVariableName}>
            <DropDownList theme="indigo">
                {(this.props.variables ? this.props.variables : []).map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setLoopVariable.bind(this, this.props.pieceId)} />)}
            </DropDownList>
        </SelectBox>;

        const upperArmContent = (<div className={styles.upperArmContent}>
            <div className={styles.text}>{this.props.isForMembersList ? 'group for' : 'group'}</div>
            {this.props.isForMembersList && variableSelectBox}
            {this.props.isForMembersList && <div className={styles.text}>in </div>}
            {this.props.isForMembersList && (this.props.iterablePiece ? this.props.iterablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverIterablePiece && !!this.props.targetPiece} isDisabled={true} onMouseOver={this.handleHoverOverIterablePiece} onMouseOut={this.handleHoverOutOfIterablePiece} />)}
        </div>);

        return (<ContainerPiece
            {...this.props}
            theme="aqua"
            handleHoverOverInnerPiece={this.handleHoverOverInnerPiece}
            upperArmContent={upperArmContent}
        >
            {this.props.children}
        </ContainerPiece>);

    }
}

const GroupPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupPiece);

export default GroupPiece;