import React from 'react';
import styles from './List.module.scss';

import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import ReportsTable from './ReportsTable';

import { ApplicationState } from '../../../shared/store/main';
import { Permissions } from '../../../shared/store/permissions/types';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditReports = state.permissions.myPermissions.general.Reports === Permissions.WRITE;
    const canViewReports = canEditReports || state.permissions.myPermissions.general.Reports === Permissions.READ;
    
    return {
        filtersExpanded: false,
        read: canViewReports,
        write: canEditReports,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

const ConnectedReports: React.FC<Props> = function (props) {
        
    if (!props.read) {
        return <Redirect to="/dashboard" />;
    }
    
    return (
        <section className={styles.reportsSection}>
            <div className={props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                <ReportsTable isReadOnly={!props.write} />
            </div>
            {/* <ReportsFilter /> */}
        </section>
    );
};

const Reports = connect(mapStateToProps)(ConnectedReports);

export default Reports;