import React, { FC } from 'react';
import styles from './WorkflowData.module.scss';
import Table, { TableHeading, TableRow } from '../../../widgets/table/Table';

import { connect } from 'react-redux';

import { getReadableDataForCustomField, FieldType } from '../../../shared/store/custom-fields';

import { ApplicationState } from '../../../shared/store/main';
import { WorkflowTypeCustomField } from '../../../shared/store/workflows/types/types';
import { CustomFieldDataForIndividualMembers } from '../../../shared/store/workflows/types';
import moment from 'moment';
import { translatePhrase } from '../../../shared/helpers/translation';

type OwnProps = {
    workflowId: string,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const workflow = state.workflows.byId[ownProps.workflowId];
    const workflowType = state.workflows.types.byId[workflow.type];
    const processState = workflow.history[workflow.historyIndex];

    return {
        workflow,
        processState,
        workflowType,
        workflowData: state.workflows,
        membersData: state.members,
        groupsData: state.groups,
        optionsData: state.workflows.types.customFieldOptions,

        applicationState: state,
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

const ConnectedWorkflowData: FC<Props> = (props) => {

    const normalCustomFieldHeadings: Array<TableHeading> = [{
        name: 'Sl. no',
        isSortable: false,
        isSticky: false,
        width: 90,
    }, {
        name: 'Field name',
        isSortable: false,
        isSticky: false,
        width: 200,
    }, {
        name: 'Value',
        isSortable: false,
        isSticky: false,
        width: 200,
    }];

    const allCustomFields = props.workflowType.customFields.map(customFieldId => props.workflowData.types.customFields.byId[customFieldId]);

    let normalCustomFields: Array<WorkflowTypeCustomField> = [];
    let perMemberCustomFields: Array<WorkflowTypeCustomField> = [];

    if (props.workflowType.affiliation === 'group') {
        normalCustomFields = allCustomFields.filter(customField => customField.affiliation === 'group');
        perMemberCustomFields = allCustomFields.filter(customField => customField.affiliation === 'member');
    } else {
        normalCustomFields = allCustomFields.slice(0);
    }

    const normalEntries: Array<TableRow> = normalCustomFields.map((customField, index) => {
        let customFieldValue = props.processState.customFields[customField.id];

        if (!Array.isArray(customFieldValue) && customFieldValue !== null && typeof customFieldValue === 'object') {
            throw new Error('There should not be any custom fields for individual members in normal entries');
        }

        let readableCustomFieldValue = getReadableDataForCustomField(customFieldValue, customField, props.workflowId, 'workflow');

        if (customField.type === FieldType.SINGLE_SELECT) {
            readableCustomFieldValue = translatePhrase(readableCustomFieldValue);
        } else if (customField.type === FieldType.MULTI_SELECT) {
            readableCustomFieldValue = readableCustomFieldValue.split(',').map(value => translatePhrase(value.trim())).join(', ');
        }

        const cells = [
            index + 2,  // Sl. no
            translatePhrase(customField.name),  // Name
            readableCustomFieldValue,  // Value
        ];

        return {
            id: customField.id,
            entries: cells,
        }
    });

    normalEntries.unshift({
        id: 'created-time',
        entries: [
            1,
            'Created time',
            moment(props.workflow.createdTime).format('DD MMM YYYY HH:mm:ssA'),
        ]
    });

    let perMemberCustomFieldHeadings: Array<TableHeading> = [{
        name: 'Sl. no',
        isSortable: false,
        isSticky: false,
        width: 90,
    }, {
        name: 'Member name',
        isSortable: false,
        isSticky: false,
        width: 200,
    }];

    perMemberCustomFields.forEach(customField => {
        perMemberCustomFieldHeadings.push({
            name: customField.name,
            isSortable: false,
            isSticky: false,
            width: 200,
        });
    });

    let perMemberEntries: Array<TableRow> = [];

    if (props.workflowType.affiliation === 'group') {
        const affiliatedGroup = props.groupsData.byId[props.workflow.affiliatedEntity]
        perMemberEntries = affiliatedGroup.members.map((memberId, index) => {
            const member = props.membersData.byId[memberId];
            
            const memberType = props.membersData.types.byId[member.type];
            let memberName = member.customFields[memberType.nameFieldId];

            const nameField = props.membersData.types.customFields.byId[memberType.nameFieldId];

            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            let cells = [
                index + 1,  // Sl. no
                memberName,  // Member
            ];

            perMemberCustomFields.forEach(customField => {

                let customFieldValue = props.processState.customFields[customField.id] as CustomFieldDataForIndividualMembers;

                if (typeof customFieldValue === 'undefined') {
                    customFieldValue = {};
                }
        
                if (typeof customFieldValue !== 'object') {
                    throw new Error('There should only be custom fields for individual members in per-member entries');
                }

                if (customField.isComputed && customField.startPiece) {
                    throw new Error('You cannot have computed fields for per-member fields');
                } else {

                    if (typeof customFieldValue === 'undefined') {
                        customFieldValue = {};
                    }
            
                    if (typeof customFieldValue !== 'object') {
                        throw new Error('There should only be custom fields for individual members in per-member entries');
                    }

                    cells.push(
                        getReadableDataForCustomField(customFieldValue[member.id], customField, props.workflowId, 'workflow'),
                    );
                }
            });

            return {
                id: member.id,
                entries: cells,
            };

        });
    }

    return <div>
        {normalCustomFields.length > 0 && <section className={styles.normalTable}>
            <Table 
                headings={normalCustomFieldHeadings}
                entries={normalEntries}
                isReadOnly
            />
        </section>}

        {perMemberEntries.length > 0 && <section className={styles.perMemberTable}>
            <Table 
                headings={perMemberCustomFieldHeadings}
                entries={perMemberEntries}
                isReadOnly
            />
        </section>}
    </div>
}

const WorkflowData = connect(mapStateToProps)(ConnectedWorkflowData);

export default WorkflowData;