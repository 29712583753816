import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './shared/store/main';

import OaaSApp from './components/OaaSApp';


class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <OaaSApp />
                </BrowserRouter>
            </Provider>
        );
    }
}

export default App;
