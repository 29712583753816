import React from 'react';
import styles from './List.module.scss';

import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { Permissions } from '../../../shared/store/permissions/types';

import UsersTable from './UsersTable';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditUsers = state.permissions.myPermissions.general.Users === Permissions.WRITE;
    const canViewUsers = canEditUsers || state.permissions.myPermissions.general.Users === Permissions.READ;
    
    return {
        filtersExpanded: false,
        read: canViewUsers,
        write: canEditUsers,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

const ConnectedUsers: React.FC<Props> = function (props) {
        
    if (!props.read) {
        return <Redirect to="/dashboard" />;
    }
    
    return (
        <section className={styles.usersSection}>
            <div className={props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                <UsersTable isReadOnly={!props.write} />
            </div>
            {/* <UsersFilter /> */}
        </section>
    );
};

const Users = connect(mapStateToProps)(ConnectedUsers);

export default Users;