import { MemberState, MemberActionTypes, ADD_MEMBER, UPDATE_MEMBER, DELETE_MEMBER, SEARCH_MEMBER_TABLE, GO_TO_PAGE_MEMBER_TABLE, SET_PAGE_SIZE_MEMBER_TABLE, SORT_MEMBER_TABLE, UPDATE_MEMBER_DATA, UPDATE_MEMBER_WORKFLOWS, UPDATE_MEMBER_CUSTOM_FIELD_DATA, ADD_GROUP_TO_MEMBER, REMOVE_GROUP_FROM_MEMBER, FILTER_MEMBER_TABLE } from './types';
import { memberTypesReducer, initialState as memberTypesInitialState } from './types/reducer';

const initialState: MemberState = {
    byId: {},
    allEntries: [],

    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),

    types: memberTypesInitialState,

    pageSize: 10,
    currentPageNumber: 1,

    filters: {
        types: [],
        locations: [],
        customFields: {}
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: undefined,
};

export function membersReducer(state = initialState, action: MemberActionTypes): MemberState {
    state = {
        ...state,
        types: memberTypesReducer(state.types, action),
    };

    let connectedWorkflows: Array<string> = [];
    let connectedGroups: Array<string> = [];

    switch(action.type) {
        case ADD_MEMBER:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        lastKnownLocation: undefined,
                        lastMetTime: undefined,
                        workflows: {},
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
                createdIds: new Set([...state.createdIds, action.payload.id]),
            };
        
        case UPDATE_MEMBER:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload,
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.payload.id]),
            };
            
        case UPDATE_MEMBER_WORKFLOWS:
            connectedWorkflows = state.byId[action.memberId].workflows[action.workflowTypeId] || [];
            connectedWorkflows = Array.from((new Set(connectedWorkflows)).add(action.workflowId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        workflows: {
                            ...state.byId[action.memberId].workflows,
                            [action.workflowTypeId]: connectedWorkflows,
                        },
                    },
                },
            };

        case UPDATE_MEMBER_CUSTOM_FIELD_DATA:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        customFields: {
                            ...state.byId[action.memberId].customFields,
                            ...action.customFieldData,
                        },
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.memberId]),
            }

        case DELETE_MEMBER:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(memberId => memberId !== action.id),
                deletedIds: new Set([...state.deletedIds, action.id]),
            };

        case ADD_GROUP_TO_MEMBER:
            connectedGroups = state.byId[action.memberId].groups[action.groupTypeId] || [];
            connectedGroups = Array.from((new Set(connectedGroups)).add(action.groupId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        groups: {
                            ...state.byId[action.memberId].groups,
                            [action.groupTypeId]: connectedGroups,
                        },
                    },
                },
            }

        case REMOVE_GROUP_FROM_MEMBER:
            connectedGroups = state.byId[action.memberId].groups[action.groupTypeId] || [];
            connectedGroups = connectedGroups.filter(groupId => groupId !== action.groupId);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        groups: {
                            ...state.byId[action.memberId].groups,
                            [action.groupTypeId]: connectedGroups,
                        }
                    }
                },
            }


        case SEARCH_MEMBER_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_MEMBER_TABLE:
            return {
                ...state,
                filters: {
                    types: action.types,
                    locations: action.locations,
                    customFields: action.customFields,
                },
                currentPageNumber: 1,
            }
        
        case GO_TO_PAGE_MEMBER_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }
        
        case SET_PAGE_SIZE_MEMBER_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }
        
        case SORT_MEMBER_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

        case UPDATE_MEMBER_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                filters: state.filters,
                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                    createdCustomFieldIds: state.types.createdCustomFieldIds,
                    updatedCustomFieldIds: state.types.updatedCustomFieldIds,
                    deletedCustomFieldIds: state.types.deletedCustomFieldIds,
                    createdCustomFieldOptionIds: state.types.createdCustomFieldOptionIds,
                    updatedCustomFieldOptionIds: state.types.updatedCustomFieldOptionIds,
                    deletedCustomFieldOptionIds: state.types.deletedCustomFieldOptionIds,
                },
            }
        
        default:
            return state;
    }
}