import { takeEvery, put, select, all } from 'redux-saga/effects'
import { AddRoleCustomFieldAction, ADD_ROLE_CUSTOM_FIELD, UPDATE_ROLE_CUSTOM_FIELD, AddRoleAction, DeleteRoleAction, ADD_ROLE, DELETE_ROLE } from './types';
import { updateRoleCustomFieldStartPiece, registerRoleCustomFieldVariable } from './actions';
import { addVariable } from '../../flowchart/variables/actions';
import { VariableType } from '../../flowchart/variables/types';
import { addPiece } from '../../flowchart/pieces/actions';
import uuid from 'uuid';
import { PieceType } from '../../flowchart/pieces/types';
import { ApplicationState } from '../../main';
import { addRoleToLevel, removeRoleFromLevel } from '../level/actions';

function* createSeedFlowchartForRoleCustomField(action: AddRoleCustomFieldAction) {

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        const state: ApplicationState = yield select();

        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {
        
            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'Group',
                    type: VariableType.GROUP,
                })),

                put(registerRoleCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateRoleCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id))
            ]);

        }

    }
}

export function* watchRoleCustomFieldChanges() {
    yield takeEvery([ADD_ROLE_CUSTOM_FIELD, UPDATE_ROLE_CUSTOM_FIELD], createSeedFlowchartForRoleCustomField);
}

function* addReverseLinkForParentLevel(action: AddRoleAction) {
    yield put(addRoleToLevel(action.payload.id, action.parentId));
}

function* removeReverseLinkForParentLevel(action: DeleteRoleAction) {
    yield put(removeRoleFromLevel(action.id, action.parentId));
}

export function* watchRoleCreationChanges() {
    yield takeEvery(ADD_ROLE, addReverseLinkForParentLevel);
}

export function* watchRoleDeletionChanges() {
    yield takeEvery(DELETE_ROLE, removeReverseLinkForParentLevel);
}