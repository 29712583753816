import { ReportTypeState, ADD_REPORT_TYPE, UPDATE_REPORT_TYPE, DELETE_REPORT_TYPE, UPDATE_REPORT_TYPE_DATA, REGISTER_REPORT_TYPE_VARIABLE, UPDATE_REPORT_TYPE_START_PIECE, SET_ISOLATED_REPORT_TYPE_PIECE, REMOVE_ISOLATED_REPORT_TYPE_PIECE, ADD_REPORT_TYPE_ROW, IReportType, ADD_REPORT_TYPE_COLUMN, SELECT_REPORT_TYPE, UN_SELECT_REPORT_TYPE, RE_ORDER_REPORT_TYPES } from './types';
import { ReportActionTypes } from '../types';
import { reOrderList } from '../../../helpers/utilities';

export const initialState: ReportTypeState = {
    byId: {},
    allEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function reportTypesReducer(state = initialState, action: ReportActionTypes): ReportTypeState {

    let reportTypeToConsider: IReportType;

    switch (action.type) {
        
        case SELECT_REPORT_TYPE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_REPORT_TYPE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_REPORT_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_REPORT_TYPE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
            };
        
        case UPDATE_REPORT_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    },
                },
            };

        case DELETE_REPORT_TYPE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(userId => userId !== action.id),
            };

        case UPDATE_REPORT_TYPE_DATA:
            return {
                ...action.data,
            };

        case ADD_REPORT_TYPE_ROW:
            reportTypeToConsider = {
                ...state.byId[action.reportTypeId]
            };

            action.newCells.forEach(newCell => {
                reportTypeToConsider.cells.byId[newCell.id] = newCell;
                reportTypeToConsider.cells.allEntries = reportTypeToConsider.cells.allEntries.concat([newCell.id]);
                reportTypeToConsider.noOfRows += 1;
            });

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: reportTypeToConsider
                }
            }

        case ADD_REPORT_TYPE_COLUMN:
            reportTypeToConsider = {
                ...state.byId[action.reportTypeId]
            };

            action.newCells.forEach(newCell => {
                reportTypeToConsider.cells.byId[newCell.id] = newCell;
                reportTypeToConsider.cells.allEntries = reportTypeToConsider.cells.allEntries.concat([newCell.id]);
                reportTypeToConsider.noOfColumns += 1;
            });

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: reportTypeToConsider
                }
            }

        case UPDATE_REPORT_TYPE_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        startPiece: action.payload,
                    }
                }
            }

        case SET_ISOLATED_REPORT_TYPE_PIECE:
            const newReportTypeIsolatedPieces = state.byId[action.reportTypeId].isolatedPieces.slice(0);
            const customFieldIsolatedPieceIndex = newReportTypeIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);
        
            if (customFieldIsolatedPieceIndex < 0) {
                newReportTypeIsolatedPieces.push(action.payload);
            } else {
                newReportTypeIsolatedPieces[customFieldIsolatedPieceIndex] = action.payload;
            }
        
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        isolatedPieces: newReportTypeIsolatedPieces,
                    }
                }
            }

        case REMOVE_ISOLATED_REPORT_TYPE_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        isolatedPieces: state.byId[action.reportTypeId].isolatedPieces.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                }
            }


        case REGISTER_REPORT_TYPE_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        variables: state.byId[action.reportTypeId].variables.concat([action.variableId]),
                    }
                }
            }
        
        default:
            return state;
    }
}