import React, { Component } from 'react';
import styles from './ReportModify.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import InputText from '../../../widgets/form/InputText';
import Button from '../../../widgets/form/Button';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../assets/cancel.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/main';
import { IUpdateableReportData } from '../../../shared/store/reports/types';

import { connect } from 'react-redux';
import uuid from 'uuid';
import moment from 'moment';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';

type OwnProps = {
    reportId?: string,
    isReadOnly?: boolean,

    submit: (reportData: IUpdateableReportData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const reportTypes = state.reports.types.allEntries.map(reportTypeId => state.reports.types.byId[reportTypeId]);

    return {
        report: ownProps.reportId ? state.reports.byId[ownProps.reportId] : undefined,
        reportTypes: reportTypes,
        
        usersData: state.users,
        myId: state.myData.id,
        
        memberData: state.members,
        groupsData: state.groups,
        groupTypesData: state.groups.types,

        reportsData: state.reports,
        reportTypesData: state.reports.types,

        applicationState: state,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;


type OwnState = {
    reportData: IUpdateableReportData,
    submitTimer: number|undefined,
    locationKey: number,
    errorMessage: string,
};

class ConnectedReportModify extends Component<Props, OwnState> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        let reportData: IUpdateableReportData;
        if (!props.report) {
            // This is a new report
            reportData = {
                id: uuid.v4(),
                name: '',
                type: '',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
                user: this.props.myId,
            };
        } else {
            reportData = {
                id: props.report.id,
                name: props.report.name,
                type: props.report.type,
                startDate: props.report.startDate,
                endDate: props.report.endDate,
                user: props.report.user,
            }
        }
        
        this.state = {
            reportData: reportData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: ''
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }
    
    changeType = (type: string) => {

        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            type: type,
            user: '',
        };
        
        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }
    
    changeName = (name: string) => {

        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            name: name,
        };
        
        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }
    
    changeStartDate = (startDate: string|undefined) => {
        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            startDate: startDate
        };
        
        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }
    
    changeEndDate = (endDate: string|undefined) => {
        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            endDate: endDate
        };
        
        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }
    
    changeUser = (user: string) => {
        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            user: user
        };
        
        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }
    
    showErrorMessage = (message: string) => {
        let that = this
        
        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 5000);
        
    }
    
    validateIUpdateableReportData = () => {
        
        if (!this.state.reportData.name) {
            this.showErrorMessage('Select a valid name');
            return;
        }
        
        if (!this.state.reportData.type) {
            this.showErrorMessage('Select a valid type');
            return;
        }
        
        if (!this.state.reportData.startDate) {
            this.showErrorMessage('Select a valid start date');
            return;
        }
        
        if (!this.state.reportData.endDate) {
            this.showErrorMessage('Select a valid end date');
            return;
        }
        
        if (!this.state.reportData.user) {
            this.showErrorMessage('Select a valid user');
            return;
        }
        
        return true
    }
    
    submitReportForm = () => {
        if (this.validateIUpdateableReportData()) {
            this.markForSubmit();
            
        }
    }
    
    markForSubmit = () => {
        let that = this;
        
        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.reportData);
        }, 1000);
        
        this.setState({
            submitTimer: timeout
        });
    }
    
    render() {

        const typesList = this.props.reportTypesData.allEntries.map(typeId => {
            return {
                name: this.props.reportTypesData.byId[typeId].name,
                value: typeId,
            };
        });

        let usersList: Array<{name: string, value: string}> = [];

        if (this.state.reportData.type) {
            const reportType = this.props.reportTypesData.byId[this.state.reportData.type];

            usersList = this.props.usersData.allEntries
            .filter(userId => {
                const userData = this.props.usersData.byId[userId];

                return userData.project === reportType.project;
            })
            .map(userId => {
                const user = this.props.usersData.byId[userId];
                let userName = user.customFields[this.props.usersData.nameFieldId];

                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, nameField, userId, 'user');

                return {
                    name: userName,
                    value: userId,
                };
            });
        }

        const currentUser = this.state.reportData.user ? usersList.find(userChoice => userChoice.value === this.state.reportData.user) : undefined;
        const currentUserName = typeof currentUser !== 'undefined' ? currentUser.name : undefined;
        
        return (
            <section className={this.props.isReadOnly ? styles.viewOnlyReport : styles.modifyReport}>
                <header>
                    <h2 className={styles.formHeading}>{this.props.isReadOnly ? 'View Report data' : 'Add Report'}</h2>
                    <button className={styles.cancelButton} onClick={this.props.cancel}><CancelIcon /> {this.props.isReadOnly ? 'Close' : 'Cancel'}</button>
                </header>
                <section className={styles.errorMessage}>{this.state.errorMessage}</section>
                <div className={styles.allInputsHolder}>
                    <div className={styles.inputSegment}>
                        <InputText placeholder="Report Type" icon={chevronIcon} default={this.props.report ? this.props.reportTypesData.byId[this.props.report.type].name : ''} options={typesList} onChange={this.changeType} />
                    </div>
                    {this.state.reportData.type && <div className={styles.inputSegment}>
                        <InputText placeholder="Report Name" icon={chevronIcon} default={this.props.report ? this.props.report.name : ''} onChange={this.changeName} />
                    </div>}
                    {this.state.reportData.type && <div className={styles.inputSegment} key={this.state.reportData.type}>
                        <InputText placeholder="User" icon={chevronIcon} default={currentUserName || ''} options={usersList} onChange={this.changeUser} />
                    </div>}
                    <div className={styles.inputSegment}>
                        <div className={styles.label}>Start Date</div>
                        <DatePicker selected={this.state.reportData.startDate ? new Date(this.state.reportData.startDate) : this.props.report && this.props.report.startDate ? new Date(this.props.report.startDate) : undefined} dateFormat="dd-MMM-yyyy" onChange={value => this.changeStartDate(value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} /> 
                    </div>
                    <div className={styles.inputSegment}>
                        <div className={styles.label}>End Date</div>
                        <DatePicker selected={this.state.reportData.endDate ? new Date(this.state.reportData.endDate) : this.props.report && this.props.report.endDate ? new Date(this.props.report.endDate) : undefined} dateFormat="dd-MMM-yyyy" onChange={value => this.changeEndDate(value ? `${value.getFullYear()}-${('0' + String(value.getMonth() + 1)).slice(-2)}-${('0' + String(value.getDate())).slice(-2)}` : undefined)} /> 
                    </div>
                    
                </div>
                    
                {!this.props.isReadOnly && <div className={styles.buttonHolder}>
                    {this.state.submitTimer ? <button className={styles.confirmFormButton}>{this.props.report ? translatePhrase('Updated Report') : translatePhrase('Added Report')}</button> : <Button text={this.props.report ? translatePhrase('Update Report') : translatePhrase('Add Report')} onClick={this.submitReportForm} />}
                </div>}
                
            </section>
        );
    }
}

const ReportModify = connect(mapStateToProps)(ConnectedReportModify);

export default ReportModify;