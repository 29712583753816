import { PiecePositionState } from "../../flowchart/pieces/types";
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_REPORT_TYPE = 'SELECT_REPORT_TYPE';
export const UN_SELECT_REPORT_TYPE = 'UN_SELECT_REPORT_TYPE';
export const RE_ORDER_REPORT_TYPES = 'RE_ORDER_REPORT_TYPES';

export const ADD_REPORT_TYPE = 'ADD_REPORT_TYPE';
export const UPDATE_REPORT_TYPE = 'UPDATE_REPORT_TYPE';
export const DELETE_REPORT_TYPE = 'DELETE_REPORT_TYPE';

export const UPDATE_REPORT_TYPE_DATA = 'UPDATE_REPORT_TYPE_DATA';

export const ADD_REPORT_TYPE_ROW = 'ADD_REPORT_TYPE_ROW';
export const ADD_REPORT_TYPE_COLUMN = 'ADD_REPORT_TYPE_COLUMN';

export const UPDATE_REPORT_TYPE_START_PIECE = 'UPDATE_REPORT_TYPE_START_PIECE';
export const SET_ISOLATED_REPORT_TYPE_PIECE = 'SET_ISOLATED_REPORT_TYPE_PIECE';
export const REMOVE_ISOLATED_REPORT_TYPE_PIECE = 'REMOVE_ISOLATED_REPORT_TYPE_PIECE';
export const REGISTER_REPORT_TYPE_VARIABLE = 'REGISTER_REPORT_TYPE_VARIABLE';

export interface INewReportTypeData {
    name: string,
    project: string,
    seedUserVariable: string,
    seedUsersVariable: string,
    seedMembersVariable: string,
    seedGroupsVariable: string,
    seedWorkflowsVariable: string,

    seedStartDateVariable: string,
    seedEndDateVariable: string,
}

export interface IUpdateableReportTypeData extends INewReportTypeData {
    id: string,
}

export interface CellState {
    id: string,
    rowIndex: number,
    columnIndex: number,
    rowSpan: number,
    columnSpan: number,
    content: string,
}

export interface IReportType extends IUpdateableReportTypeData, Synchronizable {
    archived?: boolean,
    createdTime: string,
    startPiece?: PiecePositionState,
    variables: Array<string>,
    isolatedPieces: Array<PiecePositionState>,

    noOfRows: number,
    noOfColumns: number,

    cells: {
        byId: {
            [cellId: string]: CellState,
        },
        allEntries: Array<string>,
        selected: Array<string>,
    }
}

export interface ReportTypeState extends NormalizedModel<IReportType> {
    selected: string|undefined,
}


interface SelectReportTypeAction {
    type: typeof SELECT_REPORT_TYPE,
    id: string,
}

interface UnSelectReportTypeAction {
    type: typeof UN_SELECT_REPORT_TYPE,
}

interface ReOrderReportTypesAction {
    type: typeof RE_ORDER_REPORT_TYPES,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddReportTypeAction {
    type: typeof ADD_REPORT_TYPE,
    payload: IReportType,
    currentTime: string,
}

export interface UpdateReportTypeAction {
    type: typeof UPDATE_REPORT_TYPE,
    payload: IUpdateableReportTypeData,
    currentTime: string,
}

export interface DeleteReportTypeAction {
    type: typeof DELETE_REPORT_TYPE,
    id: string,
    currentTime: string,
}

export interface IUpdateReportTypeData {
    type: typeof UPDATE_REPORT_TYPE_DATA,
    data: ReportTypeState,
}


export interface IAddReportTypeRowAction {
    type: typeof ADD_REPORT_TYPE_ROW,
    reportTypeId: string,
    newCells: Array<CellState>,
}

export interface IAddReportTypeColumnAction {
    type: typeof ADD_REPORT_TYPE_COLUMN,
    reportTypeId: string,
    newCells: Array<CellState>,
}


export interface UpdateReportTypeStartPieceAction {
    type: typeof UPDATE_REPORT_TYPE_START_PIECE,
    payload: PiecePositionState,
    reportTypeId: string,
}

export interface SetIsolatedReportTypePieceAction {
    type: typeof SET_ISOLATED_REPORT_TYPE_PIECE,
    payload: PiecePositionState,
    reportTypeId: string,
}

export interface RemoveIsolatedReportTypePieceAction {
    type: typeof REMOVE_ISOLATED_REPORT_TYPE_PIECE,
    pieceId: string,
    reportTypeId: string,
}

export interface RegisterReportTypeVariableAction {
    type: typeof REGISTER_REPORT_TYPE_VARIABLE,
    variableId: string,
    reportTypeId: string,
}

export type ReportTypeActionTypes = SelectReportTypeAction | UnSelectReportTypeAction | ReOrderReportTypesAction |AddReportTypeAction | UpdateReportTypeAction | DeleteReportTypeAction | IUpdateReportTypeData | IAddReportTypeRowAction | IAddReportTypeColumnAction | UpdateReportTypeStartPieceAction | SetIsolatedReportTypePieceAction | RemoveIsolatedReportTypePieceAction | RegisterReportTypeVariableAction;