import React, { Component } from 'react';
import Input from '../Input';
import styles from './SplitPiece.module.scss';
import stepStyles from './step-piece/StepPiece.module.scss';
import containerStyles from './container-piece/ContainerPiece.module.scss';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { Position } from '../../../shared/helpers/common-types';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setNextPiece, setTargetPiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';

import { setConditionPosition, setConditionPieces } from '../../../shared/store/flowchart/pieces/actions';
import { IfData, PieceType } from '../../../shared/store/flowchart/pieces/types';
import { nextPieceTarget, booleanPieceSlotTarget } from './utilities';

export type IfPieceData = {
    position: Position,
    conditionPiece?: JSX.Element,
    nextPiece?: JSX.Element,
}

type SplitPieceProps = {
    pieceId: string,
    initialIfPieces?: Array<Partial<IfPieceData>>,
    nextPiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState, ownProps: SplitPieceProps) => {
    const piece = state.flowchart.pieces.byId[ownProps.pieceId];

    if (piece.type !== PieceType.SPLIT) {
        throw new Error('A split can only be created from a split piece type');
    }

    return {
        ifData: piece.ifPieceData,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setConditionPosition: (pieceId: string, ifIndex: number, x: number, y: number) => dispatch(setConditionPosition(pieceId, ifIndex, x, y)),
        setConditionPieces: (pieceId: string, value: Array<IfData>) => dispatch(setConditionPieces(pieceId, value)),
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = SplitPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type SplitPieceState = {
    ifPositions: Array<Position>,
    isHoveringOverNextPiece: boolean,
    isHoveringOverConditionPiece: boolean,
    hoveringIfPieceIndex: number|undefined,

    ifKey: number,
    isExpanded: boolean,
}

const FIRST_IF_PIECE_OFFSET = 200;

class ConnectedSplitPiece extends Component<Props, SplitPieceState> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            ifPositions: this.getCompleteIfPieceData().map(ifPiece => {
                return {
                    x: ifPiece.position.x,
                    y: ifPiece.position.y,
                };
            }),
            isHoveringOverNextPiece: false,
            isHoveringOverConditionPiece: false,
            hoveringIfPieceIndex: undefined,
            ifKey: 1,
            isExpanded: false,
        };
    }

    toggleExpansion = () => {
        this.setState(prevState => {
            return {
                isExpanded: !prevState.isExpanded,
            };
        });
    }

    handleHoverOverNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: true,
            hoveringIfPieceIndex: undefined,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOverNextIfPiece = (ifPieceIndex: number) => {
        this.setState({
            isHoveringOverNextPiece: true,
            hoveringIfPieceIndex: ifPieceIndex,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: false,
            hoveringIfPieceIndex: undefined,
        });
    };

    handleHoverOverConditionPiece = (ifPieceIndex: number) => {
        this.setState({
            isHoveringOverConditionPiece: true,
            hoveringIfPieceIndex: ifPieceIndex,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfConditionPiece = () => {
        this.setState({
            isHoveringOverConditionPiece: false,
            hoveringIfPieceIndex: undefined,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id) {

            if (typeof this.state.hoveringIfPieceIndex === 'undefined') {
                if (this.state.isHoveringOverNextPiece) {
                    this.props.setNextPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
                    this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);
        
                    this.setState({
                        isHoveringOverNextPiece: false,
                    });
                }
            } else {
                const newIfData = this.props.ifData || [];
        
                newIfData[this.state.hoveringIfPieceIndex] = {
                    ...newIfData[this.state.hoveringIfPieceIndex],
                };

                if (this.state.isHoveringOverNextPiece) {
                    newIfData[this.state.hoveringIfPieceIndex].nextPiece = this.props.lastDraggedPiece.id;
                } else if (this.state.isHoveringOverConditionPiece) {
                    newIfData[this.state.hoveringIfPieceIndex].conditionPiece = this.props.lastDraggedPiece.id;
                }

                this.props.setConditionPieces(this.props.pieceId, newIfData);
                this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);
    
                this.setState({
                    isHoveringOverNextPiece: false,
                    isHoveringOverConditionPiece: false,
                    hoveringIfPieceIndex: undefined,
                });

            }

        }
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<SplitPieceState>) {
        if (props.ifData && props.ifData.length !== state.ifPositions.length) {
            return {
                ifPositions: props.ifData ? props.ifData.map((ifDatum, i) => {
                    return {
                        x: ifDatum.position ? ifDatum.position.x : FIRST_IF_PIECE_OFFSET + (i * 300),
                        y: ifDatum.position ? ifDatum.position.y : 50,
                    };
                }) : [],
                ifKey: state.ifKey + 1,
            };
        }

        return null;
    }

    handleDragStart = (e: DraggableEvent) => {
        e.stopPropagation();
    }

    getCompleteIfPieceData = () => {
        const noOfIfPieces = this.props.initialIfPieces ? this.props.initialIfPieces.length : 2;
        const ifPieceData: Array<IfPieceData> = [];

        for (let i = 0; i < noOfIfPieces; i += 1) {
            if (this.props.initialIfPieces && this.props.initialIfPieces[i]) {
                const position = this.props.initialIfPieces[i].position;
                ifPieceData.push({
                    ...this.props.initialIfPieces[i],
                    position: {
                        x: position ? position.x : FIRST_IF_PIECE_OFFSET + (i * 300),
                        y: position ? position.y : 50,
                    }
                });
            } else {
                ifPieceData.push({
                    conditionPiece: undefined,
                    nextPiece: undefined,
                    position: {
                        x: FIRST_IF_PIECE_OFFSET + (i * 300),
                        y: 50,
                    }
                });
            }
        }

        return ifPieceData;
    }

    handleIfPieceDrag = (ifPieceIndex: number, e: DraggableEvent, data: DraggableData) => {
        const newIfPositions = this.state.ifPositions.slice(0);

        newIfPositions[ifPieceIndex] = {
            x: data.x,
            y: data.y,
        };

        this.setState({
            ifPositions: newIfPositions,
        });
    }

    handleIfPieceDragEnd = (ifPieceIndex: number, e: DraggableEvent, data: DraggableData) => {
        this.props.setConditionPosition(this.props.pieceId, ifPieceIndex, data.x, data.y);
        e.stopPropagation();
    }

    handleChange = (value: string) => {
        if (!isNaN(Number(value))) {
            const newNoOfPieces = Number(value);
            let newIfData = this.props.ifData || [];
            let newIfPositions = this.state.ifPositions.slice(0);

            if (newNoOfPieces > newIfData.length) {
                for (let i = newIfData.length; i < newNoOfPieces; i += 1) {
                    newIfData.push({
                        conditionPiece: undefined,
                        nextPiece: undefined,
                        position: {
                            x: FIRST_IF_PIECE_OFFSET + (i * 300),
                            y: 50,
                        }
                    });
                    // newIfPositions.push({
                    //     x: FIRST_IF_PIECE_OFFSET + (i * 300),
                    //     y: 50,
                    // });
                }
            } else if (newNoOfPieces < newIfData.length) {
                newIfData = newIfData.slice(0, newNoOfPieces);
                // newIfPositions = newIfPositions.slice(0, newNoOfPieces);
            }

            this.setState({
                ifPositions: newIfPositions,
            });

            this.props.setConditionPieces(this.props.pieceId, newIfData);
        }
    }

    render() {
        const ifPieces: Array<JSX.Element> = [];
        const ifPieceData = this.getCompleteIfPieceData();
        const noOfPieces = Math.min(ifPieceData.length, this.state.ifPositions.length);
        for(let i = 0; i < noOfPieces; i += 1) {
            const ifPieceDatum = ifPieceData[i];
            const lineHeight = this.state.ifPositions[i].y - 30;
            const xOffset = (i * 300) + FIRST_IF_PIECE_OFFSET;

            const ifPiece = <Draggable key={i} cancel={'input'} disabled={this.props.isDragDisabled} onStart={this.handleDragStart} onDrag={this.handleIfPieceDrag.bind(this, i)} defaultPosition={{x: xOffset, y: 50}} onStop={this.handleIfPieceDragEnd.bind(this, i)}>
                <div className={styles.ifPiece}>
                    <div className={styles.ifLineDrop} style={{top: -lineHeight, height: lineHeight}}><div className={styles.ifPieceCircle}></div></div>
                    <section className={stepStyles.aquaStepPiece}>
                        <div className={stepStyles.filledUpperPieceLock}></div>
                        <div className={stepStyles.spaceBetweenLocks}></div>
                        <div className={stepStyles.lowerPieceLock}></div>

                        <div className={stepStyles.extendableUpperArm}></div>

                        <div className={stepStyles.visibleItems}>
                            <div className={stepStyles.text}>if</div>
                            {ifPieceDatum.conditionPiece || <div className={(this.state.isHoveringOverConditionPiece && typeof this.state.hoveringIfPieceIndex !== 'undefined' && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverConditionPiece.bind(this, i)} onMouseOut={this.handleHoverOutOfConditionPiece}></div>}
                            <div className={stepStyles.text}>then</div>
                        </div>
                        {!ifPieceDatum.nextPiece && <div className={this.state.isHoveringOverNextPiece && typeof this.state.hoveringIfPieceIndex !== 'undefined' && this.props.isDragging && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable} onMouseOver={this.handleHoverOverNextIfPiece.bind(this, i)} onMouseOut={this.handleHoverOutOfNextPiece}></div>}
                    </section>
                    {ifPieceDatum.nextPiece}
                </div>
            </Draggable>;
            ifPieces.push(ifPiece);
        }

        const rightMostPosition = Math.max(...this.state.ifPositions.map(ifPosition => ifPosition.x));

        const horizontalLeftValue = 60;
        const horizontalWidthValue = rightMostPosition + 5;
        
        return (
            <div className={styles.splitPieceComponentsHolder}>
                <FlowchartPiece {...this.props}>
                    <div className={containerStyles.forPiece}>
                        <section className={containerStyles.upperArm}>
                            <div className={containerStyles.upperArmUpperPieceLock}></div>
                            <div className={containerStyles.spaceBetweenLocks}></div>
        
                            <div className={containerStyles.extendableUpperArm}></div>
        
                            <div className={containerStyles.upperArmContent}>
                                <div className={containerStyles.text}>Split</div>
                                <Input type="number" defaultText={this.props.initialIfPieces ? String(this.props.initialIfPieces.length) : '2'} onChange={this.handleChange} />
                            </div>
                        </section>
                        {this.state.isExpanded && <div className={styles.splitPieceLines}>
                            <div className={styles.splitLineDrop}><div className={styles.splitPieceCircle}></div></div>
                            <div className={styles.splitLineHorizontal} style={{left: horizontalLeftValue, width: horizontalWidthValue}}></div>
                        </div>}
                        <div className={containerStyles.innards}>
                            <section className={containerStyles.leftArm}></section>
                            <section className={containerStyles.innerContent}>
                                {this.state.isExpanded && <div className={styles.ifPiecesHolder} key={this.state.ifKey}>{ifPieces}</div>}
                            </section>
                        </div>
                        <section className={containerStyles.lowerArm}>
                            <div className={containerStyles.lowerArmUpperPieceLock + ' ' + styles.lowerArmUpperPieceLock}></div>
                            <div className={containerStyles.spaceBetweenLocks}></div>
                            <div className={containerStyles.lowerArmLowerPieceLock}></div>
        
                            <div className={containerStyles.extendableLowerArm}></div>
        
                            <div className={containerStyles.lowerArmContent}>
                                <section className={styles.expandButton} onClick={this.toggleExpansion}>{this.state.isExpanded ? 'Collapse' : 'Expand'}</section>
                            </div>
                            {!this.props.nextPiece && <div className={(this.state.isHoveringOverNextPiece && this.props.isDragging && typeof this.state.hoveringIfPieceIndex === 'undefined' && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable) + ' attachment-target'} onMouseOver={this.handleHoverOverNextPiece} onMouseOut={this.handleHoverOutOfNextPiece}></div>}
                        </section>
                        <div className={containerStyles.nextPiece}>{ this.props.nextPiece || undefined }</div>
                    </div>
                </FlowchartPiece>
            </div>
        )
    }
}

const SplitPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSplitPiece);

export default SplitPiece;