import React, { Component } from 'react';
import StepPiece from './step-piece/StepPiece'
import styles from './step-piece/StepPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setUpdateStatusValue } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';


type UpdateStatusPieceProps = {
    nextPiece?: JSX.Element,
    states: Array<Option>,
    updateState?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setUpdateStatusValue: (pieceId: string, value: string) => dispatch(setUpdateStatusValue(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = UpdateStatusPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type UpdateStatusPieceState = {
}

class ConnectedUpdateStatusPiece extends Component<Props> {
    render() {
        const statesMarkup = this.props.states.map(state => <ListItem key={state.name} theme="aqua" name={state.name} value={state.value} onClick={this.props.setUpdateStatusValue.bind(this, this.props.pieceId)} />);
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.text}>Update status</div>
                <SelectBox theme="dark-aqua" selectionPromptText={this.props.updateState ? this.props.updateState : "Select a status"}>
                    <DropDownList theme="aqua">
                        {statesMarkup}
                    </DropDownList>
                </SelectBox>
            </StepPiece>
        )
    }
}

const UpdateStatusPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdateStatusPiece);

export default UpdateStatusPiece;