import React from 'react';
import styles from './Card.module.scss';
import TriToggle, { TriToggleOptions } from '../form/TriToggle';

import { CardType } from './Card';

import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {
    card: CardType,
    toggleState: TriToggleOptions,
    isActive: boolean,

    negativeText?: string,
    middleText?: string,
    positiveText?: string,

    isReadOnly: boolean,

    onToggle: (id: string, toggleState: TriToggleOptions) => void
};

export type TriToggleCardType = {
    id: string,
    name: string,
    toggleState: TriToggleOptions,

    onToggle: (id: string, toggleState: TriToggleOptions) => void,
}

const ToggleCard: React.FC<OwnProps> = (props) => {

    let toggleLegend: string;

    switch(props.toggleState) {
        case 'positive':
            toggleLegend = props.positiveText || '+';
            break;
        case 'middle':
            toggleLegend = props.middleText || 'O';
            break;
        case 'negative':
            toggleLegend = props.negativeText || '-';
            break;
        default:
            throw new Error('No other states for toggle');
    }
    
    return (
        <li className={props.isActive ? styles.active : styles.normal}>
            <div className={styles.details}> 
                <div className={styles.title}>{translatePhrase(props.card.name)}</div> 
            </div>
            <div className={styles.triToggleHolder}>
                <div className={styles.legend}>{toggleLegend}</div>
                <TriToggle toggleState={props.toggleState} onToggle={(id, state) => !props.isReadOnly && props.onToggle(props.card.id, state)} toggleId={props.card.id} />
            </div>
        </li>
    );
}

export default ToggleCard;