import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { INewMemberTypeData, IUpdateableMemberTypeData } from '../../../shared/store/members/types/types';
import { reOrderMemberTypes, addMemberType, updateMemberType, deleteMemberType } from '../../../shared/store/members/types/actions';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState 
} from './CardTreeVertical';
import { CardType } from '../../../widgets/card/Card';
import { Permissions } from '../../../shared/store/permissions/types';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewMemberTypeData) => void,
    updateCard: (payload: IUpdateableMemberTypeData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const generalMemberTypePermission = state.permissions.myPermissions.general.MembersConfiguration;

    const allMemberTypes: Array<CardType> = state.members.types.allEntries
    .filter(memberTypeId => {
        const specificPermission = state.permissions.myPermissions.members[memberTypeId];
        const permission = typeof specificPermission === 'undefined' ? generalMemberTypePermission : specificPermission;

        return permission !== Permissions.NONE;
    })
    .map(memberTypeId => {
        const memberType = state.members.types.byId[memberTypeId];
        const specificPermission = state.permissions.myPermissions.members[memberTypeId];

        return {
            id: memberTypeId,
            name: memberType.name,
            details: state.structure.projects.byId[memberType.project].name,
            isReadOnly: typeof specificPermission !== 'undefined' && specificPermission === Permissions.READ,
        };
    });

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        projectsData: state.structure.projects,
        cardsList: allMemberTypes,
        selectedCard: ownProps.selectedId ? state.members.types.byId[ownProps.selectedId] : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderMemberTypes(sourceIndex, destinationIndex)),
        addCard: (payload: INewMemberTypeData) => dispatch(addMemberType(payload)),
        deleteCard: (id: string) => dispatch(deleteMemberType(id)),
        updateCard: (payload: IUpdateableMemberTypeData) => dispatch(updateMemberType(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedMemberTypesList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
        modifyingCardProject: '',
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const MemberTypesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedMemberTypesList);

export default MemberTypesList;