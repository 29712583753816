import { CustomFieldState, IUpdateableFieldChoiceData, IUpdateableCustomFieldData } from '../../custom-fields';
import { PiecePositionState } from '../../flowchart/pieces/types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_MEMBER_TYPE = 'SELECT_MEMBER_TYPE';
export const UN_SELECT_MEMBER_TYPE = 'UN_SELECT_MEMBER_TYPE';
export const RE_ORDER_MEMBER_TYPES = 'RE_ORDER_MEMBER_TYPES';
export const ADD_MEMBER_TYPE_REQUEST = 'ADD_MEMBER_TYPE_REQUEST';
export const ADD_MEMBER_TYPE = 'ADD_MEMBER_TYPE';
export const DELETE_MEMBER_TYPE = 'DELETE_MEMBER_TYPE';
export const UPDATE_MEMBER_TYPE = 'UPDATE_MEMBER_TYPE';

export const ADD_MEMBER_TO_MEMBER_TYPE = 'ADD_MEMBER_TO_MEMBER_TYPE';
export const REMOVE_MEMBER_FROM_MEMBER_TYPE = 'REMOVE_MEMBER_FROM_MEMBER_TYPE';
export const SELECT_MEMBER_TYPE_CUSTOM_FIELD = 'SELECT_MEMBER_TYPE_CUSTOM_FIELD';
export const UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD = 'UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD';
export const ADD_MEMBER_TYPE_CUSTOM_FIELD = 'ADD_MEMBER_TYPE_CUSTOM_FIELD';
export const DELETE_MEMBER_TYPE_CUSTOM_FIELD = 'DELETE_MEMBER_TYPE_CUSTOM_FIELD';
export const UPDATE_MEMBER_TYPE_CUSTOM_FIELD = 'UPDATE_MEMBER_TYPE_CUSTOM_FIELD';

export const UPDATE_MEMBER_TYPE_CUSTOM_FIELD_START_PIECE = 'UPDATE_MEMBER_TYPE_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE';
export const REGISTER_MEMBER_TYPE_CUSTOM_FIELD_VARIABLE = 'REGISTER_MEMBER_TYPE_CUSTOM_FIELD_VARIABLE';

export const SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION = 'SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION';
export const UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION = 'UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION';
export const RE_ORDER_MEMBER_TYPE_CUSTOM_FIELD_OPTION = 'RE_ORDER_MEMBER_TYPE_CUSTOM_FIELD_OPTION';
export const ADD_MEMBER_TYPE_CUSTOM_FIELD_OPTION = 'ADD_MEMBER_TYPE_CUSTOM_FIELD_OPTION';
export const DELETE_MEMBER_TYPE_CUSTOM_FIELD_OPTION = 'DELETE_MEMBER_TYPE_CUSTOM_FIELD_OPTION';
export const UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION = 'UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION';

export const ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_ADD = 'ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_ADD';
export const ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_UPDATE = 'ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_UPDATE';
export const UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_ADD = 'UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_ADD';
export const UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE = 'UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE';
export const DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_ADD = 'DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_ADD';
export const DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE = 'DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE';

export interface INewMemberTypeData {
    name: string;
    project: string;

    nameFieldId: string;
    subTitleFieldId: string;
    locationFieldId: string;
}

export interface IUpdateableMemberTypeData extends INewMemberTypeData {
    id: string;
}

export interface IMemberType extends IUpdateableMemberTypeData, Synchronizable {
    archived?: boolean,

    addManagement?: {
        workflowTypeId: string,
        lastQuestionPiece: string,
        lastStorePiece: string,
        customFieldMap: {
            [memberTypeCustomFieldId: string]: string,  // ID of the custom field in the managed workflow
        },
    },
    updateManagement?: {
        workflowTypeId: string,
        lastQuestionPiece: string,
        lastStorePiece: string,
        customFieldMap: {
            [memberTypeCustomFieldId: string]: string,  // ID of the custom field in the managed workflow
        },
    },

    members: Array<string>,
    customFields: Array<string>,
}


export interface MemberTypeState extends NormalizedModel<IMemberType>, CustomFieldState {
    selected: string|undefined,

    reverseLinks: {
        [memberTypeId: string]: {
            members: {
                created: Array<string>,
                deleted: Array<string>,
            },
        }
    }
}

export interface SelectMemberTypeAction {
    type: typeof SELECT_MEMBER_TYPE,
    id: string,
}

export interface UnSelectMemberTypeAction {
    type: typeof UN_SELECT_MEMBER_TYPE,
}

export interface ReOrderMemberTypesAction {
    type: typeof RE_ORDER_MEMBER_TYPES,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddMemberTypeRequestAction {
    type: typeof ADD_MEMBER_TYPE_REQUEST,
    payload: INewMemberTypeData,
}

export interface AddMemberTypeAction {
    type: typeof ADD_MEMBER_TYPE,
    payload: IMemberType,
}

export interface DeleteMemberTypeAction {
    type: typeof DELETE_MEMBER_TYPE,
    id: string,
}

export interface UpdateMemberTypeAction {
    type: typeof UPDATE_MEMBER_TYPE,
    payload: IUpdateableMemberTypeData,
}

export interface AddMemberToMemberTypeAction {
    type: typeof ADD_MEMBER_TO_MEMBER_TYPE,
    memberTypeId: string,
    memberId: string,
}

export interface RemoveMemberFromMemberTypeAction {
    type: typeof REMOVE_MEMBER_FROM_MEMBER_TYPE,
    memberTypeId: string,
    memberId: string,
}


export interface SelectMemberTypeCustomFieldAction {
    type: typeof SELECT_MEMBER_TYPE_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectMemberTypeCustomFieldAction {
    type: typeof UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD,
}

export interface AddMemberTypeCustomFieldAction {
    type: typeof ADD_MEMBER_TYPE_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    memberTypeId: string,
}

export interface DeleteMemberTypeCustomFieldAction {
    type: typeof DELETE_MEMBER_TYPE_CUSTOM_FIELD,
    id: string,
    memberTypeId: string,
}

export interface UpdateMemberTypeCustomFieldAction {
    type: typeof UPDATE_MEMBER_TYPE_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
}


export interface UpdateMemberTypeCustomFieldStartPieceAction {
    type: typeof UPDATE_MEMBER_TYPE_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface SetIsolatedMemberTypeCustomFieldPieceAction {
    type: typeof SET_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface RemoveIsolatedMemberTypeCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
}

export interface RegisterMemberTypeCustomFieldVariableAction {
    type: typeof REGISTER_MEMBER_TYPE_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
}


export interface SelectMemberTypeCustomFieldOptionAction {
    type: typeof SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectMemberTypeCustomFieldOptionAction {
    type: typeof UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION,
}

export interface ReOrderMemberTypeCustomFieldOptionAction {
    type: typeof RE_ORDER_MEMBER_TYPE_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddMemberTypeCustomFieldOptionAction {
    type: typeof ADD_MEMBER_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
}

export interface DeleteMemberTypeCustomFieldOptionAction {
    type: typeof DELETE_MEMBER_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
}

export interface UpdateMemberTypeCustomFieldOptionAction {
    type: typeof UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
}

export interface AddMemberTypeManagementCustomFieldMapForAddAction {
    type: typeof ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_ADD,
    memberTypeId: string,
    memberTypeCustomFieldId: string,
    workflowTypeCustomFieldId: string,
}

export interface AddMemberTypeManagementCustomFieldMapForUpdateAction {
    type: typeof ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_UPDATE,
    memberTypeId: string,
    memberTypeCustomFieldId: string,
    workflowTypeCustomFieldId: string,
}

export interface UpdateMemberTypeManagementForAddAction {
    type: typeof UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_ADD,
    memberTypeId: string,
    workflowTypeId: string,
    lastQuestionPieceId: string,
    lastStorePieceId: string,
}

export interface DiscardMemberTypeManagementForAddAction {
    type: typeof DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_ADD,
    memberTypeId: string,
}

export interface UpdateMemberTypeManagementForUpdateAction {
    type: typeof UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE,
    memberTypeId: string,
    workflowTypeId: string,
    lastQuestionPieceId: string,
    lastStorePieceId: string,
}

export interface DiscardMemberTypeManagementForUpdateAction {
    type: typeof DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE,
    memberTypeId: string,
}

type CustomFieldActions = SelectMemberTypeCustomFieldAction | UnSelectMemberTypeCustomFieldAction | AddMemberTypeCustomFieldAction | DeleteMemberTypeCustomFieldAction | UpdateMemberTypeCustomFieldAction;

type CustomFieldChoiceActions = SelectMemberTypeCustomFieldOptionAction | UnSelectMemberTypeCustomFieldOptionAction | ReOrderMemberTypeCustomFieldOptionAction | AddMemberTypeCustomFieldOptionAction | DeleteMemberTypeCustomFieldOptionAction | UpdateMemberTypeCustomFieldOptionAction;

export type MemberTypeActionTypes = SelectMemberTypeAction | UnSelectMemberTypeAction | ReOrderMemberTypesAction | AddMemberTypeRequestAction | AddMemberTypeAction | DeleteMemberTypeAction | UpdateMemberTypeAction | AddMemberToMemberTypeAction | RemoveMemberFromMemberTypeAction | UpdateMemberTypeCustomFieldStartPieceAction | SetIsolatedMemberTypeCustomFieldPieceAction | RemoveIsolatedMemberTypeCustomFieldPieceAction | RegisterMemberTypeCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions | AddMemberTypeManagementCustomFieldMapForAddAction | AddMemberTypeManagementCustomFieldMapForUpdateAction | UpdateMemberTypeManagementForAddAction | DiscardMemberTypeManagementForAddAction | UpdateMemberTypeManagementForUpdateAction | DiscardMemberTypeManagementForUpdateAction;