import React, { Component } from 'react';
import StepPiece from './step-piece/StepPiece'
import styles from './step-piece/StepPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTransferRoles } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { isUUID } from '../../../shared/helpers/utilities';


type TransferPieceProps = {
    pieceId: string,
    projectId: string,
    nextPiece?: JSX.Element,
    selectedRoles?: Array<string>,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: TransferPieceProps) => {

    return {
        setTransferRoles: (roles: Array<string>) => dispatch(setTransferRoles(ownProps.pieceId, roles)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = TransferPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type TransferPieceState = {
}

class ConnectedTransferPiece extends Component<Props> {

    updateSelectedRoles = (option: string) => {
        let selectedRoles = this.props.selectedRoles ? this.props.selectedRoles.slice(0) : [];

        if (selectedRoles.includes(option)) {
            selectedRoles = selectedRoles.filter(selectedOption => option !== selectedOption);
        } else {
            selectedRoles.push(option);
        }

        this.props.setTransferRoles(selectedRoles);
    }

    render() {
        const rolesMarkup = this.props.rolesData.allEntries
        .filter(roleId => this.props.levelsData.byId[this.props.rolesData.byId[roleId].level].project === this.props.projectId)
        .map(roleId => <ListItem key={roleId} theme="aqua" name={this.props.rolesData.byId[roleId].name} value={roleId} onClick={this.updateSelectedRoles} />);

        const rolesText = this.props.selectedRoles && this.props.selectedRoles.length > 0 ? this.props.selectedRoles.map(roleId => isUUID(roleId) ? this.props.rolesData.byId[roleId].name : 'Previous owners').join(', ') : 'Select 1 or more roles';
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.text}>Transfer to</div>
                <SelectBox theme="dark-aqua" selectionPromptText={rolesText}>
                    <DropDownList theme="aqua" dismissAfterSelection={false}>
                        {rolesMarkup}
                        <ListItem key="previous" theme="aqua" name="Previous owners" value="previous" onClick={this.updateSelectedRoles} />
                    </DropDownList>
                </SelectBox>
            </StepPiece>
        )
    }
}

const TransferPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedTransferPiece);

export default TransferPiece;