import { WorkflowTypeState, WorkflowTypeActionTypes } from './types/types';
import { StatusActions } from './types/statuses/types';
import { VariableActionTypes } from '../flowchart/variables/types';
import { CustomFieldValueType } from '../custom-fields';
import { FlowchartProcessState } from '../flowchart/types';

import { NormalizedModel, Synchronizable } from '../normalized-model';

export const ADD_WORKFLOW = 'ADD_WORKFLOW';
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';

export const TRANSFER_WORKFLOW = 'TRANSFER_WORKFLOW';

export const SEARCH_WORKFLOW_TABLE = 'SEARCH_WORKFLOW_TABLE';
export const FILTER_WORKFLOW_TABLE = 'FILTER_WORKFLOW_TABLE';
export const GO_TO_PAGE_WORKFLOW_TABLE = 'GO_TO_PAGE_WORKFLOW_TABLE';
export const SET_PAGE_SIZE_WORKFLOW_TABLE = 'SET_PAGE_SIZE_WORKFLOW_TABLE';
export const SORT_WORKFLOW_TABLE = 'SORT_WORKFLOW_TABLE';

export const UPDATE_WORKFLOW_PROCESS_STATE = 'UPDATE_WORKFLOW_PROCESS_STATE';
export const ADD_TO_WORKFLOW_HISTORY = 'ADD_TO_WORKFLOW_HISTORY';
export const RESTRICT_WORKFLOW_NAVIGATION = 'RESTRICT_WORKFLOW_NAVIGATION';
export const WORKFLOW_NAVIGATE_BACK = 'WORKFLOW_NAVIGATE_BACK';
export const WORKFLOW_NAVIGATE_FORWARD = 'WORKFLOW_NAVIGATE_FORWARD';

export const UPDATE_WORKFLOW_STATUS = 'UPDATE_WORKFLOW_STATUS';
export const UPDATE_WORKFLOW_DUE_DATE = 'UPDATE_WORKFLOW_DUE_DATE';

export const UPDATE_WORKFLOW_DATA = 'UPDATE_WORKFLOW_DATA';

export type CustomFieldDataForIndividualMembers = {
    [memberId: string]: CustomFieldValueType
};


export interface WorkflowProcessState extends FlowchartProcessState {
    customFields: {
        [customFieldId: string]: CustomFieldValueType|CustomFieldDataForIndividualMembers,
    },
}


export interface INewWorkflowData {
    type: string,
    status: string,
    dueDate: string|undefined,

    user: string,
    affiliatedEntity: string,

    triggeringWorkflow?: string,

}

export interface IUpdateableWorkflowData extends INewWorkflowData {
    id: string,
}

export interface IWorkflow extends IUpdateableWorkflowData, Synchronizable {
    archived?: boolean,
    createdTime: string,
    trackingUsers: Array<string>,  // Set of user IDs of all the users who can read this workflow
    history: Array<WorkflowProcessState>,
    historyIndex: number,
    restrictedHistoryIndex?: number,
    lastUpdateTimeForFields: {
        [customFieldId: string] : string,
    }
}

export interface WorkflowState extends NormalizedModel<IWorkflow> {
    types: WorkflowTypeState,

    pageSize: number,
    currentPageNumber: number,
    filters: {
        dues: Array<string>,
        types: Array<string>,
        statuses: Array<string>,
        users: Array<string>,
        affiliations: Array<string>,
        customFields: {
            [fieldId: string]: Array<string>,
        }
    },
    sort: {
        column: string|undefined,
        order: 'ASC'|'DESC',
    },
    searchTerm: string|undefined,
}

export interface AddWorkflowAction {
    type: typeof ADD_WORKFLOW,
    payload: IWorkflow,
}

export interface UpdateWorkflowAction {
    type: typeof UPDATE_WORKFLOW,
    payload: IUpdateableWorkflowData,
}

export interface DeleteWorkflowAction {
    type: typeof DELETE_WORKFLOW,
    id: string,
}


export interface SearchWorkflowTableAction {
    type: typeof SEARCH_WORKFLOW_TABLE,
    searchTerm: string,
}

export interface FilterWorkflowTableAction {
    type: typeof FILTER_WORKFLOW_TABLE,
    dues: Array<string>,
    types: Array<string>,
    statuses: Array<string>,
    users: Array<string>,
    affiliations: Array<string>,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
}

export interface GoToPageWorkflowTableAction {
    type: typeof GO_TO_PAGE_WORKFLOW_TABLE,
    pageNumber: number,
}

export interface SetPageSizeWorkflowTableAction {
    type: typeof SET_PAGE_SIZE_WORKFLOW_TABLE,
    pageSize: number,
}

export interface SortWorkflowTableAction {
    type: typeof SORT_WORKFLOW_TABLE,
    column: string,
    order: 'ASC'|'DESC'
}


export interface UpdateWorkflowProcessStateAction {
    type: typeof UPDATE_WORKFLOW_PROCESS_STATE,
    processState: WorkflowProcessState,
    workflowId: string,
    updateTime: string,
}

export interface AddToWorkflowHistory {
    type: typeof ADD_TO_WORKFLOW_HISTORY,
    workflowId: string,
    processState: WorkflowProcessState,
    updateTime: string,
}

export interface RestrictWorkflowNavigationAction {
    type: typeof RESTRICT_WORKFLOW_NAVIGATION,
    workflowId: string,
}

export interface WorkflowNavigateForwardAction {
    type: typeof WORKFLOW_NAVIGATE_FORWARD,
    workflowId: string,
}

export interface WorkflowNavigateBackAction {
    type: typeof WORKFLOW_NAVIGATE_BACK,
    workflowId: string,
}

export interface UpdateWorkflowStatusAction {
    type: typeof UPDATE_WORKFLOW_STATUS,
    workflowId: string,
    statusId: string,
}

export interface UpdateWorkflowDueDateAction {
    type: typeof UPDATE_WORKFLOW_DUE_DATE,
    workflowId: string,
    dueDate: string,
}

export interface TransferWorkflowAction {
    type: typeof TRANSFER_WORKFLOW,
    workflowId: string,
    user: string,
}

export interface IUpdateWorkflowData {
    type: typeof UPDATE_WORKFLOW_DATA,
    data: WorkflowState,
}


type WorkflowActions = AddWorkflowAction | UpdateWorkflowAction | DeleteWorkflowAction;

type WorkflowTableActions = SearchWorkflowTableAction | FilterWorkflowTableAction | GoToPageWorkflowTableAction | SetPageSizeWorkflowTableAction | SortWorkflowTableAction;

type ExecutionActions = UpdateWorkflowProcessStateAction | UpdateWorkflowStatusAction | UpdateWorkflowDueDateAction | TransferWorkflowAction | AddToWorkflowHistory | RestrictWorkflowNavigationAction | WorkflowNavigateForwardAction | WorkflowNavigateBackAction;

export type WorkflowActionTypes = WorkflowActions | WorkflowTableActions | ExecutionActions | WorkflowTypeActionTypes | StatusActions | VariableActionTypes | IUpdateWorkflowData;