import { CustomFieldState, IUpdateableCustomFieldData, IUpdateableFieldChoiceData } from '../../custom-fields';
import { PiecePositionState } from '../../flowchart/pieces/types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_GROUP_TYPE = 'SELECT_GROUP_TYPE';
export const UN_SELECT_GROUP_TYPE = 'UN_SELECT_GROUP_TYPE';
export const RE_ORDER_GROUP_TYPES = 'RE_ORDER_GROUP_TYPES';
export const ADD_GROUP_TYPE_REQUEST = 'ADD_GROUP_TYPE_REQUEST';
export const ADD_GROUP_TYPE = 'ADD_GROUP_TYPE';
export const DELETE_GROUP_TYPE = 'DELETE_GROUP_TYPE';
export const UPDATE_GROUP_TYPE = 'UPDATE_GROUP_TYPE';

export const ADD_GROUP_TO_GROUP_TYPE = 'ADD_GROUP_TO_GROUP_TYPE';
export const REMOVE_GROUP_FROM_GROUP_TYPE = 'REMOVE_GROUP_FROM_GROUP_TYPE';

export const SELECT_GROUP_TYPE_CUSTOM_FIELD = 'SELECT_GROUP_TYPE_CUSTOM_FIELD';
export const UN_SELECT_GROUP_TYPE_CUSTOM_FIELD = 'UN_SELECT_GROUP_TYPE_CUSTOM_FIELD';
export const ADD_GROUP_TYPE_CUSTOM_FIELD = 'ADD_GROUP_TYPE_CUSTOM_FIELD';
export const DELETE_GROUP_TYPE_CUSTOM_FIELD = 'DELETE_GROUP_TYPE_CUSTOM_FIELD';
export const UPDATE_GROUP_TYPE_CUSTOM_FIELD = 'UPDATE_GROUP_TYPE_CUSTOM_FIELD';

export const UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE = 'UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE';
export const REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE = 'REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE';

export const SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION = 'SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION';
export const UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION = 'UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION';
export const RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION = 'RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION';
export const ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION = 'ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION';
export const DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION = 'DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION';
export const UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION = 'UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION';

export const UPDATE_GROUP_TYPE_MANAGEMENT = 'UPDATE_GROUP_TYPE_MANAGEMENT';
export const ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP = 'ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP';
export const DISCARD_GROUP_TYPE_MANAGEMENT = 'DISCARD_GROUP_TYPE_MANAGEMENT';


export interface INewGroupTypeData {
    name: string;
    project: string;
    level: string;
    isRequired: boolean,

    nameFieldId: string;
    subTitleFieldId: string;
}

export interface IUpdateableGroupTypeData extends INewGroupTypeData {
    id: string;
}

export interface IGroupType extends IUpdateableGroupTypeData, Synchronizable {
    archived?: boolean,

    management?: {
        workflowTypeId: string,
        lastQuestionPiece: string,
        customFieldMap: {
            [groupTypeCustomFieldId: string]: string,  // ID of the custom field in the managed workflow
        },
    }

    groups: Array<string>,
    customFields: Array<string>,
}


export interface GroupTypeState extends NormalizedModel<IGroupType>, CustomFieldState {
    selected: string|undefined,
}

export interface SelectGroupTypeAction {
    type: typeof SELECT_GROUP_TYPE,
    id: string,
}

export interface UnSelectGroupTypeAction {
    type: typeof UN_SELECT_GROUP_TYPE,
}

export interface ReOrderGroupTypesAction {
    type: typeof RE_ORDER_GROUP_TYPES,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddGroupTypeRequestAction {
    type: typeof ADD_GROUP_TYPE_REQUEST,
    payload: INewGroupTypeData,
}

export interface AddGroupTypeAction {
    type: typeof ADD_GROUP_TYPE,
    payload: IGroupType,
}

export interface DeleteGroupTypeAction {
    type: typeof DELETE_GROUP_TYPE,
    id: string,
}

export interface UpdateGroupTypeAction {
    type: typeof UPDATE_GROUP_TYPE,
    payload: IUpdateableGroupTypeData,
}



export interface AddGroupToGroupTypeAction {
    type: typeof ADD_GROUP_TO_GROUP_TYPE,
    groupTypeId: string,
    groupId: string,
}

export interface RemoveGroupFromGroupTypeAction {
    type: typeof REMOVE_GROUP_FROM_GROUP_TYPE,
    groupTypeId: string,
    groupId: string,
}


export interface SelectGroupTypeCustomFieldAction {
    type: typeof SELECT_GROUP_TYPE_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectGroupTypeCustomFieldAction {
    type: typeof UN_SELECT_GROUP_TYPE_CUSTOM_FIELD,
}

export interface AddGroupTypeCustomFieldAction {
    type: typeof ADD_GROUP_TYPE_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    groupTypeId: string,
}

export interface DeleteGroupTypeCustomFieldAction {
    type: typeof DELETE_GROUP_TYPE_CUSTOM_FIELD,
    id: string,
    groupTypeId: string,
}

export interface UpdateGroupTypeCustomFieldAction {
    type: typeof UPDATE_GROUP_TYPE_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
}


export interface UpdateGroupTypeCustomFieldStartPieceAction {
    type: typeof UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface SetIsolatedGroupTypeCustomFieldPieceAction {
    type: typeof SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
}

export interface RemoveIsolatedGroupTypeCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
}

export interface RegisterGroupTypeCustomFieldVariableAction {
    type: typeof REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
}


export interface SelectGroupTypeCustomFieldOptionAction {
    type: typeof SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectGroupTypeCustomFieldOptionAction {
    type: typeof UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION,
}

export interface ReOrderGroupTypeCustomFieldOptionAction {
    type: typeof RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddGroupTypeCustomFieldOptionAction {
    type: typeof ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
}

export interface DeleteGroupTypeCustomFieldOptionAction {
    type: typeof DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
}

export interface UpdateGroupTypeCustomFieldOptionAction {
    type: typeof UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
}

export interface AddGroupTypeManagementCustomFieldMapAction {
    type: typeof ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP,
    groupTypeId: string,
    groupTypeCustomFieldId: string,
    workflowTypeCustomFieldId: string,
}

export interface UpdateGroupTypeManagementAction {
    type: typeof UPDATE_GROUP_TYPE_MANAGEMENT,
    groupTypeId: string,
    workflowTypeId: string,
    lastQuestionPieceId: string,
}

export interface DiscardGroupTypeManagementAction {
    type: typeof DISCARD_GROUP_TYPE_MANAGEMENT,
    groupTypeId: string,
}

type CustomFieldActions = SelectGroupTypeCustomFieldAction | UnSelectGroupTypeCustomFieldAction | AddGroupTypeCustomFieldAction | DeleteGroupTypeCustomFieldAction | UpdateGroupTypeCustomFieldAction;

type CustomFieldChoiceActions = SelectGroupTypeCustomFieldOptionAction | UnSelectGroupTypeCustomFieldOptionAction | ReOrderGroupTypeCustomFieldOptionAction | AddGroupTypeCustomFieldOptionAction | DeleteGroupTypeCustomFieldOptionAction | UpdateGroupTypeCustomFieldOptionAction;

export type GroupTypeActionTypes = SelectGroupTypeAction | UnSelectGroupTypeAction | ReOrderGroupTypesAction | AddGroupTypeRequestAction | AddGroupTypeAction | DeleteGroupTypeAction | UpdateGroupTypeAction | AddGroupToGroupTypeAction | RemoveGroupFromGroupTypeAction | UpdateGroupTypeCustomFieldStartPieceAction | SetIsolatedGroupTypeCustomFieldPieceAction | RemoveIsolatedGroupTypeCustomFieldPieceAction | RegisterGroupTypeCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions | AddGroupTypeManagementCustomFieldMapAction | UpdateGroupTypeManagementAction | DiscardGroupTypeManagementAction;