import { StructureActionTypes } from '../types'
import { ADD_LEVEL, DELETE_LEVEL, UPDATE_LEVEL, SELECT_LEVEL, UN_SELECT_LEVEL, LevelState, SELECT_LEVEL_CUSTOM_FIELD, UN_SELECT_LEVEL_CUSTOM_FIELD, ADD_LEVEL_CUSTOM_FIELD, DELETE_LEVEL_CUSTOM_FIELD, UPDATE_LEVEL_CUSTOM_FIELD, SELECT_LEVEL_CUSTOM_FIELD_OPTION, UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION, RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION, ADD_LEVEL_CUSTOM_FIELD_OPTION, DELETE_LEVEL_CUSTOM_FIELD_OPTION, UPDATE_LEVEL_CUSTOM_FIELD_OPTION, UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE, REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE, ADD_ROLE_TO_LEVEL, REMOVE_ROLE_FROM_LEVEL, ILevel } from './types';
import { RE_ORDER_ROLES } from '../role/types';
import { UPDATE_STRUCTURE_DATA } from '../types';
import { reOrderList } from '../../../helpers/utilities';
import { UN_SELECT_PROJECT, SELECT_PROJECT } from '../project/types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, updateStartPieceForCustomField, registerVariableForCustomField } from '../../custom-fields';
import { addEntity, updateEntity, deleteEntity } from '../../normalized-model';

const initialState: LevelState = {
    byId: {},
    allEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    customFields: {
        byId: {},
        allFields: [],
    },
    customFieldOptions: {
        byId: {},
        allOptions: [],
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),
}

export function levelsReducer(state = initialState, action: StructureActionTypes): LevelState {
    let newState: LevelState;
    
    switch(action.type) {

        // PROJECT ACTIONS

        case SELECT_PROJECT:
        case UN_SELECT_PROJECT:
            return {
                ...state,
                selected: undefined,
            }

        // LEVEL ACTIONS

        case SELECT_LEVEL:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_LEVEL:
            return {
                ...state,
                selected: undefined,
            }

        case ADD_LEVEL:
            return addEntity<LevelState, ILevel>(state, action.payload);
        
        case UPDATE_LEVEL:
            return updateEntity<LevelState, ILevel>(state, action.payload);
        
        case DELETE_LEVEL:
            state = deleteEntity<LevelState, ILevel>(state, action.id);
            return state;

        case ADD_ROLE_TO_LEVEL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        children: state.byId[action.levelId].children.concat([action.roleId]),
                    }
                }
            }

        case REMOVE_ROLE_FROM_LEVEL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        children: state.byId[action.levelId].children.filter(roleId => roleId !== action.roleId),
                    }
                }
            }
        
        case SELECT_LEVEL_CUSTOM_FIELD:
            return selectCustomField<LevelState>(state, action.id);
        
        case UN_SELECT_LEVEL_CUSTOM_FIELD:
            return unSelectCustomField<LevelState>(state);


        case ADD_LEVEL_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        customFields: state.byId[action.levelId].customFields.concat([action.payload.id]),
                    }
                }
            }
            return addCustomField<LevelState>(newState, action.payload, action.levelId);
            
        case DELETE_LEVEL_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        customFields: state.byId[action.levelId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                }
            }
            return deleteCustomField<LevelState>(newState, action.id);
        
        case UPDATE_LEVEL_CUSTOM_FIELD:
            return updateCustomField<LevelState>(state, action.payload);


        case UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<LevelState>(state, action.customFieldId, action.payload);
    
        case SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<LevelState>(state, action.customFieldId, action.payload);

        case REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<LevelState>(state, action.customFieldId, action.pieceId);

        case REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<LevelState>(state, action.customFieldId, action.variableId);
        
        
        case SELECT_LEVEL_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<LevelState>(state, action.id);
        
        case UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<LevelState>(state);

        case RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<LevelState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_LEVEL_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<LevelState>(state, action.payload, action.parentId);
        
        case DELETE_LEVEL_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<LevelState>(state, action.id, action.parentId);

        case UPDATE_LEVEL_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<LevelState>(state, action.payload);
        

        // ROLE ACTIONS

        case RE_ORDER_ROLES:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        children: reOrderList(state.byId[action.parentId].children, action.sourceIndex, action.destinationIndex),
                    }
                }
            }

        // Structure actions

        case UPDATE_STRUCTURE_DATA:
            return {
                ...action.data.levels,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                createdCustomFieldIds: state.createdCustomFieldIds,
                updatedCustomFieldIds: state.updatedCustomFieldIds,
                deletedCustomFieldIds: state.deletedCustomFieldIds,
                createdCustomFieldOptionIds: state.createdCustomFieldOptionIds,
                updatedCustomFieldOptionIds: state.updatedCustomFieldOptionIds,
                deletedCustomFieldOptionIds: state.deletedCustomFieldOptionIds,

            }

        default:
            return state
    }
}