import React, { Component } from 'react';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/main';
import { updateCustomFieldPiece, setTargetPiece, setMemberVariable } from '../../../shared/store/flowchart/pieces/actions';
import { FieldType, CustomFieldDataType, CustomFieldOptionsDataType } from '../../../shared/store/custom-fields';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';

type OwnProps = {
    pieceId: string,
    customFieldIds: Array<string>,
    selectedCustomFieldId: string|undefined,
    selectedCustomFieldOptionId: string|undefined,

    type: 'Level'|'Role'|'User'|'Member'|'Group'|'Workflow',
    
    isShowingMemberVariablePiece: boolean
    memberVariablePiece?: JSX.Element,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    let customFieldData: CustomFieldDataType;
    let customFieldOptionsData: CustomFieldOptionsDataType;

    switch (ownProps.type) {
        case 'Level':
            customFieldData = state.structure.levels.customFields;
            customFieldOptionsData = state.structure.levels.customFieldOptions;
            break;
        case 'Role':
            customFieldData = state.structure.roles.customFields;
            customFieldOptionsData = state.structure.roles.customFieldOptions;
            break;
        case 'User':
            customFieldData = {
                byId: {
                    ...state.structure.roles.customFields.byId,
                    ...state.users.customFields.byId,
                },
                allFields: state.structure.roles.customFields.allFields.concat(state.users.customFields.allFields),
            };
            customFieldOptionsData = {
                byId: {
                    ...state.structure.roles.customFieldOptions.byId,
                    ...state.users.customFieldOptions.byId,
                },
                allOptions: state.structure.roles.customFieldOptions.allOptions.concat(state.users.customFieldOptions.allOptions),
            };
            break;
        case 'Member':
            customFieldData = state.members.types.customFields;
            customFieldOptionsData = state.members.types.customFieldOptions;
            break;
        case 'Group':
            customFieldData = state.groups.types.customFields;
            customFieldOptionsData = state.groups.types.customFieldOptions;
            break;
        case 'Workflow':
            customFieldData = state.workflows.types.customFields;
            customFieldOptionsData = state.workflows.types.customFieldOptions;
            break;
        default:
            throw new Error('Unknown type');
    }

    return {
        state: state,
        customField: !!ownProps.selectedCustomFieldId ? customFieldData.byId[ownProps.selectedCustomFieldId] : undefined,
        customFieldData,
        customFieldOptionsData,


        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        updateCustomFieldPiece: (customField: string|undefined, customFieldOption: string|undefined) => dispatch(updateCustomFieldPiece(ownProps.pieceId, customField, customFieldOption)),
        
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    expandedCustomFieldId: string|undefined,
    isHoveringOverMemberPiece: boolean,
};

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedCustomFieldPiece extends Component<Props & FlowchartPieceProps, OwnState> {
    
    constructor(props: Readonly<Props & FlowchartPieceProps>) {
        super(props);

        this.state = {
            expandedCustomFieldId: props.customField && (props.customField.type === FieldType.SINGLE_SELECT || props.customField.type === FieldType.MULTI_SELECT) ? props.customField.id : undefined,
            isHoveringOverMemberPiece: false,
        }
    }

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverMemberPiece) {

            this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMemberPiece: false,
            });
        }
    }

    selectListEntry = (selected: string) => {
        if (this.state.expandedCustomFieldId) {
            if (!this.props.customField) {
                throw new Error('No custom field is selected');
            }

            this.props.updateCustomFieldPiece(this.props.customField.id, selected);
        } else {
            this.props.updateCustomFieldPiece(selected, undefined);

            if (this.props.customFieldData.byId[selected].type === FieldType.SINGLE_SELECT || this.props.customFieldData.byId[selected].type === FieldType.MULTI_SELECT) {
                this.setState({
                    expandedCustomFieldId: selected,
                });
            }
        }
    }

    goBack = () => {
        this.setState({
            expandedCustomFieldId: undefined,
        }); 

        if (this.props.selectedCustomFieldId && this.props.selectedCustomFieldOptionId) {
            this.props.updateCustomFieldPiece(this.props.selectedCustomFieldId, undefined);
        }
    }

    render() {
        const currentCustomFieldName = this.props.customField ? this.props.customField.name : undefined;
        
        const completeCustomFieldHeading = this.props.selectedCustomFieldOptionId ? currentCustomFieldName + ' > ' + this.props.customFieldOptionsData.byId[this.props.selectedCustomFieldOptionId].name : currentCustomFieldName;

        let listEntries: Array<{name: string, value: string, isExpandable: boolean}>;
        let heading: string|undefined = undefined;

        const variablePiece = this.props.memberVariablePiece || <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} />

        if (this.state.expandedCustomFieldId && this.props.customField) {

            listEntries = this.props.customField.choices.map(customFieldOptionId => {
                const customFieldOption = this.props.customFieldOptionsData.byId[customFieldOptionId];
                return {
                    name: customFieldOption.name,
                    value: customFieldOption.id,
                    isExpandable: false,
                };
            });

            heading = this.props.customField.name;
        } else {
            listEntries = this.props.customFieldIds.map(customFieldId => {
                const customField = this.props.customFieldData.byId[customFieldId];
                return {
                    name: customField.name,
                    value: customField.id,
                    isExpandable: customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT,
                }
            });

            heading = undefined;
        }

        return (<FlowchartPiece {...this.props}>
            <div>
                <SelectBox isRounded selectionPromptText={this.props.isShowingMemberVariablePiece ? completeCustomFieldHeading + ' for ' : completeCustomFieldHeading} dismissDropDownAfterSelection={false} theme="pink" hasVariableSlot={this.props.isShowingMemberVariablePiece} variablePiece={variablePiece}>
                    <DropDownList heading={heading} goBack={this.goBack} dismissAfterSelection={false} theme="pink">
                        {listEntries.map(listEntry => <ListItem name={listEntry.name} value={listEntry.value} key={listEntry.value} isExpandable={listEntry.isExpandable} theme="pink" onClick={(selected) => this.selectListEntry(selected)} />)}
                    </DropDownList>
                </SelectBox>
            </div>
        </FlowchartPiece>);
    }
}

const CustomFieldPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedCustomFieldPiece);

export default CustomFieldPiece;