import React, { Component } from 'react';
import styles from './Configuration.module.scss';
import { Redirect } from "react-router-dom";

import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';
import { FieldType, INewCustomFieldData } from '../../../shared/store/custom-fields';

import { translatePhrase } from '../../../shared/helpers/translation';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Permissions } from '../../../shared/store/permissions/types';
import { addUserCustomField } from '../../../shared/store/users/actions';

import { ApplicationState } from '../../../shared/store/main';
import UserCustomFieldVertical from './UserCustomFieldVertical';
import uuid from 'uuid';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.UserConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.UserConfiguration === Permissions.READ;

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        customFieldIds: state.users.customFields.allFields,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addUserCustomField: (payload: INewCustomFieldData) => dispatch(addUserCustomField(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalType: string,
    modifyingVerticalIsComputed: string,
    modifyingVerticalIsInTable: string,
    modifyingVerticalIsUnique: string,
}

class ConnectedConfiguration extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalType: '',
        modifyingVerticalIsComputed: 'No',
        modifyingVerticalIsInTable: 'No',
        modifyingVerticalIsUnique: 'No',
    };

    toggleCustomFieldAddForm = () => {
        this.setState((prevState) => {
            return {
                isShowingAddForm: !prevState.isShowingAddForm,
            };
        });
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }

    updateFieldIsUnique = (value: string) => {
        this.setState({
            modifyingVerticalIsUnique: value,
        });
    }

    addCustomField = () => {
        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        this.props.addUserCustomField({
            name: this.state.modifyingVerticalName,
            type: FieldType[fieldType],
            isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
            isEditable: true,
            isDeletable: true,
            isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
            isUnique: this.state.modifyingVerticalIsUnique === 'Yes',
            seedEntityVariable: uuid.v4(),
        });

        this.setState({
            isShowingAddForm: false,
            modifyingVerticalName: '',
            modifyingVerticalType: '',
            modifyingVerticalIsComputed: 'No',
            modifyingVerticalIsInTable: 'No',
            modifyingVerticalIsUnique: 'No',
        });
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        if (this.state.modifyingVerticalIsComputed !== 'Yes') {
    
            if (!binaryTypes.includes(this.state.modifyingVerticalIsUnique)) {
                return 'Enter a valid unique field'
            }

        }
        
        return true;
    }

    render() {
        
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const binaryOptions = ['Yes', 'No'];
        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: name.split('_').join(' '),
                value: name,
            }
        });

        const addFieldForm = <ModifyForm isNew={true} submitForm={this.addCustomField} cancelForm={this.toggleCustomFieldAddForm} validateForm={this.validateVerticalForm}>
            <InputText placeholder="Name" onEnterPress={this.addCustomField} onChange={this.updateFieldName} />
            <InputText placeholder="Type" onEnterPress={this.addCustomField} onChange={this.updateFieldType} options={typeOptions} />
            <InputText placeholder="Is Computed" onEnterPress={this.addCustomField} onChange={this.updateFieldIsComputed} options={binaryOptions} default="No" />
            {this.state.modifyingVerticalIsComputed !== 'Yes' && <InputText placeholder="Is In Table" onEnterPress={this.addCustomField} onChange={this.updateFieldIsInTable} options={binaryOptions} default="No" />}
            {this.state.modifyingVerticalIsComputed !== 'Yes' && <InputText placeholder="Is Unique" onEnterPress={this.addCustomField} onChange={this.updateFieldIsUnique} options={binaryOptions} default="No" />}
        </ModifyForm>;
    
        return (<div className={styles.cardsTree}>
            {this.props.customFieldIds.map(id => <UserCustomFieldVertical customFieldId={id} key={id} />)}
            {this.props.isWritable && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}>+ {translatePhrase('Add Custom Field')}</section>}
            </section>}
        </div>);

    }
     
}

const Configuration = connect(mapStateToProps, mapDispatchToProps)(ConnectedConfiguration);

export default Configuration;