import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { deleteGroupTypeCustomField, updateGroupTypeCustomField, selectGroupTypeCustomFieldOption, unSelectGroupTypeCustomFieldOption, reOrderGroupTypeCustomFieldOption, addGroupTypeCustomFieldOption, deleteGroupTypeCustomFieldOption, updateGroupTypeCustomFieldOption } from '../../../shared/store/groups/types/actions';

import CustomFieldVertical, { 
    OwnProps as CustomFieldVerticalOwnProps, 
    StateProps as CustomFieldVerticalStateProps, 
    DispatchProps as CustomFieldVerticalDispatchProps, 
    OwnState as CustomFieldVerticalOwnState 
} from '../../../helpers/CustomFieldVertical';
import { Permissions } from '../../../shared/store/permissions/types';

interface OwnProps extends CustomFieldVerticalOwnProps {
    groupTypeId: string,
}

interface StateProps extends CustomFieldVerticalStateProps {
}

interface DispatchProps extends CustomFieldVerticalDispatchProps {
}

interface OwnState extends CustomFieldVerticalOwnState {
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const canEditGroupTypeConfiguration = state.permissions.myPermissions.general.GroupsConfiguration === Permissions.WRITE;
    const canViewGroupTypeConfiguration = state.permissions.myPermissions.general.GroupsConfiguration === Permissions.READ || canEditGroupTypeConfiguration;

    const customFieldData = state.groups.types.customFields.byId[ownProps.customFieldId];

    return {
        read: canViewGroupTypeConfiguration,
        write: canEditGroupTypeConfiguration,
        restrictStructureChanges: false,
        customFieldData: customFieldData,
        cardsList: customFieldData.choices.map(choiceId => {
            return {
                id: choiceId,
                name: state.groups.types.customFieldOptions.byId[choiceId].name,
            }
        }),
        selectedCard: state.groups.types.selectedOption && customFieldData.choices.includes(state.groups.types.selectedOption) ? {
            id: state.groups.types.selectedOption,
            name: state.groups.types.customFieldOptions.byId[state.groups.types.selectedOption].name,
        } : undefined
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) : DispatchProps => {
    return {
        onSelectCard: (id) => dispatch(selectGroupTypeCustomFieldOption(id)),
        onUnSelectCard: () => dispatch(unSelectGroupTypeCustomFieldOption()),
        reOrderCards: (sourceIndex, destinationIndex) => dispatch(reOrderGroupTypeCustomFieldOption(sourceIndex, destinationIndex, ownProps.customFieldId)),
        addCard: (payload) => dispatch(addGroupTypeCustomFieldOption(payload, ownProps.customFieldId)),
        deleteCard: (id) => dispatch(deleteGroupTypeCustomFieldOption(id, ownProps.customFieldId)),
        updateCard: (payload) => dispatch(updateGroupTypeCustomFieldOption(payload)),

        deleteVertical: (id) => dispatch(deleteGroupTypeCustomField(id, ownProps.groupTypeId)),
        updateVertical: (payload) => dispatch(updateGroupTypeCustomField(payload)),
    }
}

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedGroupTypeCustomFieldVertical extends CustomFieldVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingModifyVerticalForm: false,

        modifyingCardName: this.props.selectedCard ? this.props.selectedCard.name : '',
        modifyingVerticalName: this.props.customFieldData ? this.props.customFieldData.name : '',
        modifyingVerticalType: this.props.customFieldData ? this.props.customFieldData.type : '',
        modifyingVerticalIsComputed: this.props.customFieldData ? this.props.customFieldData.isComputed ? 'Yes' : 'No' : 'No',
        modifyingVerticalIsInTable: this.props.customFieldData ? this.props.customFieldData.isInTable ? 'Yes' : 'No' : 'No',
        modifyingVerticalIsUnique: this.props.customFieldData ? this.props.customFieldData.isUnique ? 'Yes' : 'No' : 'No',

        isUpdating: false,
    };

    static defaultProps = {
        isReadOnly: false,
    }

}

const GroupTypeCustomFieldVertical = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupTypeCustomFieldVertical);

export default GroupTypeCustomFieldVertical;