import React, { Component } from 'react';
import { connect } from 'react-redux';

import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';
import uuid from 'uuid';
import { Permissions } from '../../../shared/store/permissions/types';
import { reOrderReportTypes, addReportType, updateReportType, deleteReportType, selectReportType, unSelectReportType } from '../../../shared/store/reports/types/actions';
import { INewReportTypeData, IUpdateableReportTypeData } from '../../../shared/store/reports/types/types';
import { ApplicationState } from '../../../shared/store/main';
import { Dispatch } from 'redux';

export interface OwnProps {
    isReadOnly: boolean,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const canWrite = state.permissions.myPermissions.general.Reports === Permissions.WRITE;
    const canRead = canWrite || state.permissions.myPermissions.general.Reports === Permissions.READ;

    return {
        read: canRead,
        write: canRead,
        restrictStructureChanges: false,
        projectsData: state.structure.projects,
        reportTypesData: state.reports.types,
        selectedReportType: state.reports.types.selected ? state.reports.types.byId[state.reports.types.selected] : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectCard: (id: string) => dispatch(selectReportType(id)),
        unSelectCard: () => dispatch(unSelectReportType()),
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderReportTypes(sourceIndex, destinationIndex)),
        addCard: (payload: INewReportTypeData) => dispatch(addReportType(payload)),
        deleteCard: (id: string) => dispatch(deleteReportType(id)),
        updateCard: (payload: IUpdateableReportTypeData) => dispatch(updateReportType(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardProject: string,
};

class ConnectedReportTypesList extends Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isShowingAddForm: false,
            isShowingModifyForm: false,
            modifyingCardName: props.selectedReportType ? props.selectedReportType.name : '',
            modifyingCardProject: props.selectedReportType ? props.selectedReportType.project : '',
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (!state.modifyingCardName && props.selectedReportType) {

            return {
                modifyingCardName: props.selectedReportType.name ? props.selectedReportType.name : '',
            };
        }

        return null;
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;
        
        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }
    
    editSelectedCard = () => {

        if (!this.props.selectedReportType) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedReportType.name,
        });
    }
    
    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }
    
    updateCardProject = (value: string) => {
        this.setState({
            modifyingCardProject: value
        });
    }
    
    addCard = () => {
        
        this.props.addCard({
            name: this.state.modifyingCardName,
            project: this.state.modifyingCardProject,
            seedUserVariable: uuid.v4(),
            seedUsersVariable: uuid.v4(),
            seedMembersVariable: uuid.v4(),
            seedGroupsVariable: uuid.v4(),
            seedWorkflowsVariable: uuid.v4(),
            seedStartDateVariable: uuid.v4(),
            seedEndDateVariable: uuid.v4(),
        });
        
        this.setState({
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }
    
    updateCard = () => {

        if (!this.props.selectedReportType) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedReportType.id,
            name: this.state.modifyingCardName,
            project: this.state.modifyingCardProject,
            seedUserVariable: this.props.selectedReportType.seedUserVariable,
            seedUsersVariable: this.props.selectedReportType.seedUsersVariable,
            seedMembersVariable: this.props.selectedReportType.seedMembersVariable,
            seedGroupsVariable: this.props.selectedReportType.seedGroupsVariable,
            seedWorkflowsVariable: this.props.selectedReportType.seedWorkflowsVariable,
            seedStartDateVariable: this.props.selectedReportType.seedStartDateVariable,
            seedEndDateVariable: this.props.selectedReportType.seedEndDateVariable,
        });
        
        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }
    
    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }
        
        return true;
    }

    render() {

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            }
        });

        const modifyForm = <ModifyForm isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <InputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedReportType ? this.props.selectedReportType.name : ''} key={this.state.isShowingModifyForm && this.props.selectedReportType ? this.props.selectedReportType.id : 0} />
            <InputText placeholder="Project" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardProject} default={this.props.selectedReportType && this.props.selectedReportType.project ? this.props.projectsData.byId[this.props.selectedReportType.project].name : ''} options={projectsList} />
        </ModifyForm>;

        const cardsList = this.props.reportTypesData.allEntries.map(reportTypeId => {
            return {
                id: reportTypeId,
                name: this.props.reportTypesData.byId[reportTypeId].name,
            };
        })

        return <CardsList 
            heading="Report Types"
            cards={cardsList}
            selectedCard={this.props.selectedReportType} 
            onSelectCard={this.props.selectCard}
            onUnselectCard={() => {}} 
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={() => {this.props.unSelectCard(); this.toggleModifyForm(); }}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
        />
    }
}

const ReportTypesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedReportTypesList);

export default ReportTypesList;