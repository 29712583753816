import React, { Component } from 'react';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setLoopVariable, setIterableVariable } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import GroupedContainerPiece from './container-piece/GroupedContainerPiece';

import { Option } from '../drop-down/ListItem';
import QuestionPieceEssentials from './QuestionPieceEssentials';
import QuestionPieceOptionals from './QuestionPieceOptionals';
import { nextPieceTarget } from './utilities';


type GroupedQuestionPieceProps = {
    questionVariablePiece?: JSX.Element,
    questionText?: string,
    memberVariable?: JSX.Element,
    isForSingleMember?: boolean,

    isRequiredPiece?: JSX.Element,
    isDisabledPiece?: JSX.Element,
    defaultPiece?: JSX.Element,
    defaultText?: string,

    selectedCustomField?: string,
    customFields: Array<Option>,
    nextPiece?: JSX.Element,
    innerPiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setLoopVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setLoopVariable(targetPieceId, draggedPieceId)),
        setIterableVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setIterableVariable(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GroupedQuestionPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedGroupedQuestionPiece extends Component<Props> {

    handleHoverOverInnerPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    render() {
        const upperArmContent = <QuestionPieceEssentials pieceName="grouped question" {...this.props} />;
        const lowerArmContent = <QuestionPieceOptionals {...this.props} />;
        return <GroupedContainerPiece 
            {...this.props}
            theme="camo"
            handleHoverOverInnerPiece={this.handleHoverOverInnerPiece}
            upperArmContent={upperArmContent}
            lowerArmContent={lowerArmContent}
        >
            {this.props.innerPiece}
        </GroupedContainerPiece>;
    }
}

const GroupedQuestionPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupedQuestionPiece);

export default GroupedQuestionPiece;