import React, { Component } from 'react';
import styles from './Checkbox.module.scss';
import { translatePhrase } from '../../shared/helpers/translation';
import uuid from 'uuid';


type OwnProps = {
    name: string,
    value: string,
    defaultChecked: boolean,

    toggle: (id: string) => void,
};

type OwnState = {
    checkboxKey: number,
    uniqueId: string,
}

class Checkbox extends Component<OwnProps, OwnState> {
    state: OwnState = {
        checkboxKey: 0,
        uniqueId: uuid.v4(),
    };

    static defaultProps = {
        defaultChecked: false,
    };

    handleClick = () => {
        this.props.toggle(this.props.value);
        this.setState((prevState, props) => {
            return {
                checkboxKey: prevState.checkboxKey,
            };
        })
    }

    render() {
        return (
            <section className={styles.checkboxHolder} key={this.state.checkboxKey}>
                <input id={this.state.uniqueId} type="checkbox" defaultChecked={this.props.defaultChecked} onChange={this.handleClick} />
                <label htmlFor={this.state.uniqueId} className={styles.text}>{translatePhrase(this.props.name)}</label>
            </section>
        );
    }
}

export default Checkbox;

