import React, { Component, createRef, RefObject, KeyboardEvent, FocusEvent, ChangeEvent } from 'react';
import styles from './InputText.module.scss';
import OptionsList from './OptionsList';
import { translatePhrase } from '../../shared/helpers/translation';

export type Option = {
    id: number,
    name: string,
    value: string,
}

type OptionInput = {
    name: string,
    value: string
}

type OwnProps = {
    default?: string,
    options?: Array<OptionInput|string>,
    placeholder?: string,
    icon?: string,
    type: string,
    isDisabled: boolean,
    isSearchDisabled: boolean,

    onChange: (changedValue: string) => void,
    onEnterPress?: () => void,
}

type OwnState = {
    inputValue: string,
    isFocussed: boolean,
    finalValue: string,
    isTextShowing: boolean,
    lastSentInput: string,
    blurTimeout: number|undefined,
    
    options?: Array<Option>,
    highlightedOptionIndex?: number,
}

class InputText extends Component<OwnProps, OwnState> {
    input: RefObject<HTMLInputElement> = createRef();

    constructor(props: Readonly<OwnProps>) {
        super(props);
        
        this.state = {
            inputValue: this.props.default || '',
            isFocussed: false,
            finalValue: '',
            lastSentInput: '',
            isTextShowing: false,
            highlightedOptionIndex: undefined,
            blurTimeout: undefined,
        };

        // If there are options provided for the input...
        if (this.props.options) {

            // ...Mold them to the proper format...
            const options = this.props.options.map((option, index) => { 
                return {
                    id: index,
                    name: typeof option === 'string' ? option : option.name,
                    value: typeof option === 'string' ? option : option.value,
                };
            });

            // ...then add them to the state
            this.state = {
                ...this.state,
                options: options
            };
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.state.blurTimeout);
    }

    static defaultProps = {
        isDisabled: false,
        isSearchDisabled: false,
        type: 'text',
    }
    
    // Used to toggle the show/hide text for password types
    toggleText = () => {
        this.setState((prevState, props) =>{
            return {isTextShowing: !prevState.isTextShowing}
        });
    }
    
    handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        enum keyCodes { ENTER_KEY = 13, UP_KEY = 38, DOWN_KEY = 40 };

        // If the "Enter" key is pressed, select the highlighted option
        if (e.keyCode === keyCodes.ENTER_KEY) {
            if (this.state.options && this.state.highlightedOptionIndex !== undefined) {
                const selectedOption = this.state.options[this.state.highlightedOptionIndex];
                this.onSelect(selectedOption.name, selectedOption.value);
            } else if (this.props.onEnterPress) {
                this.props.onEnterPress();
            }
        }
        
        if (this.state.isFocussed && this.props.options) {
        
            if (e.keyCode === keyCodes.UP_KEY) {
                if (this.state.highlightedOptionIndex === undefined) {

                    // If no option is highlighted, highlight the last option...
                    this.setState({
                        highlightedOptionIndex: this.props.options.length - 1
                    });
                } else {
                    const currentOptionIndex = this.state.highlightedOptionIndex;

                    // If the highlighted option is the first option, highlight the last option. Otherwise, highlight the previous option
                    const previousOptionIndex = currentOptionIndex > 0 ? currentOptionIndex - 1 : this.props.options.length - 1;

                    this.setState({
                        highlightedOptionIndex: previousOptionIndex
                    });
                }
            }

            if (e.keyCode === keyCodes.DOWN_KEY) {
                if (this.state.highlightedOptionIndex === undefined) {
                    this.setState({
                        highlightedOptionIndex: 0
                    });
                } else {
                    const currentOptionIndex = this.state.highlightedOptionIndex;
                    const nextOptionIndex = currentOptionIndex < this.props.options.length - 1 ? currentOptionIndex + 1 : 0;
                    this.setState({
                        highlightedOptionIndex: nextOptionIndex
                    });
                }
            }
            
        }
    }
    
    changeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        let finalValue;

        if (this.state.options) {
            const matchingOption = this.state.options && this.state.options.find(option => option.name === inputValue);
            finalValue = matchingOption ? matchingOption.name : '';
        } else {
            finalValue = inputValue;
        }
        
        if(this.props.onChange && this.state.lastSentInput !== finalValue) {
            this.props.onChange(finalValue);

            this.setState({
                lastSentInput: finalValue
            });
        }
        
        this.setState({
            inputValue: e.target.value,
            finalValue: finalValue
        });
    }
    
    onFocus = (e: FocusEvent<HTMLInputElement>) => {
        window.clearTimeout(this.state.blurTimeout);
        this.setState({
            isFocussed: true,
            blurTimeout: undefined,
        });
    }
    
    onBlur = (e: FocusEvent<HTMLInputElement>) => {
        const that = this;
        const timeoutId = window.setTimeout(function () {
            that.setState((state, props) => ({
                // Check if the input is focussed (the input may have gained focus between the blur and the delay)
                isFocussed: document.activeElement === that.input.current,
                highlightedOptionIndex: undefined,
                blurTimeout: undefined,
            }));
        }, 500);

        this.setState({
            blurTimeout: timeoutId,
        })
    }
    
    onSelect = (name: string, value: string) => {
        this.setState({
            inputValue: name,
            finalValue: name,
            isFocussed: false,
            highlightedOptionIndex: undefined
        });
        
        if (this.input && this.input.current) {
            this.input.current.value = name;
            this.input.current.blur();
        }
        
        this.props.onChange && this.props.onChange(value);
    }
    
    render () {
        
        return (
            <div className={this.props.isDisabled ? styles.disabledInputHolder : styles.inputHolder}>
                <input
                    className={this.state.inputValue ? styles.active : ''}
                    type={this.props.type ? (this.props.type === 'password' && this.state.isTextShowing ? 'text' : this.props.type) : 'text'}
                    autoComplete="false"
                    defaultValue={this.state.inputValue}
                    onChange={this.changeInput}
                    onKeyDown={this.handleKeyPress}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.isSearchDisabled}
                    disabled={this.props.isDisabled}
                    ref={this.input} 
                />
                {this.props.placeholder && <div className={styles.placeholder}>{translatePhrase(this.props.placeholder)}</div>}

                {this.props.icon && 
                <img
                    className={(this.props.type === 'password' ? styles.toggleableIcon : styles.icon) + (this.state.options && this.state.isFocussed ? ' ' + styles.rotated : '')} 
                    src={this.props.icon} 
                    alt="" 
                    onClick={this.props.type === 'password' ? this.toggleText : undefined} 
                />}

                {this.state.options && 
                <OptionsList
                    isShowing={this.state.isFocussed}
                    search={this.props.isSearchDisabled ? '' : this.state.inputValue}
                    options={this.state.options}
                    selectedIndex={this.state.highlightedOptionIndex}
                    onSelect={this.onSelect}
                />}
            </div>
        );
    }
}

export default InputText;