import { SELECT_STATUS, UN_SELECT_STATUS, ADD_STATUS, DELETE_STATUS, UPDATE_STATUS, RE_ORDER_STATUSES, StatusState } from './types';
import { WorkflowActionTypes } from '../../types';
import { reOrderList } from '../../../../helpers/utilities';

export const initialState: StatusState = {  
    byId: {},
    allEntries:[],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function statusesReducer(state = initialState, action: WorkflowActionTypes): StatusState {
    switch(action.type) {

        case SELECT_STATUS:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_STATUS:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_STATUSES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_STATUS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
            }
        
        case DELETE_STATUS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(entry => entry !== action.id),
                selected: undefined,
            }
        
        case UPDATE_STATUS:
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.id]: {
                            ...state.byId[action.payload.id],
                            ...action.payload,
                        },
                    },
                }
        

        default:
            return state
    }
}