import React from 'react';
import styles from './List.module.scss';

import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import WorkflowsTable from './WorkflowsTable';

import { ApplicationState } from '../../../shared/store/main';
import { Permissions } from '../../../shared/store/permissions/types';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditWorkflows = state.permissions.myPermissions.general.Workflows === Permissions.WRITE;
    const canViewWorkflows = canEditWorkflows || state.permissions.myPermissions.general.Workflows === Permissions.READ;
    
    return {
        filtersExpanded: false,
        read: canViewWorkflows,
        write: canEditWorkflows,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

const ConnectedWorkflows: React.FC<Props> = function (props) {
        
    if (!props.read) {
        return <Redirect to="/dashboard" />;
    }
    
    return (
        <section className={styles.workflowsSection}>
            <div className={props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                <WorkflowsTable isReadOnly={!props.write} />
            </div>
            {/* <WorkflowsFilter /> */}
        </section>
    );
};

const Workflows = connect(mapStateToProps)(ConnectedWorkflows);

export default Workflows;