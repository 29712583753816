import React, { Component } from 'react';
import styles from './Group.module.scss';

import { WorkflowProcessState, IWorkflow } from '../../../shared/store/workflows/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';

import Question, { QuestionProps } from './Question';
import Choose, { ChoosePieceProps } from './Choose';
import ShowTable, { ShowTableProps } from './ShowTable';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { CustomFieldValueType, getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';

type OwnProps = {
    workflowId: string,
    groupPieceId: string,
    userInputs: {
        [customFieldId: string]: CustomFieldValueType,
    },
    listUserInputs?: {
        [listId: string]: {
            [customFieldId: string]: CustomFieldValueType
        }
    },
    errorMessages: {
        [questionId: string]: string,
    },
    userInputsForChoice?: {
        [questionId: string]: string,
    },
    errorMessagesForChoice?: {
        [questionId: string]: string,
    },

    overWrittenVariable?: string,
    overWrittenValue?: string,

    answerKey: number,

    getShowDataFromPieceId: (showPieceId: string, workflowProcessState: WorkflowProcessState) => ShowTableProps|string,

    updateUserInput: (customFieldId: string, value: CustomFieldValueType) => void,
    updateUserInputForChoice?: (customFieldId: string, value: string) => void,
    validateAnswer: (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => string,
    validateChoice?: (questionId: string, answer: string, processState: WorkflowProcessState) => string,
};

const mapStateToProps = (state: ApplicationState) => {

    return {
        applicationState: state,
        myId: state.myData.id,
        workflowData: state.workflows,
        membersData: state.members,
        groupsData: state.groups,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
}

type DisplayData = {
    type: 'question',
    pieceId: string,
    data: QuestionProps,
} | {
    type: 'choose',
    pieceId: string,
    data: ChoosePieceProps,
} | {
    type: 'show',
    pieceId: string,
    data: string | ShowTableProps,
} | {
    type: 'section',
    pieceId: string,
    data: Array<DisplayData>,
};

class ConnectedGroup extends Component<Props, OwnState> {

    getAllGroupedPieces = (groupPieceId: string) => {
        const groupedPieces: Array<{
            id: string,
            type: 'question'|'choose'|'show'|'section',
        }> = [];

        const groupPiece = this.props.piecesData.byId[groupPieceId];

        if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
            throw new Error('The id must be a group ID');
        }

        if (!groupPiece.innerPiece) {
            throw new Error('The group piece must have an inner piece');
        }

        let pieceIdToConsider = groupPiece.innerPiece;

        do {
            const pieceToConsider = this.props.piecesData.byId[pieceIdToConsider];

            if (pieceToConsider.type === PieceType.GROUPED_QUESTION) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'question',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_CHOOSE) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'choose',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_SHOW) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'show',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.SECTION) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'section',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else {
                throw new Error('This piece can only be a grouped question, a grouped show, or a section');
            }
            
        } while (pieceIdToConsider);

        return groupedPieces;
    }

    getDisplayDataForCollection = (collectionId: string): Array<DisplayData> => {
        
        const workflow = this.props.workflowData.byId[this.props.workflowId];
        const workflowProcessState = this.getWorkflowProcessState(workflow);

        const groupPiece = this.props.piecesData.byId[collectionId];
        const piecesInGroup = this.getAllGroupedPieces(collectionId);

        if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
            throw new Error('The piece must be a group or section type');
        }

        const piecesData: Array<DisplayData> = piecesInGroup.map(groupedPiece => {
            if (groupedPiece.type === 'question') {
                const questionPiece = this.props.piecesData.byId[groupedPiece.id];

                if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
                    throw new Error('The piece must be a question type');
                }

                if (!questionPiece.customFieldId) {
                    throw new Error('The question must have a valid custom field');
                }

                const allAnswers = typeof this.props.listUserInputs === 'undefined' ? {...this.props.userInputs} : {...this.props.listUserInputs};

                let disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isDisabled = questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, questionPiece.isDisabledPiece) : undefined;

                let requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isRequired = questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, questionPiece.isRequiredPiece) : undefined;

                const questionData: QuestionProps = {
                    workflowId: this.props.workflowId,
                    questionId: groupedPiece.id,
                    userInput: this.props.userInputs[questionPiece.customFieldId],
                    errorMessage: this.props.errorMessages[groupedPiece.id],
                    validateAnswer: this.props.validateAnswer,
                    onInputChange: this.props.updateUserInput.bind(this, questionPiece.customFieldId),
                    overWrittenVariable: this.props.overWrittenVariable,
                    overWrittenValue: this.props.overWrittenValue,
                    isDisabled,
                    isRequired,
                };

                return {
                    type: 'question',
                    pieceId: groupedPiece.id,
                    data: questionData
                };
            } else if (groupedPiece.type === 'choose') {
                const choosePiece = this.props.piecesData.byId[groupedPiece.id];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('The piece must be a question type');
                }

                if (!this.props.userInputsForChoice || !this.props.errorMessagesForChoice || !this.props.updateUserInputForChoice || !this.props.validateChoice) {
                    throw new Error('These properties must be defined for a choice type')
                }

                const allAnswers = typeof this.props.listUserInputs === 'undefined' ? {...this.props.userInputs} : {...this.props.listUserInputs};

                let disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isDisabled = choosePiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, choosePiece.isDisabledPiece) : undefined;

                let requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isRequired = choosePiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, choosePiece.isRequiredPiece) : undefined;

                const choooseData: ChoosePieceProps = {
                    workflowId: this.props.workflowId,
                    questionId: groupedPiece.id,
                    userInput: this.props.userInputsForChoice[groupedPiece.id],
                    errorMessage: this.props.errorMessagesForChoice[groupedPiece.id],
                    validateAnswer: this.props.validateChoice,
                    onInputChange: this.props.updateUserInputForChoice.bind(this, groupedPiece.id),
                    overWrittenVariable: this.props.overWrittenVariable,
                    overWrittenValue: this.props.overWrittenValue,
                    choiceInputs: this.props.userInputsForChoice,
                    isDisabled,
                    isRequired,
                };

                return {
                    type: 'choose',
                    pieceId: groupedPiece.id,
                    data: choooseData
                };
            } else if (groupedPiece.type === 'show') {
                const showData = this.props.getShowDataFromPieceId(groupedPiece.id, workflowProcessState);

                return {
                    type: 'show',
                    pieceId: groupedPiece.id,
                    data: showData,
                }
            } else if (groupedPiece.type === 'section') {
                const sectionData = this.getDisplayDataForCollection(groupedPiece.id);

                return {
                    type: 'section',
                    pieceId: groupedPiece.id,
                    data: sectionData,
                }
            } else {
                throw new Error('The piece should be one of these types');
            }
        });

        return piecesData;
    }

    getWorkflowProcessState = (workflow: IWorkflow) => {
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        return processState;
    }
        
    render() {

        const piecesData = this.getDisplayDataForCollection(this.props.groupPieceId);
        const piecesMarkup = piecesData.map(pieceData => {
            if (pieceData.type === 'question') {
                return <Question key={pieceData.pieceId + '-' + this.props.answerKey} {...pieceData.data} />;
            } else if (pieceData.type === 'choose') {
                return <Choose key={pieceData.pieceId + '-' + this.props.answerKey} {...pieceData.data} />;
            } else if (pieceData.type === 'show') {
                if (typeof pieceData.data === 'string') {
                    return <section key={pieceData.pieceId} className={styles.showMessage}>{pieceData.data}</section>;
                } else {
                    return <section className={styles.showDataContainer}><ShowTable key={pieceData.pieceId + '-' + this.props.answerKey} {...pieceData.data} /></section>;
                }
            } else if (pieceData.type === 'section') {
                const sectionPiece = this.props.piecesData.byId[pieceData.pieceId];
                const workflow = this.props.workflowData.byId[this.props.workflowId];
                const headingWorkflowProcessState = this.getWorkflowProcessState(workflow);

                if (sectionPiece.type !== PieceType.SECTION) {
                    throw new Error('The piece must be a section piece');
                }

                const sectionHeading = sectionPiece.heading && isUUID(sectionPiece.heading) ? getWorkflowPieceValue(this.props.applicationState, headingWorkflowProcessState, this.props.workflowId, sectionPiece.heading) : sectionPiece.heading;
                const noOfColumns = sectionPiece.columns && !isNaN(Number(sectionPiece.columns)) ? Number(sectionPiece.columns) : 1;
                let gridColumnStyle = (new Array(noOfColumns)).fill('calc(' + (100 / noOfColumns) + '% - 20px)').join(' ');

                const sectionMarkup = <section key={pieceData.pieceId} className={styles.sectionHolder}>
                    <h3 className={styles.sectionHeading}>{sectionHeading}</h3>
                    <div className={styles.sectionContent} style={{gridTemplateColumns: gridColumnStyle}}>
                        {pieceData.data.map(sectionPieceData => {
                            if (sectionPieceData.type === 'question') {
                                return <Question key={sectionPieceData.pieceId + '-' + this.props.answerKey} {...sectionPieceData.data} isSectioned />;
                            } else if (sectionPieceData.type === 'choose') {
                                return <Choose key={sectionPieceData.pieceId + '-' + this.props.answerKey} {...sectionPieceData.data} isSectioned />;
                            } else if (sectionPieceData.type === 'show') {
                                if (typeof sectionPieceData.data === 'string') {
                                    return <section key={sectionPieceData.pieceId} className={styles.showMessage}>{sectionPieceData.data}</section>;
                                } else {
                                    return <section className={styles.showDataContainer}><ShowTable key={sectionPieceData.pieceId + '-' + this.props.answerKey} {...sectionPieceData.data} /></section>;
                                }
                            } else {
                                throw new Error('Unknown grouped piece');
                            }
                        })}
                    </div>
                </section>

                return sectionMarkup;
            } else {
                throw new Error('Unknown kind of grouped piece');
            }
        });

        let memberNameMarkup;

        if (this.props.overWrittenValue) {
            const member = this.props.membersData.byId[this.props.overWrittenValue];
            const memberType = this.props.membersData.types.byId[member.type];
            const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];
            memberNameMarkup = <div className={styles.memberName}>{getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member')}</div>;
        }

        return <div key={this.props.groupPieceId}>
            {memberNameMarkup}
            {piecesMarkup}
        </div>
    }
}

const Group = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroup);

export default Group;