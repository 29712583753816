import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type PickFirstNElementsOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const PickFirstNElementsOperator: FC<PickFirstNElementsOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator prefixSymbol="first" operatorSymbol="of" {...props} />
    )
}

export default PickFirstNElementsOperator;