import React, { Component, ChangeEvent } from 'react';
import styles from './ColorPicker.module.scss';
import { hexToHSL, HSLToHex, ThemeColor } from '../../shared/helpers/color-utilities';

type OwnProps = {
    default: string,
    isDisabled: boolean,
    colors: Array<ThemeColor>,
    isShowingSliders: boolean,

    onChangeColor: (hexValue: string, lighterHexValue: string) => void,
};

type OwnState = {
    defaultColor: string,
    hue: number,
    saturation: number,
    brightness: number,
    selectedColor: string,

    changeKey: number,
};

export default class ColorPicker extends Component<OwnProps, OwnState> {
    constructor(props: Readonly<OwnProps>) {
        super(props);
        
        const defaultColor = props.default ? props.default : '#00ff00';
        const defaultHslColor = hexToHSL(defaultColor);

        const [hue, saturation, lightness] = [Math.round(defaultHslColor.h * 360), Math.round(defaultHslColor.s * 100), Math.round(defaultHslColor.l * 100)];
        
        this.state = {
            defaultColor: defaultColor,
            hue: hue,
            saturation: saturation,
            brightness: lightness,
            selectedColor: `hsl(${hue}deg, ${saturation}%, ${lightness}%)`,
            changeKey: 0,
        };
    }

    static defaultProps = {
        isDisabled: false,
        isShowingSliders: false,
    }
    
    static getDerivedStateFromProps(props: Readonly<OwnProps>, state: Readonly<OwnState>) {
        if (props.default !== state.defaultColor) {
        
            const defaultColor = props.default ? props.default : '#00ff00';
            const defaultHslColor = hexToHSL(defaultColor);
            const [hue, saturation, lightness] = [Math.round(defaultHslColor.h * 360), Math.round(defaultHslColor.s * 100), Math.round(defaultHslColor.l * 100)];
            
            return {
                defaultColor: defaultColor,
                hue: hue,
                saturation: saturation,
                brightness: lightness,
                selectedColor: `hsl(${hue}deg, ${saturation}%, ${lightness}%)`,
                changeKey: 0,
            }
        } else {
            return null;
        }
    }
    
    changeSelectedColor = (hue: number, saturation: number, brightness: number) => {
        const newColor = `hsl(${hue}deg, ${saturation}%, ${brightness}%)`;
        this.setState({
            hue: hue,
            saturation: saturation,
            brightness: brightness,
            selectedColor: newColor
        });
        const newHexValue = HSLToHex(hue, saturation, brightness);
        const newLighterHexValue = HSLToHex(hue, saturation, brightness + 15);
        this.props.onChangeColor(newHexValue, newLighterHexValue);
    }
    
    changeHue = (event: ChangeEvent<HTMLInputElement>) => {
        const hue = Number(event.target.value);
        this.changeSelectedColor(hue, this.state.saturation, this.state.brightness);
    }
    
    changeSaturation = (event: ChangeEvent<HTMLInputElement>) => {
        const saturation = Number(event.target.value);
        this.changeSelectedColor(this.state.hue, saturation, this.state.brightness);
    }
    
    changeBrightness = (event: ChangeEvent<HTMLInputElement>) => {
        const brightness = Number(event.target.value);
        this.changeSelectedColor(this.state.hue, this.state.saturation, brightness);
    }
    
    updateColor = (hexValue: string) => {
        let hsl_value;
        
        if (this.props.isDisabled) {
            return;
        }
        
        if (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(hexValue)) {
            hsl_value = hexToHSL(hexValue);
            const new_hue = Math.round(hsl_value.h * 360);
            const new_saturation = Math.round(hsl_value.s * 100);
            const new_lightness = Math.round(hsl_value.l * 100);
            const new_change_key = this.state.changeKey + 1;
            const new_lighter_hex_value = HSLToHex(new_hue, new_saturation, new_lightness + 15);
            
            this.setState({
                hue: new_hue,
                saturation: new_saturation,
                brightness: new_lightness,
                selectedColor: `hsl(${new_hue}deg, ${new_saturation}%, ${new_lightness}%)`,
                changeKey: new_change_key
            });
            
            this.props.onChangeColor(hexValue, new_lighter_hex_value);
        }
    }
    
    updateColorFromInput = (event: ChangeEvent<HTMLInputElement>) => {
        let hexValue = event.target.value;
        
        if (hexValue.length !== 7) {
            return;
        }
        
        this.updateColor(hexValue);
    }
    
    render() {
        const selectedColorStyles = {
            backgroundColor: this.state.selectedColor
        }, saturationStyles = {
            backgroundImage: `linear-gradient(to right, hsl(${this.state.hue}, 0%, 50%) 0%, hsl(${this.state.hue}, 100%, 50%) 100%)`
        }, brightnessStyles = {
            backgroundImage: `linear-gradient(to right, hsl(${this.state.hue}, 100%, 0%) 0%, hsl(${this.state.hue}, 100%, 50%) 50%, hsl(${this.state.hue}, 100%, 100%) 100%)`
        }, convertedColor = HSLToHex(this.state.hue, this.state.saturation, this.state.brightness);
        
        const colorPalette = this.props.colors.map(color => {
            return <section className={styles.paletteChoice} key={color.color} style={{backgroundColor: color.color}} onClick={this.updateColor.bind(this, color.color)}></section>
        });
        
        return (
            <section className={styles.ColorComponentHolder} key={this.props.default}>
                {this.props.isShowingSliders && <div className={styles.ProgressBars} key={this.state.changeKey}>
                    <section className={styles.HueSelector}>
                        <input type="range" min="0" max="360" key={this.props.default} disabled={this.props.isDisabled} defaultValue={String(this.state.hue)} onChange={this.changeHue} />
                    </section>

                    <section className={styles.BrightnessSelector} style={brightnessStyles}>
                        <input type="range" min="0" max="100" key={this.props.default} disabled={this.props.isDisabled} defaultValue={String(this.state.brightness)} onChange={this.changeBrightness} />
                    </section>

                    <section className={styles.SaturationSelector} style={saturationStyles}>
                        <input type="range" min="0" max="100" key={this.props.default} disabled={this.props.isDisabled} defaultValue={String(this.state.saturation)} onChange={this.changeSaturation} />
                    </section>
                </div>}
                {this.props.colors && <div className={styles.paletteHolder}>
                    {colorPalette}
                </div>}
                <input key={this.state.changeKey} onChange={this.updateColorFromInput} className={styles.SelectedColor} disabled={this.props.isDisabled} style={selectedColorStyles} defaultValue={convertedColor} />
            </section> 
        );
    }
}