import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/main';
import { deleteWorkflowTypeCustomField, updateWorkflowTypeCustomField, selectWorkflowTypeCustomFieldOption, unSelectWorkflowTypeCustomFieldOption, reOrderWorkflowTypeCustomFieldOption, addWorkflowTypeCustomFieldOption, deleteWorkflowTypeCustomFieldOption, updateWorkflowTypeCustomFieldOption } from '../../../shared/store/workflows/types/actions';

import CustomFieldVertical, { 
    OwnProps as CustomFieldVerticalOwnProps, 
    StateProps as CustomFieldVerticalStateProps, 
    DispatchProps as CustomFieldVerticalDispatchProps, 
    OwnState as CustomFieldVerticalOwnState 
} from './CustomFieldVertical';
import { Permissions } from '../../../shared/store/permissions/types';

interface OwnProps extends CustomFieldVerticalOwnProps {
}

interface StateProps extends CustomFieldVerticalStateProps {
}

interface DispatchProps extends CustomFieldVerticalDispatchProps {
}

interface OwnState extends CustomFieldVerticalOwnState {
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const canEditWorkflowTypeConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewWorkflowTypeConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ || canEditWorkflowTypeConfiguration;

    const workflowType = state.workflows.types.byId[ownProps.workflowTypeId];
    const customFieldData = state.workflows.types.customFields.byId[ownProps.customFieldId];

    return {
        read: canViewWorkflowTypeConfiguration,
        write: canEditWorkflowTypeConfiguration,
        canHaveGroupAffiliation: workflowType.affiliation === 'group',
        restrictStructureChanges: false,
        customFieldData: customFieldData,
        cardsList: customFieldData.choices.map(choiceId => {
            return {
                id: choiceId,
                name: state.workflows.types.customFieldOptions.byId[choiceId].name,
            }
        }),
        selectedCard: state.workflows.types.selectedOption && customFieldData.choices.includes(state.workflows.types.selectedOption) ? {
            id: state.workflows.types.selectedOption,
            name: state.workflows.types.customFieldOptions.byId[state.workflows.types.selectedOption].name,
        } : undefined
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) : DispatchProps => {
    return {
        onSelectCard: (id) => dispatch(selectWorkflowTypeCustomFieldOption(id)),
        onUnSelectCard: () => dispatch(unSelectWorkflowTypeCustomFieldOption()),
        reOrderCards: (sourceIndex, destinationIndex) => dispatch(reOrderWorkflowTypeCustomFieldOption(sourceIndex, destinationIndex, ownProps.customFieldId)),
        addCard: (payload) => dispatch(addWorkflowTypeCustomFieldOption(payload, ownProps.customFieldId)),
        deleteCard: (id) => dispatch(deleteWorkflowTypeCustomFieldOption(id, ownProps.customFieldId)),
        updateCard: (payload) => dispatch(updateWorkflowTypeCustomFieldOption(payload)),

        deleteVertical: (id) => dispatch(deleteWorkflowTypeCustomField(id, ownProps.workflowTypeId)),
        updateVertical: (payload) => dispatch(updateWorkflowTypeCustomField(payload)),
    }
}

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedWorkflowTypeCustomFieldVertical extends CustomFieldVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingModifyVerticalForm: false,

        modifyingCardName: this.props.selectedCard ? this.props.selectedCard.name : '',
        modifyingVerticalName: this.props.customFieldData ? this.props.customFieldData.name : '',
        modifyingVerticalType: this.props.customFieldData ? this.props.customFieldData.type : '',
        modifyingVerticalIsComputed: this.props.customFieldData ? this.props.customFieldData.isComputed ? 'Yes' : 'No' : 'No',
        modifyingVerticalIsInTable: this.props.customFieldData ? this.props.customFieldData.isInTable ? 'Yes' : 'No' : 'No',
        modifyingVerticalIsUnique: this.props.customFieldData ? this.props.customFieldData.isUnique ? 'Yes' : 'No' : 'No',
        modifyingVerticalAffiliation: 'member',

        isUpdating: false,
    };

    static defaultProps = {
        isReadOnly: false,
    }

}

const WorkflowTypeCustomFieldVertical = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowTypeCustomFieldVertical);

export default WorkflowTypeCustomFieldVertical;