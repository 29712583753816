import { Phone, CoOrdinates } from '../../helpers/common-types';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

export interface OrganizationState {
    name: string;
    code: string;
    category: string;
    categories: Array<string>,
    size: string,
    sizes: Array<string>,
    image: string,
    language: string,
    phone: Phone,
    address: string,
    coOrdinates: CoOrdinates,
    primary_color: string,
    primary_gradient: string,
    contrast_color: string,
}

interface UpdateOrganizationAction {
    type: typeof UPDATE_ORGANIZATION,
    payload: OrganizationState,
};

export type OrganizationActionTypes = UpdateOrganizationAction;