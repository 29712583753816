import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setReportName, setReportType, setReportUser, setReportStartDate, setReportEndDate, setVariableForAddReport } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import StepPiece from './step-piece/StepPiece';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';


type AddMemberPieceProps = {
    selectedEntityType?: string,
    nextPiece?: JSX.Element,
    variables: Array<Option>,
    addingVariableName?: string,

    namePiece?: JSX.Element,
    nameText?: string,
    userPiece?: JSX.Element,
    startDatePiece?: JSX.Element,
    startDateText?: string,
    endDatePiece?: JSX.Element,
    endDateText?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        reportTypes: state.reports.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setReportName: (pieceId: string, name: string) => dispatch(setReportName(pieceId, name)),
        setReportType: (pieceId: string, reportType: string) => dispatch(setReportType(pieceId, reportType)),
        setReportUser: (pieceId: string, user: string) => dispatch(setReportUser(pieceId, user)),
        setReportStartDate: (pieceId: string, startDate: string) => dispatch(setReportStartDate(pieceId, startDate)),
        setReportEndDate: (pieceId: string, endDate: string) => dispatch(setReportEndDate(pieceId, endDate)),
        setVariableForAddReport: (pieceId: string, value: string) => dispatch(setVariableForAddReport(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = AddMemberPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type OwnState = {
    isHoveringOverNameVariablePiece: boolean,
    isHoveringOverUserVariablePiece: boolean,
    isHoveringOverStartDateVariablePiece: boolean,
    isHoveringOverEndDateVariablePiece: boolean,
}

class ConnectedAddMemberPiece extends Component<Props, OwnState> {

    state = {
        isHoveringOverNameVariablePiece: false,
        isHoveringOverUserVariablePiece: false,
        isHoveringOverStartDateVariablePiece: false,
        isHoveringOverEndDateVariablePiece: false,
    }

    handleHoverOverNamePiece = () => {
        this.setState({
            isHoveringOverNameVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfNamePiece = () => {
        this.setState({
            isHoveringOverNameVariablePiece: false,
        });
    };

    handleNameValueUpdate = (value: string) => {
        this.props.setReportName(this.props.pieceId, value);
    }

    handleHoverOverUserPiece = () => {
        this.setState({
            isHoveringOverUserVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfUserPiece = () => {
        this.setState({
            isHoveringOverUserVariablePiece: false,
        });
    };

    handleHoverOverStartDatePiece = () => {
        this.setState({
            isHoveringOverStartDateVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfStartDatePiece = () => {
        this.setState({
            isHoveringOverStartDateVariablePiece: false,
        });
    };

    handleStartDateValueUpdate = (value: string) => {
        this.props.setReportStartDate(this.props.pieceId, value);
    }

    handleHoverOverEndDatePiece = () => {
        this.setState({
            isHoveringOverEndDateVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfEndDatePiece = () => {
        this.setState({
            isHoveringOverEndDateVariablePiece: false,
        });
    };

    handleEndDateValueUpdate = (value: string) => {
        this.props.setReportEndDate(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverNameVariablePiece || this.state.isHoveringOverUserVariablePiece || this.state.isHoveringOverStartDateVariablePiece || this.state.isHoveringOverEndDateVariablePiece)) {

            if (this.state.isHoveringOverNameVariablePiece) {
                this.props.setReportName(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverUserVariablePiece) {
                this.props.setReportUser(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverStartDateVariablePiece) {
                this.props.setReportStartDate(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverEndDateVariablePiece) {
                this.props.setReportEndDate(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverNameVariablePiece: false,
                isHoveringOverUserVariablePiece: false,
                isHoveringOverStartDateVariablePiece: false,
                isHoveringOverEndDateVariablePiece: false,
            });
        }
    }

    render() {
        const entityTypeSelectCallback = this.props.setReportType.bind(this, this.props.pieceId);

        let entityTypeSelectBox: JSX.Element|undefined;

        const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.addingVariableName}>
            <DropDownList theme="indigo">
                {this.props.variables.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setVariableForAddReport.bind(this, this.props.pieceId)} />)}
            </DropDownList>
        </SelectBox>;


        entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType ? this.props.reportTypes.byId[this.props.selectedEntityType].name : undefined}>
            <DropDownList theme="aqua">
                {this.props.reportTypes.allEntries.map(reportTypeId => <ListItem theme="aqua" name={this.props.reportTypes.byId[reportTypeId].name} key={reportTypeId} value={reportTypeId} onClick={entityTypeSelectCallback} />)}
            </DropDownList>
        </SelectBox>;

        
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.visibleItems}>
                    <div className={styles.text}>add report with name</div>
                    {this.props.namePiece ? this.props.namePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverNameVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverNamePiece} onMouseOut={this.handleHoverOutOfNamePiece} onChange={this.handleNameValueUpdate} defaultText={this.props.nameText} />}
                    <div className={styles.text}>of type</div>
                    {entityTypeSelectBox}
                    <div className={styles.text}>for user </div>
                    {this.props.userPiece ? this.props.userPiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverUserVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverUserPiece} onMouseOut={this.handleHoverOutOfUserPiece} isDisabled />}
                    <div className={styles.text}>with start date </div>
                    {this.props.startDatePiece ? this.props.startDatePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverStartDateVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverStartDatePiece} onMouseOut={this.handleHoverOutOfStartDatePiece} onChange={this.handleStartDateValueUpdate} defaultText={this.props.startDateText} />}
                    <div className={styles.text}>and end date </div>
                    {this.props.endDatePiece ? this.props.endDatePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverEndDateVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverEndDatePiece} onMouseOut={this.handleHoverOutOfEndDatePiece} onChange={this.handleEndDateValueUpdate} defaultText={this.props.endDateText} />}
                    <div className={styles.text}>and store in</div>
                    {variableSelectBox}
                </div>
            </StepPiece>
        )

    }
}

const AddMemberPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddMemberPiece);

export default AddMemberPiece;