import React, { Component } from 'react';
import { CardType } from '../../../widgets/card/Card';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';
import { LevelState } from '../../../shared/store/structure/level/types';
import { INewGroupTypeData, IUpdateableGroupTypeData } from '../../../shared/store/groups/types/types';
import uuid from 'uuid';
import { ProjectState } from '../../../shared/store/structure/project/types';

export interface OwnProps {
    selectedId?: string,
    heading: string,
    isReadOnly: boolean,
    
    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,
    
    projectsData: ProjectState,
    levelsData: LevelState,

    cardsList: Array<CardType>,
    selectedCard?: IUpdateableGroupTypeData,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: INewGroupTypeData) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: IUpdateableGroupTypeData) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardProject: string,
    modifyingCardLevel: string,
    modifyingCardIsRequired: string,
};

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;
        
        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }
    
    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardProject: this.props.selectedCard.project,
            modifyingCardIsRequired: this.props.selectedCard.isRequired ? 'Yes' : 'No',
        });
    }
    
    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }
    
    updateCardProject = (value: string) => {
        this.setState({
            modifyingCardProject: value
        });
    }
    
    updateCardLevel = (value: string) => {
        this.setState({
            modifyingCardLevel: value
        });
    }

    updateCardIsRequired = (value: string) => {
        this.setState({
            modifyingCardIsRequired: value,
        });
    }

    getLevelsOfProject = () => {

        if (!this.state.modifyingCardProject) {
            return [];
        }

        const allLevelsInProject = this.props.projectsData.byId[this.state.modifyingCardProject].children;

        const allowedLevelsData = allLevelsInProject.map(levelId => {
            return {
                name: this.props.levelsData.byId[levelId].name,
                value: levelId,
            };
        });

        return allowedLevelsData;
    }
    
    addCard = () => {
        
        this.props.addCard({
            name: this.state.modifyingCardName,
            project: this.state.modifyingCardProject,
            level: this.state.modifyingCardLevel,
            isRequired: this.state.modifyingCardIsRequired === 'Yes',
            nameFieldId: uuid.v4(),
            subTitleFieldId: uuid.v4(),
        });
        
        this.setState({
            modifyingCardName: '',
            modifyingCardIsRequired: '',
            isShowingAddForm: false
        });
    }
    
    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            project: this.state.modifyingCardProject,
            level: this.state.modifyingCardLevel,
            isRequired: this.state.modifyingCardIsRequired === 'Yes',
            nameFieldId: this.props.selectedCard.nameFieldId,
            subTitleFieldId: this.props.selectedCard.subTitleFieldId,
        });
        
        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardProject: '',
            modifyingCardIsRequired: '',
            isShowingAddForm: false
        });
    }
    
    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        if (!this.state.modifyingCardProject) {
            return 'Enter a valid project';
        }

        if (!this.state.modifyingCardName) {
            return 'Select if the field is required';
        }
        
        return true;
    }

    render() {
        const binaryOptions = ['Yes', 'No'];

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            }
        });

        const levelsList = this.getLevelsOfProject();

        const modifyForm = <ModifyForm isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <InputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <InputText placeholder="Is Required" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardIsRequired} default={this.props.selectedCard && this.props.selectedCard.isRequired ? 'Yes' : 'No'} options={binaryOptions} />
            <InputText placeholder="Project" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardProject} default={this.props.selectedCard && this.props.selectedCard.project ? this.props.projectsData.byId[this.props.selectedCard.project].name : ''} options={projectsList} />
            <InputText key={this.state.modifyingCardProject} placeholder="Level" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardLevel} default={this.props.selectedCard && this.props.selectedCard.level ? this.props.levelsData.byId[this.props.selectedCard.level].name : ''} options={levelsList} />
        </ModifyForm>;

        return <CardsList 
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard} 
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard} 
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
        />
    }
}

export default CardTreeLevel;