import React, { Component } from 'react';
import styles from './ModifyForm.module.scss';

import { translatePhrase } from '../../shared/helpers/translation';


type OwnProps = {
    isNew: boolean,

    submitForm: () => void,
    validateForm?: () => boolean|string,
    cancelForm: () => void,
}

type OwnState = { errorMessage: string, submitTimer: number|undefined }

export default class ModifyForm extends Component<OwnProps, OwnState> {
    state: OwnState = {
        submitTimer: undefined,
        errorMessage: ''
    }

    static defaultProps = {
        isNew: false,
    }
    
    submitCard = () => {
        this.props.submitForm();
        this.setState({
            submitTimer: undefined
        });
    }
    
    markForSubmit = () => {
        let that = this;
        
        if (this.props.validateForm) {
            let validationResult = this.props.validateForm();
            
            if (typeof(validationResult) === 'string') {
                this.setState({
                    errorMessage: validationResult
                });
                
                setTimeout(() => {
                    that.setState({
                        errorMessage: ''
                    });
                }, 5000);
                
                return;
            }
        }
        
        const timeout = window.setTimeout(that.submitCard, 500);
        this.setState({
            submitTimer: timeout
        });
    }
    
    render() {
        return (
            <section className={(this.props.isNew ? styles.addForm : styles.modifyForm) + ' ignore-react-onclickoutside'}>
                <section className={styles.errorMessage}>{this.state.errorMessage}</section>
                {this.props.children}
                {this.state.submitTimer ? <button className={styles.confirmFormButton}>{this.props.isNew ? translatePhrase('Added') : translatePhrase('Updated')}</button>
                : <div className={styles.buttonsHolder}>
                    <button className={styles.submitFormButton} onClick={this.markForSubmit}>{this.props.isNew ? translatePhrase('Add') : translatePhrase('Update')}</button>
                    <button className={styles.cancelFormButton} onClick={this.props.cancelForm}>{translatePhrase('Cancel')}</button>
                </div>}
            </section>
        );
    }
}