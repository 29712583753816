import React, { Component } from 'react';
import styles from './ModifyWidget.module.scss';
import MultiSelect from '@khanacademy/react-multi-select';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { ApplicationState } from '../../shared/store/main';
import { RouteComponentProps } from 'react-router';

import { withRouter } from "react-router";
import InputText from '../../widgets/form/InputText';
import { IUpdateableWidgetData } from '../../shared/store/widgets/types';
import uuid from 'uuid';
import { Option } from '../../components/flowchart/drop-down/ListItem';
import Checkbox from '../../widgets/form/Checkbox';
import { addWidget, updateWidget } from '../../shared/store/widgets/actions';
import { translatePhrase } from '../../shared/helpers/translation';
import WidgetFlowchart from './WidgetFlowchart';

type OwnProps = {
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        rolesData: state.structure.roles,
        userData: state.users,
        memberData: state.members,
        groupData: state.groups,
        workflowData: state.workflows,
        widgetsData: state.widgets,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWidget: (widgetData: IUpdateableWidgetData) => dispatch(addWidget(widgetData)),
        updateWidget: (widgetData: IUpdateableWidgetData) => dispatch(updateWidget(widgetData)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{id: string}>;

type OwnState = {
    widgetData: IUpdateableWidgetData,
    submitTimer: number|undefined,
    errorMessage: string,
}

class ConnectedModifyWidget extends Component<Props, OwnState> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        const widgetId = props.match.params.id;
        const widget = props.widgetsData.byId[widgetId];

        let widgetData: IUpdateableWidgetData;
        if (!widget) {
            // This is a new workflow
            widgetData = {
                id: uuid.v4(),
                name: '',
                type: '',
                typeId: '',
                roles: [],
                customFields: [],
                displayType: 'table',
                aggregation: 'none',
                seedEntityVariable: uuid.v4(),
            };
        } else {
            widgetData = {
                id: widget.id,
                name: widget.name,
                type: widget.type,
                typeId: widget.typeId,
                roles: widget.roles,
                customFields: widget.customFields,
                displayType: widget.displayType,
                aggregation: widget.aggregation,
                seedEntityVariable: widget.seedEntityVariable,
            }
        }
        
        this.state = {
            widgetData: widgetData,
            submitTimer: undefined,
            errorMessage: ''
        };
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (!state.widgetData.name) {
            const widgetId = props.match.params.id;
            const widget = props.widgetsData.byId[widgetId];

            if (widget) {
                return {
                    widgetData: {
                        id: widget.id,
                        name: widget.name,
                        type: widget.type,
                        typeId: widget.typeId,
                        roles: widget.roles,
                        customFields: widget.customFields,
                        displayType: widget.displayType,
                    },
                };
            }
        }

        return null;
    }

    changeName = (value: string) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            name: value,
        };
        
        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeType = (value: string) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            type: value,
        };
        
        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeTypeId = (value: string) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            typeId: value,
        };
        
        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeRoles = (value: Array<string>) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            roles: value,
        };
        
        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeDisplayType = (value: string) => {
        let displayType: 'table'|'bar'|'line'|'donut' = 'table';

        if (value === 'bar') {
            displayType = 'bar';
        } else if (value === 'line') {
            displayType = 'line';
        } else if (value === 'donut') {
            displayType = 'donut';
        } else if (value === 'table') {
            displayType = 'table';
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            displayType: displayType,
        };
        
        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeAggregation = (value: string) => {
        let aggregationType: 'none'|'count'|'sum'|'average' = 'none';

        if (value === 'none') {
            aggregationType = 'none';
        } else if (value === 'count') {
            aggregationType = 'count';
        } else if (value === 'sum') {
            aggregationType = 'sum';
        } else if (value === 'average') {
            aggregationType = 'average';
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            aggregation: aggregationType,
        };
        
        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    updateSelectedCustomFields = (value: string) => {
        let customFieldsList: Array<string>;

        if (this.state.widgetData.customFields.includes(value)) {
            customFieldsList = this.state.widgetData.customFields.filter(customFieldId => customFieldId !== value);
        } else {
            customFieldsList = this.state.widgetData.customFields.concat([value]);
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            customFields: customFieldsList,
        };
        
        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }
    
    showErrorMessage = (message: string) => {
        let that = this
        
        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 5000);
        
    }
    
    validateIWidgetsData = () => {
        
        if (!this.state.widgetData.name) {
            this.showErrorMessage('Select a valid name');
            return;
        }
        
        if (!this.state.widgetData.type) {
            this.showErrorMessage('Select a valid entity');
            return;
        }
        
        if (!this.state.widgetData.typeId) {
            this.showErrorMessage('Select a valid entity type');
            return;
        }
        
        if (!this.state.widgetData.displayType) {
            this.showErrorMessage('Select a valid chart type');
            return;
        }
        
        if (this.state.widgetData.aggregation === 'none' && this.state.widgetData.customFields.length === 0) {
            this.showErrorMessage('Select at least one custom field');
            return;
        }
        
        if (this.state.widgetData.customFields.length > 1 && this.state.widgetData.displayType !== 'table') {
            this.showErrorMessage('If you are selecting more than one field, you can only have tabular data');
            return;
        }

        if (this.state.widgetData.customFields.length > 1 && this.state.widgetData.aggregation !== 'none') {
            this.showErrorMessage('If you are selecting more than one field, you can not have any aggregations');
            return;
        }
        
        return true
    }
    
    submitWidgetForm = () => {
        if (this.validateIWidgetsData()) {
            this.markForSubmit();
            
        }
    }
    
    markForSubmit = () => {
        let that = this;
        const widgetId = this.props.match.params.id;
        
        const timeout = window.setTimeout(function () {
            if (widgetId) {
                that.props.updateWidget(that.state.widgetData);
                that.props.history.push('/dashboard');
            } else {
                that.props.addWidget(that.state.widgetData);
                that.props.history.push('/dashboard/modify-widget/' + that.state.widgetData.id);
            }

        }, 1000);
        
        this.setState({
            submitTimer: timeout
        });
    }

    render() {
        const entityOptions = ['User', 'Member', 'Group', 'Workflow'];
        const chartTypeOptions = [{
            name: 'Table',
            value: 'table',
        }, {
            name: 'Bar',
            value: 'bar',
        }, {
            name: 'Line',
            value: 'line',
        }, {
            name: 'Donut',
            value: 'donut',
        }];

        const aggregationOptions = [{
            name: 'None',
            value: 'none',
        }, {
            name: 'Count',
            value: 'count',
        }, {
            name: 'Sum',
            value: 'sum',
        }, {
            name: 'Average',
            value: 'average',
        }];

        const widgetId = this.props.match.params.id;
        const widget = this.props.widgetsData.byId[widgetId];

        let entityTypeOptions: Array<Option> = [];
        let customFieldOptions: Array<Option> = [];

        if (this.state.widgetData.type) {
            switch (this.state.widgetData.type) {
                case 'User':
                    entityTypeOptions = this.props.rolesData.allEntries.map(roleId => {
                        return {
                            name: this.props.rolesData.byId[roleId].name,
                            value: roleId,
                        };
                    });

                    if (this.state.widgetData.typeId) {
                        const userCustomFields = this.props.userData.customFields.allFields.map(customFieldId => {
                            return {
                                name: this.props.userData.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });

                        const roleCustomFields = this.props.rolesData.byId[this.state.widgetData.typeId].customFields.map(customFieldId => {
                            return {
                                name: this.props.rolesData.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });

                        customFieldOptions = userCustomFields.concat(roleCustomFields);
                    }
                    break;
                
                case 'Member':
                    entityTypeOptions = this.props.memberData.types.allEntries.map(typeId => {
                        return {
                            name: this.props.memberData.types.byId[typeId].name,
                            value: typeId,
                        };
                    });

                    if (this.state.widgetData.typeId) {
                        customFieldOptions = this.props.memberData.types.byId[this.state.widgetData.typeId].customFields.map(customFieldId => {
                            return {
                                name: this.props.memberData.types.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });
                    }
                    break;
                
                case 'Group':
                    entityTypeOptions = this.props.groupData.types.allEntries.map(typeId => {
                        return {
                            name: this.props.groupData.types.byId[typeId].name,
                            value: typeId,
                        };
                    });

                    if (this.state.widgetData.typeId) {
                        customFieldOptions = this.props.groupData.types.byId[this.state.widgetData.typeId].customFields.map(customFieldId => {
                            return {
                                name: this.props.groupData.types.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });
                    }
                    break;
                
                case 'Workflow':
                    entityTypeOptions = this.props.workflowData.types.allEntries.map(typeId => {
                        return {
                            name: this.props.workflowData.types.byId[typeId].name,
                            value: typeId,
                        };
                    });

                    if (this.state.widgetData.typeId) {
                        customFieldOptions = this.props.workflowData.types.byId[this.state.widgetData.typeId].customFields.map(customFieldId => {
                            return {
                                name: this.props.workflowData.types.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });
                    }
                    break;
                
                default:
                    throw new Error('Unknown widget type');
            }
        }

        const rolesList = this.props.rolesData.allEntries.map(roleId => {
            return {
                label: this.props.rolesData.byId[roleId].name,
                value: roleId,
            };
        });

        const selectedEntityType = widget ? entityTypeOptions.find(entityType => entityType.value === widget.typeId) : undefined;

        return (<section className={styles.FocusSpace}>
            <header className={styles.pageHeader}>
                <h2 className={styles.heading}>{widget ? 'Update Widget' : 'Add Widget'}</h2>
                <Link to="/dashboard"><button className={styles.cancelButton}>x Cancel</button></Link>
            </header>
            <section className={styles.errorMessage}>{this.state.errorMessage}</section>
            <div className={styles.allInputsHolder} key={this.state.widgetData.id}>
                <div className={styles.inputSegment}>
                    <InputText placeholder="Name" default={widget ? widget.name : ''} onChange={this.changeName} />
                </div>
                <div className={styles.inputSegment}>
                    <InputText placeholder="Entity" default={widget ? widget.type : ''} onChange={this.changeType} options={entityOptions} isDisabled={!!widget} />
                </div>
                {this.state.widgetData.type && <div className={styles.inputSegment}>
                    <InputText placeholder="Entity type" default={selectedEntityType ? selectedEntityType.name : ''} onChange={this.changeTypeId} options={entityTypeOptions} />
                </div>}
                <div className={styles.inputSegment}>
                    <InputText placeholder="Chart type" default={widget ? widget.displayType[0].toUpperCase() + widget.displayType.substring(1) : ''} options={chartTypeOptions} onChange={this.changeDisplayType} />
                </div>
                <div className={styles.inputSegment}>
                    <InputText placeholder="Aggregation" default={widget ? widget.aggregation[0].toUpperCase() + widget.aggregation.substring(1) : ''} options={aggregationOptions} onChange={this.changeAggregation} />
                </div>
                <div className={styles.inputSegment}>
                    <MultiSelect
                        options={rolesList}
                        onSelectedChanged={this.changeRoles}
                        selected={this.state.widgetData.roles}
                        overrideStrings={{
                            selectSomeItems: 'Select Roles',
                            allItemsAreSelected: 'All roles are selected',
                        }}
                    />
                </div>
            </div>
            <div className={styles.chartContentConfiguration}>
                <section className={widget ? styles.visibleFilterContainer : styles.filterContainer}>
                    {widget && <WidgetFlowchart widgetId={widgetId} />}
                </section>
                <section className={styles.customFieldsContainer}>
                    <h3 className={styles.customFieldsHeader}>Show</h3>
                    {customFieldOptions.map(customFieldOption => {
                        return <div className={styles.customFieldOption}>
                            <Checkbox 
                                key={customFieldOption.value}
                                name={customFieldOption.name}
                                value={customFieldOption.value}
                                defaultChecked={this.state.widgetData.customFields.includes(customFieldOption.value)}
                                toggle={this.updateSelectedCustomFields}
                            />
                        </div>
                    })}
                    
                </section>
            </div>
            <div className={styles.buttonsHolder}>
                {this.state.submitTimer ? <button className={styles.confirmFormButton}>{widget ? translatePhrase('Updated Widget') : translatePhrase('Added Widget')}</button> : <button className={styles.updateButton} onClick={this.submitWidgetForm}>{widget ? 'Update' : '+ Add'}</button>}
            </div>
        </section>);
    }
}

const ModifyWidget = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedModifyWidget) as any);

export default ModifyWidget;