import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setMemberVariable, setDataCopyVariable, setCustomField, setVariableForCustomField, setEntityType } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/main';
import { valuePieceSlotTarget } from './utilities';


type GetPieceProps = {
    selectedType?: string,
    selectedEntityType?: string,

    nextPiece?: JSX.Element,
    variablePiece?: JSX.Element,
    memberVariable?: JSX.Element,
    variables: Array<Option>,
    gettingCustomField?: string,
    copyingVariableName?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        userData: state.users,
        memberTypes: state.members.types,
        groupTypes: state.groups.types,
        workflowTypes: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
        setCustomField: (pieceId: string, value: string) => dispatch(setCustomField(pieceId, value)),
        setVariableForCustomField: (pieceId: string, value: string) => dispatch(setVariableForCustomField(pieceId, value)),
        setEntityType: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
        setDataCopyVariable: (pieceId: string, value: string) => dispatch(setDataCopyVariable(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GetPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type GetPieceState = {
    isHoveringOverVariablePiece: boolean,
    isHoveringOverMemberPiece: boolean,
}

class ConnectedGetPiece extends Component<Props, GetPieceState> {

    state = {
        isHoveringOverVariablePiece: false,
        isHoveringOverMemberPiece: false,
    };

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverVariablePiece || this.state.isHoveringOverMemberPiece)) {

            if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariableForCustomField(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverMemberPiece) {
                this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
                isHoveringOverMemberPiece: false,
            });
        }
    }

    render() {
        const entityTypeSelectCallback = this.props.setEntityType.bind(this, this.props.pieceId);

        let entityTypeSelectBox: JSX.Element|undefined;
        let customFieldsSelectBox: JSX.Element|undefined;
        let entityType: string|undefined;

        switch(this.props.selectedType) {

            case 'Location':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.levelsData.byId.hasOwnProperty(entityType) ? this.props.levelsData.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.levelsData.allEntries.map(levelId => {
                            const levelData = this.props.levelsData.byId[levelId];

                            return <ListItem theme="pink" name={levelData.name + ' (' + this.props.projectsData.byId[levelData.project].name + ')'} key={levelId} value={levelId} onClick={entityTypeSelectCallback} />
                        })}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.levelsData.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField ? this.props.levelsData.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink" dismissAfterSelection={false}>
                            {this.props.levelsData.byId[entityType].customFields.map(levelFieldId => <ListItem theme="pink" name={this.props.levelsData.customFields.byId[levelFieldId].name} key={levelFieldId} value={levelFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }

                break;

            case 'User':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.rolesData.byId.hasOwnProperty(entityType) ? this.props.rolesData.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.rolesData.allEntries.map(roleId => <ListItem theme="pink" name={this.props.rolesData.byId[roleId].name} key={roleId} value={roleId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;

                const customFieldName = this.props.gettingCustomField ? this.props.userData.customFields.byId.hasOwnProperty(this.props.gettingCustomField) ? this.props.userData.customFields.byId[this.props.gettingCustomField].name : this.props.rolesData.customFields.byId.hasOwnProperty(this.props.gettingCustomField) ? this.props.rolesData.customFields.byId[this.props.gettingCustomField].name : 'Select Fields' : 'Select Fields';

                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={customFieldName}>
                    <DropDownList theme="pink" dismissAfterSelection={false}>
                        {this.props.userData.customFields.allFields.map(userFieldId => <ListItem theme="pink" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        {entityType && (this.props.userData.customFields.byId.hasOwnProperty(entityType) || this.props.rolesData.customFields.byId.hasOwnProperty(entityType)) ? this.props.rolesData.byId[entityType].customFields.map(roleFieldId => <ListItem theme="pink" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />) : undefined}
                    </DropDownList>
                </SelectBox>;

                break;
            
            case 'Member':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.memberTypes.byId.hasOwnProperty(entityType) ? this.props.memberTypes.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.memberTypes.allEntries.map(memberTypeId => <ListItem theme="pink" name={this.props.memberTypes.byId[memberTypeId].name} key={memberTypeId} value={memberTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.memberTypes.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField ? this.props.memberTypes.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink" dismissAfterSelection={false}>
                            {this.props.memberTypes.byId[entityType].customFields.map(memberFieldId => <ListItem theme="pink" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }

                break;
            
            case 'Group':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.groupTypes.byId.hasOwnProperty(entityType) ? this.props.groupTypes.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.groupTypes.allEntries.map(groupTypeId => <ListItem theme="pink" name={this.props.groupTypes.byId[groupTypeId].name} key={groupTypeId} value={groupTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.groupTypes.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField ? this.props.groupTypes.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink" dismissAfterSelection={false}>
                            {this.props.groupTypes.byId[entityType].customFields.map(groupFieldId => <ListItem theme="pink" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }
                break;
            
            case 'Workflow':
                entityType = this.props.selectedEntityType;

                entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType) ? this.props.workflowTypes.byId[entityType].name : undefined}>
                    <DropDownList theme="pink">
                        {this.props.workflowTypes.allEntries.map(workflowTypeId => <ListItem theme="pink" name={this.props.workflowTypes.byId[workflowTypeId].name} key={workflowTypeId} value={workflowTypeId} onClick={entityTypeSelectCallback} />)}
                    </DropDownList>
                </SelectBox>;


                if (entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType)) {

                    customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField ? this.props.workflowTypes.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                        <DropDownList theme="pink" dismissAfterSelection={false}>
                            {this.props.workflowTypes.byId[entityType].customFields.map(workflowFieldId => <ListItem theme="pink" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                }
                break;
            
            default:
                entityTypeSelectBox = undefined;
        }

    
        const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.copyingVariableName}>
            <DropDownList theme="indigo">
                {this.props.variables.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setDataCopyVariable.bind(this, this.props.pieceId)} />)}
            </DropDownList>
        </SelectBox>;

        const isForSingleMember = this.props.selectedType === 'Workflow' && this.props.selectedEntityType && this.props.gettingCustomField && this.props.workflowTypes.byId[this.props.selectedEntityType].affiliation === 'group' && this.props.workflowTypes.customFields.byId[this.props.gettingCustomField].affiliation === 'member';
    
        return (
            <StepPiece theme="pink" {...this.props}>
                <div className={styles.text}>get from </div>
                {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} placeholderText="Entity variable (e.g. the 'Workflow' variable)" minSize={44} />}
                {this.props.selectedType && <div className={styles.text}>which is a {this.props.selectedType} of type </div>}
                {this.props.selectedType && entityTypeSelectBox}
                {this.props.selectedEntityType && <div className={styles.text}> this custom field: </div>}
                {this.props.selectedEntityType && customFieldsSelectBox}
                {this.props.gettingCustomField && isForSingleMember && <div className={styles.text}>for</div>}
                {this.props.gettingCustomField && isForSingleMember && (this.props.memberVariable ? this.props.memberVariable : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} placeholderText="Member variable" minSize={15} />)}
                {this.props.gettingCustomField && <div className={styles.text}>into</div>}
                {this.props.gettingCustomField && variableSelectBox}
            </StepPiece>
        )
    }
}

const GetPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGetPiece);

export default GetPiece;