import React, { Component, ChangeEvent } from 'react';

import UserModify from './UserModify';
import UserFilter from './UserFilter';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/main';

import { addUser, updateUserRequest, deleteUser, searchUserTable, goToPageUserTable, setPageSizeUserTable, sortUserTable } from '../../../shared/store/users/actions';
import { IUpdateableUserData } from '../../../shared/store/users/types';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField, FieldType, CustomField } from '../../../shared/store/custom-fields';
import { translatePhrase } from '../../../shared/helpers/translation';
import { isUUID } from '../../../shared/helpers/utilities';
import { getAllLocationsVisibleToUser } from '../../../shared/helpers/locations';

const mapStateToProps = (state: ApplicationState) => {
    let visibleUsers: Array<string> = [];

    if (isUUID(state.myData.id)) {
        const visibleLocationIds = getAllLocationsVisibleToUser(state.myData.id);

        visibleLocationIds.forEach(locationId => {
            const location = state.structure.locations.byId[locationId];
            visibleUsers = visibleUsers.concat(location.users);
        });
    } else {
        visibleUsers = state.users.allEntries;
    }

    const allProcessedUsers = visibleUsers.map(userId => state.users.byId[userId]),
          currentPageNumber = state.users.currentPageNumber,
          pageSize = state.users.pageSize,
          userSearchTerm = state.users.searchTerm,
          filters = state.users.filters;

    let hasFilters = filters.projects.length > 0 || filters.levels.length > 0 || filters.roles.length > 0 || filters.locations.length > 0 || Object.keys(filters.customFields).length > 0;

    let filteredUsers = !hasFilters ? allProcessedUsers : allProcessedUsers.filter(user => {

        if (filters.projects.length > 0 && !filters.projects.includes(user.project)) {
            return false;
        }

        if (filters.levels.length > 0 && !filters.levels.includes(user.level)) {
            return false;
        }

        if (filters.roles.length > 0 && !filters.roles.includes(user.role)) {
            return false;
        }

        if (filters.locations.length > 0 && !filters.locations.some(locationId => user.locations.includes(locationId))) {
            return false;
        }

        for (const customFieldId in filters.customFields) {
            if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                let customField: CustomField;
                const customFieldValue = user.customFields[customFieldId];

                if (customFieldId in state.users.customFields.byId) {
                    customField = state.users.customFields.byId[customFieldId];
                } else {
                    customField = state.structure.roles.customFields.byId[customFieldId];
                }

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (typeof customFieldValue !== 'string') {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                        return false;
                    }
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    if (!Array.isArray(customFieldValue)) {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                        return false;
                    }
                }

            }
        }

        return true;
    });

    if (userSearchTerm) {
        filteredUsers = filteredUsers.filter(user => {

            let show = false;
            const projectName = state.structure.projects.byId[user.project].name;
            const levelName = state.structure.levels.byId[user.level].name;
            const roleName = state.structure.roles.byId[user.role].name;
            const locationNames = user.locations.map(locationId => state.structure.locations.byId[locationId].name);

            if (projectName.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (levelName.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (roleName.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase())) {
                return true;
            }

            if (locationNames.some(locationName => locationName.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase()))) {
                return true;
            }

            const userFields = state.users.customFields.allFields.filter(fieldId => {
                const customField = state.users.customFields.byId[fieldId];

                return customField.isInTable && (customField.type === FieldType.TEXT || customField.type === FieldType.NUMBER);
            });

            for (const customFieldId of userFields) {
                const customField = state.users.customFields.byId[customFieldId];
                const fieldValue = getReadableDataForCustomField(user.customFields[customFieldId], customField, user.id, 'user');

                if (fieldValue.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase())) {
                    show = true;
                }
            }

            if (filters.roles.length === 1) {
                const role = state.structure.roles.byId[filters.roles[0]];
                const roleFields = role.customFields.filter(fieldId => {
                    const customField = state.structure.roles.customFields.byId[fieldId];
    
                    return customField.isInTable && (customField.type === FieldType.TEXT || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
                });
    
                for (const customFieldId of roleFields) {
                    const customField = state.structure.roles.customFields.byId[customFieldId];
                    const fieldValue = getReadableDataForCustomField(user.customFields[customFieldId], customField, user.role, 'role');
    
                    if (fieldValue.toLocaleLowerCase().includes(userSearchTerm.toLocaleLowerCase())) {
                        show = true;
                    }
                }
            }

            return show;
        });
    }
    
    return {
        usersList: filteredUsers.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize),
        totalNoOfUsers: filteredUsers.length,
        pageSize: pageSize,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(filteredUsers.length / pageSize),

        searchTerm: userSearchTerm,
        userFilters: filters,
        userSort: state.users.sort,

        customFieldsData: state.users.customFields,
        customFieldOptionsData: state.users.customFieldOptions,

        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,

        usersData: state.users,
        
        areFiltersExpanded: false,

        applicationState: state,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchUserTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageUserTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeUserTable(size)),
        sort: (column: string, order: 'ASC'|'DESC') => dispatch(sortUserTable(column, order)),
        
        addUser: (payload: IUpdateableUserData) => dispatch(addUser(payload)),
        deleteUser: (id: string) => dispatch(deleteUser(id)),
        updateUser: (payload: IUpdateableUserData) => dispatch(updateUserRequest(payload)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    isShowingFilterForm: boolean,
}

class ConnectedUsersTable extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingFilterForm: false,
    }

    static defaultProps = {
        isReadOnly: false,
    }
    
    showAddForm = () => {
        this.setState({
            isShowingAddForm: true,
        });
    }
    
    showFilterForm = () => {
        this.setState({
            isShowingFilterForm: true,
        });
    }

    showModifyForm = () => {
        this.setState({
            isShowingModifyForm: true,
        });
    }
    
    hideUserForm = () => {
        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: false,
        });
    }
    
    hideFilterForm = () => {
        this.setState({
            isShowingFilterForm: false,
        });
    }
    
    addUser = (userData: IUpdateableUserData) => {
        this.props.addUser(userData);
        this.setState({
            isShowingAddForm: false
        });
    }
    
    updateUser = (memberData: IUpdateableUserData) => {
        this.props.updateUser(memberData);
        this.setState({
            isShowingModifyForm: false
        });
    }
    
    deleteUser = (id: string) => {
        this.props.deleteUser(id);
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.searchTable(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.userSort.column !== column;
        const sortOrder = !isNewColumn && this.props.userSort.order === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }
    
    render() {

        const MAX_NO_OF_USERS = 500;

        const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
        const subTitleField = this.props.usersData.customFields.byId[this.props.usersData.subTitleFieldId];

        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: nameField.name,
            isSortable: false,
            isSticky: true,
            width: 150,
        }, {
            name: subTitleField.name,
            isSortable: false,
            isSticky: false,
            width: 150,
        }, {
            name: 'Project',
            isSortable: false,
            width: 170,
        }, {
            name: 'Level',
            isSortable: false,
            width: 130,
        }, {
            name: 'Location',
            isSortable: false,
            width: 170,
        }, {
            name: 'Role',
            isSortable: false,
            width: 150,
        }, {
            name: 'Phone',
            isSortable: false,
            width: 130,
        }];

        this.props.customFieldsData.allFields.forEach(customFieldId => {

            if (this.props.usersData.nameFieldId === customFieldId || this.props.usersData.subTitleFieldId === customFieldId) {
                return;
            }

            const customField = this.props.customFieldsData.byId[customFieldId];
            const width = customField.type === FieldType.TEXT ? 200 : 150;

            if (customField.isInTable) {
                headings.push({
                    name: customField.name,
                    isSortable: false,
                    width: width,
                });
            }

        });

        if (this.props.userFilters.roles.length === 1) {
            const role = this.props.rolesData.byId[this.props.userFilters.roles[0]];
            role.customFields.forEach(customFieldId => {
                const customField = this.props.roleCustomFieldsData.byId[customFieldId];
                const width = customField.type === FieldType.TEXT ? 200 : 150;
    
                if (customField.isInTable) {
                    headings.push({
                        name: customField.name,
                        isSortable: false,
                        width: width,
                    });
                }
            });
        }

        const entries: Array<TableRow> = this.props.usersList.map<TableRow>((user, index) => {
            let userName = user.customFields[this.props.usersData.nameFieldId];

            userName = getReadableDataForCustomField(userName, this.props.customFieldsData.byId[this.props.usersData.nameFieldId], user.id, 'user');

            let userSubTitle = user.customFields[this.props.usersData.subTitleFieldId];

            userSubTitle = getReadableDataForCustomField(userSubTitle, subTitleField, user.id, 'user');

            const cells = [
                (this.props.pageNumber - 1) * this.props.pageSize + index + 1, // Sl. no
                userName, // Name
                userSubTitle, // Details
                translatePhrase(this.props.projectsData.byId[user.project].name), // Project
                translatePhrase(this.props.levelsData.byId[user.level].name), // Level
                user.locations.length ? user.locations.map(locationId => translatePhrase(this.props.locationsData.byId[locationId].name)).join(', ') : '-', // Location
                translatePhrase(this.props.rolesData.byId[user.role].name), // Role
                user.phone.countryCode + ' ' + user.phone.number, // Phone,
            ];

            this.props.customFieldsData.allFields.forEach(customFieldId => {

                if (this.props.usersData.nameFieldId === customFieldId || this.props.usersData.subTitleFieldId === customFieldId) {
                    return;
                }
                
                const customField = this.props.customFieldsData.byId[customFieldId];

                if (!customField.isInTable) {
                    return;
                }

                let customFieldValue = user.customFields[customFieldId];

                let cellValue = getReadableDataForCustomField(customFieldValue, customField, user.id, 'user');

                cells.push(cellValue);
            });

            if (this.props.userFilters.roles.length === 1) {
                const role = this.props.rolesData.byId[this.props.userFilters.roles[0]];
                role.customFields.forEach(customFieldId => {
                    const customField = this.props.roleCustomFieldsData.byId[customFieldId];

                    if (!customField.isInTable) {
                        return;
                    }
    
                    let customFieldValue = user.customFields[customFieldId];
    
                    let cellValue = getReadableDataForCustomField(customFieldValue, customField, role.id, 'role');
    
                    cells.push(cellValue);
                });
            }

            const userEditForm = !this.props.isReadOnly ? (<UserModify userId={user.id} submit={this.updateUser} cancel={this.hideUserForm} />) : undefined;

            return {
                id: user.id,
                entries: cells,
                editForm: userEditForm,
                shareText: `Name: ${userName}\nDescription: ${userSubTitle}`,
            }
        });

        return (
            <TableWithMeta 
                entityType="User"
                headings={headings} 
                entries={entries} 
                
                sortedColumn={this.props.userSort.column} 
                sortType={this.props.userSort.order} 
                isReadOnly={this.props.isReadOnly}
                areFiltersExpanded={this.props.areFiltersExpanded}

                totalPages={this.props.totalPages}
                pageNumber={this.props.pageNumber}
                searchTerm={this.props.searchTerm}
                isAddAllowed={this.props.totalNoOfUsers < MAX_NO_OF_USERS}
                isShowingAddForm={this.state.isShowingAddForm} 
                isShowingModifyForm={this.state.isShowingModifyForm}
                addForm={<UserModify submit={this.addUser} cancel={this.hideUserForm} />}

                isShowingFilterForm={this.state.isShowingFilterForm}
                showFilterForm={this.showFilterForm}
                filterForm={<UserFilter closeFilter={this.hideFilterForm} />}

                onSort={this.handleSort} 
                onDelete={this.props.deleteUser} 
                showAddForm={this.showAddForm}
                showModifyForm={this.showModifyForm}  

                search={this.props.searchTable}
                changePageSize={this.props.changePageSize}
                goToPage={this.props.goToPage}
                sort={this.props.sort}
            />
        );
    }
}

const UsersTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsersTable);

export default UsersTable;