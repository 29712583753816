import React, { Component } from 'react';
import styles from './Configuration.module.scss';
import { Redirect } from "react-router-dom";

import { Permissions } from '../../../shared/store/permissions/types';

import { selectReportType, unSelectReportType } from '../../../shared/store/reports/types/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ReportTypesList from './ReportTypesList';
import ReportFlowchart from './ReportFlowchart';

import { ApplicationState } from '../../../shared/store/main';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.ReportsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.ReportsConfiguration === Permissions.READ;
    const selectedReportTypeId = state.reports.types.selected;

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        selectedReportTypeId,
        reportTypesData: state.reports.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectReportType: (id: string) => dispatch(selectReportType(id)),
        unSelectReportType: () => dispatch(unSelectReportType()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalType: string,
    modifyingVerticalIsComputed: string,
    modifyingVerticalIsInTable: string,
    modifyingVerticalIsUnique: string,
    modifyingVerticalAffiliation: 'member'|'group',
}

class ConnectedLocations extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalType: '',
        modifyingVerticalIsComputed: 'No',
        modifyingVerticalIsInTable: 'No',
        modifyingVerticalIsUnique: 'No',
        modifyingVerticalAffiliation: 'member',
    };
        
    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        return (<div className={styles.reportsHolder}>
            <div className={styles.allReportTypes}>
                <ReportTypesList />
            </div>
            {this.props.selectedReportTypeId && 
            <div className={styles.flowchartHolder + ' ignore-react-onclickoutside'}>
                {this.props.selectedReportTypeId && <div className={styles.selectedReport}>
                    {this.props.selectedReportTypeId && <ReportFlowchart reportTypeId={this.props.selectedReportTypeId} />}
                </div>}
            </div>}
        </div>);
        
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;