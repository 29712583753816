import React, { Component } from 'react';
import { CardType } from '../../../widgets/card/Card';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import InputText from '../../../widgets/form/InputText';
import uuid from 'uuid';
import { WorkflowTypeState } from '../../../shared/store/workflows/types/types';
import { ProjectState } from '../../../shared/store/structure/project/types';
import { MemberTypeState } from '../../../shared/store/members/types/types';
import { GroupTypeState } from '../../../shared/store/groups/types/types';
import { Option } from '../../flowchart/drop-down/ListItem';

export type SpecialType = 'add-member'|'add-group'|'edit-member'|'edit-group';

export interface OwnProps {
    selectedId?: string,
    heading: string,
    isReadOnly: boolean,
    
    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
}

export interface WorkflowCardType extends CardType {
    affiliation: 'member'|'group'|'none',
    affiliatedEntity: string,
    isCore: boolean,
    project: string,
    seedEntityVariable: string,
    seedAffiliationVariable: string,
    startPiece: {
        piece: string,
        position: {
            x: number,
            y: number,
        },
    },
};

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    projectsData: ProjectState,
    memberTypesData: MemberTypeState,
    groupTypesData: GroupTypeState,
    workflowTypesData: WorkflowTypeState,
    cardsList: Array<WorkflowCardType>,
    selectedCard: WorkflowCardType|undefined,

    originalSpecialType: 'none'|'add-member'|'add-group'|'edit-member'|'edit-group',
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: WorkflowCardType) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: WorkflowCardType) => void,
    updateSpecialWorkflowTypeForProject: (projectId: string, specialType: 'add-member'|'add-group'|'edit-member'|'edit-group', workflowTypeId: string|undefined) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardAffiliation: 'member'|'group'|'none',
    modifyingCardAffiliatedEntity: string,
    modifyingCardIsCore: string,
    modifyingCardProject: string,

    modifyingSpecialType: 'none'|'add-member'|'add-group'|'edit-member'|'edit-group',
    
    seedAffiliationVariable: string,
    lastSelectedCard: string|undefined,
};

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if ((!props.selectedCard && typeof state.lastSelectedCard !== 'undefined') || (props.selectedCard && props.selectedCard.id !== state.lastSelectedCard)) {

            return {
                modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
                modifyingCardProject: props.selectedCard ? props.selectedCard.project : '',
                modifyingCardIsCore: props.selectedCard ? props.selectedCard.isCore ? 'Yes' : 'No' : 'Yes',
                modifyingCardAffiliation: props.selectedCard ? props.selectedCard.affiliation : 'none',
                modifyingCardAffiliatedEntity: props.selectedCard ? props.selectedCard.affiliatedEntity : '',

                modifyingSpecialType: props.originalSpecialType,
            
                seedAffiliationVariable: props.selectedCard ? props.selectedCard.seedAffiliationVariable : '',
                lastSelectedCard: props.selectedCard ? props.selectedCard.id : undefined,
            };
        }

        return null;
    }

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        const toggledState = !this.state.isShowingAddForm;
        
        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false,
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState,
            });
        }
    }
    
    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardAffiliation: this.props.selectedCard.affiliation,
        });
    }
    
    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    updateCardAffiliation = (value: string) => {
        let affiliationValue: 'member'|'group'|'none';
        switch (value) {
            case 'none': affiliationValue = value;
                break;
            case 'member': affiliationValue = value;
                break;
            case 'group': affiliationValue = value;
                break;
            default:
                throw new Error('The affiliation cannot be of any other type');
        }

        this.setState({
            modifyingCardAffiliation: affiliationValue,
        });
    }

    updateCardAffiliatedEntity = (value: string) => {
        this.setState({
            modifyingCardAffiliatedEntity: value,
        });
    }

    updateCardSpecialType = (value: string) => {
        let specialType: 'none'|'add-member'|'add-group'|'edit-member'|'edit-group';
        switch (value) {
            case 'none': specialType = value;
                break;
            case 'add-member': specialType = value;
                break;
            case 'add-group': specialType = value;
                break;
            case 'edit-member': specialType = value;
                break;
            case 'edit-group': specialType = value;
                break;
            default:
                throw new Error('The speciality cannot be of any other type');
        }

        this.setState({
            modifyingSpecialType: specialType,
        });
    }

    updateCardProject = (value: string) => {

        let specialType: 'none'|'add-member'|'add-group'|'edit-member'|'edit-group' = 'none';

        if (this.props.selectedCard) {
            const projectData = this.props.projectsData.byId[this.state.modifyingCardProject];

            if (projectData['add-member'] === this.props.selectedCard.id) {
                specialType = 'add-member';
            } else if (projectData['add-group'] === this.props.selectedCard.id) {
                specialType = 'add-group';
            } else if (projectData['edit-member'] === this.props.selectedCard.id) {
                specialType = 'edit-member';
            } else if (projectData['edit-group'] === this.props.selectedCard.id) {
                specialType = 'edit-group';
            }
        }

        this.setState({
            modifyingCardProject: value,
            modifyingSpecialType: specialType,
        });
    }

    updateCardIsCore = (value: string) => {

        this.setState({
            modifyingCardIsCore: value,
        });
    }
    
    addCard = () => {
        const newId = uuid.v4();
        
        this.props.addCard({
            id: newId,
            name: this.state.modifyingCardName,
            affiliation: this.state.modifyingCardAffiliation,
            affiliatedEntity: this.state.modifyingCardAffiliatedEntity,
            isCore: this.state.modifyingCardIsCore === 'Yes',
            project: this.state.modifyingCardProject,
            seedEntityVariable: uuid.v4(),
            seedAffiliationVariable: uuid.v4(),
            startPiece: {
                piece: uuid.v4(),
                position: {
                    x: 0,
                    y: 0,
                },
            },
        });

        if (this.state.modifyingSpecialType !== 'none') {
            this.props.updateSpecialWorkflowTypeForProject(this.state.modifyingCardProject, this.state.modifyingSpecialType as SpecialType, newId)
        }
        
        this.setState({
            modifyingCardName: '',
            modifyingCardAffiliation: 'none',
            isShowingAddForm: false
        });
    }
    
    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            affiliation: this.state.modifyingCardAffiliation,
            affiliatedEntity: this.state.modifyingCardAffiliatedEntity,
            isCore: this.state.modifyingCardIsCore === 'Yes',
            project: this.state.modifyingCardProject,
            seedEntityVariable: this.props.selectedCard.seedEntityVariable,
            seedAffiliationVariable: this.props.selectedCard.seedAffiliationVariable,
            startPiece: this.props.selectedCard.startPiece,
        });

        if (this.state.modifyingSpecialType !== this.props.originalSpecialType) {
            this.props.updateSpecialWorkflowTypeForProject(this.state.modifyingCardProject, this.props.originalSpecialType as SpecialType, undefined);
        }

        if (this.state.modifyingSpecialType !== 'none') {
            this.props.updateSpecialWorkflowTypeForProject(this.state.modifyingCardProject, this.state.modifyingSpecialType as SpecialType, this.props.selectedCard.id)
        }
        
        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardAffiliation: 'none',
            isShowingAddForm: false
        });
    }
    
    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        if (!this.state.modifyingCardProject) {
            return 'Enter a valid project';
        }
        
        return true;
    }

    render() {
        const affiliationOptions = [{
            name: 'None',
            value: 'none',
        }, {
            name: 'Member',
            value: 'member',
        }, {
            name: 'Group',
            value: 'group',
        }];

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            }
        });

        let affiliatedEntityTypeOptions = [{
            name: 'All',
            value: '',
        }];

        let specialWorkflowTypes: Array<Option> = [];

        if (this.state.modifyingCardAffiliation === 'none') {
            specialWorkflowTypes = [{
                name: 'None',
                value: 'none',
            }, {
                name: 'Add Member',
                value: 'add-member',
            }, {
                name: 'Add Group',
                value: 'add-group',
            }];
        } else if (this.state.modifyingCardAffiliation === 'member') {

            if (this.state.modifyingCardAffiliatedEntity === '') {
                specialWorkflowTypes = [{
                    name: 'None',
                    value: 'none',
                }, {
                    name: 'Edit Member',
                    value: 'edit-member',
                }];
            }

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                this.props.memberTypesData.allEntries
                .filter(memberTypeId => this.props.memberTypesData.byId[memberTypeId].project === this.state.modifyingCardProject)
                .map(memberTypeId => {
                    const memberType = this.props.memberTypesData.byId[memberTypeId];
                    return {
                        name: memberType.name,
                        value: memberTypeId,
                    };
                })
            );
        } else if (this.state.modifyingCardAffiliation === 'group') {

            if (this.state.modifyingCardAffiliatedEntity === '') {
                specialWorkflowTypes = [{
                    name: 'None',
                    value: 'none',
                }, {
                    name: 'Edit Group',
                    value: 'edit-group',
                }];
            }

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                this.props.groupTypesData.allEntries
                .filter(groupTypeId => this.props.groupTypesData.byId[groupTypeId].project === this.state.modifyingCardProject)
                .map(groupTypeId => {
                    const groupType = this.props.groupTypesData.byId[groupTypeId];
                    return {
                        name: groupType.name,
                        value: groupTypeId,
                    };
                })
            );
        }

        const defaultSpecialType = this.state.modifyingSpecialType.split('-').map(word => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase()).join(' ');

        const modifyForm = <ModifyForm key={this.state.lastSelectedCard} isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <InputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            {!this.props.selectedCard && <InputText placeholder="Project" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardProject} options={projectsList} />}
            {!this.props.selectedCard && this.state.modifyingCardProject && <InputText placeholder="Affiliation" onEnterPress={this.addCard} onChange={this.updateCardAffiliation} options={affiliationOptions} />}
            {!this.props.selectedCard && this.state.modifyingCardAffiliation !== 'none' && <InputText placeholder="Affiliated entity" onEnterPress={this.addCard} onChange={this.updateCardAffiliatedEntity} options={affiliatedEntityTypeOptions} />}
            <InputText placeholder="Speciality" key={defaultSpecialType + this.state.modifyingCardAffiliation + this.state.modifyingCardAffiliation} onEnterPress={this.addCard} onChange={this.updateCardSpecialType} options={specialWorkflowTypes} default={defaultSpecialType} />
            <InputText placeholder="Is Core?" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardIsCore} default={this.props.selectedCard && !this.props.selectedCard.isCore ? 'No' : 'Yes'} options={['Yes', 'No']} />
        </ModifyForm>;

        return <CardsList 
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard} 
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard} 
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={() => {this.props.onUnSelectCard(); this.toggleModifyForm(); }}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
        />
    }
}

export default CardTreeLevel;